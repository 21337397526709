import { GsUnflaggingService } from "../../service";
import { Creators } from "./types";

const fetchGsUnflaggingList = (params) => dispatch => {
    dispatch(Creators.showLoader());
    return (
        GsUnflaggingService.getGsUnflaggingList(params)
            .then(response => {
                let { data: { detail = [] } } = response;
                dispatch(Creators.fetchGsUnflaggingListSuccess(detail));
            })
            .catch(error => {
                dispatch(Creators.fetchGsUnflaggingListError());
                throw error;
            })
            .finally(() => {
                dispatch(Creators.hideLoader());
            })
    );
};

const fetchGsUnflaggingDetails = (appointmentId, params) => dispatch => {
    dispatch(Creators.showLoader());
    return (
        GsUnflaggingService.getGsUnflaggingDetails(appointmentId, params)
            .then(response => {
                let { data: { detail = {} } } = response;
                dispatch(Creators.fetchGsUnflaggingDetailsSuccess(detail));
            })
            .catch(error => {
                dispatch(Creators.fetchGsUnflaggingDetailsError());
                throw error;
            })
            .finally(() => {
                dispatch(Creators.hideLoader());
            })
    );
};

const gsUnflaggingAction = (appointmentId, data, cb) => dispatch => {
    dispatch(Creators.showLoader());
    return (
        GsUnflaggingService.gsUnflaggingAction(appointmentId, data)
            .then(response => {
                let { data: { detail = "" } } = response;
                cb("success", detail);
            })
            .catch(error => {
                dispatch(Creators.fetchGsUnflaggingListError());
                let message = error.detail;
                if(error.status == '412') {
                    message += ', Please retry again.';
                }
                cb("error", message);
                throw error;
            })
            .finally(() => {
                dispatch(Creators.hideLoader());
            })
    );
};

export {
    fetchGsUnflaggingList,
    fetchGsUnflaggingDetails,
    gsUnflaggingAction
};
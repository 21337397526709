import React from 'react';
import styles from './pdf.module.css';
import PdfIcon from '../../images/pdf.svg';

const Pdf = ({ url }) => {
  // let pdfName = url.split('/').pop();

  const handlePdfClick = () => window.open(url, '_blank');

  return (
    <div className={styles.pdf} onClick={handlePdfClick}>
      <img src={PdfIcon} width="200" />
      <div className={styles.pdfInfo}>
        {/* <i>{pdfName}</i> */}
        <p>Click to open the pdf</p>
      </div>
    </div>
  );
};

export default Pdf;

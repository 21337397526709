import React, { useEffect, useState } from "react";
import { RESET_REDUCER_KEYS } from "../../utils/app-constants";
import moment from "moment";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Grid from "@material-ui/core/Grid";
import { isUserRoleBIkeQc } from "../../utils/helper";

const InsuranceExpiryDate = ({ detail, resetInspectionDetailByKeyConnect, inspectionDetails, bikeInspectionDetails, resetInspectionBikeDetailByKeyConnect }) => {
    const { value, key } = detail;
    const { insuranceMapper } = inspectionDetails;

    const [dateValue, setDateValue] = useState("");
    const [selectedDate, setSelectedDate] = useState(new Date());
    let isBikeQc = isUserRoleBIkeQc();
    const { bikeDocumentsMapper } = bikeInspectionDetails;

    useEffect(() => {
        return () => {
            // resetToInitialValue();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const resetToInitialValue = () => {
        let mapper = [...insuranceMapper];
        let newValue = "";
        let newId = "";
        mapper = mapper.map(map => {
            let element = { ...map };
            if (map.key === key) {
                element.newValue = newValue;
                element.newId = newId;
                element.checked = false;
            }

            return element;
        });
        resetInspectionDetailByKeyConnect(RESET_REDUCER_KEYS.INSURANCE_MAPPER, mapper);
    };

    useEffect(() => {
        if (dateValue) {
            setNewValue();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dateValue]);

    useEffect(() => {
        if (value) {
            if (isBikeQc) {
                let formattedDate = moment(value, 'DD-MM-YYYY').format('MM/DD/YYYY');
                setSelectedDate(formattedDate);
            }
            else {
                const date_arr = value.split("-");
                const fitnessDate = `${date_arr[2]}-${date_arr[1]}-${date_arr[0]}`;
                setSelectedDate(new Date(fitnessDate));
            }

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    const setNewValue = () => {
        let mapper = isBikeQc ? [...bikeDocumentsMapper] : [...insuranceMapper];
        const newValue = dateValue;
        mapper = mapper.map(map => {
            let element = { ...map };
            if (map.key === key) {
                element.newValue = newValue;
                element.newId = newValue;
                // element.checked = false;
            }

            return element;
        });
        if (isBikeQc) {
            resetInspectionBikeDetailByKeyConnect(RESET_REDUCER_KEYS.BIKE_DOCUMENTS_MAPPER, mapper);
        } else {
            resetInspectionDetailByKeyConnect(RESET_REDUCER_KEYS.INSURANCE_MAPPER, mapper);
        }
    };

    const handleDateChange = date => {
        const dateFormate = isBikeQc ? "DD/MM/YYYY" : "DD-MM-YYYY";
        const formattedDate = moment(date).format(dateFormate).toString();
        setSelectedDate(date);
        setDateValue(formattedDate);
    };

    return (
        <div className="mb-5">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container>
                    <KeyboardDatePicker
                        placeholder="Select Date"
                        value={selectedDate}
                        onChange={date => handleDateChange(date)}
                        format="dd/MM/yyyy"
                    />
                </Grid>
            </MuiPickersUtilsProvider>
        </div>
    );
};

export default InsuranceExpiryDate;

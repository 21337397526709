import React from "react";
import styles from "./zoom.module.css";
import Minus from "../images/minus.svg"; 
import Pluse from "../images/pluse.svg"; 
import Button from "../shared/button/component";

const Zoom = ({
    zoomOut,
    zoomIn
}) => {
    return (
        <div className={styles.zoom}>
            <button onClick={zoomOut}>
                <img src={Minus} alt="minus"/>
            </button>
            <button onClick={zoomIn}>
                <img src={Pluse} alt="pluse"/>
            </button>
        </div>
    );
};

export default Zoom;

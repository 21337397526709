import React, { useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import firebase from '../../utils/firebase';
import Loader from "../../components/loader/component";
import Search from '../../components/shared/search';
import Filters from './filters';
import Colorpallet from '../../components/colorpallet/component';
import Header from '../../components/header/component';
import { Table } from '../../components/shared/table/Table';
import { fetchGsUnflaggingList } from './actions';
import { convertFiltersToValueArray, getFromLocalStorage, saveToLocalStorage } from '../../utils/helper';
import { LOCAL_STORAGE_KEYS } from '../../utils/app-constants';

const GsUnflagging = ({ history }) => {
    const dispatch = useDispatch();

    const { data = [], isLoading } = useSelector(state => state.gsUnflaggingReducer);
    const { query: searchText } = useSelector(state => state.search);
    const filters = useSelector(state => state.gsUnflaggingReducer.filters);

    useEffect(() => {
        let { imageHostUrl, videoHostUrl, imageResizingQueryString } = JSON.parse(getFromLocalStorage(LOCAL_STORAGE_KEYS.LOGIN_DATA) || '{}');
        saveToLocalStorage(LOCAL_STORAGE_KEYS.IMAGE_HOST_URL, imageHostUrl);
        saveToLocalStorage(LOCAL_STORAGE_KEYS.VIDEO_HOST_URL, videoHostUrl);
        saveToLocalStorage(LOCAL_STORAGE_KEYS.IMAGE_QUALITY_PARAM, imageResizingQueryString);
    }, []);

    useEffect(() => {
        try {
            let firebaseDatabase = firebase.getInstance().firestore();
            let collection = firebaseDatabase.collection("gs-auto-assignment");

            var unsubscribe = collection.where("email", "==", getFromLocalStorage(LOCAL_STORAGE_KEYS.EMAIL))
                .onSnapshot((snapshot) => {
                    snapshot.docChanges().forEach((change) => {
                        if (
                            (change.type === "added" || change.type === "modified") &&
                            change.doc.data().status == "changed"
                        ) {
                            collection.doc(getFromLocalStorage(LOCAL_STORAGE_KEYS.EMAIL)).update({ status: "pending" });
                            fetchList();
                        }
                    });
                });
        } catch (error) {
            throw error;
        }

        return () => unsubscribe();
    }, []);

    useEffect(() => {
        fetchList();
    }, [searchText, filters]);

    const fetchList = () => {
        let params = {
            emailId: getFromLocalStorage(LOCAL_STORAGE_KEYS.EMAIL),
            ...(searchText !== null && searchText !== "-1" ? { appointmentId: searchText } : {}),
            ...(filters ? { ...convertFiltersToValueArray(filters) } : {})
        };
        dispatch(fetchGsUnflaggingList(params));
    };

    const columns = useMemo(() => [
        {
            Header: " ",
            Cell: (props) => <Colorpallet initialTime={props.row.original.createdAt} />
        },
        {
            Header: "Appt ID",
            accessor: "appointmentId"
        },
        {
            Header: "Category",
            accessor: "category"
        },
        {
            Header: "Inspection End Time",
            accessor: "inspectionEndTime"
        },
        {
            Header: "Car",
            Cell: (props) => <>{`${props.row.original.make}/${props.row.original.model}`}</>
        },
        {
            Header: "Center",
            accessor: "storeName"
        },
        {
            Header: "Created At",
            accessor: "createdAt"
        },
        {
            Header: "Assignee",
            accessor: "assignedTo"
        },
        {
            Header: "Type",
            Cell: ({ row }) => {
                let { conditionFlag } = row.original;
                return conditionFlag == undefined ? null : (conditionFlag == 0 ? "Alpha" : "Beta");
            }
        }
    ], []);

    const _data = useMemo(() => data, [data]);

    const handleOnRowClick = (row) => {
        let { appointmentId } = row.original;
        history.push(`/gs-unflagging-details/${appointmentId}`);
    };

    return (
        <>
            {isLoading && <Loader />}

            <Header
                history={history}
                showPerformance={false}
                showVersion
            />

            <div className="subheader">
                <Filters />
                <Search />
            </div>

            {!!_data.length &&
                <Table
                    columns={columns}
                    data={_data}
                    showColorStatus
                    onRowClick={handleOnRowClick}
                />
            }
            {
                !_data.length && !isLoading && <p className="text-center">No Data Found!</p>
            }
        </>
    );
};

export default GsUnflagging;
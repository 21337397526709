import React, { useState } from "react";
import Carousel from "../../components/carousel";
import ImageCheckboxBike from "../../components/image-checkbox-bike";
import styles from "./inspection-review-rc-bike.module.css";
import { BIKE_IMAGE_TYPE, LOCAL_STORAGE_KEYS } from "../../utils/app-constants";
import { getFromLocalStorage } from "../../utils/helper";
import InspectionReviewBikeDocuments from "../../components/inspection-review-bike-documents";

const InspectionReviewRcBike = ({
    bikeInspectionDetails,
    data,
    type,
    currentMapper,
    detailView,
    versionViewMode = true
}) => {
    const { qcUser } = bikeInspectionDetails;
    const email = getFromLocalStorage(LOCAL_STORAGE_KEYS.EMAIL);
    const [currentIndex, setCurrentIndex] = useState(0);
    let rcImagesMapper = [...data]; 
    if (type === "RC") {
        rcImagesMapper = rcImagesMapper.filter(x => x.type === BIKE_IMAGE_TYPE.RC || x.type === BIKE_IMAGE_TYPE.CHASIS_NUMBER);
    }
    if (type === "INSU") {
        rcImagesMapper = rcImagesMapper.filter(x => x.type === BIKE_IMAGE_TYPE.INSURANCE);
    }

    return (
        <div>
            <div className={styles.reviewWrapper}>
                <div className={styles.carouselTextWrapper}>
                    {!!rcImagesMapper && rcImagesMapper.length > 0 && (
                        <div className={styles.carousel}>
                            <Carousel versionViewMode={versionViewMode} images={rcImagesMapper} key="documents" setCurrentIndex={setCurrentIndex} height={630} wheelZoomDisabled={false} />
                            {(email === qcUser) && <ImageCheckboxBike versionViewMode={versionViewMode} index={currentIndex} currentMapper={currentMapper} />}
                        </div>
                    )}
                    {detailView && (
                        <InspectionReviewBikeDocuments versionViewMode={versionViewMode} />
                    )}
                </div>
            </div>
        </div>
    );
};

export default InspectionReviewRcBike;

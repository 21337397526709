import React, { useEffect, useState, useCallback } from "react";
import styles from "./sell-online-process.module.css";
import Button from  "../../components/shared/button";
import { InspectionService } from "../../service";
import Loader from '../../components/loader';
import { ToastContainer, toast } from 'react-toastify';
import ThankYouPage from './thank-you';
import C24Logo from './images/c24_logo.svg';
// import SettingIcon from './images/settings.svg';
import OrangeTickIcon from './images/orange_tick.svg';
import GreenTickIcon from './images/green_tick.svg';
import {SELL_ONLINE_STATUS, MAIN_QUESTION} from '../../utils/app-constants';
// import mockdata from './mockdata.json';
import ArrowDown from '../../components/arrow/arrow-down';
import ArrowUp from '../../components/arrow/arrow-up';
import {saveToLocalStorage} from '../../utils/helper';
import {LOCAL_STORAGE_KEYS} from '../../utils/app-constants';

const SellOnline = () => {
    const urlParams =  new URLSearchParams(window.location.search);
    const appointmentId = urlParams.get('appointmentId');
    const [data, setData ] = useState({});
    const [reviewDataItems, setReviewDataItems] = useState([]);
    const [mainQuestionList, setMainQuestionList] = useState([]);
    const [errorMessage, setErrorMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
    const [currentStatus, setCurrentStatus] = useState(null);
    const {
        customerName,
        offeredPrice,
        onlinePrice,
        additionalComments,
        make,
        model, 
        variant,
        year,
        gsNoGo,
        conditionFlag,
        inspectionType,
        status
    } = data;

    useEffect(() => {
        saveToLocalStorage(LOCAL_STORAGE_KEYS.ROLE, "qc");
    }, []);

    useEffect(() => {
        if(status === "pending") {
            let params = {
                status: SELL_ONLINE_STATUS.OPEN,
            };
            InspectionService.postStatusSellOnline(appointmentId, params).then(response => {
                console.log(response);
            }).catch(error => {
                console.log(error);
            });
        }
    }, [status]);
    
    const isPriceAvailable = offeredPrice && onlinePrice;
    const getAppointmentDetails = useCallback(() => {
        setIsLoading(true);
        if(!appointmentId) {
            setIsLoading(false);
            setErrorMessage('AppointmentId not found');
            return;
        }
        InspectionService.getSellOnline(appointmentId).then(response => {
            setIsLoading(false);
            if(response.status === 200) {
                setData(response.data.detail);
                if(response.data.detail && response.data.detail.reviewData) {
                    const question = {...response.data.detail.reviewData};
                    const additionalQue = {
                        [MAIN_QUESTION.towing_required]: question[MAIN_QUESTION.towing_required],
                        [MAIN_QUESTION.isaccidental]: question[MAIN_QUESTION.isaccidental],
                        [MAIN_QUESTION.carLookFromOutside]: question[MAIN_QUESTION.carLookFromOutside],
                        [MAIN_QUESTION.hasrust]: question[MAIN_QUESTION.hasrust]
                    };
                    setMainQuestionList(Object.values(additionalQue).filter(Boolean));
                    delete question[MAIN_QUESTION.towing_required];
                    delete question[MAIN_QUESTION.isaccidental];
                    delete question[MAIN_QUESTION.carLookFromOutside];
                    delete question[MAIN_QUESTION.hasrust];

                    setReviewDataItems(Object.values(question));
                }
            }
            
        }).catch(error => {
            console.log('error::',error);
            setIsLoading(false);
            if(error.detail) {
                setErrorMessage(error.detail);
            } else if (error.error) {
                setErrorMessage(error.error);
            }
        });
    });
    
    useEffect(() => {
        getAppointmentDetails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSubmitYes = () => {
        setIsLoadingSubmit(true);
        const requestParam = {
            status: SELL_ONLINE_STATUS.APPROVED
        };
        InspectionService.postStatusSellOnline(appointmentId, requestParam).then(response => {
            if(response.status === 200) {
                toast.success(response.data.detail);
                setCurrentStatus(SELL_ONLINE_STATUS.APPROVED);
            }
            setIsLoadingSubmit(false);
        }).catch(error => {
            console.log('Error::', error);
            setIsLoadingSubmit(false);
            if(error.detail) {
                toast.error(error.detail);
            }
        });
    };

    const handleSubmitNo = () => {
        setIsLoadingSubmit(true);
        const requestParam = {
            status: SELL_ONLINE_STATUS.REJECTED
        };
        InspectionService.postStatusSellOnline(appointmentId, requestParam).then(response => {
            if(response.status === 200) {
                toast.success(response.data.detail);
                setCurrentStatus(SELL_ONLINE_STATUS.REJECTED);
            }
            setIsLoadingSubmit(false);
        }).catch(error => {
            console.log('Error::', error);
            setIsLoadingSubmit(false);
            if(error.detail) {
                toast.error(error.detail);
            }
        });
    };
    
    const displayAmount = amount => amount.toLocaleString();

    const toggleCollapsFlag = index => {
        const {isCollapsable} = reviewDataItems[index];
        if(isCollapsable) {
            reviewDataItems[index].isCollapsable = false;
        } else {
            reviewDataItems[index].isCollapsable = true;
        }
        setReviewDataItems([...reviewDataItems]);
    };

    const toggleCollapsFlagForMainQuestion = index => {
        const {isCollapsable} = mainQuestionList[index];
        if(isCollapsable) {
            mainQuestionList[index].isCollapsable = false;
        } else {
            mainQuestionList[index].isCollapsable = true;
        }
        setMainQuestionList([...mainQuestionList]);
    };

    const rendersSubDetails = detail => {
        const allkeys = Object.keys(detail);
        return (
            <div className={styles.subDetailsWrapper}>
                {allkeys.map(item => (
                    <div className={styles.subDetails}>
                        <h3>{item}</h3>
                        <div>
                            {detail[item].map(detailItem => (
                                <h3 className={styles.detailValue}>{detailItem}</h3>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        );
    };

    const renderAdditionalComments = () => {
        return (
            <>
                <div className={styles.commentContainer}>
                    {(conditionFlag == 4 || inspectionType == "B2B") ? (
                        <h2 className={styles.title}>We will communicate the final price shortly!</h2>
                    ): (
                        <>
                            <h2 className={styles.title}>Uh ho! Instant online offer not available.</h2>
                            <p className={styles.subTitle}>Due to below critical flaws identified by the evaluator, your car is not eligible for instant online offer</p>
                        </>
                    )}
                    {additionalComments?.map(comment => (
                        <div className={styles.commentWrapper}>
                            <p className={styles.comment}>{comment}</p>
                            <p className={styles.yesValue}>Yes</p>
                        </div>
                    ))}
                </div>
                <div className={styles.detailsSection}>
                    {(conditionFlag == 4 || inspectionType == "B2B") && (
                        <h2 className={styles.title}>Our evaluator made a few changes to your report, please verify</h2>
                    )}
                    {
                        mainQuestionList.map(({title, CjInput, sellerInput, detail, isCollapsable}, index) => (
                            <div className={styles.itemWrapper}>
                                <div className={styles.titleWrapper}>
                                    <h3>{title}</h3>
                                    {detail && (isCollapsable ? <ArrowUp onClick={() => toggleCollapsFlagForMainQuestion(index)} />
                                        :<ArrowDown onClick={() => toggleCollapsFlagForMainQuestion(index)} />)}
                                </div>
                                <div className={styles.subTitleWrapper}>
                                    <span>Your Response</span>
                                    <span>Evaluator's Response</span>
                                </div>
                                <div className={styles.valueWrapper}>
                                    <h3 className={sellerInput === CjInput ? styles.greenValue : styles.yourValue}>{sellerInput}</h3>
                                    <h3 className={sellerInput === CjInput ? styles.greenValue : styles.evaluatorValue}>{CjInput}</h3>
                                </div>
                                {isCollapsable && detail && rendersSubDetails(detail)}
                            </div>
                        ))
                    }
                </div>
            </>
        );
    };

    return (
        isLoading ? <Loader/> : 
            <div className={styles.sellOnlineProcess}>
                <div className={styles.logo}>
                    <img src={C24Logo} />
                </div>
                {isLoadingSubmit && <Loader />}
                {
                    !errorMessage ?
                        <>
                            {[SELL_ONLINE_STATUS.APPROVED, SELL_ONLINE_STATUS.REJECTED].includes(currentStatus) ? 
                                <ThankYouPage status={currentStatus} />
                                : (
                                    <>
                                        
                                        <div className={styles.inspectionTitle}>
                                            <img src={isPriceAvailable ? GreenTickIcon : OrangeTickIcon} alt="sign" />
                                            <h1>Inspection Completed</h1>
                                            {/* <p>Please review the changes made by our evaluator to proceed</p> */}
                                        </div>
                                        <> 
                                            <div className={styles.infoWrapper}>
                                                <div>
                                                    <span className={styles.customerName}>{customerName}</span>
                                                    <span className={styles.appointmentIdLabel}>Appointment ID</span>
                                                </div>
                                                <div>
                                                    <span className={styles.appointmentId}>
                                                        {make && <span>{make} </span>}
                                                        {model && <span>{model} </span>}
                                                        {variant && <span>{variant} </span>}
                                                        {year && <span>{year}</span>}
                                                    </span>
                                                    <span className={styles.appointmentId}>{appointmentId}</span>
                                                </div>
                                                {/* <span className={styles.appointmentId}>Appt Id: {appointmentId}</span> */}
                                                
                                            </div>
                                            {gsNoGo === 0 && (
                                                <div className={styles.commentContainer}>
                                                    <h2 className={styles.title}>Uh ho! Instant online offer not available.</h2>
                                                    <p className={styles.subTitle}>Due to some issues in your car or documents, we won't be able to buy your car. However, we have got option for you to sell your car.</p>
                                                </div>
                                            )}
                                            {!isPriceAvailable && ((additionalComments  && !!additionalComments.length) || !!mainQuestionList.length) && renderAdditionalComments()}
                                            {/* {isLoadingSubmit && <Loader />} */}
                                            {(conditionFlag != 4 || inspectionType != "B2B") && (
                                                <>
                                                    {offeredPrice && onlinePrice ? (
                                                        <>
                                                            <h2 className={styles.title}>Updated Offer Price </h2>
                                                            <div className={styles.priceSection}>
                                                                <ul>
                                                                    <li>
                                                                        <h3>Previous Price</h3>
                                                                        <h3>{displayAmount(offeredPrice)}</h3>
                                                                    </li>
                                                                    <li>
                                                                        <h2>Updated Price</h2>
                                                                        <h2>{displayAmount(onlinePrice)}</h2>
                                                                    </li>
                                                                </ul>
                                                                <p className={styles.updatedPriceText}>Price has been updated due to changes in your report below</p>
                                                            </div>
                                                        </>
                                                    ): (
                                                        <div className={styles.eligibleSection}>
                                                            <h3>Hold on! We will auction your car.</h3>
                                                            {gsNoGo === 0 ? (
                                                                <p>We will come back in one hour, after getting the best price from the market via auction.</p>
                                                            ) : 
                                                                (<>
                                                                    <p>Your car is not eligible for instant online offer.</p>
                                                                    <p>Our Retail Associate will call you within an hour. </p>
                                                                </>)}
                                                        </div>
                                                    )}
                                                </>
                                            )}
                                            
                                            {reviewDataItems && !!reviewDataItems.length && (<>
                                                {(conditionFlag != 4 || inspectionType != "B2B") && (
                                                    <h2 className={styles.title}>Our evaluator made a few changes to your report, please verify</h2>
                                                )}
                                                <div className={styles.detailsSection}>
                                                    {
                                                        reviewDataItems.map(({title, CjInput, sellerInput, detail, isCollapsable}, index) => (
                                                            <div className={styles.itemWrapper}>
                                                                <div className={styles.titleWrapper}>
                                                                    <h3>{title}</h3>
                                                                    {detail && (isCollapsable ? <ArrowUp onClick={() => toggleCollapsFlag(index)} />
                                                                        :<ArrowDown onClick={() => toggleCollapsFlag(index)} />)}
                                                                </div>
                                                                <div className={styles.subTitleWrapper}>
                                                                    <span>Your Response</span>
                                                                    <span>Evaluator's Response</span>
                                                                </div>
                                                                <div className={styles.valueWrapper}>
                                                                    <h3 className={sellerInput === CjInput ? styles.greenValue : styles.yourValue}>{sellerInput}</h3>
                                                                    <h3 className={sellerInput === CjInput ? styles.greenValue : styles.evaluatorValue}>{CjInput}</h3>
                                                                </div>
                                                                {isCollapsable && detail && rendersSubDetails(detail)}
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </>)}
                                          
                                            {gsNoGo !== 0 && (<p className={styles.clarificationText}>Please contact car evaluator for any clarification</p>)}
                                            {gsNoGo !== 0 && (<p className={styles.satisfiedText}>Do you approve the inspection changes?</p>)}
                                            <div className={styles.ctaWrapper }>
                                                <Button 
                                                    onClick={handleSubmitYes}  
                                                    classNames={`primaryButton ${styles.gradient}`} 
                                                    ctaText={gsNoGo === 0 ? "PROCEED" : "YES, I APPROVE THE CHANGES"} 
                                                    disabled={isLoadingSubmit} 
                                                />
                                                {gsNoGo !== 0 && (
                                                    <Button 
                                                        onClick={handleSubmitNo}  
                                                        classNames="borderOutfitButton" 
                                                        ctaText="NO, SEND FOR CORRECTION" 
                                                        disabled={isLoadingSubmit} 
                                                    />
                                                )}
                                                
                                            </div>
                                        </>
                                    </>
                                )
                            }
                        </>:
                        <div className={styles.errorMessage}>{errorMessage}</div>
                }
                <ToastContainer />
            </div>
    );
};
export default SellOnline;


import React, {useEffect, useState} from "react";
import {RESET_REDUCER_KEYS} from "../../utils/app-constants";
import moment from "moment";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Grid from "@material-ui/core/Grid";

const RoadTaxDateValidity = ({detail,inspectionDetails,resetInspectionDetailByKeyConnect}) => {
    const {value,key} = detail;
    const {documentsMapper} = inspectionDetails;

    const [dateValue,setDateValue] = useState("");
    const [selectedDate, setSelectedDate] = useState(null);

    useEffect(()=>{
        if(dateValue){
            setNewValue();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[dateValue]);

    useEffect(()=>{
        if(value) {
            const date_arr = value.split("-");
            const fitnessDate = `${date_arr[2]}-${date_arr[1]}-${date_arr[0]}`;
            setSelectedDate(new Date(fitnessDate));

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[value]);

    const setNewValue = ()=>{
        let mapper = [...documentsMapper];
        const newValue = dateValue;
        mapper = mapper.map(map => {
            let element = { ...map };
            if (map.key === key) {
                element.newValue = newValue;
                element.newId = newValue;
                // element.checked = false;
            }

            return element;
        });
        resetInspectionDetailByKeyConnect(RESET_REDUCER_KEYS.DOCUMENTS_MAPPER, mapper);
    };

    const handleDateChange = date => {
        const formattedDate = moment(date).format("DD-MM-YYYY").toString();
        setSelectedDate(date);
        setDateValue(formattedDate);
    };

    return (
        <div className="mb-5">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container>
                    <KeyboardDatePicker
                        placeholder="Select Date"
                        value={selectedDate}
                        onChange={date => handleDateChange(date)}
                        format="dd/MM/yyyy"
                    />
                </Grid>
            </MuiPickersUtilsProvider>
        </div>
    );
};

export default RoadTaxDateValidity;

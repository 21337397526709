import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import InspectionReviewBike from "./component";
import { fetchBikeInspectionDetail, resetInspectionBikeDetail, resetInspectionBikeDetailByKey } from "./actions";

const mapStateToProps = ({
    bikeInspectionDetails
}) => ({
    bikeInspectionDetails
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchBikeInspectionDetailConnect: fetchBikeInspectionDetail,
    resetInspectionBikeDetailConnect: resetInspectionBikeDetail,
    resetInspectionBikeDetailByKeyConnect: resetInspectionBikeDetailByKey
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(InspectionReviewBike);

import React, { useState, useEffect } from "react";
import Header from "../../components/header";
import Back from "../../components/back";
import styles from "./gs-refurbishment-version.module.css";
import { getFromLocalStorage } from "../../utils/helper";
import { LOCAL_STORAGE_KEYS, ROLE_KEYS, NUMBERS } from "../../utils/app-constants";
import moment from "moment";
import Searchicon from "../../components/images/search.svg";
import {InspectionService} from '../../service';
import Loader from '../../components/loader';

const GSRefurbishmentVersion = ({ history, match }) => {
    const localData = getFromLocalStorage(LOCAL_STORAGE_KEYS.LOGIN_DATA);
    let role = localData ? JSON.parse(localData)[LOCAL_STORAGE_KEYS.ROLE] : "";
    const [data, setData] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    const [isFetching, setIsFetching] = useState(false);
    const { params: { appointment } } = match;
    const [appointmentId, setAppointmentId] = useState(appointment); // 1009095191 1001095991
    const getGSRefurbishmentVersionData = () => {
        setIsFetching(true);
        setData(null);
        setErrorMessage('');
        InspectionService.getRefurbishmentVersionList(appointment).then(response => {
            setIsFetching(false);
            setData(response.data.detail);
        }).catch(error => {
            setIsFetching(false);
            console.log('Error::',error);
            setErrorMessage(error.message || error);
        });
    };
    useEffect(() => {
        if(appointment) {
            getGSRefurbishmentVersionData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[appointment]);
  
    const versionSelection = (version, status) => {
        history.push({pathname:`/gs-refurbishment-review/${appointmentId}?version=${version}&status=${status}`});
    };

    const onChangeHandler = (event) => {
        setAppointmentId(event.target.value);
    };

    const onSearchKeyPress = (e) => {
        const { charCode } = e;
        if (charCode === NUMBERS.THIRTEEN) {
            onSearchClick();
        }
    };
  
    const onSearchClick = () => {
        if(appointmentId && !isFetching) {
            history.push({pathname: `/gs-refurbishment-version/${appointmentId}`});
        }
    };

    return (
        <div className={styles.inspectionVersion}>
            <Header showVersion={true} history={history} />
            <div className={styles.backSearchWrapper}>
                <Back history={history} versionFlag={true} />
                <div className={styles.search}>
                    <input 
                        type="text" 
                        name="search" 
                        placeholder="Appointment ID" 
                        onChange={onChangeHandler} 
                        value={appointmentId} 
                        onKeyPress={onSearchKeyPress} 
                        autoComplete="off" 
                    />
                    {/* {isSearchedData && <button onClick={clearSearch}>x</button>} */}
                    <button>
                        <img src={Searchicon} alt="search" onClick={onSearchClick} />
                    </button>
                </div>
            </div>
            {errorMessage && <span className={styles.errorMessage}>{errorMessage}</span>}
            {isFetching && <Loader />}
            {
                data && <React.Fragment>
                    {data.length > 0 ? <div className={styles.dataTable}>
                        <table cellPadding="0" cellSpacing="0" width="100%">
                            <thead>
                                <tr>
                                    <th>Make</th>
                                    <th>Model</th>
                                    <th>Version</th>
                                    <th>Inspection Status</th>
                                    <th>Refurbished by</th>
                                    <th>InspectionEndTime</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    data && data.length > 0 &&
                              data.map(item => {
                                  return <tr onClick={(role === ROLE_KEYS.QC || role === ROLE_KEYS.QC_VERSION) ? () => { versionSelection(item.version, item.status); } : ""}>
                                      <td>{item.make}</td>
                                      <td>{item.model}</td>
                                      <td>{item.version}</td>
                                      <td>{item.status}</td>
                                      <td>{item.refurbishedBy}</td>
                                      <td>{item.endTime}</td>
                                  </tr>;
                              })
                                }
                            </tbody>
                        </table>
                    </div>
                        : <span className={styles.noDataFound}>Version records not available for this appointment</span>
                    }
                </React.Fragment>
            }
        </div>
    );
};

export default GSRefurbishmentVersion;
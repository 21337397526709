import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import AdditionalImages from "./component";

const mapStateToProps = ({inspectionDetails}) => ({
    inspectionDetails
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AdditionalImages);

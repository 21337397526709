import React, { useEffect, useState } from "react";
import { LOAN_STATUS_OPTIONS, RESET_REDUCER_KEYS } from "../../utils/app-constants";
import SelectComponent from "../shared/select";

const dropdownOptions = Object.values(LOAN_STATUS_OPTIONS).map(op => ({ label: op, value: op }));

const LoanStatusEdit = ({ detail, inspectionDetails, resetInspectionDetailByKeyConnect }) => {
  const [selectedValue, setSelectedValue] = useState("");
  const { key } = detail;
  const { documentsMapper } = inspectionDetails;

  useEffect(() => {
    if (selectedValue) {
      setNewValue();
    }
}, [selectedValue]);

  const setNewValue = () => {
    let mapper = [...documentsMapper];
    const newValue = selectedValue;
    let dependentFields = [];
    mapper = mapper.map(map => {
      let element = { ...map };
      if (map.key === key) {
        let newSelectedVal = "";
        let objNewSeleted = dropdownOptions.find(x => x.value === newValue);
        if (!!objNewSeleted) {
          newSelectedVal = objNewSeleted.label;
        }
        element.newValue = newSelectedVal;
        element.newId = newValue;
        // element.checked = false;
        if (element.dependentFields && element.dependentFields.length > 0) {
          dependentFields = element.dependentFields;
        }
      }

      return element;
    });

    mapper = mapper.map(map => {
      let element = { ...map };
      if (dependentFields.find(field => field === element.key)) {
        element.checked = true;
      }

      return element;
    });

    resetInspectionDetailByKeyConnect(RESET_REDUCER_KEYS.DOCUMENTS_MAPPER, mapper);
  };

  return (
    <div>
      <SelectComponent
        optionsList={dropdownOptions}
        placeholder={detail.text}
        onChange={val => setSelectedValue(val)}
      />
    </div>
  );
};

export default LoanStatusEdit;

import React from "react";
import {CHASSIS_EMBOSSING, DETAIL_MISMATCH, RESET_REDUCER_KEYS} from "../../utils/app-constants";
import {Multiselect} from "multiselect-react-dropdown";

const MismatchInRc = ({resetInspectionDetailByKeyConnect,detail,inspectionDetails}) => {
    const {documentsMapper} = inspectionDetails;
    const {key} = detail;

    const setNewValue =(optionsList)=> {
        let result=[];
        optionsList.map(option=>{
            result.push(option.name);
        });
        const newValue = result;
        let mapper = [...documentsMapper];
        mapper = mapper.map(map => {
            let element = {...map};
            if (map.key === key) {
                element.newValue = newValue.join(",");
                element.newId = newValue;
                // element.checked = false;
            }

            return element;
        });

        resetInspectionDetailByKeyConnect(RESET_REDUCER_KEYS.DOCUMENTS_MAPPER, mapper);
    };

    const onSelect=(optionsList, selectedItem)=>{
        const okIndex = optionsList.findIndex(key => key.id === "No Mismatch");
        if(selectedItem.id === "No Mismatch" || okIndex !== -1) {
            optionsList.length = 0; 
            optionsList.push(selectedItem);
        }
        setNewValue(optionsList);
    };

    const onRemove=(optionList, removedItem)=>{
        setNewValue(optionList);
    };

    return (
        <div>
            <Multiselect
                options={DETAIL_MISMATCH} // Options to display in the dropdown
                selectedvalues={[]} // Preselected value to persist in dropdown
                onSelect={onSelect} // Function will trigger on select event
                onRemove={onRemove} // Function will trigger on remove event
                displayValue="name" // Property name to display in the dropdown options
            />
        </div>
    );
};

export default MismatchInRc;

import React, { useEffect, useState } from "react";
import styles from "./inspection-review-bike-info.module.css";
import Button from "../shared/button/component";
import SelectComponent from "../shared/select/component";
import { InspectionService } from "../../service";
import { showToastMessages, getFromLocalStorage } from "../../utils/helper";
import { LOCAL_STORAGE_KEYS } from "../../utils/app-constants";

const InspectionReviewBikeInfo = ({
    bikeInspectionDetails,
    resetInspectionBikeDetailByKeyConnect,
    versionViewMode = true
}) => {

    const { bikeMakeModelVariantMapper, appointmentId, bikeId, qcUser } = bikeInspectionDetails;
    const email = getFromLocalStorage(LOCAL_STORAGE_KEYS.EMAIL);

    const [make, setMake] = useState("");
    const [model, setModel] = useState("");
    const [modelList, setModelList] = useState([]);
    const [variant, setVariant] = useState("");

    const [initialMake, setMakeInitial] = useState("");
    const [intitialModel, setModelInitial] = useState("");
    const [initialVariant, setVariantInitial] = useState("");

    const [makeDropDown, setMakeDropDown] = useState([]);
    const [modelDropDown, setModelDropDown] = useState([]);
    const [variantDropDown, setVariantDropDown] = useState([]);

    const [selectedMakeLabel, setSelectedMakeLabel] = useState("");
    const [selectedModelLabel, setSelectedModelLabel] = useState("");
    const [selectedVariantLabel, setSelectedVariantLabel] = useState("");

    const [selectedMake, setSelectedMake] = useState("");
    const [selectedModel, setSelectedModel] = useState("");
    const [selectedVariant, setSelectedVariant] = useState("");

    const [buttonActive, setButtonActive] = useState(false);
    const [makeModelChange, setMakeModelChange] = useState(false);

    const [makeDefaultObj, setmakeDefaultObj] = useState({});
    const [modelDefaultObj, setmodelDefaultObj] = useState({});
    const [variantDefaultObj, setvariantDefaultObj] = useState({});

    const [showUpdatedValue, setShowUpdatedValue] = useState(false);
    const [show, setShow] = useState(false);

    useEffect(() => {
        InspectionService.getBikeMake()
            .then(res => {
                const { data: { detail } } = res;
                if (detail.length > 0) {
                    setMakeDropDown(detail.map(make => {
                        return {
                            label: make.name,
                            value: make.code
                        };
                    }));
                }
            })
            .catch(err => {
            });

        return () => {
            setSelectedMake("");
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (selectedMake) {
            InspectionService.getBikeModel(selectedMake)
                .then(response => {
                    const { data: { detail } } = response;
                    setModelList(detail);
                    setModelDropDown(detail.map(model => {
                        return {
                            label: model.name,
                            value: model.id,
                        };
                    }));
                }).catch(error => {
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedMake]);

    useEffect(() => {
        const modelIten = modelList.filter(model => model.id === selectedModel)[0];
        const variantList = modelIten && JSON.parse(modelIten.variant) || null;
        if(variantList && variantList.length) {
            setVariantDropDown(variantList.map(model => {
                return {
                    label: model.name,
                    value: model.code
                };
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedModel]);
    useEffect(() => {
        if (bikeMakeModelVariantMapper.length > 0) {
            setMakeInitial(bikeMakeModelVariantMapper[0].value);
            setModelInitial(bikeMakeModelVariantMapper[1].value);
            setVariantInitial(bikeMakeModelVariantMapper[2].value);

            setMake(bikeMakeModelVariantMapper[0].newValue ? bikeMakeModelVariantMapper[0].newValue : bikeMakeModelVariantMapper[0].value);
            // setSelectedMake(bikeMakeModelVariantMapper[0].newId ? bikeMakeModelVariantMapper[0].newId : bikeMakeModelVariantMapper[0].id);
            setModel(bikeMakeModelVariantMapper[1].newValue ? bikeMakeModelVariantMapper[1].newValue : bikeMakeModelVariantMapper[1].value);
            setSelectedModel(bikeMakeModelVariantMapper[1].newId ? bikeMakeModelVariantMapper[1].newId : bikeMakeModelVariantMapper[1].id);

            setVariant(bikeMakeModelVariantMapper[2].newValue ? bikeMakeModelVariantMapper[2].newValue : bikeMakeModelVariantMapper[2].value);
            setSelectedVariant(bikeMakeModelVariantMapper[2].newId ? bikeMakeModelVariantMapper[2].newId : bikeMakeModelVariantMapper[2].id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bikeMakeModelVariantMapper]);

    const showDropDowns = () => {
        const values = [
            {
                key: "make",
                label: selectedMakeLabel,
                id: selectedMake
            },
            {
                key: "model",
                label: selectedModelLabel,
                id: selectedModel
            },
            {
                key: "variant",
                id: selectedVariant,
                label: selectedVariantLabel
            }
        ];
        let foundEmptyValue = values.filter(x => x.id === "");
        if (!!foundEmptyValue && foundEmptyValue.length > 0) {
            alert("Select all the fields");
        }
        else {
            setMakeModelChange(false);
            setNewValues(values);
        }
    };

    const setNewValues = (values) => {
        let mapper = [...bikeMakeModelVariantMapper];
        values.map(field => {
            mapper = mapper.map(map => {
                let element = { ...map };
                if (map.key === field.key) {
                    element.newValue = field.label;
                    element.newId = field.id;
                    element.checked = true;
                }

                return element;
            });

            resetInspectionBikeDetailByKeyConnect("bikeMakeModelVariantMapper", mapper);
            setShowUpdatedValue(true);
            setShow(!show);
            setButtonActive(false);
        });
        pushAppointment(mapper);
    };
    const pushAppointment = (mapperFormakemoodel) => {
        const pushData = {
            "make": mapperFormakemoodel[0].newValue,
            "model": mapperFormakemoodel[1].newValue,
            "variant": mapperFormakemoodel[2].newValue,
        };
        InspectionService.pushAppoinetmentData(pushData, appointmentId)
            .then(res => {
                showToastMessages("Make Model Variant updated");
            })
            .catch(e => {
                showToastMessages(`Error in submit review - ${!!e.message ? e.message : ""}`, false);
            });
    };
    const onMakeChange = (option, label) => {
        setMakeModelChange(true);
        setSelectedMake(option);
        setSelectedMakeLabel(label);
        setSelectedModel("");
        setSelectedVariant("");
        setSelectedVariantLabel("");
        setmodelDefaultObj({});
        setvariantDefaultObj({});
        setButtonActive(true);
    };
    const onModelChange = (option, label) => {
        setMakeModelChange(true);
        setSelectedModel(option);
        setSelectedModelLabel(label);
        setSelectedVariant("");
        setSelectedVariantLabel("");
        setvariantDefaultObj({});
        setButtonActive(true);
    };
    const onVariantChange = (option, label) => {
        setMakeModelChange(true);
        setSelectedVariant(option);
        setSelectedVariantLabel(label);
        setButtonActive(true);
    };
    return (
        <div className={styles.inspectionReviewCarInfo}>
            <div className={styles.inspectionText}>
                {
                    <div className={styles.infoChange}>
                        <div>
                            <div className={styles.inspectionInfoWrapper}>
                                <p className={styles.inspectionInfo}>
                                    <span>{`${initialMake}`}</span>
                                    <span>{`${intitialModel}`}</span>
                                    <span className={styles.variantBold}>{`${initialVariant}`}</span>
                                </p>
                            </div>
                            {
                                showUpdatedValue &&
                                <div>
                                    <div className={styles.inspectionInfoWrapper}>
                                        <p className={styles.change}>
                                            Changed To <span>{`${make} ${model}  ${variant}  `}</span></p>
                                    </div>
                                </div>
                            }
                        </div>
                        {versionViewMode &&
                            <Button classNames="grayButton" ctaText={"Change"} onClick={() => {
                                setShow(!show);
                                setButtonActive(false);
                            }} />
                        }
                    </div>

                }
            </div>

            {
                show && <div className={styles.makeModelVariantYear}>
                    <div className={styles.filters}>
                        <div className={styles.boxSelect}>
                            <SelectComponent
                                optionsList={makeDropDown}
                                placeholder="Make"
                                // defaultValue={{ label: "BMW", value: 5 }}
                                defaultValue={makeDefaultObj}
                                onChange={onMakeChange}
                            />
                        </div>
                        <div className={styles.boxSelect}>
                            <SelectComponent
                                optionsList={modelDropDown}
                                placeholder="Model"
                                name={selectedMake}
                                defaultValue={modelDefaultObj}
                                onChange={onModelChange}
                            />
                        </div>
                        <div className={styles.boxSelect}>
                            <SelectComponent
                                placeholder="Variant"
                                optionsList={variantDropDown}
                                onChange={onVariantChange}
                                name={selectedModel}
                                defaultValue={variantDefaultObj}
                                isSearchable={true}
                            />
                        </div>
                    </div>
                    <div>
                        {
                            (email === qcUser) && <Button classNames={!buttonActive ? "secondaryButton" : "primaryButton"}
                                ctaText={"save changes"} disabled={!buttonActive} onClick={showDropDowns} />
                        }
                    </div>
                </div>
            }
        </div>
    );
};

export default InspectionReviewBikeInfo;

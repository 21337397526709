import React, { useRef, useEffect, useState } from "react";
import 'tui-image-editor/dist/tui-image-editor.css';
import ImageEditor from '@toast-ui/react-image-editor';
import Button from "../../components/shared/button";
import styles from "./inspection-poc-popup.module.css";
import { thumborUrl } from "../../utils/url-constants";
import { convertImageToBase64, getFromLocalStorage, showToastMessages } from "../../utils/helper";
import { LOCAL_STORAGE_KEYS } from "../../utils/app-constants";
import { InspectionService } from "../../service";
import moment from "moment";
import Loader from "../../components/loader";
import Maskinglogo from "../images/Masking.png";
import { INSPECTION_FLOW, maskingTheme } from "../../utils/app-constants";

const InspectionPocPopup = ({
    mapper,
    index,
    inspectionDetails,
    currentMapper,
    resetInspectionDetailByKeyConnect,
    close,
    inspectionFlow,
    bikeInspectionDetails,
    resetInspectionBikeDetailByKeyConnect,
    isBikeQc
}) => {
    // const existingMapper = inspectionDetails[currentMapper];
    const { carId, inspectionStatus } = inspectionDetails;
    const { bikeId, appointmentId } = bikeInspectionDetails;
    const role = getFromLocalStorage(LOCAL_STORAGE_KEYS.ROLE);
    // let { imagequalityParam } = !!getFromLocalStorage(LOCAL_STORAGE_KEYS.IMAGE_QUALITY_PARAM) ? getFromLocalStorage(LOCAL_STORAGE_KEYS.IMAGE_QUALITY_PARAM) : ""; //inspectionDetails;
    let imagequalityParam = !!getFromLocalStorage(LOCAL_STORAGE_KEYS.IMAGE_QUALITY_PARAM) ? getFromLocalStorage(LOCAL_STORAGE_KEYS.IMAGE_QUALITY_PARAM) : ""; //inspectionDetails;

    if (!!imagequalityParam) {
        imagequalityParam = imagequalityParam.replace("?", "&");
    }
    const editorRef = useRef();
    const cdnBaseUrl =  getFromLocalStorage(LOCAL_STORAGE_KEYS.IMAGE_HOST_URL) || thumborUrl();
    let url = !isBikeQc && `${cdnBaseUrl}${mapper[index].url}?v=1${imagequalityParam}`;
    if (!!mapper[index].originalUrl) {
        url = `${cdnBaseUrl}${mapper[index].originalUrl}?v=1${imagequalityParam}`;
    }
    if (currentMapper === "additionalImagesMapper") {
        if (!!mapper[index].image) {
            url = `${cdnBaseUrl}${mapper[index].image.url}?v=1${imagequalityParam}`;
            if (!!mapper[index].image.originalUrl) {
                url = `${cdnBaseUrl}${mapper[index].image.originalUrl}?v=1${imagequalityParam}`;
            }
        }
        // 
    }

    if (currentMapper === "additionalBikeImagesMapper" && !!mapper[index].uri) {
        url = `${cdnBaseUrl}${mapper[index].uri}?v=1`;
    }

    const [newImageSrc, setNewImageSrc] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");
    useEffect(() => {
        let images = document.getElementsByClassName("tui-image-editor-button apply");
        for (var i = images.length - 1; i >= 0; i--) {
            images[0].parentNode.removeChild(images[0]);
        }
        let imageEditor = editorRef.current.imageEditorInst;

        //// Code Commented as the hardocded images can be replaced with 
        // var file = dataURLtoFile(MASKING_IMAGE_BASE_64, 'cars24.png');
        // let fileObject = {};
        // fileObject.target = {};
        // fileObject.target.files = [file];
        // setTimeout(() => {
        //     imageEditor.ui.mask.eventHandler.loadMaskFile(df);
        // }, 4000);
        let interval;
        try {
            convertImageToBase64(Maskinglogo, (response) => {
                let fullbase64str = response;
                var file = dataURLtoFile(fullbase64str, 'cars24.png');
                let fileObject = {};
                fileObject.target = {};
                fileObject.target.files = [file];
                interval = setInterval(() => {
                    if (!!imageEditor.ui.mask.eventHandler.loadMaskFile && typeof (imageEditor.ui.mask.eventHandler.loadMaskFile) === "function") {
                        imageEditor.ui.mask.eventHandler.loadMaskFile(fileObject);
                        clearInterval(interval);
                    }
                }, 500);
            });
        }
        catch (exception) {
            console.log(exception);
            clearInterval(interval);
        }
        return () => {
            clearInterval(interval);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    function dataURLtoFile(dataurl, filename) {

        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, { type: mime });
    }
    const b64toBlob = (b64Data, contentType, sliceSize) => {
        contentType = contentType || '';
        sliceSize = sliceSize || 512;

        var byteCharacters = atob(b64Data);
        var byteArrays = [];

        for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            var slice = byteCharacters.slice(offset, offset + sliceSize);

            var byteNumbers = new Array(slice.length);
            for (var i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            var byteArray = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);
        }

        var blob = new Blob(byteArrays, { type: contentType });
        return blob;
    };

    const getFileExtension = () => {
        let fileExtension = "jpeg";
        if (currentMapper === "additionalImagesMapper") {
            if (!!mapper[index].image) {
                fileExtension = mapper[index].image.url.split('.')[1];
            }
            // 
        } else if(currentMapper === "additionalBikeImagesMapper" && !!mapper[index].uri ) {
            fileExtension = mapper[index].uri.split('.')[1];
        } else {
            fileExtension = mapper[index].url.split('.')[1];
        }
        return fileExtension;
    };
    const getImageUrl = () => {
        let imageUrl = "";
        if (currentMapper === "additionalImagesMapper") {
            if (!!mapper[index].image) {
                imageUrl = mapper[index].image.url;
            }
            // 
        }
        else {
            imageUrl = mapper[index].url;
        }
        return imageUrl;
    };
    const uploadImageForBike = () => {
        setIsLoading(true);
        const editorInstance = editorRef.current.getInstance();
        // editorInstance.flipX();
        var options = {};
        options.format = "jpeg";//getFileExtension();//"jpeg";
        options.quality = .9;//1;
        let _imgurl = editorInstance.toDataURL(options);

        // Split the base64 string in data and contentType
        var block = _imgurl.split(";");
        // Get the content type
        var contentType = block[0].split(":")[1];// In this case "image/gif"
        // get the real base64 content of the file
        var realData = block[1].split(",")[1];// In this case "iVBORw0KGg...."

        // Convert to blob

        var blob = b64toBlob(realData, contentType);

        setNewImageSrc(_imgurl);
        let data = new FormData();
        data.append("imageFile", blob);
        // data.append("imageFile", realData);
        // data.append("appointmentId", appointmentId);
        data.append("type", mapper[index].type);
        data.append("title", mapper[index].title);
        data.append("hashKey", mapper[index].hashKey);
        data.append("fileExtension", 'jpeg');
        InspectionService.uploadBikeImage(data, appointmentId)
            .then(res => {
                setIsLoading(false);
                showToastMessages("Image updated successfully");
                const { data: { detail: { url } } } = res;
                if (currentMapper === "additionalBikeImagesMapper") {
                    mapper[index].uri = url;
                } else {
                    mapper[index].url = url;
                }
                // mapper[index].checked = true;
                resetInspectionBikeDetailByKeyConnect(currentMapper, mapper);
                close();
                // reloadWithDelay(500);
            })
            .catch(e => {
                setIsLoading(false);
                console.log("error", e);
                close();
                // mapper[index].checked = true;
                //resetInspectionDetailByKeyConnect(currentMapper, mapper);
                showToastMessages(e.detail, false);

            });
    };
    const handleClickButton = () => {
        if (inspectionFlow === INSPECTION_FLOW.BIKE) {
            uploadImageForBike();
        }
        else {
            setIsLoading(true);
            const editorInstance = editorRef.current.getInstance();
            // editorInstance.flipX();
            var options = {};
            options.format = "jpeg";//getFileExtension();//"jpeg";
            options.quality = .9;//1;
            let _imgurl = editorInstance.toDataURL(options);

            // Split the base64 string in data and contentType
            var block = _imgurl.split(";");
            // Get the content type
            var contentType = block[0].split(":")[1];// In this case "image/gif"
            // get the real base64 content of the file
            var realData = block[1].split(",")[1];// In this case "iVBORw0KGg...."

            // Convert to blob

            var blob = b64toBlob(realData, contentType);

            setNewImageSrc(_imgurl);
            let data = new FormData();
            data.append("imageFile", blob);
            // data.append("imageFile", realData);
            data.append("carId", carId);
            if (currentMapper === "additionalImagesMapper") {
                data.append("section", mapper[index].section);
            }
            else {
                data.append("section", "exteriorTyres");
            }
            if (currentMapper === "additionalImagesMapper") {
                data.append("type", "additionalInfo");
                // data.append("part", "engineBlowByBackCompression");
                // data.append("partTitle", "Engine Permissible Blow by (Back Compression)");
                // data.append("partValue", "No");
                //After discussion with Vinay on 24th April
                data.append("part", mapper[index].key);
                data.append("partTitle", mapper[index].title);
                data.append("partValue", mapper[index].value);

                if (mapper[index].hasOwnProperty('subPart_key')) {
                    data.append("subPart", mapper[index].subPart_key);
                }
                if (mapper[index].hasOwnProperty('subPart_title')) {
                    data.append("subPartTitle", mapper[index].subPart_title);
                }
                if (mapper[index].hasOwnProperty('subPart_value')) {
                    data.append("subPartValue", mapper[index].subPart_value);
                }
            }
            else {
                data.append("type", "mainImages");
            }
            data.append("hashKey", mapper[index].hashKey);
            data.append("title", mapper[index].title);
            data.append("role", role);
            if (currentMapper === "additionalImagesMapper") {
                data.append("field", "inspectionData_" + "cardetails_" + mapper[index].key);
            }
            else {
                data.append("field", mapper[index].field);
            }
            data.append("inspectionStatus", inspectionStatus);
            data.append("uploadTime", moment().diff(moment().startOf('day')));
            data.append("pointerCount", currentMapper === "additionalImagesMapper" ? mapper[index].image.pointerCount : mapper[index].pointerCount);
            data.append("isTilted", currentMapper === "additionalImagesMapper" ? mapper[index].image.isTilted : mapper[index].isTilted);
            // data.append("fileExtension", mapper[index].url.split('.')[1]);

            //this we have to check that how it works
            data.append("fileExtension", getFileExtension());
            data.append("oldImageUrl", getImageUrl());
            //After discussion with Vinay on 24th April
            // data.append("fileExtension", "png");

            InspectionService.uploadImage(data)
                .then(res => {
                    setIsLoading(false);
                    showToastMessages("Image updated successfully");
                    const { data: { detail: { url, oldUrl } } } = res;
                    if (currentMapper === "additionalImagesMapper") {
                        mapper[index].image.url = url;
                        mapper[index].image.oldUrl = oldUrl;
                        mapper[index].image.maskedBy = "QC";
                    } else {
                        mapper[index].url = url;
                        mapper[index].oldUrl = oldUrl;
                        mapper[index].maskedBy = "QC";
                    }
                    mapper[index].checked = true;
                    mapper[index].isMaskedByQc = true;
                    resetInspectionDetailByKeyConnect(currentMapper, mapper);
                    close();
                    // reloadWithDelay(500);
                })
                .catch(e => {
                    setIsLoading(false);
                    console.log("error", e);
                    close();
                    // mapper[index].checked = true;
                    //resetInspectionDetailByKeyConnect(currentMapper, mapper);
                    showToastMessages(e.detail, false);

                });
        }
    };

    return (
        <div className={styles.imageEditor}>
            <ImageEditor
                ref={editorRef}
                includeUI={{
                    loadImage: {
                        path: url,
                        // path: "http://localhost:3000/static/media/demo.c86467c7.jpg",
                        name: 'SampleImage'
                    },
                    theme: maskingTheme,
                    // menu: ['shape', 'filter'],
                    // initMenu: 'filter',
                    menu: ['mask', 'icon'],
                    initMenu: 'mask',
                    uiSize: {
                        width: '900px',
                        height: '700px'
                    },
                    menuBarPosition: 'bottom'
                }}
                cssMaxHeight={500}
                cssMaxWidth={600}
                selectionStyle={{
                    cornerSize: 25,
                    rotatingPointOffset: 30
                }}
                usageStatistics={false}
            />
            {isLoading && <Loader />}
            <div className={styles.applyButton}>
                <Button ctaText={"Apply"} onClick={handleClickButton} classNames="greenButton" />
            </div>
        </div>
    );
};

export default InspectionPocPopup;

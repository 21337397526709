import React from "react";
import styles from "../change/change.module.css";

const RejectedOrAdded = ({
    isAdded = false,
    isRejected = false
}) => {
    return (
        <div className={styles.change}>
            {isAdded ? "added by QC" :""}
            {isRejected ? "removed by QC" :""}
        </div>
    );
};

export default React.memo(RejectedOrAdded);

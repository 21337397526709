import * as firebase from "firebase/app";
import "firebase/database";
import "firebase/firestore";
import "firebase/auth";

import { configuration } from "../firebase-constants";

class FirebaseInstance {
    constructor() {
        this.configuration = configuration();
        this.firebase = firebase.initializeApp(this.configuration);
        this.auth = firebase.auth();
        this.firestore = firebase.firestore();
    }
}

export default FirebaseInstance;

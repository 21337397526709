import React from "react";
import style from "./auid.module.css";

const Audit = ({auditVersionData}) => {

    const renderItem = (item) => {
        const itemArray = [];
        for(const key in item) {
            if(key !== 'version') {
                itemArray.push(<li>{key+': <'+ item[key]+'>'}</li>);
            }
        }

        return itemArray;
    };

    return (
        <div className={style.auidWrapper}>
            <h3>Please check the following parameters which have been submitted for audit previously</h3>
            {auditVersionData.map(versionData => (
                <div className={style.auditVersion} key={versionData.version}>
                    <p>QC Audit Version: {versionData.version}</p>
                    <div className={style.auditList}>
                        <ul>
                            {renderItem(versionData)}
                        </ul>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default Audit;

export const API_ENDPOINTS = {
  INSPECTION: 'v2/inspection',
  INSPECTION_DATA_LIST: 'v2/inspection-data-list',
  LOGIN: 'oauth',
  USER_LOGIN_DETAILS: 'v2/api/getLoginDetails',
  GOOGLE_LOGIN: 'google-auth',
  STORE_LOCATION: 'location-store',
  INSPECTION_DETAIL: 'v2/inspection',
  Assignee: 'store',
  STATE: 'state',
  RTO: 'rto',
  CITY: 'city',
  INSPECTION_APPOINTMENT_DETAIL: 'v2/inspection-detail',
  MAKE: 'make',
  MODEL: 'model',
  VARIANT_YEAR: 'variant-year',
  ASSIGN_TO: 'qc-appointment-assign-to',
  EXCLUDED_LIST: 'api/Config',
  SLEEPTIME_CONFIG: 'get-qc-auto-assignment-sleep-time',
  SUBMIT_REVIEW: 'qc-section-report-submit',
  RE_INSPECTION: 'inspection-log',
  GET_COMMENTS: 'api/comments',
  SEND_EMAIL: 'reinspection-send-email',
  LOGOUT: 'v2/api/qcLogout',
  UPLOAD_IMAGE: 'v2/image',
  VAAHAN_DATA: 'vahan',
  INSPECTION_VERSION: 'v2/inspection-version-list',
  REVIEW_START_TIME: 'v2/set-review-start-time',
  BIKE_INSPECTION: '/appointment/',
  BIKE_INSPECTION_DETAIL: '/appointment/',
  BIKE_STATUS_SUBMIT: '/appointment',
  UPLOAD_BIKE_IMAGE: 'v2/bike-image',
  QC_ASSIGN_TO_BIKE: '/appointment',
  BIKE_MAKE: 'api/bikeMake',
  BIKE_MODEL: 'api/bikeModel',
  BIKE_VARIANT: 'api/bikeVariant',
  LEADER_BOARD: 'v2/get-leader-board-data',
  BANK_LIST: 'api/financerListData',
  BIKE_PUBLIC_CONFIG: 'public/appconfig',
  CAR_VARIANT_PARAMETER_DATA: 'v2/variant-suggestion-parameter',
  CAR_MODELS: 'model',
  CAR_MANUFACTURING_YEARS: 'variant-year',
  CAR_VARIANT_SUGGESTION_DATA: 'v2/variant-suggestion-data',
  UPLOAD_VARIANT_PARAMETER_IMAGE: 'v2/variant-suggestion-parameter/sunroof',
  UPDATE_VARIANT_PARAMETER: '/v2/variant-suggestion-data',
  ADD_CAR_VARIANT_PARAMETER: 'v2/variant-suggestion-parameter',
  CREATE_PDF_FOR_BIKE: '/appointment',
  REFURBISHMENT: '/v2/refurbishment',
  REFURBISHMENT_VERSION: '/v2/refurbishment-version',
  REFURBISHMENT_VERSION_DETAIL: '/v2/refurbishment-version-detail',
  REFURBISHMENT_ASSIGN: '/v2/refurbishment-qc-assign-to',
  AUDIT_VERSION_HISTORY: '/v2/get-qc-audit-data',
  RE_INSPECTION_EMAIL_LOG: '/v2/get-reinspection-email-log-data',
  BIKE_REPORT: '/appointment',
  CUSTOMER_AUDIT_DATA: '/v2/customer-audit-data',
  VARIANT_PARAMETER_DATA: '/v2/variant-suggestion-parameter?utm_source=qc',
  CAR_MAKE: '/make',
  DELETE_CAR_VARIANT: '/V2/',
  CAR_VARIANT_SEQUENCE: '/v2/variant-question-sequence',
  BIKE_VERSION: '/appointment',
  ENABLE_DISBALE_VARIANT_SUGGESTION: '/v2/variant-suggestion-data',
  SELL_ONLINE: '/v2/sell-online',
  STORE_CANCELLATION_FACTOR: 'v2/store-cancellation-factor',
  GS_DEALER_DATA: '/v2/gs-dealer-data',
  REJECTION_REMARK: '/v2/rejection-remark',
  FETCH_PART_AND_SUBPART: '/v2/get-part-subpart-by-field-name',
  FETCH_ALL_DISPOSITION: '/v2/api/getAllDispositions',
  UPADTE_CJ_LEAVES: '/api/updateLeaveData',
  PRE_INSPECTION_DATA_POINT: '/pre-inspection',
  SUBMIT_PRE_INSPECTION_REPORT: '/submit-pre-inspection-report',
  INSPECTION_RESTRICTED_IMAGES: '/api/getRcInsuranceDocs',
};
export const ERROR_CODES = {
  UNAUTHORISED: 401,
};

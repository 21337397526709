import React, {useEffect, useState} from "react";
import SelectComponent from "../shared/select";
import {INSURANCE_TYPE, RESET_REDUCER_KEYS, ROAD_TAX_PAID} from "../../utils/app-constants";

const RoadTaxPaid = ({resetInspectionDetailByKeyConnect,inspectionDetails,detail}) => {
    const [selectedValue, setSelectedValue] = useState("");
    const {key} = detail;
    const {documentsMapper} = inspectionDetails;

    useEffect(()=>{
        if(selectedValue) {
            setNewValue();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[selectedValue]);

    const setNewValue = ()=>{
        let mapper = [...documentsMapper];
        const newValue = selectedValue;
        const keyIndex = mapper.findIndex(item=>item.key === key);
        mapper[keyIndex].newValue = newValue;
        mapper[keyIndex].newId = newValue;
        resetInspectionDetailByKeyConnect(RESET_REDUCER_KEYS.DOCUMENTS_MAPPER, mapper);
    };

    const onSelect =(value)=>{
        setSelectedValue(value);
    };

    return (
        <div>
            <SelectComponent
                optionsList={ROAD_TAX_PAID}
                placeholder={detail.text}
                onChange={onSelect}
            />
        </div>
    );
};

export default RoadTaxPaid;

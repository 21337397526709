import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import RtoCode from "./component";
import { resetInspectionBikeDetailByKey } from "../../pages/inspection-review-bike/actions";

const mapStateToProps = ({
    bikeInspectionDetails
}) => ({
    bikeInspectionDetails
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    resetInspectionBikeDetailByKeyConnect: resetInspectionBikeDetailByKey
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(RtoCode);

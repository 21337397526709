import React, {useEffect, useState} from "react";
import SelectComponent from "../shared/select";
import {monthNames, RESET_REDUCER_KEYS} from "../../utils/app-constants";

const RegistrationMonth = ({detail, resetInspectionDetailByKeyConnect, inspectionDetails}) => {
    const [selectedMonth, setSelectedMonth] = useState("");
    const {documentsMapper}= inspectionDetails;
    const {key} = detail;

    // useEffect(()=>{
    //     setTimeout(()=>{
    //         let mapper = [...documentsMapper];
    //         let dependentFields = [];
    //         mapper = mapper.map(map => {
    //             let element = { ...map };
    //             if (map.key === key) {
    //                 if(element.dependentFields && element.dependentFields.length > 0){
    //                     dependentFields = element.dependentFields;
    //                 }
    //             }
    //             return element;
    //         });
    //
    //         mapper = mapper.map(map => {
    //             let element = { ...map };
    //             if (dependentFields.find(field=>field === element.key)) {
    //                 if(element.checked === false) {
    //                     element.checked = true;
    //                     element.isDisabled = true;
    //                 }
    //             }
    //
    //             return element;
    //         });
    //         resetInspectionDetailByKeyConnect(RESET_REDUCER_KEYS.DOCUMENTS_MAPPER, mapper);
    //     },400);
    //
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // },[]);
    //
    // useEffect(()=>{
    //     return ()=>{
    //         let mapper = [...documentsMapper];
    //         let dependentFields = [];
    //         mapper = mapper.map(map => {
    //             let element = { ...map };
    //             if (map.key === key) {
    //                 element.checked = false;
    //                 if(element.dependentFields && element.dependentFields.length >0){
    //                     dependentFields = element.dependentFields;
    //                 }
    //             }
    //             return element;
    //         });
    //
    //         mapper = mapper.map(map => {
    //             let element = { ...map };
    //             if (dependentFields.find(field=>field === element.key)) {
    //                 element.isDisabled = false;
    //                 element.checked = false;
    //                 element.newValue = "";
    //                 element.newId = "";
    //             }
    //
    //             return element;
    //         });
    //         resetInspectionDetailByKeyConnect(RESET_REDUCER_KEYS.DOCUMENTS_MAPPER, mapper);
    //     };
    //
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // },[]);

    useEffect(()=>{
        setNewValue();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[selectedMonth]);

    const onMonthChange = (option) =>{
        setSelectedMonth(option);
    };

    const monthDropDown = monthNames.map((month, index) => {
        return {
            label: month,
            value: index + 1
        };
    });

    const setNewValue = ()=>{
        let mapper = [...documentsMapper];
        let newValue = "";
        let newId = "";
        newValue = `${selectedMonth}`;
        newId = `${selectedMonth}`;

        mapper = mapper.map(map => {
            let element = { ...map };
            if (map.key === key) {
                element.newValue = newValue;
                element.newId = newId;
            }
            return element;
        });

        resetInspectionDetailByKeyConnect(RESET_REDUCER_KEYS.DOCUMENTS_MAPPER, mapper);
    };

    return (
        <div>
            <div className="mb-5">
                {
                    <SelectComponent
                        optionsList={monthDropDown}
                        onChange={onMonthChange}
                        placeholder={"Month"}
                    />
                }
            </div>
        </div>
    );
};

export default RegistrationMonth;

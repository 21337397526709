import React, {useEffect, useState} from "react";
import { useDispatch } from "react-redux";
import { INSPECTION_SECTION, RESET_REDUCER_KEYS } from "../../utils/app-constants";
import styles from "./inspection-mismatch.module.css";
import Checkbox from "../shared/checkbox/";
import { getEditableComponent } from "../../utils/helper";
import Change from "../../components/change";
import RejectedOrAdded from "../rejected-or-added";
import GreenTickIcon from '../../pages/sell-online/images/green_tick.svg'

const InspectionMismatch = ({
    document,
    resetInspectionDetailByKeyConnect,
    inspectionDetails
}) => {
    const dispatch = useDispatch();
    const { text, checked, value, key, newValue, isEditable,isImportant,isRejected,isAdded, commentFlag } = document;
    const { mismatchMapper } = inspectionDetails;

    const onCheckboxHandlerMismatch = (e,key) => {
        const { target:{ checked } } = e;

        const index = mismatchMapper.findIndex(item=>item.key === key);
        if(key === "noMisMatch"){
            for(let i=index;i<mismatchMapper.length;i++){
                if(mismatchMapper[i].isImportant && mismatchMapper[i].checked){
                    mismatchMapper[i].isRejected = true;
                }
                else if(!mismatchMapper[i].isImportant && mismatchMapper[i].checked){
                    mismatchMapper[i].isRejected = false;

                }
                mismatchMapper[i].isAdded = false;
                mismatchMapper[i].checked = false;
            }
        }
        else{
            if(mismatchMapper[0].isImportant){
                mismatchMapper[0].isRejected = true;
                mismatchMapper[0].checked = false;
            }
            else{
                mismatchMapper[0].isAdded = false;
                mismatchMapper[0].checked = false;
            }
        }
        mismatchMapper[index].checked = !mismatchMapper[index].checked;

        //now check for rejection or addition

        if(mismatchMapper[index].isImportant && !mismatchMapper[index].checked){
            mismatchMapper[index].isRejected = true;
            mismatchMapper[index].isAdded = false;
        }
        else if(mismatchMapper[index].isImportant && mismatchMapper[index].checked){
            mismatchMapper[index].isRejected = false;
            mismatchMapper[index].isAdded = false;
        }
        if(!mismatchMapper[index].isImportant && !mismatchMapper[index].checked){
            mismatchMapper[index].isRejected = false;
            mismatchMapper[index].isAdded = false;
        }
        else if(!mismatchMapper[index].isImportant && mismatchMapper[index].checked){
            mismatchMapper[index].isRejected = false;
            mismatchMapper[index].isAdded = true;
        }

        let flag = true;
        for(let i=1;i<mismatchMapper.length;i++){
            if(mismatchMapper[i].checked){
                flag = false;
            }
        }
        if(flag){
            mismatchMapper[0].checked = true;
            if(mismatchMapper[0].isImportant){
                mismatchMapper[0].isRejected = false;
            }
            else{
                mismatchMapper[0].isAdded = true;
            }
        }

        resetInspectionDetailByKeyConnect(RESET_REDUCER_KEYS.MISMATCH_MAPPER, mismatchMapper);
    };

    return (
        <>
        <div className={styles.documentInfo}>
            <div className={styles.checkboxlabel}>
                <Checkbox
                    checkedStatus={checked}
                    onChangeCallback={(e) => onCheckboxHandlerMismatch(e,key)}
                    name={`mismatch_${key}`}
                    id={`mismatch_${key}`}
                />
                {/* {(key !== "variant" && key !== "fuelType") && <label className={styles.lableText}>{text}</label>} */}
                <label className={`${styles.lableText} ${(isImportant || checked) ? styles.importantField : ""}`}>{text}</label>
            </div>
            {(key !== "variant" && key !== "fuelType") && <div className={styles.dataWrapper}>
                <span>
                    {
                        !!value && key !== "noMisMatch" ? `[${value}]` : ""
                    }
                </span>
                {
                    // newValue ? <div className={styles.change}>{`changed from ${value}`}</div> : ""
                    newValue ? <Change value={value} /> : ""
                }
                {
                    (commentFlag == 0 || commentFlag == 2) && <img src={GreenTickIcon} className={styles.infoIcons} />
                }
            </div>
            }
            {
                isRejected && <RejectedOrAdded isRejected={isRejected} />
            }
            {
                isAdded && <RejectedOrAdded isAdded={isAdded} />
            }

        </div>
        </>
    );
};

export default InspectionMismatch;

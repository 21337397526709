export const imageUploadFormData = [
    { key: 'carId', value: '' },
    { key: 'role', value: 'qc' },
    { key: 'hashKey', value: 'carDetails_rtoNocIssued_rtoNmcAvailable_additionalInfo_form_0' },
    { key: 'section', value: 'carDetails' },
    { key: 'field', value: 'rtoNocAvailableDefects' },
    { key: 'title', value: 'carDetails_rtoNocIssued_rtoNmcAvailable_additionalInfo_form_1' },
    { key: 'type', value: 'additionalInfo' },
    { key: 'inspectionStatus', value: '' },
    { key: 'part', value: 'rtoNocIssued' },
    { key: 'subPart', value: 'rtoNmcAvailable' },
    { key: 'partTitle', value: 'RTO NOC Issued' },
    { key: 'partValue', value: 'Yes' },
    { key: 'subPartTitle', value: 'RTO NMC Available' },
    { key: 'subPartValue', value: 'Yes' },
    { key: 'imageFile', value: '' },
  ];
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import TyreDemo from "../images/tyre-demo.svg";
import Delete from "../images/delete.svg";
import styles from "./error-list.module.css";
import { editableFielKeys } from "../../utils/app-constants";
import Button from "../../components/shared/button/component";
import { MONTH_YEAR, rejectionReasonMapping, detailMismatchRejectionReasonMapping } from "../../utils/app-constants";
// import { MergeArrayRemooveDupes } from "../../utils/helper";

const ErrorList = ({
    inspectionDetails,
    resetInspectionDetailByKeyConnect,
    setRejectionDataList
}) => {
    //documents
    const { documentsMapper, insuranceMapper, commentsMapper, otherDetailsMapper, rcImagesMapper, makeModelVariantMapper, mismatchMapper, insuranceImageMapper } = inspectionDetails;
    //exterior
    const { lhsFrontTyremapper, rhsFrontTyremapper, lhsRearTyremapper, spareTypremapper, rhsRearTyremapper, exteriorTyresMapper, exteriorComments, odometerComments, interiorElecctricalsMapper } = inspectionDetails;
    //interior
    const { interiorDataMapper, externalDataMapper, airConditioningDataMapper, interiorImagesMapper, interiorOdometerMapper, additionalImagesMapper, duplicateKeyMapper } = inspectionDetails;

    let errorList = [];
    const [errorData, setErrorData] = useState([]);
    const [errorCount, setErrorCount] = useState(0);

    const [group1Data, setGroup1Data] = useState([]);
    const [group2Data, setGroup2Data] = useState([]);
    const [group3Data, setGroup3Data] = useState([]);
    const [group4Data, setGroup4Data] = useState([]);

    useEffect(() => {
        let rejectionArray = [];
        errorData.map(item => {
            let value = rejectionReasonMapping[item.displayTitle];
            if(item.mapperName === "mismatchMapper") {
                value = detailMismatchRejectionReasonMapping[item.displayTitle];
            }
            if(value) {
                rejectionArray.push(value);
            }
        });
        rejectionArray = [...new Set(rejectionArray)].map(item => ({name: item, id:item}));
        setRejectionDataList(rejectionArray);
    }, [errorData]);

    const getErrorObject = (item, itemType, mapper, mapperName) => {
        let itemNewValue = item.newValue;
        let itemValue = item.value;
        if (itemType === "edit") {
            if (item.key !== "noClaimBonuspercent") {
                if (!editableFielKeys.find(field => field === item.key)) {
                    itemNewValue = "To be updated by Evaluator";
                }
            }
        }
        if (itemType === "nonedit" || itemType === "image" || itemType === "comments") {
            itemNewValue = "To be updated by Evaluator";
        }
        if (itemType === "comments") {
            itemValue = !!item.value && item.value.length > 0 ? item.value.map(_item => _item.comment).join(",") : "";
        }
        if (item.key === "registrationMonth") {
            let item = MONTH_YEAR.find(x => x.id === itemNewValue);
            if (!!item) {
                itemNewValue = item.value;
            }
        }

        var errorObj = {};
        errorObj.key = itemType !== "image" ? !!item.key ? item.key : item.title : item.title;
        errorObj.title = item.title;
        errorObj.displayTitle = !!item.text ? item.text : item.title;
        errorObj.value = item.key === "variant" ? `${itemValue} (${item.variant_display_name_initial})`: itemValue;
        errorObj.newValue = item.key === "variant" ? `${itemNewValue} (${item.variant_display_name})`: itemNewValue;;
        errorObj.mapper = mapper;
        errorObj.isDisabled = item.isDisabled;
        errorObj.itemType = itemType;
        errorObj.mapperName = mapperName;
        return errorObj;
    };
    useEffect(() => {
        let errObj = {};
        let group1 = [];
        documentsMapper.map(item => {
            if (item.checked === true) {
                errObj = getErrorObject(item, "edit", documentsMapper, "documentsMapper");
                // errorList.push(errObj);
                group1.push(errObj);
            }

        });
        insuranceMapper.map(item => {
            if (item.checked === true) {
                errObj = getErrorObject(item, "edit", insuranceMapper, "insuranceMapper");
                // errorList.push(errObj);
                group1.push(errObj);
            }
        });

        commentsMapper.map(item => {
            if (item.checked && !item.isImportant) {
                errObj = getErrorObject(item, "nonedit", commentsMapper, "commentsMapper");
                // errorList.push(errObj);
                group1.push(errObj);
            }
            if (!item.checked && item.isImportant) {
                errObj = getErrorObject(item, "nonedit", commentsMapper, "commentsMapper");
                // errorList.push(errObj);
                group1.push(errObj);
            }

        });

        otherDetailsMapper.map(item => {
            if (item.checked === true) {
                errObj = getErrorObject(item, "edit", otherDetailsMapper, "otherDetailsMapper");
                // errorList.push(errObj);
                group1.push(errObj);
            }
        });

        rcImagesMapper.map(item => {
            if (item.checked === true) {
                errObj = getErrorObject(item, "image", rcImagesMapper, "rcImagesMapper");
                group1.push(errObj);

            }
        });
        makeModelVariantMapper.map(item => {
            if (item.checked === true) {
                if (item.newValue && item.newId) {
                    errObj = getErrorObject(item, "edit", makeModelVariantMapper, "makeModelVariantMapper");
                    // errorList.push(errObj);
                    group1.push(errObj);
                }
            }
        });

        insuranceImageMapper.map(item => {
            if (item.checked === true) {
                errObj = getErrorObject(item, "image", insuranceImageMapper, "insuranceImageMapper");
                // errorList.push(errObj);
                group1.push(errObj);
            }
        });

        mismatchMapper.map(item => {
            if (item.checked && !item.isImportant) {
                errObj = getErrorObject(item, "nonedit", mismatchMapper, "mismatchMapper");
                // errorList.push(errObj);
                group1.push(errObj);
            }
            if (!item.checked && item.isImportant) {
                errObj = getErrorObject(item, "nonedit", mismatchMapper, "mismatchMapper");
                // errorList.push(errObj);
                group1.push(errObj);
            }
        });

        // if (!!errorList && errorList.length > 0) {
        // if (errorData.length > 0) {
        // errorList = mergeArrays(errorList, errorData);
        // errorList = MergeArrayRemooveDupes(errorList, errorData);
        setGroup1Data(group1);
        errorList = [...group1, ...group2Data, ...group3Data, ...group4Data];
        setErrorCount(errorList.length);
        setErrorData(errorList);
        // }
        // }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [documentsMapper, insuranceMapper, commentsMapper, otherDetailsMapper,
        JSON.stringify(exteriorTyresMapper), JSON.stringify(rcImagesMapper), insuranceImageMapper, makeModelVariantMapper,
        JSON.stringify(mismatchMapper)]);

    useEffect(() => {
        let errObj = {};
        let group2 = [];
        try {
            lhsFrontTyremapper.map(item => {
                if (item.checked === true) {
                    errObj = getErrorObject(item, "nonedit", lhsFrontTyremapper, "lhsFrontTyremapper");
                    // errorList.push(errObj);
                    group2.push(errObj);
                }

            });
            rhsFrontTyremapper.map(item => {
                if (item.checked === true) {
                    errObj = getErrorObject(item, "nonedit", rhsFrontTyremapper, "rhsFrontTyremapper");
                    // errorList.push(errObj);
                    group2.push(errObj);
                }
            });

            lhsRearTyremapper.map(item => {
                if (item.checked && !item.isImportant) {
                    errObj = getErrorObject(item, "nonedit", lhsRearTyremapper, "lhsRearTyremapper");
                    // errorList.push(errObj);
                    group2.push(errObj);
                }

            });

            rhsRearTyremapper.map(item => {
                if (item.checked === true) {
                    errObj = getErrorObject(item, "nonedit", rhsRearTyremapper, "rhsRearTyremapper");
                    // errorList.push(errObj);
                    group2.push(errObj);
                }
            });
            spareTypremapper.map(item => {
                if (item.checked === true) {
                    errObj = getErrorObject(item, "nonedit", spareTypremapper, "spareTypremapper");
                    // errorList.push(errObj);
                    group2.push(errObj);
                }
            });
            exteriorTyresMapper.map(item => {
                if (item.checked === true) {
                    errObj = getErrorObject(item, "image", exteriorTyresMapper, "exteriorTyresMapper");
                    // errorList.push(errObj);
                    group2.push(errObj);
                }
            });
            // [] || exteriorComments.map(item => {
            //     if (item.checked === true) {
            //         errObj = getErrorObject(item, "nonedit", exteriorComments, "exteriorComments");
            //         errorList.push(errObj);
            //     }
            // });
            exteriorComments && Object.keys(exteriorComments).map(item => {
                if (item === "checked" && exteriorComments[item]) {
                    errObj = getErrorObject(exteriorComments, "comments", exteriorComments, "exteriorComments");
                    // errorList.push(errObj);
                    group2.push(errObj);
                }
            });
            odometerComments.map(item => {
                if (item.checked && !item.isImportant) {
                    errObj = getErrorObject(item, "nonedit", odometerComments, "odometerComments");
                    // errorList.push(errObj);
                    group2.push(errObj);
                }
                if (!item.checked && item.isImportant) {
                    errObj = getErrorObject(item, "nonedit", odometerComments, "odometerComments");
                    // errorList.push(errObj);
                    group2.push(errObj);
                }

            });

            // if (!!errorList && errorList.length > 0) {
            setGroup2Data(group2);
            errorList = [...group1Data, ...group2, ...group3Data, ...group4Data];
            // errorList = MergeArrayRemooveDupes(errorList, errorData);
            setErrorCount(errorList.length);
            setErrorData(errorList);
            // }
        }
        catch (exception) {
            alert("error");
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lhsFrontTyremapper, rhsFrontTyremapper, lhsRearTyremapper, spareTypremapper,
        rhsRearTyremapper, JSON.stringify(exteriorTyresMapper), JSON.stringify(exteriorComments), JSON.stringify(odometerComments)]);

    useEffect(() => {
        let errObj = {};
        let group3 = [];
        interiorDataMapper.map(item => {
            if (item.checked === true) {
                errObj = getErrorObject(item, "nonedit", interiorDataMapper, "interiorDataMapper");
                // errorList.push(errObj);
                group3.push(errObj);
            }

        });
        externalDataMapper.map(item => {
            if (item.checked === true) {
                errObj = getErrorObject(item, "nonedit", externalDataMapper, "externalDataMapper");
                // errorList.push(errObj);
                group3.push(errObj);
            }
        });

        airConditioningDataMapper.map(item => {
            if (item.checked && !item.isImportant) {
                errObj = getErrorObject(item, "nonedit", airConditioningDataMapper, "airConditioningDataMapper");
                // errorList.push(errObj);
                group3.push(errObj);
            }

        });

        interiorImagesMapper.map(item => {
            if (item.checked === true) {
                errObj = getErrorObject(item, "image", interiorImagesMapper, "interiorImagesMapper");
                // errorList.push(errObj);
                group3.push(errObj);
            }
        });

        duplicateKeyMapper.map(item => {
            if (item.checked === true) {
                errObj = getErrorObject(item, "image", duplicateKeyMapper, "duplicateKeyMapper");
                // errorList.push(errObj);
                group3.push(errObj);
            }
        });
        //duplicateKeyMapper

        interiorOdometerMapper.map(item => {
            if (item.checked === true) {
                errObj = getErrorObject(item, "edit", interiorOdometerMapper, "interiorOdometerMapper");
                // errorList.push(errObj);
                group3.push(errObj);
            }
        });
        interiorElecctricalsMapper.map(item => {
            if (item.checked === true) {
                const isEditable = item.key === 'fuelLevel' ? 'edit' : 'nonedit';
                errObj = getErrorObject(item, isEditable, interiorElecctricalsMapper, "interiorElecctricalsMapper");
                // errorList.push(errObj);
                group3.push(errObj);
            }
        });
        // if (!!errorList && errorList.length > 0) {
        setGroup3Data(group3);
        errorList = [...group1Data, ...group2Data, ...group3, ...group4Data];

        // errorList = MergeArrayRemooveDupes(errorList, errorData);
        setErrorCount(errorList.length);
        setErrorData(errorList);
        // }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [interiorDataMapper, externalDataMapper, airConditioningDataMapper, interiorImagesMapper, interiorOdometerMapper, interiorElecctricalsMapper, duplicateKeyMapper]);
    useEffect(() => {
        let errObj = {};
        let group4 = [];
        additionalImagesMapper.map(item => {
            if (item.checked === true) {
                errObj = getErrorObject(item, "image", additionalImagesMapper, "additionalImagesMapper");
                // errorList.push(errObj);
                // let filedIndex = group4.findIndex(x => x.key === item.key);
                // if (filedIndex >= 0) {
                //     group4.splice(filedIndex, 1);
                // }
                group4.push(errObj);
            }

        });
        // if (!!errorList && errorList.length > 0) {
        // errorList = mergeArrays(errorData, errorList);
        // errorList = MergeArrayRemooveDupes(errorList, errorData);
        setGroup4Data(group4);
        errorList = [...group1Data, ...group2Data, ...group3Data, ...group4];
        // errorList = MergeArrayRemooveDupes(errorList, errorData);
        setErrorCount(errorList.length);
        setErrorData(errorList);
        // }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(additionalImagesMapper)]);
    const resetValues = (field) => {
        field.newId = '';
        field.newValue = '';
        let filedIndex = errorData.findIndex(x => x.key === field.key);
        if (filedIndex >= 0) {
            errorData.splice(filedIndex, 1);
        }
        // let count = errorCount - 1;
        setErrorData(errorData);
        if (!!errorData && errorData.length > 0) {
            setErrorCount(errorData.length);
        }

    };
    const discardClick = (e, key, mapper, itemType, mapperName) => {

        try {
            let field = mapper.find(item => item.key === key);
            if (itemType === "image") {
                field = mapper.find(item => item.title === key);
            }
            field.checked = false;

            const { dependentFields } = field;
            resetValues(field);
            if (!!dependentFields) {
                (dependentFields || []).forEach((item => {
                    const dependentItem = mapper.find(x => x.key === item);
                    if (dependentItem) {
                        if (key === "rcAvailability") {
                            const rcAvailabilityIndex = mapper.findIndex(item => item.key === "rcAvailability");
                            if (rcAvailabilityIndex > -1 && mapper[rcAvailabilityIndex].value.match(/lost/i)) {
                                dependentItem.checked = false;
                            } else {
                                dependentItem.checked = false;
                            }
                        }
                        else {
                            dependentItem.checked = false;
                        }
                    }
                    resetValues(dependentItem);
                }));
            }
            const fieldIndex = mapper.indexOf(field);
            mapper[fieldIndex] = field;

            resetInspectionDetailByKeyConnect(mapperName, mapper);
            //  resetInspectionDetailByKeyConnect(RESET_REDUCER_KEYS.DOCUMENTS_MAPPER, mapper);
        }
        catch (exception) {
            alert("Error - " + exception);
        }

    };
    return (
        <>
            {!!errorCount && errorCount > 0 &&
                <div className={styles.errorList}>
                    <p className={styles.errorHeading}>{errorCount} errors have been marked in this inspection.</p>
                    <table cellPadding="0" cellSpacing="0" width="100%">
                        <thead>
                            <tr>
                                <th>No.</th>
                                <th>Error Description</th>
                                <th>Previous</th>
                                <th >New Value</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                errorData.map((item, index) => {
                                    return (
                                        <tr>
                                            <td>{index + 1}</td>
                                            {/* <td>{item.key}</td> */}
                                            {/* <td>{!!item.title ? item.title : item.key}</td> */}
                                            <td>{!!item.displayTitle ? item.displayTitle : item.key}</td>
                                            {item.itemType !== "image" && <td>{item.value}</td>}
                                            {item.itemType === "image" && <td><img src={TyreDemo} alt="tyredemo" /></td>}
                                            <td>{item.newValue}</td>
                                            {/* <td>
                                                {!item.isDisabled && <Button classNames="discard" ctaText={"discard"} onClick={(e) => discardClick(e, item.key, item.mapper, item.itemType, item.mapperName)} />}
                                            </td> */}
                                        </tr>
                                    );
                                })
                            }
                            {/* <tr>
                                <td>1</td>
                                <td><img src={TyreDemo} alt="tyredemo" /></td>
                                <td>3</td>
                                <td>4</td>
                                <td>5</td>
                            </tr> */}
                        </tbody>
                    </table>
                </div>
            }
        </>
    );
};

export default ErrorList;

import React from 'react';
import styles from './styles.module.css';

const List = (props) => {

    return (
        <div className={styles.listWrapper}>
            {props.data.map((datum, i) => (
                <div key={i} className={styles.item}>
                    <h4 className={styles.itemTitle}>{datum.title}</h4>
                    <ul className={styles.itemList}>
                        {datum.items.map((item, i) => <li key={i}>{item}</li>)}
                    </ul>
                </div>
            ))}
        </div>
    );
};

export default List;
import { createTypes } from "reduxsauce";

export default createTypes(
    `
    FETCH_CAR_VARIANT_PARAMETER_DATA
    FETCH_CAR_VARIANT_PARAMETER_DATA_SUCCESS
    FETCH_CAR_VARIANT_PARAMETER_DATA_FAILURE
    FETCH_CAR_VARIANT_LIST
    FETCH_CAR_VARIANT_LIST_SUCCESS
    FETCH_CAR_VARIANT_LIST_FAILURE
    FETCH_CAR_MODAL
    FETCH_CAR_MODAL_SUCCESS
    FETCH_CAR_MODAL_FAILURE
    FETCH_CAR_MANUFACTURING_LIST
    FETCH_CAR_MANUFACTURING_LIST_SUCCESS
    FETCH_CAR_MANUFACTURING_LIST_FAILURE
    FETCH_CAR_MAKE_LIST
    FETCH_CAR_MAKE_LIST_SUCCESS
    FETCH_CAR_MAKE_LIST_FAILURE
`,
    {
        prefix: "carvariant/"
    }
);

import React, { useEffect, useState } from "react";
import { RESET_REDUCER_KEYS, OWNERS } from "../../utils/app-constants";
import SelectComponent from "../shared/select";
import { InspectionService } from "../../service";

const FinancierList = ({ detail, inspectionDetails, resetInspectionDetailByKeyConnect }) => {
    const [selectedValue, setSelectedValue] = useState("");
    const [bankDropDown, setBankDropDown] = useState([]);
    const { key } = detail;
    const { documentsMapper } = inspectionDetails;

    useEffect(() => {
        InspectionService.getBankList()
            .then(res => {
                const { data: { detail } } = res;
                if (detail.length > 0) {
                    const dropdown = detail.map(x => {
                        return {
                            label: x.financierName,
                            value: x.nocClearanceTime === 0 ? -1 : x.nocClearanceTime
                        };
                    });
                    setBankDropDown(dropdown);
                }

            })
            .catch(err => {

            });
    }, []);

    useEffect(() => {
        if (selectedValue) {
            setNewValue();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedValue]);

    const setNewValue = () => {
        let mapper = [...documentsMapper];
        const newValue = selectedValue;
        let dependentFields = [];
        mapper = mapper.map(map => {
            let element = { ...map };
            if (map.key === key) {
                let newSelectedVal = "";
                let objNewSeleted = bankDropDown.find(x => x.value === newValue);
                if (!!objNewSeleted) {
                    newSelectedVal = objNewSeleted.label;
                }
                element.newValue = newSelectedVal;
                element.newId = newValue === -1 ? 0 : newValue;
                // element.checked = false;
                if (element.dependentFields && element.dependentFields.length > 0) {
                    dependentFields = element.dependentFields;
                }
            }

            return element;
        });

        mapper = mapper.map(map => {
            let element = { ...map };
            if (dependentFields.find(field => field === element.key)) {
                element.checked = true;
            }

            return element;
        });

        resetInspectionDetailByKeyConnect(RESET_REDUCER_KEYS.DOCUMENTS_MAPPER, mapper);
    };

    const onSelect = (value) => {
        setSelectedValue(value);
    };
    return (
        <div>
            <SelectComponent
                optionsList={bankDropDown}
                placeholder={detail.text}
                onChange={onSelect}
            />
        </div>
    );
};

export default FinancierList;

import React, { useEffect, useState } from "react";
import { CHASSIS_EMBOSSING, RESET_REDUCER_KEYS } from "../../utils/app-constants";
import { Multiselect } from "multiselect-react-dropdown";
import InputText from "../shared/input-text";

const NoClaimBonusPercentage = ({
    resetInspectionDetailByKeyConnect,
    detail,
    inspectionDetails
}) => {
    const initialValue = detail.newValue ? detail.newValue : detail.value;
    const [percentage, setPercentage] = useState(initialValue);
    const { key } = detail;
    const { insuranceMapper } = inspectionDetails;

    useEffect(() => {
        return () => {
            // resetToInitialValue();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const resetToInitialValue = () => {
        let mapper = [...insuranceMapper];
        let newValue = "";
        let newId = "";
        mapper = mapper.map(map => {
            let element = { ...map };
            if (map.key === key) {
                element.newValue = newValue;
                element.newId = newId;
                element.checked = false;
            }

            return element;
        });
        resetInspectionDetailByKeyConnect(RESET_REDUCER_KEYS.INSURANCE_MAPPER, mapper);
    };

    const onChange = (e) => {
        const { target: { value } } = e;
        if (value.length < 3 && !isNaN(value)) {
            setPercentage(value);
        }
    };

    const onBlurText = (e) => {
        if (percentage !== initialValue) {
            setNewValue();
        }
        else {
            if (!!percentage) {
                setNewValue();
            }
        }
    };

    const setNewValue = () => {
        let mapper = [...insuranceMapper];
        const newValue = percentage;
        let dependentFields = [];
        mapper = mapper.map(map => {
            let element = { ...map };
            if (map.key === key) {
                element.newValue = newValue;
                element.newId = newValue;
                if (element.dependentFields && element.dependentFields.length > 0) {
                    dependentFields = element.dependentFields;
                }
            }

            return element;
        });

        mapper = mapper.map(map => {
            let element = { ...map };
            if (dependentFields.find(field => field === element.key)) {
                element.checked = true;
            }

            return element;
        });

        resetInspectionDetailByKeyConnect(RESET_REDUCER_KEYS.INSURANCE_MAPPER, mapper);
    };

    return (
        <div>
            <InputText
                placeholder={detail.text}
                onChange={onChange}
                value={percentage}
                onBlurCallback={onBlurText}
            />
        </div>
    );
};
export default NoClaimBonusPercentage;

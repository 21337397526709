import React, { useEffect, useState } from "react";
import {
    RESET_REDUCER_KEYS,
    RC_AT_DELIVERY
} from "../../utils/app-constants";
import SelectComponent from "../shared/select/component";

const RcAtDelivery = ({
    resetInspectionDetailByKeyConnect,
    detail,
    inspectionDetails,
}) => {
    const [selectedValue, setSelectedValue] = useState("");
    const { key } = detail;
    const { documentsMapper } = inspectionDetails;
    useEffect(() => {
        if (selectedValue) {
            setNewValue();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedValue]);

    const setNewValue = () => {
        let mapper = [...documentsMapper];
        const newValue = selectedValue;
        let dependentFields = [];
        mapper = mapper.map(map => {
            let element = { ...map };
            if (map.key === key) {
                element.newValue = newValue;
                element.newId = newValue;
                // element.checked = false;
                if (element.dependentFields && element.dependentFields.length > 0) {
                    dependentFields = element.dependentFields;
                }
            }

            return element;
        });

        mapper = mapper.map(map => {
            let element = { ...map };
            if (dependentFields.find(field => field === element.key)) {
                element.checked = true;
            }

            return element;
        });
        resetInspectionDetailByKeyConnect(RESET_REDUCER_KEYS.DOCUMENTS_MAPPER, mapper);
    };

    const onSelect = (value) => {
        setSelectedValue(value);
    };
    return (
        <div>
            <SelectComponent
                optionsList={RC_AT_DELIVERY}
                placeholder={detail.text}
                onChange={onSelect}
            />
        </div>
    );
};

export default RcAtDelivery;

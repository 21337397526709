import { createTypes } from "reduxsauce";

export default createTypes(
    `
    FETCH_INSPECTIONS_LIST
    FETCH_INSPECTIONS_LIST_SUCCESS
    FETCH_INSPECTIONS_LIST_FAILURE
    RESET_INSPECTIONS_LIST
    RESET_INSPECTION_BY_KEY
`,
    {
        prefix: "inspection/"
    }
);

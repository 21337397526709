import React from 'react'
import moment from 'moment/moment';
import Clock from '../../icons/Clock';
import { getFromLocalStorage } from '../../../utils/helper';
import { LOCAL_STORAGE_KEYS, REJECTION_REMARK_TYPE } from '../../../utils/app-constants';

const RemarkHistory = ({
  inspectionDetails,
  resetInspectionDetailByKeyConnect,
  remarkHistory = []
}) => {
  const BASE_URL = getFromLocalStorage(LOCAL_STORAGE_KEYS.VIDEO_HOST_URL);
  
  const getRemarkUi = (type, text, audioUrl) => {
    if(type === REJECTION_REMARK_TYPE.TEXT) {
      return text;
    } else {
      return (
        <audio controls className="remark__audioplayer">
          <source src={`${audioUrl}`} type="audio/mpeg" />
        </audio>
      )
    }
  }

  return (
    <div className="remark-history">
      <div className="remark-history__list">
        {remarkHistory.map(({ id, user, type, remark, presignedUrl, timestamp }) => (
          <div key={id} className="remark-history__listitem">

            <span className="listitem__user">{user?.split("@")?.[0]}</span>

            <span className="listitem__remark">{getRemarkUi(type, remark, presignedUrl)}</span>

            <span className="listitem__timestamp">
              <Clock style={{ marginRight: "4px" }}/>
              {moment(timestamp * 1000).format("MMM DD HH:mm")}
            </span>

          </div>
        ))}
      </div>
    </div>
  )
}

export default RemarkHistory
import Types from "./types";
import { InspectionService } from "../../service";

const submitReview = (carId, param) => dispatch => {
    return InspectionService.submitReview(carId, param)
        .then(response => {
            // const { data: { detail } } = response;
            return response.data;
        })
        .catch(error => {

            throw error;
        });
};

export {
    submitReview
};

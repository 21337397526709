import React from 'react';
import styles from './styles.module.css';
import ZoomIcon from '../../images/zoom.png';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from 'react-slick';
import { thumborUrl } from '../../../utils/url-constants';
import { getFromLocalStorage } from '../../../utils/helper';
import { LOCAL_STORAGE_KEYS } from '../../../utils/app-constants';

const Carousel = (props) => {
    const cdnBaseUrl = getFromLocalStorage(LOCAL_STORAGE_KEYS.IMAGE_HOST_URL) || "";
    const imageQualityParam = getFromLocalStorage(LOCAL_STORAGE_KEYS.IMAGE_QUALITY_PARAM) || "";

    const carouselSettings = {
        dots: false,
        infinite: props.infinite || false,
        speed: 500,
        slidesToShow: props.slidesToShow,
        slidesToScroll: props.slidesToShow
    };

    const handleZoomClick = url => {
        var url = "/view-image/0?url=" + encodeURI(url);
        window.open(url, "_blank", 'noopener');
    };

    return (
        <div className={styles.carouselContainer}>
            <Slider {...carouselSettings}>

                {props.data.map(img => (
                    <div className={styles.carouselItem}>
                        <div className={styles.itemImgContainer}>
                            <>
                                <img
                                    src={`${cdnBaseUrl}${img.url}${imageQualityParam}`}
                                    style={{ height: props.imgHeight || "200px" }}
                                />
                                <div className={styles.zoom} onClick={() => handleZoomClick(img.url)}>
                                    <img src={ZoomIcon} alt="zoom" />
                                </div>
                            </>
                        </div>
                        <div className={styles.itemTitle}>
                            <p>{img.title}</p>
                        </div>
                    </div>
                ))}
        
            </Slider>
        </div>
    );
};

export default Carousel;
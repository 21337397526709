import React from "react";
import Button from "../shared/button/component";
import styles from "./inspection-confirmation-popup.module.css";
import H2 from "../h2/component";

const InspectionConfirmationPopup = ({
    onClose
}) => {

    const submitConfirm = () => {
        onClose(true);
    };
    const cancelConfirm = () => {
        onClose(false);
    };
    return (
        <div>
            <H2 text={"Inspection Confirmation"} />
            <div className={styles.ctaWrapper}>
                <Button ctaText={"SUBMIT"} classNames="primaryButton" onClick={submitConfirm} />
                <Button ctaText={"CANCEL"} classNames="cancelButton" onClick={cancelConfirm} />
            </div>
        </div>
    );
};

export default InspectionConfirmationPopup;

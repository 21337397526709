import React, { useEffect, useState } from "react";
import styles from "./inspection-review-info-tabs.module.css";
import { RESET_REDUCER_KEYS } from "../../utils/app-constants";
import Scrollspy from 'react-scrollspy';
const InspectionReviewInfoTabs = ({ inspectionDetails, inputEl, docRef, intRef, sumRef, resetInspectionDetailByKeyConnect }) => {
    //documents
    const { documentsMapper, insuranceMapper, commentsMapper, otherDetailsMapper, rcImagesMapper, makeModelVariantMapper, mismatchMapper, insuranceImageMapper, duplicateKeyMapper } = inspectionDetails;
    //exterior
    const { lhsFrontTyremapper, rhsFrontTyremapper, lhsRearTyremapper, spareTypremapper, rhsRearTyremapper, exteriorTyresMapper, exteriorComments } = inspectionDetails;
    //interior
    const { interiorDataMapper, externalDataMapper, airConditioningDataMapper, interiorImagesMapper, interiorOdometerMapper, additionalImagesMapper, odometerComments, interiorElecctricalsMapper } = inspectionDetails;
    const [documentsCount, setDocumentsCount] = useState(0);
    const [exteriorCount, setExteriorCount] = useState(0);
    const [interiorCount, setInteriorCount] = useState(0);
    const [additionalCount, setAdditionalCount] = useState(0);

    const INSPECTION_REVIEW_TABS = {
        'Documents': documentsCount,
        'EXTERIOR': exteriorCount,
        'INTERIOR': interiorCount,
        'SUMMARY': documentsCount + exteriorCount + interiorCount + additionalCount
    };

    useEffect(() => {
        resetInspectionDetailByKeyConnect(RESET_REDUCER_KEYS.MAPPER_COUNTS, INSPECTION_REVIEW_TABS);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [documentsCount, exteriorCount, interiorCount, additionalCount]);

    useEffect(() => {
        let num = 0;
        documentsMapper.map(item => {
            if (item.checked === true) {
                ++num;
            }
        });
        insuranceMapper.map(item => {
            if (item.checked === true) {
                ++num;
            }
        });

        commentsMapper.map(item => {
            if (item.checked === true) {
                ++num;
            }
            if (item.checked && item.isImportant) {
                --num;
            }
            if (!item.checked && item.isImportant) {
                ++num;
            }
        });

        otherDetailsMapper.map(item => {
            if (item.checked === true) {
                ++num;
            }
        });

        rcImagesMapper.map(item => {
            if (item.checked === true) {
                ++num;
            }
        });

        makeModelVariantMapper.map(item => {
            if (item.newValue && item.newId) {
                ++num;
            }
        });

        insuranceImageMapper.map(item => {
            if (item.checked === true) {
                ++num;
            }
        });
        duplicateKeyMapper.map(item => {
            if (item.checked === true) {
                ++num;
            }
        });
        mismatchMapper.map(item => {
            if (item.checked === true) {
                ++num;
            }
            if (item.checked && item.isImportant) {
                --num;
            }
            if (!item.checked && item.isImportant) {
                ++num;
            }
        });

        setDocumentsCount(num);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [documentsMapper, insuranceMapper, commentsMapper, otherDetailsMapper, exteriorTyresMapper, rcImagesMapper, insuranceImageMapper, makeModelVariantMapper, JSON.stringify(mismatchMapper), duplicateKeyMapper]);

    useEffect(() => {
        let num = 0;

        lhsFrontTyremapper.map(item => {
            if (item.checked === true) {
                ++num;
            }
        });
        rhsFrontTyremapper.map(item => {
            if (item.checked === true) {
                ++num;
            }
        });
        lhsRearTyremapper.map(item => {
            if (item.checked === true) {
                ++num;
            }
        });
        spareTypremapper.map(item => {
            if (item.checked === true) {
                ++num;
            }
        });
        rhsRearTyremapper.map(item => {
            if (item.checked === true) {
                ++num;
            }
        });
        exteriorTyresMapper.map(item => {
            if (item.checked === true) {
                ++num;
            }
        });
        exteriorComments && Object.keys(exteriorComments).map(item => {
            if (item === "checked" && exteriorComments[item]) {
                ++num;
            }
        });

        setExteriorCount(num);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lhsFrontTyremapper, rhsFrontTyremapper, lhsRearTyremapper, spareTypremapper, rhsRearTyremapper, JSON.stringify(exteriorTyresMapper), JSON.stringify(exteriorComments)]);

    useEffect(() => {
        let num = 0;
        interiorDataMapper.map(item => {
            if (item.checked === true) {
                ++num;
            }
        });

        externalDataMapper.map(item => {
            if (item.checked === true) {
                ++num;
            }
        });

        airConditioningDataMapper.map(item => {
            if (item.checked === true) {
                ++num;
            }
        });

        interiorImagesMapper.map(item => {
            if (item.checked === true) {
                ++num;
            }
        });

        interiorOdometerMapper.map(item => {
            if (item.checked === true) {
                ++num;
            }
        });
        interiorElecctricalsMapper.map(item => {
            if (item.checked === true) {
                ++num;
            }
        });
        // odometerComments && Object.keys(odometerComments).map(item => {
        //     if (item === "checked" && odometerComments[item]) {
        //         ++num;
        //     }
        // });
        odometerComments.map(item => {
            if (item.checked === true) {
                ++num;
            }
            if (item.checked && item.isImportant) {
                --num;
            }
            if (!item.checked && item.isImportant) {
                ++num;
            }
        });

        setInteriorCount(num);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [interiorDataMapper, externalDataMapper, airConditioningDataMapper, interiorImagesMapper, interiorOdometerMapper, interiorElecctricalsMapper, JSON.stringify(odometerComments)]);

    useEffect(() => {
        let num = 0;
        additionalImagesMapper.map(item => {
            if (item.checked === true) {
                ++num;
            }
        });
        setAdditionalCount(num);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(additionalImagesMapper)]);

    const mapper = {
        "Documents": docRef,
        "EXTERIOR": inputEl,
        "INTERIOR": intRef,
        "SUMMARY": sumRef

    };
    const scrollPage = (item) => {
        window.scrollTo({ top: mapper[item].current.offsetTop - 150, behavior: 'smooth' });
    };

    return (
        <div className={styles.inspectionReviewTabs}>
            <ul>
                <Scrollspy items={['section-1', 'section-2', 'section-3', 'section-4']} currentClassName={styles.active}>
                    {
                        Object.keys(INSPECTION_REVIEW_TABS).map(item => {
                            return (
                                <li className={styles.notactive} key={item} onClick={() => scrollPage(item)}>{item}<span>{INSPECTION_REVIEW_TABS[item]}</span></li>
                                // <li className={styles.active}>EXTERIOR</li>
                            );
                        })
                    }
                </Scrollspy>
            </ul>
        </div>
    );
};

export default InspectionReviewInfoTabs;

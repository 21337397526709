import React from "react";
import styles from "./no-results.module.css";
import noResultsFound from "./images/no-results-found.svg";
import H2 from "../h2";
import {ERROR_MESSAGE} from "../../utils/app-constants";

const NoResults = () => {
    return (
        <div className={styles.errorWrapper}>
            <div className={styles.imgWrapper}>
                <img src={noResultsFound} alt={ERROR_MESSAGE.NO_RESULTS_HEADING} />
            </div>
            <div className={styles.errorTextWrapper}>
                <H2 text={ERROR_MESSAGE.NO_RESULTS_HEADING} />
                <p className={styles.text}>{ERROR_MESSAGE.NO_RESULTS_HEADING}</p>
            </div>
        </div>
    );
};

export default NoResults;

/* eslint-disable indent */
import React, {useState, useRef} from "react";
import { useHistory } from "react-router-dom";
import Header from "../../components/header";
import styles from './styles.module.css';
import Back from "../../components/back";
import H2 from "../../components/h2";
import { Button}from "@material-ui/core";
import { showToastMessages} from "../../utils/helper";
import { InspectionService } from "../../service";
import { ToastContainer } from 'react-toastify';
import Upload from "../../components/icons/Upload";
import sampleFile from './assets/sample_leave.csv';
import { saveAs } from 'file-saver';
const UploadCjLeave = () => {
  const history = useHistory();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const [failedErros, setFailedErros] = useState([]);
  const uploadRef = useRef();

  const isValidForm = () => {
    if (!uploadRef.current?.value) {
      showToastMessages('Please select csv file to upload', false);
      return false;
    } 
    return true;
  };
  const handleInputChange = e => {
    const file = e.target.files[0];
    setSelectedFile(file);
  };
  const handleSubmit = () => {
    if (isValidForm()) {
      setIsSubmitting(true);
      setFailedErros([]);
      InspectionService.uploadCjLeaves(selectedFile).then(()=>{
        uploadRef.current.value = '';
        setSelectedFile();
        showToastMessages('CJ Leaves file update successfully');
      }).catch(ex => {
        setFailedErros(ex?.detail);
        uploadRef.current.value = '';
        setSelectedFile();
        showToastMessages(ex?.detail || 'Failed to upload', false);
      }).finally(()=>{
        setIsSubmitting(false);
      });
    }
  };
  const onDownloadSample = () => {
    saveAs(sampleFile);
  };
  return (
    <>
      <Header history={history} showPerformance={false} />
      <ToastContainer />
      <div className={styles.backSearchWrapper}>
        <Back history={history} versionFlag={true} />
      </div>
      <div className={styles.adminForm}>
        <H2  text="Upload CJ Leave"/>
        <div class={styles.upload}>
          <Upload width="20" />
          <input
            className={styles.uploadcsv}
            ref={uploadRef}
            type="file"
            accept="text/csv"
            onChange={handleInputChange}
          />
        </div>
        <div class="row">
          <Button classNames="primaryButton" className={styles.downloadSample} variant="contained" type="submit" color="primary" disabled={isSubmitting} onClick={handleSubmit}>
            Submit
          </Button>
          <Button
              classNames="secondaryButton" className={styles.downloadSample} variant="contained" onClick={onDownloadSample}>
            Download Sample
          </Button>
        </div>
      </div>
      {!!failedErros.length && <div className={styles.errorList}>
        <p className={styles.heading}>Error Details</p>
        <ul>{failedErros.map((item, index)=> {
          return (
            <li key={`key-${index}`}>
              <span className={styles.errorText}>{item}</span>
            </li> 
          );
        })
          
        }
        </ul>
      </div>}
    </>
  );
};

export default UploadCjLeave;

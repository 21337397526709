import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import AddParameterPopup from "./component";
import { fetchCarVariantParameterDataList } from '../../pages/add-car-variant/actions';

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchCarVariantParameterDataList
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AddParameterPopup);

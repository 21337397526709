import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import InspectionHeader from "./component";
import {AssignToMe} from "../../pages/inspection/actions";

const mapStateToProps = (
    {inspectionDetails, bikeInspectionDetails}
) => ({
    inspectionDetails,bikeInspectionDetails
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    AssignToMeConnect: AssignToMe
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(InspectionHeader);

import { FILTER_ELEMENTS } from "../../components/shared/filters/constants";

export const FILTER_IDS = {
  STORE_ID: "storeId",
  DATE: "date",
  SLOT: "slotTime"
};

export const FILTER_CONFIG = {
  [FILTER_IDS.STORE_ID]: {
    id: FILTER_IDS.STORE_ID,
    label: "Store Name",
    filterType: FILTER_ELEMENTS.SELECT,
    placeholder: "Select Store Name",
    options: [],
    value: "",
  },
  [FILTER_IDS.DATE]: {
    id: FILTER_IDS.DATE,
    label: "Date",
    filterType: FILTER_ELEMENTS.DATE,
    placeholder: "Select Date",
    value: "",
  },
  [FILTER_IDS.SLOT]: {
    id: FILTER_IDS.SLOT,
    label: "Slot",
    filterType: FILTER_ELEMENTS.SELECT,
    placeholder: "Select Slot",
    options: [],
    value: "",
    isClearable: true
  }
}

export const UPDATE_FORM_IDS = {
  ...FILTER_IDS,
  X_FACTOR: "xFactor"
}
import Types from "./types";
import { InspectionService } from "../../service";
import { getFromLocalStorage, saveToLocalStorage } from "../../utils/helper";


const fetchStoreLocation = () => dispatch => {
    if(getFromLocalStorage("locationStore") == null) {
        return InspectionService.getStoreLocation()
        .then(response => {
            const { data: { detail } } = response;
            //localStorage.setItem("locationStore", JSON.stringify(detail));
            saveToLocalStorage("locationStore", JSON.stringify(detail));
            dispatch(fetchStorLocationSuccess(detail));
            return response;
        })
        .catch(error => dispatch(fetchStorLocationFailure(error)));
    } else {
        let detail = JSON.parse(getFromLocalStorage("locationStore"));
        dispatch(fetchStorLocationSuccess(detail));
        return detail;
    }
    
};
const fetchAssignee = (role) => dispatch => {
    if(getFromLocalStorage("assigneeData") == null) {
        return InspectionService.getAssignee(role)
            .then(response => {
                const { data: { detail } } = response;
                saveToLocalStorage("assigneeData", JSON.stringify(detail))
                dispatch(fetchAssigneeSuccess(detail));
                return response;
            })
            .catch(error => dispatch(fetchAssigneeFailure(error)));
        } else {
            let detail = JSON.parse(getFromLocalStorage("assigneeData"));
            dispatch(fetchAssigneeSuccess(detail));
            return detail;
        }
};
const fetchStorLocationSuccess = (data) => ({
    type: Types.FETCH_CITYLOCATION_SUCCESS,
    data
});
const fetchStorLocationFailure = (data) => ({
    type: Types.FETCH_CITYLOCATION_FAILURE,
    data
});
const fetchAssigneeSuccess = (data) => ({
    type: Types.FETCH_ASSIGNEE_SUCCESS,
    data
});
const fetchAssigneeFailure = (data) => ({
    type: Types.FETCH_ASSIGNEE_FAILURE,
    data
});
const resetFilterByKey = (key, value) => ({
    type: Types.RESET_FILTER_BY_KEY,
    data: { key, value }
});

export {
    fetchStoreLocation,
    resetFilterByKey,
    fetchAssignee
};

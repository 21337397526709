import React, { useEffect, useState } from "react";
import {
    CHASSIS_NO_ON_RC,
    DETAIL_MISMATCH,
    RC_CONDITIONS,
    BIKE_RC_CONDITIONS,
    RESET_REDUCER_KEYS
} from "../../utils/app-constants";
import { isUserRoleBIkeQc } from "../../utils/helper";
import { Multiselect } from "multiselect-react-dropdown";
import styles from "./rc-condition.module.css";
import SelectComponent from "../shared/select";
import Change from "../change";

const RcCondition = ({
    resetInspectionDetailByKeyConnect,
    detail,
    inspectionDetails,
    bikeInspectionDetails,
    resetInspectionBikeDetailByKeyConnect
}) => {
    let isBikeQc = isUserRoleBIkeQc();
    const { documentsMapper } = inspectionDetails;
    const { bikeDocumentsMapper } = bikeInspectionDetails;
    const { key, value } = detail;
    const [chassisNo, setChassisNo] = useState(null);
    const [showChassisNo, setShowChassisNo] = useState(false);
    const [detailMisMatch, setDetailMismatch] = useState(null);
    const [rcCondition, setRcCondition] = useState(null);
    const [showDetailMismatch, setShowDetailMismatch] = useState(false);
    const defaultValueObj = [value];
    useEffect(() => {
        if (documentsMapper.length > 0) {
            const chassisNoValue = documentsMapper.find(field => field.key === "chassisNoOnRc");
            const detailMismatch = documentsMapper.find(field => field.key === "misMatchInRc");
            const rcConditionValue = documentsMapper.find(field => field.key === "rcCondition");

            setChassisNo({
                value: chassisNoValue.value ? chassisNoValue.value : "",
                text: chassisNoValue.text ? chassisNoValue.text : "",
                newValue: chassisNoValue.newValue ? chassisNoValue.newValue : ""
            });

            setDetailMismatch({
                value: detailMismatch.value ? detailMismatch.value : "",
                text: detailMismatch.text ? detailMismatch.text : "",
                newValue: detailMismatch.newValue ? detailMismatch.newValue : ""
            });

            setRcCondition({
                value: rcConditionValue.value ? rcConditionValue.value : "",
                text: rcConditionValue.text ? rcConditionValue.text : "",
                newValue: rcConditionValue.newValue ? rcConditionValue.newValue : ""
            });

        }
    }, [documentsMapper]);

    const setNewValueRcCondition = (optionsList) => {
        let result = [];
        let mapper = isBikeQc ? [...bikeDocumentsMapper] : [...documentsMapper];
        optionsList.map(option => {
            result.push(option.name);
        });

        const newValue = result;
        mapper = mapper.map(map => {
            let element = { ...map };
            if (map.key === key) {
                element.newValue = newValue.join(",");
                element.newId = newValue;
                element.checked = true;
            }

            return element;
        });
        if (isBikeQc) {
            resetInspectionBikeDetailByKeyConnect(RESET_REDUCER_KEYS.BIKE_DOCUMENTS_MAPPER, mapper);
        }
        else {
            resetInspectionDetailByKeyConnect(RESET_REDUCER_KEYS.DOCUMENTS_MAPPER, mapper);
        }
    };

    const onSelectRcCondition = (optionsList, selectedItem) => {
        const okIndex = optionsList.findIndex(key => key.id === "Ok");
        const lostIndex = optionsList.findIndex(key => key.id === "Lost");
        if (!isBikeQc) {
            if (okIndex > -1 && selectedItem.id !== "Ok") {
                optionsList.splice(okIndex, 1);
            }
            else if (selectedItem.id === "Ok") {
                optionsList.splice(0, optionsList.length);
                optionsList.push(selectedItem);
            }
        }
        else {
            if (okIndex > -1 || lostIndex > -1) {
                if (okIndex > -1 && selectedItem.id !== "Ok") {
                    optionsList.splice(okIndex, 1);
                }
                if (lostIndex > -1 && lostIndex.id !== "Lost") {
                    optionsList.splice(lostIndex, 1);
                }
            }
            if (selectedItem.id === "Ok") {
                if (lostIndex > -1 && lostIndex.id !== "Lost") {
                    optionsList.splice(lostIndex, 1);
                }
                optionsList.splice(0, optionsList.length);
                optionsList.push(selectedItem);
            }
            if (selectedItem.id === "Lost") {
                if (okIndex > -1 && okIndex.id !== "Ok") {
                    optionsList.splice(okIndex, 1);
                }
                optionsList.splice(0, optionsList.length);
                optionsList.push(selectedItem);
            }
        }
        setNewValueRcCondition(optionsList);
    };

    const onRemoveRcCondition = (optionList, removedItem) => {
        setNewValueRcCondition(optionList);
    };
    return (
        <div>
            <div className={styles.multiSelect}>
                <Multiselect
                    options={isBikeQc ? BIKE_RC_CONDITIONS : RC_CONDITIONS}
                    selectedvalues={defaultValueObj}
                    onSelect={onSelectRcCondition}
                    onRemove={onRemoveRcCondition}
                    displayValue="name"
                />
                {
                    rcCondition && rcCondition.newValue ? <Change value={rcCondition.value} /> : ""
                }
            </div>
        </div>
    );
};

export default RcCondition;

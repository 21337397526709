import Types from "./types";
import { InspectionService } from "../../service";
import { getBikeMocDetail } from "../../utils/mocBikeApi";

const fetchBikeInspectionDetail = (appointmentId) => dispatch => {
    return InspectionService.getBikeInspectionDetail(appointmentId)
        .then(response => {
            // response = getBikeMocDetail();
            const { data } = response;
            dispatch(fetchInspectionBikeDetailSuccess(data));
            return response;
        })
        .catch(error => {
            // let response = getBikeDetail();
            // dispatch(fetchInspectionBikeDetailSuccess(response));
            dispatch(fetchInspectionBikeDetailFailure(error));
            throw error;
        });

};
const fetchInspectionBikeDetailSuccess = (data) => ({
    type: Types.FETCH_INSPECTIONS_BIKE_DETAIL_SUCCESS,
    data
});
const fetchInspectionBikeDetailFailure = (data) => ({
    type: Types.FETCH_INSPECTIONS_BIKE_DETAIL_FAILURE,
    data
});
const resetInspectionBikeDetailByKey = (key, value) => ({
    type: Types.RESET_INSPECTION_BIKE_DETAIL_BY_KEY,
    data: { key, value }
});
const resetInspectionBikeDetail = () => ({
    type: Types.RESET_INSPECTIONS_BIKE_DETAIL
});
export {
    fetchBikeInspectionDetail,
    resetInspectionBikeDetailByKey,
    resetInspectionBikeDetail
};

import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Carousel from './component';
import { resetCarouselByKey } from './actions';
import { resetInspectionDetailByKey } from '../../pages/inspection-review/actions';

const mapStateToProps = ({ carousel, inspectionDetails }) => ({
  carousel,
  inspectionDetails,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      resetCarouselByKeyConnect: resetCarouselByKey,
      resetInspectionDetailByKeyConnect: resetInspectionDetailByKey,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Carousel);

import React from "react";
import "./button.css";
import PropTypes from "prop-types";
// import ButtonLoader from "../../button-loader";

const Button = (props) => {
    const {
        ctaText,
        onClick = () => { },
        classNames = "",
        style = {},
        disabled = false,
        actionCtaText = "",
        isLoading,
        children
    } = props;

    return (
        <button style={style} onClick={onClick} className={classNames} disabled={disabled}>
            {actionCtaText ? actionCtaText : ctaText}
            {/* {isLoading && <ButtonLoader />} */}
            {children}
        </button>
    );
};

Button.propTypes = {
    className: PropTypes.string
};
export default Button;

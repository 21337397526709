import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import InspectionFilters from "./component";
import { fetchStoreLocation, resetFilterByKey, fetchAssignee } from "./actions";
import { resetSearchByKey } from "../shared/search/actions";
import {resetInspectionByKey} from "../../pages/inspection/actions";

const mapStateToProps = ({
    inspectionFiltersReducer
}) => ({
    inspectionFiltersReducer  //part of combined reducer
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchStoreLocationConnect: fetchStoreLocation,
    resetFilterByKeyConnect: resetFilterByKey,
    fetchAssigneeConnect: fetchAssignee,
    resetSearchByKeyConnect: resetSearchByKey,
    resetInspectionByKeyConnect : resetInspectionByKey
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(InspectionFilters);

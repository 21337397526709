import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import InspectionPocPopup from "./component";
import { resetInspectionDetailByKey } from "../../pages/inspection-review/actions";
import { resetInspectionBikeDetailByKey } from "../../pages/inspection-review-bike/actions";

const mapStateToProps = ({ inspectionDetails, bikeInspectionDetails }) => ({
    inspectionDetails,
    bikeInspectionDetails
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    resetInspectionDetailByKeyConnect: resetInspectionDetailByKey,
    resetInspectionBikeDetailByKeyConnect: resetInspectionBikeDetailByKey
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(InspectionPocPopup);

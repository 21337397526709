import { createTypes } from "reduxsauce";

export default createTypes(
    `
    RESET_INSPECTIONS_BIKE_DETAIL
    FETCH_INSPECTIONS_BIKE_DETAIL_SUCCESS
    FETCH_INSPECTIONS_BIKE_DETAIL_FAILURE
    RESET_INSPECTION_BIKE_DETAIL_BY_KEY
    RESET_INSPECTION_BIKE_DETAIL_DOCUMENT_BY_KEY
`,
    {
        prefix: "inspectionreviewbike/"
    }
);

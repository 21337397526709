import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Checkbox from "../../components/shared/checkbox/";
import RemarkInput from "../shared/remark-input";
import styles from "./image-checkbox.module.css";
import Remark from '../shared/remark/Remark';

const ImageCheckbox = ({
    currentMapper,
    index,
    inspectionDetails,
    resetInspectionDetailByKeyConnect,
    treadValue = null,
    bikeInspectionDetails,
    resetInspectionBikeDetailByKeyConnect,
    isBikeQc,
    section
}) => {
    const dispatch = useDispatch();
    const [isRemarkHistoryOpen, setIsRemarkHistoryOpen] = useState(false);
    let existingMapper = isBikeQc ? bikeInspectionDetails[currentMapper] : inspectionDetails[currentMapper];

    // Not showing [engineBlowByBackCompression] in Images/ Videos slider
    existingMapper = existingMapper.filter(obj => obj.key !== "engineBlowByBackCompression");

    const [checkBoxChecked, setCheckBoxChecked] = useState(false);
    const [text, setText] = useState("");
    const [prevValue, setPrevValue] = useState("");
    useEffect(() => {
        if (existingMapper) {
            let mapper = [...existingMapper];
            const currentIndex = mapper.findIndex(item => item.index == index);
            if (currentIndex > -1) {
                setCheckBoxChecked(mapper[currentIndex].checked);
                setText(mapper[currentIndex].subPart_title ? `${mapper[currentIndex].title} - ${mapper[currentIndex].subPart_title}` : mapper[currentIndex].title);
                setPrevValue(mapper[currentIndex].prevValue);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [index, JSON.stringify(existingMapper)]);

    const onCheckboxHandler = () => {
        let mapper = [...existingMapper];
        const currentIndex = mapper.findIndex(item => item.index === index);
        if (currentIndex > -1) {
            mapper[currentIndex].checked = !mapper[currentIndex].checked;
            setCheckBoxChecked(mapper[currentIndex].checked);
        }
        // if(mapper[index].dependentFields){
        //     dependentFields = mapper[index].dependentFields;
        // }
        if(isBikeQc) {
            resetInspectionBikeDetailByKeyConnect(currentMapper, mapper);
            return;
        }
        resetInspectionDetailByKeyConnect(currentMapper, mapper);
        setIsRemarkHistoryOpen(!checkBoxChecked);

    };

    return (
        <>
        <div className={styles.imageStrip}>
            <div className={styles.imageDetails}>
                <div className={styles.checkboxWrapper}>
                    <Checkbox
                        onChangeCallback={onCheckboxHandler}
                        name={`${currentMapper}_${index}`}
                        id={`${currentMapper}_${index}`}
                        checkedStatus={checkBoxChecked}
                    />
                    <p className={styles.text}>{text}</p>
                </div>
                {existingMapper[index]?.infoText && <p className={styles.rejected}>{existingMapper[index].infoText}</p>}
                <p className={styles.rejected}>{prevValue}</p>
            </div>
            {
                treadValue && <p className={styles.text}>{treadValue.value[0]}</p>
            }
            {!!existingMapper[index]?.remarkHistory?.length && (
                <span
                    className={"remarkHistoryBtn ml-10"}
                    onClick={() => setIsRemarkHistoryOpen(!isRemarkHistoryOpen)}
                >
                    Remark History
                </span>
            )}
        </div>

        <Remark
            document={existingMapper[index]}
            section={section}
            showRemarkInput={checkBoxChecked}
            isRemarkHistoryOpen={isRemarkHistoryOpen}
        />

        </>
    );
};

export default ImageCheckbox;

/* eslint-disable indent */
import React, { useRef, useState, useEffect } from "react";
import Header from "../../components/header";
import Search from "../../components/shared/search";
import styles from "./inspection-bike.module.css";
import InfiniteScroll from 'react-infinite-scroller';
import { RESET_REDUCER_STATE_KEYS, LOCAL_STORAGE_KEYS } from "../../utils/app-constants";
import { 
  getFromLocalStorage, 
  setImageHostToLocalStorageForBike, 
  setVideoBaseUrlToLocalStorageForBike 
} from "../../utils/helper";
import ColorPallet from "../../components/colorpallet";
import moment from "moment";
import DataInformationMessage from "../../components/data-information-message";
import Button from "../../components/shared/button/component";
import { InspectionService } from "../../service";

const InspectionBike = ({ bikeInspectionReducer,
  fetcBikeInspectionListConnect,
  resetBikeInspectionByKeyConnect,
  resetInspectionBikeDetailByKeyConnect,
  search,
  match,
  history }) => {

  const scrollRef = useRef();
  const [pageStart] = useState(0);
  const [hasMoreItems, setHasMoreItems] = useState(true);
  const { data, total, isLoading, error, initialCall } = bikeInspectionReducer;
  const { query } = search;
  const email = getFromLocalStorage(LOCAL_STORAGE_KEYS.EMAIL);

  useEffect(() => {
    InspectionService.getBikePublicConfigData().then(response => {
      const { data: { imageUrlTemplate, videoBaseUrl } } = response;
      if (!!imageUrlTemplate) {
        setImageHostToLocalStorageForBike(imageUrlTemplate);
      }
      if(videoBaseUrl) {
        setVideoBaseUrlToLocalStorageForBike(videoBaseUrl);
      }
      let abc = "!";
    }).catch(error => {
      console.log('Error::', error);
    });

    return () => {
      resetAndFetchNewListing();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (!!query && query !== "") {
      resetAndFetchNewListing();
      if (query === "-1") {
        // loadMoreInspections(1, citySelected, locationSelected, categorySelected, assigneeSelected);
      }
      else {
        loadMoreInspections(1, [], [], "", "", query);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  const loadMoreInspections = ((page, storeCityId, storeId, category, assignedToSearch, appointmentId) => {
    // fetcInspectionListConnect(page);
    setHasMoreItems(false);
    const startDate = moment(moment(new Date()).subtract(6, 'months').toDate()).format('YYYY-MM-DD').toString();
    const endDate = moment(moment(new Date()).toDate()).format('YYYY-MM-DD').toString();
    if (page > 0) { page = page - 1; }
    fetcBikeInspectionListConnect(page, email, null, appointmentId, startDate, endDate).then(resp => {
      if (resp.data) {
        const { data: { status, detail } } = resp;
        if (detail && detail.length === 50) {
          setHasMoreItems(true);
        }
      }
    }).catch(error => {
      const { status, detail } = error;
      if (!!status && status === 403) {
        localStorage.clear();
        window.location = `${window.origin}/login`;
      }
    });
  });
  const resetAndFetchNewListing = () => {
    scrollRef.current.pageLoaded = 0;
    setHasMoreItems(true);
    resetBikeInspectionByKeyConnect(RESET_REDUCER_STATE_KEYS.DATA, []);
    resetBikeInspectionByKeyConnect("isLoading", true);
  };

  const redirectToReview = (item) => {
    resetInspectionBikeDetailByKeyConnect("isLoading", true);
    history.push(`bike-review/${item.appointmentId}`);
  };
  const covetEpochToDate = (unixTimeStamp) => {
    let _date = moment.unix(unixTimeStamp).format('DD-MM-YYYY h:mm:ss A');
    return _date;
  };
  const getAssignToMe = (carId, item) => {
    return (
      <Button classNames="assignCta" ctaText={"assign to me"} onClick={(e) => assignToMeClick(e, carId, item)} />
    );

  };
  const assignToMeClick = (e, carId, item) => {
    e.stopPropagation();
    let data = new FormData();
    data.append("assignUser", email);
    InspectionService.assignQcForBike(item.appointmentId, data)
      .then(res => {
        resetInspectionBikeDetailByKeyConnect("isLoading", true);
        history.push(`bike-review/${item.appointmentId}`);
      })
      .catch(e => {

      });
  };
  const renderInspectionList = (itemList) => {
    return (
      itemList.map(item =>
        (
          <tr onClick={() => redirectToReview(item)}>
            <td><ColorPallet appointmentId={item.appointmentId} initialTime={covetEpochToDate(item.inspectionEndTime)} /></td>
            <td >{item.appointmentId}</td>
            <td>{item.inspectionUser}</td>
            <td>{`${!!item.make ? item.make + "/" : ""}${!!item.model ? item.model : ""}`}</td>
            <td>{covetEpochToDate(item.inspectionEndTime)}</td>
            <td>{(!!item.qcUser && item.qcUser !== "null" ? item.qcUser : getAssignToMe(item.carId, item))}</td>
          </tr>
        )
      )
    );
  };
  return (
    <div>
      <Header history={history} showVersion={false} />
      <div className={styles.inspectionHeader}>
        {/* <InspectionFilters /> */}
        <Search />
      </div>
      {data && data.length == 0 && !isLoading && <p className={styles.noAppointment}>No Appointment Data</p>}
      <div className={styles.inspectionList}>
        <InfiniteScroll
          key={"1"}
          ref={scrollRef}
          className={styles.inspectionList}
          pageStart={pageStart}
          loadMore={loadMoreInspections}
          hasMore={hasMoreItems}
        >
          {data && data.length > 0 && !isLoading &&
            <table cellPadding="0" cellSpacing="0" width="100%" >
              <thead>
                <tr>
                  <th></th>
                  <th>Appt ID <span className={styles.count}>({total})</span></th>
                  <th>Inspection_by</th>
                  <th>Model</th>
                  <th>Inspection End Time</th>
                  <th>Assignee</th>
                </tr>
              </thead>
              <tbody>
                {renderInspectionList(data)}
              </tbody>
            </table >
          }
        </InfiniteScroll>
      </div>
      <DataInformationMessage
        isLoading={isLoading}
        data={[1]}
        error={error}
      />

    </div>

  );
};

export default InspectionBike;

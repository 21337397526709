import { getFullMonthName, formatDate, removeSpecialCharAndSpace, isValidDate } from "./helper";
import { RTO_NOC_ISSUED_FIELD_VALUE } from "./app-constants";

const YES = "yes";
const NO = "no";
const INFO = "info"

export const getValidRegistrationYearDetails = ({
    parivahan1,
    vahanWebsite,
}, currentValue) => {
    let details = {};
    let parivahanValue = null;
    let vahanWebsiteValue = null;
    let isNotEqual = false;
    if (parivahan1) {
        parivahanValue = parivahan1["Registration Date:"] ? parivahan1["Registration Date:"].split("-")[2] : "";
    }
    if (vahanWebsite) {
        vahanWebsiteValue = vahanWebsite["Registration Date:"] ? vahanWebsite["Registration Date:"].split("-")[2] : "";
    }
    if (parivahanValue == currentValue || vahanWebsiteValue == currentValue) {
        details = {
            ...details,
            vaahanFlag: YES
        };
    }
    if (parivahanValue && parivahanValue == currentValue) {
        details = {
            ...details,
            vaahanFlag: YES,
            parivahanWebsite: parivahanValue
        };
    } else if (parivahanValue) {
        isNotEqual = true;
        details = {
            ...details,
            vaahanFlag: NO,
            parivahanWebsite: parivahanValue
        };
    }
    if (vahanWebsiteValue && vahanWebsiteValue == currentValue) {
        details = {
            ...details,
            vaahanFlag: isNotEqual ? NO : YES,
            vahanWebsite: vahanWebsiteValue
        };
    } else if (vahanWebsiteValue) {
        details = {
            ...details,
            vaahanFlag: NO,
            vahanWebsite: vahanWebsiteValue
        };
    }
    return details;
};

export const getValidCustomerNameDetails = ({ vahanWebsite }, currentValue) => {
    let vahanCustomerName = vahanWebsite?.["rcOwnerName"] || '';

    let details = {
        vaahanFlag: INFO,
        vahanWebsite: vahanCustomerName
    };

    return details;
}

export const getValidRoadTaxPaidDetails = ({ vahanWebsite }, currentValue) => {
    let vahanCustomerName = vahanWebsite?.["taxUpTo"] || '';

    let details = {
        vaahanFlag: INFO,
        vahanWebsite: vahanCustomerName
    };

    return details;
}

export const getValidRegistrationMonthDetails = ({
    parivahan1,
    vahanWebsite,
}, currentValue) => {
    let details = {};
    let parivahanValue = null;
    let vahanWebsiteValue = null;
    let isNotEqual = false;
    if (parivahan1) {
        parivahanValue = parivahan1["Registration Date:"] ? parivahan1["Registration Date:"].split("-")[1] : "";
    }
    if (vahanWebsite) {
        vahanWebsiteValue = vahanWebsite["Registration Date:"] ? vahanWebsite["Registration Date:"].split("-")[1] : "";
    }
    //skipping vahansms block seems like an unused block
    if (parivahanValue && getFullMonthName(parivahanValue) == currentValue) {
        details = {
            ...details,
            vaahanFlag: YES,
            parivahanWebsite: getFullMonthName(parivahanValue)
        };
    } else if (parivahanValue) {
        isNotEqual = true;
        details = {
            ...details,
            vaahanFlag: NO,
            parivahanWebsite: getFullMonthName(parivahanValue)
        };
    }
    if (vahanWebsiteValue && getFullMonthName(vahanWebsiteValue) == currentValue) {
        details = {
            ...details,
            vaahanFlag: isNotEqual ? NO : YES,
            vahanWebsite: getFullMonthName(vahanWebsiteValue)
        };
    } else if (vahanWebsiteValue) {
        details = {
            ...details,
            vaahanFlag: NO,
            vahanWebsite: getFullMonthName(vahanWebsiteValue)
        };
    }
    return details;
};

export const getValidFitnessDetails = ({
    parivahan1,
    vahanWebsite,
}, currentValue) => {
    let details = {};
    let parivahanValue = null;
    let vahanWebsiteValue = null;
    let isNotEqual = false;
    if (parivahan1) {
        parivahanValue = parivahan1["Fitness Upto:"];
    }
    if (vahanWebsite) {
        vahanWebsiteValue = vahanWebsite["Fitness/REGN Upto:"];
    }
    //skipping vahansms block seems like an unused block
    if (parivahanValue && formatDate(parivahanValue) == currentValue) {
        details = {
            ...details,
            vaahanFlag: YES,
            parivahanWebsite: parivahanValue
        };
    } else if (parivahanValue) {
        isNotEqual = true;
        details = {
            ...details,
            vaahanFlag: NO,
            parivahanWebsite: parivahanValue
        };
    }
    if (vahanWebsiteValue && formatDate(vahanWebsiteValue) == currentValue) {
        details = {
            ...details,
            vaahanFlag: isNotEqual ? NO : YES,
            vahanWebsite: vahanWebsiteValue
        };
    } else if (vahanWebsiteValue) {
        details = {
            ...details,
            vaahanFlag: NO,
            vahanWebsite: vahanWebsiteValue
        };
    }
    return details;
};

export const validateRtoWithBgc = ({ bgcData }, currentValue) => {
    let details = {
        dataSource: "BGC Data"
    };
    let bgcData_rto = null;

    if(bgcData?.vahanRtoCode !== null) {
        bgcData_rto = bgcData.vahanRtoCode;
    }

    if (
        bgcData_rto !== null &&
        bgcData_rto?.toLowerCase() === currentValue?.substring(0, 5)?.toLowerCase()
    ) {
        details = {
            ...details,
            vaahanFlag: YES,
            vahanWebsite: bgcData_rto
        };
    } else {
        details = {
            ...details,
            vaahanFlag: NO,
            vahanWebsite: bgcData_rto
        };
    }

    return details;
};

export const getRTONOCDetails = ({ bgcData }, currentValue) => {
    let details = {
        dataSource: "BGC Data"
    };
    let bgcData_rtoNocIssued = null;

    if(bgcData?.rtoNocIssued !== null) {
        bgcData_rtoNocIssued = RTO_NOC_ISSUED_FIELD_VALUE[bgcData.rtoNocIssued];
    }

    if (
        bgcData_rtoNocIssued !== null &&
        bgcData_rtoNocIssued?.toLowerCase() === currentValue?.toLowerCase()
    ) {
        details = {
            ...details,
            vaahanFlag: YES,
            vahanWebsite: bgcData_rtoNocIssued
        };
    } else {
        details = {
            ...details,
            vaahanFlag: NO,
            vahanWebsite: bgcData_rtoNocIssued
        };
    }

    return details;
};

export const getValidRegistrationNumberDetails = ({
    parivahan1,
    vahanWebsite,
    parseSms
}, currentValue) => {
    let details = {};
    let parivahanValue = null;
    let vahanWebsiteValue = null;
    let vahanSMSValue = null;
    let isNotEqual = false;
    if (parivahan1) {
        parivahanValue = parivahan1["Registration No:"];
    }
    if (vahanWebsite) {
        vahanWebsiteValue = vahanWebsite["Registration No:"];
    }
    if (parseSms && parseSms.length > 0) {
        vahanSMSValue = parseSms[0]["registrationNumber"];
    }
    if (vahanSMSValue && vahanSMSValue == currentValue) {
        details = {
            ...details,
            vaahanFlag: YES,
            vahanSms: vahanSMSValue
        };
    } else if (vahanSMSValue) {
        isNotEqual = true;
        details = {
            ...details,
            vaahanFlag: NO,
            vahanSms: vahanSMSValue
        };
    }
    if (parivahanValue && parivahanValue == currentValue) {
        details = {
            ...details,
            vaahanFlag: isNotEqual ? NO : YES,
            parivahanWebsite: parivahanValue
        };
    } else if (parivahanValue) {
        isNotEqual = true;
        details = {
            ...details,
            vaahanFlag: NO,
            parivahanWebsite: parivahanValue
        };
    }
    if (vahanWebsiteValue && vahanWebsiteValue == currentValue) {
        details = {
            ...details,
            vaahanFlag: isNotEqual ? NO : YES,
            vahanWebsite: vahanWebsiteValue
        };
    } else if (vahanWebsiteValue) {
        details = {
            ...details,
            vaahanFlag: NO,
            vahanWebsite: vahanWebsiteValue
        };
    }
    return details;
};

export const getValidOwnerNumberDetails = ({ bgcData }, currentValue) => {
    let details = {
        dataSource: "BGC Data"
    };
    let bgcData_ownerNo = null;
    
    if(bgcData?.ownerNoRetail) {
        bgcData_ownerNo = bgcData.ownerNoRetail;
    }

    if (bgcData_ownerNo !== null && bgcData_ownerNo == currentValue) {
        details = {
            ...details,
            vaahanFlag: YES,
            vahanWebsite: `${bgcData_ownerNo}`
        };
    } else {
        details = {
            ...details,
            vaahanFlag: NO,
            vahanWebsite: `${bgcData_ownerNo}`
        };
    }
    return details;
};

export const validateChassisNumber = ({ vahanWebsite }, currentValue) => {
    let details = {};

    let vahanChassisNo = vahanWebsite && vahanWebsite["Chassis No:"];
    
    if (vahanChassisNo == currentValue) {
        details = {
            ...details,
            vaahanFlag: YES,
            vahanWebsite: `${vahanChassisNo}`
        };
    } else {
        details = {
            ...details,
            vaahanFlag: NO,
            vahanWebsite: `${vahanChassisNo}`
        };
    }
    return details;
};

export const getValidHypothecationDetails = ({
    vahanWebsite
}, currentValue) => {
    let details = {};
    if (vahanWebsite) {
        const inspection_data = currentValue || "";
        const isFinancierNameVaahanWebsite = Object.keys(vahanWebsite).find(item => item.match(/financ/i));
        if (inspection_data) {
            if (inspection_data.match(/Yes/i)) {
                if (inspection_data.match(/Yes/i) && (isFinancierNameVaahanWebsite)) {
                    details = {
                        ...details,
                        vaahanFlag: YES
                    };
                } else {
                    details = {
                        ...details,
                        vaahanFlag: NO
                    };
                }
            } else {
                //case 2:
                if (inspection_data.match(/No/i) && !isFinancierNameVaahanWebsite) {
                    details = {
                        ...details,
                        vaahanFlag: YES
                    };
                } else {
                    details = {
                        ...details,
                        vaahanFlag: NO
                    };
                }
            }
            details = {
                ...details,
                vahanWebsite: vahanWebsite["financier's name:"] ? `${vahanWebsite["financier's name:"]}` : ""
            };
        }
    }
    return details;
};

export const getValidCustomerName = ({
    parivahan1,
    vahanWebsite,
    parseSms
}, currentValue) => {
    let details = {};
    let parivahanValue = null;
    let vahanWebsiteValue = null;
    let vahanSMSValue = null;
    let isNotEqual = false;
    if (parivahan1) {
        parivahanValue = parivahan1["Owner Name:"];
    }
    if (vahanWebsite) {
        vahanWebsiteValue = vahanWebsite["Owner Name:"];
    }
    if (parseSms && parseSms.length > 0) {
        vahanSMSValue = parseSms[0]["ownerName"];
    }
    if (vahanSMSValue && removeSpecialCharAndSpace(vahanSMSValue).toLowerCase() == removeSpecialCharAndSpace(currentValue).toLowerCase()) {
        details = {
            ...details,
            vaahanFlag: YES,
            vahanSms: vahanSMSValue
        };
    } else if (vahanSMSValue) {
        isNotEqual = true;
        details = {
            ...details,
            vaahanFlag: NO,
            vahanSms: vahanSMSValue
        };
    }
    if (parivahanValue && removeSpecialCharAndSpace(parivahanValue).toLowerCase() == removeSpecialCharAndSpace(currentValue).toLowerCase()) {
        details = {
            ...details,
            vaahanFlag: isNotEqual ? NO : YES,
            parivahanWebsite: parivahanValue
        };
    } else if (parivahanValue) {
        isNotEqual = true;
        details = {
            ...details,
            vaahanFlag: NO,
            parivahanWebsite: parivahanValue
        };
    }
    if (vahanWebsiteValue && removeSpecialCharAndSpace(vahanWebsiteValue).toLowerCase() == removeSpecialCharAndSpace(currentValue).toLowerCase()) {
        details = {
            ...details,
            vaahanFlag: isNotEqual ? NO : YES,
            vahanWebsite: vahanWebsiteValue
        };
    } else if (vahanWebsiteValue) {
        details = {
            ...details,
            vaahanFlag: NO,
            vahanWebsite: vahanWebsiteValue
        };
    }
    return details;
};

export const mutateDataForRoadTaxValidity = ({
    vahanWebsite
}, documentsMapper) => {
    const roadTaxPaidIndex = documentsMapper.findIndex(item => item.key == "roadTaxPaid");
    const roadTaxDateValidityIndex = documentsMapper.findIndex(item => item.key == "roadTaxDateValidity");
    let roadTaxNoMatch = false;
    if (vahanWebsite && vahanWebsite["MV Tax upto:"]) {
        if (((vahanWebsite["MV Tax upto:"].match(/life/i) || vahanWebsite["MV Tax upto:"].match(/ltt/i) || vahanWebsite["MV Tax upto:"].match(/ott/i)) &&
            (documentsMapper[roadTaxPaidIndex].value.match(/ott/i) || documentsMapper[roadTaxPaidIndex].value.match(/ltt/i)))) {
            documentsMapper[roadTaxPaidIndex].vaahanFlag = "yes";
            documentsMapper[roadTaxPaidIndex].vahanWebsite = vahanWebsite["MV Tax upto:"];
        }
        else {
            documentsMapper[roadTaxPaidIndex].vaahanFlag = "no";
            roadTaxNoMatch = true;
            documentsMapper[roadTaxPaidIndex].vahanWebsite = vahanWebsite["MV Tax upto:"];
        }
        if (isValidDate(vahanWebsite["MV Tax upto:"])) {
            if (formatDate(vahanWebsite["MV Tax upto:"]) == documentsMapper[roadTaxDateValidityIndex].value) {
                documentsMapper[roadTaxPaidIndex].vaahanFlag = roadTaxNoMatch ? "no" : "yes";
                documentsMapper[roadTaxPaidIndex].vahanWebsite = vahanWebsite["MV Tax upto:"];
                documentsMapper[roadTaxDateValidityIndex].vaahanFlag = roadTaxNoMatch ? "no" : "yes";
                documentsMapper[roadTaxDateValidityIndex].vahanWebsite = vahanWebsite["MV Tax upto:"];
            }
            else {
                documentsMapper[roadTaxPaidIndex].vaahanFlag = "no";
                documentsMapper[roadTaxPaidIndex].vahanWebsite = vahanWebsite["MV Tax upto:"];
                documentsMapper[roadTaxDateValidityIndex].vaahanFlag = "no";
                documentsMapper[roadTaxDateValidityIndex].vahanWebsite = vahanWebsite["MV Tax upto:"];

            }
        }
    }
};
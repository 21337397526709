import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import InspectionBike from "./component";
import { fetcBikeInspectionList, resetBikeInspectionByKey } from "./actions";
import {resetInspectionBikeDetailByKey} from "../inspection-review-bike/actions";

const mapStateToProps = ({
    bikeInspectionReducer,
    inspectionFiltersReducer,
    search
}) => ({
    bikeInspectionReducer, //combined reducer
    inspectionFiltersReducer, //combined reducer
    search //combined reducer
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetcBikeInspectionListConnect: fetcBikeInspectionList,
    resetBikeInspectionByKeyConnect: resetBikeInspectionByKey,
    resetInspectionBikeDetailByKeyConnect:resetInspectionBikeDetailByKey
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(InspectionBike);

import React, { useState, useEffect } from 'react';
import styles from './performance.module.css';
import { InspectionService } from '../../service';
import {
  getFromLocalStorage,
  isUserRoleBIkeQc,
  saveToLocalStorage,
} from '../../utils/helper';
import { LOCAL_STORAGE_KEYS, ROLE_KEYS } from '../../utils/app-constants';
import { getMocLeaderBoard } from '../../utils/mocApi';

const Performance = ({}) => {
  const [leaderData, setLeaderData] = useState([]);
  const [selfPerformance, setSelfPerformance] = useState('');
  let email = getFromLocalStorage(LOCAL_STORAGE_KEYS.EMAIL);
  let role = getFromLocalStorage(LOCAL_STORAGE_KEYS.ROLE);
  let isBikeQc = isUserRoleBIkeQc();

  const getNumberWithOrdinal = n => {
    var s = ['th', 'st', 'nd', 'rd'],
      v = n % 100;
    return n + (s[(v - 20) % 10] || s[v] || s[0]);
  };

  useEffect(() => {
    ROLE_KEYS.QC === role &&
      InspectionService.getLeaderBoardData(email)
        .then(res => {
          const {
            data: { detail },
          } = res;
          if (detail) {
            const { topPerformers, myPerformance } = detail;
            if (!!topPerformers && topPerformers.length > 0) {
              setLeaderData(topPerformers);
            }
            if (!!myPerformance) {
              setSelfPerformance(
                `My Performance : ${getNumberWithOrdinal(
                  myPerformance.rank,
                )} Rank (${myPerformance.totalNoReview} cars, ${
                  myPerformance.tat
                } min Processing TAT)`,
              );
            }
          }
        })
        .catch(e => {
          // let res = getMocLeaderBoard();
          // const { data: { detail } } = res;
          // if (detail) {
          //     const { topPerformers, myPerformance } = detail;
          //     if (!!topPerformers && topPerformers.length > 0) {
          //         setLeaderData(topPerformers);
          //     }
          //     if (!!myPerformance) {
          //         setSelfPerformance(`My Performance : ${myPerformance.rank} Rank (${myPerformance.totalNoReview} cars, ${myPerformance.totalReviewTime} min Processing TAT)`);
          //     }
          // }
          // console.log(e);
        });
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.performanceWrapper}>
      <p className={styles.performanceText}>{selfPerformance}</p>

      {!!leaderData && leaderData.length > 0 && (
        <div className={styles.performanceTextWrapper}>
          <ul className={styles.topPerformerHeading}>
            <li>Top Performer of day</li>
            <li>Cars</li>
            <li>TAT</li>
          </ul>
          <div className={styles.topers}>
            {leaderData
              .sort((x, y) => x.rank - y.rank)
              .map((item, index) => {
                return (
                  <ul>
                    <li className={styles.emailid}>
                      <span
                        className={`${styles.activeicon} ${
                          item.isActive ? styles.green : styles.red
                        }`}></span>
                      <span className={styles.lightBlue}>{`${index +
                        1} `}</span>
                      {item.user}
                    </li>
                    <li>{item.totalNoReview}</li>
                    <li>{item.tat}</li>
                  </ul>
                );
              })}

            {/* <ul>
                            <li><span className={styles.yellow}>1</span>  Dinesh Kumar</li>
                            <li>150</li>
                            <li>2</li>
                        </ul>
                        <ul>
                            <li><span className={styles.lightBlue}>2</span> Dinesh Kumar</li>
                            <li>150</li>
                            <li>2</li>
                        </ul>
                        <ul>
                            <li><span className={styles.orange}>3</span> Dinesh Kumar</li>
                            <li>150</li>
                            <li>2</li>
                        </ul> */}
          </div>
        </div>
      )}
    </div>
  );
};

export default Performance;

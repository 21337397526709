import React, { useEffect, useState } from "react";
import InputText from "../shared/input-text";
import { RESET_REDUCER_KEYS } from "../../utils/app-constants";
import { isUserRoleBIkeQc } from "../../utils/helper";

const RegistrationNumber = ({ detail, resetInspectionDetailByKeyConnect, inspectionDetails, bikeInspectionDetails, resetInspectionBikeDetailByKeyConnect }) => {

    let isBikeQc = isUserRoleBIkeQc();
    const [registrationNumber, setRegistrationNumber] = useState(detail.newValue ? detail.newValue : detail.value);
    const initialValue = detail.newValue ? detail.newValue : detail.value;
    const { key } = detail;
    const { documentsMapper } = inspectionDetails;
    const { bikeDocumentsMapper } = bikeInspectionDetails;

    useEffect(() => {
        return () => {
            // resetToInitialValue();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const resetToInitialValue = () => {
        let mapper = isBikeQc ? [...bikeDocumentsMapper] : [...documentsMapper];
        let newValue = "";
        let newId = "";
        mapper = mapper.map(map => {
            let element = { ...map };
            if (map.key === key) {
                element.newValue = newValue;
                element.newId = newId;
                element.checked = false;
            }

            return element;
        });
        resetInspectionDetailByKeyConnect(RESET_REDUCER_KEYS.DOCUMENTS_MAPPER, mapper);
    };

    const onChange = (e) => {
        const { target: { value } } = e;
        setRegistrationNumber(!!value ? value.toUpperCase() : value);
    };

    const onBlurText = (e) => {
        if (registrationNumber !== initialValue) {
            if (registrationNumber.length > 11) {
                setNewValue(false);
            } else {
                setNewValue(true);
            }
        }
    };

    const setNewValue = (isValid) => {
        let mapper = isBikeQc ? [...bikeDocumentsMapper] : [...documentsMapper];
        const newValue = registrationNumber;
        let dependentFields = [];
        mapper = mapper.map(map => {
            let element = { ...map };
            if (map.key === key) {
                element.newValue = newValue;
                element.newId = newValue;
                element.isValid = isValid;
                element.errorMsg = isValid ? "" : "Registration No should be 11 character long";

                if (element.dependentFields && element.dependentFields.length > 0) {
                    dependentFields = element.dependentFields;
                }
            }

            return element;
        });

        mapper = mapper.map(map => {
            let element = { ...map };
            if (dependentFields.find(field => field === element.key)) {
                element.checked = true;
            }

            return element;
        });

        if (isBikeQc) {
            resetInspectionBikeDetailByKeyConnect(RESET_REDUCER_KEYS.BIKE_DOCUMENTS_MAPPER, mapper);
        }
        else {
            resetInspectionDetailByKeyConnect(RESET_REDUCER_KEYS.DOCUMENTS_MAPPER, mapper);
        }
    };

    return (
        <div>
            <InputText
                placeholder="Registration Number"
                onChange={onChange}
                value={registrationNumber}
                onBlurCallback={onBlurText}
                maxLength={11}
            />
        </div>
    );
};

export default RegistrationNumber;

import { API_ENDPOINTS } from './api-constants';
import { convertObjectToParams, getCityIdParams } from '../utils/helper';
import { LOCAL_STORAGE_KEYS } from '../utils/app-constants';
export default api => {
  const getInspection = inspectionId => {
    return api.get(
      `${API_ENDPOINTS.INSPECTION}?inspectionId=${inspectionId}&utm_source=qc`,
    );
  };
  const getStoreLocation = () => {
    return api.get(`${API_ENDPOINTS.STORE_LOCATION}?utm_source=qc`);
  };
  const getInspectionList = (
    page,
    userEmail,
    searchQuery,
    storeCityId,
    storeId,
    category,
    assignedToSearch,
    appointmentId,
    startDate,
    endDate,
  ) => {
    if (!!appointmentId && appointmentId > 0) {
      return api.get(
        `${
          API_ENDPOINTS.INSPECTION_DATA_LIST
        }?appointmentId=${appointmentId}&page=${page}&records=${50}&user=${
          userEmail ? userEmail : '``'
        }${
          storeCityId && storeCityId.length > 0
            ? `${getCityIdParams('storeCityId', storeCityId)}`
            : ''
        }${
          storeId && storeId.length > 0
            ? `${getCityIdParams('storeId', storeId)}`
            : ''
        }${category ? `&category[]=${category}` : ''}${
          assignedToSearch ? `&assignTo=${assignedToSearch}` : ''
        }&endDate=${endDate}&startDate=${startDate}&utm_source=qc`,
      );
    } else {
      return api.get(
        `${
          API_ENDPOINTS.INSPECTION_DATA_LIST
        }?page=${page}&records=${50}&user=${userEmail ? userEmail : '``'}${
          storeCityId && storeCityId.length > 0
            ? `${getCityIdParams('storeCityId', storeCityId)}`
            : ''
        }${
          storeId && storeId.length > 0
            ? `${getCityIdParams('storeId', storeId)}`
            : ''
        }${category ? `&category[]=${category}` : ''}${
          assignedToSearch ? `&assignTo=${assignedToSearch}` : ''
        }&endDate=${endDate}&startDate=${startDate}&utm_source=qc`,
      );
    }
  };
  const getInspectionVersionList = (
    userEmail,
    appointmentId,
    startDate,
    endDate,
  ) => {
    // {{base_url}}/v2/inspection?user=test3@cars24.com&role=qc&client=web&enableDuration=0&logData=1&action=Search&endDate=2020-03-23&startDate=2020-03-01&appointmentId=1001599799
    return api.get(
      `${API_ENDPOINTS.INSPECTION_VERSION}?appointmentId=${appointmentId}&utm_source=qc`,
    );
  };
  const AssignToMe = (carId, params) => {
    return api.patch(`${API_ENDPOINTS.ASSIGN_TO}/${carId}?utm_source=qc`, params);
  };

  const getAssignee = (role = 'qc') => {
    return api.get(`${API_ENDPOINTS.Assignee}?role=${role}&utm_source=qc`);
  };
  const getInspectionDetail = carId => {
    return api.get(`${API_ENDPOINTS.INSPECTION_DETAIL}/${carId}?utm_source=qc`);
  };
  const getInspectionVersionDetail = (carId, version, inspectionStatus) => {
    return api.get(
      `${API_ENDPOINTS.RE_INSPECTION}?carId=${carId}&version=${version}&inspectionStatus=${inspectionStatus}&utm_source=qc&additionalImages=1`,
    );
  };
  const getStateList = () => {
    return api.get(`${API_ENDPOINTS.STATE}?utm_source=qc`);
  };
  const getRtoList = state => {
    return api.get(`/api/${API_ENDPOINTS.RTO}?stateId=${state}&utm_source=qc`);
  };
  const getCityList = state => {
    return api.get(`${API_ENDPOINTS.CITY}?state_id=${state}&utm_source=qc`);
  };
  const getMake = () => {
    return api.get(`${API_ENDPOINTS.MAKE}?utm_source=qc`);
  };
  const getModel = make => {
    return api.get(`${API_ENDPOINTS.MAKE}/${make}?utm_source=qc`);
  };

  const getVariantYear = model => {
    return api.get(`${API_ENDPOINTS.VARIANT_YEAR}?modelId=${model}&utm_source=qc`);
  };
  const getVariantYearByFuelType = (model, fuelType) => {
    return api.get(
      `${API_ENDPOINTS.VARIANT_YEAR}?modelId=${model}&fuelType=${fuelType}&utm_source=qc`,
    );
  };
  const getReinspection = (carId, version) => {
    return api.get(
      `${API_ENDPOINTS.RE_INSPECTION}?carId=${carId}&version=${version}&utm_source=qc`,
    );
  };

  const getExcludedList = () => {
    return api.get(
      `${API_ENDPOINTS.EXCLUDED_LIST}?condition[type]=config&condition[client]=insEngine&utm_source=qc`,
    );
  };
  const getSleeptimeConfig = () => {
    return api.get(`${API_ENDPOINTS.SLEEPTIME_CONFIG}?utm_source=qc`);
  };
  const submitReview = (carId, params) => {
    return api.patch(`${API_ENDPOINTS.SUBMIT_REVIEW}/${carId}?utm_source=qc`, params);
  };
  const getComments = section => {
    return api.get(`${API_ENDPOINTS.GET_COMMENTS}?section=${section}&utm_source=qc`);
  };
  const sendEmail = params => {
    return api.post(`${API_ENDPOINTS.SEND_EMAIL}?utm_source=qc`, params);
  };
  const uploadImage = params => {
    let headers = { 'Content-Type': 'multipart/form-data' };
    return api.post(`${API_ENDPOINTS.UPLOAD_IMAGE}?utm_source=qc`, params, {
      headers: headers,
    });
  };
  const logOut = params => {
    return api.post(`${API_ENDPOINTS.LOGOUT}?utm_source=qc`, params);
  };
  // const uploadImage = (params) => {
  //     return api.post(`${API_ENDPOINTS.UPLOAD_IMAGE}?utm_source=qc`, params);
  // };

  const getVaahanData = appId => {
    return api.get(`${API_ENDPOINTS.VAAHAN_DATA}/${appId}?utm_source=qc`);
  };
  const setReviewStartTime = params => {
    return api.post(`${API_ENDPOINTS.REVIEW_START_TIME}?utm_source=qc`, params);
  };

  const getBankList = () => {
    return api.get(`${API_ENDPOINTS.BANK_LIST}?status=active&utm_source=qc`);
  };

  const getCarVariantParameterData = () =>
    api.get(`${API_ENDPOINTS.CAR_VARIANT_PARAMETER_DATA}?utm_source=qc`);

  const getCarManufacturingYearsList = modelId =>
    api.get(`${API_ENDPOINTS.CAR_MANUFACTURING_YEARS}?modelId=${modelId}&utm_source=qc`);

  const submitCarVariantSuggestionData = params =>
    api.post(`${API_ENDPOINTS.CAR_VARIANT_SUGGESTION_DATA}?utm_source=qc`, params);

  const getCarVariantSuggestionData = param =>
    api.get(
      `${API_ENDPOINTS.CAR_VARIANT_SUGGESTION_DATA}?model_id=${param.modelId}&manufacturingYears=${param.years}&fuelType=${param.fuelType}&utm_source=qc`,
    );

  const updateCarVariantSuggestionData = (srNo, params) =>
    api.put(`${API_ENDPOINTS.CAR_VARIANT_SUGGESTION_DATA}/${srNo}?utm_source=qc`, params);

  const uploadCarVariantParameterImage = params =>
    api.patch(`${API_ENDPOINTS.UPLOAD_VARIANT_PARAMETER_IMAGE}?utm_source=qc`, params);

  // const deleteCarVariantData = param => api.patch(API_ENDPOINTS.DELETE_CAR_VARIANT);

  const submitCarVariantParameter = params =>
    api.post(`${API_ENDPOINTS.ADD_CAR_VARIANT_PARAMETER}?utm_source=qc`, params);

  const updateCarVariantParameter = (id, params) =>
    api.put(`${API_ENDPOINTS.UPDATE_VARIANT_PARAMETER}/${id}?utm_source=qc`, params);

  const getRefurbishmentDetails = appintmentId =>
    api.get(`${API_ENDPOINTS.REFURBISHMENT}/${appintmentId}&utm_source=qc`);

  const getRefurbishmentList = page =>
    api.get(`${API_ENDPOINTS.REFURBISHMENT}?page=${page}&utm_source=qc`);

  const getRefurbishmentSearch = (page, appointmentId) =>
    api.get(
      `${API_ENDPOINTS.REFURBISHMENT}?page=${page}&appointmentId=${appointmentId}&utm_source=qc`,
    );

  const getRefurbishmentVersionList = appointmentId =>
    api.get(
      `${API_ENDPOINTS.REFURBISHMENT_VERSION}?appointmentId=${appointmentId}&utm_source=qc`,
    );

  const getRefurbishmentVersionDetail = ({ appointmentId, status, version }) =>
    api.get(
      `${API_ENDPOINTS.REFURBISHMENT_VERSION_DETAIL}?appointmentId=${appointmentId}&status=${status}&version=${version}&utm_source=qc`,
    );

  const assignRefurbishmentToQc = params =>
    api.post(`${API_ENDPOINTS.REFURBISHMENT_ASSIGN}?utm_source=qc`, params);

  const submitRefurbishmentData = (params, appointmentId) =>
    api.patch(`${API_ENDPOINTS.REFURBISHMENT}/${appointmentId}?utm_source=qc`, params);

  const getQcAuditVersionData = appointmentId =>
    api.get(
      `${API_ENDPOINTS.AUDIT_VERSION_HISTORY}?appointmentId=${appointmentId}&utm_source=qc`,
    );

  const getReinspectionEmailLogData = appointmentId =>
    api.get(
      `${API_ENDPOINTS.RE_INSPECTION_EMAIL_LOG}?appointmentId=${appointmentId}&utm_source=qc`,
    );

  const getCustomerAuditData = appointmentId => {
    return api.get(
      `${API_ENDPOINTS.CUSTOMER_AUDIT_DATA}?appointmentId=${appointmentId}&utm_source=qc`,
    );
  };

  const submitCustomerAuditData = params =>
    api.post(`${API_ENDPOINTS.CUSTOMER_AUDIT_DATA}?utm_source=qc`, params);

  const getVariantParameterData = () =>
    api.get(`${API_ENDPOINTS.VARIANT_PARAMETER_DATA}?utm_source=qc`);

  const getCarMake = () => api.get(`${API_ENDPOINTS.CAR_MAKE}?utm_source=qc`);

  const getCarModalList = id => api.get(`${API_ENDPOINTS.CAR_MAKE}/${id}?utm_source=qc`);

  const getCarVariantSequence = param =>
    api.get(
      `${API_ENDPOINTS.CAR_VARIANT_SEQUENCE}?model_id=${param.modelId}&manufacturingYears=${param.years}&fuelType=${param.fuelType}&utm_source=qc`,
    );

  const submitCarVariantSequence = params =>
    api.post(`${API_ENDPOINTS.CAR_VARIANT_SEQUENCE}?utm_source=qc`, params);

  const deleteVariantSuggestionData = srNo =>
    api.delete(`${API_ENDPOINTS.CAR_VARIANT_SUGGESTION_DATA}/${srNo}?utm_source=qc`);

  const enableCarVariantSuggestion = params =>
    api.patch(
      `${API_ENDPOINTS.ENABLE_DISBALE_VARIANT_SUGGESTION}/${params.model_id}?utm_source=qc`,
      params,
    );

  // Bike Related APIs
  const getBikeInspectionList = (
    page,
    userEmail,
    searchQuery,
    appointmentId,
  ) => {
    if (!!appointmentId && appointmentId > 0) {
      return api.get(
        `${
          API_ENDPOINTS.BIKE_INSPECTION
        }?appointmentId=${appointmentId}&offset=${page}&records=${50}&status=READY_FOR_REVIEW&utm_source=qc`,
      );
    } else {
      // return api.get(`${API_ENDPOINTS.BIKE_INSPECTION}?offset=${page}&records=${500}&status=READY_FOR_REVIEW`);
      return api.get(
        `${
          API_ENDPOINTS.BIKE_INSPECTION
        }?status=READY_FOR_REVIEW&daysElapsed=7&limit=${500}&utm_source=qc`,
      );
    }
  };
  const getBikeInspectionDetail = appointmentId => {
    // return api.get(`${API_ENDPOINTS.BIKE_INSPECTION_DETAIL}/${bikeId}`);
    return api.get(`${API_ENDPOINTS.BIKE_INSPECTION_DETAIL}${appointmentId}`);
    // return api.get(`${API_ENDPOINTS.BIKE_INSPECTION_DETAIL}?appointmentId=${appointmentId}`);
  };
  const assignQcForBike = (appointmentId, params) => {
    let headers = { 'Content-Type': 'multipart/form-data' };
    return api.post(
      `${API_ENDPOINTS.QC_ASSIGN_TO_BIKE}/${appointmentId}/qc/assign?utm_source=qc`,
      params,
      { headers: headers },
    );
  };
  const submitBikeStatus = (params, bikeId, appointmentId) => {
    let headers = { 'Content-Type': 'multipart/form-data' };
    return api.post(
      `${API_ENDPOINTS.BIKE_STATUS_SUBMIT}/${appointmentId}/qc?utm_source=qc`,
      params,
      { headers: headers },
    );
  };
  const uploadBikeImage = (params, appointmentId) => {
    let headers = { 'Content-Type': 'multipart/form-data' };
    return api.put(
      `${API_ENDPOINTS.UPLOAD_BIKE_IMAGE}/${appointmentId}?utm_source=qc`,
      params,
      { headers: headers },
    );
  };

  const getBikeMake = () => {
    return api.get(`${API_ENDPOINTS.BIKE_MAKE}?utm_source=qc`);
  };
  const getBikeModel = make => {
    return api.get(`${API_ENDPOINTS.BIKE_MODEL}?makeCode=${make}&utm_source=qc`);
  };
  const getBikeVariant = model => {
    return api.get(`${API_ENDPOINTS.BIKE_VARIANT}?model_id=${model}&utm_source=qc`);
  };
  const pushAppoinetmentData = (params, appointmentId) => {
    return api.post(
      `${API_ENDPOINTS.BIKE_STATUS_SUBMIT}/${appointmentId}?utm_source=qc`,
      params,
    );
  };

  const getLeaderBoardData = email => {
    return api.get(
      `${API_ENDPOINTS.LEADER_BOARD}?email=${email}&numofperformer=all&utm_source=qc`,
    );
  };
  const getBikePublicConfigData = () => {
    return api.get(`${API_ENDPOINTS.BIKE_PUBLIC_CONFIG}?utm_source=qc`);
  };
  const createPdfForBike = appointmentId => {
    return api.post(
      `${API_ENDPOINTS.CREATE_PDF_FOR_BIKE}/${appointmentId}/generate?utm_source=qc`,
    );
  };
  const getBikeReport = (appointmentId, version) => {
    return api.get(
      `${API_ENDPOINTS.BIKE_REPORT}/${appointmentId}?version=${version}?utm_source=qc`,
    );
  };

  const getBikeReportWithoutVersion = appointmentId => {
    return api.get(`${API_ENDPOINTS.BIKE_REPORT}/${appointmentId}?utm_source=qc`);
  };

  const getBikeVersion = (appointmentId, version) =>
    api.get(
      `${API_ENDPOINTS.BIKE_VERSION}/${appointmentId}?version=${version}?utm_source=qc`,
    );

  const getSellOnline = appointmentId =>
    api.get(`${API_ENDPOINTS.SELL_ONLINE}?appointmentId=${appointmentId}?utm_source=qc`);

  const postStatusSellOnline = (appointmentId, params) =>
    api.put(`${API_ENDPOINTS.SELL_ONLINE}/${appointmentId}?utm_source=qc`, params);
  // End Bike Related APIS

  const fetchStoreCancellationFactorList = params =>
    api.get(
      `${API_ENDPOINTS.STORE_CANCELLATION_FACTOR}${convertObjectToParams(
        params,
      )}&utm_source=qc`,
    );

  const addCancellationFactor = data =>
    api.post(`${API_ENDPOINTS.STORE_CANCELLATION_FACTOR}?utm_source=qc`, data);

  const updateCancellationFactor = (storeId, data) =>
    api.patch(`${API_ENDPOINTS.STORE_CANCELLATION_FACTOR}/${storeId}?utm_source=qc`, data);

  const uploadCancellationFactor = file => {
    let updatedBy = localStorage.getItem(LOCAL_STORAGE_KEYS.EMAIL);

    const formData = new FormData();
    formData.append('file', file);
    formData.append('updatedBy', updatedBy);
    return api.put(
      `${API_ENDPOINTS.STORE_CANCELLATION_FACTOR}/upload?utm_source=qc`,
      formData,
    );
  };

  const getRejectionRemark = params => {
    return api.get(
      `${API_ENDPOINTS.REJECTION_REMARK}${convertObjectToParams(params)}&utm_source=qc`,
    );
  };

  const saveRejectionRemark = data => {
    let updatedBy = localStorage.getItem(LOCAL_STORAGE_KEYS.EMAIL);
    let role = localStorage.getItem(LOCAL_STORAGE_KEYS.ROLE);

    const formData = new FormData();
    formData.append('carId', data.carId);
    formData.append('section', data.section);
    if (data.hashKey) formData.append('hashKey', data.hashKey);
    formData.append('part', data.part);
    if (data.subPart) formData.append('subPart', data.subPart);
    formData.append('remarkType', data.remarkType);
    if (data.file) formData.append('file', data.file, 'blob.mp3');
    if (data.remark) formData.append('remark', data.remark);
    formData.append('type', 'rejectionRemark');
    formData.append('role', role);
    formData.append('inspectionStatus', 'smReview');
    formData.append('user', updatedBy);

    return api.post(`${API_ENDPOINTS.REJECTION_REMARK}?utm_source=qc`, formData);
  };

  const updateCorrectRegNumber = payload =>
    api.post(`update-correct-reg-number?utm_source=qc`, payload);

  const uploadCjLeaves = file => {
    const updatedBy = localStorage.getItem(LOCAL_STORAGE_KEYS.EMAIL);
    const formData = new FormData();
    formData.append('leaveFile', file);
    formData.append('uploadedBy', updatedBy);
    return api.post(`${API_ENDPOINTS.UPADTE_CJ_LEAVES}?utm_source=qc`, formData);
  };

  const getNocIssuedForList = () => {
    return api.get(`/api/${API_ENDPOINTS.RTO}?timestamp=0&utm_source=qc`);
  };

  const uploadRtoNocAvailableImage = payload => {
    return api.post(`${API_ENDPOINTS.UPLOAD_IMAGE}?utm_source=qc`, payload);
  };

  const fetchPreInspectionData = appointmentId => {
    return api.get(
      `${API_ENDPOINTS.PRE_INSPECTION_DATA_POINT}/${appointmentId}?utm_source=qc`,
    );
  };

  const updatePreInspectionImage = (params = {}) => {
    return api.post(
      `${API_ENDPOINTS.PRE_INSPECTION_DATA_POINT}?utm_source=qc`,
      params,
    );
  };

  const uploadVideoTutorial = payload =>
    api.post(`upload-video-tutorial?utm_source=qc`, payload);

  const getInspectionRestrictImages = carId => {
    return api.get(
      `${API_ENDPOINTS.INSPECTION_RESTRICTED_IMAGES}?carId=${carId}&utm_source=qc`,
    );
  };

  return {
    getInspectionRestrictImages,
    updatePreInspectionImage,
    fetchPreInspectionData,
    uploadCjLeaves,
    updateCorrectRegNumber,
    getInspection,
    getStoreLocation,
    getInspectionList,
    getInspectionDetail,
    getAssignee,
    getStateList,
    getRtoList,
    getCityList,
    getMake,
    getModel,
    getVariantYear,
    AssignToMe,
    getExcludedList,
    getSleeptimeConfig,
    submitReview,
    getReinspection,
    getVariantYearByFuelType,
    getComments,
    sendEmail,
    getInspectionVersionList,
    getInspectionVersionDetail,
    logOut,
    uploadImage,
    getVaahanData,
    setReviewStartTime,
    getBikeInspectionList,
    getBikeInspectionDetail,
    submitBikeStatus,
    uploadBikeImage,
    assignQcForBike,
    getBikeMake,
    getBikeModel,
    getBikeVariant,
    pushAppoinetmentData,
    getLeaderBoardData,
    getBankList,
    getBikePublicConfigData,
    getCarVariantParameterData,
    getCarModalList,
    getCarManufacturingYearsList,
    submitCarVariantSuggestionData,
    uploadCarVariantParameterImage,
    submitCarVariantParameter,
    updateCarVariantParameter,
    createPdfForBike,
    getBikeReport,
    getBikeReportWithoutVersion,
    getRefurbishmentDetails,
    getRefurbishmentList,
    getRefurbishmentVersionList,
    getRefurbishmentVersionDetail,
    assignRefurbishmentToQc,
    submitRefurbishmentData,
    getQcAuditVersionData,
    getReinspectionEmailLogData,
    getVariantParameterData,
    getCarMake,
    getCarVariantSuggestionData,
    // deleteCarVariantData,
    getCarVariantSequence,
    submitCarVariantSequence,
    deleteVariantSuggestionData,
    updateCarVariantSuggestionData,
    getCustomerAuditData,
    submitCustomerAuditData,
    getBikeVersion,
    enableCarVariantSuggestion,
    getRefurbishmentSearch,
    getSellOnline,
    postStatusSellOnline,
    fetchStoreCancellationFactorList,
    addCancellationFactor,
    updateCancellationFactor,
    uploadCancellationFactor,
    getRejectionRemark,
    saveRejectionRemark,
    getNocIssuedForList,
    uploadRtoNocAvailableImage,
    uploadVideoTutorial,
  };
};

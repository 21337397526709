import { createReducer } from "reduxsauce";
import Types from "./types";

export const INITIAL_STATE = {
    isLoading: false,
    carVariantParameterList: [],
    carVariantList: [],
    carModelsList: [],
    carManufacturingYears: [],
    carMakeList: [],
    error: null
};

const fetchCarVariantParameterData = (state = INITIAL_STATE) => {
    return { ...state, isLoading: true};
};

const fetchCarVariantParameterDataSuccess = (state = INITIAL_STATE, { data }) => {
    const { detail } = data;
    return { ...state, carVariantParameterList: detail, isLoading: false};
};

const fetchCarVariantParameterDataFailure = (state = INITIAL_STATE, { error }) => {
    return { ...state, error, carVariantParameterList: [], isLoading: false };
};

const fetCarModelsList = ( state = INITIAL_STATE) => {
    return { ...state, isLoading: true};
};

const fetCarModelsListSuccess = (state =INITIAL_STATE, { data }) => {
    const { detail } = data;
    return { ...state, carModelsList: detail, isLoading: false};
};

const fetCarModelsListFailure = (state = INITIAL_STATE, { error }) => {
    return { ...state, error, carModelsList: [], isLoading: false };
};

const fetchCarManufacturingYeasList = (state = INITIAL_STATE) => {
    return { ...state, isLoading: true};
};

const fetchCarManufacturingYeasListSuccess = (state = INITIAL_STATE, { data }) => {
    const { detail } = data;
    return { ...state, carManufacturingYears: detail, isLoading: false};
};

const fetchCarManufacturingYeasListFailure = (state = INITIAL_STATE, { error }) => {
    return { ...state, error, carManufacturingYears: [], isLoading: false };
};

const fetchCarMakeList = (state = INITIAL_STATE) => {
    return { ...state, isLoading: true};
};

const fetchCarMakeListSuccess = (state =INITIAL_STATE, { data }) => {
    const { detail } = data;
    return { ...state, carMakeList: detail, isLoading: false};
};

const fetchCarMakeListFailure =  (state = INITIAL_STATE, { error }) => {
    return { ...state, error, carMakeList: [], isLoading: false };
};

export const HANDLERS = {
    [Types.FETCH_CAR_VARIANT_PARAMETER_DATA]: fetchCarVariantParameterData,
    [Types.FETCH_CAR_VARIANT_PARAMETER_DATA_SUCCESS]: fetchCarVariantParameterDataSuccess,
    [Types.FETCH_CAR_VARIANT_PARAMETER_DATA_FAILURE]: fetchCarVariantParameterDataFailure,
    [Types.FETCH_CAR_MODAL]: fetCarModelsList,
    [Types.FETCH_CAR_MODAL_SUCCESS]: fetCarModelsListSuccess,
    [Types.FETCH_CAR_MODAL_FAILURE]: fetCarModelsListFailure,
    [Types.FETCH_CAR_MANUFACTURING_LIST]: fetchCarManufacturingYeasList,
    [Types.FETCH_CAR_MANUFACTURING_LIST_SUCCESS]: fetchCarManufacturingYeasListSuccess,
    [Types.FETCH_CAR_MANUFACTURING_LIST_FAILURE]: fetchCarManufacturingYeasListFailure,
    [Types.FETCH_CAR_MAKE_LIST]: fetchCarMakeList,
    [Types.FETCH_CAR_MAKE_LIST_SUCCESS]: fetchCarMakeListSuccess,
    [Types.FETCH_CAR_MAKE_LIST_FAILURE]: fetchCarMakeListFailure
};

export default createReducer(INITIAL_STATE, HANDLERS);

import { createActions } from "reduxsauce";

const { Types, Creators } = createActions({
    showLoader: null,
    hideLoader: null,
    fetchXFactorList: null,
    fetchXFactorListSuccess: ['data'],
    fetchXFactorListError: ['data'],
    addUpdateSuccess: null,
    addUpdateError: null,
    addUpdateReset: null,
    setToast: ['data'],
    resetToast: null,
    updateFilter: ['data']
}, { prefix: 'x-factor/' });

export { Types, Creators };
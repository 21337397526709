import { createActions } from "reduxsauce";

const { Types, Creators } = createActions({
    showLoader: null,
    hideLoader: null,
    fetchGsUnflaggingListSuccess: ['data'],
    fetchGsUnflaggingListError: ['error'],
    fetchGsUnflaggingDetailsSuccess: ['data'],
    fetchGsUnflaggingDetailsError: ['error'],
    setFilter: ['filter'],
    resetFilters: null,
}, { prefix: 'gs-unflagging/' });

export { Types, Creators };
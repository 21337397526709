import React from "react";

export const currentEnv = process.env.REACT_APP_HOST_ENV;

export const configuration = () => {
    if (currentEnv === 'PRODUCTION') {
        return {
            apiKey: 'AIzaSyCy5w4ZeoHUpxqZGr2lS8iAjeHwcDuQAUI',
            authDomain: 'inspection-app-f00f3.firebaseapp.com',
            databaseURL: 'https://inspection-app-f00f3.firebaseio.com',
            projectId: 'inspection-app-f00f3',
            storageBucket: 'inspection-app-f00f3.appspot.com',
            messagingSenderId: '195481465636',
            appId: '1:195481465636:web:f7ddf577e43f36f7',
            databaseName: 'inspection-prod'
        };
    }
    else if (currentEnv === 'STAGING') {
        return {
            //STG  working
            apiKey: "AIzaSyCbukkt5kLnUo3etLy7AxiwgNn7HrxwyQo",
            authDomain: "inspection-qa1.firebaseapp.com",
            databaseURL: "https://inspection-qa1.firebaseio.com",
            projectId: "inspection-qa1",
            storageBucket: "inspection-qa1.appspot.com",
            messagingSenderId: "390778933320",
            appId: "1:390778933320:web:405445832ba0e51459c87d",
            measurementId: "G-49L9HH1TV6",
            databaseName: 'inspection-qa1'
            //End STG  working
        };
    }
    else {
        return {
            //STG  working
            apiKey: "AIzaSyDSqmuK9eBqAW3W_rGxcYnPCF2U2VHt0P8",
            authDomain: "inspection-qa.firebaseapp.com",
            databaseURL: "https://inspection-qa.firebaseio.com",
            projectId: "inspection-qa",
            storageBucket: "inspection-qa.appspot.com",
            messagingSenderId: "856920753990",
            appId: "1:856920753990:web:7fada2328fd20991",
            databaseName: 'inspection-qa'
            //End STG  working
        };
    }
};
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CarVariant from "./component";
import { 
    fetchCarVariantParameterDataList, 
    fetchCarMakeList,
    fetchCarModelList
} from './actions';

const mapStateToProps = ({carVariantReducer}) => ({carVariantReducer});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchCarVariantParameterDataList,
    fetchCarMakeList,
    fetchCarModelList
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CarVariant);

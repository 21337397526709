/* eslint-disable no-loop-func */
import { saveAs } from 'file-saver';
import ManufacturingDate from '../components/manufacturing-date';
import React from 'react';
import {
  LOCAL_STORAGE_KEYS,
  monthNames,
  TIME_DIFF_IN_MINUTES,
  CAR_ID_REGEX,
  USER_ROLEACCESS_PATHS,
  ROLE_KEYS,
  MONTH_MAP,
  BLANK_ADDITIONAL_IMAGES,
  INSPECTION_REVIEW_ITEMS,
  MONTH_YEAR,
  BIKE_DETAIL_KEYS,
  BIKE_IMAGE_TYPE,
  RESET_REDUCER_KEYS,
  INSPECTION_STATUS_KEYS,
} from './app-constants';
import FitnessUpto from '../components/fitness-upto';
import RegistrationState from '../components/registration-state';
import Rto from '../components/rto';
import RegistrationCity from '../components/registration-city';
import RegistrationNumber from '../components/registration-number';
import moment from 'moment';
import RtoNoc from '../components/rto-noc';
import NoOfOwners from '../components/no-of-owners';
import ChassisNo from '../components/chassis-no';
import ChassisNoEmbossing from '../components/chassis-no-embossing';
import UnderHypothecation from '../components/under-hypothecation';
import CustomerName from '../components/customer-name';
import RcAvailability from '../components/rc-availability';
import RcCondition from '../components/rc-condition';
import InsuranceType from '../components/insurance-type';
import InsuranceExpiryDate from '../components/insurance-expiry-date';
import NoClaimBonus from '../components/no-claim-bonus';
import NoClaimBonusPercentage from '../components/no-claim-bonus-percentage';
import CngLpgRc from '../components/cng-lpg-rc';
import OdometerReading from '../components/odometer-reading';
import { toast } from 'react-toastify';
import RegistrationYear from '../components/registration-year';
import RegistrationMonth from '../components/registration-month';
import { InspectionService } from '../service';
import ChassisNoOnRc from '../components/chassis-no-on-rc';
import MismatchInRc from '../components/mismatch-in-rc';
import RoadTaxPaid from '../components/road-tax-paid';
import RoadTaxDateValidity from '../components/road-tax-date-validity';
import FinancierList from '../components/financier-list';
import CustomerNumber from '../components/customer-number';
import RcChasisNumber from '../components/rc-chasis-number';
import BikeRcAvailability from '../components/bike-rc-availability';
import BikeInsuranceAvailability from '../components/bike-insurance-availability';
import { getMonth } from 'date-fns';
import {
  getValidRegistrationYearDetails,
  getValidRegistrationMonthDetails,
  getValidFitnessDetails,
  getRTONOCDetails,
  getValidRegistrationNumberDetails,
  getValidOwnerNumberDetails,
  getValidHypothecationDetails,
  getValidCustomerName,
  mutateDataForRoadTaxValidity,
  validateRtoWithBgc,
  validateChassisNumber,
  getValidCustomerNameDetails,
  getValidRoadTaxPaidDetails,
} from './vahan-mapper-helper';
import FuelType from '../components/fuel-type';
import RtoCode from '../components/rto-code';
import LoanStatusEdit from '../components/loan-status-edit';
import RcAtDelivery from '../components/rc-at-delivery';
import InfoIcon from '../components/shared/info-icon/component';
import RtoNocAvailability from '../components/rto-noc-availability';
import RtoNocIssued from '../components/rto-noc-issued';
import RtoNmcAvailability from '../components/rto-nmc-availability';
import RtoNmcDate from '../components/rto-nmc-date';
import { getSessionToken } from '@descope/react-sdk';
// import RtoNocAvailabilityImage from '../components/rto-noc-availability-image';
export const YES_NO = {
  YES: 'yes',
  NO: 'no',
};

export const Fuel_Type_Petrol = 'petrol';

export const navigateToHomeWithDelay = history => {
  let redirectUrl = isUserRoleBIkeQc() ? '/inspection-bike' : '/inspection';
  setTimeout(() => {
    history.push(redirectUrl);
  }, 3000);
};

export const showToastMessages = (message, isSuccess = true) => {
  toast.dismiss();
  if (isSuccess) {
    toast.success(message, {
      position: toast.POSITION.TOP_CENTER,
      hideProgressBar: true,
      autoClose: 3000,
    });
  } else {
    toast.error(message, {
      position: toast.POSITION.TOP_CENTER,
      // autoClose: false,
      hideProgressBar: true,
      autoClose: 5000,
    });
  }
};

export const saveToLocalStorage = (key, value) => {
  localStorage.setItem(key, value);
};

export const getFromLocalStorage = key => {
  return localStorage.getItem(key);
};

export const removeFromLocalStorage = key => {
  localStorage.removeItem(key);
};

export const setImageHostToLocalStorageForBike = imageHostUrl => {
  let imageUrlSubPart = imageHostUrl.split('?');
  if (!!imageUrlSubPart && imageUrlSubPart.length > 0) {
    let imageHost = imageUrlSubPart[0].replace('{imgUri}', '');
    if (!!imageHost) {
      saveToLocalStorage(LOCAL_STORAGE_KEYS.IMAGE_HOST_URL, imageHost);
    }
    if (!!imageUrlSubPart && imageUrlSubPart.length > 1) {
      saveToLocalStorage(
        LOCAL_STORAGE_KEYS.IMAGE_QUALITY_PARAM,
        '?' + imageUrlSubPart[1],
      );
    }
  }
};

export const setVideoBaseUrlToLocalStorageForBike = videoBaseUrl => {
  saveToLocalStorage(LOCAL_STORAGE_KEYS.VIDEO_HOST_URL, videoBaseUrl);
};

export const hasTimeElapsed = (date, timeInMinutes) => {
  const timeDiff = new Date().getTime() - new Date(date).getTime();
  if (timeDiff / TIME_DIFF_IN_MINUTES >= timeInMinutes) {
    return true;
  } else {
    return false;
  }
};

// get method for timedifference using momen
export const getTimeDifferenceInMinutes = fromDate => {
  fromDate = new Date(fromDate.replace(/(\d{2})-(\d{2})-(\d{4})/, '$2/$1/$3'));
  var start_date = moment(fromDate, 'YYYY-MM-DD HH:mm:ss');
  var end_date = moment(new Date(), 'YYYY-MM-DD HH:mm:ss');
  var duration = moment.duration(end_date.diff(start_date));
  var mins = duration.minutes();
  return mins;
};

// get method for timedifference
export const getTimeDifferenceFromCurrentTime = fromDate => {
  try {
    if (fromDate) {
      fromDate = new Date(
        fromDate.replace(/(\d{2})-(\d{2})-(\d{4})/, '$2/$1/$3'),
      );
      const timeDiff = new Date().getTime() - new Date(fromDate).getTime();
      const timeDiffInMin = Math.floor(timeDiff / TIME_DIFF_IN_MINUTES);
      return timeDiffInMin;
    } else {
      // return -1;
    }
  } catch (Exception) {
    // return -1;
  }
  // return Math.floor(new Date().getTime() - fromDate.getTime());
};

// get method for timedifference
export const getTimeDifference = (fromDate, toDate) => {
  return Math.floor(toDate.getTime() - fromDate.getTime());
};

export const getEditableComponent = (key, detail) => {
  switch (key) {
    case BIKE_DETAIL_KEYS.RC_Availability:
      return <BikeRcAvailability detail={detail} type="year" />;
    case BIKE_DETAIL_KEYS.Insurance_Availability:
      return <BikeInsuranceAvailability detail={detail} type="year" />;
    case 'year':
      return <ManufacturingDate detail={detail} type="year" />;
    case 'month':
      return <ManufacturingDate detail={detail} type="month" />;
    case 'registrationMonth':
      return <RegistrationMonth detail={detail} type="month" />;
    case 'registrationYear':
    case 'Model Year (Registration Year)':
      return <RegistrationYear detail={detail} type="year" />;
    case BIKE_DETAIL_KEYS.Manufacturing_Date:
    case BIKE_DETAIL_KEYS.Registration_Date:
    case 'fitnessUpto':
      return <FitnessUpto detail={detail} />;
    case 'registrationState':
    case BIKE_DETAIL_KEYS.Registration_State:
      return <RegistrationState detail={detail} />;
    case BIKE_DETAIL_KEYS.RTO_Code:
    case 'rto':
      return <Rto detail={detail} />;
    case 'registrationCity':
      return <RegistrationCity detail={detail} />;
    case 'regNo':
    case 'registrationNumber':
    case BIKE_DETAIL_KEYS.Registration_Number:
      return <RegistrationNumber detail={detail} />;
    case 'rtoNocIssued':
      return <RtoNoc detail={detail} />;
    case 'noOfOwners':
    case BIKE_DETAIL_KEYS.Ownership_Number:
      return <NoOfOwners detail={detail} />;
    case 'ownerNumber':
      return <NoOfOwners detail={detail} />;
    case 'Chassis Number on RC': //BIKE_DETAIL_KEYS.Chassis_Number_on_RC:
      return <RcChasisNumber detail={detail} />;
    case 'chassisNumber':
    case BIKE_DETAIL_KEYS.Chassis_Number:
      return <ChassisNo detail={detail} />;
    case 'chassisNumberEmbossing':
    case BIKE_DETAIL_KEYS.Chassis_Number_Embossing:
      return <ChassisNoEmbossing detail={detail} />;
    case BIKE_DETAIL_KEYS.Hypothecation:
    case BIKE_DETAIL_KEYS.Duplicate_Key:
    case BIKE_DETAIL_KEYS.Is_Scrap:
    case BIKE_DETAIL_KEYS.Loan_Paid:
    case BIKE_DETAIL_KEYS.Bank_NOC:
    case BIKE_DETAIL_KEYS.Are_there_changes_during_ReInspection:
    case 'isUnderHypothecation':
      return <UnderHypothecation detail={detail} placeHolderValue="" />;
    case BIKE_DETAIL_KEYS.Customer_Mobile:
      return <CustomerNumber detail={detail} />;
    case 'customerName':
    case BIKE_DETAIL_KEYS.Customer_Name_on_RC:
    case BIKE_DETAIL_KEYS.Financier_Name:
      return <CustomerName detail={detail} />;
    case 'rcAvailability':
      return <RcAvailability detail={detail} />;
    case 'rcCondition':
    case BIKE_DETAIL_KEYS.RC_Condition:
      return <RcCondition detail={detail} />;
    case 'insuranceType':
    case BIKE_DETAIL_KEYS.Insurance_Type:
      return <InsuranceType detail={detail} />;
    case 'insuranceExpiry':
    case BIKE_DETAIL_KEYS.Insurance_Expiry:
      return <InsuranceExpiryDate detail={detail} />;
    case 'noClaimBonus':
      return <NoClaimBonus detail={detail} />;
    case 'noClaimBonuspercent':
      return <NoClaimBonusPercentage detail={detail} />;
    case 'cngLpgFitment':
      return <CngLpgRc detail={detail} />;
    case 'odometerReading':
    case BIKE_DETAIL_KEYS.Odometer_reading:
      return <OdometerReading detail={detail} />;
    case 'chassisNoOnRc':
      return <ChassisNoOnRc detail={detail} />;
    case 'misMatchInRc':
      return <MismatchInRc detail={detail} />;
    case 'rcAtDelivery':
      return <RcAtDelivery detail={detail} />;
    case 'roadTaxPaid':
      return <RoadTaxPaid detail={detail} />;
    case 'roadTaxDateValidity':
      return <RoadTaxDateValidity detail={detail} />;
    case 'financierName':
      return <FinancierList detail={detail} />;
    case 'loanStatus':
      return <LoanStatusEdit detail={detail} />;
    case 'rtoNocAvailable':
      return <RtoNocAvailability detail={detail} />;
    case 'rtoNocIssuedFor':
      return <RtoNocIssued detail={detail} />;
    case 'rtoNocIssuedDate':
      return <RtoNmcDate detail={detail} />;
    case 'rtoNmcAvailable':
      return <RtoNmcAvailability detail={detail} />;
    // case 'rtoNocAvailableImage':
    // return <RtoNocAvailabilityImage detail={detail} />;
    case BIKE_DETAIL_KEYS.Fuel_Type:
    case BIKE_DETAIL_KEYS.Exhaust_Smoke_Colour:
      return <FuelType detail={detail} />;
    case BIKE_DETAIL_KEYS.REGISTRATION_CITY_RTO_CODE:
      return <RtoCode detail={detail} />;
    default:
      break;
  }
};
const getRegMonth = val => {
  if (!!val) {
    let item = MONTH_YEAR.find(x => x.id === val);
    if (!!item) {
      return item.value;
    }
  }
};
export const getBikeDocumentsMapper = data => {
  const { inspectionData, regNo, regState } = data;
  const documents = [
    // {
    //     key: BIKE_DETAIL_KEYS.Customer_Mobile,
    //     text: BIKE_DETAIL_KEYS.Customer_Mobile,
    //     checked: false,
    //     value: `${!!inspectionData[BIKE_DETAIL_KEYS.Customer_Mobile] ? inspectionData[BIKE_DETAIL_KEYS.Customer_Mobile] : ""}`,
    //     id: `${inspectionData[BIKE_DETAIL_KEYS.Customer_Mobile] ? inspectionData[BIKE_DETAIL_KEYS.Customer_Mobile] : ""}`,
    //     newValue: "",
    //     newId: "",
    //     isEditable: true,
    //     isVisible: true,
    //     isDisabled: false
    // },
    {
      key: BIKE_DETAIL_KEYS.REGISTRATION_YEAR,
      text: BIKE_DETAIL_KEYS.REGISTRATION_YEAR,
      checked: false,
      value: `${
        !!inspectionData[BIKE_DETAIL_KEYS.REGISTRATION_YEAR]
          ? inspectionData[BIKE_DETAIL_KEYS.REGISTRATION_YEAR]
          : ''
      }`,
      id: `${
        inspectionData[BIKE_DETAIL_KEYS.REGISTRATION_YEAR]
          ? inspectionData[BIKE_DETAIL_KEYS.REGISTRATION_YEAR]
          : ''
      }`,
      newValue: '',
      newId: '',
      isEditable: true,
      isVisible: true,
      isDisabled: false,
    },
    {
      key: BIKE_DETAIL_KEYS.Registration_Number,
      text: BIKE_DETAIL_KEYS.Registration_Number,
      checked: false,
      value: `${
        !!inspectionData[BIKE_DETAIL_KEYS.Registration_Number]
          ? inspectionData[BIKE_DETAIL_KEYS.Registration_Number]
          : ''
      }`,
      id: `${
        inspectionData[BIKE_DETAIL_KEYS.Registration_Number]
          ? inspectionData[BIKE_DETAIL_KEYS.Registration_Number]
          : ''
      }`,
      newValue: '',
      newId: '',
      isEditable: true,
      isVisible: true,
      isDisabled: false,
    },
    {
      key: BIKE_DETAIL_KEYS.Customer_Name_on_RC,
      text: BIKE_DETAIL_KEYS.Customer_Name_on_RC,
      checked: false,
      value: `${
        !!inspectionData[BIKE_DETAIL_KEYS.Customer_Name_on_RC]
          ? inspectionData[BIKE_DETAIL_KEYS.Customer_Name_on_RC]
          : ''
      }`,
      id: `${
        inspectionData[BIKE_DETAIL_KEYS.Customer_Name_on_RC]
          ? inspectionData[BIKE_DETAIL_KEYS.Customer_Name_on_RC]
          : ''
      }`,
      newValue: '',
      newId: '',
      isEditable: true,
      isVisible: true,
      isDisabled: false,
    },
    {
      key: BIKE_DETAIL_KEYS.Manufacturing_Date,
      text: BIKE_DETAIL_KEYS.Manufacturing_Date,
      checked: false,
      value: `${
        !!inspectionData[BIKE_DETAIL_KEYS.Manufacturing_Date]
          ? formatDateForBike(
              inspectionData[BIKE_DETAIL_KEYS.Manufacturing_Date],
            )
          : ''
      }`,
      id: `${
        inspectionData[BIKE_DETAIL_KEYS.Manufacturing_Date]
          ? formatDateForBike(
              inspectionData[BIKE_DETAIL_KEYS.Manufacturing_Date],
            )
          : ''
      }`,
      newValue: '',
      newId: '',
      isEditable: true,
      isVisible: true,
      isDisabled: false,
    },
    {
      key: BIKE_DETAIL_KEYS.Registration_Date,
      text: BIKE_DETAIL_KEYS.Registration_Date,
      checked: false,
      value: `${
        !!inspectionData[BIKE_DETAIL_KEYS.Registration_Date]
          ? formatDateForBike(
              inspectionData[BIKE_DETAIL_KEYS.Registration_Date],
            )
          : ''
      }`,
      id: `${
        inspectionData[BIKE_DETAIL_KEYS.Registration_Date]
          ? formatDateForBike(
              inspectionData[BIKE_DETAIL_KEYS.Registration_Date],
            )
          : ''
      }`,
      newValue: '',
      newId: '',
      isEditable: true,
      isVisible: true,
      isDisabled: false,
    },
    {
      key: BIKE_DETAIL_KEYS.Ownership_Number,
      text: BIKE_DETAIL_KEYS.Ownership_Number,
      checked: false,
      value: `${
        !!inspectionData[BIKE_DETAIL_KEYS.Ownership_Number]
          ? inspectionData[BIKE_DETAIL_KEYS.Ownership_Number]
          : ''
      }`,
      id: `${
        inspectionData[BIKE_DETAIL_KEYS.Ownership_Number]
          ? inspectionData[BIKE_DETAIL_KEYS.Ownership_Number]
          : ''
      }`,
      newValue: '',
      newId: '',
      isEditable: true,
      isVisible: true,
      isDisabled: false,
    },
    {
      key: BIKE_DETAIL_KEYS.Hypothecation,
      text: BIKE_DETAIL_KEYS.Hypothecation,
      checked: false,
      value: `${
        !!inspectionData[BIKE_DETAIL_KEYS.Hypothecation]
          ? inspectionData[BIKE_DETAIL_KEYS.Hypothecation]
          : ''
      }`,
      id: `${
        inspectionData[BIKE_DETAIL_KEYS.Hypothecation]
          ? inspectionData[BIKE_DETAIL_KEYS.Hypothecation]
          : ''
      }`,
      newValue: '',
      newId: '',
      isEditable: true,
      isVisible: true,
      isDisabled: false,
      dependentFields: [BIKE_DETAIL_KEYS.Financier_Name],
    },
    {
      key: BIKE_DETAIL_KEYS.Financier_Name,
      text: BIKE_DETAIL_KEYS.Financier_Name,
      checked: false,
      value: `${
        !!inspectionData[BIKE_DETAIL_KEYS.Financier_Name]
          ? inspectionData[BIKE_DETAIL_KEYS.Financier_Name]
          : ''
      }`,
      id: `${
        inspectionData[BIKE_DETAIL_KEYS.Financier_Name]
          ? inspectionData[BIKE_DETAIL_KEYS.Financier_Name]
          : ''
      }`,
      newValue: '',
      newId: '',
      isEditable: true,
      isVisible: true,
      isDisabled: false,
    },
    {
      key: BIKE_DETAIL_KEYS.Is_Scrap,
      text: BIKE_DETAIL_KEYS.Is_Scrap,
      checked: false,
      value: `${
        !!inspectionData[BIKE_DETAIL_KEYS.Is_Scrap]
          ? inspectionData[BIKE_DETAIL_KEYS.Is_Scrap]
          : ''
      }`,
      id: `${
        inspectionData[BIKE_DETAIL_KEYS.Is_Scrap]
          ? inspectionData[BIKE_DETAIL_KEYS.Is_Scrap]
          : ''
      }`,
      newValue: '',
      newId: '',
      isEditable: true,
      isVisible: true,
      isDisabled: false,
    },
    {
      key: BIKE_DETAIL_KEYS.RC_Availability,
      text: BIKE_DETAIL_KEYS.RC_Availability,
      checked: false,
      value: `${
        !!inspectionData[BIKE_DETAIL_KEYS.RC_Availability]
          ? inspectionData[BIKE_DETAIL_KEYS.RC_Availability]
          : ''
      }`,
      id: `${
        inspectionData[BIKE_DETAIL_KEYS.RC_Availability]
          ? inspectionData[BIKE_DETAIL_KEYS.RC_Availability]
          : ''
      }`,
      newValue: '',
      newId: '',
      isEditable: true,
      isVisible: true,
      isDisabled: false,
    },
    {
      key: BIKE_DETAIL_KEYS.RC_Condition,
      text: BIKE_DETAIL_KEYS.RC_Condition,
      checked: false,
      value: `${
        !!inspectionData[BIKE_DETAIL_KEYS.RC_Condition]
          ? inspectionData[BIKE_DETAIL_KEYS.RC_Condition]
          : ''
      }`,
      id: `${
        inspectionData[BIKE_DETAIL_KEYS.RC_Condition]
          ? inspectionData[BIKE_DETAIL_KEYS.RC_Condition]
          : ''
      }`,
      newValue: '',
      newId: '',
      isEditable: true,
      isVisible: true,
      isDisabled: false,
    },
    {
      key: BIKE_DETAIL_KEYS.Registration_State,
      text: BIKE_DETAIL_KEYS.Registration_State,
      checked: false,
      value: `${
        !!inspectionData[BIKE_DETAIL_KEYS.Registration_State]
          ? inspectionData[BIKE_DETAIL_KEYS.Registration_State]
          : ''
      }`,
      id: `${
        inspectionData[BIKE_DETAIL_KEYS.Registration_State_Id]
          ? inspectionData[BIKE_DETAIL_KEYS.Registration_State_Id]
          : ''
      }`,
      newValue: '',
      newId: '',
      isEditable: true,
      isVisible: true,
      isDisabled: false,
      dependentFields: [BIKE_DETAIL_KEYS.RTO_Code],
    },
    {
      key: BIKE_DETAIL_KEYS.RTO_Code,
      text: BIKE_DETAIL_KEYS.RTO_Code,
      checked: false,
      value: `${
        !!inspectionData[BIKE_DETAIL_KEYS.RTO_Code]
          ? inspectionData[BIKE_DETAIL_KEYS.RTO_Code]
          : ''
      }`,
      id: `${
        inspectionData[BIKE_DETAIL_KEYS.RTO_Code]
          ? inspectionData[BIKE_DETAIL_KEYS.RTO_Code]
          : ''
      }`,
      newValue: '',
      newId: '',
      isEditable: true,
      isVisible: true,
      isDisabled: false,
      parentField: [BIKE_DETAIL_KEYS.Registration_State],
    },
    {
      key: BIKE_DETAIL_KEYS.Chassis_Number_on_RC,
      text: BIKE_DETAIL_KEYS.Chassis_Number_on_RC,
      checked: false,
      value: `${
        !!inspectionData[BIKE_DETAIL_KEYS.Chassis_Number_on_RC]
          ? inspectionData[BIKE_DETAIL_KEYS.Chassis_Number_on_RC]
          : ''
      }`,
      id: `${
        inspectionData[BIKE_DETAIL_KEYS.Chassis_Number_on_RC]
          ? inspectionData[BIKE_DETAIL_KEYS.Chassis_Number_on_RC]
          : ''
      }`,
      newValue: '',
      newId: '',
      isEditable: true,
      isVisible: true,
      isDisabled: false,
    },
    {
      key: BIKE_DETAIL_KEYS.Chassis_Number,
      text: BIKE_DETAIL_KEYS.Chassis_Number,
      checked: false,
      value: `${
        !!inspectionData[BIKE_DETAIL_KEYS.Chassis_Number]
          ? inspectionData[BIKE_DETAIL_KEYS.Chassis_Number]
          : ''
      }`,
      id: `${
        inspectionData[BIKE_DETAIL_KEYS.Chassis_Number]
          ? inspectionData[BIKE_DETAIL_KEYS.Chassis_Number]
          : ''
      }`,
      newValue: '',
      newId: '',
      isEditable: true,
      isVisible: true,
      isDisabled: false,
    },
    {
      key: BIKE_DETAIL_KEYS.Chassis_Number_Embossing,
      text: BIKE_DETAIL_KEYS.Chassis_Number_Embossing,
      checked: false,
      value: `${
        !!inspectionData[BIKE_DETAIL_KEYS.Chassis_Number_Embossing]
          ? inspectionData[BIKE_DETAIL_KEYS.Chassis_Number_Embossing]
          : ''
      }`,
      id: `${
        inspectionData[BIKE_DETAIL_KEYS.Chassis_Number_Embossing]
          ? inspectionData[BIKE_DETAIL_KEYS.Chassis_Number_Embossing]
          : ''
      }`,
      newValue: '',
      newId: '',
      isEditable: true,
      isVisible: true,
      isDisabled: false,
    },
    {
      key: BIKE_DETAIL_KEYS.Duplicate_Key,
      text: BIKE_DETAIL_KEYS.Duplicate_Key,
      checked: false,
      value: `${
        !!inspectionData[BIKE_DETAIL_KEYS.Duplicate_Key]
          ? inspectionData[BIKE_DETAIL_KEYS.Duplicate_Key]
          : ''
      }`,
      id: `${
        inspectionData[BIKE_DETAIL_KEYS.Duplicate_Key]
          ? inspectionData[BIKE_DETAIL_KEYS.Duplicate_Key]
          : ''
      }`,
      newValue: '',
      newId: '',
      isEditable: true,
      isVisible: true,
      isDisabled: false,
    },
    {
      key: BIKE_DETAIL_KEYS.Odometer_reading,
      text: BIKE_DETAIL_KEYS.Odometer_reading,
      checked: false,
      value: `${
        !!inspectionData[BIKE_DETAIL_KEYS.Odometer_reading]
          ? inspectionData[BIKE_DETAIL_KEYS.Odometer_reading]
          : ''
      }`,
      id: `${
        inspectionData[BIKE_DETAIL_KEYS.Odometer_reading]
          ? inspectionData[BIKE_DETAIL_KEYS.Odometer_reading]
          : ''
      }`,
      newValue: '',
      newId: '',
      isEditable: true,
      isVisible: true,
      isDisabled: false,
    },
    {
      key: BIKE_DETAIL_KEYS.Insurance_Type,
      text: BIKE_DETAIL_KEYS.Insurance_Type,
      checked: false,
      value: `${
        !!inspectionData[BIKE_DETAIL_KEYS.Insurance_Type]
          ? inspectionData[BIKE_DETAIL_KEYS.Insurance_Type]
          : ''
      }`,
      id: `${
        inspectionData[BIKE_DETAIL_KEYS.Insurance_Type]
          ? inspectionData[BIKE_DETAIL_KEYS.Insurance_Type]
          : ''
      }`,
      newValue: '',
      newId: '',
      isEditable: true,
      isVisible: true,
      isDisabled: false,
      dependentFields: [BIKE_DETAIL_KEYS.Insurance_Expiry],
    },
    {
      key: BIKE_DETAIL_KEYS.Insurance_Expiry,
      text: BIKE_DETAIL_KEYS.Insurance_Expiry,
      checked: false,
      value: `${
        !!inspectionData[BIKE_DETAIL_KEYS.Insurance_Expiry]
          ? formatDateForBike(inspectionData[BIKE_DETAIL_KEYS.Insurance_Expiry])
          : ''
      }`,
      id: `${
        inspectionData[BIKE_DETAIL_KEYS.Insurance_Expiry]
          ? formatDateForBike(inspectionData[BIKE_DETAIL_KEYS.Insurance_Expiry])
          : ''
      }`,
      newValue: '',
      newId: '',
      isEditable: true,
      isVisible: true,
      isDisabled: false,
    },
  ];
  return documents;
};
export const isEmpty = value => {
  return (
    // null or undefined
    value == null ||
    // has length and it's zero
    (value.hasOwnProperty('length') && value.length === 0) ||
    // is an Object and has no keys
    (value.constructor === Object && Object.keys(value).length === 0)
  );
};

export const getBikeDocumentsMapper_v2 = data => {
  const { inspectionData, version } = data;
  // console.log ('data', data);
  const documents = [
    {
      key: BIKE_DETAIL_KEYS.Customer_Name_on_RC,
      text: BIKE_DETAIL_KEYS.Customer_Name_on_RC,
      checked: false,
      value: `${
        !!inspectionData[BIKE_DETAIL_KEYS.Customer_Name_on_RC]
          ? inspectionData[BIKE_DETAIL_KEYS.Customer_Name_on_RC]
          : ''
      }`,
      id: `${
        inspectionData[BIKE_DETAIL_KEYS.Customer_Name_on_RC]
          ? inspectionData[BIKE_DETAIL_KEYS.Customer_Name_on_RC]
          : ''
      }`,
      newValue: '',
      newId: '',
      isEditable: true,
      isVisible: true,
      isDisabled: false,
    },
    {
      key: BIKE_DETAIL_KEYS.REGISTRATION_YEAR,
      text: BIKE_DETAIL_KEYS.REGISTRATION_YEAR,
      checked: false,
      value: `${
        !!inspectionData[BIKE_DETAIL_KEYS.REGISTRATION_YEAR]
          ? inspectionData[BIKE_DETAIL_KEYS.REGISTRATION_YEAR]
          : ''
      }`,
      id: `${
        inspectionData[BIKE_DETAIL_KEYS.REGISTRATION_YEAR]
          ? inspectionData[BIKE_DETAIL_KEYS.REGISTRATION_YEAR]
          : ''
      }`,
      newValue: '',
      newId: '',
      isEditable: true,
      isVisible: true,
      isDisabled: false,
    },
    {
      key: BIKE_DETAIL_KEYS.Registration_Number,
      text: BIKE_DETAIL_KEYS.Registration_Number,
      checked: false,
      value: `${
        !!inspectionData[BIKE_DETAIL_KEYS.Registration_Number]
          ? inspectionData[BIKE_DETAIL_KEYS.Registration_Number]
          : ''
      }`,
      id: `${
        inspectionData[BIKE_DETAIL_KEYS.Registration_Number]
          ? inspectionData[BIKE_DETAIL_KEYS.Registration_Number]
          : ''
      }`,
      newValue: '',
      newId: '',
      isEditable: true,
      isVisible: true,
      isDisabled: false,
    },
    // {
    //     key: BIKE_DETAIL_KEYS.Manufacturing_Date,
    //     text: 'Manufacturing Year',
    //     checked: false,
    //     value: `${!!inspectionData[BIKE_DETAIL_KEYS.Manufacturing_Date] ? formatDateForBike (inspectionData[BIKE_DETAIL_KEYS.Manufacturing_Date]) : ''}`,
    //     id: `${inspectionData[BIKE_DETAIL_KEYS.Manufacturing_Date] ? formatDateForBike (inspectionData[BIKE_DETAIL_KEYS.Manufacturing_Date]) : ''}`,
    //     newValue: '',
    //     newId: '',
    //     isEditable: true,
    //     isVisible: true,
    //     isDisabled: false,
    // },
    {
      key: BIKE_DETAIL_KEYS.Ownership_Number,
      text: BIKE_DETAIL_KEYS.Ownership_Number,
      checked: false,
      value: `${
        !!inspectionData[BIKE_DETAIL_KEYS.Ownership_Number]
          ? inspectionData[BIKE_DETAIL_KEYS.Ownership_Number]
          : ''
      }`,
      id: `${
        inspectionData[BIKE_DETAIL_KEYS.Ownership_Number]
          ? inspectionData[BIKE_DETAIL_KEYS.Ownership_Number]
          : ''
      }`,
      newValue: '',
      newId: '',
      isEditable: true,
      isVisible: true,
      isDisabled: false,
    },
    {
      key: BIKE_DETAIL_KEYS.Odometer_reading,
      text: BIKE_DETAIL_KEYS.Odometer_reading,
      checked: false,
      value: `${
        !!inspectionData[BIKE_DETAIL_KEYS.Odometer_reading]
          ? inspectionData[BIKE_DETAIL_KEYS.Odometer_reading]
          : ''
      }`,
      id: `${
        inspectionData[BIKE_DETAIL_KEYS.Odometer_reading]
          ? inspectionData[BIKE_DETAIL_KEYS.Odometer_reading]
          : ''
      }`,
      newValue: '',
      newId: '',
      isEditable: true,
      isVisible: true,
      isDisabled: false,
    },
    {
      key: BIKE_DETAIL_KEYS.Hypothecation,
      text: BIKE_DETAIL_KEYS.Hypothecation,
      checked: false,
      value: `${
        !!inspectionData[BIKE_DETAIL_KEYS.Hypothecation]
          ? inspectionData[BIKE_DETAIL_KEYS.Hypothecation]
          : ''
      }`,
      id: `${
        inspectionData[BIKE_DETAIL_KEYS.Hypothecation]
          ? inspectionData[BIKE_DETAIL_KEYS.Hypothecation]
          : ''
      }`,
      newValue: '',
      newId: '',
      isEditable: true,
      isVisible: true,
      isDisabled: false,
      dependentFields: [BIKE_DETAIL_KEYS.Loan_Paid, BIKE_DETAIL_KEYS.Bank_NOC],
    },
    {
      key: BIKE_DETAIL_KEYS.REGISTRATION_CITY_RTO_CODE,
      text: BIKE_DETAIL_KEYS.REGISTRATION_CITY_RTO_CODE,
      checked: false,
      value: `${
        !!inspectionData[BIKE_DETAIL_KEYS.REGISTRATION_CITY_RTO_CODE]
          ? inspectionData[BIKE_DETAIL_KEYS.REGISTRATION_CITY_RTO_CODE]
          : ''
      }`,
      id: `${
        inspectionData[BIKE_DETAIL_KEYS.REGISTRATION_CITY_RTO_CODE]
          ? inspectionData[BIKE_DETAIL_KEYS.REGISTRATION_CITY_RTO_CODE]
          : ''
      }`,
      newValue: '',
      newId: '',
      isEditable: true,
      isVisible: true,
      isDisabled: false,
    },
    {
      key: BIKE_DETAIL_KEYS.Loan_Paid,
      text: BIKE_DETAIL_KEYS.Loan_Paid,
      checked: false,
      value: `${
        !!inspectionData[BIKE_DETAIL_KEYS.Loan_Paid]
          ? inspectionData[BIKE_DETAIL_KEYS.Loan_Paid]
          : ''
      }`,
      id: `${
        inspectionData[BIKE_DETAIL_KEYS.Loan_Paid]
          ? inspectionData[BIKE_DETAIL_KEYS.Loan_Paid]
          : ''
      }`,
      newValue: '',
      newId: '',
      isEditable: true,
      isVisible: true,
      isDisabled: false,
    },
    {
      key: BIKE_DETAIL_KEYS.Bank_NOC,
      text: BIKE_DETAIL_KEYS.Bank_NOC,
      checked: false,
      value: `${
        !!inspectionData[BIKE_DETAIL_KEYS.Bank_NOC]
          ? inspectionData[BIKE_DETAIL_KEYS.Bank_NOC]
          : ''
      }`,
      id: `${
        inspectionData[BIKE_DETAIL_KEYS.Bank_NOC]
          ? inspectionData[BIKE_DETAIL_KEYS.Bank_NOC]
          : ''
      }`,
      newValue: '',
      newId: '',
      isEditable: true,
      isVisible: true,
      isDisabled: false,
    },
    {
      key: BIKE_DETAIL_KEYS.Fuel_Type,
      text: BIKE_DETAIL_KEYS.Fuel_Type,
      checked: false,
      value: `${
        !!inspectionData[BIKE_DETAIL_KEYS.Fuel_Type]
          ? inspectionData[BIKE_DETAIL_KEYS.Fuel_Type]
          : ''
      }`,
      id: `${
        inspectionData[BIKE_DETAIL_KEYS.Fuel_Type]
          ? inspectionData[BIKE_DETAIL_KEYS.Insurance_Expiry]
          : ''
      }`,
      newValue: '',
      newId: '',
      isEditable: true,
      isVisible: true,
      isDisabled: false,
      dependentFields: [BIKE_DETAIL_KEYS.Exhaust_Smoke_Colour],
    },
    {
      key: BIKE_DETAIL_KEYS.Exhaust_Smoke_Colour,
      text: BIKE_DETAIL_KEYS.Exhaust_Smoke_Colour,
      checked: false,
      value: `${
        !!inspectionData[BIKE_DETAIL_KEYS.Exhaust_Smoke_Colour]
          ? inspectionData[BIKE_DETAIL_KEYS.Exhaust_Smoke_Colour]
          : ''
      }`,
      id: `${
        inspectionData[BIKE_DETAIL_KEYS.Exhaust_Smoke_Colour]
          ? inspectionData[BIKE_DETAIL_KEYS.Exhaust_Smoke_Colour]
          : ''
      }`,
      newValue: '',
      newId: '',
      isEditable: true,
      isVisible: true,
      isDisabled: false,
    },
    {
      key: BIKE_DETAIL_KEYS.RC_Availability,
      text: BIKE_DETAIL_KEYS.RC_Availability,
      checked: false,
      value: `${
        !!inspectionData[BIKE_DETAIL_KEYS.RC_Availability]
          ? inspectionData[BIKE_DETAIL_KEYS.RC_Availability]
          : ''
      }`,
      id: `${
        inspectionData[BIKE_DETAIL_KEYS.RC_Availability]
          ? inspectionData[BIKE_DETAIL_KEYS.RC_Availability]
          : ''
      }`,
      newValue: '',
      newId: '',
      isEditable: true,
      isVisible: true,
      isDisabled: false,
    },
    {
      key: BIKE_DETAIL_KEYS.Insurance_Availability,
      text: BIKE_DETAIL_KEYS.Insurance_Availability,
      checked: false,
      value: `${
        !!inspectionData[BIKE_DETAIL_KEYS.Insurance_Availability]
          ? inspectionData[BIKE_DETAIL_KEYS.Insurance_Availability]
          : ''
      }`,
      id: `${
        inspectionData[BIKE_DETAIL_KEYS.Insurance_Availability]
          ? inspectionData[BIKE_DETAIL_KEYS.Insurance_Availability]
          : ''
      }`,
      newValue: '',
      newId: '',
      isEditable: true,
      isVisible: true,
      isDisabled: false,
    },
    {
      key: BIKE_DETAIL_KEYS.Are_there_changes_during_ReInspection,
      text: BIKE_DETAIL_KEYS.Are_there_changes_during_ReInspection,
      checked: false,
      value: `${
        !!inspectionData[BIKE_DETAIL_KEYS.Are_there_changes_during_ReInspection]
          ? inspectionData[
              BIKE_DETAIL_KEYS.Are_there_changes_during_ReInspection
            ]
          : ''
      }`,
      id: `${
        inspectionData[BIKE_DETAIL_KEYS.Are_there_changes_during_ReInspection]
          ? inspectionData[
              BIKE_DETAIL_KEYS.Are_there_changes_during_ReInspection
            ]
          : ''
      }`,
      newValue: '',
      newId: '',
      isEditable: true,
      isVisible: version > 0 ? true : false,
      isDisabled: false,
    },
  ];
  return documents;
};

export const getFieldRemarkHistory = (fieldKey, errors = {}, hashKey) => {
  for (let errorFieldKey in errors) {
    if (hashKey) {
      if (Array.isArray(errors[errorFieldKey])) {
        for (let obj of errors[errorFieldKey]) {
          if (obj.hashKey === hashKey) {
            return [...(obj.rejectionRemarks || [])].reverse();
          }
        }
      }
    } else {
      if (errorFieldKey == fieldKey) {
        return [...(errors[errorFieldKey].rejectionRemarks || [])].reverse();
      }

      if (errors[errorFieldKey].subParts) {
        for (let subpartFieldKey in errors[errorFieldKey].subParts) {
          if (subpartFieldKey == fieldKey) {
            return [
              ...(errors[errorFieldKey].subParts[subpartFieldKey]
                .rejectionRemarks || []),
            ].reverse();
          }
        }
      }
    }
  }
};

export const getDocumentsMapper = data => {
  const {
    inspectionData: { carDetails },
    vahanData,
    rcOCR,
  } = data;
  const { errors } = carDetails;
  const manufacturingYear = carDetails.year ? carDetails.year.value : '';
  const manufacturingMonth = carDetails.month
    ? `${monthNames[carDetails.month.value - 1]}`
    : '';
  const registrationYear = carDetails.registrationYear
    ? `${carDetails.registrationYear.value}`
    : '';
  const registrationMonth = carDetails.registrationMonth
    ? `${monthNames[carDetails.registrationMonth.value - 1]}`
    : '';

  const rcAvailability = carDetails.rcAvailability;
  let rcCondition =
    rcAvailability.subParts && rcAvailability.subParts.rcCondition
      ? rcAvailability.subParts.rcCondition.value
      : '';
  let rcAtDelivery = rcAvailability?.subParts?.rcAtDelivery?.value || '';
  const chassisNoOnRc =
    rcAvailability.subParts &&
    rcAvailability.subParts.rcCondition &&
    rcAvailability.subParts.rcCondition.subParts &&
    rcAvailability.subParts.rcCondition.subParts.chassisNumberOnRC
      ? rcAvailability.subParts.rcCondition.subParts.chassisNumberOnRC.value
      : '';
  let mismatchInRc =
    rcAvailability.subParts && rcAvailability.subParts.mismatchInRC
      ? rcAvailability.subParts.mismatchInRC.value
      : '';

  const bankNocStatus =
    carDetails.isUnderHypothecation.subParts &&
    carDetails.isUnderHypothecation.subParts.bankNocStatus &&
    carDetails.isUnderHypothecation.subParts &&
    carDetails.isUnderHypothecation.subParts.bankNocStatus
      ? carDetails.isUnderHypothecation.subParts.bankNocStatus
      : null;
  const financierName =
    carDetails.isUnderHypothecation.subParts &&
    carDetails.isUnderHypothecation.subParts.financierName
      ? carDetails.isUnderHypothecation.subParts.financierName
      : null;

  const bankNocExpiryDate =
    bankNocStatus &&
    bankNocStatus.subParts &&
    bankNocStatus.subParts.bankNocExpiryDate
      ? bankNocStatus.subParts.bankNocExpiryDate.value
      : '';
  const rtoNoIssued = carDetails.rtoNocIssued;
  const rtoNoIssuedSubparts = rtoNoIssued.subParts;
  const rtoNocAvailable =
    rtoNoIssued && rtoNoIssuedSubparts && rtoNoIssuedSubparts.rtoNocAvailable
      ? rtoNoIssuedSubparts.rtoNocAvailable
      : null;
  const rtoNocAvailableImage = rtoNocAvailable?.additionalInfo?.url || '';
  const rtoNmcAvailable =
    rtoNoIssued && rtoNoIssuedSubparts && rtoNoIssuedSubparts.rtoNmcAvailable
      ? rtoNoIssuedSubparts.rtoNmcAvailable
      : null;

  const rtoNocIssuedFor =
    rtoNoIssued && rtoNoIssuedSubparts && rtoNoIssuedSubparts.rtoNocIssuedFor
      ? rtoNoIssuedSubparts.rtoNocIssuedFor
      : null;
  const rtoNocIssuedDate =
    rtoNoIssued && rtoNoIssuedSubparts && rtoNoIssuedSubparts.rtoNocIssuedDate
      ? rtoNoIssuedSubparts.rtoNocIssuedDate
      : null;

  const roadTaxPaid = carDetails.roadTaxPaid;
  const roadTaxPaidsubParts = roadTaxPaid ? roadTaxPaid.subParts : null;
  const roadTaxDateValidity =
    roadTaxPaidsubParts && roadTaxPaidsubParts.roadTaxDateValidity
      ? roadTaxPaidsubParts.roadTaxDateValidity
      : null;

  const MAPPER = RESET_REDUCER_KEYS.DOCUMENTS_MAPPER;

  let documents = [
    {
      key: 'registrationYear',
      text: 'Registration Year',
      checked: false,
      value: registrationYear,
      id: registrationYear,
      newValue: '',
      newId: '',
      isEditable: true,
      isVisible: true,
      dependentFields: ['registrationMonth', 'fitnessUpto'],
      isDisabled: false,
      isImportant: true,
      prevValue: getPreviousValueFromErrorObject(errors, 'registrationYear'),
      ...((vahanData &&
        getValidRegistrationYearDetails(vahanData, registrationYear)) ||
        {}),
      remarkHistory: getFieldRemarkHistory('registrationYear', errors),
      mapper: MAPPER,
      isValidate: errors?.registrationYear?.isValidate,
      part: 'registrationYear',
      subPart: null,
      isVerified: carDetails?.['registrationYear']?.isVerified,
    },
    {
      key: 'registrationMonth',
      text: `${
        carDetails.registrationMonth ? carDetails.registrationMonth.title : ''
      }`,
      checked: false,
      value: registrationMonth,
      id: `${
        carDetails.registrationMonth ? carDetails.registrationMonth.value : ''
      }`,
      newValue: '',
      newId: '',
      isEditable: true,
      isVisible: true,
      dependentFields: ['fitnessUpto'],
      isDisabled: false,
      isImportant: true,
      prevValue: getRegMonth(
        getPreviousValueFromErrorObject(errors, 'registrationMonth'),
      ),
      ...((vahanData &&
        getValidRegistrationMonthDetails(vahanData, registrationMonth)) ||
        {}),
      remarkHistory: getFieldRemarkHistory('registrationMonth', errors),
      mapper: MAPPER,
      isValidate: errors?.registrationMonth?.isValidate,
      part: 'registrationMonth',
      subPart: null,
      isVerified: carDetails?.['registrationMonth']?.isVerified,
    },
    {
      key: 'fitnessUpto',
      text: `${carDetails.fitnessUpto ? carDetails.fitnessUpto.title : ''}`,
      checked: false,
      value: `${carDetails.fitnessUpto ? carDetails.fitnessUpto.value : ''}`,
      id: `${carDetails.fitnessUpto ? carDetails.fitnessUpto.value : ''}`,
      newValue: '',
      newId: '',
      isEditable: true,
      isVisible: true,
      isDisabled: false,
      prevValue: getPreviousValueFromErrorObject(errors, 'fitnessUpto'),
      ...((vahanData &&
        getValidFitnessDetails(
          vahanData,
          `${carDetails.fitnessUpto ? carDetails.fitnessUpto.value : ''}`,
        )) ||
        {}),
      remarkHistory: getFieldRemarkHistory('fitnessUpto', errors),
      mapper: MAPPER,
      isValidate: errors?.fitnessUpto?.isValidate,
      part: 'fitnessUpto',
      subPart: null,
      isVerified: carDetails?.['fitnessUpto']?.isVerified,
    },
    {
      key: 'registrationState',
      text: `${
        carDetails.registrationState ? carDetails.registrationState.title : ''
      }`,
      checked: false,
      value: `${
        carDetails.registrationState ? carDetails.registrationState.value : ''
      }`,
      id: `${
        carDetails.registrationState ? carDetails.registrationState.id : ''
      }`,
      newValue: '',
      newId: '',
      isEditable: true,
      isVisible: true,
      dependentFields: ['registrationCity', 'rto'],
      isDisabled: false,
      prevValue: getPreviousValueFromErrorObject(errors, 'registrationState'),
      remarkHistory: getFieldRemarkHistory('registrationState', errors),
      mapper: MAPPER,
      isValidate: errors?.registrationState?.isValidate,
      part: 'registrationState',
      subPart: null,
      isVerified: carDetails?.['registrationState']?.isVerified,
    },
    {
      key: 'rto',
      rtoCode: `${
        carDetails.registrationState &&
        carDetails.registrationState.subParts &&
        carDetails.registrationState.subParts.rto
          ? carDetails.registrationState.subParts.rto.rtoCode
          : ''
      }`,
      text: `${
        carDetails.registrationState &&
        carDetails.registrationState.subParts &&
        carDetails.registrationState.subParts.rto
          ? carDetails.registrationState.subParts.rto.title
          : ''
      }`,
      checked: false,
      value: `${
        carDetails.registrationState &&
        carDetails.registrationState.subParts &&
        carDetails.registrationState.subParts.rto
          ? carDetails.registrationState.subParts.rto.value
          : ''
      }`,
      id: `${
        carDetails.registrationState &&
        carDetails.registrationState.subParts &&
        carDetails.registrationState.subParts.rto
          ? carDetails.registrationState.subParts.rto.id
          : ''
      }`,
      newRtoCode: '',
      newValue: '',
      newId: '',
      isEditable: true,
      isVisible: true,
      isDisabled: false,
      parentField: ['registrationState'],
      prevValue: getPreviousValueFromErrorObject(errors, 'rto'),
      ...((vahanData &&
        vahanData.bgcData &&
        Object.keys(vahanData.bgcData).length &&
        validateRtoWithBgc(
          vahanData,
          carDetails.registrationState?.subParts?.rto?.value || '',
        )) ||
        {}),
      remarkHistory: getFieldRemarkHistory('rto', errors),
      mapper: MAPPER,
      isValidate: errors?.rto?.isValidate,
      part: 'registrationState',
      subPart: 'rto',
      isVerified: carDetails?.['rto']?.isVerified,
    },
    {
      key: 'rtoNocIssued',
      text: rtoNoIssued ? rtoNoIssued.title : '',
      checked: false,
      value: rtoNoIssued ? rtoNoIssued.value : '',
      id: rtoNoIssued ? rtoNoIssued.value : '',
      newValue: '',
      newId: '',
      isEditable: true,
      isVisible: true,
      isDisabled: false,
      dependentFields: [
        'rtoNocAvailable',
        'rtoNmcAvailable',
        'rtoNocIssuedFor',
        'rtoNocIssuedDate',
      ],
      prevValue: getPreviousValueFromErrorObject(errors, 'rtoNocIssued'),
      ...((vahanData &&
        vahanData.bgcData &&
        Object.keys(vahanData.bgcData).length &&
        getRTONOCDetails(vahanData, rtoNoIssued ? rtoNoIssued.value : '')) ||
        {}),
      remarkHistory: getFieldRemarkHistory('rtoNocIssued', errors),
      mapper: MAPPER,
      isValidate: errors?.rtoNocIssued?.isValidate,
      part: 'rtoNocIssued',
      subPart: null,
      isVerified: carDetails?.['rtoNocIssued']?.isVerified,
    },
    {
      key: 'rtoNocAvailable',
      text: rtoNocAvailable ? rtoNocAvailable.title : '',
      checked: false,
      value: rtoNocAvailable ? rtoNocAvailable.value : '',
      id: rtoNocAvailable ? rtoNocAvailable.value : '',
      newValue: '',
      newId: '',
      isEditable: true,
      isVisible: false,
      isDisabled: false,
      // dependentFields: ["rtoNocIssuedFor", "rtoNocIssuedDate"],
      // dependentFields: ["rtoNocAvailableImage"],
      prevValue: getPreviousValueFromErrorObject(errors, 'rtoNocAvailable'),
      remarkHistory: getFieldRemarkHistory('rtoNocAvailable', errors),
      mapper: MAPPER,
      isValidate: errors?.rtoNocIssued?.subParts?.rtoNocAvailable?.isValidate,
      part: 'rtoNocIssued',
      subPart: 'rtoNocAvailable',
      isVerified: carDetails?.['rtoNocAvailable']?.isVerified,
    },

    {
      key: 'rtoNocIssuedFor',
      text: rtoNocIssuedFor ? rtoNocIssuedFor.title : '',
      checked: false,
      value: rtoNocIssuedFor ? rtoNocIssuedFor.value : '',
      id: rtoNocIssuedFor ? rtoNocIssuedFor.value : '',
      newValue: '',
      newId: '',
      isEditable: true,
      isVisible: false,
      isDisabled: false,
      prevValue: getPreviousValueFromErrorObject(errors, 'rtoNocIssuedFor'),
      remarkHistory: getFieldRemarkHistory('rtoNocIssuedFor', errors),
      mapper: MAPPER,
      isValidate: errors?.rtoNocIssued?.subParts?.rtoNocIssuedFor?.isValidate,
      part: 'rtoNocIssued',
      subPart: 'rtoNocIssuedFor',
      isVerified: carDetails?.['rtoNocIssuedFor']?.isVerified,
    },
    {
      key: 'rtoNocIssuedDate',
      text: rtoNocIssuedDate ? rtoNocIssuedDate.title : '',
      checked: false,
      value: rtoNocIssuedDate ? rtoNocIssuedDate.value : '',
      id: rtoNocIssuedDate ? rtoNocIssuedDate.value : '',
      newValue: '',
      newId: '',
      isEditable: true,
      isVisible: false,
      isDisabled: false,
      prevValue: getPreviousValueFromErrorObject(errors, 'rtoNocIssuedDate'),
      remarkHistory: getFieldRemarkHistory('rtoNocIssuedDate', errors),
      mapper: MAPPER,
      isValidate: errors?.rtoNocIssued?.subParts?.rtoNocIssuedDate?.isValidate,
      part: 'rtoNocIssued',
      subPart: 'rtoNocIssuedDate',
      isVerified: carDetails?.['rtoNocIssuedDate']?.isVerified,
    },
    {
      key: 'rtoNmcAvailable',
      text: rtoNmcAvailable ? rtoNmcAvailable.title : '',
      checked: false,
      value: rtoNmcAvailable ? rtoNmcAvailable.value : '',
      id: rtoNmcAvailable ? rtoNmcAvailable.value : '',
      newValue: '',
      newId: '',
      isEditable: true,
      isVisible: false,
      isDisabled: false,
      prevValue: getPreviousValueFromErrorObject(errors, 'rtoNmcAvailable'),
      remarkHistory: getFieldRemarkHistory('rtoNmcAvailable', errors),
      mapper: MAPPER,
      isValidate: errors?.rtoNocIssued?.subParts?.rtoNmcAvailable?.isValidate,
      part: 'rtoNocIssued',
      subPart: 'rtoNmcAvailable',
      isVerified: carDetails?.['rtoNmcAvailable']?.isVerified,
    },
    {
      key: 'registrationCity',
      text: `${
        carDetails.registrationCity ? carDetails.registrationCity.title : ''
      }`,
      checked: false,
      value: `${
        carDetails.registrationCity ? carDetails.registrationCity.value : ''
      }`,
      id: `${
        carDetails.registrationCity ? carDetails.registrationCity.id : ''
      }`,
      newValue: '',
      newId: '',
      isEditable: true,
      isVisible: true,
      isDisabled: false,
      prevValue: getPreviousValueFromErrorObject(errors, 'registrationCity'),
      remarkHistory: getFieldRemarkHistory('registrationCity', errors),
      mapper: MAPPER,
      isValidate: errors?.registrationCity?.isValidate,
      part: 'registrationCity',
      subPart: null,
      isVerified: carDetails?.['registrationCity']?.isVerified,
    },
    {
      key: 'registrationNumber',
      text: `${
        carDetails.registrationNumber ? carDetails.registrationNumber.title : ''
      }`,
      checked: false,
      value: `${
        carDetails.registrationNumber && carDetails.registrationNumber.value
          ? carDetails.registrationNumber.value
          : ''
      }`,
      id: `${
        carDetails.registrationNumber && carDetails.registrationNumber.value
          ? carDetails.registrationNumber.value
          : ''
      }`,
      newValue: '',
      newId: '',
      isEditable: true,
      isVisible: true,
      prevValue: getPreviousValueFromErrorObject(errors, 'registrationNumber'),
      ...((vahanData &&
        getValidRegistrationNumberDetails(
          vahanData,
          `${
            carDetails.registrationNumber && carDetails.registrationNumber.value
              ? carDetails.registrationNumber.value
              : ''
          }`,
        )) ||
        {}),
      remarkHistory: getFieldRemarkHistory('registrationNumber', errors),
      mapper: MAPPER,
      isValidate: errors?.registrationNumber?.isValidate,
      part: 'registrationNumber',
      subPart: null,
      rcOcrRegNo: rcOCR?.registrationNumber,
      isVerified: carDetails?.['registrationNumber']?.isVerified,
    },
    {
      key: 'ownerNumber',
      text: `${carDetails.ownerNumber ? carDetails.ownerNumber.title : ''}`,
      checked: false,
      value: `${carDetails.ownerNumber ? carDetails.ownerNumber.value : ''}`,
      id: `${carDetails.ownerNumber ? carDetails.ownerNumber.value : ''}`,
      newValue: '',
      newId: '',
      isEditable: true,
      isVisible: true,
      isDisabled: false,
      prevValue: getPreviousValueFromErrorObject(errors, 'ownerNumber'),
      ...((vahanData &&
        vahanData.bgcData &&
        Object.keys(vahanData.bgcData).length &&
        getValidOwnerNumberDetails(
          vahanData,
          `${carDetails.ownerNumber ? carDetails.ownerNumber.value : ''}`,
        )) ||
        {}),
      remarkHistory: getFieldRemarkHistory('ownerNumber', errors),
      mapper: MAPPER,
      isValidate: errors?.ownerNumber?.isValidate,
      part: 'ownerNumber',
      subPart: null,
      isVerified: carDetails?.['ownerNumber']?.isVerified,
    },
    {
      key: 'cngLpgFitment',
      text: `${carDetails.cngLpgFitment ? carDetails.cngLpgFitment.title : ''}`,
      checked: false,
      value: `${
        carDetails.cngLpgFitment ? carDetails.cngLpgFitment.value : ''
      }`,
      id: `${carDetails.cngLpgFitment ? carDetails.cngLpgFitment.value : ''}`,
      newValue: '',
      newId: '',
      isEditable: true,
      isVisible: true,
      isDisabled: false,
      isImportant: true,
      prevValue: getPreviousValueFromErrorObject(errors, 'cngLpgFitment'),
      remarkHistory: getFieldRemarkHistory('cngLpgFitment', errors),
      mapper: MAPPER,
      isValidate: errors?.cngLpgFitment?.isValidate,
      part: 'cngLpgFitment',
      subPart: null,
      isVerified: carDetails?.['cngLpgFitment']?.isVerified,
    },
    {
      key: 'chassisNumber',
      text: `${carDetails.chassisNumber ? carDetails.chassisNumber.title : ''}`,
      checked: false,
      value: `${
        carDetails.chassisNumber ? carDetails.chassisNumber.value : ''
      }`,
      id: `${carDetails.chassisNumber ? carDetails.chassisNumber.value : ''}`,
      newValue: '',
      newId: '',
      isEditable: true,
      isVisible: true,
      isDisabled: false,
      prevValue: getPreviousValueFromErrorObject(errors, 'chassisNumber'),
      ...((vahanData &&
        validateChassisNumber(
          vahanData,
          `${carDetails?.chassisNumber?.value || ''}`,
        )) ||
        {}),
      remarkHistory: getFieldRemarkHistory('chassisNumber', errors),
      mapper: MAPPER,
      isValidate: errors?.chassisNumber?.isValidate,
      part: 'chassisNumber',
      subPart: null,
      isVerified: carDetails?.['chassisNumber']?.isVerified,
    },
    {
      key: 'chassisNumberEmbossing',
      text: `${
        carDetails.chassisNumberEmbossing
          ? carDetails.chassisNumberEmbossing.title
          : ''
      }`,
      checked: false,
      value: `${
        carDetails.chassisNumberEmbossing
          ? carDetails.chassisNumberEmbossing.value
          : ''
      }`,
      id: `${
        carDetails.chassisNumberEmbossing
          ? carDetails.chassisNumberEmbossing.value
          : ''
      }`,
      newValue: '',
      newId: '',
      isEditable: true,
      isVisible: true,
      isDisabled: false,
      prevValue: getPreviousValueFromErrorObject(
        errors,
        'chassisNumberEmbossing',
      ),
      remarkHistory: getFieldRemarkHistory('chassisNumberEmbossing', errors),
      mapper: MAPPER,
      isValidate: errors?.chassisNumberEmbossing?.isValidate,
      part: 'chassisNumberEmbossing',
      subPart: null,
      isVerified: carDetails?.['chassisNumberEmbossing']?.isVerified,
    },
    {
      key: 'rcAvailability',
      text: `${
        carDetails.rcAvailability ? carDetails.rcAvailability.title : ''
      }`,
      checked: false,
      value: `${
        carDetails.rcAvailability ? carDetails.rcAvailability.value : ''
      }`,
      id: `${carDetails.rcAvailability ? carDetails.rcAvailability.value : ''}`,
      newValue: '',
      newId: '',
      isEditable: false,
      isVisible: true,
      isDisabled: false,
      dependentFields: ['rcCondition', 'misMatchInRc'],
      prevValue: getPreviousValueFromErrorObject(errors, 'rcAvailability'),
      remarkHistory: getFieldRemarkHistory('rcAvailability', errors),
      mapper: MAPPER,
      isValidate: errors?.rcAvailability?.isValidate,
      part: 'rcAvailability',
      subPart: null,
      isVerified: carDetails?.['rcAvailability']?.isVerified,
    },
    {
      key: 'rcAtDelivery',
      text: 'RC at Delivery',
      checked: false,
      value: rcAtDelivery || '',
      id: rcAtDelivery || '',
      newValue: '',
      newId: '',
      isEditable: true,
      isVisible: carDetails.rcAvailability.value === 'Photocopy',
      isDisabled: false,
      prevValue: getPreviousValueFromErrorObject(errors, 'rcAtDelivery'),
      isValidate: errors?.rcAvailability?.subParts?.rcAtDelivery?.isValidate,
      part: 'rcAvailability',
      subPart: null,
      part: 'rcAvailability',
      subPart: 'rcAtDelivery',
      isVerified: carDetails?.['rcAtDelivery']?.isVerified,
    },
    {
      key: 'rcCondition',
      text: 'RC Condition',
      checked: false,
      value: rcCondition,
      id: !!rcCondition ? rcCondition.split(',') : [''],
      newValue: '',
      newId: '',
      isEditable: true,
      isVisible: carDetails.rcAvailability.value !== 'Lost',
      isDisabled: false,
      prevValue: getPreviousValueFromErrorObject(errors, 'rcCondition'),
      remarkHistory: getFieldRemarkHistory('rcCondition', errors),
      mapper: MAPPER,
      isValidate: errors?.rcAvailability?.subParts?.rcCondition?.isValidate,
      part: 'rcAvailability',
      subPart: 'rcCondition',
      isVerified: carDetails?.['rcCondition']?.isVerified,
    },
    {
      key: 'chassisNoOnRc',
      text: 'Chassis Number on RC',
      checked: false,
      value: chassisNoOnRc,
      id: chassisNoOnRc,
      newValue: '',
      newId: '',
      isEditable: true,
      isVisible: false,
      isDisabled: false,
      prevValue: getPreviousValueFromErrorObject(errors, 'chassisNoOnRc'),
      remarkHistory: getFieldRemarkHistory('chassisNoOnRc', errors),
      mapper: MAPPER,
      isValidate: errors?.chassisNoOnRc?.isValidate,
      part: 'chassisNoOnRc',
      subPart: null,
      isVerified: carDetails?.['chassisNoOnRc']?.isVerified,
    },
    {
      key: 'misMatchInRc',
      text: 'Mismatch In RC',
      checked: false,
      value: mismatchInRc,
      id: !!mismatchInRc ? mismatchInRc.split(',') : '',
      newValue: '',
      newId: '',
      isEditable: true,
      isVisible: true,
      isDisabled: false,
      prevValue: getPreviousValueFromErrorObject(errors, 'misMatchInRc'),
      remarkHistory: getFieldRemarkHistory('misMatchInRc', errors),
      mapper: MAPPER,
      isValidate: errors?.rcAvailability?.subParts?.misMatchInRc?.isValidate,
      part: 'rcAvailability',
      subPart: 'misMatchInRc',
      isVerified: carDetails?.['misMatchInRc']?.isVerified,
    },
    {
      key: 'carColour',
      text: carDetails.carColour?.title || '',
      checked: false,
      value: carDetails.carColour?.value || '',
      id: carDetails.carColour?.value || '',
      newValue: '',
      newId: '',
      isEditable: false,
      isVisible: true,
      isDisabled: false,
      isVerified: carDetails?.['carColour']?.isVerified,
    },
    {
      key: 'isUnderHypothecation',
      text: `${
        carDetails.isUnderHypothecation
          ? carDetails.isUnderHypothecation.title
          : ''
      }`,
      checked: false,
      value: `${
        carDetails.isUnderHypothecation
          ? carDetails.isUnderHypothecation.value
          : ''
      }`,
      id: `${
        carDetails.isUnderHypothecation
          ? carDetails.isUnderHypothecation.value
          : ''
      }`,
      newValue: '',
      newId: '',
      isEditable: true,
      isVisible: true,
      isDisabled: false,
      //dependentFields: ['bankNocStatus', 'bankNocExpiryDate', 'financierName'],
      dependentFields: ['financierName', 'loanStatus'],
      prevValue: getPreviousValueFromErrorObject(
        errors,
        'isUnderHypothecation',
      ),
      ...((vahanData &&
        getValidHypothecationDetails(
          vahanData,
          `${
            carDetails.isUnderHypothecation
              ? carDetails.isUnderHypothecation.value
              : ''
          }`,
        )) ||
        {}),
      remarkHistory: getFieldRemarkHistory('isUnderHypothecation', errors),
      mapper: MAPPER,
      isValidate: errors?.isUnderHypothecation?.isValidate,
      part: 'isUnderHypothecation',
      subPart: null,
      isVerified: carDetails?.['isUnderHypothecation']?.isVerified,
    },
    {
      key: 'financierName',
      text: `Financier Name`,
      checked: false,
      value: financierName ? financierName.value : '',
      id: financierName ? financierName.nocClearanceTime : '',
      newValue: '',
      newId: '',
      isEditable: true,
      isVisible: false,
      isDisabled: false,
      prevValue: getPreviousValueFromErrorObject(errors, 'financierName'),
      remarkHistory: getFieldRemarkHistory('financierName', errors),
      mapper: MAPPER,
      isValidate:
        errors?.isUnderHypothecation?.subParts?.financierName?.isValidate,
      part: 'financierName',
      subPart: null,
      isVerified: carDetails?.['isUnderHypothecation']?.['subParts']?.['financierName']?.isVerified,
    },
    {
      key: 'loanStatus',
      text: 'Loan Status',
      checked: false,
      value:
        carDetails?.isUnderHypothecation?.subParts?.loanStatus?.value || '',
      id: carDetails?.isUnderHypothecation?.subParts?.loanStatus?.value || '',
      newValue: '',
      newId: '',
      isEditable: true,
      isVisible: false,
      isDisabled: false,
      prevValue: getPreviousValueFromErrorObject(errors, 'loanStatus'),
      remarkHistory: getFieldRemarkHistory('loanStatus', errors),
      mapper: MAPPER,
      isValidate:
        errors?.isUnderHypothecation?.subParts?.loanStatus?.isValidate,
      part: 'loanStatus',
      subPart: null,
      isVerified: carDetails?.['loanStatus']?.isVerified,
    },
    {
      key: 'bankNocStatus',
      text: `Bank NOC Status`,
      checked: false,
      value: bankNocStatus ? bankNocStatus.value : '',
      id: bankNocStatus ? bankNocStatus.value : '',
      newValue: '',
      newId: '',
      isEditable: false,
      isVisible: false,
      isDisabled: false,
      dependentFields: ['bankNocExpiryDate'],
      prevValue: getPreviousValueFromErrorObject(errors, 'bankNocStatus'),
      remarkHistory: getFieldRemarkHistory('bankNocStatus', errors),
      mapper: MAPPER,
      isValidate:
        errors?.isUnderHypothecation?.subParts?.bankNocStatus?.isValidate,
      part: 'bankNocStatus',
      subPart: null,
      isVerified: carDetails?.['bankNocStatus']?.isVerified,
    },
    {
      key: 'bankNocExpiryDate',
      text: `Bank NOC Expiry Date`,
      checked: false,
      value: bankNocExpiryDate,
      id: bankNocExpiryDate,
      newValue: '',
      newId: '',
      isEditable: false,
      isVisible: false,
      isDisabled: false,
      // dependentFields: ["bankNocStatus", "bankNocExpiryDate"],
      prevValue: getPreviousValueFromErrorObject(errors, 'bankNocExpiryDate'),
      remarkHistory: getFieldRemarkHistory('bankNocExpiryDate', errors),
      mapper: MAPPER,
      isValidate:
        errors?.isUnderHypothecation?.subParts?.bankNocStatus?.subParts
          ?.bankNocExpiryDate?.isValidate,
      part: 'bankNocExpiryDate',
      subPart: null,
      isVerified: carDetails?.['bankNocExpiryDate']?.isVerified,
    },
    {
      key: 'customerName',
      text: 'Customer Name',
      checked: false,
      value: `${carDetails.customerName ? carDetails.customerName.value : ''}`,
      id: `${carDetails.customerName ? carDetails.customerName.value : ''}`,
      newValue: '',
      newId: '',
      isEditable: true,
      isVisible: true,
      isDisabled: false,
      prevValue: getPreviousValueFromErrorObject(errors, 'customerName'),
      remarkHistory: getFieldRemarkHistory('customerName', errors),
      mapper: MAPPER,
      isValidate: errors?.customerName?.isValidate,
      isImportant: true,
      prevValue: getPreviousValueFromErrorObject(errors, 'customerName'),
      ...((vahanData &&
        getValidCustomerNameDetails(
          vahanData,
          `${carDetails?.customerName?.value || ''}`,
        )) ||
        {}),
      part: 'customerName',
      subPart: null,
      isVerified: carDetails?.['customerName']?.isVerified,
    },
    {
      key: 'roadTaxPaid',
      text: roadTaxPaid && roadTaxPaid.title ? roadTaxPaid.title : '',
      checked: false,
      value: roadTaxPaid && roadTaxPaid.value ? roadTaxPaid.value : '',
      id: roadTaxPaid && roadTaxPaid.value ? roadTaxPaid.value : '',
      newValue: '',
      newId: '',
      isEditable: true,
      isVisible: true,
      isDisabled: false,
      dependentFields: ['roadTaxDateValidity'],
      prevValue: getPreviousValueFromErrorObject(errors, 'roadTaxPaid'),
      remarkHistory: getFieldRemarkHistory('roadTaxPaid', errors),
      mapper: MAPPER,
      isValidate: errors?.roadTaxPaid?.isValidate,
      ...((vahanData &&
        getValidRoadTaxPaidDetails(
          vahanData,
          `${carDetails?.roadTaxPaid || ''}`,
        )) ||
        {}),
      part: 'roadTaxPaid',
      subPart: null,
      isVerified: carDetails?.['roadTaxPaid']?.isVerified,
    },
    {
      key: 'roadTaxDateValidity',
      text:
        roadTaxDateValidity && roadTaxDateValidity.title
          ? roadTaxDateValidity.title
          : '',
      checked: false,
      value:
        roadTaxDateValidity && roadTaxDateValidity.value
          ? roadTaxDateValidity.value
          : '',
      id:
        roadTaxDateValidity && roadTaxDateValidity.value
          ? roadTaxDateValidity.value
          : '',
      newValue: '',
      newId: '',
      isEditable: true,
      isVisible: true,
      isDisabled: false,
      prevValue: getPreviousValueFromErrorObject(errors, 'roadTaxDateValidity'),
      remarkHistory: getFieldRemarkHistory('roadTaxDateValidity', errors),
      mapper: MAPPER,
      isValidate:
        errors?.roadTaxPaid?.subParts?.roadTaxDateValidity?.isValidate,
      part: 'roadTaxDateValidity',
      subPart: null,
      isVerified: carDetails?.roadTaxPaid?.subParts?.roadTaxDateValidity?.isVerified,
    },
  ];

  if (vahanData) {
    mutateDataForRoadTaxValidity(vahanData, documents);
  }
  documents = addValueToDocumentMapper(carDetails, documents, 'commentFlag');
  return documents;
};

export const addValueToDocumentMapper = (carDetails, documents, field) => {
  return documents.map((doc) => {
    switch(doc.key) {
      case 'roadTaxDateValidity':
        return {
          ...doc,
          [field]: carDetails?.roadTaxPaid?.subParts?.roadTaxDateValidity?.[field],
        }
      case 'financierName':
        return {
          ...doc,
          [field]: carDetails?.isUnderHypothecation?.subParts?.financierName?.[field],
        }
      default:
        return {
          ...doc,
          [field]: carDetails?.[doc.key]?.[field],
        }
    }
  })
}

const getIsEditable = (flagValue) => {
  switch (flagValue) {
    case 0:
    case 2:
      return false;
    default:
      return true;
  }
}

export const getInsuranceMapper = data => {
  const {
    inspectionData: { carDetails },
  } = data;
  const { errors } = carDetails;
  const insuranceType =
    (carDetails.insuranceType && carDetails.insuranceType.value) || '';
    const insuranceTypeCommentFlag = carDetails?.insuranceType ? carDetails?.insuranceType?.commentFlag : null;
  const insuranceExpiry =
    carDetails.insuranceType.subParts &&
    carDetails.insuranceType.subParts.insuranceExpiry
      ? carDetails.insuranceType.subParts.insuranceExpiry.value
      : '';
  const insuranceExpiryCommentFlag = carDetails?.insuranceType?.subParts &&
  carDetails?.insuranceType?.subParts?.insuranceExpiry
    ? carDetails?.insuranceType?.subParts?.insuranceExpiry?.commentFlag
    : '';
  const noClaimBonus =
    carDetails.insuranceType.subParts &&
    carDetails.insuranceType.subParts.noClaimBonus
      ? carDetails.insuranceType.subParts.noClaimBonus.value
      : null;
      const noClaimBonusCommentFlag = carDetails?.insuranceType?.subParts &&
      carDetails?.insuranceType?.subParts?.noClaimBonus
        ? carDetails?.insuranceType?.subParts?.noClaimBonus?.commentFlag
        : null;
  let noClaimBonusDep =
    carDetails.insuranceType.subParts &&
    carDetails.insuranceType.subParts.noClaimBonus &&
    carDetails.insuranceType.subParts.noClaimBonus.subParts &&
    carDetails.insuranceType.subParts.noClaimBonus.subParts
      .noClaimBonusPercentage
      ? carDetails.insuranceType.subParts.noClaimBonus.subParts
          .noClaimBonusPercentage.value
      : '';
      let noClaimBonusDepCommentFlag = null;
  if (noClaimBonusDep) {
    noClaimBonusDep = noClaimBonusDep.match(/(\d+)/)[0];
  }
  
  noClaimBonusDepCommentFlag = carDetails?.insuranceType?.subParts?.noClaimBonus?.subParts
  ?.noClaimBonusPercentage?.commentFlag

  const MAPPER = RESET_REDUCER_KEYS.INSURANCE_MAPPER;

  return [
    {
      key: 'insuranceType',
      text: `${carDetails.insuranceType ? carDetails.insuranceType.title : ''}`,
      checked: false,
      value: `${
        carDetails.insuranceType ? carDetails.insuranceType.value : ''
      }`,
      id: `${carDetails.insuranceType ? carDetails.insuranceType.value : ''}`,
      newValue: '',
      newId: '',
      isEditable: getIsEditable(insuranceTypeCommentFlag),
      isVisible: true,
      dependentFields: ['insuranceExpiry', 'noClaimBonus'],
      prevValue: getPreviousValueFromErrorObject(errors, 'insuranceType'),
      remarkHistory: getFieldRemarkHistory('insuranceType', errors),
      mapper: MAPPER,
      isValidate: errors?.insuranceType?.isValidate,
      part: 'insuranceType',
      subPart: null,
      commentFlag: carDetails?.insuranceType ? carDetails?.insuranceType?.commentFlag : null,
    },
    {
      key: 'insuranceExpiry',
      text: 'Insurance Expiry Date',
      checked: false,
      value: insuranceExpiry,
      id: insuranceExpiry,
      newValue: '',
      newId: '',
      isEditable: getIsEditable(insuranceExpiryCommentFlag),
      isVisible: false, //insuranceType !== "Insurance Expired",
      prevValue: getPreviousValueFromErrorObject(errors, 'insuranceExpiry'),
      remarkHistory: getFieldRemarkHistory('insuranceExpiry', errors),
      mapper: MAPPER,
      isValidate: errors?.insuranceType?.subParts?.insuranceExpiry?.isValidate,
      part: 'insuranceType',
      subPart: 'insuranceExpiry',
      commentFlag: insuranceExpiryCommentFlag
    },
    {
      key: 'noClaimBonus',
      text: 'No Claim Bonus',
      checked: false,
      value: noClaimBonus,
      id: noClaimBonus,
      newValue: '',
      newId: '',
      isEditable: getIsEditable(noClaimBonusCommentFlag),
      isVisible: false,
      prevValue: getPreviousValueFromErrorObject(errors, 'noClaimBonus'),
      remarkHistory: getFieldRemarkHistory('noClaimBonus', errors),
      mapper: MAPPER,
      isValidate: errors?.insuranceType?.subParts?.noClaimBonus?.isValidate,
      part: 'insuranceType',
      subPart: 'noClaimBonus',
      commentFlag: noClaimBonusCommentFlag
    },
    {
      key: 'noClaimBonuspercent',
      text: 'No Claim Bonus Percentage',
      checked: false,
      value: noClaimBonusDep,
      id: noClaimBonusDep,
      newValue: '',
      newId: '',
      isEditable: getIsEditable(noClaimBonusDepCommentFlag),
      isVisible: noClaimBonus === 'Yes',
      prevValue: getPreviousValueFromErrorObject(errors, 'noClaimBonuspercent'),
      remarkHistory: getFieldRemarkHistory('noClaimBonuspercent', errors),
      mapper: MAPPER,
      isValidate:
        errors?.insuranceType?.subParts?.noClaimBonuspercent?.isValidate,
      part: 'insuranceType',
      subPart: 'noClaimBonuspercent',
      commentFlag: noClaimBonusDepCommentFlag
    },
  ];
};

export const getMismatchMapper = data => {
  const {
    inspectionData: { carDetails },
  } = data;
  const { errors } = carDetails;
  const MAPPER = RESET_REDUCER_KEYS.MISMATCH_MAPPER;
  /**
   * hardcoding part, subPart for mismatch section,
   * as mismatch options can be by default checked,
   * and all have same part and subPart values
   */
  let part = 'insuranceType';
  let subPart = 'mismatchInInsurance';
  let mismatchData = carDetails?.insuranceType?.subParts?.mismatchInInsurance?.mismatchInDS ?? {};

  return [
    {
      key: 'noMisMatch',
      text: 'No Mismatch',
      checked: false,
      value: 'No Mismatch',
      id: 'No Mismatch',
      newValue: '',
      newId: '',
      isEditable: false,
      isVisible: false,
      isAdded: false,
      isRejected: false,
      isImportant: false,
      part: 'insuranceType',
      subPart: 'mismatchInInsurance',
      remarkHistory: getFieldRemarkHistory('mismatchInInsurance', errors),
      mapper: MAPPER,
      isValidate:
        errors?.insuranceType?.subParts?.mismatchInInsurance?.isValidate,
      part,
      subPart,
      commentFlag: null
    },

    {
      key: 'variant',
      text: `Make/Model`,
      checked: false,
      value: `Make/Model`,
      id: `Make/Model`,
      newValue: '',
      newId: '',
      isEditable: false,
      isVisible: true,
      isAdded: false,
      isRejected: false,
      part: 'insuranceType',
      subPart: 'mismatchInInsurance',
      isImportant: false,
      mapper: MAPPER,
      isValidate:
        errors?.insuranceType?.subParts?.mismatchInInsurance?.isValidate,
      part,
      subPart,
      commentFlag: mismatchData['Make/Model']?.commentFlag ?? null
    },
    {
      key: 'fuelType',
      text: `${carDetails.fuelType ? carDetails.fuelType.title : ''}`,
      checked: false,
      value: `${carDetails.fuelType ? carDetails.fuelType.value : ''}`,
      id: `${carDetails.fuelType ? carDetails.fuelType.value : ''}`,
      newValue: '',
      newId: '',
      isEditable: false,
      isVisible: true,
      isAdded: false,
      isRejected: false,
      isImportant: false,
      part: 'insuranceType',
      subPart: 'mismatchInInsurance',
      mapper: MAPPER,
      isValidate:
        errors?.insuranceType?.subParts?.mismatchInInsurance?.isValidate,
      part,
      subPart,
      commentFlag: mismatchData[carDetails?.fuelType?.title ?? '']?.commentFlag ?? null
    },
    {
      key: 'engineNumber',
      text: 'Engine Number',
      checked: false,
      value: '',
      id: '',
      newValue: '',
      newId: '',
      isEditable: false,
      isVisible: true,
      isAdded: false,
      isRejected: false,
      isImportant: false,
      part: 'insuranceType',
      subPart: 'mismatchInInsurance',
      mapper: MAPPER,
      isValidate:
        errors?.insuranceType?.subParts?.mismatchInInsurance?.isValidate,
      part,
      subPart,
      commentFlag: mismatchData['Engine Number']?.commentFlag ?? null
    },
    {
      key: 'registrationNumber',
      text: `${
        carDetails.registrationNumber && carDetails.registrationNumber.title
          ? carDetails.registrationNumber.title
          : ''
      }`,
      checked: false,
      value: `${
        carDetails.registrationNumber && carDetails.registrationNumber.value
          ? carDetails.registrationNumber.value
          : ''
      }`,
      id: '',
      newValue: '',
      newId: '',
      isEditable: false,
      isVisible: true,
      isAdded: false,
      isRejected: false,
      isImportant: false,
      part: 'insuranceType',
      subPart: 'mismatchInInsurance',
      mapper: MAPPER,
      isValidate:
        errors?.insuranceType?.subParts?.mismatchInInsurance?.isValidate,
      part,
      subPart,
      commentFlag: mismatchData[carDetails?.registrationNumber?.title ?? '']?.commentFlag ?? null
    },
    {
      key: 'customerName',
      text: `${carDetails.customerName ? carDetails.customerName.title : ''}`,
      checked: false,
      value: `${carDetails.customerName ? carDetails.customerName.value : ''}`,
      id: '',
      newValue: '',
      newId: '',
      isEditable: false,
      isVisible: true,
      isAdded: false,
      isRejected: false,
      isImportant: false,
      part: 'insuranceType',
      subPart: 'mismatchInInsurance',
      mapper: MAPPER,
      isValidate:
        errors?.insuranceType?.subParts?.mismatchInInsurance?.isValidate,
      part,
      subPart,
      commentFlag: mismatchData[carDetails?.customerName?.title ?? '']?.commentFlag ?? null
    },
    {
      key: 'chassisNumber',
      text: `${carDetails.chassisNumber ? carDetails.chassisNumber.title : ''}`,
      checked: false,
      value: `${
        carDetails.chassisNumber ? carDetails.chassisNumber.value : ''
      }`,
      id: '',
      newValue: '',
      newId: '',
      isEditable: false,
      isVisible: true,
      isAdded: false,
      isRejected: false,
      isImportant: false,
      part: 'insuranceType',
      subPart: 'mismatchInInsurance',
      mapper: MAPPER,
      isValidate:
        errors?.insuranceType?.subParts?.mismatchInInsurance?.isValidate,
      part,
      subPart,
      commentFlag: mismatchData[carDetails?.chassisNumber?.title ?? '']?.commentFlag ?? null
    },
  ];
};

export const insuranceOtherDetailsMapper = data => {
  const {
    inspectionData: { carDetails },
  } = data;
  const { errors } = carDetails;
  const MAPPER = RESET_REDUCER_KEYS.OTHER_DETAILS_MAPPER;
  return [
    {
      key: 'ra',
      text: 'RA',
      checked: false,
      value: `${carDetails.raDetails ? carDetails.raDetails.value : ''}`,
      id: `${carDetails.raDetails ? carDetails.raDetails.value : ''}`,
      newValue: '',
      newId: '',
      isEditable: false,
      isVisible: true,
      remarkHistory: getFieldRemarkHistory('ra', errors),
      mapper: MAPPER,
    },

    {
      key: 'cj',
      text: 'CJ',
      checked: false,
      value: `${data.inspectionBy ? data.inspectionBy : ''}`,
      id: `${data.inspectionBy ? data.inspectionBy : ''}`,
      newValue: '',
      newId: '',
      isEditable: false,
      isVisible: true,
      remarkHistory: getFieldRemarkHistory('cj', errors),
      mapper: MAPPER,
    },
    {
      key: 'city',
      text: 'City',
      checked: false,
      value: `${carDetails.city ? carDetails.city.value : ''}`,
      id: `${carDetails.city.id ? carDetails.city.id : ''}`,
      newValue: '',
      newId: '',
      isEditable: false,
      isVisible: true,
      dependentFields: ['storeName'],
      remarkHistory: getFieldRemarkHistory('city', errors),
      mapper: MAPPER,
      isValidate: errors?.city?.isValidate,
      part: 'city',
      subPart: null,
    },
    {
      key: 'inspectionAt',
      text: 'Inspection at',
      checked: false,
      value: `${carDetails.inspectionAt ? carDetails.inspectionAt.value : ''}`,
      // id: `${carDetails.inspectionAt ? carDetails.inspectionAt.value : ""}`,
      newValue: '',
      newId: '',
      isEditable: false,
      isVisible: true,
      remarkHistory: getFieldRemarkHistory('inspectionAt', errors),
      mapper: MAPPER,
      isValidate: errors?.inspectionAt?.isValidate,
      part: 'inspectionAt',
      subPart: null,
    },
    {
      key: 'storeName',
      text: 'Branch',
      checked: false,
      value: `${carDetails.storeName ? carDetails.storeName.value : ''}`,
      id: `${carDetails.storeName.id ? carDetails.storeName.id : ''}`,
      newValue: '',
      newId: '',
      isEditable: false,
      isVisible: true,
      regionId: `${
        carDetails.storeName.regionId ? carDetails.storeName.regionId : ''
      }`,
      regionName: `${
        carDetails.storeName.regionName ? carDetails.storeName.regionName : ''
      }`,
      remarkHistory: getFieldRemarkHistory('storeName', errors),
      mapper: MAPPER,
      isValidate: errors?.storeName?.isValidate,
      part: 'storeName',
      subPart: null,
    },
  ];
};

export const getCarDetailsOdometerMapper = data => {
  const {
    inspectionData: { electricalsInterior },
  } = data;
  const { errors } = electricalsInterior;
  return [
    {
      key: 'odometerReading',
      text: `${
        electricalsInterior.odometerReading
          ? electricalsInterior.odometerReading.title
          : ''
      }`,
      checked: false,
      value: `${
        electricalsInterior.odometerReading
          ? electricalsInterior.odometerReading.value
          : ''
      }`,
      id: `${
        electricalsInterior.odometerReading
          ? electricalsInterior.odometerReading.value
          : ''
      }`,
      newValue: '',
      newId: '',
      isEditable: true,
      isVisible: true,
      isDisabled: false,
      remarkHistory: getFieldRemarkHistory('odometerReading', errors),
      mapper: 'interiorOdometerMapper',
      isValidate: errors?.odometerReading?.isValidate,
      part: 'odometerReading',
      subPart: null,
    },
  ];
};

export const getCarDetailsDuplicateMapper = data => {
  const {
    inspectionData: { carDetails },
    duplicateKeyConfig,
  } = data;
  const { errors } = carDetails;
  let finalImages = [];
  // let actualImages=["inspection/2020/02/27/RRQRQXZ/v1/carDetails/additionalInfo/duplicateKey/duplicatekeyadditionalinfoform1_1582802783.jpg"];

  let images =
    carDetails.duplicateKey && carDetails.duplicateKey.additionalInfo
      ? carDetails.duplicateKey.additionalInfo
      : [];
  if (images && images.length > 0) {
    finalImages.push(images[0].image);
    return finalImages.map(function(el, index) {
      const o = Object.assign({}, el);
      o.key = 'duplicateKey';
      o.checked = false;
      o.index = index;
      o.title = getTitleForImage(o.title);
      o.mapper = 'duplicateKeyMapper';
      o.part = 'duplicateKey';
      o.subPart = null;
      o.remarkHistory = getFieldRemarkHistory(
        'duplicateKey',
        carDetails?.errors || {},
      );
      o.isValidate = errors?.duplicateKey?.isValidate;
      if (duplicateKeyConfig == 1) {
        o.infoText = (
          <b className="flex-center">
            <InfoIcon className="mr-sm" /> Please reject if picture does not
            contain both keys
          </b>
        );
      }
      return o;
    });
  } else {
    return [];
  }
};

export const getExteriorComments = data => {
  const {
    inspectionData: {
      exteriorTyres: { comments },
    },
  } = data;

  if (comments) {
    comments.checked = false;
    comments.key = 'comments';
    comments.mapper = 'exteriorComments';
    return comments;
  } else {
    return null;
  }
};

export const getElectricalInteriorComments = data => {
  // const { inspectionData: { electricalsInterior } } = data;
  // let comments = electricalsInterior.comments ? electricalsInterior.comments : null;
  // if (comments) {
  //     comments.checked = false;
  //     return comments;
  // }
  // else {
  //     return null;
  // }

  // return comments;
  const {
    inspectionData: { electricalsInterior },
  } = data;
  let detail = electricalsInterior.comments;
  if (!!detail && !!detail.value && detail.value.length > 0) {
    let mapper = detail.value.map(function(el, index) {
      const o = Object.assign({}, el);
      o.checked = true;
      o.key = o.comment;
      o.text = o.comment;
      o.isVisible = true;
      o.isEditable = false;
      o.isImportant = true;
      o.remarkHistory = getFieldRemarkHistory(
        o.comment,
        electricalsInterior.errors,
      );
      o.mapper = 'odometerComments';
      return o;
    });
    return mapper;
  } else {
    return [];
  }
};

// export const getInteriorsMapper = (data)=>{
//     const {inspectionData:{carDetails}} = data;
//
//     return [
//         {
//             key: "Alloy Wheels",
//             text: "Alloy Wheels",
//             checked: false
//         },
//         {
//             key: "Steering Audio Control",
//             text: "Steering Audio Control",
//             checked: false
//         },
//         {
//             key: "Reverse Camera",
//             text: "Reverse Camera",
//             checked: false
//         },
//         {
//             key: "Airbag",
//             text: "Airbag",
//             checked: false
//         },
//         {
//             key: "Music System",
//             text: "Music System",
//             checked: false
//         },
//         {
//             key: "Leather Seat",
//             text: "Leather Seat",
//             checked: false
//         },
//         {
//             key: "Fabric Seat",
//             text: "Fabric Seat",
//             checked: false
//         },
//         {
//             key: "Sunroof",
//             text: "Sunroof",
//             checked: false
//         },
//         {
//             key: "ABS",
//             text: "ABS",
//             checked: false
//         },
//         {
//             key: "Rear Defogger",
//             text: "Rear Defogger",
//             checked: false
//         },
//         {
//             key: "Power Windows",
//             text: "Power Windows",
//             checked: false
//         },
//         {
//             key: "AC Cooling",
//             text: "AC Cooling",
//             checked: false
//         },
//         {
//             key: "Heater",
//             text: "Heater",
//             checked: false
//         },
//         {
//             key: "Climate Control AC",
//             text: "Climate Control AC",
//             checked: false
//         }
//
//     ];
// };

//interior
export const getExteriorDataMapper = data => {
  const {
    inspectionData: {
      exteriorTyres,
      carDetails,
      electricalsInterior,
      airConditioning,
    },
  } = data;
  const { errors } = exteriorTyres;
  return [
    {
      key: 'alloyWheels',
      text: `${
        exteriorTyres.alloyWheels ? exteriorTyres.alloyWheels.title : ''
      }`,
      checked: false,
      value: `${
        exteriorTyres.alloyWheels ? exteriorTyres.alloyWheels.value : ''
      }`,
      isVisible: true,
      additionalImageKey: true,
      prevValue: getPreviousValueFromErrorObject(errors, 'alloyWheels'),
      remarkHistory: getFieldRemarkHistory('alloyWheels', errors),
      mapper: 'externalDataMapper',
      isValidate: errors?.alloyWheels?.isValidate,
      part: 'alloyWheels',
      subPart: null,
    },
  ];
};
export const getRejectionRemarkMapper = data => {
  const { inspectionData } = data;
  let rejectionRemarkData = [];
  let finalRemarks = [];

  INSPECTION_REVIEW_ITEMS.map(item => {
    if (
      !!inspectionData[item].rejectionRemarks &&
      !!inspectionData[item].rejectionRemarks.value
    ) {
      rejectionRemarkData.push(inspectionData[item].rejectionRemarks.value);
    }
  });
  if (!!rejectionRemarkData && rejectionRemarkData.length > 0) {
    rejectionRemarkData = rejectionRemarkData.flat();
    rejectionRemarkData = getUnique(rejectionRemarkData, 'rejectionRemark');

    try {
      finalRemarks = rejectionRemarkData.map(function(el, index) {
        const o = Object.assign({}, el);
        o.timestamp = moment(o.timestamp, 'DD-MM-YYYY HH:mm:ss')
          .format('MM/DD/YYYY HH:mm:ss')
          .toString();
        o.newtimestamp = moment(o.timestamp, 'DD-MM-YYYY HH:mm:ss').format(
          'MM/DD/YYYY HH:mm:ss',
        );
        return o;
      });
      finalRemarks = finalRemarks.sort(
        (a, b) => new Date(b.timestamp) - new Date(a.timestamp),
      );
    } catch (e) {
      finalRemarks = rejectionRemarkData;
    }
  }
  return finalRemarks;
};
export const getInteriorElecctricalsMapper = data => {
  const {
    inspectionData: {
      electricalsInterior: { electrical, fuelLevel, errors },
    },
  } = data;
  let returnArr = [];
  let currentConditionVal = '';
  //     const fuelLevel = {
  //         title: "Fuel Level",
  //         value: "Reserve"
  //       };
  let mapper = [];
  if (!!electrical) {
    if (!!electrical.additionalInfo && electrical.additionalInfo.length > 0) {
      // if (!!electrical.additionalInfo[0].currentCondition) {
      //     if (!!electrical.additionalInfo[0].currentCondition.value && electrical.additionalInfo[0].currentCondition.value.length > 0)
      //         currentConditionVal = electrical.additionalInfo[0].currentCondition.value.toString();
      // }
      const additionalInfo = electrical.additionalInfo;
      additionalInfo.map(addnl => {
        if (!!addnl.currentCondition && !!addnl.currentCondition.value) {
          currentConditionVal = !!currentConditionVal
            ? currentConditionVal +
              ', ' +
              addnl.currentCondition.value.toString()
            : addnl.currentCondition.value.toString();
        }
        mapper.push(addnl);
      });
    }

    let obj = {
      key: 'electrical',
      text: `${electrical.title ? electrical.title : ''}`,
      value: `${electrical.value ? electrical.value : ''}`,
      additionalInfo: `${mapper.length > 0 ? JSON.stringify(mapper) : []}`,
      currentCondition: `${currentConditionVal}`,
      checked: false,
      isVisible: true,
      remarkHistory: getFieldRemarkHistory('electrical', errors),
      mapper: 'interiorElecctricalsMapper',
      isValidate: errors?.electrical?.isValidate,
      part: 'electrical',
      subPart: null,
    };
    returnArr.push(obj);
  }
  if (fuelLevel) {
    const fuelObject = {
      key: 'fuelLevel',
      text: fuelLevel.title || '',
      value: fuelLevel.value || '',
      checked: false,
      isVisible: true,
      isEditable: true,
      isDisabled: false,
      newValue: '',
      newId: '',
      remarkHistory: getFieldRemarkHistory('fuelLevel', errors),
      mapper: 'interiorElecctricalsMapper',
      isValidate: errors?.fuelLevel?.isValidate,
      part: 'fuelLevel',
      subPart: null,
    };
    returnArr.push(fuelObject);
  }
  return returnArr;
};
export const getInteriorDataMapper = data => {
  const {
    inspectionData: {
      exteriorTyres,
      carDetails,
      electricalsInterior,
      airConditioning,
    },
  } = data;
  const { errors } = electricalsInterior;
  return [
    {
      key: 'steeringMountedAudioControls',
      text: `${
        electricalsInterior.steeringMountedAudioControls
          ? electricalsInterior.steeringMountedAudioControls.title
          : ''
      }`,
      checked: false,
      value: `${
        electricalsInterior.steeringMountedAudioControls
          ? electricalsInterior.steeringMountedAudioControls.value
          : ''
      }`,
      isVisible: true,
      prevValue: getPreviousValueFromErrorObject(
        errors,
        'steeringMountedAudioControls',
      ),
      remarkHistory: getFieldRemarkHistory(
        'steeringMountedAudioControls',
        errors,
      ),
      mapper: 'interiorDataMapper',
      isValidate: errors?.steeringMountedAudioControls?.isValidate,
      part: 'steeringMountedAudioControls',
      subPart: null,
    },
    {
      key: 'reverseCamera',
      text: `${
        electricalsInterior.reverseCamera
          ? electricalsInterior.reverseCamera.title
          : ''
      }`,
      checked: false,
      value: `${
        electricalsInterior.reverseCamera
          ? electricalsInterior.reverseCamera.value
          : ''
      }`,
      isVisible: true,
      prevValue: getPreviousValueFromErrorObject(errors, 'reverseCamera'),
      remarkHistory: getFieldRemarkHistory('reverseCamera', errors),
      mapper: 'interiorDataMapper',
      isValidate: errors?.reverseCamera?.isValidate,
      part: 'reverseCamera',
      subPart: null,
    },
    {
      key: 'numberOfAirbags',
      text: `${
        electricalsInterior.numberOfAirbags
          ? electricalsInterior.numberOfAirbags.title
          : ''
      }`,
      checked: false,
      value: `${
        electricalsInterior.numberOfAirbags
          ? electricalsInterior.numberOfAirbags.value
          : ''
      }`,
      isVisible: true,
      prevValue: getPreviousValueFromErrorObject(errors, 'numberOfAirbags'),
      remarkHistory: getFieldRemarkHistory('numberOfAirbags', errors),
      mapper: 'interiorDataMapper',
      isValidate: errors?.numberOfAirbags?.isValidate,
      part: 'numberOfAirbags',
      subPart: null,
    },
    {
      key: 'musicSystem',
      text: `${
        electricalsInterior.musicSystem
          ? electricalsInterior.musicSystem.title
          : ''
      }`,
      checked: false,
      value: `${
        electricalsInterior.musicSystem
          ? electricalsInterior.musicSystem.value
          : ''
      }`,
      isVisible: true,
      prevValue: getPreviousValueFromErrorObject(errors, 'musicSystem'),
      remarkHistory: getFieldRemarkHistory('musicSystem', errors),
      mapper: 'interiorDataMapper',
      isValidate: errors?.musicSystem?.isValidate,
      part: 'musicSystem',
      subPart: null,
    },
    {
      key: 'leatherSeats',
      text: `${
        electricalsInterior.leatherSeats
          ? electricalsInterior.leatherSeats.title
          : ''
      }`,
      checked: false,
      value: `${
        electricalsInterior.leatherSeats
          ? electricalsInterior.leatherSeats.value
          : ''
      }`,
      isVisible: true,
      additionalImageKey: true,
      prevValue: getPreviousValueFromErrorObject(errors, 'leatherSeats'),
      remarkHistory: getFieldRemarkHistory('leatherSeats', errors),
      mapper: 'interiorDataMapper',
      isValidate: errors?.leatherSeats?.isValidate,
      part: 'leatherSeats',
      subPart: null,
    },
    {
      key: 'fabricSeats',
      text: `${
        electricalsInterior.fabricSeats
          ? electricalsInterior.fabricSeats.title
          : ''
      }`,
      checked: false,
      value: `${
        electricalsInterior.fabricSeats
          ? electricalsInterior.fabricSeats.value
          : ''
      }`,
      additionalImageKey: true,
      isVisible: true,
      prevValue: getPreviousValueFromErrorObject(errors, 'fabricSeats'),
      remarkHistory: getFieldRemarkHistory('fabricSeats', errors),
      mapper: 'interiorDataMapper',
      isValidate: errors?.fabricSeats?.isValidate,
      part: 'fabricSeats',
      subPart: null,
    },
    {
      key: 'sunroof',
      text: `${
        electricalsInterior.sunroof ? electricalsInterior.sunroof.title : ''
      }`,
      checked: false,
      value: `${
        electricalsInterior.sunroof ? electricalsInterior.sunroof.value : ''
      }`,
      additionalImageKey: true,
      isVisible: true,
      prevValue: getPreviousValueFromErrorObject(errors, 'sunroof'),
      remarkHistory: getFieldRemarkHistory('sunroof', errors),
      mapper: 'interiorDataMapper',
      isValidate: errors?.sunroof?.isValidate,
      part: 'sunroof',
      subPart: null,
    },
    {
      key: 'abs',
      text: `${
        electricalsInterior['abs'] ? electricalsInterior['abs'].title : ''
      }`,
      checked: false,
      value: `${
        electricalsInterior['abs'] ? electricalsInterior['abs'].value : ''
      }`,
      isVisible: true,
      prevValue: getPreviousValueFromErrorObject(errors, 'abs'),
      remarkHistory: getFieldRemarkHistory('abs', errors),
      mapper: 'interiorDataMapper',
      isValidate: errors?.abs?.isValidate,
      part: 'abs',
      subPart: null,
    },
    {
      key: 'rearDefogger',
      text: `${
        electricalsInterior.rearDefogger
          ? electricalsInterior.rearDefogger.title
          : ''
      }`,
      checked: false,
      value: `${
        electricalsInterior.rearDefogger
          ? electricalsInterior.rearDefogger.value
          : ''
      }`,
      isVisible: true,
      prevValue: getPreviousValueFromErrorObject(errors, 'rearDefogger'),
      remarkHistory: getFieldRemarkHistory('rearDefogger', errors),
      mapper: 'interiorDataMapper',
      isValidate: errors?.rearDefogger?.isValidate,
      part: 'rearDefogger',
      subPart: null,
    },
    {
      key: 'powerWindowsNumber',
      text: `${
        electricalsInterior.powerWindowsNumber
          ? electricalsInterior.powerWindowsNumber.title
          : ''
      }`,
      checked: false,
      value: `${
        electricalsInterior.powerWindowsNumber
          ? electricalsInterior.powerWindowsNumber.value
          : ''
      }`,
      isVisible: true,
      prevValue: getPreviousValueFromErrorObject(errors, 'powerWindowsNumber'),
      remarkHistory: getFieldRemarkHistory('powerWindowsNumber', errors),
      mapper: 'interiorDataMapper',
      isValidate: errors?.powerWindowsNumber?.isValidate,
      part: 'powerWindowsNumber',
      subPart: null,
    },
  ];
};

export const getAirConditoningDataMapper = data => {
  const {
    inspectionData: {
      exteriorTyres,
      carDetails,
      electricalsInterior,
      airConditioning,
    },
  } = data;
  const { errors } = airConditioning;
  return [
    {
      key: 'acCooling',
      text: `${
        airConditioning.acCooling ? airConditioning.acCooling.title : ''
      }`,
      checked: false,
      value: `${
        airConditioning.acCooling ? airConditioning.acCooling.value : ''
      }`,
      isVisible: true,
      prevValue: getPreviousValueFromErrorObject(errors, 'acCooling'),
      remarkHistory: getFieldRemarkHistory('acCooling', errors),
      mapper: 'airConditioningDataMapper',
      isValidate: errors?.acCooling?.isValidate,
      part: 'acCooling',
      subPart: null,
    },
    {
      key: 'heater',
      text: `${airConditioning.heater ? airConditioning.heater.title : ''}`,
      checked: false,
      value: `${airConditioning.heater ? airConditioning.heater.value : ''}`,
      isVisible: true,
      prevValue: getPreviousValueFromErrorObject(errors, 'heater'),
      remarkHistory: getFieldRemarkHistory('heater', errors),
      mapper: 'airConditioningDataMapper',
      isValidate: errors?.heater?.isValidate,
      part: 'heater',
      subPart: null,
    },
    {
      key: 'climateControlAc',
      text: `${
        airConditioning.climateControlAc
          ? airConditioning.climateControlAc.title
          : ''
      }`,
      checked: false,
      value: `${
        airConditioning.climateControlAc
          ? airConditioning.climateControlAc.value
          : ''
      }`,
      isVisible: true,
      prevValue: getPreviousValueFromErrorObject(errors, 'climateControlAc'),
      remarkHistory: getFieldRemarkHistory('climateControlAc', errors),
      mapper: 'airConditioningDataMapper',
      isValidate: errors?.climateControlAc?.isValidate,
      part: 'climateControlAc',
      subPart: null,
    },
  ];
};
const getPreviousValueFromErrorObject = (errObj, key) => {
  let prevValue = '';
  try {
    switch (key) {
      case 'rto':
        prevValue = getPrevValueForRTO(errObj, key);
        break;
      case 'rcCondition':
      case 'chassisNoOnRc':
      case 'misMatchInRc':
        prevValue = getPrevValueForRcAvailibility(errObj, key);
        break;
      case 'rtoNocAvailable':
      case 'rtoNocIssuedFor':
      case 'rtoNocIssuedDate':
      case 'rtoNmcAvailable':
        prevValue = getPrevValueForRtoNOCAvailibility(errObj, key);
        break;
      case 'noClaimBonuspercent':
      case 'noClaimBonus':
      case 'insuranceExpiry':
        prevValue = getPrevValueForInsuranceType(errObj, key);
        break;
      // case "isUnderHypothecation":
      case 'bankNocStatus':
      case 'bankNocExpiryDate':
        prevValue = getPrevValueForUnderHypothetication(errObj, key);
        break;
      case 'roadTaxDateValidity':
        prevValue = getPrevValueForRoadTaxPaid(errObj, key);
        break;
      default:
        prevValue = getPrevValue(errObj, key);
        break;
    }
  } catch (e) {
    prevValue = '';
  }
  return !!prevValue ? prevValue : '';
};
const getPrevValueForInsuranceType = (errObj, key) => {
  let prevValue = '';
  if (!!errObj) {
    const { insuranceType } = errObj;

    const insuranceExpiry =
      insuranceType.subParts && insuranceType.subParts.insuranceExpiry
        ? insuranceType.subParts.insuranceExpiry
        : null;
    const noClaimBonus =
      insuranceType.subParts && insuranceType.subParts.noClaimBonus
        ? insuranceType.subParts.noClaimBonus
        : null;
    let noClaimBonusDep =
      insuranceType.subParts &&
      insuranceType.subParts.noClaimBonus &&
      insuranceType.subParts.noClaimBonus.subParts &&
      insuranceType.subParts.noClaimBonus.subParts.noClaimBonusPercentage
        ? insuranceType.subParts.noClaimBonus.subParts.noClaimBonusPercentage
        : null;

    if (!!insuranceExpiry && key === 'insuranceExpiry') {
      prevValue = insuranceExpiry.value;
    }
    if (!!noClaimBonus && key === 'noClaimBonus') {
      prevValue = noClaimBonus.value;
    }
    if (!!noClaimBonusDep && key === 'noClaimBonuspercent') {
      prevValue = noClaimBonusDep.value;
    }
  }
  return prevValue;
};
const getPrevValueForRtoNOCAvailibility = (errObj, key) => {
  let prevValue = '';
  if (!!errObj) {
    const { rtoNocIssued } = errObj;

    const rtoNoIssuedSubparts = rtoNocIssued.subParts;
    const rtoNocAvailable =
      rtoNocIssued && rtoNoIssuedSubparts && rtoNoIssuedSubparts.rtoNocAvailable
        ? rtoNoIssuedSubparts.rtoNocAvailable
        : null;
    const rtoNmcAvailable =
      rtoNocIssued && rtoNoIssuedSubparts && rtoNoIssuedSubparts.rtoNmcAvailable
        ? rtoNoIssuedSubparts.rtoNmcAvailable
        : null;

    //rtoNocIssuedDate
    // const rtoNocAvailableSubparts = rtoNocAvailable && rtoNocAvailable.subParts ? rtoNocAvailable.subParts : null;
    const rtoNocIssuedFor =
      rtoNocIssued && rtoNoIssuedSubparts && rtoNoIssuedSubparts.rtoNocIssuedFor
        ? rtoNoIssuedSubparts.rtoNocIssuedFor
        : null;
    const rtoNocIssuedDate =
      rtoNocIssued &&
      rtoNoIssuedSubparts &&
      rtoNoIssuedSubparts.rtoNocIssuedDate
        ? rtoNoIssuedSubparts.rtoNocIssuedDate
        : null;

    if (!!rtoNocAvailable && key === 'rtoNocAvailable') {
      prevValue = rtoNocAvailable.value;
    }
    if (!!rtoNocIssuedFor && key === 'rtoNocIssuedFor') {
      prevValue = rtoNocIssuedFor.value;
    }
    if (!!rtoNocIssuedDate && key === 'rtoNocIssuedDate') {
      prevValue = rtoNocIssuedDate.value;
    }
    if (!!rtoNmcAvailable && key === 'rtoNmcAvailable') {
      prevValue = rtoNmcAvailable.value;
    }
  }
  return prevValue;
};
const getPrevValueForRoadTaxPaid = (errObj, key) => {
  let prevValue = '';
  if (!!errObj) {
    const { roadTaxPaid } = errObj;
    const roadTaxDateValidity =
      roadTaxPaid.subParts && roadTaxPaid.subParts.roadTaxDateValidity
        ? roadTaxPaid.subParts.roadTaxDateValidity
        : null;

    if (!!roadTaxDateValidity) {
      prevValue = roadTaxDateValidity.value;
    }
  }
  return prevValue;
};
const getPrevValueForUnderHypothetication = (errObj, key) => {
  let prevValue = '';
  if (!!errObj) {
    const { isUnderHypothecation } = errObj;
    const bankNocStatus =
      isUnderHypothecation.subParts &&
      isUnderHypothecation.subParts.bankNocStatus
        ? isUnderHypothecation.subParts.bankNocStatus
        : null;
    const bankNocExpiryDate =
      bankNocStatus &&
      bankNocStatus.subParts &&
      bankNocStatus.subParts.bankNocExpiryDate
        ? bankNocStatus.subParts.bankNocExpiryDate.value
        : '';

    if (!!bankNocStatus && key === 'bankNocStatus') {
      prevValue = bankNocStatus.value;
    }
    if (!!bankNocExpiryDate && key === 'bankNocExpiryDate') {
      prevValue = bankNocExpiryDate.value;
    }
  }
  return prevValue;
};
const getPrevValueForRcAvailibility = (errObj, key) => {
  let prevValue = '';
  if (!!errObj) {
    const { rcAvailability } = errObj;
    const rcCondition =
      rcAvailability.subParts && rcAvailability.subParts.rcCondition
        ? rcAvailability.subParts && rcAvailability.subParts.rcCondition
        : null;
    const chassisNoOnRc =
      rcAvailability.subParts &&
      rcAvailability.subParts.rcCondition &&
      rcAvailability.subParts.rcCondition.subParts &&
      rcAvailability.subParts.rcCondition.subParts.chassisNumberOnRC
        ? rcAvailability.subParts.rcCondition.subParts.chassisNumberOnRC
        : null;
    let mismatchInRc =
      rcAvailability.subParts &&
      rcAvailability.subParts.rcCondition &&
      rcAvailability.subParts.rcCondition.subParts &&
      rcAvailability.subParts.rcCondition.subParts.mismatchInRC
        ? rcAvailability.subParts.rcCondition.subParts.mismatchInRC
        : null;
    if (!!rcCondition && key === 'rcCondition') {
      prevValue = rcCondition.value;
    }
    if (!!chassisNoOnRc && key === 'chassisNoOnRc') {
      prevValue = chassisNoOnRc.value;
    }
    if (!!mismatchInRc && key === 'misMatchInRc') {
      prevValue = mismatchInRc.value;
    }
  }
  return prevValue;
};
const getPrevValueForRTO = (errObj, key) => {
  let prevValue = '';
  if (!!errObj) {
    const rto =
      errObj.registrationState.subParts && errObj.registrationState.subParts.rto
        ? errObj.registrationState.subParts.rto
        : null;
    if (!!rto) {
      prevValue = rto.value;
    }
  }
  return prevValue;
};
const getPrevValue = (errObj, key) => {
  let prevValue = '';
  if (!!errObj) {
    if (!!errObj[key]) {
      prevValue = errObj[key].value;
    }
  }
  return !!prevValue ? prevValue : '';
};
export const getMakeModelVariantMapper = data => {
  const {
    inspectionData: { carDetails },
  } = data;
  const { errors } = carDetails;
  const manufacturingMonth = carDetails.month
    ? `${monthNames[carDetails.month.value - 1]}`
    : '';
  let makeModelVairants =  [
    {
      key: 'make',
      text: `${carDetails.make ? carDetails.make.title : ''}`,
      checked: false,
      value: `${carDetails.make ? carDetails.make.value : ''}`,
      id: `${carDetails.make ? carDetails.make.id : ''}`,
      newValue: '',
      newId: '',
      apiValue: '',
      prevValue: getPreviousValueFromErrorObject(errors, 'make'),
      remarkHistory: getFieldRemarkHistory('make', errors),
      mapper: 'makeModelVariantMapper',
      isVerified: carDetails?.['make']?.isVerified,
    },
    {
      key: 'model',
      text: `${carDetails.model ? carDetails.model.title : ''}`,
      checked: false,
      value: `${carDetails.model ? carDetails.model.value : ''}`,
      id: `${carDetails.model ? carDetails.model.id : ''}`,
      newValue: '',
      newId: '',
      apiValue: '',
      prevValue: getPreviousValueFromErrorObject(errors, 'model'),
      remarkHistory: getFieldRemarkHistory('model', errors),
      mapper: 'makeModelVariantMapper',
      isVerified: carDetails?.['model']?.isVerified,
    },
    {
      key: 'year',
      text: `${carDetails.year ? carDetails.year.title : ''}`,
      checked: false,
      value: `${carDetails.year ? carDetails.year.value : ''}`,
      id: `${carDetails.year ? carDetails.year.id : ''}`,
      newValue: '',
      newId: '',
      apiValue: '',
      prevValue: getPreviousValueFromErrorObject(errors, 'year'),
      remarkHistory: getFieldRemarkHistory('year', errors),
      mapper: 'makeModelVariantMapper',
      isVerified: carDetails?.['year']?.isVerified,
    },
    {
      key: 'month',
      text: 'Manufacturing Month',
      checked: false,
      value: manufacturingMonth,
      id: carDetails.month ? carDetails.month.value : '',
      newValue: '',
      newId: '',
      isEditable: true,
      isVisible: true,
      isDisabled: false,
      prevValue: getPreviousValueFromErrorObject(errors, 'month'),
      remarkHistory: getFieldRemarkHistory('month', errors),
      mapper: 'makeModelVariantMapper',
      isVerified: carDetails?.['month']?.isVerified,
    },

    {
      key: 'variant',
      text: `${carDetails.variant ? carDetails.variant.title : ''}`,
      checked: false,
      value: `${carDetails.variant ? carDetails.variant.value : ''}`,
      id: `${carDetails.variant ? carDetails.variant.id : ''}`,
      newValue: '',
      newId: '',
      apiValue: '',
      transportation_category: `${
        carDetails.transportation_category
          ? carDetails.transportation_category
          : ''
      }`,
      variant_display_name_initial: `${carDetails.variant?.variantDisplayName ||
        ''}`,
      variant_display_name: `${carDetails.variant?.variantDisplayName || ''}`,
      prevValue: getPreviousValueFromErrorObject(errors, 'variant'),
      remarkHistory: getFieldRemarkHistory('variant', errors),
      mapper: 'makeModelVariantMapper',
      isVerified: carDetails?.['variant']?.isVerified,
    },
    {
      key: 'fuelType',
      text: `${carDetails.fuelType ? carDetails.fuelType.title : ''}`,
      checked: false,
      value: `${carDetails.fuelType ? carDetails.fuelType.value : ''}`,
      id: `${carDetails.fuelType ? carDetails.fuelType.value : ''}`,
      newValue: '',
      newId: '',
      apiValue: '',
      prevValue: getPreviousValueFromErrorObject(errors, 'fuelType'),
      remarkHistory: getFieldRemarkHistory('fuelType', errors),
      mapper: 'makeModelVariantMapper',
      isVerified: carDetails?.['fuelType']?.isVerified,
    },
    {
      key: 'toBeScrapped',
      text: `${carDetails.toBeScrapped ? carDetails.toBeScrapped.title : ''}`,
      checked: false,
      value: `${carDetails.toBeScrapped ? carDetails.toBeScrapped.value : ''}`,
      id: `${carDetails.toBeScrapped ? carDetails.toBeScrapped.value : ''}`,
      newValue: '',
      newId: '',
      apiValue: '',
      prevValue: getPreviousValueFromErrorObject(errors, 'toBeScrapped'),
      remarkHistory: getFieldRemarkHistory('toBeScrapped', errors),
      mapper: 'makeModelVariantMapper',
      isVerified: carDetails?.['toBeScrapped']?.isVerified,
    },
  ];
  makeModelVairants = addValueToDocumentMapper(carDetails, makeModelVairants, 'commentFlag');
  return makeModelVairants
};

const getImageIndex = (data, key) => {
  return data.find(item => item.hashKey === key).index;
};
export const getRcImagesMapper = (
  data,
  isImageFillRequired = false,
  prevCarDetails,
) => {
  let rcImagesData = [];
  let additionalImagesData = [];
  let chassisNumberImageData = [];
  let _errors = {};
  if (isImageFillRequired) {
    rcImagesData = data.rcImages;
    additionalImagesData = data.additionalImages;
    chassisNumberImageData = data.chassisNumberImage;
    _errors = prevCarDetails?.errors || {};
  } else {
    const { inspectionData: { carDetails = {} } = {} } = data || {};
    rcImagesData = carDetails.rcImages;
    additionalImagesData = carDetails.additionalImages;
    chassisNumberImageData = carDetails.chassisNumberImage;
    _errors = carDetails?.errors || {};
  }
  const blankRcImages = [
    {
      index: 0,
      hashKey: 'inspectionData_carDetails_rcImages_RC0',
      title: 'RC1',
      url: '',
      imageType: 'rc',
      key: 'rcImages',
      remarkHistory: getFieldRemarkHistory(
        'rcImages',
        _errors,
        'inspectionData_carDetails_rcImages_RC0',
      ),
      mapper: RESET_REDUCER_KEYS.RC_IMAGES_MAPPER,
      part: 'rcImages',
      subPart: null,
    },
    {
      //   index: 1,
      hashKey: 'inspectionData_carDetails_rcImages_RC1',
      title: 'RC2',
      url: '',
      imageType: 'rc',
      key: 'rcImages',
      remarkHistory: getFieldRemarkHistory(
        'rcImages',
        _errors,
        'inspectionData_carDetails_rcImages_RC1',
      ),
      mapper: RESET_REDUCER_KEYS.RC_IMAGES_MAPPER,
      part: 'rcImages',
      subPart: null,
    },
    {
      //   index: 2,
      hashKey: 'inspectionData_carDetails_rcImages_RC2',
      title: 'RC3',
      url: '',
      imageType: 'rc',
      key: 'rcImages',
      remarkHistory: getFieldRemarkHistory(
        'rcImages',
        _errors,
        'inspectionData_carDetails_rcImages_RC2',
      ),
      mapper: RESET_REDUCER_KEYS.RC_IMAGES_MAPPER,
      part: 'rcImages',
      subPart: null,
    },
    {
      //   index: 3,
      hashKey: 'inspectionData_carDetails_rcImages_RC3',
      title: 'RC4',
      url: '',
      imageType: 'rc',
      key: 'rcImages',
      remarkHistory: getFieldRemarkHistory(
        'rcImages',
        _errors,
        'inspectionData_carDetails_rcImages_RC3',
      ),
      mapper: RESET_REDUCER_KEYS.RC_IMAGES_MAPPER,
      part: 'rcImages',
      subPart: null,
    },
    {
      //   index: 4,
      hashKey: 'inspectionData_carDetails_rcImages_RC4',
      title: 'RC5',
      url: '',
      imageType: 'rc',
      key: 'rcImages',
      remarkHistory: getFieldRemarkHistory(
        'rcImages',
        _errors,
        'inspectionData_carDetails_rcImages_RC4',
      ),
      mapper: RESET_REDUCER_KEYS.RC_IMAGES_MAPPER,
      part: 'rcImages',
      subPart: null,
    },
  ];

  let rcImages = rcImagesData || [];
  let length = !!rcImages.length ? rcImages.length : 0;
  let mapper = blankRcImages.map((item, index) => {
    let o = Object.assign({}, item);
    let obj = !!rcImages.length && rcImages.find(x => x.hashKey === o.hashKey);
    if (!!obj) {
      o = obj;
      o.prevValue = getPrevValueForImages(_errors, 'rcImages', o.hashKey);
      o.remarkHistory = getFieldRemarkHistory('rcImages', _errors, obj.hashKey);
    }
    o.checked = false;
    o.index = index;
    o.imageType = 'rc';
    o.key = 'rcImages';
    o.mapper = RESET_REDUCER_KEYS.RC_IMAGES_MAPPER;
    o.isValidate = _errors?.rcImages?.find(
      imgObj => imgObj.hashKey === o.hashKey,
    )?.isValidate;
    o.part = 'rcImages';
    o.subPart = null;
    o.isPdf = /\.pdf/i.test(o.url);
    return o;
  });
  let i = blankRcImages.length;
  let additionalImage = additionalImagesData || [];
  let allAdditionalImages = [];
  allAdditionalImages = BLANK_ADDITIONAL_IMAGES.map(function(el, index) {
    let o = Object.assign({}, el);
    let obj =
      additionalImage && additionalImage.find(x => x.hashKey === o.hashKey);
    if (!!obj) {
      o = obj;
      o.prevValue = getPrevValueForImages(
        _errors,
        'additionalImages',
        o.hashKey,
      );
    }

    o.checked = false;
    o.index = i++;
    o.imageType = 'addnl';
    o.key = 'additionalImages';
    o.mapper = RESET_REDUCER_KEYS.RC_IMAGES_MAPPER;
    o.remarkHistory = getFieldRemarkHistory(
      'additionalImages',
      _errors,
      o.hashKey,
    );
    o.isValidate = _errors?.additionalImages?.find(
      imgObj => imgObj.hashKey === o.hashKey,
    )?.isValidate;
    o.part = 'additionalImages';
    o.subPart = null;
    return o;
  });

  let chassisNoImage = chassisNumberImageData || [];
  if (!!chassisNoImage && chassisNoImage.length > 0) {
    chassisNoImage[0].index = i;
    chassisNoImage[0].imageType = 'chasis';
    chassisNoImage[0].key = 'chassisNumberImage';
    chassisNoImage[0].mapper = RESET_REDUCER_KEYS.RC_IMAGES_MAPPER;
    chassisNoImage[0].remarkHistory = getFieldRemarkHistory(
      'chassisNumberImage',
      _errors,
      chassisNoImage[0].hashKey,
    );
    chassisNoImage[0].isValidate = _errors?.chassisNumberImage?.find(
      imgObj => imgObj.hashKey === chassisNoImage[0].hashKey,
    )?.isValidate;
    chassisNoImage[0].prevValue = getPrevValueForImages(
      _errors,
      'chassisNumberImage',
      chassisNoImage[0].hashKey,
    );
    chassisNoImage[0].part = 'chassisNumberImage';
    chassisNoImage[0].subPart = null;
  }
  mapper = [...mapper, ...allAdditionalImages, ...chassisNoImage];
  return mapper;
};

const getPrevValueForImages = (errObj, imageType, key) => {
  let prevValue = '';
  if (!!errObj) {
    if (!!errObj[imageType] && errObj[imageType].length > 0) {
      let rejectedImage = errObj[imageType].find(x => x.hashKey === key);
      if (!!rejectedImage) {
        prevValue = rejectedImage.message;
      } else {
        prevValue = '';
      }
    }
  }
  return prevValue;
};

export const getExteriorTyresImagesMapper = data => {
  const {
    inspectionData: { exteriorTyres },
  } = data;
  const { errors } = exteriorTyres;
  let images = exteriorTyres.mainImages;
  const exteriorImagesOrder = [
    'front main',
    'front left side',
    'front right side',
    'rear right side',
    'rear main',
    'front with hood open',
    'rear boot opened',
  ];
  let arr = [];
  exteriorImagesOrder.map(order => {
    images.map(current => {
      if (current.title.toLowerCase().match(order)) {
        arr.push(current);
      }
    });
  });

  if (images) {
    const mapper = arr.map(function(el, index) {
      const o = Object.assign({}, el);
      o.checked = false;
      o.index = index;
      o.isMaskedByQc = false;
      o.prevValue = getPrevValueForImages(errors, 'mainImages', o.hashKey);
      o.mapper = RESET_REDUCER_KEYS.EXTERIOR_TYRES_MAPPER;
      o.remarkHistory = getFieldRemarkHistory('mainImages', errors, o.hashKey);
      o.key = 'mainImages';
      o.isValidate = errors?.mainImages?.find(
        imgObj => imgObj.hashKey === o.hashKey,
      )?.isValidate;
      o.part = 'mainImages';
      o.subPart = null;
      return o;
    });
    return mapper;
  } else {
    return [];
  }
};

export const getInteriorImagesMapper = data => {
  const {
    inspectionData: { electricalsInterior },
  } = data;
  const { errors } = electricalsInterior;
  let images = electricalsInterior.mainImages;
  // let theRemovedElement = images.shift();
  let interiorImagesOrder = [
    'Interior From Back',
    'Interior From Rear Right',
    'Interior From Front Right',
  ];

  let odometerImageIndex = images.findIndex(item =>
    item.title.match(/odometer/i),
  );

  let odometerImage = {};
  if (odometerImageIndex > -1) {
    odometerImage = images[odometerImageIndex];
    images.splice(odometerImageIndex, 1);
  }
  let arr = [];
  // let actualInteriorImages = ["inspection/2020/02/27/RRQ6QRD/v0/electricalsInterior/mainImages/interiorfromfrontright_1582799008.jpg",
  // "inspection/2020/02/27/RRQ6QRD/v0/electricalsInterior/mainImages/interiorfromrearright_1582799010.jpg",
  // "inspection/2020/02/27/RRQ6QRD/v0/electricalsInterior/mainImages/interiorfrombackseat_1582799013.jpg",
  // "inspection/2020/02/27/RRQ6QRD/v0/electricalsInterior/mainImages/odometer_1582798948.jpg"
  // ];
  interiorImagesOrder.map(order => {
    images.map(current => {
      if (current.title.toLowerCase().match(order.toLowerCase())) {
        arr.push(current);
      }
    });
  });
  arr.push(odometerImage);

  if (images) {
    const mapper = arr.map(function(el, index) {
      const o = Object.assign({}, el);
      o.checked = false;
      o.index = index;
      o.prevValue = getPrevValueForImages(errors, 'mainImages', o.hashKey);
      o.mapper = 'interiorImagesMapper';
      o.remarkHistory = getFieldRemarkHistory('mainImages', errors, o.hashKey);
      o.key = 'mainImages';
      o.isValidate = errors?.mainImages?.find(
        imgObj => imgObj.hashKey === o.hashKey,
      )?.isValidate;
      o.part = 'mainImages';
      o.subPart = null;
      return o;
    });
    return mapper;
  } else {
    return [];
  }
};

export const getInsuranceImageMapper = (
  data,
  isImageFillRequired = false,
  prevCarDetails,
) => {
  const { inspectionData: { carDetails } = {} } = data || {};

  let _errors = {};
  let images = [];
  if (isImageFillRequired) {
    images = data.insuranceImages;
    _errors = prevCarDetails?.errors || {};
  } else {
    images = carDetails.insuranceImages;
    _errors = carDetails?.errors || {};
  }
  const blankInsuranceImages = [
    {
      hashKey: 'inspectionData_carDetails_insuranceImages_Insurance0',
      title: 'Insurance1',
      url: '',
      key: 'insuranceImages',
      mapper: RESET_REDUCER_KEYS.INSURANCE_IMAGE_MAPPER,
      remarkHistory: getFieldRemarkHistory(
        'insuranceImages',
        _errors,
        'inspectionData_carDetails_insuranceImages_Insurance0',
      ),
      part: 'insuranceImages',
      subPart: null,
    },
    {
      hashKey: 'inspectionData_carDetails_insuranceImages_Insurance1',
      title: 'Insurance2',
      url: '',
      key: 'insuranceImages',
      mapper: RESET_REDUCER_KEYS.INSURANCE_IMAGE_MAPPER,
      remarkHistory: getFieldRemarkHistory(
        'insuranceImages',
        _errors,
        'inspectionData_carDetails_insuranceImages_Insurance1',
      ),
      part: 'insuranceImages',
      subPart: null,
    },
    {
      hashKey: 'inspectionData_carDetails_insuranceImages_Insurance2',
      title: 'Insurance3',
      url: '',
      key: 'insuranceImages',
      mapper: RESET_REDUCER_KEYS.INSURANCE_IMAGE_MAPPER,
      remarkHistory: getFieldRemarkHistory(
        'insuranceImages',
        _errors,
        'inspectionData_carDetails_insuranceImages_Insurance2',
      ),
      part: 'insuranceImages',
      subPart: null,
    },
    {
      hashKey: 'inspectionData_carDetails_insuranceImages_Insurance3',
      title: 'Insurance4',
      url: '',
      key: 'insuranceImages',
      mapper: RESET_REDUCER_KEYS.INSURANCE_IMAGE_MAPPER,
      remarkHistory: getFieldRemarkHistory(
        'insuranceImages',
        _errors,
        'inspectionData_carDetails_insuranceImages_Insurance3',
      ),
      part: 'insuranceImages',
      subPart: null,
    },
    {
      hashKey: 'inspectionData_carDetails_insuranceImages_Insurance4',
      title: 'Insurance5',
      url: '',
      key: 'insuranceImages',
      mapper: RESET_REDUCER_KEYS.INSURANCE_IMAGE_MAPPER,
      remarkHistory: getFieldRemarkHistory(
        'insuranceImages',
        _errors,
        'inspectionData_carDetails_insuranceImages_Insurance4',
      ),
      part: 'insuranceImages',
      subPart: null,
    },
  ];

  let mapper = [];
  if (images) {
    mapper = images.map(function(el, index) {
      const o = Object.assign({}, el);
      o.checked = false;
      o.index = index;
      o.prevValue = getPrevValueForImages(
        _errors,
        'insuranceImages',
        o.hashKey,
      );
      o.key = 'insuranceImages';
      o.mapper = RESET_REDUCER_KEYS.INSURANCE_IMAGE_MAPPER;
      o.remarkHistory = getFieldRemarkHistory(
        'insuranceImages',
        _errors,
        o.hashKey,
      );
      o.isValidate = _errors?.insuranceImages?.find(
        imgObj => imgObj.hashKey === o.hashKey,
      )?.isValidate;
      o.part = 'insuranceImages';
      o.subPart = null;
      o.isPdf = o.url.split('.').pop() === 'pdf';
      return o;
    });
  }
  let length = (images && images.length) || 0;
  for (let i = length; i < 5; i++) {
    blankInsuranceImages[i].index = i;
    mapper.push(blankInsuranceImages[i]);
  }

  return mapper;
};

export const getTyreMapper = (data, key, storeMapper) => {
  const {
    inspectionData: { exteriorTyres },
  } = data;
  const { errors } = exteriorTyres;
  // let actualTyreImages = [];
  let images = exteriorTyres[key];
  let _value = !!images && !!images.value ? images.value : '';
  let mainTitle = !!images && !!images.title ? images.title : '';
  // if(key.match(/spare/)){
  //     actualTyreImages=["inspection/2020/02/26/RRQR6ZR/v0/exteriorTyres/additionalInfo/spareTyre/sparetyreadditionalinfoform1_1582717437.jpg"];
  // }
  // else{
  //     actualTyreImages=["inspection/2020/02/26/RRQR6ZR/v0/exteriorTyres/additionalInfo/lhsRearTyre/lhsreartyreadditionalinfoform1_1582718019.jpg"];
  // }
  images =
    images.additionalInfo &&
    images.additionalInfo[0] &&
    images.additionalInfo[0].image;
  const tread =
    exteriorTyres[key].additionalInfo && exteriorTyres[key].additionalInfo[0]
      ? exteriorTyres[key].additionalInfo[0].treadDepth
      : {};
  const currentCondition =
    exteriorTyres[key].additionalInfo && exteriorTyres[key].additionalInfo[0]
      ? exteriorTyres[key].additionalInfo[0].currentCondition
      : {};
  const finalMapper = [];

  finalMapper.push(images);
  if (images) {
    const mapper = finalMapper.map(function(el, index) {
      const o = Object.assign({}, el);
      o.checked = false;
      o.imageTitle = o.title;
      o.mainTitle = mainTitle;
      o.index = index;
      o.title = getTitleForImage(o.title);
      o.newValue = _value;
      o.treadDepth = tread;
      o.currentCondition = currentCondition;
      o.prevValue = getPrevValueForImages(errors, key, o.hashKey);
      o.remarkHistory = getFieldRemarkHistory(key, errors);
      o.isValidate = errors?.[key]?.isValidate;

      o.mapper = storeMapper;
      o.key = key;
      o.part = key;
      o.subPart = null;
      return o;
    });
    return mapper;
  } else {
    return [];
  }
};

const getPrevValueForAdditionalImages = addnlItem => {
  let prevValue = '';
  try {
    const { error } = addnlItem;
    if (!!error) {
      prevValue = !!error.message ? error.message : '';
    }
  } catch (e) {
    prevValue = '';
  }
  return prevValue;
};

export const getAdditionalImagesMapper = data => {
  const { additionalImages, inspectionData } = data;
  let mapper = [];
  let withSubPartMapper = [];
  let count = 0;

  if (additionalImages) {
    Object.keys(additionalImages).map((item, index) => {
      if (
        additionalImages[item] &&
        additionalImages[item].additionalInfo &&
        additionalImages[item].additionalInfo.length > 0
      ) {
        if (additionalImages[item].subParts === undefined) {
          const additionalInfo = additionalImages[item].additionalInfo;
          additionalInfo.map(image => {
            image.checked = false;
            image.isMaskedByQc = false;
            // image.orignalUrlBeforeMask = (!!image["image"] && !!image["image"].originalUrl) ? image["image"].originalUrl : !!image["image"] ? image["image"].url : ""
            image.key = item;
            image.section = additionalImages[item].section;
            image.title = additionalImages[item].title;
            image.value = additionalImages[item].value;
            image.index = count;
            image.mapper = 'additionalImagesMapper';
            image.remarkHistory = getFieldRemarkHistory(
              item,
              inspectionData[additionalImages[item].section].errors,
            );
            image.prevValue = getPrevValueForAdditionalImages(
              additionalImages[item],
            );
            image.part = item;
            image.subPart = null;
            image.isValidate =
              inspectionData[additionalImages[item].section]?.errors?.[
                item
              ]?.isValidate;
            mapper.push(image);
            ++count;
          });
        }
        //Special Logic for if Additional Images with additional info have sub parts also
        if (!!additionalImages[item].subParts) {
          const subPartsInfo = additionalImages[item].subParts;
          let subPartKeys = [];
          Object.keys(subPartsInfo).forEach(function(key) {
            var value = subPartsInfo[key];
            subPartKeys.push(key);
          });
          if (subPartKeys.length > 0) {
            for (let i = 0; i < subPartKeys.length; i++) {
              const keysubParts = subPartsInfo[subPartKeys[i]];
              const additionalInfo = keysubParts.additionalInfo;
              if (!!additionalInfo) {
                additionalInfo.map(image => {
                  image.checked = false;
                  image.isMaskedByQc = false;
                  // image.orignalUrlBeforeMask = (!!image["image"] && !!image["image"].originalUrl) ? image["image"].originalUrl : !!image["image"] ? image["image"].url : ""
                  image.key = item; //subPartKeys[i];
                  image.section = additionalImages[item].section;
                  image.title = additionalImages[item].title;
                  image.value = additionalImages[item].value;

                  image.subPart_key = subPartKeys[i];
                  image.subPart_title = subPartsInfo[subPartKeys[i]].title;
                  image.subPart_value = subPartsInfo[subPartKeys[i]].value;

                  image.index = count;
                  image.prevValue = getPrevValueForAdditionalImages(
                    additionalImages[item],
                  );
                  image.part = item;
                  image.subPart = subPartKeys[i];
                  image.mapper = 'additionalImagesMapper';
                  image.remarkHistory = getFieldRemarkHistory(
                    subPartKeys[i],
                    inspectionData[additionalImages[item].section].errors,
                  );
                  image.isValidate =
                    inspectionData[additionalImages[item].section]?.errors?.[
                      item
                    ]?.isValidate;
                  mapper.push(image);
                  ++count;
                });
              }
            }
          }
        }
        // end Special Logic for if Additional Images with additional info have sub parts also
      } else if (additionalImages[item] && additionalImages[item].subParts) {
        // if (item === "lights") {
        const subPartsInfo = additionalImages[item].subParts;
        let subPartKeys = [];
        // let newCount = count;
        Object.keys(subPartsInfo).forEach(function(key) {
          var value = subPartsInfo[key];
          subPartKeys.push(key);
        });
        if (subPartKeys.length > 0) {
          for (let i = 0; i < subPartKeys.length; i++) {
            const keysubParts = subPartsInfo[subPartKeys[i]];
            const additionalInfo = keysubParts?.additionalInfo;
            if (!!additionalInfo) {
              additionalInfo.map(image => {
                image.checked = false;
                image.isMaskedByQc = false;
                // image.orignalUrlBeforeMask = (!!image["image"] && !!image["image"].originalUrl) ? image["image"].originalUrl : !!image["image"] ? image["image"].url : ""
                image.key = item; //subPartKeys[i];
                image.section = additionalImages[item].section;
                image.title = additionalImages[item].title;
                image.value = additionalImages[item].value;

                image.subPart_key = subPartKeys[i];
                image.subPart_title = subPartsInfo[subPartKeys[i]].title;
                image.subPart_value = subPartsInfo[subPartKeys[i]].value;
                image.mapper = 'additionalImagesMapper';
                image.remarkHistory = getFieldRemarkHistory(
                  subPartKeys[i],
                  inspectionData[additionalImages[item].section].errors,
                );
                image.isValidate =
                  inspectionData[additionalImages[item].section]?.errors?.[
                    item
                  ]?.isValidate;
                image.part = item;
                image.subPart = subPartKeys[i];
                image.index = count;
                image.prevValue = getPrevValueForAdditionalImages(
                  additionalImages[item],
                );
                mapper.push(image);
                ++count;
              });
            }
          }
        }
        // }
      }
    });
    let finalMapper = [...mapper, ...withSubPartMapper];
    return finalMapper;
  } else {
    return [];
  }
};

export const getVersionText = version => {
  if (version == 0) {
    return 'New Inspection';
  } else {
    return 'Re-Inspection';
  }
};

export const isAssignedToMe = currentUser => {
  const email = getFromLocalStorage(LOCAL_STORAGE_KEYS.EMAIL);
  if (email && currentUser) {
    return currentUser.trim().toLowerCase() === email.trim().toLowerCase();
  }
};

export const getTimeStringFromSeconds = secs => {
  return moment.utc(secs * 1000).format('DD/MM/YYYY');
};

export const removeDuplicates = (originalArray, prop) => {
  var newArray = [];
  var lookupObject = {};

  for (var i in originalArray) {
    lookupObject[originalArray[i][prop]] = originalArray[i];
  }

  for (i in lookupObject) {
    newArray.push(lookupObject[i]);
  }
  return newArray;
};

export const getUnique = (arr, comp) => {
  //store the comparison  values in array
  const unique = arr
    .map(e => e[comp])
    // store the keys of the unique objects
    .map((e, i, final) => final.indexOf(e) === i && i)
    // eliminate the dead keys & return unique objects
    .filter(e => arr[e])
    .map(e => arr[e]);

  return unique;
};

export const reloadWithDelay = time => {
  setTimeout(() => {
    window.location.reload();
  }, time);
};

export const getTitleForImage = title => {
  let arr = title.split('_');
  if (arr[0] && arr[1]) {
    if (title.match(/spare/i) || title.match(/duplicate/i)) {
      return arr[0] + ' ' + arr[1];
    } else {
      if (arr[2]) {
        return arr[0] + ' ' + arr[1] + ' ' + arr[2];
      }
    }
  }
};

export const getCityIdParams = (text, cityIds) => {
  let str = '';
  for (let i = 0; i < cityIds.length; i++) {
    str += `&${text}[]=${cityIds[i].id}`;
  }
  return str;
};
export const isAuthenticated = () => {
  const access_token = getSessionToken();
  return !!access_token;
};

export const getHomepageURL = (role) => {
  if (role === ROLE_KEYS.GS) {
    return '/gs-unflagging';
  } else if ([ROLE_KEYS.OTHER, ROLE_KEYS.QC_VERSION].includes(role)) {
    return '/inspection-version';
  } else if ([ROLE_KEYS.QC, ROLE_KEYS.QC_ADMIN, ROLE_KEYS.QC_STORE_MANAGER].includes(role)) {
    return '/inspection';
  } else {
    return '/unauth';
  }
}
export const isAuthorizedAccess = pathName => {
  const localData = getFromLocalStorage(LOCAL_STORAGE_KEYS.LOGIN_DATA);
  let role = localData ? JSON.parse(localData)[LOCAL_STORAGE_KEYS.ROLE] : '';
  if (!!role) {
    if (
      role !== ROLE_KEYS.QC &&
      role !== ROLE_KEYS.QC_BIKE &&
      role !== ROLE_KEYS.QC_STORE_MANAGER &&
      role !== ROLE_KEYS.QC_ADMIN &&
      role !== ROLE_KEYS.GS &&
      role !== ROLE_KEYS.QC_VERSION
    ) {
      role = ROLE_KEYS.OTHER;
    }
    // let pathName = window.location.pathname;
    const path = pathName.trim().replace(CAR_ID_REGEX, '');
    let data = USER_ROLEACCESS_PATHS;

    let rolesPaths =
      data['roleAccess'].length > 0 &&
      !!data['roleAccess'].find(
        x => x.role.toLowerCase() === role.toLowerCase(),
      )
        ? data['roleAccess'].find(
            x => x.role.toLowerCase() === role.toLowerCase(),
          ).paths
        : [];
    if (rolesPaths.indexOf(path) > -1) {
      return true;
    } else return false;
  } else {
    return false;
  }
};

export const isQCUser = () => {
  let localData = getFromLocalStorage(LOCAL_STORAGE_KEYS.LOGIN_DATA);
  return localData
    ? JSON.parse(localData)[LOCAL_STORAGE_KEYS.QC_USER] === true
      ? true
      : false
    : false;
};

export const isUserRoleBIkeQc = () => {
  if (window && window.location.pathname.includes('bike')) {
    return true;
  }
  let role = getFromLocalStorage(LOCAL_STORAGE_KEYS.ROLE);
  if (role === ROLE_KEYS.QC_BIKE) {
    return true;
  } else {
    return false;
  }
};

export const GetQueryString = field => {
  var href = window.location.href;
  var reg = new RegExp('[?&]' + field + '=([^&#]*)', 'i');
  var string = reg.exec(href);
  return string ? string[1] : null;
};

export const isExcludedUser = () => {
  let localData = getFromLocalStorage(LOCAL_STORAGE_KEYS.LOGIN_DATA);
  return localData
    ? JSON.parse(localData)[LOCAL_STORAGE_KEYS.IS_EXCLUDED_USER] === true
      ? true
      : false
    : false;
};
export const MergeArrayRemooveDupes = (arr1, arr2) => {
  const mergedArray = [...arr1, ...arr2];
  let set = new Set();
  let unionArray = mergedArray.filter(item => {
    if (!set.has(item.key)) {
      set.add(item.key);
      return true;
    }
    return false;
  }, set);
  return unionArray;
};
export const formatDate = date => {
  return moment(date).format('DD-MM-YYYY');
};
export const formatDateForBike = date => {
  const formattedDate = moment(date, 'DD-MM-YYYY').format('DD/MM/YYYY');
  return formattedDate; //moment(date).format("DD-MM-YYYY");
};
export const getFullMonthName = month => {
  let mapMonth = '';
  if (!!month) {
    let objMonth = MONTH_MAP.filter(m => m.id === month.toLowerCase().trim());
    if (!!objMonth && objMonth.length > 0) {
      mapMonth = objMonth[0].value;
    }
  }
  return mapMonth;
};
export const isValidDate = date => {
  const dateFormat = moment(date).format('DD-MM-YYYY');
  if (dateFormat === 'Invalid date') {
    return false;
  } else {
    return true;
  }
};

export const removeSpecialCharAndSpace = val => {
  try {
    return val.replace(/[^a-zA-Z ]/g, '').replace(/ /g, '');
  } catch (e) {
    return val;
  }
};

export const convertKeyIntoString = (strKey, separator = '_') => {
  if (!strKey) {
    return;
  }
  const keyString = strKey
    .replace(/([a-z\d])([A-Z])/g, '$1' + separator + '$2')
    .replace(/([A-Z]+)([A-Z][a-z\d]+)/g, '$1' + separator + '$2');
  return keyString[0].toUpperCase() + keyString.replace(keyString[0], '');
};

export const onBackLogin = (message = '') => {
  window.location = `${window.origin}/login${message ? `?key=${message}` : ''}`;
};

// Specific to Bike related methods

export const getMakeModelVariantMapperForBike = data => {
  // const { inspectionData } = data;
  return [
    {
      key: 'make',
      text: `${data.make ? data.make : ''}`,
      checked: false,
      value: `${data.make ? data.make : ''}`,
      id: `${data.make ? data.make : ''}`,
      newValue: '',
      newId: '',
      apiValue: '',
    },
    {
      key: 'model',
      text: `${data.model ? data.model : ''}`,
      checked: false,
      value: `${data.model ? data.model : ''}`,
      id: `${data.model ? data.model : ''}`,
      newValue: '',
      newId: '',
      apiValue: '',
    },
    {
      key: 'variant',
      text: `${data.variant ? data.variant : ''}`,
      checked: false,
      value: `${data.variant ? data.variant : ''}`,
      id: `${data.variant ? data.variant : ''}`,
      newValue: '',
      newId: '',
      apiValue: '',
    },
  ];
};

export const getBikeAdditionalImagesMapper = data => {
  const { images, videos } = data;
  let imageMapper,
    videoMapper,
    mediaArrayMapper = [];
  let counter = 0;

  if (videos && videos.length) {
    videoMapper = videos.map(video => ({
      ...video,
      url: video.uri,
      mediaType: 'video',
      checked: false,
      index: counter++,
    }));
    mediaArrayMapper = [...mediaArrayMapper, ...videoMapper];
  }

  if (images && images.length) {
    imageMapper = images
      .filter(image => image.type === BIKE_IMAGE_TYPE.DEFECT_IMAGES)
      .map(image => ({
        ...image,
        url: image.uri,
        mediaType: 'image',
        checked: false,
        index: counter++,
      }));
    mediaArrayMapper = [...mediaArrayMapper, ...imageMapper];
  }

  return mediaArrayMapper;
};

export const convertObjectToParams = params => {
  if (typeof params === 'string') {
    return params;
  }
  let finalParams = '';
  Object.keys(params).forEach((key, index) => {
    if (index === 0) {
      finalParams += '?';
    }
    finalParams += key + '=' + params[key];
    if (index + 1 !== Object.keys(params).length) {
      finalParams += '&';
    }
  });
  return finalParams;
};

export const convertFiltersToValueArray = filters => {
  let finalFilters = {};
  for (let filterKey in filters) {
    if (Array.isArray(filters[filterKey])) {
      finalFilters[filterKey] = filters[filterKey].map(
        filterValue => filterValue.value,
      );
    } else {
      finalFilters[filterKey] = filters[filterKey];
    }
  }
  return finalFilters;
};

export const computeStyles = (...classes) => classes.join(' ');

export const downloadTableData = (tableId, fileName) => {
  let csvData = [];
  let tableRowsSelector = `#${tableId} tr`;
  let rows = document.querySelectorAll(tableRowsSelector);

  for (var i = 0; i < rows.length; i++) {
    var cols = rows[i].querySelectorAll(
      'td:not([data-no-download]),th:not([data-no-download])',
    );
    var csvrow = [];

    for (var j = 0; j < cols.length; j++) {
      csvrow.push(cols[j].textContent);
    }
    csvData.push(csvrow.join(','));
  }
  csvData = csvData.join('\n');
  const blob = new Blob([csvData], { type: `${fileName}.csv` });
  saveAs(blob, `${fileName}.csv`);
};

export const createTimeRange = (
  from,
  to,
  separator = ':',
  intervals = ['00'],
) => {
  const timeRange = [];
  const seconds = '00';

  for (let i = from; i <= to; i++) {
    for (let j = 0; j < intervals.length; j++) {
      let time = `${i}${separator}${intervals[j]}${separator}${seconds}`;
      if (i < 10) {
        time = `0${time}`;
      }
      timeRange.push(time);
    }
  }
  timeRange.pop();
  return timeRange;
};

export const convertImageToBase64 = (imgUrl, callback) => {
  const image = new Image();
  image.crossOrigin = 'anonymous';
  image.onload = () => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    canvas.height = image.naturalHeight;
    canvas.width = image.naturalWidth;
    ctx.drawImage(image, 0, 0);
    const dataUrl = canvas.toDataURL();
    callback && callback(dataUrl);
  };
  image.src = imgUrl;
};
// Method to filter items based on the requirements and return array of keys
export const getUnVerifiedKeys = (items) => {
  const filteredKeys = [];
  let foundUnVerified = false;

  for (let i = 0; i < items.length; i++) {
    if (!foundUnVerified && items[i].isVerified === 1) {
      continue;
    } else {
      foundUnVerified = true;
      filteredKeys.push(items[i].key);
    }
  }

  // If no item has isVerified = 1, return an empty array
  if (!foundUnVerified) {
    return [];
  }

  return filteredKeys;
};
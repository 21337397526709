import React, { useState, useEffect } from 'react';
import styles from './styles.module.css';
import cross from '../../components/images/cross_checkbox.svg';
import right from '../../components/images/right_checkbox_green.svg';
import { ToastContainer } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { GS_UNFLAGGING_ACTION, GS_UNFLAG_BUTTON_ACTION, LOCAL_STORAGE_KEYS, MONTHS_NAME, NUMBERS } from '../../utils/app-constants';
import { fetchGsUnflaggingDetails, gsUnflaggingAction } from './actions';
import Header from "../../components/header";
import List from '../../components/shared/list/List';
import Loader from '../../components/loader/component';
import H2 from '../../components/h2/component';
import SubHeader from '../../components/shared/sub-header/SubHeader';
import Carousel from '../../components/shared/carousel/Carousel';
import Image from '../../components/shared/image/Image';
import AllOtherParts from './AllOtherParts';
import CollapseList from '../../components/shared/collapse-list/CollapseList';
import { getFromLocalStorage, isAssignedToMe, saveToLocalStorage, showToastMessages } from '../../utils/helper';
import ButtonContainer from './ButtonContainer';

const transformBasicInfoImages = data => {
    let result = [];
    Object.keys(data).forEach(item => {
        result.push(...data[item]);
    });
    return result;
};

const transformComments = (data) => {
    let result = [];
    data.forEach(item => {
        const listItems = item.comments.map(commentObj => commentObj.comment);
        let resultItem = { title: item.title, items: listItems };
        result.push(resultItem);
    });
    return result;
};

const transformAdditionalImages = (data) => {
    let result = [];
    Object.keys(data).forEach(item => {

        if (data[item]?.subParts) {
            Object.keys(data[item].subParts).forEach(subPart => {
                let { image: subPartImages, condition } = data[item].subParts[subPart]?.additionalInfo || {};
                let title = `${data[item].subParts[subPart].title} ${data[item].title} (${condition})`;
                let resultItem = subPartImages?.map(img => ({ title, url: img.url })) || [];
                result.push(...resultItem);
            });
        }

        if (data[item]?.additionalInfo?.image) {
            let resultItem = data[item]?.additionalInfo?.image?.map(img => ({
                title: `${data[item].title} (${data[item].additionalInfo?.condition})`, url: img.url
            }));
            result.push(...resultItem);
        }
    });
    return result;
};

const transformCollapseData = (important, lessImportant) => {
    const getResultItem = (obj, title, isOpen = false) => {
        let items = [];
        Object.keys(obj).forEach(item => items.push(obj[item]));
        return { title, isOpen, items };
    };

    return [
        getResultItem(important, "Show Important", true),
        getResultItem(lessImportant, "Show Less Important")
    ];
};

const transformAllOtherParts = data => {
    let result = [];
    let resultItem = {};

    Object.keys(data).forEach((dataKey, i) => {
        if (data[dataKey].subParts) {
            Object.keys(data[dataKey].subParts).forEach(subpart => {
                resultItem = {
                    col1: data[dataKey].title,
                    col2: data[dataKey].subParts[subpart].title,
                    col3: data[dataKey].subParts[subpart].value.match(/yes/i) ? <img src={right} /> : <img src={cross} />,
                    col4: data[dataKey].subParts[subpart]?.additionalInfo?.condition,
                };
                result.push(resultItem);
            });
        } else {
            resultItem = {
                col1: data[dataKey].title,
                col2: "-",
                col3: data[dataKey].value.match(/yes/i) ? <img src={right} /> : <img src={cross} />,
                col4: data[dataKey]?.additionalInfo?.condition
            };
            result.push(resultItem);
        }
    });
    return result;
};

const transformGsNoGoImages = data => {
    let result = [];
    let resultItem = [];
    Object.keys(data).forEach(part => {
        let { subParts, title, additionalInfo } = data[part];

        if (!subParts) {
            let images = additionalInfo?.image?.map(img => ({
                ...img,
                title: additionalInfo?.condition ? title + " (" + additionalInfo.condition + ")" : title,
                type: "image"
            })) || [];

            let videos = additionalInfo?.video?.map(vid => ({
                ...vid,
                title: additionalInfo?.condition ? title + " (" + additionalInfo.condition + ")" : title,
                type: "video"
            })) || [];

            let media = [...images, ...videos];

            resultItem = {
                title,
                items: [media]
            };
            result.push(resultItem);
        } else {
            let finalImages = [];
            Object.keys(subParts).forEach(subpart => {
                let _images = subParts[subpart].additionalInfo?.image?.map(img => ({
                    ...img,
                    title: subParts[subpart].additionalInfo?.condition ? subParts[subpart].title + " " + title + " (" + subParts[subpart].additionalInfo.condition + ")" : subParts[subpart].title + " " + title,
                    type: "image"
                })) || [];

                let _videos = additionalInfo?.video?.map(vid => ({
                    ...vid,
                    title: additionalInfo?.condition ? title + " (" + additionalInfo.condition + ")" : title,
                    type: "video"
                })) || [];

                let _media = [..._images, ..._videos];
                finalImages = [...finalImages, _media];
            });
            resultItem = {
                title,
                items: finalImages
            };
            result.push(resultItem);
        }
    });
    return result;
};

const GsUnflaggingDetails = ({ history }) => {
    const dispatch = useDispatch();
    const { isLoading, details} = useSelector(state => state.gsUnflaggingReducer);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [btnAction, setBtnAction] = useState("");
    const [reason, setReason] = useState("");
    const [disposition, setDisposition] = useState("");
    const [subDispositions,setSubDispositions]=useState("");
    const [dispostionOptions,setDispostionOptions]=useState([]);
    const [errors, setErrors] = useState({});
    const { header, comments, important, showLessImportant, image } = details.basicInfo || {};
    const mainImages = details.mainImages || [];
    const impMainImages = details.impMainImages || [];
    const gsNoGoImages = details.gsNoGoImages || {};
    const additionalImages = details.additionalImages || {};
    const allOtherParts = details.allOtherParts || {};
    const {otherInfo: {betaReason, hardBeta=0}={}} = details;
    let appointmentId = Number(history.location.pathname.split("/").pop());
    if (isNaN(appointmentId)) {
        console.log("WRONG URL");
    }

    useEffect(() => {
        let { imageHostUrl, videoHostUrl, imageResizingQueryString } = JSON.parse(getFromLocalStorage(LOCAL_STORAGE_KEYS.LOGIN_DATA) || '{}');
        saveToLocalStorage(LOCAL_STORAGE_KEYS.IMAGE_HOST_URL, imageHostUrl);
        saveToLocalStorage(LOCAL_STORAGE_KEYS.VIDEO_HOST_URL, videoHostUrl);
        saveToLocalStorage(LOCAL_STORAGE_KEYS.IMAGE_QUALITY_PARAM, imageResizingQueryString);
    }, []);

    useEffect(() => {
        dispatch(fetchGsUnflaggingDetails(appointmentId));
    }, [appointmentId]);

    const handlePopupClose = () => {
        setIsModalOpen(false);
        setErrors({});
        setReason("");
        setDisposition("");
        setSubDispositions([]);
    };

    const handleBtnClick = status => {
        setBtnAction(status);
        setIsModalOpen(true);
    };

    const getConditionFlag = (action) => {
        if(action === GS_UNFLAG_BUTTON_ACTION.UNFLAG) {
            return -1;
        } else if(action === GS_UNFLAG_BUTTON_ACTION.CHANGE) {
            return details.conditionFlag == 0 ? 4: 0;
        } else {
            return details.conditionFlag;
        }
    }

    const handleActionSubmit = () => {
        let errors = {};
        let subDistopsitionarr=[];
        if(subDispositions && subDispositions.length>0){
            subDistopsitionarr=subDispositions?.map((item)=>item.value);
        }
        

        if(btnAction !== GS_UNFLAG_BUTTON_ACTION.MARK) {
            if(!reason.length) {
                errors["reason"] = "Reason is required";
            }
            if(!disposition.length) {
                errors["disposition"] = "Disposition is required";
            }
        }
        if(Object.keys(errors).length) {
            setErrors(errors);
            return;
        }   
        let data = {
            reason,
            disposition,
            conditionFlag: getConditionFlag(btnAction),
            status: btnAction !== GS_UNFLAG_BUTTON_ACTION.UNFLAG ? GS_UNFLAGGING_ACTION.APPROVED : GS_UNFLAGGING_ACTION.REJECTED,
            updatedBy: getFromLocalStorage(LOCAL_STORAGE_KEYS.EMAIL),
            subDisposition: details.conditionFlag!==NUMBERS.ZERO? subDistopsitionarr :undefined
        };
        const redirectOnSuccess = (status, message) => {
            if(status === "success") {
                showToastMessages(message, true);
                setTimeout(() => {
                    history.push("/gs-unflagging");
                }, 2000);

            } else {
                showToastMessages(message, false);
            }
        };
        dispatch(gsUnflaggingAction(appointmentId, data, redirectOnSuccess));
    };

    return (
        <div>
            {isLoading && <Loader />}
            <ToastContainer />

            <Header
                history={history}
                showVersion
                showDropdown={false}
                showUsername={false}
                showPerformance={false}
                headerText={`${appointmentId} | New inspection`}
                showInspectionPdf={!!header?.pdfUrl?.length}
                inspectionPdfUrl={header?.pdfUrl}
                showLogout={false}
            />

            {header && (
                <SubHeader>
                    <div className={styles.subheaderText}>
                        <span>{header?.make}</span>
                        <span className={styles.textBold}>{header?.model}</span>
                        <span>{header?.year}</span>
                        <span>{MONTHS_NAME[header?.month]}</span>
                        <span className={styles.textBold}>{header?.variant}</span>
                        <span className={styles.textBold}>{header?.fuelType}</span>
                        <span>Scrap({header?.toBeScrapped})</span>
                    </div>
                    <div className="ml-auto">
                        <span className={styles.textBold}>
                            Inspected by {header?.inspectionBy}
                            {header?.cjNumber && ` (${header?.cjNumber})`}
                        </span>
                    </div>
                </SubHeader>
            )}

            <div className="pageWrap">
                <div className={styles.imageAndCollapseSection}>

                    {image && (
                        <div className={styles.slider}>
                            <Carousel
                                data={transformBasicInfoImages(image)}
                                imgHeight="500px"
                                infinite={true}
                                slidesToShow={1}
                            />
                        </div>
                    )}

                    {important && (
                        <div className={styles.collapse}>
                            <CollapseList data={transformCollapseData(important, showLessImportant)} />
                        </div>
                    )}

                </div>
            </div>

                {!isLoading && <div className="pageWrap">
                    <H2
                        text="Flagging Details"
                        className="my-lg"
                    />
                    <div className={styles.flagTypeContainer}>
                        <span className={styles.flagType}>Flag Type</span> - {!!hardBeta ? "Hard Beta" : !!details?.conditionFlag ? "Beta" : "Alpha"}</div>
                        {betaReason && betaReason?.length>0 && <List data={[{title:"Flagging Dispositions",items:betaReason}]}
                    />}
                </div>}

            {comments && (
                <div className="pageWrap">
                    <H2
                        text="Comments"
                        className="my-lg"
                    />
                    <List
                        data={transformComments(comments)}
                    />
                </div>
            )}

            {!!mainImages.length && (
                <div className="pageWrap">
                    <H2
                        text="Main Images"
                        className="my-lg"
                    />
                    <Carousel
                        data={mainImages}
                        slidesToShow={6}
                    />
                </div>
            )}

            {!!impMainImages.length && (
                <div className="pageWrap my-lg">
                    <H2
                        text="Important Images"
                        className="my-lg"
                    />
                    <div className={styles.imgMainImages}>
                        {impMainImages.map(imgObj =>
                            <Image
                                title={imgObj.title}
                                url={imgObj.url}
                            />
                        )}
                    </div>
                </div>
            )}

            {!!Object.keys(gsNoGoImages).length && (
                <div className="pageWrap my-lg">
                    {transformGsNoGoImages(gsNoGoImages).map((imgObj, i) => (
                        <>
                            <H2
                                text={imgObj.title}
                                className="my-lg"
                            />
                            <div className={styles.gsNoGoImages}>
                                {imgObj.items.map(item => (
                                    <>
                                        {item.map(img => (
                                            <Image
                                                type={img.type}
                                                title={img.title}
                                                url={img.url}
                                            />
                                        ))}
                                    </>
                                ))}
                            </div>
                        </>
                    ))}
                </div>
            )}

            {!!Object.keys(additionalImages).length && (
                <div className="pageWrap">
                    <H2
                        text="All Other Photos"
                        className="my-lg"
                    />
                    <Carousel
                        data={transformAdditionalImages(additionalImages)}
                        slidesToShow={4}
                    />
                </div>
            )}

            {!!Object.keys(allOtherParts).length && (
                <div className="pageWrap">
                    <H2
                        text="All Other Parts without Photos"
                        className="my-lg"
                    />
                    <AllOtherParts data={transformAllOtherParts(allOtherParts)} />
                </div>
            )}

            {!!Object.keys(details).length && isAssignedToMe(details?.assignedTo) && (
                <ButtonContainer
                    conditionFlag={details?.conditionFlag}
                    reason={reason}
                    btnAction={btnAction}
                    isModalOpen={isModalOpen}
                    setReason={setReason}
                    setDisposition={setDisposition}
                    setSubDispositions={setSubDispositions}
                    errors={errors}
                    handleBtnClick={handleBtnClick}
                    handlePopupClose={handlePopupClose}
                    setIsModalOpen={setIsModalOpen}
                    handleActionSubmit={handleActionSubmit}
                    disposition={disposition}
                    setDispostionOptions={setDispostionOptions}
                    dispostionOptions={dispostionOptions}
                    subDispositions={subDispositions}
                    hardBeta={hardBeta}
                />
            )}
        </div>
    );
};

export default GsUnflaggingDetails;
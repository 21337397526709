import React, { useState, useEffect } from "react";
import styles from "./add-parameter-popup.module.css";
import InputText from "../../components/shared/input-text";
import RadioButton from "../../components/shared/radio-button";
import CameraIcon from "../../components/images/cameraUpload.jpg";
import Button from "../shared/button/component";
import { InspectionService } from '../../service';
import Loader from '../loader';
import { getFromLocalStorage } from '../../utils/helper';
import { thumborUrl } from '../../utils/url-constants';
import { LOCAL_STORAGE_KEYS } from '../../utils/app-constants';

const AddParameterPopup = ({
    fetchCarVariantParameterDataList, 
    editParameter, 
    isEditingParameter,
    onAddParameterClose
}) => {
    
    const intialImageOptions = [{
        name: '',
        url: '',
    }];
    const { type, name, value, image, _id} = editParameter;
    const initialDropdownOptions = [''];
    const initialOptionListData = image || value || initialDropdownOptions;
    const [imageHostUrl, setImageHostUrl] = useState('');
    const [isSubmitButtonClicked, setIsSubmitButtonClicked] = useState(false);
    const [responsedata, setResponsedata] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [serverError, setServerError] = useState('');
    const [parameterType, setParameterType] = useState(type || 'dropDown');
    const [optionsList, setOtionsList] = useState(JSON.parse(JSON.stringify(initialOptionListData)));
    const [parameterName, setParameterName] = useState(name || '');
    const [currentImageIndex, setCurrentImageIndex] = useState('');
    const inputTypeList = [
        {valyeType: 'dropDown', label: 'Dropdown'},
        {valyeType: 'yes/no', label: 'Yes/No'},
        {valyeType: 'image', label: 'Image'}
    ];
    
    useEffect(() => {
        const loginData = JSON.parse(getFromLocalStorage(LOCAL_STORAGE_KEYS.LOGIN_DATA)) || {};
        const baseUrl = loginData.imageHostUrl ? loginData.imageHostUrl : thumborUrl();
        setImageHostUrl(baseUrl);
    },[]);

    const onChangeHandlerType = (value) => {
        setServerError('');
        setIsSubmitButtonClicked(false);
        if(value === "dropDown") {
            setOtionsList(initialDropdownOptions);
        } else if(value === "image") {
            setOtionsList(intialImageOptions);
        } else {
            setOtionsList([]);
        }
        setParameterType(value);
    };

    const updateOptionHandler = (event, index) => {
        setServerError('');
        const value = event.target.value;
        const optionIitem = [...optionsList];
        if(parameterType === "dropDown") {
            optionIitem[index] = value;
        } else if(parameterType === "image") {
            optionIitem[index].name = value;
        }
        setOtionsList(optionIitem);
    };

    const addOptionHandler = () => {
        setServerError('');
        const optionIitem = [...optionsList];
        if(parameterType === "dropDown") {
            optionIitem.push("");
        } else if(parameterType === "image") {
            optionIitem.push({name: '', url: ''});
        }
        setOtionsList(optionIitem);
    };

    const onChangeHandlerParameterName = event => {
        setServerError('');
        const value = event.target.value;
        setParameterName(value);
    };

    const removeHandler = index => {
        setServerError('');
        const optionIitem = [...optionsList];
        optionIitem.splice(index, 1);
        setOtionsList(optionIitem);
    };

    const onSubmitHandler = () => {
        setServerError('');
        const variantParameter = {
            name: parameterName,
            type: parameterType,
            value: '',
            image: '',
            status: "Active"
        };
       
        setIsSubmitButtonClicked(true);
        let errorMessagesList = [];
        if(parameterType === "dropDown") {
            variantParameter.value = optionsList;
            delete variantParameter.image;
        } else if(parameterType === "image") {
            variantParameter.image = optionsList;
        } else {
            delete variantParameter.image;
        }
        if( parameterType === "dropDown") {
            if(variantParameter.value.length) {
                errorMessagesList =  optionsList.filter(item => !item);
            }
        }

        if(parameterType === "image") {
            if(variantParameter.image.length) {
                errorMessagesList =  optionsList.filter(imageItem => !imageItem.name || !imageItem.url);
            }
        }
        
        if(!variantParameter.name || errorMessagesList.length) {
            setServerError('');
        } else {
            
            if(isEditingParameter) {
                const newParameter = JSON.stringify(variantParameter);
                const editParameterData = {...editParameter};
                delete editParameterData._id;
                const oldParameter = JSON.stringify(editParameterData);
               
                if(newParameter === oldParameter) {
                    alert('Change at least one value');
                    return;
                }
                setIsLoading(true);
                InspectionService.updateCarVariantParameter(_id, variantParameter).then(response => {
                    setIsLoading(false);
                    const { data: { detail } } = response;
                    setResponsedata(detail);
                    fetchCarVariantParameterDataList();
                    
                }).catch(error => {
                    console.log('error::',error);
                    const {detail, title} = error;
                    setServerError(title + ': ' + detail);
                    setIsLoading(false);
                });
                return;
            } 
            setIsLoading(true);
            InspectionService.submitCarVariantParameter(variantParameter).then(response => {
                setIsLoading(false);
                const { data: { detail } } = response;
                setResponsedata(detail);
                fetchCarVariantParameterDataList();
            }).catch(error => {
                console.log('error::',error);
                const {detail, title} = error;
                setServerError(title + ': ' + detail);
                setIsLoading(false);
            });
        }
    };

    const uploadImageHandler = (event, imageName) => {
        if(event.target.files.length) {
            if(event.target.files[0].type !== "image/webp") {
                alert('Only webp type image will be uploaded');
                return;
            }
            setIsLoading(true);
            setServerError('');
            // const imageName = event.target.files[0].name.replace(/\.[^/.]+$/, "");
            const formData = new FormData();
            formData.append('imageName', imageName);
            formData.append('imageFile', event.target.files[0]);
            InspectionService.uploadCarVariantParameterImage(formData).then(response => {
                setIsLoading(false);
                const {data: {detail: {url}}} = response;
                const optionIitem = [...optionsList];
                optionIitem[currentImageIndex].url = url;
                setOtionsList(optionIitem);
            }).catch(error => {
                setIsLoading(false);
                console.log('error::',error);
            });
        }
    };

    const resetDataHandler = () => {
        setResponsedata('');
        setOtionsList(initialDropdownOptions);
        setParameterType('dropDown');
        setParameterName('');
        setIsSubmitButtonClicked(false);
        setServerError('');
    };

    const getErrorMessage = imageObject => {
        const { name, url } = imageObject;
        if(!name && !url) {
            return 'Image and Image name are required';
        }
        if(!name) {
            return 'Image name is required';
        } 
        if(!url) {
            return 'Image is required';
        }
    };

    if(responsedata) {
        return (
            <div>
                <h2 style={{color: '#006400'}}>{responsedata}</h2>
                <Button 
                    ctaText={isEditingParameter ? 'Close' : "Add an other parameter"} 
                    classNames="greenCtaButton"  
                    onClick={isEditingParameter ? onAddParameterClose : resetDataHandler}
                />
            </div>
        );
    }
    return (
        <div className={styles.addParameterPopup}>
            {isLoading && <Loader />}
            <p className={styles.heading}>{isEditingParameter ? 'Update' : 'Add'} a Parameter:</p>
            <div className={styles.parameterList}>
                <ul>
                    <li>
                        <span className={styles.labelText}>Parameter Name</span>
                        <div>
                            <InputText 
                                onChange={onChangeHandlerParameterName} 
                                value={parameterName} 
                            />
                            {isSubmitButtonClicked && !parameterName && <span className={styles.errorMessage}>Parameter Name is required</span>}
                        </div>
                    </li>
                    
                    <li>
                        <span className={styles.labelText}>Type of Input</span>
                        <div className={styles.typeList}>
                            {
                                inputTypeList.map((type, index) => (
                                    <div key={index} className={styles.typeListOption}>
                                        <RadioButton 
                                            text={type.label} 
                                            id={type.valyeType}
                                            name={type.valyeType}
                                            value={type.valyeType}
                                            onClickCallback={onChangeHandlerType}
                                            checkedStatus={parameterType === type.valyeType}
                                        />
                                    </div>
                                ))
                            }                            
                        </div>
                    </li> 
                    { parameterType !== "yes/no" && <li>
                        <span className={styles.labelText}>Dropdown Options</span>
                        <div className={styles.uploadList}>
                            {
                                optionsList.length && optionsList.map((optionIitem, index) => (
                                    <div className={styles.optionIitem}>
                                        <InputText 
                                            onChange={(event) => updateOptionHandler(event, index)}
                                            value={ parameterType === "dropDown" ? optionIitem : optionIitem.name}
                                        />
                                        {parameterType !== "image" && isSubmitButtonClicked && !optionIitem && <span className={styles.errorMessage}>Option is required</span>}
                                        {parameterType === "image" && optionIitem.name && 
                                            <React.Fragment>
                                                <input onChange={(event) => uploadImageHandler(event, optionIitem.name)} type="file" id="img" name="img" accept="image/*" />
                                                <label for="img">
                                                    <img 
                                                        src={ optionIitem.url ? imageHostUrl + optionIitem.url : CameraIcon} 
                                                        onClick={() => setCurrentImageIndex(index)}  
                                                        alt="camera" 
                                                        width="34px" 
                                                        height="34px" 
                                                    />
                                                </label>
                                                
                                            </React.Fragment>
                                        }
                                        {parameterType === "image" && isSubmitButtonClicked && <span className={styles.errorMessage}>{getErrorMessage(optionIitem)}</span>}
                                        {index !== 0 && <button 
                                            className={styles.removeButton} 
                                            onClick={() => removeHandler(index)}
                                        >
                                            X
                                        </button>}
                                        
                                    </div>
                                ))
                            }
                            <button onClick={addOptionHandler} className={styles.addOption}>+ Add Option</button>
                        </div>
                        
                    </li>}
                </ul>
            </div>
            <div className={styles.errorMessage}>
                {serverError && <span>Error:[{serverError}]</span>}
            </div>
            <div className={styles.greenCtaButton} >
                <Button 
                    ctaText={isEditingParameter ? 'UPDATE' : 'SUBMIT'} 
                    classNames="greenCtaButton" 
                    onClick={onSubmitHandler}
                    disabled={isLoading}
                />
            </div>
        </div>
    );
};

export default AddParameterPopup;

import React, { useEffect, useState } from "react";
import SelectComponent from "../../components/shared/select";
import { monthNames, REGISTRATION_YEAR } from "../../utils/app-constants";
import { RESET_REDUCER_KEYS } from "../../utils/app-constants";
import { InspectionService } from "../../service";

const ManufacturingDate = ({ detail, resetInspectionDetailByKeyConnect, inspectionDetails, type, fetchInspectionDetailConnect }) => {
    const [selectedYear, setSelectedYear] = useState("");
    const [selectedMonth, setSelectedMonth] = useState("");
    const { documentsMapper, makeModelVariantMapper, carId } = inspectionDetails;
    const [yearDropDown, setYearDropDown] = useState([]);

    const [modelId] = useState(makeModelVariantMapper[1].newId ? makeModelVariantMapper[1].newId : makeModelVariantMapper[1].id);

    const { key } = detail;

    useEffect(() => {
        return () => {
            // resetToInitialValue();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (type === "year" && selectedYear || type === "month" && selectedMonth)
            setNewValue();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedYear, selectedMonth]);

    useEffect(() => {
        if (modelId) {
            if (key === "year") {
                InspectionService.getVariantYear(modelId)
                    .then(res => {
                        let { data: { detail } } = res;
                        // if (!!detail && detail.length > 0) {
                        //     detail = detail.filter(x => x.variant_status === "Active" && x.variantyear_status === "Active");
                        // }
                        setYearDropDown(Object.keys(detail).map(year => {
                            return {
                                label: year,
                                value: year
                            };
                        }));
                    })
                    .catch(err => {

                    });
            }
            else if (key === "registrationYear") {
                setYearDropDown(REGISTRATION_YEAR());
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modelId]);

    const resetToInitialValue = () => {
        let mapper = [...documentsMapper];
        let newValue = "";
        let newId = "";
        mapper = mapper.map(map => {
            let element = { ...map };
            if (map.key === key) {
                element.newValue = newValue;
                element.newId = newId;
                element.checked = false;
            }

            return element;
        });
        resetInspectionDetailByKeyConnect(RESET_REDUCER_KEYS.DOCUMENTS_MAPPER, mapper);
    };

    const setNewValue = () => {
        let mapper = [...documentsMapper];
        let dependentFields = [];
        let newValue = "";
        let newId = "";
        if (type === "month") {
            newValue = `${monthNames[selectedMonth - 1]}`;
            newId = `${selectedMonth}`;
        }
        else if (type === "year") {
            newValue = `${selectedYear}`;
            newId = `${selectedYear}`;
        }

        mapper = mapper.map(map => {
            let element = { ...map };
            if (map.key === key) {
                // if(map.value !== newValue){
                element.newValue = newValue;
                element.newId = newId;
                if (element.dependentFields && element.dependentFields.length > 0) {
                    dependentFields = element.dependentFields;
                }
                // }
            }

            return element;
        });
        mapper = mapper.map(map => {
            let element = { ...map };
            if (dependentFields.find(field => field === element.key)) {
                element.checked = true;
            }

            return element;
        });

        resetInspectionDetailByKeyConnect(RESET_REDUCER_KEYS.DOCUMENTS_MAPPER, mapper);
    };

    const monthDropDown = monthNames.map((month, index) => {
        return {
            label: month,
            value: index + 1
        };
    });

    const onYearChange = (option) => {
        setSelectedYear(option);
        // resetInspectionDetailByKeyConnect(RESET_REDUCER_KEYS.DOCUMENTS_MAPPER, null);
    };

    const onMonthChange = (option) => {
        setSelectedMonth(option);
    };

    return (
        <div>
            <div className="mb-5">
                {
                    type === "month" && <SelectComponent
                        optionsList={monthDropDown}
                        onChange={onMonthChange}
                        placeholder={"Month"}
                    />
                }
            </div>
            {
                type === "year" && <SelectComponent
                    optionsList={yearDropDown}
                    onChange={onYearChange}
                    placeholder={"Year"}
                />
            }
        </div>
    );
};

export default ManufacturingDate;

import React, {useState} from "react";
import styles from "./car-variant-widget-panel.module.css";
import { useDrag } from 'react-dnd';
import { convertKeyIntoString } from '../../utils/helper';
import Popup from "../../components/popup";
import AddParameterPopup from "../../components/add-parameter-popup";

const CarVariantWidgetPanel = ({carVariantReducer}) => {
    const {carVariantParameterList} = carVariantReducer;
    const [editParameter, setEditParameter] = useState({});
    const [addParameterOpen, setAddParameterOpen] = useState(false);
    const onAddParameterOpen = (event, parameter) => {
        setEditParameter(parameter || {});
        setAddParameterOpen(true);
    };
    const onAddParameterClose = () => {
        setAddParameterOpen(false);
    };
    return (
        <div className={styles.carVariantWidgetPanel}>
            <div className={styles.addParameter}>
                <button onClick={onAddParameterOpen}>Add Parameter..</button>
                <Popup isOpen={addParameterOpen} close={onAddParameterClose}>
                    <AddParameterPopup 
                        onClose={onAddParameterClose}
                        editParameter={editParameter}
                        isEditingParameter={!!editParameter.name}
                        onAddParameterClose={onAddParameterClose}
                    />
                </Popup>
            </div>
            <div className={styles.widgetWrapper}>
                <ul>
                    {
                        !!carVariantParameterList.length && carVariantParameterList.map((parameter, index) => (
                            parameter.name && <ParamaterWidget 
                                parameter={parameter}
                                type="parameter"
                                key={index}
                                addParameterOpenHandler={onAddParameterOpen}
                            />
                        ))
                    }
                </ul>
            </div>
        </div>
    );
};

export const ParamaterWidget = ({parameter, type, addParameterOpenHandler}) => {
    const {name} = parameter;
    const [{ opacity }, drag] = useDrag({
        item: { parameter, type },
        collect: (monitor) => ({
            opacity: monitor.isDragging() ? 0.4 : 1,
        }),
    });
    
    return (
        <li ref={drag}>
            <div className={styles.nameWrapper}>
                <span>{convertKeyIntoString(name, ' ')}</span>
                <button onClick={(e) => addParameterOpenHandler(e, parameter)}>Edit</button>
            </div>
        </li>
    );
};

export default CarVariantWidgetPanel;
import React from "react";
import Infoicon from "../../images/infoIcon.svg";

const InfoIcon = ({ className }) => {
    return (
        <img src={Infoicon} alt="info" className={className} />
    );
};

export default InfoIcon;

import moment from 'moment';

export const LOCAL_STORAGE_KEYS = {
  LOGIN_DATA: 'loginData',
  EMAIL: 'email',
  NAME: 'name',
  EXCLUDED_USER: 'excludedUser',
  SLEEP_TIME: 'sleepTime',
  CATEGORY: 'category',
  ASSIGNEE: 'assignee',
  ROLE: 'role',
  IS_EXCLUDED_USER: 'isExcludedUser',
  QC_USER: 'qc',
  ACCESS_TOKEN: 'access_token',
  IMAGE_HOST_URL: 'image_host_url',
  IMAGE_QUALITY_PARAM: 'image_quality_param',
  VIDEO_HOST_URL: 'video_host_url',
  SELECTED_DROPDOWN_ITEM: 'selected_dropdown_item',
  DISPOSITIONS_DROPDOWN_ITEM: 'alpha_dispostions',
  ACCESS_TOKEN: 'access_token',
};

export const TIME_DIFF_IN_MINUTES = 60000;

export const ERROR_MESSAGE = {
  ALREADY_CHECKED_IN: 'Already checked-in',
  DISPOSITION: 'Please select call result',
  SUB_DISPOSITION: 'Please select time',
  PRIMARY_CANCELLATION_REASON: 'Please select cancellation reason',
  SECONDARY_CANCELLATION_REASON: 'Please select cancellation reason',
  EMAIL: 'Please enter valid email address',
  PASSWORD: 'Password is required',
  NO_RESULTS_HEADING: 'No results found',
  NO_RESULTS_CONTENT:
    'Try adjusting your search to find what you are looking for',
  SOMETHING_WENT_WRONG_HEADING: 'Oops!',
  SOMETHING_WENT_WRONG_CONTENT: 'Something went wrong, please try again',
  CUSTOMER_EXPECTED_PRICE: 'Please enter price',
  OTP: 'Please enter valid Otp',
  OTHER_NUMBER: 'Please enter valid number',
  ADDRESS: 'Please enter valid address',
  CHECK_IN_LEAD_SEARCH: 'Please enter 10 digit number for search',
  REGISTRATION_REQUIRED: 'Registration no is required',
  ROLE: 'role',
};
export const RESET_REDUCER_STATE_KEYS = {
  DATA: 'data',
  QUERY: 'query',
};

export const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const RESET_REDUCER_KEYS = {
  DOCUMENTS_MAPPER: 'documentsMapper',
  INSURANCE_MAPPER: 'insuranceMapper',
  SHOW_MISMATCH: 'showMismatch',
  MISMATCH_MAPPER: 'mismatchMapper',
  OTHER_DETAILS_MAPPER: 'otherDetailsMapper',
  ODOMETER_MAPPER: 'odometerMapper',
  DUPLICATE_MAPPER: 'duplicateKeyMapper',
  COMMENTS_MAPPER: 'commentsMapper',
  MAPPER_COUNTS: 'mapperCounts',
  BIKE_DOCUMENTS_MAPPER: 'bikeDocumentsMapper',
  RC_IMAGES_MAPPER: 'rcImagesMapper',
  INSURANCE_IMAGE_MAPPER: 'insuranceImageMapper',
  EXTERIOR_TYRES_MAPPER: 'exteriorTyresMapper',
};

export const BIKE_DETAIL_KEYS = {
  Inspection_Type: 'Inspection Type',
  Customer_Mobile: 'Customer Mobile',
  Registration_Number: 'Registration Number',
  Customer_Name_on_RC: 'Customer Name on RC',
  Customer_Name: 'Customer Name',
  Dealer_Name: 'Dealer Name',
  Same_Color_Bike: 'Same Color Bike',
  Manufacturing_Date: 'Manufacturing Date',
  Registration_Date: 'Registration Date',
  Ownership_Number: 'Ownership Number',
  Bike_Ownership: 'Bike Ownership',
  Hypothecation: 'Hypothecation',
  Financier_Name: 'financier Name',
  Is_Scrap: 'Is Scrap?',
  RC_Availability: 'RC Availability',
  RC_Condition: 'RC Condition',
  Insurance_Availability: 'Insurance availability',
  Registration_State: 'Registration State',
  Registration_State_Id: 'stateId',
  RTO_Code: 'RTO Code',
  Chassis_Number_on_RC: 'Chassis Number on RC',
  Chassis_Number: 'Chassis Number',
  Chassis_Number_Embossing: 'Chassis Number Embossing',
  Duplicate_Key: 'Duplicate Key',
  Odometer_reading: 'Odometer Reading',
  Kilometres_driven: 'Kilometres driven',
  Insurance_Type: 'Insurance Type',
  Insurance_Expiry: 'Insurance Expiry',
  Loan_Paid: 'Loan Paid',
  Bank_NOC: 'Bank NOC',
  Fuel_Type: 'Fuel Type',
  Exhaust_Smoke_Colour: 'Exhaust Smoke Colour',
  make: 'Make',
  model: 'Model',
  variant: 'Variant',
  Inspection_Store: 'Inspection Store',
  RA_Name: 'RA Name',
  Dealership_Name: 'Dealership Name (only for B2B)',
  Are_there_changes_during_ReInspection:
    'Are there changes during Re-Inspection?',
  REGISTRATION_CITY_RTO_CODE: 'Registration city/RTO code',
  REGISTRATION_YEAR: 'Model Year (Registration Year)',
  Clutch: 'Clutch',
  Chain_Condition: 'Chain condition',
  Ignition_Switch: 'Ignition Switch',
  Indicator_Switch: 'Indicator Switch',
  Self_Switch: 'Self Switch',
  Headlight_Switch: 'Headlight Switch',
  Carburetor: 'Carburetor',
  Accelerator_Cable: 'Accelerator Cable',
  Meter_Cable: 'Meter Cable',
  Dipper_Switch: 'Dipper Switch',
  Brake_Cable: 'Brake Cable',
  Choke_Cable: 'Choke cable',
  Horn: 'Horn',
  Choke: 'Choke',
  Clutch_Cable: 'Clutch Cable',
  Chassis_Number: 'Chassis Number',
  Abnormal_Engine_Sound: 'Abnormal Engine Sound',
  Engine_Internal: 'Engine Internal',
  Engine_Outer: 'Engine Outer',
  Chassis_Condition: 'Chassis condition',
  Front_Tyre: 'Front Tyre',
  Rear_Tyre: 'Rear Tyre',
  Front_Mudguard: 'Front Mudguard',
  Headlight_Visor: 'Headlight Visor',
  Seat_Condition: 'Seat condition',
  Front_Wheel: 'Front Wheel',
  Rear_Wheel: 'Rear Wheel',
  Rear_Mudguard: 'Rear Mudguard',
  Saree_Guard: 'Saree guard',
  Meter_Display_Condition: 'Meter Display Condition',
  Main_Stand: 'Main Stand',
  Headlight_Windshield: 'Headlight Windshield',
  Steering_Bar_Handle: 'Steering bar handle',
  Tail_Panel: 'Tail Panel',
  Gear_Pedal: 'Gear Pedal',
  Brakes_Rear: 'Brakes - Rear',
  Front_Side_Panel_RHS: 'Front Side Panel RHS',
  Front_Side_Panel_LHS: 'Front Side Panel LHS',
  Brakes_Front: 'Brakes - Front',
  Rear_Grip_Handle: 'Rear Grip Handle',
  Side_Stand: 'Side Stand',
  Shock_Abosrber_Rear_LHS: 'Shock Abosrber - rear LHS',
  Foot_Rest_Rear_LHS: 'Foot Rest rear LHS',
  Shock_Abosrber_Front_RHS: 'Shock Abosrber - Front RHS',
  Brakes_Lever_Front_RHS: 'Brakes Lever - Front RHS',
  Shock_Abosrber_Front_LHS: 'Shock Abosrber - Front LHS',
  Shock_Abosrber_Rear_RHS: 'Shock Abosrber - rear RHS',
  No_Of_Indicators_Working: 'No. of Indicators Working',
  Ignition_Lock: 'Ignition Lock',
  Handle_Lock: 'Handle Lock',
  Panel_Lock: 'Panel Lock',
  Foot_Rest_Rear_RHS: 'Foot Rest - rear RHS',
  Foot_Rest_Front_RHS: 'Foot Rest - Front RHS',
  Foot_Rest_Front_LHS: 'Foot Rest - Front LHS',
  Rear_Brake_Pedal: 'Rear Brake Pedal',
  Brakes_Lever_Front_LHS: 'Brakes Lever- Front LHS',
  Mud_Flaps_Rear: 'Mud Flaps - Rear',
  Number_Plate_Rear: 'Number Plate rear',
  Number_Plate_Front: 'Number Plate front',
  Mud_Flaps_Front: 'Mud Flaps - Front',
  Front_Lower_Panel: 'Front Lower Panel',
  Front_Nose_Panel: 'Front Nose Panel',
  Mirror_LHS: 'Mirror - LHS',
  Mirror_RHS: 'Mirror - RHS',
  RI_Change_Flag: 'RI Change flag',
};
export const BIKE_REPORT_CATEGORY = {
  NEW_VERSION: 'NEW',
  OLD_VERSION: 'OLD',
  BOTH_VERSION: 'BOTH',
};

export const YES_NO = [
  {
    label: 'Yes',
    value: 'Yes',
  },
  {
    label: 'No',
    value: 'No',
  },
];
export const BIKE_SMOKE_COLOR = [
  {
    label: 'Black',
    value: 'Black',
  },
  {
    label: 'Blue',
    value: 'Blue',
  },
  {
    label: 'White',
    value: 'White',
  },
  {
    label: 'Colourless',
    value: 'Colourless',
  },
];
export const BIKE_FUEL_TYPES = [
  {
    label: 'Petrol',
    value: 'Petrol',
  },
  {
    label: 'Electrical',
    value: 'Electrical',
  },
];
export const BIKE_ODOMETER_RANGES = [
  {
    label: '0k - 10k',
    value: '0k - 10k',
  },
  {
    label: '10k - 25k',
    value: '10k - 25k',
  },
  {
    label: '25k - 50k',
    value: '25k - 50k',
  },
  {
    label: '50k - 75k',
    value: '50k - 75k',
  },
  {
    label: '75k - 100k',
    value: '75k - 100k',
  },
  {
    label: '>100k',
    value: '>100k',
  },
  {
    label: 'Not Working',
    value: 'Not Working',
  },
];
export const READ_NOREAD = [
  {
    label: 'Readable',
    value: 'Readable',
  },
  {
    label: 'Not Readable',
    value: 'Not Readable',
  },
];
export const RC_OPTIONS = [
  {
    label: 'Original',
    value: 'Original',
  },
  {
    label: 'Duplicate',
    value: 'Duplicate',
  },
  {
    label: 'Photocopy',
    value: 'Photocopy',
  },
  {
    label: 'Lost',
    value: 'Lost',
  },
];
export const INSURANCE_AVAILABILITY_OPTIONS = [
  {
    label: 'Valid: <3 Month',
    value: 'Valid: <3 Month',
  },
  {
    label: 'Valid: >3 Month',
    value: 'Valid: >3 Month',
  },
  {
    label: 'Expired',
    value: 'Expired',
  },
];
export const YES_NO_NA = [
  {
    label: 'Yes',
    value: 'Yes',
  },
  {
    label: 'No',
    value: 'No',
  },
  {
    label: 'N/A',
    value: 'N/A',
  },
];

export const OWNERS = () => {
  let arr = [];
  for (let i = 1; i <= 10; i++) {
    arr.push({
      value: i,
      label: i,
    });
  }

  return arr;
};
export const NUMBERS = {
  MINUS_SIXTY: -60,
  MINUS_ONE: -1,
  ZERO: 0,
  ONE: 1,
  TWO: 2,
  THREE: 3,
  FOUR: 4,
  FIVE: 5,
  SEVEN: 7,
  EIGHT: 8,
  NINE: 9,
  TEN: 10,
  ELEVEN: 11,
  THIRTEEN: 13,
  FOURTEEN: 14,
  FIFTEEN: 15,
  SIXTEEN: 16,
  SEVENTEEN: 17,
  EIGHTEEN: 18,
  TWENTY: 20,
  TWENTY_TWO: 22,
  FORTY_EIGHT: 48,
  FIFTY_SEVEN: 57,
  SIXTY: 60,
};

export const REGISTRATION_YEAR = () => {
  let arr = [];
  try {
    const currentYear = moment(new Date()).year();
    for (let i = 1990; i <= currentYear; i++) {
      arr.push({
        value: i,
        label: i,
      });
    }
  } catch (e) {}

  return arr;
};

export const FUEL_TYPES = [
  {
    label: 'Diesel',
    value: 'Diesel',
  },
  {
    label: 'Petrol',
    value: 'Petrol',
  },
  {
    label: 'Petrol + CNG',
    value: 'Petrol + CNG',
  },
  {
    label: 'Petrol + LPG',
    value: 'Petrol + LPG',
  },
  {
    label: 'Electric',
    value: 'Electric',
  },
  {
    label: 'Hybrid',
    value: 'Hybrid',
  },
];

export const CHASSIS_EMBOSSING = [
  {
    name: 'Ok',
    id: 'Ok',
  },
  {
    name: 'Rusted',
    id: 'Rusted',
  },
  {
    name: 'Repunched',
    id: 'Repunched',
  },
  {
    name: 'Not traceable',
    id: 'Not traceable',
  },
  {
    name: 'Mis-Match',
    id: 'Mis-Match',
  },
  {
    name: 'Incomplete',
    id: 'Incomplete',
  },
];

export const RC_CONDITIONS = [
  {
    name: 'Ok',
    id: 'Ok',
  },
  {
    name: 'Broken Chip',
    id: 'Broken Chip',
  },
  {
    name: 'Damaged',
    id: 'Damaged',
  },
  {
    name: 'Faded',
    id: 'Faded',
  },
];

export const RC_AT_DELIVERY = [
  {
    label: 'Original',
    value: 'Original',
  },
  {
    label: 'Duplicate',
    value: 'Duplicate',
  },
  {
    label: 'Lost with photocopy',
    value: 'Lost with photocopy',
  },
];

export const BIKE_RC_CONDITIONS = [
  {
    name: 'Ok',
    id: 'Ok',
  },
  {
    name: 'Broken Chip',
    id: 'Broken Chip',
  },
  {
    name: 'Damaged',
    id: 'Damaged',
  },
  {
    name: 'Faded',
    id: 'Faded',
  },
  {
    name: 'Detail Mismatch',
    id: 'Detail Mismatch',
  },
  {
    name: 'Lost',
    id: 'Lost',
  },
];

//Owner Name, Fuel Type, Chassis Number, Variant, Mfg Year, Engine No., Colour
export const DETAIL_MISMATCH = [
  {
    name: 'Owner Name',
    id: 'Owner Name',
  },
  {
    name: 'Owner Number',
    id: 'Owner Number',
  },
  {
    name: 'Fuel Type',
    id: 'Fuel Type',
  },
  {
    name: 'Chassis Number',
    id: 'Chassis Number',
  },
  {
    name: 'Variant',
    id: 'Variant',
  },
  {
    name: 'Mfg. year',
    id: 'Mfg. year',
  },
  {
    name: 'Color',
    id: 'Color',
  },
  {
    name: 'Engine No.',
    id: 'Engine No.',
  },
  {
    name: 'No Mismatch',
    id: 'No Mismatch',
  },
];

export const CHASSIS_NO_ON_RC = [
  {
    label: 'Readable',
    value: 'Readable',
  },
  {
    label: 'Not Readable',
    value: 'Not Readable',
  },
];

export const INSURANCE_TYPE = [
  {
    label: 'Comprehensive',
    value: 'Comprehensive',
  },
  {
    label: 'Zero Depreciation',
    value: 'Zero Depreciation',
  },
  {
    label: '3rd Party',
    value: '3rd Party',
  },
  {
    label: 'Insurance Expired',
    value: 'Insurance Expired',
  },
];

export const ROAD_TAX_PAID = [
  {
    label: 'OTT/LTT',
    value: 'OTT/LTT',
  },
  {
    label: 'Limited Period',
    value: 'Limited Period',
  },
];

export const INSPECTION_SECTION = {
  CAR_DETAILS: 'carDetails',
  EXTERIOR_TYRES: 'exteriorTyres',
  ELECTRICALS_INTERIOR: 'electricalsInterior',
  ENGINE_TRANSMISSION: 'engineTransmission',
  STEERING_SUSPENSION_BRAKES: 'steeringSuspensionBrakes',
  AIR_CONDITIONING: 'airConditioning',
  SUMMARY: 'summary',
};

export const INSPECTION_DATA_KEYS = {
  carDetails: 'Car Details',
  exteriorTyres: 'Exterior Tyres',
  electricalsInterior: 'Electrical Interiors',
  engineTransmission: 'Engine Transmission',
  steeringSuspensionBrakes: 'Steering Suspension Brakes',
  airConditioning: 'Air Conditioning',
  summary: 'Summary',
};

export const editableFielKeys = [
  'make',
  'model',
  'year',
  'month',
  'variant',
  'registrationYear',
  'registrationMonth',
  'registrationState',
  'rto',
  'registrationCity',
  'registrationNumber',
  'ownerNumber',
  'rtoNocIssued',
  'chassisNo',
  'chassisNumberEmbossing',
  'fuelType',
  'cngLpgFitment',
  'chassisNumber',
  'chassisNumberEmbossing',
  'customerName',
  'insuranceType',
  'insuranceExpiry',
  'noClaimBonus',
  'noClaimBonusPercentage',
  'noClaimBonuspercent',
  'fitnessUpto',
  'odometerReading',
  'mismatchInInsurance',
  'comments',
  'noOfOwners',
  'isUnderHypothecation',
  'toBeScrapped',
  'rcCondition',
  'rcAtDelivery',
  'chassisNoOnRc',
  'misMatchInRc',
  'ownerNumber',
  'roadTaxPaid',
  'roadTaxDateValidity',
  'bankNocStatus',
  'bankNocExpiryDate',
  'financierName',
  'loanStatus',
  'fuelLevel',
  'rtoNocIssued',
  'rtoNmcAvailable',
  'rtoNocAvailable',
  'rtoNocIssuedFor',
  'rtoNocIssuedDate',
  'rcAvailability',
];

export const editableFielKeysForExteriorInterior = ['odometerReading'];

// export const nonEditableFieldKeysForCarDetailSection = ['rcAvailability', 'rcCondition', 'chassisNoOnRc', 'misMatchInRc'];
export const nonEditableFieldKeysForCarDetailSection = ['rcAvailability'];

export const carDetailRejectedFieldsWithSubPartNodeArr = [
  'registrationState',
  'rcAvailability',
  'chassisNoOnRc',
  'misMatchInRc',
  'rcCondition',
  'rcAtDelivery',
  'rto',
  'rtoNocIssued',
  'rtoNmcAvailable',
  'rtoNocAvailable',
  'rtoNocIssuedFor',
  'rtoNocIssuedDate',
  'roadTaxPaid',
  'roadTaxDateValidity',
  'isUnderHypothecation',
  'bankNocStatus',
  'bankNocExpiryDate',
];

export const INSPECTION_REVIEW_ITEMS = [
  'carDetails',
  'exteriorTyres',
  'electricalsInterior',
  'engineTransmission',
  'steeringSuspensionBrakes',
  'airConditioning',
  'summary',
];
export const keysMapper = [
  {
    field: 'make',
    mapper: 'makeModelVariantMapper',
  },
  {
    field: 'model',
    mapper: 'makeModelVariantMapper',
  },
  {
    field: 'year',
    mapper: 'makeModelVariantMapper',
  },
  {
    field: 'month',
    mapper: 'documentsMapper',
  },
  {
    field: 'variant',
    mapper: 'makeModelVariantMapper',
  },
  {
    field: 'registrationYear',
    mapper: 'documentsMapper',
  },
  {
    field: 'registrationMonth',
    mapper: 'documentsMapper',
  },
  {
    field: 'registrationState',
    mapper: 'documentsMapper',
  },
  {
    field: 'rto',
    mapper: 'documentsMapper',
  },
  {
    field: 'registrationCity',
    mapper: 'documentsMapper',
  },
  {
    field: 'registrationNumber',
    mapper: 'documentsMapper',
  },
  {
    field: 'ownerNumber',
    mapper: 'documentsMapper',
  },
  {
    field: 'fuelType',
    mapper: 'makeModelVariantMapper',
  },
  {
    field: 'cngLpgFitment',
    mapper: 'documentsMapper',
  },
  {
    field: 'chassisNumber',
    mapper: 'documentsMapper',
  },
  {
    field: 'chassisNumberEmbossing',
    mapper: 'documentsMapper',
  },
  {
    field: 'customerName',
    mapper: 'documentsMapper',
  },
  {
    field: 'insuranceType',
    mapper: 'insuranceMapper',
  },
  {
    field: 'insuranceExpiry',
    mapper: 'insuranceMapper',
  },
  {
    field: 'noClaimBonus',
    mapper: 'insuranceMapper',
  },
  {
    field: 'noClaimBonusPercentage',
    mapper: 'insuranceMapper',
  },
  {
    field: 'fitnessUpto',
    mapper: 'documentsMapper',
  },
  {
    field: 'odometerReading',
    mapper: 'documentsMapper',
  },
  {
    field: 'mismatchInInsurance',
    mapper: 'insuranceMapper',
  },
  {
    field: 'comments',
    mapper: 'insuranceMapper',
  },
];

export const ISSUE_TYPES = [
  {
    name: 'RC Documents',
    id: 'RC Documents',
  },
  {
    name: 'Variant Selection',
    id: 'Variant Selection',
  },
  {
    name: 'Detail Mismatch-Vahan/RC/Insurance',
    id: 'Detail Mismatch-Vahan/RC/Insurance',
  },
  {
    name: 'Comment Selection',
    id: 'Comment Selection',
  },
  {
    name: 'Variant Feature',
    id: 'Variant Feature',
  },
  {
    name: 'Tyres Condition & Gauge Meter',
    id: 'Tyres Condition & Gauge Meter',
  },
  {
    name: 'Image Quality',
    id: 'Image Quality',
  },
  {
    name: 'Fuel Type',
    id: 'Fuel Type',
  },
  {
    name: 'Odometer Reading & Condition',
    id: 'Odometer Reading & Condition',
  },
  {
    name: 'SOP Adherence',
    id: 'SOP Adherence',
  },
  {
    name: 'To Be Scrapped or Not',
    id: 'To Be Scrapped or Not',
  },
  {
    name: 'RTO Details',
    id: 'RTO Details',
  },
];

export const rejectionReasonMapping = {
  //RC Documents
  'Manufacturing Month': 'RC Documents',
  'Manufacturing Year': 'RC Documents',
  'Registration Number': 'RC Documents',
  'Registration Month': 'RC Documents',
  'Registration Year': 'RC Documents',
  'Fitness Upto': 'RC Documents',
  'Under Hypothecation': 'RC Documents',
  'Financier Name/Bank NOC Status': 'RC Documents',
  'Road Tax Paid': 'RC Documents',
  'Road Tax Date (Validity)': 'RC Documents',
  'RC Availability': 'RC Documents',
  //Variant Selection
  Make: 'Variant Selection',
  Model: 'Variant Selection',
  Variant: 'Variant Selection',
  //Detail Mismatch-Vahan/RC/Insurance
  'Mismatch In RC': 'Detail Mismatch-Vahan/RC/Insurance',
  'Insurance Type': 'Detail Mismatch-Vahan/RC/Insurance',
  'Insurance Expiry Date': 'Detail Mismatch-Vahan/RC/Insurance',
  'Fuel Type': 'Detail Mismatch-Vahan/RC/Insurance',
  'Engine Number': 'Detail Mismatch-Vahan/RC/Insurance',
  'Make/Model': 'Detail Mismatch-Vahan/RC/Insurance',
  'Customer Name': 'Detail Mismatch-Vahan/RC/Insurance',
  'Chassis Number': 'Detail Mismatch-Vahan/RC/Insurance',
  'Chassis Number Embossing': 'Detail Mismatch-Vahan/RC/Insurance',
  'No Claim Bonus': 'Detail Mismatch-Vahan/RC/Insurance',
  'No Claim Bonus Percentage': 'Detail Mismatch-Vahan/RC/Insurance',
  //Comment Selection
  'Colour of Car on RC is different then in pictures': 'Comment Selection',
  'CNG cylinder test certificate not available': 'Comment Selection',
  'Car converted from Commercial to Private': 'Comment Selection',
  'CNG plate not available': 'Comment Selection',
  'RC / Fitness Certificate expired': 'Comment Selection',
  'CNG / LPG removed': 'Comment Selection',
  'Migrated from other state': 'Comment Selection',
  'Remote key not available / Damaged - only mechanical key':
    'Comment Selection',
  'VIN plate missing': 'Comment Selection',
  'Navigation chip not available': 'Comment Selection',
  'Amplifier – will be retained by customer': 'Comment Selection',
  'Odometer Tampered': 'Comment Selection',
  'Music system – will be retained by customer': 'Comment Selection',
  'Base Tube / woofer - will be retained by customer': 'Comment Selection',
  'Airbag warning light glows': 'Comment Selection',
  'Jack & Tool not available': 'Comment Selection',
  //Variant Feature
  'Alloy Wheel': 'Variant Feature',
  'Steering Mounted Audio Control': 'Variant Feature',
  'Reverse Camera': 'Variant Feature',
  'No. Of Airbags': 'Variant Feature',
  'Music System': 'Variant Feature',
  'Leather Seat': 'Variant Feature',
  'FABRIC SEAT': 'Variant Feature',
  Sunroof: 'Variant Feature',
  ABS: 'Variant Feature',
  'Rear Defogger': 'Variant Feature',
  'No. Of Power Windows': 'Variant Feature',
  'AC Cooling': 'Variant Feature',
  Heater: 'Variant Feature',
  'Auto AC': 'Variant Feature',
  //Tyres Condition & Gauge Meter
  'Lhs front tyre': 'Tyres Condition & Gauge Meter',
  'Rhs front tyre': 'Tyres Condition & Gauge Meter',
  'Lhs rear tyre': 'Tyres Condition & Gauge Meter',
  'Spare tyre': 'Tyres Condition & Gauge Meter',
  'Rhs rear tyre': 'Tyres Condition & Gauge Meter',
  //Image Quality
  'Interior From Back Seat': 'Image Quality',
  'Interior From Rear Right': 'Image Quality',
  'Interior From Front Right': 'Image Quality',
  'Front Main': 'Image Quality',
  'Front Left Side': 'Image Quality',
  'Rear Right Side': 'Image Quality',
  'Rear Main': 'Image Quality',
  'Front With Hood Open': 'Image Quality',
  'Rear Boot Opened': 'Image Quality',
  //Odometer Reading & Condition
  Odometer: 'Odometer Reading & Condition',
  'Odometer(Km)': 'Odometer Reading & Condition',
  'Fuel Level Reserve': 'Odometer Reading & Condition',
  //SOP Adherence
  'Engine Sound': 'SOP Adherence',
  'Exhaust Smoke': 'SOP Adherence',
  'Engine Permissible Blow by (Back Compression)': 'SOP Adherence',
  'Power Windows': 'SOP Adherence',
  Bumper: 'SOP Adherence',
  'Bonnet/Hood': 'SOP Adherence',
  Fender: 'SOP Adherence',
  Pillar: 'SOP Adherence',
  'Running Border': 'SOP Adherence',
  'Quarter Panel': 'SOP Adherence',
  'Dicky Door / Boot Door': 'SOP Adherence',
  Apron: 'SOP Adherence',
  Firewall: 'SOP Adherence',
  'Cowl Top': 'SOP Adherence',
  'Lower Cross Member': 'SOP Adherence',
  'Upper Cross Member (Bonnet Patti)': 'SOP Adherence',
  'Head Light Support': 'SOP Adherence',
  'Radiator Support': 'SOP Adherence',
  'Front Show': 'SOP Adherence',
  Windshield: 'SOP Adherence',
  'ORVM - Manual / Electrical': 'SOP Adherence',
  Light: 'SOP Adherence',
  //To Be Scrapped or Not
  'Scrapped yes/not': 'To Be Scrapped or Not',
  //RTO Details'
  'RTO NOC Issued': 'RTO Details',
  'Registration State,RTO,City': 'RTO Details',
  'Registration City': 'RTO Details',
  RTO: 'RTO Details',
};

export const detailMismatchRejectionReasonMapping = {
  'Registration Number': 'Detail Mismatch-Vahan/RC/Insurance',
  'Mismatch In RC': 'Detail Mismatch-Vahan/RC/Insurance',
  'Insurance Type': 'Detail Mismatch-Vahan/RC/Insurance',
  'Insurance Expiry Date': 'Detail Mismatch-Vahan/RC/Insurance',
  'Fuel Type': 'Detail Mismatch-Vahan/RC/Insurance',
  'Engine Number': 'Detail Mismatch-Vahan/RC/Insurance',
  'Make/Model': 'Detail Mismatch-Vahan/RC/Insurance',
  'Customer Name': 'Detail Mismatch-Vahan/RC/Insurance',
  'Chassis Number': 'Detail Mismatch-Vahan/RC/Insurance',
  'Chassis Number Embossing': 'Detail Mismatch-Vahan/RC/Insurance',
};

export const KEY_WITH_IDS = [
  'make',
  'model',
  'year',
  'variant',
  'registrationState',
  'registrationCity',
  'city',
  'storeName',
  'comments',
  'branch',
];
export const CAR_ID_REGEX = /\/([0-9]+)[^\/]*$/g;

export const DEALER_API_AUTH_TOKEN =
  'Bearer 6b0f2163-1953-4f89-9dc4-8353416e7152';

export const ROLE_KEYS = {
  QC: 'qc',
  OTHER: 'version-pdf',
  QC_BIKE: 'bike-qc',
  QC_VERSION: 'qc-Version',
  QC_STORE_MANAGER: 'StoreManager-Qc',
  QC_ADMIN: 'SM-QcAdmin',
  GS: 'gs',
};
export const INSPECTION_FLOW = {
  BIKE: 'bike',
  CAR: 'car',
};
export const USER_ROLEACCESS_PATHS = {
  roleAccess: [
    {
      role: ROLE_KEYS.QC,
      paths: [
        '/',
        '/login',
        '/inspection',
        '/re-inspection',
        '/review',
        '/inspection-version',
        '/poc',
        '/view-image',
        '/inspection-bike',
        '/bike-review',
        '/add-car-variant',
        '/gs-refurbishment',
        '/gs-refurbishment-review',
        '/bike-report',
        '/car-variant-list',
        '/inspection-bike-version',
        '/gs-refurbishment-version',
        '/sell-online',
      ],
    },
    {
      role: ROLE_KEYS.QC_BIKE,
      paths: [
        '/',
        '/login',
        '/inspection-bike',
        '/bike-review',
        '/inspection',
        '/re-inspection',
        '/review',
        '/inspection-version',
        '/poc',
        '/view-image',
        '/add-car-variant',
        '/bike-report',
        '/inspection-bike-version',
        '/gs-refurbishment-version',
        '/sell-online',
      ],
    },
    {
      role: ROLE_KEYS.OTHER,
      paths: [
        '/login',
        '/re-inspection',
        '/inspection-version',
        '/poc',
        '/inspection-bike',
        '/add-car-variant',
        '/customer-audit',
        '/inspection-bike-version',
        '/bike-report',
        '/gs-refurbishment-version',
        '/sell-online',
      ],
    },
    {
      role: ROLE_KEYS.QC_STORE_MANAGER,
      paths: [
        '/',
        '/login',
        '/inspection',
        '/re-inspection',
        '/review',
        '/inspection-version',
        '/poc',
        '/view-image',
        '/inspection-bike',
        '/bike-review',
        '/add-car-variant',
        '/gs-refurbishment',
        '/gs-refurbishment-review',
        '/bike-report',
        '/car-variant-list',
        '/inspection-bike-version',
        '/gs-refurbishment-version',
        '/sell-online',
      ],
    },
    {
      role: ROLE_KEYS.QC_ADMIN,
      paths: [
        '/',
        '/login',
        '/inspection',
        '/re-inspection',
        '/review',
        '/inspection-version',
        '/poc',
        '/view-image',
        '/inspection-bike',
        '/bike-review',
        '/add-car-variant',
        '/gs-refurbishment',
        '/gs-refurbishment-review',
        '/bike-report',
        '/car-variant-list',
        '/inspection-bike-version',
        '/gs-refurbishment-version',
        '/sell-online',
        '/admin',
        '/gs-unflagging',
        '/gs-unflagging-details',
        '/admin-rv',
        '/update-cj',
        '/admin-vtt',
      ],
    },
    {
      role: ROLE_KEYS.GS,
      paths: [
        '/login',
        '/gs-unflagging',
        '/gs-unflagging-details',
        '/view-image',
        '/inspection-version',
        '/review',
      ],
    },
    {
      role: ROLE_KEYS.QC_VERSION,
      paths: ['/login', '/view-image', '/inspection-version', '/review'],
    },
  ],
};

// export const carDetailRejectedFieldsWithSubPartNodeArr = ['registrationState', 'rcAvailability', 'chassisNoOnRc', 'misMatchInRc',
// 'rcCondition', 'rto'];

export const exteriorTyreRejectedFieldsWithSubPartNodeArr = [
  'apron',
  'bumpers',
  'doors',
  'fenders',
  'lights',
  'orvm',
  'pillars',
  'quarterPanel',
  'runningBorder',
  'windshield',
  'powerWindows',
  'airbagFeature',
];

export const MONTH_YEAR = [
  {
    id: '1',
    value: 'January',
  },
  {
    id: '2',
    value: 'February',
  },
  {
    id: '3',
    value: 'March',
  },
  {
    id: '4',
    value: 'April',
  },
  {
    id: '5',
    value: 'May',
  },
  {
    id: '6',
    value: 'June',
  },
  {
    id: '7',
    value: 'July',
  },
  {
    id: '8',
    value: 'August',
  },
  {
    id: '9',
    value: 'September',
  },
  {
    id: '10',
    value: 'October',
  },
  {
    id: '11',
    value: 'November',
  },
  {
    id: '12',
    value: 'December',
  },
];

export const MONTHS_NAME = {
  1: 'January',
  2: 'February',
  3: 'March',
  4: 'April',
  5: 'May',
  6: 'June',
  7: 'July',
  8: 'August',
  9: 'September',
  10: 'October',
  11: 'November',
  12: 'December',
};
export const BLANK_ADDITIONAL_IMAGES = [
  {
    title: "Owner's Manual",
    url: '',
    hashKey: 'inspectionData_cardetails_additionalImages_ownersManual',
    imageType: 'addnl',
    part: 'additionalImages',
    subPart: null,
  },
  {
    title: 'RC Particular',
    url: '',
    hashKey: 'inspectionData_cardetails_additionalImages_RCParticular',
    imageType: 'addnl',
    part: 'additionalImages',
    subPart: null,
  },
  {
    title: 'Address Proof',
    url: '',
    hashKey: 'inspectionData_cardetails_additionalImages_AddressProof',
    imageType: 'addnl',
    part: 'additionalImages',
    subPart: null,
  },
  {
    title: 'Form 35/NOC',
    url: '',
    hashKey: 'inspectionData_cardetails_additionalImages_Form35NOC',
    imageType: 'addnl',
    part: 'additionalImages',
    subPart: null,
  },
  {
    title: 'Hypothecation 1',
    url: '',
    hashKey: 'inspectionData_cardetails_additionalImages_Hypothication_1',
    imageType: 'addnl',
    part: 'additionalImages',
    subPart: null,
  },
  {
    title: 'Hypothecation 2',
    url: '',
    hashKey: 'inspectionData_cardetails_additionalImages_Hypothication_2',
    imageType: 'addnl',
    part: 'additionalImages',
    subPart: null,
  },
];

export const BIKE_IMAGE_TYPE = {
  RC: 'rc',
  INSURANCE: 'insurance',
  CHASIS_NUMBER: 'chassisNumber',
  BIKE_IMAGES: 'bikeImages',
  DEFECT_IMAGES: 'defectImages',
  DUPLICATE_KEY: 'duplicateKeys',
};
export const maskingTheme = {
  // 'common.bi.image': 'https://uicdn.toast.com/toastui/img/tui-image-editor-bi.png',
  'common.bi.image': '',
  'common.bisize.width': '0px',
  'common.bisize.height': '0px',
  'common.backgroundImage': 'none',
  'common.backgroundColor': '#c1c1c1',
  'common.border': '0px',

  // header
  'header.backgroundImage': 'none',
  'header.backgroundColor': 'transparent',
  'header.border': '0px',

  // load button
  'loadButton.display': 'none',
  'loadButton.backgroundColor': '#fff',
  'loadButton.border': '1px solid #ddd',
  'loadButton.color': '#222',
  'loadButton.fontFamily': 'NotoSans, sans-serif',
  'loadButton.fontSize': '12px',

  // download button
  'downloadButton.display': 'none',
  'downloadButton.backgroundColor': '#c1c1c1',
  'downloadButton.border': '1px solid #c1c1c1',
  'downloadButton.color': '#c1c1c1',
  'downloadButton.fontFamily': 'NotoSans, sans-serif',
  'downloadButton.fontSize': '0px',
};
export const MONTH_MAP = [
  {
    id: 'jan',
    value: 'January',
  },
  {
    id: 'feb',
    value: 'February',
  },
  {
    id: 'mar',
    value: 'March',
  },
  {
    id: 'apr',
    value: 'April',
  },
  {
    id: 'may',
    value: 'May',
  },
  {
    id: 'jun',
    value: 'June',
  },
  {
    id: 'jul',
    value: 'July',
  },
  {
    id: 'aug',
    value: 'August',
  },
  {
    id: 'sep',
    value: 'September',
  },
  {
    id: 'oct',
    value: 'October',
  },
  {
    id: 'nov',
    value: 'November',
  },
  {
    id: 'dec',
    value: 'December',
  },
];
export const PETROL = 'Petrol';

export const SELL_ONLINE_STATUS = {
  PENDING: 'pending',
  APPROVED: 'approved',
  REJECTED: 'rejected',
  OPEN: 'open',
};

export const MAIN_QUESTION = {
  towing_required: 'towing_required',
  isaccidental: 'isaccidental',
  carLookFromOutside: 'carLookFromOutside',
  hasrust: 'hasrust',
};

export const RTO_NOC_ISSUED_FIELD_VALUE = {
  '0': 'No',
  '1': 'Yes',
  '2': 'NA',
};

export const MIGRATED_VEHICLE_VALUE = {
  '0': 'No',
  '1': 'Yes',
};

export const GS_UNFLAGGING_FILTER_PARAMS = {
  city: 'storeCityId',
  center: 'storeId',
  category: 'category',
  assignee: 'assignedTo',
};

export const GS_UNFLAGGING_ACTION = {
  APPROVED: 'approved',
  REJECTED: 'rejected',
  PENDING: 'pending',
};

export const GS_UNFLAG_CONDITION_FLAG = {
  '0': 'Alpha',
  '4': 'Beta',
};

export const GS_UNFLAG_BUTTON_ACTION = {
  MARK: 'Mark',
  CHANGE: 'Change',
  UNFLAG: 'Unflag',
};

export const GS_UNFLAG_REASON = {
  pillar_rusted: 'pillar_rusted',
  pillar_repaired: 'pillar_repaired',
  pillar_damaged: 'pillar_damaged',
  pillar_replaced: 'pillar_replaced',
  apron_rusted: 'apron_rusted',
  apron_repaired: 'apron_repaired',
  apron_damaged: 'apron_damaged',
  apron_replaced: 'apron_replaced',
  roof_rusted: 'roof_rusted',
  roof_repaired: 'roof_repaired',
  roof_damaged: 'roof_damaged',
  roof_replaced: 'roof_replaced',
  'rust_metal eating': 'rust_metal eating',
  'water logged vehicle': 'water logged vehicle',
  'odometer tampered': 'odometer tampered',
  'Cng/Lpg_issue': 'Cng/Lpg_issue',
  'exhaustSmoke_white/blue': 'exhaustSmoke_white/blue',
  ATtransmission_issue: 'ATtransmission_issue',
  ownership_issue: 'ownership_issue',
  'engineTransmission_towing suggested': 'engineTransmission_towing suggested',
  'engine_critical sound': 'engine_critical sound',
  'engine_critical sound': 'engine_critical sound',
  'engineSump_heavy leakage': 'engineSump_heavy leakage',
  engine_repaired: 'engine_repaired',
  'converted_Commercial to pvt': 'converted_Commercial to pvt',
  'steering_not working': 'steering_not working',
  airbag_issue: 'airbag_issue',
};

export const GS_UNFLAG_BETA_TO_ALPHA = {
  pillar_not_rusted: 'pillar_not_rusted',
  pillar_not_repaired: 'pillar_not_repaired',
  pillar_not_damaged: 'pillar_not_damaged',
  pillar_not_replaced: 'pillar_not_replaced',
  apron_not_rusted: 'apron_not_rusted',
  apron_not_repaired: 'apron_not_repaired',
  apron_not_damaged: 'apron_not_damaged',
  apron_not_replaced: 'apron_not_replaced',
  roof_not_rusted: 'roof_not_rusted',
  roof_not_repaired: 'roof_not_repaired',
  roof_not_damaged: 'roof_not_damaged',
  roof_not_replaced: 'roof_not_replaced',
  'not_water logged': 'not_water logged',
  'no_metal eating rust': 'no_metal eating rust',
  no_engineRepair: 'no_engineRepair',
  others: 'others',
};

export const GS_UNFLAG_APLHA_TO_BETA = {
  exterior_condition_faded: 'exterior_condition_faded',
  exterior_condition_rusted: 'exterior_condition_rusted',
  exterior_condition_dented: 'exterior_condition_dented',
  high_refurb_estimated: 'high_refurb_estimated',
  others: 'others',
};

export const LOAN_STATUS_OPTIONS = {
  OPEN: 'Open',
  PAID_CLOSED: 'Paid / Closed',
};

export const REJECTION_REMARK_TYPE = {
  TEXT: 'text',
  AUDIO: 'audio',
};

export const B_TO_B = {
  B_TO_B: 'B2B',
};

export const INSPECTION_STATUS_KEYS = {
  SM_REVIEW: 'smReview',
  DONE: 'done',
  NEW: 'new',
};

export const RC_AVAILABITY_TYPES = {
  ORIGINIAL: 'Original',
  DUPLICATE: 'Duplicate',
  PHOTOCOPY: 'Photocopy',
  LOST: 'Lost',
};
export const RC_AVAILABITY_LIST = [
  {
    label: 'Original',
    value: RC_AVAILABITY_TYPES.ORIGINIAL,
  },
  {
    label: 'Duplicate',
    value: RC_AVAILABITY_TYPES.DUPLICATE,
  },
  {
    label: 'Photocopy',
    value: RC_AVAILABITY_TYPES.PHOTOCOPY,
  },
  {
    label: 'Lost',
    value: RC_AVAILABITY_TYPES.LOST,
  },
];

export const REPLACE_WITH_RC = [
  {
    label: 'RC1',
    value: 'RC0',
  },
  {
    label: 'RC2',
    value: 'RC1',
  },
  {
    label: 'RC3',
    value: 'RC2',
  },
  {
    label: 'RC4',
    value: 'RC3',
  },
  {
    label: 'RC5',
    value: 'RC4',
  },
];

export const REPLACE_WITH_INS = [
  {
    label: 'INS1',
    value: 'INS0',
  },
  {
    label: 'INS2',
    value: 'INS1',
  },
  {
    label: 'INS3',
    value: 'INS2',
  },
  {
    label: 'INS4',
    value: 'INS3',
  },
  {
    label: 'INS5',
    value: 'INS4',
  },
];
export const SPECIAL_EDITABLE_FIELDS = [
  'rcAvailability',
  'insuranceType',
  'insuranceExpiry',
  'rcCondition',
  'misMatchInRc',
  'rcAtDelivery',
  'noClaimBonus',
  'noClaimBonuspercent',
];

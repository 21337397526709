import React, { useState } from "react";
import H2 from "../h2";
import styles from "./multiple-carousel.module.css";
import ImageCheckbox from "../image-checkbox";
import { thumborUrl, videoUrl } from "../../utils/url-constants";
import { Player } from 'video-react';
import "video-react/dist/video-react.css";
import Popup from "../popup";
import InspectionPocPopup from "../inspection-poc-popup";
import Button from "../shared/button";
import ZoomIcon from "../../components/images/zoom.png";
import { isAssignedToMe, getFromLocalStorage, isUserRoleBIkeQc } from "../../utils/helper";
import { LOCAL_STORAGE_KEYS, INSPECTION_FLOW } from "../../utils/app-constants";

const MultipleCarousel = ({ inspectionDetails, bikeInspectionDetails, resetInspectionDetailByKeyConnect, mapper: _mapper, currentMapper, enlargeImage = false, zoomEnabled = true, url, versionViewMode = true }) => {

    // Not showing [engineBlowByBackCompression] in Images/ Videos slider
    let mapper = _mapper.filter(obj => obj.key !== "engineBlowByBackCompression");
    
    let isBikeQc = isUserRoleBIkeQc();
    const imagequalityParam  = getFromLocalStorage(LOCAL_STORAGE_KEYS.IMAGE_QUALITY_PARAM) || ""; 

    const cdnBaseUrl =  getFromLocalStorage(LOCAL_STORAGE_KEYS.IMAGE_HOST_URL) || thumborUrl();
    const videosHostURL = getFromLocalStorage(LOCAL_STORAGE_KEYS.VIDEO_HOST_URL) || videoUrl();
    const { qcAssign, selectedVersion, qcUser, qcAudit } = isBikeQc ? bikeInspectionDetails : inspectionDetails;
    const currentUser = qcAssign ? qcAssign.to : "";
    const inspectionFlow = isBikeQc ? INSPECTION_FLOW.BIKE : '';
    const qcUserEmail = getFromLocalStorage(LOCAL_STORAGE_KEYS.EMAIL);
    let showEditImage = false;
    if (isAssignedToMe(currentUser) && !(selectedVersion > -1 || selectedVersion === "current")) {
        showEditImage = true;
    }

    const [popupOpen, setPopupOpen] = useState(false);
    const [imageIndex, setImageIndex] = useState(0);

    const onPopupOpen = (index) => {
        setImageIndex(index);
        setPopupOpen(true);
    };
    const onPopupClose = () => {
        setPopupOpen(false);
    };

    const getSeverityScaleCC = (obj) => {
        let text = [];
        Object.keys(obj).map(item => {
            text.push(`${item}(${obj[item]})`);
        });
        return text.join(", ");
    };

    const getCurrentCondition = (arr) => {
        let text = [];
        arr.map(item => {
            text.push(item);
        });
        return text.join(", ");
    };

    const getWorkDone = (workDone) => {
        let text = [];
        workDone.value.forEach(item => {
            if (item === "Repaired" && workDone.repairedOption && workDone.repairedOption.length) {
                text.push(`${item}(${workDone.repairedOption.join(", ")})`);
            } else {
                text.push(item);
            }
        });
        return text.join(", ");
    };

    const panelsTitleList = ['Bonnet', 'Bonnet/Hood', 'Door', 'Pillar', 'Roof', 'Fender', 'Quarter Panel', 'Dicky Door', 'Dicky Door / Boot Door']; 
    
    const compareWorkDoneValue = (ItemValue) => {
        if(ItemValue && ItemValue.value) {
            const currentCondition = ['Black', 'Repaired', 'Repainted', 'Replaced'];
            for(let i = 0; i< ItemValue.value.length; i++) {
                if(currentCondition.includes(ItemValue.value[i])) {
                    return true;
                }
            }
        }
        
    };
    return (
        <div className={styles.gridContainer}>
            <H2 text={"Images/ Videos"} />
            <div className={styles.grid}>
                { isBikeQc && mapper.length && mapper.map((item, index) =>
                    <div className={styles.carsCard} key={index}>
                        <div className={styles.carsCardImage}>
                            {item.mediaType === 'image' && (
                                <>
                                    <img 
                                        src={`${cdnBaseUrl}${item.uri}${imagequalityParam}`} 
                                        alt="" 
                                        width="263px" 
                                        height="199px" 
                                    />
                                    {versionViewMode && qcUser === qcUserEmail && (
                                        <Button 
                                            classNames="imageEditCta" 
                                            ctaText="Edit" 
                                            onClick={() => { onPopupOpen(index); }} 
                                        />
                                    )}
                                </>
                            )}
                            {item.mediaType === 'video' && (
                                <Player
                                    playsInline
                                    src={`${videosHostURL}/${item.uri}`}
                                    preload="none"
                                />
                            )}
                        </div>
                        {versionViewMode && (<div className={styles.yoursCarsContent}>
                            <ImageCheckbox 
                                isBikeQc={isBikeQc} 
                                index={index} 
                                currentMapper={currentMapper} 
                                checked={item.checked}
                                section={item.section}
                            />
                        </div>)}
                    </div>
                )}

                { !isBikeQc && mapper.length > 0 && mapper.map((item, index) =>
                    <div className={`${styles.carsCard} 
                        ${qcAudit && ((item.title === "Exhaust Smoke" && item.value !== 'Yes') || (panelsTitleList.includes(item.title)) 
                        && compareWorkDoneValue(item.workDone))
                        ? styles.carsCardImageqcRequired : '' }`} key={index}>
                        <div className={styles.carsCardImage}>
                            {
                                item.image &&
                                <>
                                    <img src={`${cdnBaseUrl}${item.image ? item.image.url : ""}${imagequalityParam}`} alt="" width="263px"
                                        height="199px" />
                                    {showEditImage && <Button classNames="imageEditCta" ctaText="Edit" onClick={() => { onPopupOpen(index); }} />}
                                </>
                            }
                            {
                                item.video && <Player
                                    playsInline
                                    poster={`${videosHostURL}/${item.video ? (item.video.thumbUrl) : ""}`}
                                    src={`${videosHostURL}/${item.video ? (item.video.engineSoundVideoIdle ? item.video.engineSoundVideoIdle : item.video.url) : ""}`}
                                    preload="none"
                                />
                            }

                        </div>
                        <div className={styles.yoursCarsContent}>
                            <ImageCheckbox index={item.index} currentMapper={currentMapper} checked={item.checked} section={item.section} />
                        </div>
                        {
                            item.workDone && item.workDone.value &&
                            <p className={styles.text}>{getWorkDone(item.workDone)}</p>
                        }
                        {
                            item.currentCondition && item.currentCondition.value &&
                            <p className={styles.text}>{getCurrentCondition(item.currentCondition.value)}</p>
                        }
                        {
                            item.severityScaleCC && item.severityScaleCC.value &&
                            <p className={styles.text}>{getSeverityScaleCC(item.severityScaleCC.value)}</p>
                        }
                        {
                            item.panelPaint && item.panelPaint.coatingMaxDiff &&
                            <p className={styles.text}>PCM: {item.panelPaint.coatingMaxDiff}</p>
                        }
                        {
                            item.image &&
                            <a href={"/view-image/0?url=" + item.image.url} target="_blank" className={styles.zoomIcon} ><img src={ZoomIcon} alt="zoom" /></a>
                        }
                        {
                            (item.image && item.image.prevValue) &&
                            <p className={styles.text}>{item.image.prevValue}</p>
                        }
                        <p className={styles.requiredText}>QC Audit Required</p>
                    </div>
                )}
            </div>

            <Popup isOpen={popupOpen} close={onPopupClose}>
                <InspectionPocPopup 
                    index={imageIndex} 
                    close={onPopupClose} 
                    mapper={mapper} 
                    setPopupOpen={setPopupOpen} 
                    currentMapper={currentMapper} 
                    isBikeQc={isBikeQc}
                    inspectionFlow={inspectionFlow}
                />
            </Popup>
        </div>

    );
};

export default MultipleCarousel;

import React from 'react'
import RemarkHistory from '../remark-history';
import RemarkInput from '../remark-input';

const Remark = ({
  document,
  section,
  showRemarkInput,
  isRemarkHistoryOpen
}) => {
  return (
    <>
      {showRemarkInput && (
        <RemarkInput
            document={document}
            section={section}
        />
      )}

      {isRemarkHistoryOpen && (
          <RemarkHistory remarkHistory={document.remarkHistory} />
      )}
    </>
  )
}

export default Remark
import React from "react";
import PropTypes from "prop-types";
import styles from "./radio-button.module.css";

const RadioButton = ({
    id,
    name,
    value,
    checkedStatus,
    onClickCallback = () => { },
    text = "",
    labelClass,
    isDisabled = false
}) => {

    const onChangeHandler = (e) => {
        const { target: { value } } = e;
        onClickCallback(value);
    };

    return (
        <div className={styles.radioButtonWrapper}>
            <input
                key={name}
                onChange={onChangeHandler}
                type="radio"
                id={id}
                // name="cb"
                name={name}
                value={value}
                checked={checkedStatus}
                disabled={isDisabled}
            />
            <label className={styles[labelClass] || styles.labelText}  htmlFor={id} >{text}</label>
        </div>
    );
};

RadioButton.propTypes = {
    onClickCallback: PropTypes.func,
    checkedStatus: PropTypes.bool
};

export default RadioButton;


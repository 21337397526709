import React from 'react';
import { Descope, useSession, useUser } from '@descope/react-sdk';
const DescopeSignInWidget = props => {
  const { isSessionLoading } = useSession();
  const { isUserLoading } = useUser();
  if (isSessionLoading || isUserLoading) {
    return <p>Loading...</p>;
  }
  return (
    <Descope
      flowId={'c2b-qc-panel'}
      onSuccess={props.onSuccess}
      onError={props.onError}
    />
  );
};
export default DescopeSignInWidget;

import { createTypes } from "reduxsauce";

export default createTypes(
    `
    FETCH_CITYLOCATION
    FETCH_CITYLOCATION_SUCCESS
    FETCH_CITYLOCATION_FAILURE
    RESET_CITYLOCATION
    RESET_FILTER_BY_KEY
    FETCH_ASSIGNEE_SUCCESS
    FETCH_ASSIGNEE_FAILURE
`,
    {
        prefix: "inspectionfilters/"
    }
);

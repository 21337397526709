import React, { useEffect, useState } from "react";
import { RESET_REDUCER_KEYS } from "../../utils/app-constants";
import InputText from "../shared/input-text";
import { isUserRoleBIkeQc } from "../../utils/helper";

const CustomerNumber = ({ detail, resetInspectionDetailByKeyConnect, inspectionDetails, bikeInspectionDetails, resetInspectionBikeDetailByKeyConnect }) => {
    const initialValue = detail.newValue ? detail.newValue : detail.value;
    const [customerNo, setCustomerNo] = useState(initialValue);
    const { key } = detail;
    const { documentsMapper } = inspectionDetails;
    let isBikeQc = isUserRoleBIkeQc();
    const { bikeDocumentsMapper } = bikeInspectionDetails;

    const onChange = (e) => {
        const { target: { value } } = e;
        if (!isNaN(value)) {
            setCustomerNo(value);
        }

    };

    const onBlurText = (e) => {
        if (customerNo !== initialValue) {
            setNewValue();
        }
    };

    const setNewValue = () => {
        let mapper = isBikeQc ? [...bikeDocumentsMapper] : [...documentsMapper];
        const newValue = customerNo;
        let dependentFields = [];
        mapper = mapper.map(map => {
            let element = { ...map };
            if (map.key === key) {
                element.newValue = newValue;
                element.newId = newValue;
                // element.checked = false;
                if (element.dependentFields && element.dependentFields.length > 0) {
                    dependentFields = element.dependentFields;
                }
            }

            return element;
        });

        mapper = mapper.map(map => {
            let element = { ...map };
            if (dependentFields.find(field => field === element.key)) {
                element.checked = true;
            }

            return element;
        });
        if (isBikeQc) {
            resetInspectionBikeDetailByKeyConnect(RESET_REDUCER_KEYS.BIKE_DOCUMENTS_MAPPER, mapper);
        } else {
            resetInspectionDetailByKeyConnect(RESET_REDUCER_KEYS.DOCUMENTS_MAPPER, mapper);
        }
    };
    return (
        <div>
            <InputText
                placeholder={detail.text}
                onChange={onChange}
                value={customerNo}
                onBlurCallback={onBlurText}
                maxLength={10}
            />
        </div>
    );
};

export default CustomerNumber;

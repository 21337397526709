import React, {useEffect, useState} from "react";
import {RESET_REDUCER_KEYS, YES_NO} from "../../utils/app-constants";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';import DateFnsUtils from '@date-io/date-fns';
import Grid from '@material-ui/core/Grid';
import moment from "moment";


const RtoNmcDate = ({detail,resetInspectionDetailByKeyConnect, inspectionDetails}) => {
    
    const { value, key } = detail;
    const { documentsMapper } = inspectionDetails;

    const [dateValue, setDateValue] = useState("");
    const [selectedDate, setSelectedDate] = useState(new Date());

    const setNewValue = () => {
        let mapper = [...documentsMapper];
        const newValue = dateValue;
        mapper = mapper.map(map => {
            let element = { ...map };
            if (map.key === key) {
                element.newValue = newValue;
                element.newId = newValue;
            }
            return element;
        });
        resetInspectionDetailByKeyConnect(RESET_REDUCER_KEYS.DOCUMENTS_MAPPER, mapper);
    };

    useEffect(() => {
        if (dateValue) {
            setNewValue();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dateValue]);

    useEffect(() => {
        if (value) {        
            const date_arr = value.split("-");
            const fitnessDate = `${date_arr[2]}-${date_arr[1]}-${date_arr[0]}`;
            setSelectedDate(new Date(fitnessDate));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    const handleDateChange = date => {
        const dateFormate = "DD-MM-YYYY";
        const formattedDate = moment(date).format(dateFormate).toString();
        setSelectedDate(date);
        setDateValue(formattedDate);
    };
    return (
        <div className="mb-5">
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container>
                <KeyboardDatePicker
                    placeholder="Select Date"
                    value={selectedDate}
                    onChange={date => handleDateChange(date)}
                    format="dd/MM/yyyy"
                />
            </Grid>
        </MuiPickersUtilsProvider>
    </div>
    );
};

export default RtoNmcDate;

import React from "react";
import styles from "../inspection-interior/inspection-interior.module.css";
import Checkbox from "../shared/checkbox";

const InspectionOdometerComments = ({inspectionDetails,resetInspectionDetailByKeyConnect,document}) => {
    const { text, checked, value, key, newValue, isEditable,id} = document;
    const {odometerComments} = inspectionDetails;

    const onCheckboxHandler = (id) => {
        let mapper = [...odometerComments];
        const index = mapper.findIndex(item=>item.id === id)      ;
        mapper[index].checked = !mapper[index].checked;
        resetInspectionDetailByKeyConnect("odometerComments", mapper);
    };

    return (
        <div className={styles.documentInfo}>
            <div className={styles.checkboxlabel}>
                <Checkbox
                    checkedStatus={checked}
                    onChangeCallback={() => onCheckboxHandler(id)}
                    name={`odometerComments_${id}`}
                    id={`odometerComments_${id}`}
                />
                <label className={`${styles.lableText} `}>{text}</label>
            </div>
            <div className={styles.dataWrapper}>
                {/*{*/}
                {/*    checked && isEditable ? getEditableComponent(key, document, resetInspectionDetailByKeyConnect) : newValue ? newValue : value*/}
                {/*}*/}
                {
                    <span>{value}</span>
                }
                {/*{*/}
                {/*    newValue ? <div className={styles.newValue}>{`changed from ${value}`}</div>  : ""*/}
                {/*}*/}
            </div>
        </div>
    );
};

export default InspectionOdometerComments;

import React from "react";
import styles from "./inspection-documents-bike.module.css";
import Checkbox from "../shared/checkbox/";
import { getEditableComponent, getFromLocalStorage } from "../../utils/helper";
import { RESET_REDUCER_KEYS, LOCAL_STORAGE_KEYS, BIKE_DETAIL_KEYS, PETROL } from "../../utils/app-constants";
import Change from "../change";
import Tooltip from "../../components/tooltip";

const InspectionDocumentsBike = ({
    bikeInspectionDetails,
    document,
    resetInspectionBikeDetailByKeyConnect,
    onItemCheckCallback = () => { },
    versionViewMode = true
}) => {
    
    const { text, checked, value, key, newValue, isEditable, isDisabled, isImportant, vaahanFlag, parivahanWebsite, vahanWebsite, vahanSms, prevValue, errorMsg } = document;
    const excludedFieldsForChangedTo = [
        "rcCondition"
    ];
    // const { documentsMapper } = inspectionDetails;
    const { bikeDocumentsMapper, qcUser } = bikeInspectionDetails;
    const email = getFromLocalStorage(LOCAL_STORAGE_KEYS.EMAIL);
    const resetValues = (field) => {
        field.newId = '';
        field.newValue = '';
        field.errorMsg = '';
        field.isValid = true;
    };

    const onCheckboxHandler = (e, key) => {
        const { target: { checked } } = e;
        let mapper = [...bikeDocumentsMapper];
        const field = mapper.find(item => item.key === key);
        const { dependentFields } = field;
        field.checked = checked;
        if (!checked) {
            resetValues(field);
        }
        (dependentFields || []).forEach((item => {
            const dependentItem = mapper.find(x => x.key === item);
            if (dependentItem) {
                if (key === "rcAvailability") {
                    const rcAvailabilityIndex = mapper.findIndex(item => item.key === "rcAvailability");
                    if (rcAvailabilityIndex > -1 && mapper[rcAvailabilityIndex].value.match(/lost/i)) {
                        dependentItem.checked = false;
                    } else {
                        dependentItem.checked = checked;
                        dependentItem.isDisabled = checked;
                    }
                }
                else {
                    dependentItem.checked = checked;
                    dependentItem.isDisabled = checked;
                }
            }
            if (!checked) {
                if (field.key === BIKE_DETAIL_KEYS.Hypothecation) {
                    if (field.value === "Yes") {
                        dependentItem.isVisible = true;
                    }
                    if (field.value === "No") {
                        dependentItem.isVisible = false;
                    }
                }
                if (field.key === BIKE_DETAIL_KEYS.Insurance_Type) {
                    if (field.value === "Insuranc Expired") {
                        dependentItem.isVisible = false;
                    } else {
                        dependentItem.isVisible = true;
                    }
                }
                if (field.key === BIKE_DETAIL_KEYS.Fuel_Type) {
                    if (field.value === PETROL) {
                        dependentItem.isVisible = true;
                    } 
                    if (field.value !== PETROL) {
                        dependentItem.isVisible = false;
                    }
                }
                resetValues(dependentItem);
            }
        }));

        const fieldIndex = mapper.indexOf(field);
        mapper[fieldIndex] = field;

        resetInspectionBikeDetailByKeyConnect(RESET_REDUCER_KEYS.BIKE_DOCUMENTS_MAPPER, mapper);

        onItemCheckCallback(key);
    };

    return (
        <div className={styles.documentInfo}>
            <div className={styles.checkboxlabel}>
                {(versionViewMode && email === qcUser) && <Checkbox
                    checkedStatus={checked}
                    onChangeCallback={(e) => onCheckboxHandler(e, key)}
                    name={`documents_${key}`}
                    id={`documents_${key}`}
                    isDisabled={isDisabled}
                />
                }
                <label className={`${styles.lableText} ${isImportant ? styles.boldField : ""}`}>{text}</label>
                {/* <label>{prevValue}</label> */}
            </div>
            <div className={styles.peWrappper}>
                <div className={styles.dataWrapper}>
                    {
                        vaahanFlag && <Tooltip vaahanFlag={vaahanFlag} parivahanWebsite={parivahanWebsite} vahanWebsite={vahanWebsite} vahanSms={vahanSms} />
                    }
                    {
                        checked && isEditable ? getEditableComponent(key, document) : newValue ?
                            <label className={`${isImportant ? styles.boldField : ""}`}>{newValue}</label> :
                            <label className={`${isImportant ? styles.boldField : ""}`}>{value}</label>
                    }
                    {
                        newValue && !excludedFieldsForChangedTo.find(item => item === key) ? <Change value={value} errorMessage={errorMsg} /> : ""
                    }
                </div>
                {!!prevValue && <label className={styles.pe}>PE: {prevValue}</label>}
            </div>
        </div>
    );
};

export default InspectionDocumentsBike;

import { createReducer } from "reduxsauce";
import Types from "./types";
import { removeDuplicates, getUnique } from "../../utils/helper";

export const INITIAL_STATE = {
    isLoading: true,
    columns: [],
    page: 0,
    data: [],
    error: null,
    total: 0,
    initialCall: true

};

export const fetchInspections = (state = INITIAL_STATE) => {
    return { ...state, isLoading: true };
};

export const resetInspections = () => {
    return INITIAL_STATE;
};

export const fetchInspectionSuccess = (state = INITIAL_STATE, { data }) => {
    // const { detail, totalRecords } = data;
    let rowData = [...state.data, ...data];
    rowData = getUnique(rowData, "appointmentId");
    return { ...state, data: rowData, isLoading: false, total: rowData.length };
};

export const fetchAInspectionFailure = (state = INITIAL_STATE, { error }) => {
    return { ...state, error, data: [], isLoading: false };
};
export const resetInspectionByKey = (state = INITIAL_STATE, { data }) => {
    const { key, value } = data;
    return { ...state, [key]: value };
};
export const HANDLERS = {
    [Types.FETCH_BIKE_INSPECTIONS_LIST]: fetchInspections,
    [Types.RESET_BIKE_INSPECTIONS_LIST]: resetInspections,
    [Types.FETCH_BIKE_INSPECTIONS_LIST_SUCCESS]: fetchInspectionSuccess,
    [Types.FETCH_BIKE_INSPECTIONS_LIST_FAILURE]: fetchAInspectionFailure,
    [Types.RESET_BIKE_INSPECTION_BY_KEY]: resetInspectionByKey

};

export default createReducer(INITIAL_STATE, HANDLERS);

import { createReducer } from "reduxsauce";
import Types from "./types";

export const INITIAL_STATE = {
    defaultPositionX: 200,
    defaultPositionY: 100,
    defaultScale: 1
};

export const resetCarouselByKey = (state = INITIAL_STATE, { data }) => {
    const { key, value } = data;
    return { ...state, [key]: value };
};

export const HANDLERS = {
    [Types.RESET_CAROUSEL_BY_KEY]: resetCarouselByKey

};

export default createReducer(INITIAL_STATE, HANDLERS);

import React, {useEffect, useState} from "react";
import { Player } from 'video-react';
import "video-react/dist/video-react.css";
// import Header from "../../components/header";
// import Back from "../../components/back";
import styles from "./gs-refurbishment-review.module.css";
import  Button  from "../../components/shared/button";
import { InspectionService } from '../../service';
import Loader from '../../components/loader';
import { MONTHS_NAME, LOCAL_STORAGE_KEYS } from '../../utils/app-constants';
import { getFromLocalStorage, saveToLocalStorage } from '../../utils/helper';
import Carousel from "../../components/carousel";
import { ToastContainer, toast } from 'react-toastify';

const GsRefurbishmentReview = ({history, match}) => {
    const { params: { appointmentId } } = match;
    const urlParams = new URLSearchParams(window.location.search);
    const refurbishVersion = urlParams.get('version');
    const refurbishStatus = urlParams.get('status');
    const versionViewMode = refurbishVersion === null;
    const [refurbishmentDetails, setRefurbishmentDetails] = useState({});
    const [comments, setComments] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
    const [qcUser, setQcUser] = useState('');
    const [currentIndex, setCurrentIndex] = useState(0);
    const [videoHostUrl, setVideoHostUrl] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const {
        fields,
        make,
        model,
        serviceCenter,
        fuelType,
        month,
        year,
        variant,
        qcAssignTo = {},
        status,
        totalEstimatedRefurbishmentCost,
        totalActualRefurbishmentCost,
        gsReInspectionReason
    } = refurbishmentDetails;
    const statusObject = {
        before: 'review',
        after: 'refReview'
    };
    useEffect(() => {
        setIsLoading(true);
        if(versionViewMode) {
            InspectionService.getRefurbishmentDetails(appointmentId).then(response => {
                const { data: {detail} } = response;
                setRefurbishmentDetails(detail);
                setIsLoading(false);
            }).catch((error => {
                setIsLoading(false);
                console.log('error::',error);
            }));
        } else {
            InspectionService.getRefurbishmentVersionDetail({
                appointmentId:appointmentId.split('?')[0], 
                status: refurbishStatus, 
                version: refurbishVersion
            }).then(response => {
                const { data: {detail} } = response;
                setRefurbishmentDetails(detail);
                setIsLoading(false);
            }).catch((error => {
                setIsLoading(false);
                console.log('error::',error);
                setErrorMessage(error);
            }));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[appointmentId]);

    useEffect(() => {
        const email = getFromLocalStorage(LOCAL_STORAGE_KEYS.EMAIL);
        if(email) {
            setQcUser(email);
        }
    }, []);

    useEffect(()=> {
        const loginData = JSON.parse(getFromLocalStorage(LOCAL_STORAGE_KEYS.LOGIN_DATA));
        if(loginData) {
            saveToLocalStorage(LOCAL_STORAGE_KEYS.IMAGE_HOST_URL, loginData.imageHostUrl);
            saveToLocalStorage(LOCAL_STORAGE_KEYS.VIDEO_HOST_URL, loginData.videoHostUrl);
            setVideoHostUrl(loginData.videoHostUrl);
        }
    },[]);

    const goBackHandler = () => history.goBack();
    
    const onChangeHandler = (event, hashKey, index) => {
        const commentsObject = [...comments];
        if(commentsObject[index]) {
            const obj = {
                qcRemark: event.target.value,
            };
            commentsObject[index] = {...commentsObject[index],...obj};
        } else {
            const obj = {
                hashKey,
                qcRemark: event.target.value,
                isRejected: 0
            };
            commentsObject[index] = {...commentsObject[index],...obj};
        }
        setComments(commentsObject);
    };

    const rejectHandler = (hashKey, index) => {
        const commentsObject = [...comments];
        if(commentsObject[index]) {
            if(commentsObject[index].isRejected === 1) {
                if(!commentsObject[index].qcRemark) {
                    commentsObject.splice(index, 1);   
                } else {
                    commentsObject[index].isRejected = 0;
                }
            } else {
                commentsObject[index].isRejected = 1;
            }
        } else {
            const ob = {
                hashKey,
                qcRemark: '',
                isRejected: 1
            };
            commentsObject[index] = ob;
        }
        setComments(commentsObject);
    };

    const submitRequestData = () => {
        setIsLoadingSubmit(true);
        let isRejected = false;
        const fieldsObject = comments.filter(comment => {
            if( comment && (comment.isRejected || comment.qcRemark)) {
                if(comment.isRejected) {
                    isRejected = true;
                }
                return true;
            }
        });
        const requestObject = {
            appointmentId,
            fields: fieldsObject,
            reviewBy: qcUser,
            status: (isRejected && (status === "review" ? "rejected" : "refRejected")) || (status === 'review' ? 'inRef' : 'done'),
            section: status === 'review' ? 'beforeRef' : 'afterRef',
        };
        InspectionService.submitRefurbishmentData(requestObject, appointmentId).then(response => {
            setIsLoadingSubmit(false);
            const { data: {detail} } = response;
            toast.success(detail);
            setTimeout(() => {
                history.push('/gs-refurbishment');
            }, 1000);
        }).catch(error => {
            console.log('Error::',error);
            setIsLoadingSubmit(false);
            toast.error('Error::'+error.detail);
        });
        
    };

    const isValidUser = qcAssignTo.to === qcUser;

    const videoSection = ({title, url}, index) => {
        return (
            <div className={styles.videoSection} key={index}>
                <p className={styles.videoText}>{title}</p>
                <Player 
                    src={videoHostUrl+'/'+url}
                />
            </div>
        );
    };

    return (
        <div className={styles.gsRefurbishment}>
            {isLoading && <Loader />}
            <div className={styles.fixedHeader}>
                <div className={styles.gsRefurbishmentHeader}>
                    <p className={styles.goBack} onClick={goBackHandler}></p>
                    <p className={styles.aapId}>{appointmentId.split('?')[0]}</p>
                    <p>GS Refurbishment Suggestions {gsReInspectionReason && `(${gsReInspectionReason})`}</p>
                </div>
                {!isLoading && <div className={styles.inspectionReviewInfo}>
                    <div className={styles.inspectionInfoWrapper}>
                        <p className={styles.inspectionInfo}>
                            <span>{make}</span>
                            <span className={styles.variantBold}>{model}</span>
                            <span>{year}</span>
                            <span>{MONTHS_NAME[month]}</span>
                            <span className={styles.variantBold}>{variant}</span>
                            <span className={styles.variantBold}>{fuelType}</span>
                        </p>
                        <p className={styles.scrap}>{serviceCenter}</p>
                    </div>
                </div>}
            </div>
            { !isLoading &&
                <>
                    <div className={styles.marginWrapper}>
                        {
                            fields && fields.length && fields.map((fieldItem, index) => (
                                <div className={styles.refurbishmentMainWrapper} key={fieldItem.hashKey}>
                                    <p className={styles.headingText}><span>{fieldItem.category}:</span> {fieldItem.subCategory}</p>
                                    <div className={styles.beforeAfterWrapper}>
                                        <div className={styles.beforeWrapper}>
                                            <h3>Before Refurbishment</h3>
                                            <div className={styles.imageSection}>
                                                {fieldItem.beforeRef && fieldItem.beforeRef.image &&
                                                 <Carousel
                                                     setCurrentIndex={setCurrentIndex}
                                                     height="630"
                                                     images={fieldItem.beforeRef.image}
                                                     refurbishment={true}
                                                 />}
                                            </div>
                                            <div className={styles.videosWrapper}>
                                                {fieldItem.beforeRef && fieldItem.beforeRef.video && (
                                                    fieldItem.beforeRef.video.map((item, index) => videoSection(item, index))
                                                )}
                                            </div>
                                            <div className={styles.prevComments}>
                                                <h4>Comments:</h4>
                                                <p>{fieldItem.beforeRef ? fieldItem.beforeRef.observation : 'NA'}</p>
                                            </div>
                                        </div>
                                        <div className={styles.afterWrapper}>
                                            <h3>After Refurbishment</h3>
                                            {!fieldItem.afterRef && 
                                            <p className={styles.refurbishImageTitle}>I</p>}
                                            <div className={styles.imageSection}>
                                                {fieldItem.afterRef && fieldItem.afterRef.image &&
                                                <Carousel
                                                    setCurrentIndex={setCurrentIndex}
                                                    height="630"
                                                    images={fieldItem.afterRef.image}
                                                    refurbishment={true}
                                                />}
                                            </div>
                                            <div className={styles.videosWrapper}>
                                                {fieldItem.afterRef && fieldItem.afterRef.video && (
                                                    fieldItem.afterRef.video.map((item, index) => videoSection(item, index))
                                                )}
                                            </div>
                                            <div className={styles.prevComments}>
                                                <h4>Comments:</h4>
                                                <p>{fieldItem.afterRef ? fieldItem.afterRef.observation : 'NA'}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={styles.commentsBefore}>
                                        <div className={styles.beforeAfterWrapper}>
                                            <div className={styles.beforeWrapper}>
                                                <div className={styles.estimatedCost}>
                                                    <h4>Estimated Cost:</h4>
                                                    <p>{fieldItem.beforeRef && fieldItem.beforeRef.estimatedRefurbishmentCost || 'NA'}</p>
                                                </div>
                                                <div className={styles.afterComments}>
                                                    <h4>QC Comments before refurbishment:</h4>
                                                    <div className={styles.actionWrapper}>
                                                        { status === statusObject.before && (
                                                            <input 
                                                                type="text" 
                                                                disabled={!isValidUser || !versionViewMode}
                                                                value={comments[index] ? comments[index].qcRemark : ''}
                                                                onChange={(event) => onChangeHandler(event, fieldItem.hashKey, index)} 
                                                            />
                                                        )}
                                                        { versionViewMode && status === statusObject.before && (
                                                            <button  
                                                                disabled={!isValidUser} 
                                                                onClick={() => rejectHandler(fieldItem.hashKey, index)}
                                                                className={comments[index] && comments[index].isRejected ? styles.selectRejectButton : styles.rejectButton}
                                                            >
                                                                Reject
                                                            </button>
                                                        )}
                                                        {status === statusObject.after && (
                                                            <p>{fieldItem.beforeRef && fieldItem.beforeRef.qcRemark}</p>
                                                        ) }
                                                    </div>
                                                </div>
                                            </div>
                                            { status === statusObject.after && <div className={styles.afterWrapper}>
                                                <div className={styles.estimatedCost}>
                                                    <h4>Actual Cost:</h4>
                                                    <p>{fieldItem.afterRef && fieldItem.afterRef.actualRefurbishmentCost || 'NA'}</p>
                                                </div>
                                                <div className={styles.afterComments}>
                                                    <h4>QC Comments after refurbishment:</h4>
                                                    <div className={styles.actionWrapper}>
                                                        <input 
                                                            type="text" 
                                                            disabled={!isValidUser || !versionViewMode}
                                                            value={comments[index] ? comments[index].qcRemark : ''} 
                                                            onChange={(event) => onChangeHandler(event, fieldItem.hashKey, index)} 
                                                        />
                                                        {versionViewMode && (
                                                            <button  
                                                                disabled={!isValidUser} 
                                                                onClick={() => rejectHandler(fieldItem.hashKey, index)}
                                                                className={comments[index] && comments[index].isRejected ? styles.selectRejectButton : styles.rejectButton}
                                                            >
                                                            Reject
                                                            </button>)}
                                                    </div>
                                                </div>
                                            </div>}
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                        
                        {(totalActualRefurbishmentCost || totalEstimatedRefurbishmentCost) && (<div className={styles.totalCostWrapper}>
                            <div className={styles.totalCost}>
                                <h4>Total Estimated Refurbishment Cost:</h4>
                                <p>{totalEstimatedRefurbishmentCost || 'NA'}</p>
                            </div>
                            <div className={styles.totalCost}>
                                <h4>Total Actual Refurbishment Cost:</h4>
                                <p>{totalActualRefurbishmentCost || 'NA'}</p>
                            </div>
                        </div>)}
                    </div>
                    { versionViewMode && isValidUser && (
                        <div className={styles.ctaWrapper}>
                            <Button 
                                disabled={isLoadingSubmit} 
                                ctaText="SUBMIT" 
                                classNames="blueCta" 
                                onClick={submitRequestData} 
                            />
                        </div>)}
                </>
            }
            {errorMessage && <span className={styles.errorMessage}>{errorMessage}</span>}
            <div className={styles.loaderWrapper}>
                {isLoadingSubmit && <Loader />}
            </div>
            <ToastContainer />
        </div>
    );
};

export default GsRefurbishmentReview;

import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CSVFileValidator from 'csv-file-validator'
import Upload from '../../components/icons/Upload';
import Button from '../../components/shared/button/component';
import { uploadXFactor, showToast } from "./actions";
import { FILTER_IDS } from './constants';

const CSVConfig = {
  headers: [
    { name: 'CENTRE_ID', inputName: 'CENTRE_ID', required: true },
    { name: 'DATE', inputName: 'DATE', required: true },
    { name: 'DAY', inputName: 'DAY', required: true },
    { name: 'SLOT', inputName: 'SLOT', required: true },
    { name: 'FACTOR', inputName: 'FACTOR', required: true },
    { name: 'START_DATE', inputName: 'START_DATE', required: false },
    { name: 'END_DATE', inputName: 'END_DATE', required: false }
  ]
}

const UploadXFactor = () => {
  const dispatch = useDispatch();
  const $uploader = useRef();
  const { filters } = useSelector(state => state.xFactorReducer);

  const onUploadClick = () => {
    $uploader.current.click();
  }

  const handleInputChange = (e) => {
    const file = e.target.files[0];

    CSVFileValidator(file, CSVConfig)
    .then(csvData => {
      if(!csvData.inValidData.length) {
        let params = {
          [FILTER_IDS.STORE_ID]: filters[FILTER_IDS.STORE_ID].value.value ?? '',
          [FILTER_IDS.DATE]: filters[FILTER_IDS.DATE].value ?? '',
          [FILTER_IDS.SLOT]: filters[FILTER_IDS.SLOT].value.value ?? ''
      };
        dispatch(uploadXFactor(file, params));
      } else {
        dispatch(showToast('error', csvData.inValidData[0].message))
      }
    })
    .finally(() => {
      $uploader.current.value = "";
      dispatch(showToast('error', ""));
    })
  }

  return (
    <Button
        classNames="ml-10 primaryButton cursor-pointer"
        onClick={onUploadClick}
    >
        <Upload width="20" />
        <input
          ref={$uploader}
          type="file"
          onChange={handleInputChange}
          accept="text/csv"
          style={{ display: "none" }}
        />
    </Button>
  )
}

export default UploadXFactor
import React from "react";
import Back from "../images/back-button.svg";
import BackBlue from "../images/backButton.svg";
import styles from "./inspection-header-back.module.css";

const InspectionHeaderBack = ({ history, isBikeQcRole, versionFlag = false }) => {
    const urlParams = new URLSearchParams(window.location.search);
    const bikeVersion = urlParams.get('version');
    const redirectToInspection = () => {
        if (versionFlag || bikeVersion !== null) {
            history.goBack();
        }
        else {
            if (isBikeQcRole) {
                history.push("/inspection-bike");
            }
            else {
                history.push("/inspection");
            }
        }
    };
    return (
        <img className={styles.back} src={versionFlag ? BackBlue : Back} alt="back" onClick={redirectToInspection} />
    );
};

export default InspectionHeaderBack;

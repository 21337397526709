import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import InspectionBikeVersion from './component';

const mapStateToProps = ({}) => ({});

const mapDispatchToProps = ({}) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(InspectionBikeVersion);


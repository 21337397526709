import React, { useEffect } from "react";
import ImageCheckbox from "../../components/image-checkbox";
import styles from "./inspection-review-interior.module.css";
import ImageWithZoom from "../image-with-zoom";
import InspectionInterior from "../inspection-interior";
import OdometerReading from "../odometer-reading";
import InspectionDocuments from "../inspection-documents";
import InspectionDocumentsOdometer from "../inspection-documents-odometer";
import Exclamation from "../images/exclamation.svg";
import InspectionOdometerComments from "../inspection-odometer-comments";
import Checkbox from "../shared/checkbox";
import InfoIcon from "../shared/info-icon";
import { InspectionService } from "../../service";
import InspectionCarDetailsComments from "../inspection-car-details-comments";
import { getFieldRemarkHistory } from "../../utils/helper";
import { INSPECTION_SECTION } from "../../utils/app-constants";

const InspectionReviewInterior = ({
    inspectionDetails,
    resetInspectionDetailByKeyConnect
}) => {

    const {
        interiorImagesMapper,
        interiorDataMapper,
        externalDataMapper,
        airConditioningDataMapper,
        interiorOdometerMapper,
        odometerComments,
        interiorElecctricalsMapper
    } = inspectionDetails;

    // const onCheckboxHandler = () => {
    //     odometerComments.checked = !odometerComments.checked;
    //     resetInspectionDetailByKeyConnect("odometerComments", odometerComments);
    // };

    const checkIfExists = (id) => {
        if (!!odometerComments && odometerComments.length > 0) {
            return !!odometerComments.find(item => item.id == id);
        } else {
            return false;
        }
    };

    useEffect(() => {
        InspectionService.getComments("electricalsInterior")
            .then(res => {
                let { data: { detail } } = res;
                if (detail) {
                    detail = detail || [];
                    detail = !!detail ? detail.filter(x => x.status === "Active") : [];
                    let mapper = detail.map(function (el, index) {
                        const o = Object.assign({}, el);
                        o.checked = checkIfExists(o.id);
                        o.key = o.comment;
                        o.text = o.comment;
                        o.isVisible = true;
                        o.isEditable = false;
                        o.isImportant = checkIfExists(o.id);
                        o.remarkHistory = getFieldRemarkHistory(o.comment, inspectionDetails.electricalsInterior?.errors?.comments?.subParts)
                        return o;
                    });
                    resetInspectionDetailByKeyConnect("odometerComments", mapper);
                }

            })
            .catch(e => {

            });
        // // eslint-disable-next-line react-hooks/exhaustive-deps
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={styles.inspectionReviewInterior}>
            <h3>Interior</h3>
            <h4>Main Images</h4>
            <div className={styles.inspectionReviewInteriorWrapper}>
                {
                    interiorImagesMapper.map(item => {
                        if (!item.title.match(/odometer/i)) {
                            return <div className={styles.interiorImageWrapper}>
                                <ImageWithZoom url={item.url} index={item.index} alt={item.alt} height={513} />
                                <ImageCheckbox currentMapper={"interiorImagesMapper"} index={item.index} section={INSPECTION_SECTION.ELECTRICALS_INTERIOR} />
                            </div>;
                        }
                    })

                }
                {
                    interiorImagesMapper.map(item => {
                        if (item.title.match(/odometer/i)) {
                            return <div className={styles.interiorImageWrapper}>
                                <ImageWithZoom url={item.url} index={item.index} alt={item.alt} height={513} />
                                <ImageCheckbox currentMapper={"interiorImagesMapper"} index={item.index} section={INSPECTION_SECTION.ELECTRICALS_INTERIOR} />
                                {
                                    interiorOdometerMapper.map(item => {
                                        return <InspectionDocumentsOdometer document={item} currentMapper={odometerComments} />;
                                    })
                                }
                                {/*{*/}
                                {/*    odometerComments.map(item=>{*/}
                                {/*        return <div className={styles.greyStrip}>*/}
                                {/*            <img src={Exclamation} alt="exclamation"/>*/}
                                {/*            <p key={item.id}>{item.comment}</p>*/}
                                {/*        </div>;*/}
                                {/*    })*/}
                                {/*}*/}
                            </div>;
                        }
                    })

                }

                <div className={styles.interiorImageWrapper}>
                    <div className={styles.interiorTesting}>
                        {
                            interiorElecctricalsMapper.length > 0 && interiorElecctricalsMapper.map(document => {
                                if (document.text && document.isVisible) {
                                    return <>
                                        <div className={styles.checkboxContent}>
                                            <InspectionInterior
                                                document={document}
                                                currentMapper={"interiorElecctricalsMapper"}
                                                section={INSPECTION_SECTION.ELECTRICALS_INTERIOR}
                                            />
                                        </div>
                                        <p className={styles.yes}>{!!document.currentCondition ? document.currentCondition : ""}</p>
                                    </>;
                                }
                            })
                        }
                        {
                            <div className={styles.exteriorTextList}>
                                <div className={styles.exteriorText}>
                                    <p className={styles.text}>Comments: Interior </p>
                                    {
                                        odometerComments && odometerComments.length > 0 && odometerComments.map(detail => {
                                            return <InspectionCarDetailsComments
                                                document={detail} currentMapper={odometerComments} mapperName={"odometerComments"}
                                            />;
                                        })

                                    }
                                </div>
                            </div>
                        }
                    </div>
                </div>

                <div className={styles.interiorContentWrapper}>
                    <ul>
                        {
                            externalDataMapper.length > 0 && externalDataMapper.map(document => {
                                if (document.text && document.isVisible) {
                                    return <li>
                                        <div className={styles.checkboxContent}>
                                            <InspectionInterior
                                                document={document}
                                                currentMapper={"externalDataMapper"}
                                                section={INSPECTION_SECTION.EXTERIOR_TYRES}
                                            />
                                        </div>
                                        {/*<p className={styles.yes}>{item.value}</p>*/}
                                    </li>;
                                }
                            })
                        }
                        {
                            interiorDataMapper.length > 0 && interiorDataMapper.map(document => {
                                if (document.text && document.isVisible) {
                                    return <li>
                                        <div className={styles.checkboxContent}>
                                            <InspectionInterior
                                                document={document}
                                                currentMapper={"interiorDataMapper"}
                                                section={INSPECTION_SECTION.ELECTRICALS_INTERIOR}
                                            />
                                        </div>
                                        {/*<p className={styles.yes}>{item.value}</p>*/}
                                    </li>;
                                }
                            })
                        }
                        {
                            airConditioningDataMapper.length > 0 && airConditioningDataMapper.map(document => {
                                if (document.text && document.isVisible) {
                                    return <li>
                                        <div className={styles.checkboxContent}>
                                            <InspectionInterior
                                                document={document}
                                                currentMapper={"airConditioningDataMapper"}
                                                section={INSPECTION_SECTION.AIR_CONDITIONING}
                                            />
                                        </div>
                                        {/*<p className={styles.yes}>{item.value}</p>*/}
                                    </li>;
                                }
                            })
                        }

                    </ul>
                    {/*{*/}
                    {/*    interiorImagesMapper && interiorImagesMapper[0] &&*/}
                    {/*    <div className={styles.interiorImageWrapper}>*/}
                    {/*        <ImageWithZoom url={interiorImagesMapper[0].url} index={interiorImagesMapper[0].index}*/}
                    {/*            alt={interiorImagesMapper[0].alt}/>*/}
                    {/*        <ImageCheckbox currentMapper={"interiorImagesMapper"}*/}
                    {/*            index={interiorImagesMapper[0].index}/>*/}
                    {/*    </div>*/}
                    {/*}*/}
                </div>
            </div>

        </div>
    );
};

export default InspectionReviewInterior;

import { createReducer } from "reduxsauce";
import Types from "./types";
import { removeDuplicates } from "../../utils/helper";

export const INITIAL_STATE = {
    isLoading: true,
    columns: [],
    page: 0,
    data: [],
    error: null,
    total: 0,
    appointmentId:null

};

export const fetchInspectionsVersion = (state = INITIAL_STATE) => {
    return { ...state, isLoading: true };
};

export const resetInspections = () => {
    return INITIAL_STATE;
};

export const fetchInspectionSuccess = (state = INITIAL_STATE, { data }) => {
    // data: [...state.data, ...result]
    const { detail, totalRecords } = data;
    // let rowData = [...state.data, ...detail];
    // rowData = removeDuplicates(rowData, "appointmentId");
    return { ...state, data: detail, total: totalRecords, isLoading: false };
};

export const fetchAInspectionFailure = (state = INITIAL_STATE, { error }) => {
    return { ...state, error, data: [], isLoading: false };
};
export const resetInspectionVersionByKey = (state = INITIAL_STATE, { data }) => {
    const { key, value } = data;
    return { ...state, [key]: value };
};
export const HANDLERS = {
    [Types.FETCH_INSPECTIONS_VERSION_LIST]: fetchInspectionsVersion,
    [Types.RESET_INSPECTIONS_VERSION_LIST]: resetInspections,
    [Types.FETCH_INSPECTIONS_VERSION_LIST_SUCCESS]: fetchInspectionSuccess,
    [Types.FETCH_INSPECTIONS_VERSION_LIST_FAILURE]: fetchAInspectionFailure,
    [Types.RESET_INSPECTIONS_VERSION_BY_KEY]: resetInspectionVersionByKey

};

export default createReducer(INITIAL_STATE, HANDLERS);

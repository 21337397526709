import React from "react";
import PropTypes from "prop-types";
import styles from "./checkbox.module.css";
import { isAssignedToMe } from "../../../utils/helper";
import {INSPECTION_STATUS_KEYS} from '../../../utils/app-constants';
const Checkbox = (props) => {
    
    const {
        checkedStatus = false,
        id,
        name,
        onClickCallback = () => { },
        onChangeCallback = () => { },
        inspectionDetails,
        isDisabled,
        defaultChecked = false,
        document={}
    } = props || {};
    const {key=''} = document || {};
    const onClickHandler = (e) => {
        onClickCallback(e);
    };

    const onChangeHandler = (e) => {
        onChangeCallback(e);
    };

    const { qcAssign, selectedVersion,isDocumentPopup, inspectionStatus } = inspectionDetails;
    const currentUser = qcAssign ? qcAssign.to : "";
    let isKeyEditable = false;
    if (inspectionStatus === INSPECTION_STATUS_KEYS.DONE && !isDocumentPopup) {
        isKeyEditable = isAssignedToMe(currentUser);
    } else if (inspectionStatus === INSPECTION_STATUS_KEYS.SM_REVIEW) {
        isKeyEditable = isAssignedToMe(currentUser) && !(selectedVersion > -1 || selectedVersion === "current");
    }
    return (
        <>
            {isKeyEditable 
                 &&
                <div className={styles.checkboxHolder}>
                    <input
                        onClick={onClickHandler}
                        onChange={onChangeHandler}
                        type="checkbox"
                        id={id}
                        defaultChecked={defaultChecked}
                        name={name}
                        checked={checkedStatus}
                        disabled={!isKeyEditable || isDisabled}
                    />
                    <label htmlFor={id} />
                </div>
            }
        </>
    );
};

Checkbox.propTypes = {
    onClickCallback: PropTypes.func,
    checkedStatus: PropTypes.bool
};

export default Checkbox;


import React, { useState } from "react";
import H3 from "../../components/h3";
import ImageCheckbox from "../../components/image-checkbox/";
import Tyres from "../../components/images/lhsfronttyreadditio.jpg";
import styles from "./inspection-review-tyres.module.css";
import ImageWithZoom from "../image-with-zoom";
import { INSPECTION_SECTION } from "../../utils/app-constants";

const InspectionReviewTyres = ({ inspectionDetails }) => {
    const {
        lhsFrontTyremapper,
        rhsFrontTyremapper,
        lhsRearTyremapper,
        spareTypremapper,
        rhsRearTyremapper
    } = inspectionDetails;

    const height = 198;
    return (
        <div className={styles.tyresWrapper}>
            <H3 text={"Tyres"} />
            <div className={styles.tyresList}>

                {
                    lhsFrontTyremapper.map(item => {
                        return <div className={styles.tyresListItem}>
                            {/* <ImageWithZoom url={item.url} alt={item.url} index={item.index} zoomEnabled={false} height={height} editImage={true} mapper={lhsFrontTyremapper} currentMapper={"lhsFrontTyremapper"} /> */}
                            <ImageWithZoom url={item.url} alt={item.url} index={item.index} zoomEnabled={false} height={height} currentMapper={"lhsFrontTyremapper"} enlargeImage={true} />
                            <ImageCheckbox currentMapper={"lhsFrontTyremapper"} index={item.index} treadValue={item.treadDepth} section={INSPECTION_SECTION.EXTERIOR_TYRES} />
                        </div>;
                    })
                }
                {
                    rhsFrontTyremapper.map(item => {
                        return <div className={styles.tyresListItem}>
                            {/* <ImageWithZoom url={item.url} alt={item.url} index={item.index} zoomEnabled={false} height={height} editImage={true} mapper={rhsFrontTyremapper} currentMapper={"rhsFrontTyremapper"} /> */}
                            <ImageWithZoom url={item.url} alt={item.url} index={item.index} zoomEnabled={false} height={height} currentMapper={"rhsFrontTyremapper"} enlargeImage={true} />
                            <ImageCheckbox currentMapper={"rhsFrontTyremapper"} index={item.index} treadValue={item.treadDepth} section={INSPECTION_SECTION.EXTERIOR_TYRES} />
                        </div>;
                    })
                }
                {
                    lhsRearTyremapper.map(item => {
                        return <div className={styles.tyresListItem}>
                            {/* <ImageWithZoom url={item.url} alt={item.url} index={item.index} zoomEnabled={false} height={height} editImage={true} mapper={lhsRearTyremapper} currentMapper={"lhsRearTyremapper"} /> */}
                            <ImageWithZoom url={item.url} alt={item.url} index={item.index} zoomEnabled={false} height={height} currentMapper={"lhsRearTyremapper"} enlargeImage={true} />
                            <ImageCheckbox currentMapper={"lhsRearTyremapper"} index={item.index} treadValue={item.treadDepth} section={INSPECTION_SECTION.EXTERIOR_TYRES} />
                        </div>;
                    })
                }
                {
                    spareTypremapper.map(item => {
                        return <div className={styles.tyresListItem}>
                            {/* <ImageWithZoom url={item.url} alt={item.url} index={item.index} zoomEnabled={false} height={height} editImage={true} mapper={spareTypremapper} currentMapper={"spareTypremapper"} /> */}
                            <ImageWithZoom url={item.url} alt={item.url} index={item.index} zoomEnabled={false} height={height} currentMapper={"spareTypremapper"} enlargeImage={true} />
                            <ImageCheckbox currentMapper={"spareTypremapper"} index={item.index} treadValue={item.treadDepth} section={INSPECTION_SECTION.EXTERIOR_TYRES}/>
                        </div>;
                    })
                }
                {
                    rhsRearTyremapper.map(item => {
                        return <div className={styles.tyresListItem}>
                            {/* <ImageWithZoom url={item.url} alt={item.url} index={item.index} zoomEnabled={false} height={height} editImage={true} mapper={rhsRearTyremapper} currentMapper={"rhsRearTyremapper"} /> */}
                            <ImageWithZoom url={item.url} alt={item.url} index={item.index} zoomEnabled={false} height={height} currentMapper={"spareTypremapper"} enlargeImage={true} />
                            <ImageCheckbox currentMapper={"rhsRearTyremapper"} index={item.index} treadValue={item.treadDepth} section={INSPECTION_SECTION.EXTERIOR_TYRES}/>
                        </div>;
                    })
                }

            </div>
        </div>
    );
};

export default InspectionReviewTyres;

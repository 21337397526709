/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import Carousel from '../../components/carousel';
import ImageCheckbox from '../../components/image-checkbox';
import InspectionReviewDocuments from '../../components/inspection-review-documents';
import { INSPECTION_SECTION } from '../../utils/app-constants';
import styles from './inspection-review-rc.module.css';

const InspectionReviewRc = ({
  inspectionDetails,
  resetInspectionDetailByKeyConnect,
  fetchInspectionRestrictImagesConnect,
}) => {
  const { rcImagesMapper, carId } = inspectionDetails;
  const [currentIndex, setCurrentIndex] = useState(0);
  const [rcImageCount, setrcImageCount] = useState(0);
  const [swipeIndex, setSwipeIndex] = useState(0);
  const [additionalImageCount, setAdditionalImageCount] = useState(0);
  useEffect(() => {
    if (!!rcImagesMapper && rcImagesMapper.length > 0) {
      let rcImagesCount = rcImagesMapper.filter(
        x => x.imageType === 'rc' && x.url !== '',
      );
      if (!!rcImagesCount && rcImagesCount.length > 0) {
        setrcImageCount(rcImagesCount.length);
      }
      rcImagesCount = rcImagesMapper.filter(
        x => x.imageType === 'addnl' && x.url !== '',
      );
      if (!!rcImagesCount && rcImagesCount.length > 0) {
        setAdditionalImageCount(rcImagesCount.length);
      }
    }
  }, []);
  const onFetchingRestrictImages = () => {
    fetchInspectionRestrictImagesConnect(carId);
  };
  return (
    <div>
      {
        <div className={styles.reviewWrapper}>
          <div className={styles.carouselTextWrapper}>
            <div className={styles.carousel}>
              {/* <div className={styles.additionalWrapper}>
                                <p className={styles.rc}>rc - <span>{rcImageCount}</span></p>
                                <p onClick={setSwipeIndexForAdditional} className={styles.additional}>additional - <span>{additionalImageCount}</span></p>
                            </div> */}
              <Carousel
                images={rcImagesMapper}
                key="documents"
                setCurrentIndex={setCurrentIndex}
                height={630}
                wheelZoomDisabled={false}
                swipeIndex={swipeIndex}
                currentMapper="rcImagesMapper"
                rcImageCount={rcImageCount}
                additionalImageCount={additionalImageCount}
                fetchRestrictImagesHandler={onFetchingRestrictImages}
              />
              <ImageCheckbox
                index={currentIndex}
                currentMapper="rcImagesMapper"
                section={INSPECTION_SECTION.CAR_DETAILS}
              />
            </div>
            <InspectionReviewDocuments />
          </div>
        </div>
      }
    </div>
  );
};

export default InspectionReviewRc;

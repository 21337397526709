import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from 'react-toastify';
import styles from './x-factor.module.css';
import { createTimeRange, getFromLocalStorage, showToastMessages } from "../../utils/helper";
import { fetchXFactorList, updateFilter } from "./actions";
import Loader from "../../components/loader/component";
import Header from "../../components/header/component";
import Button from "../../components/shared/button/component";
import Popup from "../../components/popup/component";
import { Table } from '../../components/shared/table/Table';
import UpdateXFactor from "./UpdateXFactor";
import Filters from "../../components/shared/filters";
import { FILTER_IDS } from "./constants";
import XFactorDataTransfer from "./XFactorDataTransfer";

const SlotXFactorCell = ({ cell }) => {
    const { slotXFactor } = cell.row.original;
    return Object.keys(slotXFactor).map(slot => <p key={slot}>{slot} - {slotXFactor[slot]}</p>);
}

const XFactor = ({ history }) => {
    const dispatch = useDispatch();
    const {
        data = [],
        isLoading,
        addUpdateSuccess,
        toast,
        filters
    } = useSelector(state => state.xFactorReducer);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [rowDataToUpdate, setRowDataToUpdate] = useState({});

    const columns = useMemo(() => [
        {
            Header: "Store Id",
            accessor: "storeId"
        },
        {
            Header: "Store Name",
            accessor: "storeName"
        },
        {
            Header: "Date",
            accessor: "date"
        },
        {
            Header: "Slot - X factor",
            Cell: SlotXFactorCell
        },
        {
            Header: "Updated By",
            accessor: "updatedBy",
            noDownload: true
        },
        {
            Header: "Updated At",
            accessor: "updatedAt",
            noDownload: true
        },
        {
            Header: "Action",
            Cell: ({ cell }) => <Button classNames="assignCta" ctaText="Update" onClick={() => handleUpdateBtnClick(cell.row.original)} />,
            noDownload: true
        },
    ], []);

    const _data = useMemo(() => data, [data]);

    const fetchXFactor = () => {
        if (!filters[FILTER_IDS.STORE_ID].value) return;

        let params = {
            [FILTER_IDS.STORE_ID]: filters[FILTER_IDS.STORE_ID].value.value ?? '',
            [FILTER_IDS.DATE]: filters[FILTER_IDS.DATE].value ?? '',
            [FILTER_IDS.SLOT]: filters[FILTER_IDS.SLOT].value.value ?? ''
        };
        dispatch(fetchXFactorList(params));
    }

    useEffect(() => {
        fetchXFactor();
    }, [filters[FILTER_IDS.STORE_ID].value, filters[FILTER_IDS.DATE].value, filters[FILTER_IDS.SLOT].value]);

    useEffect(() => {
        if (addUpdateSuccess) {
            handleModalClose();
            fetchXFactor();
        }
    }, [addUpdateSuccess]);

    useEffect(() => {
        if (toast.msg.length) {
            showToastMessages(toast.msg, toast.type === "success");
        }
    }, [toast.msg]);

    useEffect(() => {
        //Populate store name filter options
        if (filters[FILTER_IDS.STORE_ID].options.length) return;

        const STORES_BY_CITY = JSON.parse(getFromLocalStorage("locationStore") || "[]");
        const STORE_TYPE = ["locality", "sell_online"];

        const allStores = STORES_BY_CITY.reduce((prev, { locations }) => {
            prev.push(...locations);
            return prev;
        }, []);
        const filteredStores = allStores.filter(({ type }) => STORE_TYPE.includes(type));
        const options = filteredStores.map(({ name, location_id, startTime, endTime }) => ({
            label: name,
            value: location_id,
            startTime,
            endTime
        }));
        //select first store by default
        dispatch(updateFilter(FILTER_IDS.STORE_ID, { options, value: options[0] }));
    }, []);

    useEffect(() => {
        //Populate the selected store's slot options
        if (!filters[FILTER_IDS.STORE_ID].value) return;

        const { startTime, endTime } = filters[FILTER_IDS.STORE_ID].value;
        const slotRange = createTimeRange(parseInt(startTime.split(":")[0]), parseInt(endTime.split(":")[0]));
        const options = slotRange.map(slot => ({ label: slot, value: slot }));

        dispatch(updateFilter(FILTER_IDS.SLOT, { options }));
    }, [filters[FILTER_IDS.STORE_ID].value]);

    const handleUpdateBtnClick = (clickedData) => {
        setRowDataToUpdate(clickedData);
        setIsModalOpen(true);
    };

    const handleModalClose = () => {
        setIsModalOpen(false);
    };

    const onFilterChange = (id, value) => {
        if (id === FILTER_IDS.STORE_ID) {
            dispatch(updateFilter(FILTER_IDS.SLOT, { value: '' }));
        }
        dispatch(updateFilter(id, { value }));
    }

    const filterChangeHandlers = {
        [FILTER_IDS.STORE_ID]: onFilterChange.bind(null, FILTER_IDS.STORE_ID),
        [FILTER_IDS.DATE]: onFilterChange.bind(null, FILTER_IDS.DATE),
        [FILTER_IDS.SLOT]: onFilterChange.bind(null, FILTER_IDS.SLOT)
    };

    return (
        <>
            {isLoading && <Loader />}

            <Header history={history} showPerformance={false} />

            <Filters
                filterConfig={filters}
                changeHandlers={filterChangeHandlers}
                CustomComponent={<XFactorDataTransfer />}
            />

            <div className={styles.container}>
                {_data.length > 0 ? (
                    <Table id="xFactorList" columns={columns} data={_data} />
                ) :
                    <p className="text-center">No data found!</p>
                }
            </div>

            <Popup isOpen={isModalOpen} close={handleModalClose}>
                <UpdateXFactor
                    id={rowDataToUpdate.id}
                    date={rowDataToUpdate.date}
                    storeId={rowDataToUpdate.storeId}
                    storeName={rowDataToUpdate.storeName}
                    slotXFactor={rowDataToUpdate.slotXFactor}
                />
            </Popup>

            <ToastContainer />

        </>
    );
};

export default XFactor;
import { API_ENDPOINTS } from './api-constants';
export default api => {
  const login = params => {
    return api.post(`${API_ENDPOINTS.LOGIN}?utm_source=qc`, params);
  };

  const googleLogin = params => {
    return api.post(`${API_ENDPOINTS.GOOGLE_LOGIN}?utm_source=qc`, params);
  };

  const getUserDetails = token => {
    const params = { Authorization: `Bearer ${token}` };
    return new Promise((resolve, reject) => {
      api
        .get(`${API_ENDPOINTS.USER_LOGIN_DETAILS}?utm_source=qc`, {
          headers: params,
        })
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  };

  return {
    login,
    googleLogin,
    getUserDetails,
  };
};

/* eslint-disable indent */
import React, { useEffect, useState } from "react";
import { GetQueryString } from "../../utils/helper";
import { thumborUrl } from "../../utils/url-constants";
import { getFromLocalStorage } from "../../utils/helper";
import { LOCAL_STORAGE_KEYS } from "../../utils/app-constants";

const ViewImage = ({
  match,
  inspectionDetails
}) => {
  const cdnBaseUrl = !!getFromLocalStorage(LOCAL_STORAGE_KEYS.IMAGE_HOST_URL) ? getFromLocalStorage(LOCAL_STORAGE_KEYS.IMAGE_HOST_URL) : thumborUrl();
  // const { imagequalityParam } = !!getFromLocalStorage(LOCAL_STORAGE_KEYS.IMAGE_QUALITY_PARAM) ? getFromLocalStorage(LOCAL_STORAGE_KEYS.IMAGE_QUALITY_PARAM) : ""; //inspectionDetails;
  const imagequalityParam = !!getFromLocalStorage(LOCAL_STORAGE_KEYS.IMAGE_QUALITY_PARAM) ? getFromLocalStorage(LOCAL_STORAGE_KEYS.IMAGE_QUALITY_PARAM) : ""; //inspectionDetails;

  const { params: { index } } = match;
  let url = decodeURI(GetQueryString("url"));
  const [imageUrl, setImageUrl] = useState(url);
  return (
    <div>
      <img key={index}
        src={`${cdnBaseUrl}${imageUrl}${imagequalityParam}`}
      />
    </div>
  );
};

export default ViewImage;

import React, { useEffect } from "react";
import { Multiselect } from 'multiselect-react-dropdown';
import { CHASSIS_EMBOSSING, RESET_REDUCER_KEYS } from "../../utils/app-constants";
import { isUserRoleBIkeQc } from "../../utils/helper";

const ChassisNoEmbossing = ({
    resetInspectionDetailByKeyConnect,
    detail,
    inspectionDetails,
    bikeInspectionDetails,
    resetInspectionBikeDetailByKeyConnect
}) => {

    const { documentsMapper } = inspectionDetails;
    const { key } = detail;
    let isBikeQc = isUserRoleBIkeQc();
    const { bikeDocumentsMapper } = bikeInspectionDetails;

    const setNewValue = (optionsList) => {
        let result = [];
        optionsList.map(option => {
            result.push(option.name);
        });

        let mapper = isBikeQc ? [...bikeDocumentsMapper] : [...documentsMapper];
        const newValue = result.join();
        mapper = mapper.map(map => {
            let element = { ...map };
            if (map.key === key) {
                element.newValue = newValue;
                element.newId = newValue;
                // element.checked = false;
            }

            return element;
        });
        if (isBikeQc) {
            resetInspectionBikeDetailByKeyConnect(RESET_REDUCER_KEYS.BIKE_DOCUMENTS_MAPPER, mapper);
        } else {
            resetInspectionDetailByKeyConnect(RESET_REDUCER_KEYS.DOCUMENTS_MAPPER, mapper);
        }
    };

    const onSelect = (optionsList, selectedItem) => {
        const okIndex = optionsList.findIndex(key => key.id === "Ok");
        if (okIndex > -1 && selectedItem.id !== "Ok") {
            optionsList.splice(okIndex, 1);
        }
        else if (selectedItem.id === "Ok") {
            optionsList.splice(0, optionsList.length);
            optionsList.push(selectedItem);
        }
        setNewValue(optionsList);
    };

    const onRemove = (optionList, removedItem) => {
        setNewValue(optionList);
    };

    return (
        <div>
            <Multiselect
                options={CHASSIS_EMBOSSING} // Options to display in the dropdown
                selectedvalues={[]} // Preselected value to persist in dropdown
                onSelect={onSelect} // Function will trigger on select event
                onRemove={onRemove} // Function will trigger on remove event
                displayValue="name" // Property name to display in the dropdown options
            />
        </div>
    );
};

export default ChassisNoEmbossing;

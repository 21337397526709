/* eslint-disable indent */
import React from "react";
import Timer from "react-compound-timer";
import { isAssignedToMe } from "../../utils/helper";

const InspectionHeaderTimer = ({ inspectionDetails, isBikeQcRole }) => {
  const { qcAssign } = inspectionDetails;
  const currentUser = qcAssign ? qcAssign.to : "";
  const initialTime = 180000;
  return (
    <>
      {(isAssignedToMe(currentUser) || isBikeQcRole) && <div>
        <Timer
          initialTime={initialTime}
          direction="backward"
          formatValue={(value) => `${(value < 10 ? `0${value}` : value)} `}
        >
          <div>
            <Timer.Minutes />:<Timer.Seconds />
          </div>
        </Timer>
      </div>
      }
    </>
  );
};

export default InspectionHeaderTimer;

import React, { useState, useEffect } from "react";
import styles from "./search.module.css";
import Searchicon from "../../images/search.svg";
import { RESET_REDUCER_STATE_KEYS, NUMBERS } from "../../../utils/app-constants";

const Search = ({ search, resetSearchByKeyConnect, versionFlag = false,history }) => {

    // const { query } = search;
    const [searchQuery, setSearchQuery] = useState("");
    const [isSearchedData, setIsSearchedData] = useState(false);

    useEffect(() => {
        return () => {
            resetSearchByKeyConnect(RESET_REDUCER_STATE_KEYS.QUERY, "-1");
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const onChangeHandler = (e) => {
        const { target: { value } } = e;
        setSearchQuery(value);
    };

    const onSearchClick = () => {
        if (searchQuery.trim()) {
            setIsSearchedData(true);
            resetSearchByKeyConnect(RESET_REDUCER_STATE_KEYS.QUERY, searchQuery.trim());
            if(versionFlag){
                history.push(`/inspection-version/${searchQuery.trim()}`);
            }
        } else if (isSearchedData) {
            clearSearch();
        }
    };

    const onSearchKeyPress = (e) => {
        const { charCode } = e;
        if (charCode === NUMBERS.THIRTEEN) {
            onSearchClick();
        }
    };

    const clearSearch = () => {
        setSearchQuery("");
        setIsSearchedData(false);
        resetSearchByKeyConnect(RESET_REDUCER_STATE_KEYS.QUERY, "-1");
        if(versionFlag){
            history.push(`/inspection-version`);
        }
    };
    return (
        <div className={styles.search}>
            <input type="text" name="search" placeholder="Appointment ID" onChange={onChangeHandler} onKeyPress={onSearchKeyPress} value={searchQuery || ""} autoComplete="off" />
            {isSearchedData && <button onClick={clearSearch}>x</button>}
            {!isSearchedData && <button>
                <img src={Searchicon} alt="search" onClick={onSearchClick} />
            </button>}
        </div>
    );
};

export default Search;

import React, { useEffect } from "react";
import Header from "../../components/header";
import Back from "../../components/back";
import styles from "./inspection-version.module.css";
import { getFromLocalStorage } from "../../utils/helper";
import { LOCAL_STORAGE_KEYS, ROLE_KEYS } from "../../utils/app-constants";
import moment from "moment";
import Search from "../../components/shared/search";

// {{base_url}}/v2/inspection?user=test3@cars24.com&role=qc&client=web&enableDuration=0&logData=1&action=Search&endDate=2020-03-23&startDate=2020-03-01&appointmentId=1001599799
const InspectionVersion = ({ fetcInspectionVersionListConnect, inspectionVersion, search, history, resetInspectionDetailByKeyConnect, resetInspectionVersionByKeyConnect, match, resetSearchByKeyConnect }) => {
    const email = getFromLocalStorage(LOCAL_STORAGE_KEYS.EMAIL);
    const localData = getFromLocalStorage(LOCAL_STORAGE_KEYS.LOGIN_DATA);
    let role = localData ? JSON.parse(localData)[LOCAL_STORAGE_KEYS.ROLE] : "";
    const startDate = moment(moment(new Date()).subtract(6, 'months').toDate()).format('YYYY-MM-DD').toString();
    const endDate = moment(moment(new Date()).toDate()).format('YYYY-MM-DD').toString();
    const { query } = search;
    const { data } = inspectionVersion;
    const { params: { appointment } } = match;

    useEffect(() => {
        resetInspectionVersionByKeyConnect("data", []);
        if (appointment) {
            fetcInspectionVersionListConnect(email, appointment, startDate, endDate);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appointment]);

    useEffect(() => {

        return () => {
            resetInspectionVersionByKeyConnect("data", []);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const versionSelection = (version, carId, inspectionStatus) => {
        resetInspectionDetailByKeyConnect("selectedVersion", version);
        history.push({pathname:`/review/${carId}`, state: {inspectionStatus}});
    };
    const StopPropogation = (e) => {
        e.stopPropagation();
    };
    return (
        <div className={styles.inspectionVersion}>
            <Header showVersion={true} history={history} />
            <div className={styles.backSearchWrapper} style={{ justifyContent: "flex-end" }}>
                {/* <Back history={history} versionFlag={true} /> */}
                <div>
                    <Search versionFlag={true} history={history} />
                </div>
            </div>
            {
                appointment && <div className={styles.dataTable}>
                    <table cellPadding="0" cellSpacing="0" width="100%">
                        <thead>
                            <tr>
                                <th>Store</th>
                                <th>Make</th>
                                <th>Model</th>
                                <th>Variant</th>
                                <th>Version</th>
                                <th>Inspection Status</th>
                                <th>Inspection by</th>
                                <th>QC by</th>
                                <th>InspectionStartTime</th>
                                <th>InspectionEndTime</th>
                                <th>QCStartTime</th>
                                <th>QCEndTime</th>
                                <th>PDF</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                data && data.length > 0 &&
                                // data.find(item => item.appointmentId == appointment) &&
                                // data.find(item => item.appointmentId == appointment).versions &&
                                // data.find(item => item.appointmentId == appointment).versions.length > 0 &&
                                data.map(item => {
                                    return <tr onClick={(
                                        role === ROLE_KEYS.QC || role === ROLE_KEYS.QC_VERSION ||
                                            role === ROLE_KEYS.QC_STORE_MANAGER || role === ROLE_KEYS.QC_ADMIN || role === ROLE_KEYS.GS
                                    ) ? () => { versionSelection(item.version, item.carId, item.inspectionStatus); } : ""}>
                                        <td>{item.storeName}</td>
                                        <td>{item.make}</td>
                                        <td>{item.model}</td>
                                        <td>{item.variant}</td>
                                        <td>{item.version}</td>
                                        <td>{item.inspectionStatus}</td>
                                        <td>{item.inspectionBy}</td>
                                        <td>{item.qcAssign ? item.qcAssign : ""}</td>
                                        <td>{item.inspectionStartTime}</td>
                                        <td>{item.inspectionEndTime}</td>
                                        <td>{item.reviewStartTime}</td>
                                        <td>{item.reviewEndTime}</td>
                                        <td onClick={(e) => { e.stopPropagation(); }}>{item.pdfUrl && <a target="_blank" href={item.pdfUrl}>Pdf</a>}</td>
                                    </tr>;
                                })
                            }
                        </tbody>
                    </table>
                </div>
            }
        </div>
    );
};

export default InspectionVersion;

import React from 'react';
import styles from './sent-mail.module.css';

const ReInspectionSentMail = ({reInspectionEmailLog}) => {

    return(
        <div className={styles.mailWrapper}>
            <h2>RI Sent Email History</h2>
            <table cellPadding="0" cellSpacing="0"  width="100%" className={styles.tableWrapper}>
                <thead>
                    <tr>
                        <th>Sent Date Time</th>
                        <th>URL</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        reInspectionEmailLog.map(item => (
                            <tr>
                                <td>{item.createdAt}</td>
                                <td><a target="_blank" href={item.reinspectionDiffUrl}>See Details</a></td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </div>
    );
};

export default ReInspectionSentMail;
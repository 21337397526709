/* eslint-disable indent */
import React, {useState} from "react";
import { useHistory } from "react-router-dom";
import Header from "../../components/header";
import styles from './styles.module.css';
import Back from "../../components/back";
import H2 from "../../components/h2";
import {Grid, TextField, Button}from "@material-ui/core";
import { showToastMessages} from "../../utils/helper";
import { InspectionService } from "../../service";
import { ToastContainer } from 'react-toastify';
const INITIATE_DATA = {
  appointmentId: '',
  oldRegNo: '',
  newRegNo: '',
  oldVariant: '',
  newVariant: '',
};
const QcAdmin = () => {
  const history = useHistory();
  const [data, setData] = useState(INITIATE_DATA);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  
  const handleInputChange = ({name, value}) => {
    setFormSubmitted(false);
    setData({
      ...data,
      [name]: value,
    });
  };
  const isValidForm = () => {
    if (!data.appointmentId) {
      showToastMessages('Invalid Appointment Id');
      return false;
    }
    else if (!!data.oldRegNo && !data.newRegNo) {
      showToastMessages('Invalid new registration number');
      return false;
    }
    else if (!!data.oldVariant && !data.newVariant) {
      showToastMessages('Invalid new variant');
      return false;
    }
    return true;
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    setFormSubmitted(true);
    if (isValidForm()) {
      const payload = {
        appointmentId: data.appointmentId,
        registrationNumber: data.newRegNo,
        oldRegistrationNumber: data.oldRegNo,
        variant: data.newVariant,
        oldVariant: data.oldVariant
      };
      InspectionService.updateCorrectRegNumber(payload).then((response)=>{
        showToastMessages(response?.data?.detail);
        setData(INITIATE_DATA);
      }).catch(err => {
        showToastMessages(err?.detail, false);
      }).finally(()=>{
        setIsSubmitting(false);
      });
    }
  };
  return (
    <>
      <Header history={history} showPerformance={false} />
      <ToastContainer />
      <div className={styles.backSearchWrapper}>
        <Back history={history} versionFlag={true} />
      </div>
      <div className={styles.adminForm}>
        <H2  text="Admin - Reg/Variant"/>
      </div>
      <form onSubmit={handleSubmit} className={styles.inputAdminForm}>
        <Grid container direction="column">
          <Grid item>
            <TextField
              className={styles.inputItem}
              id="appointmentId-input"
              name="appointmentId"
              label="Appointment Id"
              type="number"
              error={isSubmitting && !data.appointmentId}
              value={data.appointmentId}
              onChange={(e) => handleInputChange({name: e.target.name, value: e.target.value})}
            />
          </Grid>
          <Grid item >
            <TextField
              className={styles.inputRegNo}
              id="oldRegNo-input"
              name="oldRegNo"
              label="Old Registration Number"
              type="text"
              value={data.oldRegNo}
              onChange={(e) => handleInputChange({name: e.target.name, value: e.target.value})}
            />
          </Grid>
          <Grid item >
            <TextField
              className={styles.inputRegNo}
              id="newRegNo-input"
              name="newRegNo"
              label="New Registration Number"
              type="text"
              value={data.newRegNo}
              error={formSubmitted && !!data.oldRegNo && !data.newRegNo}
              onChange={(e) => handleInputChange({name: e.target.name, value: e.target.value.toUpperCase()})}
            />
          </Grid>
          <Grid item >
            <TextField
              className={styles.inputItem}
              id="oldVariant-input"
              name="oldVariant"
              label="Old Variant Id"
              type="text"
              value={data.oldVariant}
              onChange={(e) => handleInputChange({name: e.target.name, value: e.target.value})}
            />
          </Grid>
          <Grid item>
            <TextField
              className={styles.inputItem}
              id="newVariant-input"
              name="newVariant"
              label="New Variant Id"
              type="text"
              value={data.newVariant}
              error={formSubmitted && !!data.oldVariant && !data.newVariant}
              onChange={(e) => handleInputChange({name: e.target.name, value: e.target.value})}
            />
          </Grid>
          <Button className={styles.btnContainer} variant="contained" color="primary" type="submit" disabled={isSubmitting}>
            Submit
          </Button>
        </Grid>
      </form>
    </>
  );
};

export default QcAdmin;

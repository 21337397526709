import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CarVariantWidgetPanel from "./component";

const mapStateToProps = ({carVariantReducer}) => ({carVariantReducer});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CarVariantWidgetPanel);

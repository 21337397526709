import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import InspectionReviewExteriorBike from "./component";

const mapStateToProps = (
    {
        bikeInspectionDetails
    }
) => ({
    bikeInspectionDetails
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(InspectionReviewExteriorBike);

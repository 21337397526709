/* eslint-disable indent */
import React, { useState, useEffect } from "react";
import styles from "./colorpallet.module.css";
import Timer from "react-compound-timer";
import { getFromLocalStorage, hasTimeElapsed, getTimeDifferenceFromCurrentTime, getTimeDifferenceInMinutes } from "../../utils/helper";

const Colorpallet = ({ appointmentId, initialTime }) => {

  const getColorCode = () => {
    const timeDifference = getTimeDifferenceFromCurrentTime(initialTime);// getTimeDifferenceInMinutes(initialTime);
    //
    if (timeDifference) {
      if (timeDifference >= 0 && timeDifference < 15) {
        // setColorCode("greencolor");
        return "greencolor";
      }
      else if (timeDifference >= 15 && timeDifference < 30) {
        // setColorCode("yellowcolor");
        return "yellowcolor";
      }
      else if (timeDifference >= 30 && timeDifference < 60) {
        // setColorCode("orangecolor");
        return "orangecolor";
      }
      else if (timeDifference >= 60) {
        // setColorCode("redcolor");
        return "redcolor";
      }
      else {
        // setColorCode("redcolor");
        return "redcolor";
      }
    };

  };
  const color = getColorCode();
  const [colorCode, setColorCode] = useState(color);

  useEffect(() => {

    // initialTime = 1636000;
    let interval = setInterval(() => {
      const timeDifference = getTimeDifferenceFromCurrentTime(initialTime);// getTimeDifferenceInMinutes(initialTime);
      //

      if (timeDifference) {
        if (timeDifference >= 0 && timeDifference < 15) {
          setColorCode("greencolor");
        }
        else if (timeDifference >= 15 && timeDifference < 30) {
          setColorCode("yellowcolor");
        }
        else if (timeDifference >= 30 && timeDifference < 60) {
          setColorCode("orangecolor");
        }
        else if (timeDifference >= 60) {
          setColorCode("redcolor");
        }
      };
    }, 1000);
    return () => {
      clearInterval(interval);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <p className={styles[getColorCode()]}></p>
    </div>
  );
};

export default Colorpallet;
/* eslint-disable indent */
import React, { useEffect, useState } from "react";
import CarImage from "../../components/images/demo.jpg";
import styles from "./re-inspection.module.css";
import Button from "../shared/button/";
import H2 from "../../components/h2";
import { INSPECTION_DATA_KEYS } from "../../utils/app-constants";
import Popup from "../../components/popup";
import ReinspectionEmailPopup from "../../components/reinspection-email-popup";
import { isAssignedToMe, GetQueryString } from "../../utils/helper";

const ReInspection = ({ fetchReInspectionConnect, inspectionDetails, mailFeature, match }) => {
    const { riTableData, currentAuctionVersion, lastAuctionVersion, qcAssign, version, noValidRiBid } = inspectionDetails;
    const currentUser = qcAssign ? qcAssign.to : "";
    let sectionTitle = '';
    const { params } = match || {};
    const { carId } = params || inspectionDetails;
    let qsVersion = GetQueryString("version");

    const getValue = (title, value) => {
        switch (title && title.toLowerCase()) {
            case 'comments':
            case 'highlight positives':
            case 'highlight positive':
                let comments = value && value.map(item => {
                    return <p className={styles.commentP}>{item.comment}</p>;
                });
                return comments;

            default:
                if (typeof value === 'string' || value instanceof String || typeof value === 'number') {
                    return value;
                }

            // default:
            //     return <p>{value}</p>
        }
    };

    const getErrors = (field) => {
        let updateFieldValues = "";
        if (!!field && !!field.additionalInfo && field.additionalInfo.length > 0) {
            field.additionalInfo.forEach(element => {
                if (!!element.currentCondition) {
                    updateFieldValues = !!updateFieldValues ? updateFieldValues + ", " + element.currentCondition.value.toString() : element.currentCondition.value.toString();
                }
                if (!!element.workDone) {
                    updateFieldValues = !!updateFieldValues ? updateFieldValues + ", " + element.workDone.value.toString() : element.workDone.value.toString();
                }
            });
        }
        return updateFieldValues;
    };
    const getOldValue = (fieldObject, key) => {
        let value = ""; let title = "";
        if (!!fieldObject && !!fieldObject.oldValue && !!fieldObject.oldValue.subParts) {
            let field = fieldObject.oldValue.subParts[key];
            if (!!field && !!field.title && !!field.value) {
                title = field.title;
                value = field.value;
                switch (title) {
                    case 'Comments':
                    case 'Highlight Positives':
                    case 'Highlight Positive':
                        let comments = value.map(item => {
                            return <p className={styles.commentP}>{item.comment}</p>;
                        });
                        return comments;

                    default:
                        if (typeof value === 'string' || value instanceof String || typeof value === 'number') {
                            return value;
                        }

                    // default:
                    //     return <p>{value}</p>
                }
            }
        }
        return value;
    };
    const getOldErrors = (fieldObject, key) => {
        let updateFieldValues = "";
        if (!!fieldObject && !!fieldObject.oldValue && !!fieldObject.oldValue.subParts) {
            let field = fieldObject.oldValue.subParts[key];
            if (!!field && !!field.additionalInfo && field.additionalInfo.length > 0) {
                field.additionalInfo.forEach(element => {
                    if (!!element.currentCondition) {
                        updateFieldValues = !!updateFieldValues ? updateFieldValues + ", " + element.currentCondition.value.toString() : element.currentCondition.value.toString();
                    }
                    if (!!element.workDone) {
                        updateFieldValues = !!updateFieldValues ? updateFieldValues + ", " + element.workDone.value.toString() : element.workDone.value.toString();
                    }
                });
            }
        }
        return updateFieldValues;
    };
    const [popupOpen, setPopupOpen] = useState(false);
    const onPopupOpen = () => {
        setPopupOpen(true);
    };
    const onPopupClose = () => {
        setPopupOpen(false);
    };

    useEffect(() => {
        if (carId && (version > -1)) {
            if (!!qsVersion) {
                fetchReInspectionConnect(carId, qsVersion);
            } else {
                fetchReInspectionConnect(carId, version);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [carId]);

    const isTitleChanged = (title) => {
        if (sectionTitle !== title) {
            sectionTitle = title;
            return true;
        } else {
            return false;
        }
    };

    return (
        <>
            {noValidRiBid === true &&
                <div className={styles.reInspection}>
                    <H2 text={"RI Changes"} />
                    <p className={styles.textWrapper}>There is no buying dealer, hence RI change email is not required.</p>
                </div>
            }
            {
                noValidRiBid !== true &&
                <div className={styles.reInspection}>
                    <H2 text={"RI Changes"} />
                    <p className={styles.subContent}>{riTableData.length} differences from the last inspection</p>
                    <table cellPadding="0" cellSpacing="0" width="100%" className={styles.subTable}>
                        <thead>
                            <tr>
                                <th>Section</th>
                                <th>
                                    Last auction version
                                    <p>{lastAuctionVersion}</p>
                                </th>
                                <th>
                                    <div className={styles.buttonSection}>
                                        <div>
                                            Current version
                                            <p>{currentAuctionVersion}</p>
                                        </div>
                                        {
                                            (isAssignedToMe(currentUser) && mailFeature === true) && <Button onClick={onPopupOpen} ctaText={"Send Mail"} classNames="greenButton" />
                                        }
                                        <Popup isOpen={popupOpen} close={onPopupClose}>
                                            <ReinspectionEmailPopup onClose={onPopupClose} />
                                        </Popup>
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {

                                (riTableData).map((row, index) => {
                                    try {

                                        return <>
                                            {
                                                (row.newValue.sub_parts === undefined) &&
                                                <tr>
                                                    <td colSpan="3">
                                                        <table cellPadding="0" cellSpacing="0" width="100%" className={styles.subTable}>
                                                            <tbody>
                                                                <tr>
                                                                    <td>
                                                                        {isTitleChanged(INSPECTION_DATA_KEYS[row.key]) && <p className={styles.titles}>{INSPECTION_DATA_KEYS[row.key]}</p>}
                                                                        {/* Vivek {row.key} */}
                                                                        {row.title}
                                                                    </td>
                                                                    <td>
                                                                        <div className={styles.tableContent}>
                                                                            <div className={styles.contentSection}>
                                                                                {row.newValue.sub_parts ? <></> : <p>{getValue(row.title, !!row.oldValue ? row.oldValue.value : "")}</p>}
                                                                                {row.newValue.sub_parts ? <></> : <p>{row.updateDifferences}</p>}
                                                                            </div>
                                                                            {/* {row.newValue.sub_parts ? <></> : <div className={styles.imageWrapper}>
                                                                        <img src={CarImage} alt="car" />
                                                                    </div>} */}
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className={styles.tableContent}>
                                                                            <div className={styles.contentSection}>
                                                                                {row.newValue.sub_parts ? <></> : <p>{getValue(row.title, row.newValue.value)}</p>}
                                                                                {row.newValue.sub_parts ? <></> : <p>{row.updatedValues}</p>}
                                                                            </div>
                                                                            {/*<div className={styles.imageWrapper}>*/}
                                                                            {/*    <img src={CarImage} alt="car" />*/}
                                                                            {/*</div>*/}
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                            }
                                            {
                                                row.newValue.sub_parts && row.newValue.sub_parts.map((part, index) => {
                                                    return <tr>
                                                        <td colSpan="3">
                                                            <table cellPadding="0" cellSpacing="0" width="100%" className={styles.subTable}>
                                                                <tbody>
                                                                    <tr>
                                                                        <td>
                                                                            {/*<p className={styles.titles}>{INSPECTION_DATA_KEYS[row.key]}</p>*/}
                                                                            {isTitleChanged(INSPECTION_DATA_KEYS[row.key]) && <p className={styles.titles}>{INSPECTION_DATA_KEYS[row.key]}</p>}
                                                                            {row.title}:{row.newValue.subParts[part].title}
                                                                        </td>
                                                                        <td>
                                                                            <div className={styles.tableContent}>
                                                                                <div className={styles.contentSection}>
                                                                                    {/* <p>{getValue(row.newValue.subParts[part].title, row.newValue.subParts[part].value)}</p> */}
                                                                                    {index === 0 ? row.oldValue.value : ""}
                                                                                    <p>{getOldValue(row, part)}</p>
                                                                                    <p>{getOldErrors(row, part)}</p>
                                                                                    {/* <p>({row.updateDifferences})</p> */}
                                                                                </div>

                                                                                {/* <div className={styles.imageWrapper}>
                                                                            <img src={CarImage} alt="car" />
                                                                        </div> */}
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <div className={styles.tableContent}>
                                                                                <div className={styles.contentSection}>
                                                                                    {index === 0 ? row.newValue.value : ""}
                                                                                    <p>{getValue(row.newValue.subParts[part].title, row.newValue.subParts[part].value)}</p>
                                                                                    <p>{getErrors(row.newValue.subParts[part])}</p>
                                                                                    {/* <p>({row.updatedValues})</p> */}
                                                                                </div>
                                                                                {/*<div className={styles.imageWrapper}>*/}
                                                                                {/*    <img src={CarImage} alt="car" />*/}
                                                                                {/*</div>*/}
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                    </tr>;
                                                })
                                            }
                                        </>;
                                    }
                                    catch (e) { console.log(e); }
                                })
                            }

                        </tbody>
                    </table>
                </div>
            }
        </>
    );
};

export default ReInspection;

import React, {useState} from "react";
import styles from "./variants-popup.module.css";
import Button from "../shared/button/component";
import { InspectionService } from '../../service';
import Loader from '../loader';
import { convertKeyIntoString } from '../../utils/helper';
//need to chnage here
const VariantsPopup = ({carVariants, onClose, requestParameters, isEditMode, srNo}) => {
    const {
        modelName,
        fuelType,
        manufacturingYears,
        variantParameters,
    } = carVariants;
    const [isSubmittingFlag, setIsSubmittingFlag] = useState(false);
    const [responseData, setResponseData] = useState('');
    const [error, setError] = useState('');
    const displayParameters = (parameter) => {
        const allParameters = [];
        for(let key in parameter) {
            if(!(key in {variant: "variant", variantId: "variantId"})) {
                allParameters.push(<span>
                    {parameter[key].split(':')[0] + ' ' + convertKeyIntoString(key, ' ')}
                </span>);
            }
        }
        return allParameters;
    };
    
    const submitCarVariantHanlder = () => {
        setIsSubmittingFlag(true);
        if(isEditMode) {
            InspectionService.updateCarVariantParameter(srNo, {data: requestParameters[0]})
                .then(resp => {
                    setResponseData(resp.data);
                    setIsSubmittingFlag(false);
                })
                .catch(err => {
                    setIsSubmittingFlag(false);
                    console.log('error::',err);
                    setError(err);
                });
        } else {
            InspectionService.submitCarVariantSuggestionData({data: requestParameters}).then(response => {
                setResponseData(response.data);
                setIsSubmittingFlag(false);
            }).catch(error => {
                setIsSubmittingFlag(false);
                console.log('error::',error);
                setError(error);
            });
        }
    };

    const goBackhandler = () => {
        onClose(responseData.status || '');
    };
    return (
        <div className={styles.variantsPopup}>
            {isSubmittingFlag && <Loader />}
            {responseData.detail ? <h1 className={styles.successText}>{responseData.detail}</h1> : 
                <p className={styles.text}>Please confirm the Variants you have added</p>
            }

            <div className={styles.variants}>
                <ul>
                    <li className={styles.firstLi}><p>Model: <span>{modelName}</span></p> <p>Fuel Type: <span>{fuelType}</span></p></li>
                    <li>Manufacturing Year: <span>{manufacturingYears}</span></li>
                </ul>
            </div>

            <div className={styles.variantListWrapper}>
                <ul>
                    {variantParameters.map((parameterItem, index) => (
                        <li key={index}>
                            <p>{parameterItem.variant}</p> 
                            <div className={styles.variantList}>
                                {displayParameters(parameterItem)}
                            </div>
                        </li>
                    ))
                    }
                </ul>
            </div>
            {error && <p className={styles.error}> Error: {error.detail}</p>}
            <div className={styles.ctaWrappr}>
                <div className={styles.goBackCta}>
                    <Button ctaText={"Go Back"} onClick={goBackhandler} classNames="goBackCta" />
                </div>
                { responseData.status !== 201 && (
                    <div className={styles.greenCtaButton} >
                        <Button 
                            ctaText={isEditMode ? "UPDTAE" : "SUBMIT"} 
                            onClick={submitCarVariantHanlder} 
                            classNames="greenCtaButton" 
                            disabled={isSubmittingFlag}
                        />
                    </div>
                )
                }
            </div>
        </div>
    );
};

export default VariantsPopup;

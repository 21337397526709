import React from "react";
import backImage from "../../components/back/image/backButton.svg";
import styles from "./back.module.css";
import InspectionHeaderBack from "../inspection-header-back";

const Back = ({history,versionFlag = false}) => {
    return (
        <div className={styles.back}>
            <InspectionHeaderBack versionFlag ={versionFlag} history={history}/>  Go Back
        </div>
    );
};

export default Back;

import React, { useEffect, useState } from "react";
import { RESET_REDUCER_KEYS } from "../../utils/app-constants";
import InputText from "../shared/input-text";
import { isUserRoleBIkeQc } from "../../utils/helper";

const CustomerName = ({ detail, resetInspectionDetailByKeyConnect, inspectionDetails, bikeInspectionDetails, resetInspectionBikeDetailByKeyConnect }) => {
    const initialValue = detail.newValue ? detail.newValue : detail.value;
    const [customerName, setCustomerName] = useState(initialValue);
    const { key } = detail;
    const { documentsMapper } = inspectionDetails;
    let isBikeQc = isUserRoleBIkeQc();
    const { bikeDocumentsMapper } = bikeInspectionDetails;

    useEffect(() => {
        return () => {
            // resetToInitialValue();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const resetToInitialValue = () => {
        let mapper = [...documentsMapper];
        let newValue = "";
        let newId = "";
        mapper = mapper.map(map => {
            let element = { ...map };
            if (map.key === key) {
                element.newValue = newValue;
                element.newId = newId;
                element.checked = false;
            }

            return element;
        });
        resetInspectionDetailByKeyConnect(RESET_REDUCER_KEYS.DOCUMENTS_MAPPER, mapper);
    };

    const onChange = (e) => {
        const { target: { value } } = e;
        setCustomerName(value);
    };

    const onBlurText = (e) => {
        if (customerName !== initialValue) {
            setNewValue();
        }
    };

    const setNewValue = () => {
        let mapper = isBikeQc ? [...bikeDocumentsMapper] : [...documentsMapper];
        const newValue = customerName;
        let dependentFields = [];
        mapper = mapper.map(map => {
            let element = { ...map };
            if (map.key === key) {
                element.newValue = newValue;
                element.newId = newValue;
                // element.checked = false;
                if (element.dependentFields && element.dependentFields.length > 0) {
                    dependentFields = element.dependentFields;
                }
            }

            return element;
        });

        mapper = mapper.map(map => {
            let element = { ...map };
            if (dependentFields.find(field => field === element.key)) {
                element.checked = true;
            }

            return element;
        });
        if (isBikeQc) {
            resetInspectionBikeDetailByKeyConnect(RESET_REDUCER_KEYS.BIKE_DOCUMENTS_MAPPER, mapper);
        } else {
            resetInspectionDetailByKeyConnect(RESET_REDUCER_KEYS.DOCUMENTS_MAPPER, mapper);
        }
    };

    return (
        <div>
            <InputText
                placeholder={detail.text}
                onChange={onChange}
                value={customerName}
                onBlurCallback={onBlurText}
            />
        </div>
    );
};

export default CustomerName;

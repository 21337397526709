import { combineReducers } from 'redux';
import inspectionReducer from "../../pages/inspection/reducers";
import InspectionFilters from "../../components/inspection-filters/reducers";
import searchReducer from "../shared/search/reducers";
import carouselReducers from "../carousel/reducers";
import inspectionDetailsReducers from "../../pages/inspection-review/reducers";
import inspectionVersionReducer from "../../pages/inspection-version/reducers";
import bikeInspectionReducer from "../../pages/inspection-bike/reducers";
import bikeInspectionDetailsReducers from "../../pages/inspection-review-bike/reducers";
import carVariantReducer from '../../pages/add-car-variant/reducers';
import xFactorReducer from '../../pages/x-factor/reducers';
import gsUnflaggingReducer from '../../pages/gs-unflagging/reducers';

export default combineReducers({
    inspectionsReducer: inspectionReducer,
    inspectionFiltersReducer: InspectionFilters,
    carousel: carouselReducers,
    inspectionDetails: inspectionDetailsReducers,
    search: searchReducer,
    inspectionVersion: inspectionVersionReducer,
    bikeInspectionReducer: bikeInspectionReducer,
    bikeInspectionDetails: bikeInspectionDetailsReducers,
    carVariantReducer,
    xFactorReducer,
    gsUnflaggingReducer
});
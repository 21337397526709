import { createTypes } from "reduxsauce";

export default createTypes(
    `
    RESET_INSPECTIONS_DETAIL
    FETCH_INSPECTIONS_DETAIL_SUCCESS
    FETCH_INSPECTIONS_DETAIL_FAILURE
    FETCH_RE_INSPECTIONS_DETAIL_SUCCESS
    FETCH_RE_INSPECTIONS_DETAIL_FAILURE
    RESET_INSPECTION_DETAIL_BY_KEY
    RESET_INSPECTION_DETAIL_DOCUMENT_BY_KEY
    FETCH_INSPECTIONS_VERSION_SUCCESS
    FETCH_INSPECTIONS_VERSION_FAILURE
    UPDATE_FIELD_REMARK_HISTORY
    FETCH_PRE_INSPECTION_DATA_SUCCESS
    FETCH_PRE_INSPECTION_DATA_FAILURE
    FETCH_INSPECTION_DATA_START
    FETCH_INSPECTION_DATA_END
    FETCH_RESTRICT_INSPECTION_IMAGES_SUCCESS
    FETCH_RESTRICT_PRE_INSPECTION_IMAGES_SUCCESS
`,
    {
        prefix: "inspectionreview/"
    }
);

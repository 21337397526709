import React, {useState} from "react";
import styles from "./car-variant-header.module.css";
import Button from "../../components/shared/button";
import Popup from "../../components/popup";
import VariantsPopup from "../../components/variants-popup";
import { convertKeyIntoString } from '../../utils/helper';

const CarVariantHeader = ({carVariants, history, resetCarVariantData, setErrorList, location: {state : currentVariantData}}) => {
    const [variantFormOpen, setVariantFormOpen] = useState(false);
    let [requestParameters, setRequestParameters] = useState([]);
    const {
        model_id,
        fuelType,
        manufacturingYears,
        variantParameters,
    } = carVariants;
    const isEditMode = !!currentVariantData;
    const {editVariantData: {srNo} = {}} = currentVariantData || {};
    const onVariantFormClose = (statusCode) => {
        if(statusCode === 201) {
            resetCarVariantData(); 
        }
        setVariantFormOpen(false);
    };
    const isRequestParamsValidated = reqParam => {
        const reqParamArray = reqParam.map(param => JSON.stringify(param));
        const uniqueRequestParam = [...new Set(reqParamArray)];
        if(uniqueRequestParam.length === reqParamArray.length) {
            return true;
        } else {
            const allCommonVariants = [];
            uniqueRequestParam.map(uParam => {
                const commonVariants = [];
                reqParamArray.map((reqParam, index) => {
                    if(reqParam === uParam) {
                        commonVariants.push(index + 1);
                    }
                });
                if(commonVariants.length > 1) {
                    allCommonVariants.push('['+ commonVariants.join(', ') +']');
                }
            });
            alert('Car variant '+ allCommonVariants + ' should not be same');
        }

        return false;
    };

    const createRequestParam = (missingFields) => {
        const fielNameMappingObject = {
            model_id: 'Model',
            fuelType: 'Fuel Type',
            manufacturingYears: 'Manufacturing Years',
            variant: 'Variant',
        };
        return variantParameters.map((parameter) => {
            const errorObject = {};
            const paramObject = {
                model_id,
                fuelType,
                variant: '',
                variantId: '',
                manufacturingYears,
                ...parameter
            };
            const parameterObject = {...parameter};
            delete parameterObject.variant;
            delete parameterObject.variantId;
            if(!Object.keys(parameterObject).length) {
                errorObject.isParamereSelected = 'Drop at least one parameter here';
            }
            for(const key in paramObject) {
                if(!paramObject[key] && key !== 'variantId') {
                    errorObject[key] = fielNameMappingObject[key] || convertKeyIntoString(key, ' ');
                }
            }

            missingFields.push(errorObject);
            
            return paramObject;
        });
    };

    const onVariantFormOpen = () => {
        const missingFields = [];
        const reqParam = createRequestParam(missingFields);
        const missingFieldsList = missingFields.filter(errorItem => Object.keys(errorItem).length);
        if(missingFieldsList.length) {
            setErrorList(missingFields);
            return;
        }

        if(isRequestParamsValidated(reqParam)) {
            setRequestParameters(reqParam);
            setVariantFormOpen(true);
        }
        
    };
    const goBackHandler = () => history.push({pathname:'/car-variant-list', state: currentVariantData});
    return (
        <div className={styles.carVariantHeader}>
            <div className={styles.goBack} onClick={goBackHandler}>Go Back</div>
            <div>
                <Button onClick={onVariantFormOpen} ctaText={"SUBMIT"} classNames="normalCta" />
                <Popup isOpen={variantFormOpen}>
                    <VariantsPopup 
                        onClose={onVariantFormClose}
                        carVariants={carVariants} 
                        requestParameters={requestParameters}
                        history={history}
                        resetCarVariantData={resetCarVariantData}
                        isEditMode={isEditMode}
                        srNo={srNo}
                    />
                </Popup>
            </div>
        </div>
        
    );
};

export default CarVariantHeader;

import React, {useEffect} from "react";
import MultipleCarousel from "../multiple-carousel";

const AdditionalImages = ({inspectionDetails}) => {
    const {additionalImagesMapper} = inspectionDetails;

    return (
        <div>
            <MultipleCarousel mapper={additionalImagesMapper} currentMapper="additionalImagesMapper"/>
        </div>
    );
};

export default AdditionalImages;

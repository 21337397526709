import React, {useState, useEffect} from 'react';
import { useDrop } from 'react-dnd';
import DropWidget from './DropWidget';
import styles from "./car-variant-left-panel.module.css";
import InputText from '../shared/input-text';
import SelectComponent from '../shared/select'; 
import { LOCAL_STORAGE_KEYS } from '../../utils/app-constants';
import { getFromLocalStorage } from '../../utils/helper';
import { thumborUrl } from '../../utils/url-constants';

const DropBoxForWidget = ({
    accept, 
    onDrop, 
    index, 
    handleRemove, 
    updateCarVariantObject, 
    lastDroppedItem,
    carVariants,
    variantIndex,
    errorListObject
}) => {
    const { name, type, value, image } = lastDroppedItem || {};
    const { variantParameters } = carVariants;
    let selectedParamValue = {};
    if( name && variantParameters[variantIndex] && variantParameters[variantIndex][name]) {
        if(type === 'image') {
            const values = variantParameters[variantIndex][name].split(':');
            selectedParamValue = {label: values[0], value: values[1]};
        } else {
            selectedParamValue = {label:variantParameters[variantIndex][name], value: variantParameters[variantIndex][name]};
        }
    }
    const [{ isOver, canDrop }, drop] = useDrop({
        accept,
        drop: onDrop,
    });
    useEffect(() => {
        const loginData = JSON.parse(getFromLocalStorage(LOCAL_STORAGE_KEYS.LOGIN_DATA)) || {};
        const baseUrl = loginData.imageHostUrl ? loginData.imageHostUrl : thumborUrl();
        setImageHostUrl(baseUrl);
    },[]);
    const [textValue, setTextValue] = useState('');
    const [imageUrl, setImageUrl] = useState('');
    const [imageHostUrl, setImageHostUrl] = useState('');

    const getOptionList = type => {
        switch(type) {
        case "dropDown": 
            const values = value || [];
            return values.map(item => ({label: item, value: item}));

        case "yes/no":
            return [{label: 'Yes', value: 'Yes'}, {label: 'No', value: 'No'}];

        case "image": 
            const images = image || [];
            return images.map(item => ({label: item.name, value: item.url}));
        
        default: return [];
        }
    };

    let optionsList = type && getOptionList(type) || [];
    
    const onChangeHandler = (value, label) => {
        if(type === "image") {
            updateCarVariantObject({[name]: label + ':' + value}, variantIndex);
            setImageUrl(value);
            return;
        }
        updateCarVariantObject({[name]: value}, variantIndex);
    };

    const onChangeTextHandler = event => {
        setTextValue(event.target.value);
    };

    const freeTextHandler = () => {
        updateCarVariantObject({[name]: textValue});
    };

    return(
        <ul className={styles.dropList}>
            <li>Parameter {index + 1}</li>
            <li ref={drop}>
                { name ? 
                    <DropWidget 
                        handleRemove={handleRemove} 
                        name={name} 
                    /> : (
                        errorListObject.isParamereSelected ? <span className={styles.errorMessage}>{errorListObject.isParamereSelected}</span> : 
                            <span className={styles.placeHolderColor}>Drop Parameter here</span>)
                }
            </li>
            <li>
                { type === "Text" && 
                <InputText 
                    onChange={onChangeTextHandler} 
                    value={selectedParamValue} 
                    onBlurCallback={freeTextHandler} 
                />
                }
                
                {(type === "dropDown" || type === "image" || type === "yes/no")  && 
                <SelectComponent 
                    optionsList={optionsList} 
                    onChange={onChangeHandler}  
                    value={selectedParamValue}
                />}
                {imageUrl && type === "image" && <img className={styles.image} src={imageHostUrl+imageUrl} />}
                { errorListObject[name] && <span className={styles.errorMessage}>Select value</span>}
            </li>
        </ul>
    
    );
};

export default DropBoxForWidget;
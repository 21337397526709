import React, { useState } from "react";
import { RESET_REDUCER_KEYS, BIKE_ODOMETER_RANGES } from "../../utils/app-constants";
import InputText from "../shared/input-text";
import { isUserRoleBIkeQc } from "../../utils/helper";
import SelectComponent from "../shared/select/component";

const OdometerReading = ({ resetInspectionDetailByKeyConnect, detail, inspectionDetails,
    bikeInspectionDetails,
    resetInspectionBikeDetailByKeyConnect }) => {
    const initialValue = detail.newValue ? detail.newValue : detail.value;
    const [kilometer, setKilometer] = useState(initialValue);
    const { key } = detail;
    const { interiorOdometerMapper } = inspectionDetails;
    const {uiVersion} = bikeInspectionDetails || {};
    let isBikeQc = isUserRoleBIkeQc();
    const { bikeDocumentsMapper } = bikeInspectionDetails;
  
    const onChange = (e) => {
        const { target: { value } } = e;
        if (!isNaN(value)) {
            setKilometer(value);
        }
    };

    const onBlurText = (e) => {
        if (kilometer !== initialValue) {
            setNewValue();
        }
    };

    const setNewValue = (value) => {
        let mapper = isBikeQc ? [...bikeDocumentsMapper] : [...interiorOdometerMapper];
        const newValue = !!value ? value : kilometer;
        let dependentFields = [];
        mapper = mapper.map(map => {
            let element = { ...map };
            if (map.key === key) {
                element.newValue = newValue;
                element.newId = newValue;
                if (element.dependentFields && element.dependentFields.length > 0) {
                    dependentFields = element.dependentFields;
                }
            }

            return element;
        });

        mapper = mapper.map(map => {
            let element = { ...map };
            if (dependentFields.find(field => field === element.key)) {
                element.checked = true;
            }

            return element;
        });
        if (isBikeQc) {
            resetInspectionBikeDetailByKeyConnect(RESET_REDUCER_KEYS.BIKE_DOCUMENTS_MAPPER, mapper);
        } else {
            resetInspectionDetailByKeyConnect("interiorOdometerMapper", mapper);
        }
    };
    const onSelect = (value) => {
        if (value !== initialValue) {
            setKilometer(value);
            setNewValue(value);
        }
    };
    return (
        <div>
            {isBikeQc && !!uiVersion && uiVersion === 2 ? <SelectComponent
                optionsList={BIKE_ODOMETER_RANGES}
                placeholder={detail.text}
                onChange={onSelect}
                onBlurCallback = {onBlurText}
            /> : <InputText
                placeholder={detail.text}
                onChange={onChange}
                value={kilometer}
                onBlurCallback={onBlurText}
            />}
        </div>
    );
};

export default OdometerReading;

/* eslint-disable indent */
import React, { useState } from "react";
import { Link } from "react-router-dom";
import InputText from "../../components/shared/input-text/component";
import Button from "../../components/shared/button/component";
import { AuthService, InspectionService,GsUnflaggingService } from "../../service";
import { GoogleLogin } from 'react-google-login';
import {
  getGoogleCLientId
} from "../../utils/url-constants";
import { GetQueryString } from "../../utils/helper";
import styles from "./login.module.css";
import Logo from "../../components/images/logo.svg";
import QcWeblink from "../../components/images/qcWeblink.svg";
import { IsUserHasCars24EmailDomain, validateEmail } from "../../utils/validators/email-validator";
import { ERROR_MESSAGE } from "../../utils/app-constants";
import { saveToLocalStorage } from "../../utils/helper";
import { LOCAL_STORAGE_KEYS, ROLE_KEYS } from "../../utils/app-constants";
import firebase from "../../utils/firebase";
export const currentEnv = process.env.REACT_APP_HOST_ENV;

const Login = ({ history, resetInspectionDetailByKeyConnect, match }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [emailErrorMsg, setEmailErrorMsg] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const [loginErrorMessage, setLoginErrorMessage] = useState();
  const [isLoading, setIsLoading] = useState(false);
  // const { params: { carId } } = match;
  let qsVersion = GetQueryString("version");
  let carId = GetQueryString("carId");

  const onEmailChangeHandler = (e) => {
    const { target: { value } } = e;
    setEmail(value);
  };
  const onPasswordChangeHandler = (e) => {
    const { target: { value } } = e;
    setPassword(value);
  };

  const onPasswordKeyPress = (e) => {
    const { charCode } = e;
    if (charCode === 13) {
      onSignIn();
    }
  };

  const validateData = () => {
    let errors = 0;
    if (!(email.trim() && validateEmail(email.trim()))) {
      ++errors;
      setEmailError(true);
      setEmailErrorMsg("Enter a valid Email");
    } else {
      setEmailError(false);
      if (currentEnv === 'PRODUCTION' && IsUserHasCars24EmailDomain(email.trim())) {
        ++errors;
        setEmailError(true);
        setEmailErrorMsg("Cars24 employees should login through google sign in");
      }
    }
    if (!password.trim()) {
      ++errors;
      setPasswordError(true);
    } else {
      setPasswordError(false);
    }
    if (errors > 0) {
      return false;
    } else {
      return true;
    }
  };

  // login: "test3@cars24.com",
  //     "loginPassword": "123456",

  const getExcludedList = () => {
    InspectionService.getExcludedList().then(response => {
      const { data: { detail } } = response;
      saveToLocalStorage(LOCAL_STORAGE_KEYS.EXCLUDED_USER, JSON.stringify(detail));
    }).catch(error => {
    });
  };
  const getSleeptimeConfig = () => {
    InspectionService.getSleeptimeConfig().then(response => {
      const { data: { detail: { data } } } = response;
      saveToLocalStorage(LOCAL_STORAGE_KEYS.SLEEP_TIME, JSON.stringify(data));
    }).catch(error => {
    });
  };

  const onSignIn = () => {
    
    setLoginError(false);
    const isValid = validateData();
    if (isValid) {
      // const params = {
      //   "email": email.trim(),
      //   "password": password.trim()
      // };
      const params = {
        "grant_type": "password",
        "loginUserName": email,
        "loginPassword": password,
      };
      setIsLoading(true);
      AuthService.login(params).then(response => {
        const { data } = response;
        if (data.role && data.role.match(/qc|StoreManager-Qc|SM-QcAdmin|gs/)) {
          saveToLocalStorage(LOCAL_STORAGE_KEYS.LOGIN_DATA, JSON.stringify(data));
          saveToLocalStorage(LOCAL_STORAGE_KEYS.EMAIL, email);
          saveToLocalStorage(LOCAL_STORAGE_KEYS.ROLE, data.role);
          getAllDispositionsList();
          if (data.role === ROLE_KEYS.QC_VERSION) {
            history.push(`/inspection-version`);
          } else if (data.role === ROLE_KEYS.QC_BIKE) {
            history.push("/inspection-bike");
          }
          else {
            if (carId) {
              history.push(`/re-inspection/${carId}?version=${qsVersion}`);
            } else {
              const firebaseToken = data.firebaseToken;
              if(firebaseToken) {
                firebase.getInstance().auth().signInWithCustomToken(firebaseToken)
                .then(() => {
                  if(data.role === ROLE_KEYS.GS) {
                    history.push("/gs-unflagging");
                  } else {
                    history.push("/inspection");
                  }
                })
                .catch((error) => {
                  console.log("error", error);
                });
              } else {
                if(data.role === ROLE_KEYS.GS) {
                  history.push("/gs-unflagging");
                } else {
                  history.push("/inspection");
                }
              }
              
            }
          }
        }
        else {
          saveToLocalStorage(LOCAL_STORAGE_KEYS.LOGIN_DATA, JSON.stringify(data));
          saveToLocalStorage(LOCAL_STORAGE_KEYS.EMAIL, email);
          saveToLocalStorage(LOCAL_STORAGE_KEYS.ROLE, data.role);
          if (carId) {
            history.push(`/re-inspection/${carId}?version=${qsVersion}`);
          }
          else {
            history.push(`/inspection-version`);
            // setLoginError(true);
            // setIsLoading(false);
            // setLoginErrorMessage("Not a authroized user");
          }
        }

      }).catch(error => {
        const { detail } = error;
        setLoginError(true);
        setIsLoading(false);
        setLoginErrorMessage(detail);
      });
    }
  };
  const validateDomain = (email) => {
    const cars24Domain = (email.split(/@/)[1]).split(/\./)[0];
    if (cars24Domain.match(/cars24|sachimistri|carpavillion|cariotauto/i)) {
      return true;
    }
    return false;
  };
  const onGoogleSignIn = (idToken, email) => {
    
    setLoginError(false);
    const isValid = !!email;
    if (isValid) {
      const params = {
        "grant_type": "password",
        "loginUserName": email,
        "googleIdToken": idToken
      };
      setIsLoading(true);
      AuthService.login(params).then(response => {
        const { data } = response;
        saveToLocalStorage(LOCAL_STORAGE_KEYS.LOGIN_DATA, JSON.stringify(data));
        saveToLocalStorage(LOCAL_STORAGE_KEYS.EMAIL, email);
        saveToLocalStorage(LOCAL_STORAGE_KEYS.ROLE, data.role);
        getAllDispositionsList();
        if (data.role && data.role.match(/qc|StoreManager-Qc|SM-QcAdmin|gs/)) {
          if (data.role === ROLE_KEYS.QC_VERSION) {
            history.push(`/inspection-version`);
          } else if (data.role === ROLE_KEYS.QC_BIKE) {
            history.push("/inspection-bike");
          }
          else {
            if (carId) {
              history.push(`/re-inspection/${carId}?version=${qsVersion}`);
            } else {
              const firebaseToken = data.firebaseToken;
              if(firebaseToken) {
                firebase.getInstance().auth().signInWithCustomToken(firebaseToken)
                .then(() => {
                  if(data.role === ROLE_KEYS.GS) {
                    history.push("/gs-unflagging");
                  } else {
                    history.push("/inspection");
                  }
                })
                .catch((error) => {
                  console.log("error", error);
                });
              } else {
                if(data.role === ROLE_KEYS.GS) {
                  history.push("/gs-unflagging");
                } else {
                  history.push("/inspection");
                }
              }
            }
          }
        }
        else {
          if (carId) {
            history.push(`/re-inspection/${carId}?version=${qsVersion}`);
          }
          else {
            history.push(`/inspection-version`);
            // setLoginError(true);
            // setIsLoading(false);
            // setLoginErrorMessage("Not a authroized user");
          }
        }
      }).catch(error => {
        const { detail } = error;
        setLoginError(true);
        setLoginErrorMessage(detail);
        setIsLoading(false);
      });
    }
  };
  const responseGoogle = (response) => {
    setEmailError(false);
    const { profileObj: { email } } = response;
    if (validateDomain(email)) {
      setEmail(email);
      const id_code = response.getAuthResponse().id_token;
      onGoogleSignIn(id_code, email);
    }
    else {
      setLoginError(true);
      setLoginErrorMessage("Not a Valid Cars24 user");
      setIsLoading(false);
    }

  };

  const getAllDispositionsList=()=>{
    GsUnflaggingService.getAllDispositions().then((res)=>{
      saveToLocalStorage(LOCAL_STORAGE_KEYS.DISPOSITIONS_DROPDOWN_ITEM, JSON.stringify(res.data.detail));
    })
  }
  return (
    <div className={styles.loginMainWrapper}>
      <div className={styles.loginWrapper}>
        <div className={styles.logoSection}>
          <img src={Logo} alt="logo" />
          <img src={QcWeblink} alt="QcWeblink" />
        </div>
        <p className={styles.text}>Help us do right by our customers! </p>
        <div className={styles.googleLogin}>
          <GoogleLogin
            clientId={getGoogleCLientId()}
            buttonText="Cars24 Google Sign-In"
            onSuccess={responseGoogle}
            onFailure={responseGoogle}
            theme="dark"
            uxMode="popup"
          />
        </div>
        <div className={styles.signUplink}>
          <Link to="#">Are you a franchise user?</Link>
          <p>Sign in here</p>
        </div>
        {currentEnv !== "PRODUCTION" && <div className={styles.loginSection}>
          <label>Email</label>
          <InputText
            id="email"
            onChange={onEmailChangeHandler}
            value={email}
            maxLength={100} />
          {
            emailError &&
            <small key="emailErr" className={styles.textDanger}>{emailErrorMsg}</small>
          }

          <label>Password</label>
          <InputText
            id="password"
            type="password"
            onChange={onPasswordChangeHandler} value={password}
            onKeyPress={onPasswordKeyPress}
          />
          {
            passwordError &&
            <small key="pswrdErr" className={styles.textDanger}>{ERROR_MESSAGE.PASSWORD}</small>
          }
          <Link to="#" className={styles.forgetPassword}>Forgot Password?</Link>
          {
            loginError &&
            <small key="loginErr" className={styles.textDanger}>{loginErrorMessage}</small>
          }
          <div>
            <Button classNames="primaryButton" ctaText={"Sign in"} onClick={onSignIn}
              isLoading={isLoading} disabled={isLoading} />
          </div>
        </div>}

        {/* <InputText id="email" text="Email Address" onChange={onEmailChangeHandler} value={email}
          maxLength={100} />
        {
          emailError &&
          <small key="emailErr"
            className={`form-text text-danger`}>{emailErrorMsg}</small>
        }*/}
      </div>
      {/* <div className={""}>
        <InputText id="password" text="Password" type="password"
          onChange={onPasswordChangeHandler} value={password}
        />
        {
          passwordError &&
          <small key="pswrdErr"
            className="form-text text-danger">{"password error"}</small>
        }
      </div>
      <div className={""}>
        <GoogleLogin
          clientId={getGoogleCLientId()}
          buttonText="Cars24 Google Sign-In"
          onSuccess={responseGoogle}
          onFailure={responseGoogle}
          uxMode="popup"
          theme="dark"
        />
      </div>
      <div>
        <Button classNames="primaryButton" ctaText={"login"} onClick={onSignIn}
          isLoading={isLoading} disabled={isLoading} />
      </div> */}
    </div>
  );
};

export default Login;
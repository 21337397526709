import React, {useEffect, useState} from "react";
import Checkbox from "../../components/shared/checkbox/";
import styles from "./inspection-check-box.module.css";

const InspectionCheckBox = ({text,mismatchMapper}) => {

    const getCheckedStatus = () => {
        const item = mismatchMapper.find(x => x.checked);
        return !item;
    };

    return (
        <div className={styles.checkboxlabel}>
            <Checkbox
                checkedStatus = {getCheckedStatus()}
                name={`no_mismatch`}
                id={`no_mismatch`}
            />
            <label className={styles.lableText}>{text}</label>
        </div>
    );
};

export default InspectionCheckBox;

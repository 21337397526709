import React, {useEffect, useState} from "react";
import SelectComponent from "../shared/select";
import {RESET_REDUCER_KEYS, YES_NO} from "../../utils/app-constants";
import { InspectionService } from "../../service";
import { showToastMessages } from "../../utils/helper";
import Button from "../shared/button/component";
import { imageUploadFormData } from "./constant";

const RtoNmcAvailability = ({detail,resetInspectionDetailByKeyConnect, inspectionDetails}) => {

    const [selectedValue, setSelectedValue] = useState("");
    const [imageUrl,setImageUrl] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const {key} = detail;
    const {documentsMapper, carId, inspectionStatus} = inspectionDetails;

    useEffect(()=>{
      if(!imageUrl){
        const additionalInfo = inspectionDetails?.carDetails?.rtoNocIssued?.subParts?.rtoNmcAvailable?.additionalInfo?.[0] || {};
        const url = additionalInfo?.image?.url;
        setImageUrl(url);
      }
    },[inspectionDetails]);

    useEffect(()=>{
        if(selectedValue) {
            setNewValue();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[selectedValue]);
    useEffect(()=>{
      if(!!imageUrl?.length) {
          setNewValue();
      }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[imageUrl]);


    const setNewValue = () => {
        let mapper = [...documentsMapper];
        const newValue = selectedValue;
        let dependentFields = [];
        mapper = mapper.map(map => {
          let element = { ...map };
          if (map.key === key) {
            let newSelectedVal = "";
            let objNewSeleted = YES_NO.find(x => x.value === newValue);
            if (!!objNewSeleted) {
              newSelectedVal = objNewSeleted.label;
            }
            element.newValue = newSelectedVal;
            element.newId = newValue;
            // element.checked = false;
            if(newSelectedVal === "Yes" && !!imageUrl){
              const additionalInfo = [
                {
                  image: {
                    title: "Rto_noc_issued_Rto_nmc_available_Additional_Info_Form_1",
                    url: imageUrl,
                    hashKey: "carDetails_rtoNocIssued_rtoNmcAvailable_additionalInfo_form_0",
                  },
                  hashKey: "carDetails_rtoNocIssued_rtoNmcAvailable_additionalInfo_form_0"
                }
              ];
              element.additionalInfo=additionalInfo;
            }
            else if(newSelectedVal === "No"){
              delete element?.additionalInfo;
            }
            if (element.dependentFields && element.dependentFields.length > 0) {
              dependentFields = element.dependentFields;
            }
          }
    
          return element;
        });
    
        mapper = mapper.map(map => {
          let element = { ...map };
          if (dependentFields.find(field => field === element.key)) {
            element.checked = true;
          }
    
          return element;
        });
        resetInspectionDetailByKeyConnect(RESET_REDUCER_KEYS.DOCUMENTS_MAPPER, mapper);
      };

    const onSelect = (value) => {
        setSelectedValue(value);
        if(value === "No"){
          setImageUrl("");
        }
    };

    const uploadImageHandler = (event) => {
      if(event.target.files.length) {
          const formData = new FormData();
          imageUploadFormData.forEach(({ key, value }) => {
            if (key === 'imageFile') {
              formData.append('imageFile', event.target.files[0]);
            }
            else if(key === 'carId'){
              formData.append('carId',carId);
            }
            else if(key === 'inspectionStatus'){
              formData.append('inspectionStatus',inspectionStatus);
            }
            else {
              formData.append(key, value);
            }
          });
          setIsLoading(true);   
          InspectionService.uploadRtoNocAvailableImage(formData).then((res)=> {
              showToastMessages("Image Uploaded",true);
              setImageUrl(res?.data.detail?.url);
          })
          .catch((err)=>showToastMessages(err?.detail,false))
          .finally(() => {
            setIsLoading(false);
          });
      }
  };

    const handleViewClick = () => {
      var url = "/view-image/0?url=" + encodeURI(imageUrl);
      window.open(url, "_blank", 'noopener');
  };
   

    return (
        <div>
            <SelectComponent
                optionsList={YES_NO}
                placeholder={detail.text}
                onChange={onSelect}
            />
            {(!!imageUrl && selectedValue === "Yes") && <Button style={{margin:"12px 0px 4px 0px"}}onClick={handleViewClick} classNames="grayButton" ctaText={"View"} />}
            {selectedValue === "Yes" && <div style={{marginTop:"10px"}}> <input disabled={isLoading} onChange={(event) => uploadImageHandler(event)} type="file" id="img" name="img" accept="image/*"/> </div>}
        </div>
    );
};

export default RtoNmcAvailability;

import React from 'react';
import { computeStyles } from '../../../utils/helper';
import styles from './styles.module.css';

const toggleCollapse = (e) => {
    const clickedItem = e.target;
    const clickedItemList = clickedItem?.nextSibling;

    if(clickedItemList?.classList?.contains(styles.show)) {
        clickedItem.classList.add(styles.rightArrow);
        clickedItem.classList.remove(styles.downArrow);

        clickedItemList.classList.add(styles.hide);
        clickedItemList.classList.remove(styles.show);
    } else {
        clickedItem.classList.add(styles.downArrow);
        clickedItem.classList.remove(styles.rightArrow);

        clickedItemList.classList.add(styles.show);
        clickedItemList.classList.remove(styles.hide);
    }
};

const CollapseList = (props) => {
    
    return (
        <div className={styles.collapseContainer}>
            {props.data?.map(item => (
                <>
                    <div
                        className={computeStyles(styles.collapseItem, item.isOpen ? styles.downArrow : styles.rightArrow)}
                        onClick={toggleCollapse}
                    >{item.title}</div>
                    <ul className={computeStyles(styles.collapseItemList, item.isOpen ? styles.show : styles.hide)}>
                        {item.items.map(it => (
                            <li key={it.title}>
                                <span>{it.title}</span>
                                {typeof it.value === "string" && <span className={styles.listValue}>{it.value}</span>}
                            </li>
                        ))}
                    </ul>
                </>
            ))}
        </div>
    );
};

export default CollapseList;
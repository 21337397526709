import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { INSPECTION_SECTION } from "../../utils/app-constants";
import styles from "./inspection-car-details-comments.module.css";
import Checkbox from "../shared/checkbox/";
import { getEditableComponent } from "../../utils/helper";
import RejectedOrAdded from "../rejected-or-added";
import Tooltip from "../tooltip";
import { MIGRATED_VEHICLE_VALUE } from "../../utils/app-constants";

const InspectionCarDetailsComments = ({
    document,
    resetInspectionDetailByKeyConnect,
    inspectionDetails,
    currentMapper,
    mapperName,
    isMigratedVehicleExists,
    migratedVehicleFromBgc
}) => {
    const dispatch = useDispatch();
    const { id, text, checked, value, key, newValue, isEditable, isImportant, isAdded, isRejected } = document;
    // const { commentsMapper } = inspectionDetails;

    const onCheckboxHandler = (key) => {

        let mapper = [...currentMapper];
        const index = mapper.findIndex(item => item.key === key);
        if (index > -1) {
            mapper[index].checked = !mapper[index].checked;
            if (mapper[index].isImportant && !mapper[index].checked) {
                mapper[index].isRejected = true;
                mapper[index].isAdded = false;
            }
            else if (mapper[index].isImportant && mapper[index].checked) {
                mapper[index].isRejected = false;
                mapper[index].isAdded = false;
            }
            if (!mapper[index].isImportant && !mapper[index].checked) {
                mapper[index].isRejected = false;
                mapper[index].isAdded = false;
            }
            else if (!mapper[index].isImportant && mapper[index].checked) {
                mapper[index].isRejected = false;
                mapper[index].isAdded = true;
            }

            // resetInspectionDetailByKeyConnect(RESET_REDUCER_KEYS.COMMENTS_MAPPER, mapper);
            resetInspectionDetailByKeyConnect(mapperName, mapper);
        }

    };

    return (
        <>
        <div className={styles.documentInfo}>
            <div className={styles.checkboxlabel}>
                <Checkbox
                    checkedStatus={checked}
                    onChangeCallback={() => onCheckboxHandler(key)}
                    name={`comments_${key}`}
                    id={`comments_${key}`}
                    section={INSPECTION_SECTION.ELECTRICALS_INTERIOR}
                />
                {/** id = 1 represents migrated vehicle */}
                {id == "1" && isMigratedVehicleExists && (
                    <span style={{ margin: "0 -10px 0 10px" }}>
                        <Tooltip
                            dataSource={"BGC Data"}
                            vaahanFlag={migratedVehicleFromBgc == 1 ? "yes" : "no"}
                            vahanWebsite={MIGRATED_VEHICLE_VALUE[migratedVehicleFromBgc] || `${migratedVehicleFromBgc}`}
                        />
                    </span>
                )}
                <label className={`${styles.lableText} ${(isImportant || checked) ? styles.importantField : ""}`}>{text}</label>
            </div>
            <div className={styles.dataWrapper}>
                {
                    checked && isEditable ? getEditableComponent(key, document, resetInspectionDetailByKeyConnect) : newValue ? newValue : value
                }
                {
                    newValue ? <div className={styles.newValue}>{`changed from ${value}`}</div> : ""
                }
                {
                    isRejected && <RejectedOrAdded isRejected={isRejected} />
                }
                {
                    isAdded && <RejectedOrAdded isAdded={isAdded} />
                }

            </div>
        </div>
        
        </>
    );
};

export default InspectionCarDetailsComments;

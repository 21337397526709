import React from "react";
import Button from "../shared/button/component";
import styles from "./dummy-inspection-confirmation-popup.module.css";
import H2 from "../h2/component";

const DummyInspectionConfirmationPopup = ({
    onClose
}) => {
    return (
        <div>
            <H2 text={"Dummy Inspection Confirmation"} />
            <p>Are you sure to mark this inspection as dummy as it will remove all your selection[If Any]</p>
            <div className={styles.ctaWrapper}>
                <div className={styles.ctaButtons}>
                    <Button ctaText={"OK"} classNames="primaryButton" onClick={()=> onClose(true)} />
                    <Button ctaText={"CANCEL"} classNames="cancelButton" onClick={()=> onClose(false)} />
                </div>
            </div>
        </div>
    );
};

export default DummyInspectionConfirmationPopup;

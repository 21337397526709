import React, { useState } from "react";
import { RESET_REDUCER_KEYS } from "../../utils/app-constants";
import InputText from "../shared/input-text/component";

const RtoCode = (props) => {
    const {detail, resetInspectionBikeDetailByKeyConnect, bikeInspectionDetails} = props;
    const [registrationNumber, setRegistrationNumber] = useState(detail.newValue ? detail.newValue : detail.value);
    const initialValue = detail.newValue ? detail.newValue : detail.value;
    const { key } = detail;
    const { bikeDocumentsMapper } = bikeInspectionDetails;

    const onChange = (e) => {
        const { target: { value } } = e;
        setRegistrationNumber(!!value ? value.toUpperCase() : value);
    };

    const onBlurText = (e) => {
        if (registrationNumber !== initialValue) {
            setNewValue(true);
        }
    };

    const setNewValue = (isValid) => {
        let mapper = [...bikeDocumentsMapper];
        const newValue = registrationNumber;
        let dependentFields = [];
        mapper = mapper.map(map => {
            let element = { ...map };
            if (map.key === key) {
                element.newValue = newValue;
                element.newId = newValue;
                element.isValid = isValid;
                element.errorMsg = "";

                if (element.dependentFields && element.dependentFields.length > 0) {
                    dependentFields = element.dependentFields;
                }
            }

            return element;
        });

        mapper = mapper.map(map => {
            let element = { ...map };
            if (dependentFields.find(field => field === element.key)) {
                element.checked = true;
            }

            return element;
        });

        resetInspectionBikeDetailByKeyConnect(RESET_REDUCER_KEYS.BIKE_DOCUMENTS_MAPPER, mapper);
    };

    return (
        <div>
            <InputText
                placeholder="Registration City/RTO Code"
                onChange={onChange}
                value={registrationNumber}
                onBlurCallback={onBlurText}
            />
        </div>
    );
};

export default RtoCode;

import React, {useEffect, useState} from "react";
import {RESET_REDUCER_KEYS, YES_NO} from "../../utils/app-constants";
import SelectComponent from "../shared/select";

const NoClaimBonus = ({detail,inspectionDetails,resetInspectionDetailByKeyConnect}) => {
    const [selectedValue, setSelectedValue] = useState("");
    const {key} = detail;
    const {insuranceMapper} = inspectionDetails;

    useEffect(()=>{
        if(selectedValue) {
            setNewValue();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[selectedValue]);

    useEffect(()=>{
        return ()=>{
            // resetToInitialValue();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const resetToInitialValue=()=>{
        let mapper = [...insuranceMapper];
        let newValue = "";
        let newId = "";
        mapper = mapper.map(map => {
            let element = { ...map };
            if (map.key === key) {
                element.newValue = newValue;
                element.newId = newId;
                element.checked = false;
            }

            return element;
        });
        resetInspectionDetailByKeyConnect(RESET_REDUCER_KEYS.INSURANCE_MAPPER, mapper);
    };

    const setNewValue = ()=>{
        let mapper = [...insuranceMapper];
        const newValue = selectedValue;
        let dependentFields = [];
        mapper = mapper.map(map => {
            let element = { ...map };
            if (map.key === key) {
                element.newValue = newValue;
                element.newId = newValue;
                // if(element.dependentFields && element.dependentFields.length >0){
                //     dependentFields = element.dependentFields;
                // }
            }

            return element;
        });

        resetInspectionDetailByKeyConnect(RESET_REDUCER_KEYS.INSURANCE_MAPPER, mapper);
    };

    const onSelect =(value)=>{
        setSelectedValue(value);
    };

    const getDefaultValue = () => {
        const ncbMap = insuranceMapper?.filter((map) => {
            return map.key === key
        })[0]
        const ncdValue = ncbMap?.newValue || ncbMap?.value;
        return YES_NO.filter((item) => item?.value === ncdValue)[0]
    }
    return (
        <div>
            <SelectComponent
                optionsList={YES_NO}
                placeholder={detail.text}
                onChange={onSelect}
                defaultValue={getDefaultValue()}
            />
        </div>
    );
};

export default NoClaimBonus;

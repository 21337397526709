import React from 'react';
import {Redirect, Route} from 'react-router-dom';
import {isAuthenticated} from '../../utils/helper';

const AuthenticatedRoute = ({component: Component, ...rest}) => (
    <Route {...rest} render={props => {
        if (!isAuthenticated()) {
            return <Component {...props} />;
        }
        return <Redirect to={{
            pathname: '/'
        }}
        />;
    }} />
);

export default AuthenticatedRoute;
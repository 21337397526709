import { createTypes } from "reduxsauce";

export default createTypes(
    `
    FETCH_INSPECTIONS_VERSION_LIST
    FETCH_INSPECTIONS_VERSION_LIST_SUCCESS
    FETCH_INSPECTIONS_VERSION_LIST_FAILURE
    RESET_INSPECTIONS_VERSION_LIST
    RESET_INSPECTIONS_VERSION_BY_KEY
`,
    {
        prefix: "inspectionversion/"
    }
);

import React from 'react';
import {Redirect, Route} from 'react-router-dom';
import {isAuthenticated} from '../../utils/helper';

const PrivateRoute = ({component: Component, path, ...rest}) => {
    return (
        <Route {...rest} render={props => {
            if (isAuthenticated()) {
                return <Component {...props} {...rest} />;
            }
            return <Redirect to={{pathname: '/login'}}
            />;
        }} />
    );
};

export default PrivateRoute;
import React from 'react';
import { getFromLocalStorage } from './helper';
import { LOCAL_STORAGE_KEYS, DEALER_API_AUTH_TOKEN } from './app-constants';

export const currentEnv = process.env.REACT_APP_HOST_ENV;
// export const currentClientId = process.env.QC_CLIENT_ID;

export const apiUrl = () => {
  const urls = {
    QA: 'https://insapi-qa1.qac24svc.dev', //"https://insapi-qa1.qac24svc.dev",//"https://insapi-qa1.ninja24.in",
    STAGING: 'https://ins-api-staging.24c.in',
    PRODUCTION: 'https://ins-api.24c.in',
  };
  return urls[currentEnv];
};
export const bikeApiUrl = () => {
  const urls = {
    QA: 'https://bikes-dealer-app.qac24svc.dev', //"https://bike-dealer-service.qac24svc.dev",
    STAGING: 'https://bikes-dealer-app.qac24svc.dev',
    PRODUCTION: 'https://bikes-dealer-app.c24.tech', //"https://bike-dealer-service.c24svc.app"
  };
  return urls[currentEnv];
};
export const bikeApiHeader = () => {
  const urls = {
    QA: 'Bearer 2b333ec3-a45e-436e-9b36-eb2e5b934461', //6b0f2163-1953-4f89-9dc4-8353416e7152",
    STAGING: 'Bearer 2b333ec3-a45e-436e-9b36-eb2e5b934461', // 6b0f2163-1953-4f89-9dc4-8353416e7152",
    PRODUCTION: 'Bearer cb1e4222-c6cf-4e96-92c6-fa7f65ff9036',
  };
  return urls[currentEnv];
};
export const apiHeader = (isBikeQC, token) => {
  if (isBikeQC) {
    // return DEALER_API_AUTH_TOKEN;
    return bikeApiHeader();
  } else {
    // const loginData = getFromLocalStorage(LOCAL_STORAGE_KEYS.LOGIN_DATA);
    // let jsonToken = loginData ? JSON.parse(loginData) : null;
    // const bearerHeader = !!jsonToken ? 'Bearer ' + jsonToken?.access_token : ''; //{ "key": "Authorization", "value": "Bearer " + jsonToken.access_token };
    const bearerHeader = `Bearer ${token}`;
    return bearerHeader;
  }
};

// export const getCLientId = () => {
//     if (currentEnv === "QA") {
//         // const clinetIds = {
//         //     QA: "client-2154",
//         //     STAGING: "client-2283",
//         //     PRODUCTION: ""
//         // };
//         return "client-2154";//clinetIds[currentEnv];
//     } else {
//         return process.env.REACT_APP_QC_CLIENT_ID;
//     }
// };
// export const getCLientSecrets = () => {
//     if (currentEnv === "QA") {
//         // const clinetSecrets = {
//         //     QA: "heb9SAHQBQo$",
//         //     STAGING: "yRct4zqomtKr",
//         //     PRODUCTION: ""
//         // };
//         return "heb9SAHQBQo$";//clinetSecrets[currentEnv];
//     }
//     else {
//         return process.env.REACT_APP_QC_CLIENT_SECRET;
//     }
// };
// export const getUserDeviceKeys = () => {
//     if (currentEnv === "QA") {
//         // const userDeviceKeys = {
//         //     QA: "device-2154",
//         //     STAGING: "device-2283",
//         //     PRODUCTION: ""
//         // };
//         return "device-2154";//userDeviceKeys[currentEnv];
//     } else {
//         return process.env.REACT_APP_QC_DEVICE_KEY;
//     }
// };
// export const getUserDevicepwds = () => {
//     if (currentEnv === "QA") {
//         // const userDevicepwds = {
//         //     QA: "o$fszcAM",
//         //     STAGING: "7eEncycP",
//         //     PRODUCTION: ""
//         // };
//         return "o$fszcAM";//userDevicepwds[currentEnv];
//     } else {
//         return process.env.REACT_APP_QC_DEVICE_PWD;
//     }
// };
export const getGoogleCLientId = () => {
  const clinetIds = {
    QA:
      '349287238690-ij0fv9nu1qc715bihfo5optht50g00tg.apps.googleusercontent.com',
    STAGING:
      '183876515907-fja234l5vurv92mv1ekflma5qmg1delo.apps.googleusercontent.com',
    PRODUCTION:
      '183876515907-3r5aq17lmed0ubkjto0p7e8pk3dg0ras.apps.googleusercontent.com',
  };
  return clinetIds[currentEnv];
};

export const thumborUrl = () => {
  const urls = {
    QA: 'http://asset-inspection.ninja24.in/',
    LNOD: 'http://thumbor-inspection1.ninja24.in',
    STAGING: 'https://cars24-staging.imgix.net/', //"https://thumbor-staging.24c.in/unsafe/",
    PRODUCTION: 'https://ins-timg.24c.in/unsafe/', //"https://cars24-production.imgix.net/"
  };
  return urls[currentEnv];
};
export const thumborBikeUrl = () => {
  const urls = {
    QA: 'http://bikes-media-qa.24c.in/',
    LNOD: 'http://bikes-media-qa.24c.in/',
    STAGING: 'http://bikes-media-qa.24c.in/unsafe/',
    PRODUCTION: 'http://bikes-media-qa.24c.in/unsafe/',
  };
  return urls[currentEnv];
};
export const videoUrl = () => {
  const urls = {
    QA: 'http://asset-inspection.ninja24.in/',
    LNOD: 'http://thumbor-inspection1.ninja24.in',
    STAGING: 'https://videos-staging.24c.in/',
    PRODUCTION: 'https://videos.24c.in/',
  };
  return urls[currentEnv];
};
export const getFireBaseDB = () => {
  const clinetIds = {
    QA: 'inspection-qa',
    STAGING: 'inspection-stage',
    PRODUCTION: 'inspection-prod',
  };
  return clinetIds[currentEnv];
};

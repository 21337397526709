import React, { useState } from "react";
import PropTypes from "prop-types";
import styles from "./input-text.module.css";
import { useToggle } from "../../hooks";

const InputText = ({
    value = "",
    id = "",
    text = "",
    placeholder = "",
    onChange = () => { },
    onBlurCallback = () => { },
    validator = () => { },
    errorMessage = "",
    inputRef,
    onFocusCallback = () => { },
    maxLength = 30,
    onKeyPress = () => { },
    type = "text",
    validationError = false,
    disabled = false,
    className
}) => {
    const [isTouched, setIsTouched] = useState(false);
    const [isFocused, setIsFocused] = useToggle(false);
    const [hasError, setHasError] = useState(false);
    const [error, setError] = useState(errorMessage);

    const onChangeHandler = (e) => {
        const inputValue = e.target.value;
        if (validator(inputValue)) {
            setHasError(false);
            setError("");
        } else {
            setHasError(true);
            setError(errorMessage);
        }
        onChange(e);
    };

    const onFocus = () => {
        setIsFocused();
        setIsTouched(true);
        onFocusCallback();
    };

    const onBlur = (e) => {
        setIsFocused();
        onBlurCallback(e.target.value);
    };

    return (
        <div
            className={`
                ${styles.inputShift}
                ${validationError ? styles.validatorInput : ""}
                ${disabled ? styles.disabled : ""}
                ${className}
            `}>
            <input
                ref={inputRef}
                id={id}
                placeholder={placeholder}
                type={type}
                className={`form-control ${disabled ? "cursor-not-allowed" : ""}`}
                value={value}
                maxLength={maxLength}
                onChange={onChangeHandler}
                onFocus={(e) => onFocus(e)}
                onBlur={(e) => onBlur(e)}
                autoComplete="off"
                onKeyPress={(e) => onKeyPress(e)}
                disabled={disabled}
            />
            {/* <label className={`${(isFocused || !!value) ? styles.active : ""}`}>{text}</label> */}
            {
                isTouched &&
                !isFocused &&
                hasError &&
                <span className={styles.error}>{error}</span>
            }
        </div>
    );
};

InputText.propTypes = {
    value: PropTypes.string,
    id: PropTypes.string,
    text: PropTypes.string,
    onChange: PropTypes.func,
    onBlurCallback: PropTypes.func,
    maxLength: PropTypes.number,
    validator: PropTypes.func,
    errorMessage: PropTypes.string,
    inputRef: PropTypes.object,
    onFocusCallback: PropTypes.func
};
export default InputText;
import React, { useEffect, useState } from "react";
import Checkbox from "../../components/shared/checkbox/";
import styles from "./image-checkbox-bike.module.css";
import { RESET_REDUCER_KEYS } from "../../utils/app-constants";

const ImageCheckboxBike = ({
    currentMapper,
    index,
    bikeInspectionDetails,
    resetInspectionBikeDetailByKeyConnect,
    treadValue = null,
    checked,
    currentConsi,
    versionViewMode = true
}
) => {
    const existingMapper = bikeInspectionDetails[currentMapper];
    const [checkBoxChecked, setCheckBoxChecked] = useState(false);
    const [text, setText] = useState("");
    const [prevValue, setPrevValue] = useState("");

    useEffect(() => {
        if (existingMapper) {
            let mapper = [...existingMapper];
            const currentIndex = mapper.findIndex(item => item.index == index);
            if (currentIndex > -1) {
                setCheckBoxChecked(mapper[currentIndex].checked);
                setText(mapper[currentIndex].subPart_title ? `${mapper[currentIndex].title} - ${mapper[currentIndex].subPart_title}` : mapper[currentIndex].title);
                setPrevValue(mapper[currentIndex].prevValue);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [index, JSON.stringify(bikeInspectionDetails[currentMapper])]);

    const onCheckboxHandler = () => {
        let mapper = [...existingMapper];
        const currentIndex = mapper.findIndex(item => item.index === index);
        if (currentIndex > -1) {
            mapper[currentIndex].checked = !mapper[currentIndex].checked;
            setCheckBoxChecked(mapper[currentIndex].checked);
        }
        // if(mapper[index].dependentFields){
        //     dependentFields = mapper[index].dependentFields;
        // }

        resetInspectionBikeDetailByKeyConnect(currentMapper, mapper);

    };

    return (
        <div className={styles.imageStrip}>
            <div className={styles.imageDetails}>
                <div className={styles.checkboxWrapper}>
                    {versionViewMode && (<Checkbox
                        onChangeCallback={() => onCheckboxHandler()}
                        name={`${currentMapper}_${index}`}
                        id={`${currentMapper}_${index}`}
                        checkedStatus={checkBoxChecked}
                    />)}
                    <p className={styles.text}>{text}</p>
                </div>
                <p className={styles.rejected}>{prevValue}</p>
            </div>
            {
                treadValue && <p className={styles.text}>{treadValue.value[0]}</p>
            }
        </div>
    );
};

export default ImageCheckboxBike;

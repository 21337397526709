import { createTypes } from "reduxsauce";

export default createTypes(
    `
    RESET_SEARCH
    RESET_SEARCH_BY_KEY
`,
    {
        prefix: "search/"
    }
);

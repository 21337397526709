import { createReducer } from "reduxsauce";
import { Types } from "./types";
import { FILTER_CONFIG } from './constants';

export const INITIAL_STATE = {
    isLoading: true,
    data: [],
    toast: { type: '', msg: '' },
    filters: FILTER_CONFIG,
    addUpdateSuccess: null
};

const showLoader = (state = INITIAL_STATE) => ({
    ...state, isLoading: true
});

const hideLoader = (state = INITIAL_STATE) => ({
    ...state, isLoading: false
});

const fetchXFactorListSuccess = (state = INITIAL_STATE, { data }) => ({
    ...state, data
});

const fetchXFactorListFailure = (state = INITIAL_STATE, { data }) => ({
    ...state, error: true
});

const addUpdateSuccess = (state = INITIAL_STATE) => ({
    ...state, addUpdateSuccess: true
});

const addUpdateError = (state = INITIAL_STATE) => ({
    ...state, addUpdateSuccess: false
});

const addUpdateReset = (state = INITIAL_STATE) => ({
    ...state, addUpdateSuccess: null
});

const setToast = (state = INITIAL_STATE, { data }) => ({
    ...state, toast: { type: data.type, msg: data.msg }
});

const resetToast = (state = INITIAL_STATE) => ({
    ...state, toast: { type: '', msg: ''}
});

const updateFilter = (state = INITIAL_STATE, { data: { id, data } }) => {
    return {
        ...state,
        filters: {
            ...state.filters,
            [id]: {
                ...state.filters[id],
                ...data
            }
        }
    };
};

export const HANDLERS = {
    [Types.SHOW_LOADER]: showLoader,
    [Types.HIDE_LOADER]: hideLoader,
    [Types.FETCH_X_FACTOR_LIST]: fetchXFactorListSuccess,
    [Types.FETCH_X_FACTOR_LIST_SUCCESS]: fetchXFactorListSuccess,
    [Types.FETCH_X_FACTOR_LIST_ERROR]: fetchXFactorListFailure,
    [Types.ADD_UPDATE_SUCCESS]: addUpdateSuccess,
    [Types.ADD_UPDATE_RESET]: addUpdateReset,
    [Types.ADD_UPDATE_ERROR]: addUpdateError,
    [Types.SET_TOAST]: setToast,
    [Types.RESET_TOAST]: resetToast,
    [Types.UPDATE_FILTER]: updateFilter,
};

export default createReducer(INITIAL_STATE, HANDLERS);
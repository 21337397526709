import React from "react";
import PropTypes from "prop-types";
import styles from "./h2.module.css";

const H2 = (props) => {
    const { text } = props;
    let heading = (<h2 className={`${styles.heading} ${props.className}`}>{text}</h2>);
  
    return heading;
};

H2.propTypes = {
    text: PropTypes.string,
    color: PropTypes.string
};

export default H2;
import React, { useState } from "react";
import Zoom from "../../components/zoom/component";
import { TransformComponent } from "react-zoom-pan-pinch";
import { TransformWrapper } from "react-zoom-pan-pinch";
import styles from "./image-with-zoom.module.css";
import Rotation from "../images/rotation.svg";
import { thumborUrl } from "../../utils/url-constants";
import Popup from "../popup";
import InspectionPocPopup from "../inspection-poc-popup";
import Button from "../shared/button";
import ZoomIcon from "../../components/images/zoom.png";
import { getFromLocalStorage } from "../../utils/helper";
import { LOCAL_STORAGE_KEYS } from "../../utils/app-constants";

const ImageWithZoom = ({ url, alt, index, zoomEnabled = true, height, wheelZoomDisabled = true, editImage = false, mapper, currentMapper, enlargeImage = false, inspectionFlow, inspectionDetails, versionViewMode = true }) => {
    const [rotateDeg, setRotateDeg] = useState(0);
    const cdnBaseUrl = !!getFromLocalStorage(LOCAL_STORAGE_KEYS.IMAGE_HOST_URL) ? getFromLocalStorage(LOCAL_STORAGE_KEYS.IMAGE_HOST_URL) : thumborUrl();
    // const { imagequalityParam } = !!getFromLocalStorage(LOCAL_STORAGE_KEYS.IMAGE_QUALITY_PARAM) ? getFromLocalStorage(LOCAL_STORAGE_KEYS.IMAGE_QUALITY_PARAM) : ""; //inspectionDetails;
    const imagequalityParam = !!getFromLocalStorage(LOCAL_STORAGE_KEYS.IMAGE_QUALITY_PARAM) ? getFromLocalStorage(LOCAL_STORAGE_KEYS.IMAGE_QUALITY_PARAM) : ""; //inspectionDetails;

    const rotate = () => {
        let newRotation = rotateDeg + 90;
        if (newRotation >= 360) {
            newRotation = - 360;
        }
        setRotateDeg(newRotation);
    };
    const [popupOpen, setPopupOpen] = useState(false);

    const onPopupOpen = () => {
        setPopupOpen(true);
    };
    const onPopupClose = () => {
        setPopupOpen(false);
    };
    const viewEnlargeImage = (index, curMaper, url) => {
        var url = "/view-image/" + index + "?url=" + encodeURI(url);
        window.open(url, "_blank", 'noopener');
    };
    return (
        <>
            <TransformWrapper
                defaultScale={1}
                options={[]}
                wheel={{ disabled: wheelZoomDisabled }}
            >

                {({ zoomIn, zoomOut, ...rest }) => (
                    <div className={styles.staticImageWrapper}>
                        <TransformComponent>
                            <img style={{ transform: `rotate(${rotateDeg}deg)` }}
                                key={index}
                                src={`${cdnBaseUrl}${url}${imagequalityParam}`}
                                // src={`${cdnBaseUrl}x${height}/${url}`}
                                alt={alt}
                            />
                        </TransformComponent>
                        {
                            zoomEnabled && <div className={styles.zoomWrapper}>
                                <div className={styles.zoomRotationWrapper}>
                                    <Zoom zoomIn={zoomIn} zoomOut={zoomOut} />
                                    <button className={styles.rotation} onClick={rotate}>
                                        <img src={Rotation} alt="rotation" />
                                    </button>
                                </div>
                                {
                                    versionViewMode && editImage && <Button classNames="imageEditCta" ctaText="Edit" onClick={() => { onPopupOpen(index); }} />
                                }
                            </div>

                        }
                        {
                            versionViewMode &&!zoomEnabled && editImage &&
                            <Button classNames="imageEditCta" ctaText="Edit" onClick={() => { onPopupOpen(index); }} />
                        }
                        {
                            !zoomEnabled && enlargeImage &&
                            <a href={"/view-image/0?url=" + url} target="_blank" className={styles.zoomIcon} ><img src={ZoomIcon} alt="zoom" /></a>

                            // <a href="#" className={styles.zoomIcon} onClick={() => { viewEnlargeImage(index, currentMapper, url); }}><img src={ZoomIcon} alt="zoom" /></a>
                        }
                    </div>
                )}

            </TransformWrapper>
            {
                index > -1 && editImage && <Popup isOpen={popupOpen} close={onPopupClose}>
                    <InspectionPocPopup index={index} close={onPopupClose} mapper={mapper} setPopupOpen={setPopupOpen} currentMapper={currentMapper} inspectionFlow={inspectionFlow} />
                </Popup>
            }
        </>

    );
};

export default ImageWithZoom;

import React, { useEffect, useState, useRef } from "react";
import styles from "./customer-audit-process.module.css";
import RightSign from "./images/rightSign.svg";
import CloseIcon from "./images/closeIcon.svg";
import RadioButton from  "../../components/shared/radio-button";
import Button from  "../../components/shared/button";
import { InspectionService } from "../../service";
import Loader from '../../components/loader';
import { ToastContainer, toast } from 'react-toastify';
import { getCurrentTimeInSecond } from "../../utils/dateHelper";

const CustomerAudit = ({
    match  
}) => {
    const { params: { appointmentId, expiryTime } } = match;
    const [data, setData ] = useState({});
    const [errorMessage, setErrorMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
    const currentTimeInSecond = getCurrentTimeInSecond();

    useEffect(() => {   
        if(currentTimeInSecond > expiryTime) {
            setErrorMessage("The Link has been expired!"); 
            setIsLoading(false); 
        } else {
            InspectionService.getCustomerAuditData(appointmentId)
                .then(res => {            
                    if(res.data.detail !== undefined) {
                        setData(res.data.detail);  
                        setIsLoading(false);         
                    }        
                })
                .catch(e => {
                    if( e.detail !== undefined) {
                        setErrorMessage(e.detail);
                        setIsLoading(false);
                    }
                });        
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appointmentId]);

    let status = null;
    const setStatus = (event) => {
        status = event.target.value;  
    };

    const submitRequestData = () => {
        if(!status) {
            toast.error('Please select feedback option');
            return false;  
        }
        setIsLoadingSubmit(true);

        const requestObject = {
            appointmentId,
            status: status,
        };
        InspectionService.submitCustomerAuditData(requestObject, appointmentId).then(response => {
            setIsLoadingSubmit(false);
            const { data: {detail} } = response;
            toast.success(detail);
            setErrorMessage(detail);      
        }).catch(error => {
            setIsLoadingSubmit(false);
            toast.error('Error::'+error.detail);
        });
  
    };

    return (
        isLoading ? <Loader/> : 
            <div className={styles.customerAuditProcess}>
                <div className={styles.inspectionTitle}>
                    <img src={RightSign} alt="sign" />
                    <h1>Inspection Completed</h1>
                </div>
                {
                    (errorMessage) 
                        ? 
                        <div className={styles.appointmentIdSection}><ul><li>{errorMessage}</li></ul></div>
                        :
                        <> 
                            <div className={styles.appointmentIdSection}>
                                <ul>
                                    <li>
                                        <h3>{data.carDetails.customerName}</h3>
                                        <span>Appointment ID</span>
                                    </li>
                                    <li>
                                        <span>{data.carDetails.make} {data.carDetails.model} {data.carDetails.year}</span>
                                        <span>{data.appointmentId}</span>
                                    </li>
                                </ul>
                            </div>
                            <h2 className={styles.title}>Our evaluator made a few changes to your report, please verify</h2>
                            <div className={styles.detailsSection}>
                                <ul>
                                    <li className={styles.titles}>
                                        <h3></h3>
                                        <span>YOURS</span>
                                        <span>EVALUATOR</span>
                                    </li>
                                    {
                                        data.customerInput && Object.keys(data.customerInput).map((key, index)=>(
                                            <li key={index} >
                                                <h3>{data.customerInput[`${key}`]["title"]}</h3>                    
                                                { <span className={styles.valueText}>{data.customerInput[`${key}`]["value"]}</span> }
                                                { <span>{data.cjInput[`${key}`]["value"]}</span> }
                                            </li>
                                        ))
                                    }
                                </ul>
                            </div>
                            <div className={styles.feedbackSection}>
                                <h4>Please share your feedback</h4>
                                <div className={styles.radioButtonSection} onChange={setStatus.bind(this)}>
                                    <RadioButton name="status" value="approved" text="Satisfied with the details, go ahead !" />
                                    <RadioButton name="status" value="rejected" text="Not satisifed with the details, send for correction." />
                                </div>
                            </div>
                            <div className={styles.ctaWrapper }>
                                <Button onClick={submitRequestData}  classNames="primaryButton" ctaText="SUBMIT" disabled={isLoadingSubmit} />
                            </div>
                            <p className={styles.text}>Please contact car evaluator {data.inspectionBy} for any clarification</p>
                        </>
                }
                <ToastContainer />
            </div>
    );
};
export default CustomerAudit;


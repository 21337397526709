import React, { useEffect, useState } from "react";
import { InspectionService } from "../../service";
import SelectComponent from "../shared/select";
import { RESET_REDUCER_KEYS } from "../../utils/app-constants";
import { isUserRoleBIkeQc } from "../../utils/helper";
import { BIKE_DETAIL_KEYS } from "../../utils/app-constants";

const Rto = ({ resetInspectionDetailByKeyConnect, detail, inspectionDetails,
    bikeInspectionDetails, resetInspectionBikeDetailByKeyConnect }) => {

    let isBikeQc = isUserRoleBIkeQc();
    const { key } = detail;
    const { documentsMapper } = inspectionDetails;
    const { bikeDocumentsMapper } = bikeInspectionDetails;
    const [rtoDropDown, setRtoDropDown] = useState([]);
    const [selectedRto, setSelectedRto] = useState("");
    let selectedState = "";// documentsMapper.find(item=>item.key === "registrationState").newValue;
    if (isBikeQc) {
        selectedState = bikeDocumentsMapper.find(item => item.key === BIKE_DETAIL_KEYS.Registration_State).newValue;
    } else {
        selectedState = documentsMapper.find(item => item.key === "registrationState").newValue;
    }

    useEffect(() => {
        let stateId = documentsMapper.find(item => item.key === "registrationState");
        if (stateId) {
            stateId = stateId.newId ? stateId.newId : stateId.id;
            InspectionService.getRtoList(stateId)
                .then(res => {
                    const { data: { detail } } = res;
                    if (detail.length > 0) {
                        const dropdown = detail.map(rto => {
                            return {
                                label: `${rto.rtoCode} ${rto.rto}`,
                                value: rto.id,
                                rtoCode: rto.rtoCode
                            };
                        });
                        setRtoDropDown(dropdown);
                    }
                })
                .catch(err => {

                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(documentsMapper)]);

    useEffect(() => {
        let stateId = bikeDocumentsMapper.find(item => item.key === BIKE_DETAIL_KEYS.Registration_State);
        if (stateId) {
            stateId = stateId.newId ? stateId.newId : stateId.id;
            InspectionService.getRtoList(stateId)
                .then(res => {
                    const { data: { detail } } = res;
                    if (detail.length > 0) {
                        const dropdown = detail.map(rto => {
                            return {
                                label: `${rto.rtoCode} ${rto.rto}`,
                                value: rto.id,
                                rtoCode: rto.rtoCode
                            };
                        });
                        setRtoDropDown(dropdown);
                    }
                })
                .catch(err => {

                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(bikeDocumentsMapper)]);

    useEffect(() => {
        if (selectedRto) {
            setNewValue();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRto]);

    const onRtoChange = (value) => {
        setSelectedRto(value);
    };

    const setNewValue = () => {
        let mapper = isBikeQc ? [...bikeDocumentsMapper] : [...documentsMapper];
        const newValue = selectedRto;
        let dependentFields = [];
        mapper = mapper.map(map => {
            let element = { ...map };
            if (map.key === key) {
                element.newValue = rtoDropDown.find(rto => rto.value === newValue).label;
                element.newId = newValue;
                element.newRtoCode = rtoDropDown.find(rto => rto.value === newValue).rtoCode;
                if (element.dependentFields && element.dependentFields.length > 0) {
                    dependentFields = element.dependentFields;
                }
            }

            return element;
        });

        mapper = mapper.map(map => {
            let element = { ...map };
            if (dependentFields.find(field => field === element.key)) {
                element.checked = true;
            }

            return element;
        });
        if (isBikeQc) {
            resetInspectionBikeDetailByKeyConnect(RESET_REDUCER_KEYS.BIKE_DOCUMENTS_MAPPER, mapper);
        } else {
            resetInspectionDetailByKeyConnect(RESET_REDUCER_KEYS.DOCUMENTS_MAPPER, mapper);
        }
    };

    return (
        <div>
            <SelectComponent
                placeholder="RTO"
                optionsList={rtoDropDown}
                onChange={onRtoChange}
                name={selectedState}
            />
        </div>
    );
};

export default Rto;

import React, { useState, useEffect } from "react";
import Header from "../../components/header";
import Back from "../../components/back";
import styles from "./inspection-bike-version.module.css";
import { 
    getFromLocalStorage, 
    setImageHostToLocalStorageForBike, 
    setVideoBaseUrlToLocalStorageForBike 
} from "../../utils/helper";
import { LOCAL_STORAGE_KEYS, ROLE_KEYS, NUMBERS } from "../../utils/app-constants";
import moment from "moment";
// import Search from "../../components/shared/search";
import Searchicon from "../../components/images/search.svg";
import {InspectionService} from '../../service';
import Loader from '../../components/loader';

// {{base_url}}/v2/inspection?user=test3@cars24.com&role=qc&client=web&enableDuration=0&logData=1&action=Search&endDate=2020-03-23&startDate=2020-03-01&appointmentId=1001599799
const InspectionBikeVersion = ({ history, match }) => {
    const localData = getFromLocalStorage(LOCAL_STORAGE_KEYS.LOGIN_DATA);
    let role = localData ? JSON.parse(localData)[LOCAL_STORAGE_KEYS.ROLE] : "";
    const [data, setData] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [isFetching, setIsFetching] = useState(false);
    const { params: { appointment } } = match;
    const [appointmentId, setAppointmentId] = useState(appointment); //1033646766
    useEffect(() => {
        if(appointment) {
            getBikeVersionData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[appointment]);

    useEffect(() => {
        InspectionService.getBikePublicConfigData().then(response => {
            const { data: { imageUrlTemplate, videoBaseUrl } } = response;
            if (!!imageUrlTemplate) {
                setImageHostToLocalStorageForBike(imageUrlTemplate);
            }
            if(videoBaseUrl) {
                setVideoBaseUrlToLocalStorageForBike(videoBaseUrl);
            }
        }).catch(error => {
            console.log('Error::', error);
        });
    }, []);

    const getBikeVersionData = () => {
        setIsFetching(true);
        setData([]);
        setErrorMessage('');
        InspectionService.getBikeVersion(appointment, 'all').then(data => {
            setIsFetching(false);
            setData(data.data);
        }).catch(error => {
            setIsFetching(false);
            console.log('Error::',error);
            setErrorMessage(error.message);
        });
    };
    const versionSelection = (version) => {
        history.push({pathname:`/bike-review/${appointmentId}?version=${version}`});
    };

    const onChangeHandler = (event) => {
        setAppointmentId(event.target.value);
    };

    const onSearchKeyPress = (e) => {
        const { charCode } = e;
        if (charCode === NUMBERS.THIRTEEN) {
            onSearchClick();
        }
    };
    
    const onSearchClick = () => {
        if(appointmentId && !isFetching) {
            history.push({pathname: `/inspection-bike-version/${appointmentId}`});
            // getBikeVersionData(appointmentId);

        }
    };

    const covetEpochToDate = (unixTimeStamp) => {
        let _date = moment.unix(unixTimeStamp).format('DD-MM-YYYY h:mm:ss A');
        return _date;
    };

    return (
        <div className={styles.inspectionVersion}>
            <Header showVersion={true} history={history} />
            <div className={styles.backSearchWrapper}>
                <Back history={history} versionFlag={true} />
                <div className={styles.search}>
                    <input 
                        type="text" 
                        name="search" 
                        placeholder="Appointment ID" 
                        onChange={onChangeHandler} 
                        value={appointmentId} 
                        onKeyPress={onSearchKeyPress} 
                        autoComplete="off" 
                    />
                    {/* {isSearchedData && <button onClick={clearSearch}>x</button>} */}
                    <button>
                        <img src={Searchicon} alt="search" onClick={onSearchClick} />
                    </button>
                </div>
            </div>
            {errorMessage && <span className={styles.errorMessage}>{errorMessage}</span>}
            {isFetching && <Loader />}
            {
                data && !!data.length && <div className={styles.dataTable}>
                    <table cellPadding="0" cellSpacing="0" width="100%">
                        <thead>
                            <tr>
                                <th>Make</th>
                                <th>Model</th>
                                <th>Variant</th>
                                <th>Version</th>
                                <th>Inspection Status</th>
                                {/* <th>Inspection by</th> */}
                                <th>QC by</th>
                                {/* <th>InspectionStartTime</th> */}
                                <th>InspectionEndTime</th>
                                {/* <th>QCStartTime</th> */}
                                {/* <th>QCEndTime</th> */}
                                <th>View Bike Report</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                data && data.length > 0 &&
                                // data.find(item => item.appointmentId == appointment) &&
                                // data.find(item => item.appointmentId == appointment).versions &&
                                // data.find(item => item.appointmentId == appointment).versions.length > 0 &&
                                data.map(item => {
                                    return <tr onClick={(role === ROLE_KEYS.QC || role === ROLE_KEYS.QC_VERSION) ? () => { versionSelection(item.version); } : ""}>
                                        <td>{item.make}</td>
                                        <td>{item.model}</td>
                                        <td>{item.variant}</td>
                                        <td>{item.version}</td>
                                        <td>{item.status}</td>
                                        {/* <td>{item.inspectionBy}</td> */}
                                        <td>{item.qcUser}</td>
                                        {/* <td>{item.inspectionStartTime}</td> */}
                                        <td>{covetEpochToDate(item.inspectionEndTime)}</td>
                                        {/* <td>{item.reviewStartTime}</td> */}
                                        {/* <td>{item.reviewEndTime}</td> */}
                                        <td onClick={(e) => { e.stopPropagation(); }}>{item.pdfUrl && <a target="_blank" href={`/bike-report/${appointment}?version=${item.version}`}>View Bike Report</a>}</td>
                                    </tr>;
                                })
                            }
                        </tbody>
                    </table>
                </div>
            }
        </div>
    );
};

export default InspectionBikeVersion;
import React from 'react';
import styles from './styles.module.css';

const AllOtherParts = (props) => {
    return (
        <div className={styles.allOtherPartsTableContainer}>
            <table style={{ width: "100%" }} className={styles.allOtherPartsTable}>
                {props.data.map((row, i) => (
                    <tr key={i}>
                        <td
                            width="40%"
                            style={{ visibility: props.data[i-1]?.col1 === row.col1 ? "hidden" : "visible" }}
                        >
                            {row.col1}
                        </td>
                        <td width="18%">{row.col2}</td>
                        <td width="2%">{row.col3}</td>
                        <td width="40%">{row.col4}</td>
                    </tr>
                ))}
            </table>
        </div>
    );
};

export default AllOtherParts;
/* eslint-disable indent */
import React, { useEffect, useState } from "react";
import SelectComponent from "../shared/select";
import styles from "./inspection-filters.module.css";
import { getCategory } from "../../utils/mocApi";
import { CHASSIS_EMBOSSING, RESET_REDUCER_STATE_KEYS, LOCAL_STORAGE_KEYS } from "../../utils/app-constants";
import Button from "../shared/button";
import { Multiselect } from 'multiselect-react-dropdown';
import { reloadWithDelay, isExcludedUser, getFromLocalStorage } from "../../utils/helper";

const InspectionFilters = ({
  inspectionFiltersReducer,
  fetchStoreLocationConnect,
  resetFilterByKeyConnect,
  fetchAssigneeConnect,
  resetSearchByKeyConnect,
  resetInspectionByKeyConnect
}) => {
  let { cityLocation, assigneeList, citySelected, locationSelected, categorySelected, assigneeSelected } = inspectionFiltersReducer;
  const [centersSelected, setCentresSelected] = useState([]);
  const [citiesPreSelected, setCitiesPreSelected] = useState([]);
  const [cityDropdown, setCityDropDown] = useState([]);
  const [centerDropdown, setCenterDropDown] = useState([]);
  const [cityCenter, setCityCenter] = useState([]);
  const category = getCategory();
  const multiselectRef = React.createRef();
  const [categoryDefaultObj, setcategoryDefaultObj] = useState({});
  const [assigneeDefaultObj, setassigneeDefaultObj] = useState({});

  const [catFilter, setCatFilter] = useState([]);

  let assignLabel = "";

  let isUserExludedUser = isExcludedUser();
  if ((!!assigneeList && assigneeList.length > 0) && !isUserExludedUser) {
    let email = getFromLocalStorage(LOCAL_STORAGE_KEYS.EMAIL);
    assigneeList = assigneeList.filter(x => x.email === email);
  }

  if (!!assigneeList && assigneeList.length > 0) {
    let assignObj = assigneeList.find(x => x.id === assigneeSelected);
    if (!!assignObj) {
      assignLabel = assignObj.name;
    }
  }
  useEffect(() => {
    fetchStoreLocationConnect();
    fetchAssigneeConnect('qc');
    let result = [];
    if (citySelected.length > 0) {
      citySelected.map(item => {
        item.key = item.id;
        result.push(item);
      });
      setCitiesPreSelected(result);
      updateCenters(citySelected);
    }
    // if (!!categorySelected && !!category && category.length > 0) {
    //   let catObj = category.find(x => x.key === categorySelected);
    //   setcategoryDefaultObj({ label: !!catObj ? catObj.key : "", value: !!catObj ? catObj.value : "" });
    // }
    // if (!!assigneeSelected && !!assigneeList && assigneeList.length > 0) {
    //   let assignObj = assigneeList.find(x => x.email === assigneeSelected);
    //   setassigneeDefaultObj({ label: !!assignObj ? assignObj.name : "", value: !!assignObj ? assignObj.email : "" });
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let arr = [];
    if (cityLocation) {
      cityLocation.map(city => {
        arr.push({
          id: city.city_id,
          name: city.city_name,
          locations: city.locations
        });
      });
    }
    setCityDropDown(arr);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cityLocation]);

  const updateCenters = (cityOptions) => {
    resetFilterByKeyConnect("citySelected", cityOptions);
    let locations = [];
    for (let i = 0; i < cityOptions.length; i++) {
      locations = [...locations, ...cityOptions[i].locations];
    }
    setCenterDropDown(locations.map(item => {
      return {
        id: item.location_id,
        name: item.name
      };
    }));
  };

  const onCityChange = (option, questionid) => {
    const filteredCityList = cityLocation.find((x) => { return x.city_id === option; });
    const locations = !!filteredCityList ? filteredCityList.locations : [];
    resetFilterByKeyConnect("citySelected", option);
    resetSearchByKeyConnect(RESET_REDUCER_STATE_KEYS.QUERY, null);
    setCityCenter(locations);
  };
  const onLocationChange = (option, questionid) => {
    resetFilterByKeyConnect("locationSelected", option);
    resetSearchByKeyConnect(RESET_REDUCER_STATE_KEYS.QUERY, null);
  };
  const onAssigneeChange = (option, questionid) => {
    resetFilterByKeyConnect("assigneeSelected", option);
    resetSearchByKeyConnect(RESET_REDUCER_STATE_KEYS.QUERY, null);
  };

  const onCategoryChange = (option, questionid) => {
    resetFilterByKeyConnect("categorySelected", option);
    resetSearchByKeyConnect(RESET_REDUCER_STATE_KEYS.QUERY, null);
  };

  const onSelectCities = (optionsList, selectedItem) => {
    updateCenters(optionsList);
  };

  const onRemoveCities = (optionList, removedItem) => {
    updateCenters(optionList);
    multiselectRef.current.resetSelectedValues();
    onSelectCities(optionList);
    resetFilterByKeyConnect("locationSelected", []);
  };

  const onSelectCenters = (optionsList, selectedItem) => {
    let storeIds = [];
    optionsList.map(item => {
      storeIds.push(item.id);
    });
    // setCentresSelected(optionsList);
    resetFilterByKeyConnect("locationSelected", optionsList);
    resetSearchByKeyConnect(RESET_REDUCER_STATE_KEYS.QUERY, null);
    // const filteredCityList = cityLocation.find((x) => { return x.city_id === option; });
    // const locations = !!filteredCityList ? filteredCityList.locations : [];
  };

  const onRemoveCenters = (optionList, removedItem) => {
    let storeIds = [];
    optionList.map(item => {
      storeIds.push(item.id);
    });
    // setCentresSelected(optionList);
    resetFilterByKeyConnect("locationSelected", optionList);
    resetSearchByKeyConnect(RESET_REDUCER_STATE_KEYS.QUERY, null);
  };

  const getDefaultCategoryValue = () => {
    let arr = category.find(item => item.value === categorySelected);
    return arr;
  };

  return (
    <div className={styles.locationWrapper}>
      {
        // cityLocation && cityLocation.length > 0 &&
        <div className={styles.boxSelect}>
          {/*<SelectComponent*/}
          {/*  placeholder={"City"}*/}
          {/*  optionsList={cityLocation.map((item) => {*/}
          {/*    return {*/}
          {/*      label: item.city_name,*/}
          {/*      value: item.city_id*/}
          {/*    };*/}
          {/*  })}*/}
          {/*  onChange={onCityChange}*/}
          {/*/>*/}
          <Multiselect
            options={cityDropdown} // Options to display in the dropdown
            selectedValues={citiesPreSelected} // Preselected value to persist in dropdown
            onSelect={onSelectCities} // Function will trigger on select event
            onRemove={onRemoveCities} // Function will trigger on remove event
            displayValue="name"// Property name to display in the dropdown options
            placeholder="City"
          />
        </div>
      }
      {

        <div className={styles.boxSelect}>
          {/*<SelectComponent*/}
          {/*  placeholder={"Center"}*/}
          {/*  optionsList={cityCenter.map((item) => {*/}
          {/*    return {*/}
          {/*      label: item.name,*/}
          {/*      value: item.location_id*/}
          {/*    };*/}
          {/*  })}*/}
          {/*  onChange={onLocationChange}*/}
          {/*/>*/}
          <Multiselect
            options={centerDropdown} // Options to display in the dropdown
            selectedValues={locationSelected} // Preselected value to persist in dropdown
            onSelect={onSelectCenters} // Function will trigger on select event
            onRemove={onRemoveCenters} // Function will trigger on remove event
            displayValue="name" // Property name to display in the dropdown options
            ref={multiselectRef}
            placeholder="Center"
          />
        </div>
      }
      {
        <div className={styles.boxSelect}>
          <SelectComponent
            placeholder={"Category"}
            optionsList={category.map((item) => {
              return {
                label: item.key,
                value: item.value
              };
            })}
            // defaultValue={categoryDefaultObj}
            // defaultValue={{ label: !!_categ ? _categ : "", value: !!_categ ? _categ : "" }}
            defaultValue={!!categorySelected ? { label: !!categorySelected ? categorySelected : "", value: !!categorySelected ? categorySelected : "" } : ""}
            onChange={onCategoryChange}
          // defaultValue={() => { return getDefaultCategoryValue(); }}
          />
        </div>
      }
      {
        // assigneeList && assigneeList.length > 0 &&
        <div className={styles.boxSelect}>
          <SelectComponent
            placeholder={"Asignee"}
            optionsList={assigneeList.map((item) => {
              return {
                label: item.name,
                value: item.id
              };
            })}
            onChange={onAssigneeChange}
            // defaultValue={assigneeDefaultObj}
            // defaultValue={{ label: "test 3", value: "test3@cars24.com" }}
            defaultValue={!!assigneeSelected ? { label: assignLabel, value: assigneeSelected } : ""}
            //assigneeSelected
            name={"assgn"}
          />
        </div>
      }

      <div className={styles.boxSelect}>
        <Button ctaText={"Reset"} classNames="secondaryButton" onClick={() => { reloadWithDelay(1000); }} />
      </div>

    </div>
  );
};

export default InspectionFilters;

import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styles from './x-factor.module.css';
import { Creators } from './types';
import SelectComponent from '../../components/shared/select/component';
import InputText from '../../components/shared/input-text/component';
import H2 from '../../components/h2/component';
import Button from '../../components/shared/button/component';
import { fetchStoreLocation } from '../../components/inspection-filters/actions';
import { updateXFactor } from './actions';
import Loader from '../../components/loader/component';
import { getFromLocalStorage } from '../../utils/helper';
import { LOCAL_STORAGE_KEYS } from '../../utils/app-constants';
import Date from '../../components/shared/filters/Date';
import { UPDATE_FORM_IDS as FORM_IDS } from './constants';

const UpdateXFactor = ({
    id,
    date,
    storeId,
    storeName,
    slotXFactor
}) => {
    const dispatch = useDispatch();
    const [errors, setErrors] = useState({});
    const [formData, setFormData] = useState({
        [FORM_IDS.STORE_ID]: {
            label: `${storeId} : ${storeName}`,
            value: `${storeId} : ${storeName}`
        },
        [FORM_IDS.DATE]: date,
        [FORM_IDS.SLOT]: "",
        [FORM_IDS.X_FACTOR]: ""
    });

    const { isLoading } = useSelector(state => state.xFactorReducer);
    const allStores = useSelector(state => state.inspectionFiltersReducer.cityLocation);

    if(!allStores.length) {
        dispatch(fetchStoreLocation());
    }

    useEffect(() => {
        return () => dispatch(Creators.addUpdateReset());
    }, []);

    const ALL_STORE_IDS = useMemo(() => {
        return allStores.reduce((acc, curr) => {
            acc.push(
                ...curr.locations.map(loc =>
                    ({
                        label: `${loc.location_id} : ${loc.name}`,
                        value: `${loc.location_id} : ${loc.name}`
                    })
                )
            );
            return acc;
        }, []);
    }, [allStores]);
    const SLOT_OPTIONS = useMemo(() => (
        Object.keys(slotXFactor).map(slot => ({ label: slot, value: slot }))
    ), []);

    const isFormInvalid = () => {
        let errors = {};

        if (!formData.slotTime) {
            errors[FORM_IDS.SLOT] = "Slot is required";
        }
        if (!formData.xFactor) {
            errors[FORM_IDS.X_FACTOR] = "X factor is required";
        }

        if (Object.keys(errors).length) {
            setErrors(errors);
            return true;
        }
        return false;
    };

    const handleFormChange = (id, value) => {
        setFormData({ ...formData, [id]: value });
    }

    const handleUpdateBtnClick = () => {
        if (isFormInvalid()) return;

        let data = {
            [FORM_IDS.SLOT]: formData[FORM_IDS.SLOT].value,
            [FORM_IDS.X_FACTOR]: parseFloat(formData[FORM_IDS.X_FACTOR]),
            updatedBy: getFromLocalStorage(LOCAL_STORAGE_KEYS.EMAIL)
        };

        dispatch(updateXFactor(id, data));
        setErrors({});
    };

    if(isLoading) return <Loader />;

    return (
        <div>

            <H2 text={"Update value"} className="mb-lg" />

            <div className="mb-lg">
                <SelectComponent
                    placeholder="Select Store Id"
                    optionsList={ALL_STORE_IDS}
                    value={formData[FORM_IDS.STORE_ID]}
                    onChange={value => handleFormChange(FORM_IDS.STORE_ID, { label: value, value })}
                    isDisabled
                />
            </div>

            <div className="mb-lg">
                <Date
                    value={formData[FORM_IDS.DATE]}
                    onChange={e => handleFormChange(FORM_IDS.DATE, e.target.value)}
                    disabled
                />
            </div>

            <div className="mb-lg">
                <SelectComponent
                    hasError={errors[FORM_IDS.SLOT]}
                    error={errors[FORM_IDS.SLOT]}
                    placeholder="Select Slot"
                    optionsList={SLOT_OPTIONS}
                    value={formData[FORM_IDS.SLOT]}
                    onChange={value => handleFormChange(FORM_IDS.SLOT, { label: value, value })}
                />
            </div>

            <div className="mb-lg">
                <InputText
                    type="number"
                    placeholder="Enter X factor"
                    value={formData[FORM_IDS.X_FACTOR]}
                    className={errors[FORM_IDS.X_FACTOR] ? styles.errorInput : ""}
                    onChange={e => handleFormChange(FORM_IDS.X_FACTOR, e.target.value)}
                />
                {errors[FORM_IDS.X_FACTOR] && <span className={styles.errorText}>{errors[FORM_IDS.X_FACTOR]}</span>}
            </div>
            
            <Button
                ctaText={"Update"}
                classNames="primaryButton"
                onClick={handleUpdateBtnClick}
            />

        </div>
    );
};

export default UpdateXFactor;
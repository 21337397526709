import React, { useState, useEffect } from 'react';
import RadioButton from '../shared/radio-button';
import style from './fuel.module.css';

const FuelLevel = ({
    fuelValue, 
    inspectionDetails, 
    currentMapper, 
    resetInspectionDetailByKeyConnect
}) => {
    const fuelLevelObject = [
        {id:'reserve' , value: 'Reserve'},
        {id:'lower' , value: '<25%'},
        {id:'average' , value: '25-50%'},
        {id:'higher' , value: '>50%'},
    ];
    const [currentFuelLevel, setCurrentFuelLevel] = useState(fuelValue);
    const currentMapperValue = [...inspectionDetails[currentMapper]];
    const fuelMapperIndex = currentMapperValue.findIndex(item => item.key === "fuelLevel");
    useEffect(() => {
        const fuelMapperValue = currentMapperValue[fuelMapperIndex];
        if(fuelValue !== currentFuelLevel) {
            fuelMapperValue.newValue = currentFuelLevel;
            currentMapperValue[fuelMapperIndex] = fuelMapperValue;
            resetInspectionDetailByKeyConnect(currentMapper, currentMapperValue);
        } else {
            fuelMapperValue.newValue = "";
            currentMapperValue[fuelMapperIndex] = fuelMapperValue;
            resetInspectionDetailByKeyConnect(currentMapper, currentMapperValue);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentFuelLevel]);
  
    return (
        <div className={style.fuelWrapper}>
            {fuelLevelObject.map(item =>( 
                <RadioButton 
                    text={item.value}
                    id={item.id}
                    name="fuelLevel"
                    value={item.value}
                    checkedStatus={currentFuelLevel.toLocaleLowerCase() === item.value.toLocaleLowerCase()}
                    labelClass="fontWeightBold"
                    onClickCallback={setCurrentFuelLevel}
                />
            ))}
      
        </div>
    );
};

export default FuelLevel;


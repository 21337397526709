import React, {useEffect, useState} from "react";
import SelectComponent from "../shared/select";
import {monthNames, REGISTRATION_YEAR, RESET_REDUCER_KEYS} from "../../utils/app-constants";
import {InspectionService} from "../../service";
import { isUserRoleBIkeQc } from "../../utils/helper";

const RegistrationYear = ({
    detail,
    resetInspectionDetailByKeyConnect,
    inspectionDetails,
    bikeInspectionDetails,
    resetInspectionBikeDetailByKeyConnect
}) => {
    const [selectedYear, setSelectedYear] = useState("");
    const {documentsMapper}= inspectionDetails;
    const { bikeDocumentsMapper } = bikeInspectionDetails;
    const {key} = detail;
    let isBikeQc = isUserRoleBIkeQc();
    useEffect(()=>{
        setNewValue();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[selectedYear]);

    const onYearChange = (option) => {
        setSelectedYear(option);
    };

    const setNewValue = ()=>{
        let mapper = isBikeQc ? [...bikeDocumentsMapper] : [...documentsMapper];
        let newValue = "";
        let newId = "";
        newValue = `${selectedYear}`;
        newId = `${selectedYear}`;
        mapper = mapper.map(map => {
            let element = { ...map };
            if (map.key === key) {
                element.newValue = newValue;
                element.newId = newId;
            }
            return element;
        });
        if (isBikeQc) {
            resetInspectionBikeDetailByKeyConnect(RESET_REDUCER_KEYS.BIKE_DOCUMENTS_MAPPER, mapper);
        }
        else {
            resetInspectionDetailByKeyConnect(RESET_REDUCER_KEYS.DOCUMENTS_MAPPER, mapper);
        }
    };

    return (
        <div>
            <div className="mb-5">
                <SelectComponent
                    optionsList={REGISTRATION_YEAR()}
                    onChange={onYearChange}
                    placeholder={"Year"}
                />
            </div>
        </div>
    );
};

export default RegistrationYear;

import React, { useRef, useState } from 'react'
import Header from "../../components/header";
import Back from "../../components/back";
import styles from './styles.module.css';

import { ToastContainer } from 'react-toastify';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import H2 from '../../components/h2';
import {Grid, TextField, Button}from "@material-ui/core";
import { showToastMessages} from "../../utils/helper";
import { InspectionService } from '../../service';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import moment from "moment";


const VideoTutorialUpload = () => {
const history = useHistory();
const [isSubmitting, setIsSubmitting] = useState(false);
const [formData, setFormData] = useState({
    featureTitle:'',
    description:'',
    hindiVideo:'',
    englishVideo:'',
    startDate:moment(new Date()).format("YYYY-MM-DD").toString(),
    configName:'',
});
const hindiVideoRef = useRef(null);
const englishVideoRef = useRef(null);

const resetFormDataValues = () => {
    setFormData({
        featureTitle:'',
        description:'',
        hindiVideo:'',
        englishVideo:'',
        startDate:moment(new Date()).format("YYYY-MM-DD").toString(),
        configName:'',
    });
    if (hindiVideoRef.current && englishVideoRef.current) {
        hindiVideoRef.current.value = '';
        englishVideoRef.current.value = '';
      }
}


const handleSubmit = (event) => {
    event.preventDefault();
    if(isFormValid()){
        const payload = new FormData();
        payload.append('featureTitle', formData?.featureTitle);
        payload.append('description', formData?.description);
        payload.append('hindiVideo', formData?.hindiVideo);
        payload.append('englishVideo', formData?.englishVideo);
        payload.append('startDate', formData?.startDate);
        payload.append('configName', formData?.configName);

        setIsSubmitting(true);
        InspectionService.uploadVideoTutorial(payload).then((res)=>{
            resetFormDataValues();
            showToastMessages("Video Uploaded Successfully");
        }).catch(err => {
            showToastMessages(err?.detail, false);
        }).finally(()=>{
            setIsSubmitting(false);
        });;
    }
}

const handleInputChange = (e) => {
    const {name} = e?.target || {};
    const value = e?.target?.files?.length? e?.target?.files[0] : e?.target?.value
    setFormData({
        ...formData,
        [name]: value,
      });
}

const handleDateChange = (date) => {
    const formattedDate = moment(date).format("YYYY-MM-DD").toString();
    setFormData({
        ...formData,
        ["startDate"]: formattedDate,
      });
}

const isFormValid = () => {
    if(!formData?.featureTitle){
        showToastMessages("Please Enter Video Title",false);
        return false;
    }
    else if(!formData?.description){
        showToastMessages("Please Enter Video Message",false);
        return false;
    }
    else if(!formData?.hindiVideo){
        showToastMessages("Please Upload Hindi Video",false);
        return false;
    }
    else if(!formData?.englishVideo){
        showToastMessages("Please Upload English Video",false);
        return false;
    }
    else if(!formData?.startDate){
        showToastMessages("Please Enter Date to trigger",false);
        return false;
    }
    else if(!formData?.configName){
        showToastMessages("Please Enter Store Config",false);
        return false;
    }
    else{
        return true;
    }
}
  return (
    <>
        <Header history={history} showPerformance={false} />
        <ToastContainer />
        <div className={styles.backSearchWrapper}>
            <Back history={history} versionFlag={true} />
        </div>
        <div className={styles.adminForm}>
        <H2  text="Video Tutorial Trigger"/>
      </div>
      <form onSubmit={handleSubmit} className={styles.inputAdminForm}>
        <Grid container direction="column">
          <Grid item>
            <TextField
              className={styles.inputRegNo}
              id="videoTitle-input"
              name="featureTitle"
              label="Video Title"
              type="text"
              onChange={handleInputChange}
              value={formData?.featureTitle}
            />
          </Grid>
          <Grid item >
            <TextField
              className={styles.inputRegNo}
              id="videoMessage-input"
              name="description"
              label="Video Message"
              type="text"
              onChange={handleInputChange}
              value={formData?.description}
            />
          </Grid>
          <Grid item >
            <div className={styles.uploadVideo}>
                <div className={styles.storeConfigLabel}>
                    Hindi Video Link
                </div>
            <input onChange={handleInputChange} type="file" id="hindiVideoLink-upload" name="hindiVideo" accept="video/mp4,video/x-m4v,video/*" ref={hindiVideoRef}/>
            </div>
          </Grid>
          <Grid item>
             <div className={styles.uploadVideo}>
                <div className={styles.storeConfigLabel}>
                English Video Link
                </div>
            <input onChange={handleInputChange} type="file" id="englishVideoLink-input" name="englishVideo" accept="video/mp4,video/x-m4v,video/*" ref={englishVideoRef}/>
            </div>
          </Grid>
          <Grid item>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <div className={styles.uploadVideo}>
                <div className={styles.storeConfigLabel}>
                    Date to trigger
                </div>
                <KeyboardDatePicker
                    placeholder="Date to trigger"
                    value={formData?.startDate}
                    onChange={(date) => handleDateChange(date)}
                    format="yyyy-MM-dd"
                />
                </div>
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item>
          <TextField
              className={styles.inputRegNo}
              id="storeConfig-input"
              name="configName"
              label="Store Config"
              type="text"
              onChange={handleInputChange}
              value={formData?.configName}
            />
          </Grid>
          <Button className={styles.btnContainer} variant="contained" color="primary" type="submit" disabled={isSubmitting}>
            Submit
          </Button>
        </Grid>
      </form>
    </>
  )
}
export default VideoTutorialUpload;

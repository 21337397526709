import React, { useState } from "react";
import { RESET_REDUCER_KEYS, BIKE_FUEL_TYPES, BIKE_DETAIL_KEYS, BIKE_SMOKE_COLOR, PETROL } from "../../utils/app-constants";
import SelectComponent from "../shared/select/component";

const FuelType = ({ detail, bikeInspectionDetails, resetInspectionBikeDetailByKeyConnect }) => {
    const initialValue = detail.newValue ? detail.newValue : detail.value;
    // const [keyNewValue, setKeyNewValue] = useState(initialValue);
    const { key } = detail;
    const { bikeDocumentsMapper } = bikeInspectionDetails || {};
    const setNewValue = (newValue) => {
        let mapper = [...bikeDocumentsMapper];
        // const newValue = keyNewValue;
        let dependentFields = [];
        mapper = mapper.map(map => {
            let element = { ...map };
            if (map.key === key) {
                element.newValue = newValue;
                element.newId = newValue;
                if (element.dependentFields && element.dependentFields.length > 0) {
                    dependentFields = element.dependentFields;
                }
            }
            return element;
        });
        mapper = mapper.map(map => {
            let element = { ...map };
            if (dependentFields.find(field => field === element.key)) {
                element.checked = true;
                if (newValue === PETROL) {
                    element.isVisible = true;
                    element.checked = true;
                } 
                if (newValue !== PETROL) {
                    element.isVisible = false;
                    element.checked = false;
                }
            }

            return element;
        });
        resetInspectionBikeDetailByKeyConnect(RESET_REDUCER_KEYS.BIKE_DOCUMENTS_MAPPER, mapper);
    };
    const onSelect = (value) => {
        setNewValue(value);
    };
    return (
        <div>
            <SelectComponent
                optionsList={key === BIKE_DETAIL_KEYS.Fuel_Type ? BIKE_FUEL_TYPES : BIKE_SMOKE_COLOR}
                placeholder={detail.text}
                onChange={onSelect} 
                // onBlurCallback = {onBlurText}
            />
        </div>
    );
};

export default FuelType;

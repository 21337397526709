import React, { useEffect, useState } from "react";
import SelectComponent from "../shared/select";
import { InspectionService } from "../../service";
import { RESET_REDUCER_KEYS } from "../../utils/app-constants";
import { isUserRoleBIkeQc } from "../../utils/helper";

const RegistrationState = ({
    resetInspectionDetailByKeyConnect,
    detail,
    inspectionDetails,
    bikeInspectionDetails,
    resetInspectionBikeDetailByKeyConnect
}) => {
    let isBikeQc = isUserRoleBIkeQc();
    const [stateDropDown, setStateDropDown] = useState([]);
    const [selectedState, setSelectedState] = useState("");
    const { documentsMapper } = inspectionDetails;
    const { bikeDocumentsMapper } = bikeInspectionDetails;
    const { key } = detail;

    useEffect(() => {
        InspectionService.getStateList()
            .then(res => {
                const { data: { detail } } = res;
                if (detail.length > 0) {
                    const dropdown = detail.map(state => {
                        return {
                            label: state.state_name,
                            value: state.state_id
                        };
                    });
                    setStateDropDown(dropdown);
                }

            })
            .catch(err => {

            });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (selectedState) {
            setNewValue();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedState]);

    const onStateChange = (value) => {
        setSelectedState(value);
    };

    const setNewValue = () => {
        let mapper = isBikeQc ? [...bikeDocumentsMapper] : [...documentsMapper];
        const newValue = selectedState;
        const index = mapper.findIndex(item => item.key === key);
        mapper[index].newValue = stateDropDown.find(state => state.value === newValue).label;;
        mapper[index].newId = newValue;
        //dependentFields
        let dependentFields = mapper[index].dependentFields;

        dependentFields.map(field => {
            const index = mapper.findIndex(item => item.key === field);
            mapper[index].newValue = "";
            mapper[index].newId = "";
        });
        if (isBikeQc) {
            resetInspectionBikeDetailByKeyConnect(RESET_REDUCER_KEYS.BIKE_DOCUMENTS_MAPPER, mapper);
        }
        else {
            resetInspectionDetailByKeyConnect(RESET_REDUCER_KEYS.DOCUMENTS_MAPPER, mapper);
        }
    };

    return (
        <div>
            <SelectComponent
                placeholder="State"
                optionsList={stateDropDown}
                onChange={onStateChange}
            />
        </div>
    );
};

export default RegistrationState;

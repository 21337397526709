import React, {useState} from "react";
import { useDispatch } from "react-redux";
import { INSPECTION_SECTION, RESET_REDUCER_KEYS } from "../../utils/app-constants";
import styles from "./inspection-documents-odometer.module.css";
import Checkbox from "../shared/checkbox";
import { getEditableComponent } from "../../utils/helper";
import Change from "../change";
import Remark from "../shared/remark/Remark";

const InspectionDocumentsOdometer = ({
    document,
    resetInspectionDetailByKeyConnect,
    inspectionDetails
}) => {
    const dispatch = useDispatch();
    const [isRemarkHistoryOpen, setIsRemarkHistoryOpen] = useState(false);
    const { text, checked, value, key, newValue, isEditable, isDisabled, isImportant } = document;
    const { interiorOdometerMapper } = inspectionDetails;

    const resetValues = (field) => {
        field.newId = '';
        field.newValue = '';
    };

    const onCheckboxHandler = (e, key) => {
        const { target: { checked } } = e;

        let mapper = [...interiorOdometerMapper];
        const field = mapper.find(item => item.key === key);
        field.checked = checked;
        if (!checked) {
            resetValues(field);
        }

        const fieldIndex = mapper.indexOf(field);
        mapper[fieldIndex] = field;

        resetInspectionDetailByKeyConnect("interiorOdometerMapper", mapper);
        setIsRemarkHistoryOpen(checked);
    };

    return (
        <>
        <div className={styles.imageStrip}>
            <div className={styles.imageDetails}>
                <Checkbox
                    checkedStatus={checked}
                    onChangeCallback={(e) => onCheckboxHandler(e, key)}
                    name={`interiorOdometerMapper_${key}`}
                    id={`interiorOdometerMapper_${key}`}
                    isDisabled={isDisabled}
                />
                <label className={`${styles.lableText} ${isImportant ? styles.boldField : ""}`}>{text}</label>
            </div>
            <div className={styles.dataWrapper}>
                {
                    checked && isEditable ? getEditableComponent(key, document) : newValue ?
                        <label className={`${isImportant ? styles.boldField : ""}`}>{newValue}</label> :
                        <label className={`${isImportant ? styles.boldField : ""}`}>{value}</label>
                }
                {
                    newValue && <Change value={value} />
                }

            </div>
            {!!document?.remarkHistory?.length && (
                <span
                    className={"remarkHistoryBtn"}
                    onClick={() => setIsRemarkHistoryOpen(!isRemarkHistoryOpen)}
                >
                    Remark History
                </span>
            )}
        </div>
        <Remark
            document={document}
            section={INSPECTION_SECTION.ELECTRICALS_INTERIOR}
            showRemarkInput={document.checked}
            isRemarkHistoryOpen={isRemarkHistoryOpen}
        />
        </>
    );
};

export default InspectionDocumentsOdometer;


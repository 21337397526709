import React from 'react'
import { useTable } from 'react-table';
import styles from './table.module.css';

export const Table = ({ id, columns, data, onRowClick }) => {

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  })

  const handleOnRowClick = (row) => onRowClick(row);

  return (
    <div className={styles.container}>
      <table id={id} {...getTableProps()} cellPadding="0" cellSpacing="0" width="100%">
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th
                  {...column.getHeaderProps()}
                  data-no-download={column.noDownload ? true : null}
                >
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()} onClick={onRowClick ? () => handleOnRowClick(row) : null}>
                {row.cells.map(cell => {
                  return (
                    <td
                      {...cell.getCellProps()}
                      data-no-download={cell.column.noDownload ? true : null}
                    >
                      {cell.render('Cell')}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}
import React from "react";
import PropTypes from "prop-types";
import styles from "./h3.module.css";

const H3 = (props) => {
    const { text } = props;
    let heading = (<h3 className={styles.heading}>{text}</h3>);
  
    return heading;
};

H3.propTypes = {
    text: PropTypes.string,
    color: PropTypes.string
};

export default H3;
import FirebaseInstance from "./firebase.js";

const Firebase = (function () {
    let instance = null;

    return {
        getInstance() {
            if (!instance) {
                instance = new FirebaseInstance();
            }
            return instance.firebase;
        }
    };
})();

export default Firebase;

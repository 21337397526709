import React, {useEffect, useState} from 'react';
import InspectionDocuments from '../../components/inspection-documents-bike/';
import styles from './inspection-review-bike-documents.module.css';
import Checkbox from '../../components/shared/checkbox/';
import InfoIcon from '../../components/shared/info-icon/component';
import {
    RESET_REDUCER_KEYS,
    BIKE_DETAIL_KEYS,
    PETROL,
} from '../../utils/app-constants';
import {tr} from 'date-fns/locale';

const DISPLAY_ORDER = ['regNo'];
const parseDocumentsInOrder = documents => {
    const orderedItems = [];
    DISPLAY_ORDER.forEach (item => {
        const selection = documents.find (doc => doc.key === item);
        if (selection) {
            orderedItems.push (selection);
        }
    });
    documents.forEach (item => {
        if (!DISPLAY_ORDER.includes (item.key)) {
            orderedItems.push (item);
        }
    });
    return orderedItems;
};

const parseDocumentsByVerifiedStatus = documents => {
    let verifiedDocs = [];
    let genericDocs = [];
    let nonVerifiedDocs = [];
    let registrationNumberVerified = true;
    documents.forEach (item => {
        if (item.key === 'regNo') {
            genericDocs.push (item);
        } else if (item.vaahanFlag === 'yes') {
            verifiedDocs.push (item);
        } else {
            if (item.key === 'regNo') {
                registrationNumberVerified = false;
            }
            nonVerifiedDocs.push (item);
        }
    });
    registrationNumberVerified =
        registrationNumberVerified && verifiedDocs.length > 0;
    return [
        verifiedDocs,
        nonVerifiedDocs,
        genericDocs,
        registrationNumberVerified,
    ];
};

const InspectionReviewBikeDocuments = ({
    bikeInspectionDetails,
    resetInspectionBikeDetailByKeyConnect,
    versionViewMode = true
}) => {
    const {bikeDocumentsMapper, uiVersion} = bikeInspectionDetails;
    const initialUnderHypothecation = bikeDocumentsMapper.find (
        item => item.key === BIKE_DETAIL_KEYS.Hypothecation
    );
    const initialFuelType = bikeDocumentsMapper.find (
        item => item.key === BIKE_DETAIL_KEYS.Fuel_Type
    );
    const initialInspectionType = bikeDocumentsMapper.find (
        item => item.key === BIKE_DETAIL_KEYS.Inspection_Type
    );

    useEffect (
        () => {
            if (
                initialUnderHypothecation &&
                initialUnderHypothecation.value === 'Yes'
            ) {
                switch (uiVersion) {
                case 2:
                    const bankNocIndex = bikeDocumentsMapper.findIndex (
                        item => item.key === BIKE_DETAIL_KEYS.Bank_NOC
                    );
                    const loanPaidIndex = bikeDocumentsMapper.findIndex (
                        item => item.key === BIKE_DETAIL_KEYS.Loan_Paid
                    );
                    if (initialUnderHypothecation.newValue === 'Yes') {
                        bikeDocumentsMapper[bankNocIndex].isVisible = true;
                        bikeDocumentsMapper[loanPaidIndex].isVisible = true;
                    }
                    if (initialUnderHypothecation.newValue === 'No') {
                        bikeDocumentsMapper[bankNocIndex].isVisible = false;
                        bikeDocumentsMapper[loanPaidIndex].isVisible = false;
                    }
                    break;
                default:
                    const financierNameIndex = bikeDocumentsMapper.findIndex (
                        item => item.key === BIKE_DETAIL_KEYS.Financier_Name
                    );
                    if (initialUnderHypothecation.newValue === 'Yes') {
                        bikeDocumentsMapper[financierNameIndex].isVisible = true;
                    } else {
                        bikeDocumentsMapper[financierNameIndex].isVisible = false;
                    }
                    break;
                }
            } else {
                switch (uiVersion) {
                case 2:
                    const bankNocIndex = bikeDocumentsMapper.findIndex (
                        item => item.key === BIKE_DETAIL_KEYS.Bank_NOC
                    );
                    const loanPaidIndex = bikeDocumentsMapper.findIndex (
                        item => item.key === BIKE_DETAIL_KEYS.Loan_Paid
                    );
                    if (initialUnderHypothecation.newValue === 'Yes') {
                        bikeDocumentsMapper[bankNocIndex].isVisible = true;
                        bikeDocumentsMapper[loanPaidIndex].isVisible = true;
                    } else {
                        bikeDocumentsMapper[bankNocIndex].isVisible = false;
                        bikeDocumentsMapper[loanPaidIndex].isVisible = false;
                        bikeDocumentsMapper[bankNocIndex].checked = false;
                        bikeDocumentsMapper[loanPaidIndex].checked = false;
                    }

                    break;
                default:
                    const financierNameIndex = bikeDocumentsMapper.findIndex (
                        item => item.key === BIKE_DETAIL_KEYS.Financier_Name
                    );
                    if (initialUnderHypothecation.newValue === 'Yes') {
                        bikeDocumentsMapper[financierNameIndex].isVisible = true;
                        bikeDocumentsMapper[financierNameIndex].checked = true;
                    } else {
                        bikeDocumentsMapper[financierNameIndex].isVisible = false;
                        bikeDocumentsMapper[financierNameIndex].checked = false;
                    }
                    break;
                }
            }
            if (uiVersion === 2 && initialFuelType) {
                const smokeColorIndex = bikeDocumentsMapper.findIndex (
                    item => item.key === BIKE_DETAIL_KEYS.Exhaust_Smoke_Colour
                );
                if (
                    initialFuelType.value === PETROL &&
                (initialFuelType.newValue === '' ||
                    initialFuelType.newValue === PETROL)
                ) {
                    bikeDocumentsMapper[smokeColorIndex].isVisible = true;
                } else if (
                    initialFuelType.value !== PETROL &&
                initialFuelType.newValue === PETROL
                ) {
                    bikeDocumentsMapper[smokeColorIndex].isVisible = true;
                } else {
                    bikeDocumentsMapper[smokeColorIndex].isVisible = false;
                }
            }
            if (uiVersion === 2 && initialInspectionType) {
                const dealerShipNameIndex = bikeDocumentsMapper.findIndex (
                    item => item.key === BIKE_DETAIL_KEYS.Dealership_Name
                );
                if (initialInspectionType.value === 'B2B') {
                    bikeDocumentsMapper[dealerShipNameIndex].isVisible = true;
                } else {
                    bikeDocumentsMapper[dealerShipNameIndex].isVisible = false;
                }
            }
            resetInspectionBikeDetailByKeyConnect (
                RESET_REDUCER_KEYS.BIKE_DOCUMENTS_MAPPER,
                bikeDocumentsMapper
            );
        // eslint-disable-next-line react-hooks/exhaustive-deps
        },
        [initialUnderHypothecation, initialFuelType, initialInspectionType, uiVersion, resetInspectionBikeDetailByKeyConnect, bikeDocumentsMapper]
    );

    const renderDocs = docs => {
        let elements = [];
        if (docs.length) {
            docs.forEach (item => {
                if (item.text && item.isVisible) {
                    elements.push (
                        <InspectionDocuments
                            document={item}
                            onItemCheckCallback={key => {
                                if (key === 'regNo') {
                                    setIsVerifiedTabClicked (true);
                                }
                            }}
                            versionViewMode={versionViewMode}
                        />
                    );
                }
            });
        }
        return elements;
    };

    const rearrangedDocuments = parseDocumentsInOrder (bikeDocumentsMapper);
    const [
        verifiedDocuments,
        nonVerifiedDocuments,
        genericDocs,
        showCollapsedUI,
    ] = parseDocumentsByVerifiedStatus (rearrangedDocuments);
    const [isVerifiedTabClicked, setIsVerifiedTabClicked] = useState (false);
    const [isNonVerifiedTabClicked, setIsNonVerifiedTabClicked] = useState (true);
    return (
        <div className={styles.carouselContent}>
            <div className={styles.text}>
                Please cross-check with the images, click
                {' '}
                <div className={styles.checkbox}><Checkbox /></div>
                {' '}
                if anything is wrong.
            </div>
            <p className={styles.text}>
                Look out for
                {' '}
                <span className={styles.info}><InfoIcon /></span>
                {' '}
                symbol for Vaahan mismatch.
            </p>
            <div className={styles.rcTextWrapper}>
                {renderDocs (genericDocs)}
                {showCollapsedUI &&
            <div
                className={`${styles.heading} ${isVerifiedTabClicked ? styles.active : ''}`}
                onClick={() => setIsVerifiedTabClicked (!isVerifiedTabClicked)}
            >
                <p className={styles.headingtext}>Show Verified</p>
            </div>}
                {isVerifiedTabClicked && renderDocs (verifiedDocuments)}
                {showCollapsedUI &&
            <div
                className={`${styles.heading} ${isNonVerifiedTabClicked ? styles.active : ''}`}
                onClick={() =>
                    setIsNonVerifiedTabClicked (!isNonVerifiedTabClicked)}
            >
                <p
                    className={styles.headingtext}
                    onClick={setIsNonVerifiedTabClicked}
                >
                Show Non Verified
                </p>
            </div>}
                {(isNonVerifiedTabClicked || !showCollapsedUI) &&
            renderDocs (nonVerifiedDocuments)}
            </div>
        </div>
    );
};

export default InspectionReviewBikeDocuments;


export const getMocLeaderBoard = () => {
    let response = {
        data: {
            "type": "Qc-leader-board-api",
            "title": "Qc-leader-board-api",
            "status": 200,
            "detail": {
                "topPerformers": [
                    {
                        "user": "test1@cars24.com",
                        "totalNoReview": 1,
                        "totalReviewTime": 18,
                        "rank": 1
                    },
                    {
                        "user": "test3@cars24.com",
                        "totalNoReview": 1,
                        "totalReviewTime": 18,
                        "rank": 3
                    },
                    {
                        "user": "test2@cars24.com",
                        "totalNoReview": 1,
                        "totalReviewTime": 18,
                        "rank": 2
                    }
                ],
                "myPerformance": {
                    "user": "test3@cars24.com",
                    "totalNoReview": 1,
                    "totalReviewTime": 18,
                    "rank": 1
                }
            }
        }
    };
    return response;
};

export const getVahaanDataMoc = () => {
    let response = {
        "type": "Vahan Check",
        "title": "Vahan details found for this vehicle no.- ",
        "status": 200,
        "detail": {
            "appointmentId": "1190662184",
            "registrationNumber": "WB22U7506",
            "vahanWebsite": {
                "Registration No:": "WB22U7506",
                "Fitness/REGN Upto:": "16-Apr-2028",
                "MV Tax upto:": "23-Apr-2023",
                "Engine No:": "HJD4B****8",
                "RC Status:": "ACTIVE",
                "Emission norms:": "BHARAT STAGE III",
                "Owner Name:": "BADAL KR GHOSH",
                "Fuel:": "DIESEL",
                "Vehicle Class:": "Motor Car(LMV)",
                "Insurance Upto:": "11-Jun-2020",
                "PUCC Upto:": "NA",
                "Chassis No:": "MA1YL2HJUD6B****5",
                "Registering Authority": "Alipore RTO, West Bengal",
                "Maker / Model:": "MAHINDRA & MAHINDRA LIMITED /  XUV 500",
                "Registration Date:": "24-Apr-2013",
                "endTime": {
                    "sec": 1591722196,
                    "usec": 0
                }
            },
            "parivahan1": {
                "Registration No:": "WB22U7506",
                "Fuel Type:": "DIESEL",
                "Engine No:": "HJD4BXXXXX",
                "Fitness Upto:": "16-Apr-2028",
                "Vehicle Class:": "MOTOR CAR (LMV)",
                "Insurance Upto:": "11-Jun-2020",
                "Registration Date:": "24-Apr-2013",
                "Chassis No:": "MA1YL2HJUD6BXXXXX",
                "Fuel Norms:": "BHARAT STAGE III",
                "Owner Name:": "BADAL KR GHOSH",
                "Maker / Model:": "MAHINDRA & MAHINDRA LIMITED / XUV 500",
                "Road Tax Paid Upto:": "23-Apr-2023",
                "endTime": {
                    "sec": 1591722199,
                    "usec": 0
                }
            },
            "vahanSMS": ""
        }
    };
    return response;
};

export const getReInspectionMocData = () => {
    let response = {
        "type": "Inspection-log-Api",
        "title": "Inspection-log-Api",
        "status": 200,
        "detail": {
            "data": {
                "appointmentId": "1064323581",
                "inspectionStatus": "smReview",
                "odometerReading": 72674,
                "inspectionData": {
                    "carDetails": {
                        "odometerReading": {
                            "title": "Odometer(Km)",
                            "value": "72614"
                        },
                        "appointmentId": {
                            "title": "Appointment ID",
                            "value": "1064323581"
                        },
                        "make": {
                            "id": "21",
                            "title": "Make",
                            "value": "MARUTI SUZUKI"
                        },
                        "model": {
                            "id": "150",
                            "title": "Model",
                            "value": "WAGON R 1.0"
                        },
                        "city": {
                            "id": "2378",
                            "title": "City",
                            "value": "Mumbai"
                        },
                        "inspectionAt": {
                            "title": "Inspection At",
                            "value": "Store"
                        },
                        "storeName": {
                            "regionId": "38",
                            "regionName": "Pune",
                            "id": "126",
                            "title": "Branch",
                            "value": "Andheri East"
                        },
                        "customerName": {
                            "title": "Customer Name",
                            "value": "SURYAKANT V KAKADE"
                        },
                        "customerMobile": {
                            "title": "Customer Mobile",
                            "value": "8999294642"
                        },
                        "customerEmail": {
                            "title": "Customer Email",
                            "value": "xyz@gmail.com"
                        },
                        "registrationNumber": {
                            "title": "Registration Number",
                            "value": "MH01YA5199"
                        },
                        "fitnessUpto": {
                            "title": "Fitness Upto",
                            "value": "24-10-2026"
                        },
                        "registrationYear": {
                            "title": "Registration Year",
                            "value": "2011"
                        },
                        "registrationMonth": {
                            "title": "Registration Month",
                            "value": "10"
                        },
                        "isUnderHypothecation": {
                            "title": "Under Hypothecation",
                            "value": "No"
                        },
                        "rtoNocIssued": {
                            "title": "RTO NOC Issued",
                            "value": "No"
                        },
                        "chassisNumber": {
                            "title": "Chassis Number",
                            "value": "MA3E10DE1S00321924"
                        },
                        "rcImages": [
                            {
                                "title": "RC1",
                                "url": "inspection/2020/03/12/RR55RQ6/v0/carDetails/rcImages/rc1_1584006173.jpg",
                                "hashKey": "inspectionData_carDetails_rcImages_RC0",
                                "pointerCount": 0,
                                "isTilted": 1,
                                "field": "inspectionData_carDetails_rcImages_RC0",
                                "imageUploadTime": {
                                    "sec": 1584025943,
                                    "usec": 0
                                },
                                "serverTime": {
                                    "sec": 1584025973,
                                    "usec": 0
                                },
                                "rating": "Yes"
                            },
                            {
                                "title": "RC2",
                                "url": "inspection/2020/03/12/RR55RQ6/v0/carDetails/rcImages/rc2_1584006175.jpg",
                                "hashKey": "inspectionData_carDetails_rcImages_RC1",
                                "pointerCount": 0,
                                "isTilted": 1,
                                "field": "inspectionData_carDetails_rcImages_RC1",
                                "imageUploadTime": {
                                    "sec": 1584025949,
                                    "usec": 0
                                },
                                "serverTime": {
                                    "sec": 1584025975,
                                    "usec": 0
                                },
                                "rating": "Yes"
                            },
                            {
                                "title": "RC3",
                                "url": "inspection/2020/03/12/RR55RQ6/v0/carDetails/rcImages/rc3_1584006177.jpg",
                                "hashKey": "inspectionData_carDetails_rcImages_RC2",
                                "pointerCount": 0,
                                "isTilted": 1,
                                "field": "inspectionData_carDetails_rcImages_RC2",
                                "imageUploadTime": {
                                    "sec": 1584025959,
                                    "usec": 0
                                },
                                "serverTime": {
                                    "sec": 1584025978,
                                    "usec": 0
                                },
                                "rating": "Yes"
                            },
                            {
                                "title": "RC4",
                                "url": "inspection/2020/03/12/RR55RQ6/v0/carDetails/rcImages/rc4_1584006180.jpg",
                                "hashKey": "inspectionData_carDetails_rcImages_RC3",
                                "pointerCount": 0,
                                "isTilted": 1,
                                "field": "inspectionData_carDetails_rcImages_RC3",
                                "imageUploadTime": {
                                    "sec": 1584025969,
                                    "usec": 0
                                },
                                "serverTime": {
                                    "sec": 1584025980,
                                    "usec": 0
                                },
                                "rating": "Yes"
                            }
                        ],
                        "insuranceImages": [
                            {
                                "title": "Insurance2",
                                "url": "inspection/2020/03/12/RR55RQ6/v0/carDetails/insuranceImages/insurance2_1584006299.jpg",
                                "hashKey": "inspectionData_carDetails_insuranceImages_Insurance1",
                                "pointerCount": 0,
                                "isTilted": 1,
                                "field": "inspectionData_carDetails_insuranceImages_Insurance1",
                                "imageUploadTime": {
                                    "sec": 1584026085,
                                    "usec": 0
                                },
                                "serverTime": {
                                    "sec": 1584026099,
                                    "usec": 0
                                },
                                "rating": "Yes"
                            },
                            {
                                "title": "Insurance1",
                                "url": "inspection/2020/03/12/RR55RQ6/v0/carDetails/insuranceImages/insurance1_1584006320.jpg",
                                "hashKey": "inspectionData_carDetails_insuranceImages_Insurance0",
                                "pointerCount": 0,
                                "isTilted": 1,
                                "field": "inspectionData_carDetails_insuranceImages_Insurance0",
                                "imageUploadTime": {
                                    "sec": 1584026115,
                                    "usec": 0
                                },
                                "serverTime": {
                                    "sec": 1584026120,
                                    "usec": 0
                                },
                                "rating": "Yes"
                            }
                        ],
                        "chassisNumberEmbossing": {
                            "title": "Chassis Number Embossing",
                            "value": "Ok"
                        },
                        "cngLpgFitment": {
                            "title": "CNG/LPG Fitment in RC",
                            "value": "Yes"
                        },
                        "comments": {
                            "title": "Comments",
                            "value": [
                                {
                                    "comment": "CNG cylinder test certificate not available",
                                    "id": 34,
                                    "rank": 0,
                                    "type": "Comments"
                                }
                            ]
                        },
                        "duplicateKey": {
                            "title": "Duplicate Key",
                            "value": "No"
                        },
                        "fuelType": {
                            "title": "Fuel Type",
                            "value": "Petrol + CNG"
                        },
                        "highlightPositives": {
                            "title": "Highlight Positive",
                            "value": [

                            ]
                        },
                        "insuranceType": {
                            "subParts": {
                                "insuranceExpiry": {
                                    "title": "Insurance Expiry Date",
                                    "value": "21-01-2021"
                                },
                                "mismatchInInsurance": {
                                    "title": "Mismatch In Insurance",
                                    "value": "No Mismatch"
                                }
                            },
                            "title": "Insurance Type",
                            "value": "3rd Party"
                        },
                        "month": {
                            "title": "Manufacturing Month",
                            "value": "10"
                        },
                        "ownerNumber": {
                            "title": "No. Of Owner",
                            "value": "2"
                        },
                        "partiPeshi": {
                            "title": "Partipeshi Request",
                            "value": "No"
                        },
                        "raDetails": {
                            "email": "abhay.chaturvedi@cars24.com",
                            "id": "14976",
                            "title": "RA",
                            "value": "Abhay Chaturvedi"
                        },
                        "rcAvailability": {
                            "subParts": {
                                "rcCondition": {
                                    "title": "RC Condition",
                                    "value": "Ok"
                                }
                            },
                            "title": "RC Availability",
                            "value": "Original"
                        },
                        "registrationCity": {
                            "id": "2423",
                            "title": "Registration City",
                            "value": "Pune"
                        },
                        "registrationState": {
                            "subParts": {
                                "rto": {
                                    "rtoCode": "MH-12",
                                    "id": "692",
                                    "title": "RTO",
                                    "value": "MH-12 Pune"
                                }
                            },
                            "id": "14",
                            "title": "Registration State",
                            "value": "Maharashtra"
                        },
                        "roadTaxPaid": {
                            "title": "Road Tax Paid",
                            "value": "OTT/LTT"
                        },
                        "toBeScrapped": {
                            "title": "To be Scrapped",
                            "value": "No"
                        },
                        "variant": {
                            "transportation_category": "Category_2",
                            "id": "895",
                            "title": "Variant",
                            "value": "LXI CNG"
                        },
                        "year": {
                            "id": "5943",
                            "title": "Manufacturing Year",
                            "value": "2011"
                        },
                        "chassisNumberImage": [
                            {
                                "title": "Chassis Number",
                                "url": "inspection/2020/03/12/RR55RQ6/v0/carDetails/chassisNumberImage/chassisnumber_1584006617.jpg",
                                "hashKey": "inspectionData_cardetails_chassisNumberImage",
                                "pointerCount": 0,
                                "isTilted": 0,
                                "field": "inspectionData_cardetails_chassisNumberImage",
                                "imageUploadTime": {
                                    "sec": 1584026414,
                                    "usec": 0
                                },
                                "serverTime": {
                                    "sec": 1584026417,
                                    "usec": 0
                                }
                            }
                        ],
                        "errorsCount": 0,
                        "reinspDiff": {
                            "city": {
                                "id": "2423",
                                "title": "City",
                                "value": "Pune",
                                "isValidate": 3
                            }
                        }
                    },
                    "exteriorTyres": {
                        "comments": {
                            "title": "Comments",
                            "value": [
                                {
                                    "comment": "Car pulling on side",
                                    "id": 2,
                                    "rank": 0,
                                    "type": "Comments"
                                }
                            ]
                        },
                        "estimatedRFC": {
                            "title": "Estimated RFC",
                            "value": 0
                        },
                        "highlightPositives": {
                            "title": "Highlight Positive",
                            "value": [

                            ]
                        },
                        "mainImages": [
                            {
                                "title": "Front With Hood Open",
                                "url": "inspection/2020/03/12/RR55RQ6/v0/exteriorTyres/mainImages/frontwithhoodopen_1584006931.jpg",
                                "hashKey": "inspectionData_exteriortyres_mainImages_frontWithHoodOpen",
                                "pointerCount": 0,
                                "isTilted": 1,
                                "field": "inspectionData_exteriortyres_mainImages_frontWithHoodOpen",
                                "imageUploadTime": {
                                    "sec": 1584026575,
                                    "usec": 0
                                },
                                "serverTime": {
                                    "sec": 1584026731,
                                    "usec": 0
                                },
                                "rating": "Yes"
                            },
                            {
                                "title": "Rear Boot Opened",
                                "url": "inspection/2020/03/12/RR55RQ6/v0/exteriorTyres/mainImages/rearbootopened_1584007466.jpg",
                                "hashKey": "inspectionData_exteriortyres_mainImages_rearBootOpened",
                                "pointerCount": 0,
                                "isTilted": 0,
                                "field": "inspectionData_exteriortyres_mainImages_rearBootOpened",
                                "imageUploadTime": {
                                    "sec": 1584027263,
                                    "usec": 0
                                },
                                "serverTime": {
                                    "sec": 1584027266,
                                    "usec": 0
                                },
                                "rating": "Yes"
                            },
                            {
                                "title": "Front Main",
                                "url": "inspection/2020/03/12/RR55RQ6/v0/exteriorTyres/mainImages/frontmain_1584007811.jpg",
                                "hashKey": "inspectionData_exteriorTyres_mainImages_frontMain",
                                "pointerCount": 0,
                                "isTilted": 0,
                                "field": "inspectionData_exteriorTyres_mainImages_frontMain",
                                "imageUploadTime": {
                                    "sec": 1584027580,
                                    "usec": 0
                                },
                                "serverTime": {
                                    "sec": 1584027611,
                                    "usec": 0
                                },
                                "rating": "Yes"
                            },
                            {
                                "title": "Front Left Side",
                                "url": "inspection/2020/03/12/RR55RQ6/v0/exteriorTyres/mainImages/frontleftside_1584007813.jpg",
                                "hashKey": "inspectionData_exteriorTyres_mainImages_FrontLeftSide",
                                "pointerCount": 0,
                                "isTilted": 0,
                                "field": "inspectionData_exteriorTyres_mainImages_FrontLeftSide",
                                "imageUploadTime": {
                                    "sec": 1584027586,
                                    "usec": 0
                                },
                                "serverTime": {
                                    "sec": 1584027613,
                                    "usec": 0
                                },
                                "rating": "Yes"
                            },
                            {
                                "title": "Rear Main",
                                "url": "inspection/2020/03/12/RR55RQ6/v0/exteriorTyres/mainImages/rearmain_1584007815.jpg",
                                "hashKey": "inspectionData_exteriortyres_mainImages_rearMain",
                                "pointerCount": 0,
                                "isTilted": 0,
                                "field": "inspectionData_exteriortyres_mainImages_rearMain",
                                "imageUploadTime": {
                                    "sec": 1584027598,
                                    "usec": 0
                                },
                                "serverTime": {
                                    "sec": 1584027615,
                                    "usec": 0
                                },
                                "rating": "Yes"
                            },
                            {
                                "title": "Rear Right Side",
                                "url": "inspection/2020/03/12/RR55RQ6/v0/exteriorTyres/mainImages/rearrightside_1584007816.jpg",
                                "hashKey": "inspectionData_exteriorTyres_mainImages_RearRightSide",
                                "pointerCount": 0,
                                "isTilted": 0,
                                "field": "inspectionData_exteriorTyres_mainImages_RearRightSide",
                                "imageUploadTime": {
                                    "sec": 1584027608,
                                    "usec": 0
                                },
                                "serverTime": {
                                    "sec": 1584027617,
                                    "usec": 0
                                },
                                "rating": "Yes"
                            }
                        ],
                        "apron": {
                            "subParts": {
                                "lhs": {
                                    "additionalInfo": [
                                        {
                                            "image": {
                                                "title": "Apron_Lhs_Additional_Info_Form_1",
                                                "url": "inspection/2020/03/12/RR55RQ6/v0/exteriorTyres/additionalInfo/apron/lhs/apronlhsadditionalinfoform1_1584007015.jpg",
                                                "hashKey": "exteriorTyres_apron_lhs_additionalInfo_form_0",
                                                "pointerCount": 0,
                                                "isTilted": 0,
                                                "imageUploadTime": {
                                                    "sec": 1584026720,
                                                    "usec": 0
                                                },
                                                "serverTime": {
                                                    "sec": 1584026815,
                                                    "usec": 0
                                                },
                                                "rating": "Yes"
                                            },
                                            "hashKey": "exteriorTyres_apron_lhs_additionalInfo_form_0",
                                            "currentCondition": {
                                                "title": "Current Condition",
                                                "value": [
                                                    "Rusted"
                                                ]
                                            },
                                            "severityScaleCC": {
                                                "title": "Severity Scale",
                                                "value": {
                                                    "Rusted": "2"
                                                }
                                            }
                                        }
                                    ],
                                    "title": "LHS",
                                    "value": "No"
                                },
                                "rhs": {
                                    "title": "RHS",
                                    "value": "Yes"
                                }
                            },
                            "title": "Apron",
                            "value": "No"
                        },
                        "firewall": {
                            "additionalInfo": [
                                {
                                    "image": {
                                        "title": "Firewall_Additional_Info_Form_1",
                                        "url": "inspection/2020/03/12/RR55RQ6/v0/exteriorTyres/additionalInfo/firewall/firewalladditionalinfoform1_1584007017.jpg",
                                        "hashKey": "exteriorTyres_firewall_additionalInfo_form_0",
                                        "pointerCount": 0,
                                        "isTilted": 0,
                                        "imageUploadTime": {
                                            "sec": 1584026720,
                                            "usec": 0
                                        },
                                        "serverTime": {
                                            "sec": 1584026817,
                                            "usec": 0
                                        },
                                        "rating": "Yes"
                                    },
                                    "hashKey": "exteriorTyres_firewall_additionalInfo_form_0",
                                    "currentCondition": {
                                        "title": "Current Condition",
                                        "value": [
                                            "Rusted"
                                        ]
                                    },
                                    "severityScaleCC": {
                                        "title": "Severity Scale",
                                        "value": {
                                            "Rusted": "2"
                                        }
                                    }
                                }
                            ],
                            "title": "Firewall",
                            "value": "No"
                        },
                        "cowlTop": {
                            "additionalInfo": [
                                {
                                    "image": {
                                        "title": "Cowl_top_Additional_Info_Form_1",
                                        "url": "inspection/2020/03/12/RR55RQ6/v0/exteriorTyres/additionalInfo/cowlTop/cowltopadditionalinfoform1_1584007053.jpg",
                                        "hashKey": "exteriorTyres_cowlTop_additionalInfo_form_0",
                                        "pointerCount": 0,
                                        "isTilted": 0,
                                        "imageUploadTime": {
                                            "sec": 1584026843,
                                            "usec": 0
                                        },
                                        "serverTime": {
                                            "sec": 1584026853,
                                            "usec": 0
                                        },
                                        "rating": "Yes"
                                    },
                                    "hashKey": "exteriorTyres_cowlTop_additionalInfo_form_0",
                                    "currentCondition": {
                                        "title": "Current Condition",
                                        "value": [
                                            "Damaged"
                                        ]
                                    },
                                    "severityScaleCC": {
                                        "title": "Severity Scale",
                                        "value": {
                                            "Damaged": "1"
                                        }
                                    }
                                }
                            ],
                            "title": "Cowl Top",
                            "value": "No"
                        },
                        "runningBorder": {
                            "subParts": {
                                "rhs": {
                                    "additionalInfo": [
                                        {
                                            "image": {
                                                "title": "Running_border_Rhs_Additional_Info_Form_1",
                                                "url": "inspection/2020/03/12/RR55RQ6/v0/exteriorTyres/additionalInfo/runningBorder/rhs/runningborderrhsadditionalinfoform1_1584007056.jpg",
                                                "hashKey": "exteriorTyres_runningBorder_rhs_additionalInfo_form_0",
                                                "pointerCount": 0,
                                                "isTilted": 0,
                                                "imageUploadTime": {
                                                    "sec": 1584026843,
                                                    "usec": 0
                                                },
                                                "serverTime": {
                                                    "sec": 1584026856,
                                                    "usec": 0
                                                },
                                                "rating": "Yes"
                                            },
                                            "hashKey": "exteriorTyres_runningBorder_rhs_additionalInfo_form_0",
                                            "currentCondition": {
                                                "title": "Current Condition",
                                                "value": [
                                                    "Scratched",
                                                    "Dented"
                                                ]
                                            },
                                            "workDone": {
                                                "title": "Work Done",
                                                "value": [
                                                    "Repainted"
                                                ]
                                            },
                                            "severityScaleCC": {
                                                "title": "Severity Scale",
                                                "value": {
                                                    "Dented": "3",
                                                    "Scratched": "3"
                                                }
                                            }
                                        }
                                    ],
                                    "title": "RHS",
                                    "value": "No"
                                },
                                "lhs": {
                                    "additionalInfo": [
                                        {
                                            "image": {
                                                "title": "Running_border_Lhs_Additional_Info_Form_1",
                                                "url": "inspection/2020/03/12/RR55RQ6/v0/exteriorTyres/additionalInfo/runningBorder/lhs/runningborderlhsadditionalinfoform1_1584007598.jpg",
                                                "hashKey": "exteriorTyres_runningBorder_lhs_additionalInfo_form_0",
                                                "pointerCount": 0,
                                                "isTilted": 0,
                                                "imageUploadTime": {
                                                    "sec": 1584027396,
                                                    "usec": 0
                                                },
                                                "serverTime": {
                                                    "sec": 1584027399,
                                                    "usec": 0
                                                },
                                                "rating": "Yes"
                                            },
                                            "hashKey": "exteriorTyres_runningBorder_lhs_additionalInfo_form_0",
                                            "currentCondition": {
                                                "title": "Current Condition",
                                                "value": [
                                                    "Scratched",
                                                    "Dented"
                                                ]
                                            },
                                            "workDone": {
                                                "title": "Work Done",
                                                "value": [
                                                    "Repainted"
                                                ]
                                            },
                                            "severityScaleCC": {
                                                "title": "Severity Scale",
                                                "value": {
                                                    "Dented": "2",
                                                    "Scratched": "3"
                                                }
                                            }
                                        }
                                    ],
                                    "title": "LHS",
                                    "value": "No"
                                }
                            },
                            "title": "Running Border",
                            "value": "No"
                        },
                        "bonnetHood": {
                            "additionalInfo": [
                                {
                                    "image": {
                                        "title": "Bonnet/hood_Additional_Info_Form_1",
                                        "url": "inspection/2020/03/12/RR55RQ6/v0/exteriorTyres/additionalInfo/bonnetHood/bonnethoodadditionalinfoform1_1584007050.jpg",
                                        "hashKey": "exteriorTyres_bonnetHood_additionalInfo_form_0",
                                        "pointerCount": 0,
                                        "isTilted": 0,
                                        "imageUploadTime": {
                                            "sec": 1584026843,
                                            "usec": 0
                                        },
                                        "serverTime": {
                                            "sec": 1584026850,
                                            "usec": 0
                                        },
                                        "rating": "Yes"
                                    },
                                    "hashKey": "exteriorTyres_bonnetHood_additionalInfo_form_0",
                                    "currentCondition": {
                                        "title": "Current Condition",
                                        "value": [
                                            "Scratched",
                                            "Dented"
                                        ]
                                    },
                                    "workDone": {
                                        "title": "Work Done",
                                        "value": [
                                            "Repainted"
                                        ]
                                    },
                                    "severityScaleCC": {
                                        "title": "Severity Scale",
                                        "value": {
                                            "Dented": "4",
                                            "Scratched": "4"
                                        }
                                    }
                                }
                            ],
                            "title": "Bonnet/Hood",
                            "value": "No"
                        },
                        "bumpers": {
                            "subParts": {
                                "front": {
                                    "additionalInfo": [
                                        {
                                            "image": {
                                                "title": "Bumper_Front_Additional_Info_Form_1",
                                                "url": "inspection/2020/03/12/RR55RQ6/v0/exteriorTyres/additionalInfo/bumpers/front/bumperfrontadditionalinfoform1_1584007052.jpg",
                                                "hashKey": "exteriorTyres_bumpers_front_additionalInfo_form_0",
                                                "pointerCount": 0,
                                                "isTilted": 0,
                                                "imageUploadTime": {
                                                    "sec": 1584026843,
                                                    "usec": 0
                                                },
                                                "serverTime": {
                                                    "sec": 1584026853,
                                                    "usec": 0
                                                },
                                                "rating": "Yes"
                                            },
                                            "hashKey": "exteriorTyres_bumpers_front_additionalInfo_form_0",
                                            "currentCondition": {
                                                "title": "Current Condition",
                                                "value": [
                                                    "Scratched",
                                                    "Damaged"
                                                ]
                                            },
                                            "workDone": {
                                                "title": "Work Done",
                                                "value": [
                                                    "Repainted"
                                                ]
                                            }
                                        }
                                    ],
                                    "title": "Front",
                                    "value": "No"
                                },
                                "rear": {
                                    "additionalInfo": [
                                        {
                                            "image": {
                                                "title": "Bumper_Rear_Additional_Info_Form_1",
                                                "url": "inspection/2020/03/12/RR55RQ6/v0/exteriorTyres/additionalInfo/bumpers/rear/bumperrearadditionalinfoform1_1584007083.jpg",
                                                "hashKey": "exteriorTyres_bumpers_rear_additionalInfo_form_0",
                                                "pointerCount": 0,
                                                "isTilted": 0,
                                                "imageUploadTime": {
                                                    "sec": 1584026882,
                                                    "usec": 0
                                                },
                                                "serverTime": {
                                                    "sec": 1584026883,
                                                    "usec": 0
                                                },
                                                "rating": "Yes"
                                            },
                                            "hashKey": "exteriorTyres_bumpers_rear_additionalInfo_form_0",
                                            "currentCondition": {
                                                "title": "Current Condition",
                                                "value": [
                                                    "Scratched"
                                                ]
                                            },
                                            "workDone": {
                                                "title": "Work Done",
                                                "value": [
                                                    "Repainted"
                                                ]
                                            }
                                        }
                                    ],
                                    "title": "Rear",
                                    "value": "No"
                                }
                            },
                            "title": "Bumper",
                            "value": "No"
                        },
                        "doors": {
                            "subParts": {
                                "rhsFront": {
                                    "additionalInfo": [
                                        {
                                            "image": {
                                                "title": "Door_Rhs_front_Additional_Info_Form_1",
                                                "url": "inspection/2020/03/12/RR55RQ6/v0/exteriorTyres/additionalInfo/doors/rhsFront/doorrhsfrontadditionalinfoform1_1584007053.jpg",
                                                "hashKey": "exteriorTyres_doors_rhsFront_additionalInfo_form_0",
                                                "pointerCount": 0,
                                                "isTilted": 0,
                                                "imageUploadTime": {
                                                    "sec": 1584026843,
                                                    "usec": 0
                                                },
                                                "serverTime": {
                                                    "sec": 1584026854,
                                                    "usec": 0
                                                },
                                                "rating": "Yes"
                                            },
                                            "hashKey": "exteriorTyres_doors_rhsFront_additionalInfo_form_0",
                                            "currentCondition": {
                                                "title": "Current Condition",
                                                "value": [
                                                    "Scratched",
                                                    "Dented",
                                                    "Rusted"
                                                ]
                                            },
                                            "workDone": {
                                                "title": "Work Done",
                                                "value": [
                                                    "Repainted"
                                                ]
                                            },
                                            "severityScaleCC": {
                                                "title": "Severity Scale",
                                                "value": {
                                                    "Dented": "3",
                                                    "Rusted": "2",
                                                    "Scratched": "3"
                                                }
                                            }
                                        },
                                        {
                                            "image": {
                                                "title": "Door_Rhs_front_Additional_Info_Form_2",
                                                "url": "inspection/2020/03/12/RR55RQ6/v2/exteriorTyres/additionalInfo/doors/rhsFront/doorrhsfrontadditionalinfoform2_1591526175.jpg",
                                                "hashKey": "exteriorTyres_doors_rhsFront_additionalInfo_form_1",
                                                "pointerCount": 0,
                                                "isTilted": 0,
                                                "imageUploadTime": {
                                                    "sec": 1591545968,
                                                    "usec": 0
                                                },
                                                "serverTime": {
                                                    "sec": 1591545975,
                                                    "usec": 0
                                                }
                                            },
                                            "hashKey": "exteriorTyres_doors_rhsFront_additionalInfo_form_1",
                                            "currentCondition": {
                                                "title": "Current Condition",
                                                "value": [
                                                    "Rusted"
                                                ]
                                            },
                                            "severityScaleCC": {
                                                "title": "Severity Scale",
                                                "value": {
                                                    "Rusted": "2"
                                                }
                                            }
                                        }
                                    ],
                                    "title": "RHS Front",
                                    "value": "No"
                                },
                                "rhsRear": {
                                    "additionalInfo": [
                                        {
                                            "image": {
                                                "title": "Door_Rhs_rear_Additional_Info_Form_1",
                                                "url": "inspection/2020/03/12/RR55RQ6/v0/exteriorTyres/additionalInfo/doors/rhsRear/doorrhsrearadditionalinfoform1_1584007054.jpg",
                                                "hashKey": "exteriorTyres_doors_rhsRear_additionalInfo_form_0",
                                                "pointerCount": 0,
                                                "isTilted": 0,
                                                "imageUploadTime": {
                                                    "sec": 1584026843,
                                                    "usec": 0
                                                },
                                                "serverTime": {
                                                    "sec": 1584026854,
                                                    "usec": 0
                                                },
                                                "rating": "Yes"
                                            },
                                            "hashKey": "exteriorTyres_doors_rhsRear_additionalInfo_form_0",
                                            "currentCondition": {
                                                "title": "Current Condition",
                                                "value": [
                                                    "Scratched"
                                                ]
                                            },
                                            "workDone": {
                                                "title": "Work Done",
                                                "value": [
                                                    "Repainted"
                                                ]
                                            },
                                            "severityScaleCC": {
                                                "title": "Severity Scale",
                                                "value": {
                                                    "Scratched": "4"
                                                }
                                            }
                                        }
                                    ],
                                    "title": "RHS Rear",
                                    "value": "No"
                                },
                                "lhsRear": {
                                    "additionalInfo": [
                                        {
                                            "image": {
                                                "title": "Door_Lhs_rear_Additional_Info_Form_1",
                                                "url": "inspection/2020/03/12/RR55RQ6/v0/exteriorTyres/additionalInfo/doors/lhsRear/doorlhsrearadditionalinfoform1_1584007604.jpg",
                                                "hashKey": "exteriorTyres_doors_lhsRear_additionalInfo_form_0",
                                                "pointerCount": 0,
                                                "isTilted": 0,
                                                "imageUploadTime": {
                                                    "sec": 1584027403,
                                                    "usec": 0
                                                },
                                                "serverTime": {
                                                    "sec": 1584027405,
                                                    "usec": 0
                                                },
                                                "rating": "Yes"
                                            },
                                            "hashKey": "exteriorTyres_doors_lhsRear_additionalInfo_form_0",
                                            "currentCondition": {
                                                "title": "Current Condition",
                                                "value": [
                                                    "Scratched"
                                                ]
                                            },
                                            "workDone": {
                                                "title": "Work Done",
                                                "value": [
                                                    "Repainted"
                                                ]
                                            },
                                            "severityScaleCC": {
                                                "title": "Severity Scale",
                                                "value": {
                                                    "Scratched": "3"
                                                }
                                            }
                                        }
                                    ],
                                    "title": "LHS Rear",
                                    "value": "No"
                                },
                                "lhsFront": {
                                    "additionalInfo": [
                                        {
                                            "image": {
                                                "title": "Door_Lhs_front_Additional_Info_Form_1",
                                                "url": "inspection/2020/03/12/RR55RQ6/v0/exteriorTyres/additionalInfo/doors/lhsFront/doorlhsfrontadditionalinfoform1_1584007610.jpg",
                                                "hashKey": "exteriorTyres_doors_lhsFront_additionalInfo_form_0",
                                                "pointerCount": 0,
                                                "isTilted": 0,
                                                "imageUploadTime": {
                                                    "sec": 1584027408,
                                                    "usec": 0
                                                },
                                                "serverTime": {
                                                    "sec": 1584027410,
                                                    "usec": 0
                                                },
                                                "rating": "Yes"
                                            },
                                            "hashKey": "exteriorTyres_doors_lhsFront_additionalInfo_form_0",
                                            "currentCondition": {
                                                "title": "Current Condition",
                                                "value": [
                                                    "Scratched",
                                                    "Rusted"
                                                ]
                                            },
                                            "workDone": {
                                                "title": "Work Done",
                                                "value": [
                                                    "Repainted"
                                                ]
                                            },
                                            "severityScaleCC": {
                                                "title": "Severity Scale",
                                                "value": {
                                                    "Rusted": "4",
                                                    "Scratched": "5"
                                                }
                                            }
                                        },
                                        {
                                            "image": {
                                                "title": "Door_Lhs_front_Additional_Info_Form_2",
                                                "url": "inspection/2020/03/12/RR55RQ6/v2/exteriorTyres/additionalInfo/doors/lhsFront/doorlhsfrontadditionalinfoform2_1591526042.jpg",
                                                "hashKey": "exteriorTyres_doors_lhsFront_additionalInfo_form_1",
                                                "pointerCount": 0,
                                                "isTilted": 0,
                                                "imageUploadTime": {
                                                    "sec": 1591545833,
                                                    "usec": 0
                                                },
                                                "serverTime": {
                                                    "sec": 1591545842,
                                                    "usec": 0
                                                }
                                            },
                                            "hashKey": "exteriorTyres_doors_lhsFront_additionalInfo_form_1",
                                            "currentCondition": {
                                                "title": "Current Condition",
                                                "value": [
                                                    "Rusted"
                                                ]
                                            },
                                            "severityScaleCC": {
                                                "title": "Severity Scale",
                                                "value": {
                                                    "Rusted": "5"
                                                }
                                            }
                                        }
                                    ],
                                    "title": "LHS Front",
                                    "value": "No"
                                }
                            },
                            "title": "Door",
                            "value": "No"
                        },
                        "fenders": {
                            "subParts": {
                                "rhs": {
                                    "additionalInfo": [
                                        {
                                            "image": {
                                                "title": "Fender_Rhs_Additional_Info_Form_1",
                                                "url": "inspection/2020/03/12/RR55RQ6/v0/exteriorTyres/additionalInfo/fenders/rhs/fenderrhsadditionalinfoform1_1584007055.jpg",
                                                "hashKey": "exteriorTyres_fenders_rhs_additionalInfo_form_0",
                                                "pointerCount": 0,
                                                "isTilted": 0,
                                                "imageUploadTime": {
                                                    "sec": 1584026843,
                                                    "usec": 0
                                                },
                                                "serverTime": {
                                                    "sec": 1584026855,
                                                    "usec": 0
                                                },
                                                "rating": "Yes"
                                            },
                                            "hashKey": "exteriorTyres_fenders_rhs_additionalInfo_form_0",
                                            "currentCondition": {
                                                "title": "Current Condition",
                                                "value": [
                                                    "Scratched",
                                                    "Rusted"
                                                ]
                                            },
                                            "workDone": {
                                                "title": "Work Done",
                                                "value": [
                                                    "Repainted"
                                                ]
                                            },
                                            "severityScaleCC": {
                                                "title": "Severity Scale",
                                                "value": {
                                                    "Rusted": "3",
                                                    "Scratched": "3"
                                                }
                                            }
                                        }
                                    ],
                                    "title": "RHS",
                                    "value": "No"
                                },
                                "lhs": {
                                    "additionalInfo": [
                                        {
                                            "image": {
                                                "title": "Fender_Lhs_Additional_Info_Form_1",
                                                "url": "inspection/2020/03/12/RR55RQ6/v0/exteriorTyres/additionalInfo/fenders/lhs/fenderlhsadditionalinfoform1_1584007617.jpg",
                                                "hashKey": "exteriorTyres_fenders_lhs_additionalInfo_form_0",
                                                "pointerCount": 0,
                                                "isTilted": 0,
                                                "imageUploadTime": {
                                                    "sec": 1584027415,
                                                    "usec": 0
                                                },
                                                "serverTime": {
                                                    "sec": 1584027417,
                                                    "usec": 0
                                                },
                                                "rating": "Yes"
                                            },
                                            "hashKey": "exteriorTyres_fenders_lhs_additionalInfo_form_0",
                                            "currentCondition": {
                                                "title": "Current Condition",
                                                "value": [
                                                    "Scratched",
                                                    "Rusted"
                                                ]
                                            },
                                            "workDone": {
                                                "title": "Work Done",
                                                "value": [
                                                    "Repainted"
                                                ]
                                            },
                                            "severityScaleCC": {
                                                "title": "Severity Scale",
                                                "value": {
                                                    "Rusted": "4",
                                                    "Scratched": "5"
                                                }
                                            }
                                        },
                                        {
                                            "image": {
                                                "title": "Fender_Lhs_Additional_Info_Form_2",
                                                "url": "inspection/2020/03/12/RR55RQ6/v2/exteriorTyres/additionalInfo/fenders/lhs/fenderlhsadditionalinfoform2_1591525991.jpg",
                                                "hashKey": "exteriorTyres_fenders_lhs_additionalInfo_form_1",
                                                "pointerCount": 0,
                                                "isTilted": 0,
                                                "imageUploadTime": {
                                                    "sec": 1591545787,
                                                    "usec": 0
                                                },
                                                "serverTime": {
                                                    "sec": 1591545791,
                                                    "usec": 0
                                                }
                                            },
                                            "hashKey": "exteriorTyres_fenders_lhs_additionalInfo_form_1",
                                            "currentCondition": {
                                                "title": "Current Condition",
                                                "value": [
                                                    "Rusted"
                                                ]
                                            },
                                            "severityScaleCC": {
                                                "title": "Severity Scale",
                                                "value": {
                                                    "Rusted": "4"
                                                }
                                            }
                                        }
                                    ],
                                    "title": "LHS",
                                    "value": "No"
                                }
                            },
                            "title": "Fender",
                            "value": "No"
                        },
                        "roof": {
                            "additionalInfo": [
                                {
                                    "image": {
                                        "title": "Roof_Additional_Info_Form_1",
                                        "url": "inspection/2020/03/12/RR55RQ6/v0/exteriorTyres/additionalInfo/roof/roofadditionalinfoform1_1584007056.jpg",
                                        "hashKey": "exteriorTyres_roof_additionalInfo_form_0",
                                        "pointerCount": 0,
                                        "isTilted": 0,
                                        "imageUploadTime": {
                                            "sec": 1584026843,
                                            "usec": 0
                                        },
                                        "serverTime": {
                                            "sec": 1584026856,
                                            "usec": 0
                                        },
                                        "rating": "Yes"
                                    },
                                    "hashKey": "exteriorTyres_roof_additionalInfo_form_0",
                                    "currentCondition": {
                                        "title": "Current Condition",
                                        "value": [
                                            "Dented"
                                        ]
                                    },
                                    "severityScaleCC": {
                                        "title": "Severity Scale",
                                        "value": {
                                            "Dented": "3"
                                        }
                                    }
                                }
                            ],
                            "title": "Roof",
                            "value": "No"
                        },
                        "quarterPanel": {
                            "subParts": {
                                "rhs": {
                                    "additionalInfo": [
                                        {
                                            "image": {
                                                "title": "Quarter_panel_Rhs_Additional_Info_Form_1",
                                                "url": "inspection/2020/03/12/RR55RQ6/v0/exteriorTyres/additionalInfo/quarterPanel/rhs/quarterpanelrhsadditionalinfoform1_1584007055.jpg",
                                                "hashKey": "exteriorTyres_quarterPanel_rhs_additionalInfo_form_0",
                                                "pointerCount": 0,
                                                "isTilted": 0,
                                                "imageUploadTime": {
                                                    "sec": 1584026843,
                                                    "usec": 0
                                                },
                                                "serverTime": {
                                                    "sec": 1584026855,
                                                    "usec": 0
                                                },
                                                "rating": "Yes"
                                            },
                                            "hashKey": "exteriorTyres_quarterPanel_rhs_additionalInfo_form_0",
                                            "currentCondition": {
                                                "title": "Current Condition",
                                                "value": [
                                                    "Scratched",
                                                    "Dented"
                                                ]
                                            },
                                            "severityScaleCC": {
                                                "title": "Severity Scale",
                                                "value": {
                                                    "Dented": "3",
                                                    "Scratched": "2"
                                                }
                                            }
                                        }
                                    ],
                                    "title": "RHS",
                                    "value": "No"
                                },
                                "lhs": {
                                    "additionalInfo": [
                                        {
                                            "image": {
                                                "title": "Quarter_panel_Lhs_Additional_Info_Form_1",
                                                "url": "inspection/2020/03/12/RR55RQ6/v0/exteriorTyres/additionalInfo/quarterPanel/lhs/quarterpanellhsadditionalinfoform1_1584007591.jpg",
                                                "hashKey": "exteriorTyres_quarterPanel_lhs_additionalInfo_form_0",
                                                "pointerCount": 0,
                                                "isTilted": 0,
                                                "imageUploadTime": {
                                                    "sec": 1584027390,
                                                    "usec": 0
                                                },
                                                "serverTime": {
                                                    "sec": 1584027392,
                                                    "usec": 0
                                                },
                                                "rating": "Yes"
                                            },
                                            "hashKey": "exteriorTyres_quarterPanel_lhs_additionalInfo_form_0",
                                            "currentCondition": {
                                                "title": "Current Condition",
                                                "value": [
                                                    "Scratched"
                                                ]
                                            },
                                            "workDone": {
                                                "title": "Work Done",
                                                "value": [
                                                    "Repainted"
                                                ]
                                            },
                                            "severityScaleCC": {
                                                "title": "Severity Scale",
                                                "value": {
                                                    "Scratched": "3"
                                                }
                                            }
                                        }
                                    ],
                                    "title": "LHS",
                                    "value": "No"
                                }
                            },
                            "title": "Quarter Panel",
                            "value": "No"
                        },
                        "rhsFrontTyre": {
                            "additionalInfo": [
                                {
                                    "image": {
                                        "title": "Rhs_front_tyre_Additional_Info_Form_1",
                                        "url": "inspection/2020/03/12/RR55RQ6/v0/exteriorTyres/additionalInfo/rhsFrontTyre/rhsfronttyreadditionalinfoform1_1584007062.jpg",
                                        "hashKey": "exteriorTyres_rhsFrontTyre_additionalInfo_form_0",
                                        "pointerCount": 0,
                                        "isTilted": 0,
                                        "imageUploadTime": {
                                            "sec": 1584026860,
                                            "usec": 0
                                        },
                                        "serverTime": {
                                            "sec": 1584026862,
                                            "usec": 0
                                        },
                                        "rating": "Yes"
                                    },
                                    "hashKey": "exteriorTyres_rhsFrontTyre_additionalInfo_form_0",
                                    "treadDepth": {
                                        "title": "Tread Depth",
                                        "value": [
                                            "22-38%"
                                        ]
                                    }
                                }
                            ],
                            "title": "RHS Front Tyre",
                            "value": "No"
                        },
                        "rhsRearTyre": {
                            "additionalInfo": [
                                {
                                    "image": {
                                        "title": "Rhs_rear_tyre_Additional_Info_Form_1",
                                        "url": "inspection/2020/03/12/RR55RQ6/v0/exteriorTyres/additionalInfo/rhsRearTyre/rhsreartyreadditionalinfoform1_1584007075.jpg",
                                        "hashKey": "exteriorTyres_rhsRearTyre_additionalInfo_form_0",
                                        "pointerCount": 0,
                                        "isTilted": 0,
                                        "imageUploadTime": {
                                            "sec": 1584026873,
                                            "usec": 0
                                        },
                                        "serverTime": {
                                            "sec": 1584026875,
                                            "usec": 0
                                        },
                                        "rating": "Yes"
                                    },
                                    "hashKey": "exteriorTyres_rhsRearTyre_additionalInfo_form_0",
                                    "treadDepth": {
                                        "title": "Tread Depth",
                                        "value": [
                                            "6-22%"
                                        ]
                                    }
                                }
                            ],
                            "title": "RHS Rear Tyre",
                            "value": "No"
                        },
                        "windshield": {
                            "subParts": {
                                "front": {
                                    "additionalInfo": [
                                        {
                                            "image": {
                                                "title": "Windshield_Front_Additional_Info_Form_1",
                                                "url": "inspection/2020/03/12/RR55RQ6/v0/exteriorTyres/additionalInfo/windshield/front/windshieldfrontadditionalinfoform1_1584007111.jpg",
                                                "hashKey": "exteriorTyres_windshield_front_additionalInfo_form_0",
                                                "pointerCount": 0,
                                                "isTilted": 0,
                                                "imageUploadTime": {
                                                    "sec": 1584026909,
                                                    "usec": 0
                                                },
                                                "serverTime": {
                                                    "sec": 1584026911,
                                                    "usec": 0
                                                },
                                                "rating": "Yes"
                                            },
                                            "hashKey": "exteriorTyres_windshield_front_additionalInfo_form_0",
                                            "workDone": {
                                                "title": "Work Done",
                                                "value": [
                                                    "Replaced"
                                                ]
                                            }
                                        }
                                    ],
                                    "title": "Front",
                                    "value": "No"
                                },
                                "rear": {
                                    "title": "Rear",
                                    "value": "Yes"
                                }
                            },
                            "title": "Windshield",
                            "value": "No"
                        },
                        "orvm": {
                            "subParts": {
                                "rhs": {
                                    "additionalInfo": [
                                        {
                                            "image": {
                                                "title": "Orvm_-_manual_/_electrical_Rhs_Additional_Info_Form_1",
                                                "url": "inspection/2020/03/12/RR55RQ6/v0/exteriorTyres/additionalInfo/orvm/rhs/orvmmanualelectricalrhsadditionalinfoform1_1584007119.jpg",
                                                "hashKey": "exteriorTyres_orvm_rhs_additionalInfo_form_0",
                                                "pointerCount": 0,
                                                "isTilted": 0,
                                                "imageUploadTime": {
                                                    "sec": 1584026918,
                                                    "usec": 0
                                                },
                                                "serverTime": {
                                                    "sec": 1584026919,
                                                    "usec": 0
                                                },
                                                "rating": "Yes"
                                            },
                                            "hashKey": "exteriorTyres_orvm_rhs_additionalInfo_form_0",
                                            "currentCondition": {
                                                "title": "Current Condition",
                                                "value": [
                                                    "Scratched"
                                                ]
                                            }
                                        }
                                    ],
                                    "title": "RHS",
                                    "value": "No"
                                },
                                "lhs": {
                                    "additionalInfo": [
                                        {
                                            "image": {
                                                "title": "Orvm_-_manual_/_electrical_Lhs_Additional_Info_Form_1",
                                                "url": "inspection/2020/03/12/RR55RQ6/v0/exteriorTyres/additionalInfo/orvm/lhs/orvmmanualelectricallhsadditionalinfoform1_1584007568.jpg",
                                                "hashKey": "exteriorTyres_orvm_lhs_additionalInfo_form_0",
                                                "pointerCount": 0,
                                                "isTilted": 0,
                                                "imageUploadTime": {
                                                    "sec": 1584027367,
                                                    "usec": 0
                                                },
                                                "serverTime": {
                                                    "sec": 1584027368,
                                                    "usec": 0
                                                },
                                                "rating": "Yes"
                                            },
                                            "hashKey": "exteriorTyres_orvm_lhs_additionalInfo_form_0",
                                            "currentCondition": {
                                                "title": "Current Condition",
                                                "value": [
                                                    "Scratched"
                                                ]
                                            }
                                        }
                                    ],
                                    "title": "LHS",
                                    "value": "No"
                                }
                            },
                            "title": "ORVM - Manual / Electrical",
                            "value": "No"
                        },
                        "lights": {
                            "subParts": {
                                "rhsTaillight": {
                                    "title": "RHS Taillight",
                                    "value": "Yes"
                                },
                                "lhsTaillight": {
                                    "additionalInfo": [
                                        {
                                            "image": {
                                                "title": "Light_Lhs_taillight_Additional_Info_Form_1",
                                                "url": "inspection/2020/03/12/RR55RQ6/v0/exteriorTyres/additionalInfo/lights/lhsTaillight/lightlhstaillightadditionalinfoform1_1584007135.jpg",
                                                "hashKey": "exteriorTyres_lights_lhsTaillight_additionalInfo_form_0",
                                                "pointerCount": 0,
                                                "isTilted": 0,
                                                "imageUploadTime": {
                                                    "sec": 1584026933,
                                                    "usec": 0
                                                },
                                                "serverTime": {
                                                    "sec": 1584026935,
                                                    "usec": 0
                                                },
                                                "rating": "Yes"
                                            },
                                            "hashKey": "exteriorTyres_lights_lhsTaillight_additionalInfo_form_0",
                                            "currentCondition": {
                                                "title": "Current Condition",
                                                "value": [
                                                    "Damaged"
                                                ]
                                            }
                                        }
                                    ],
                                    "title": "LHS Taillight",
                                    "value": "No"
                                },
                                "lhsHeadlight": {
                                    "additionalInfo": [
                                        {
                                            "image": {
                                                "title": "Light_Lhs_headlight_Additional_Info_Form_1",
                                                "url": "inspection/2020/03/12/RR55RQ6/v0/exteriorTyres/additionalInfo/lights/lhsHeadlight/lightlhsheadlightadditionalinfoform1_1584007679.jpg",
                                                "hashKey": "exteriorTyres_lights_lhsHeadlight_additionalInfo_form_0",
                                                "pointerCount": 0,
                                                "isTilted": 0,
                                                "imageUploadTime": {
                                                    "sec": 1584027477,
                                                    "usec": 0
                                                },
                                                "serverTime": {
                                                    "sec": 1584027479,
                                                    "usec": 0
                                                },
                                                "rating": "Yes"
                                            },
                                            "hashKey": "exteriorTyres_lights_lhsHeadlight_additionalInfo_form_0",
                                            "currentCondition": {
                                                "title": "Current Condition",
                                                "value": [
                                                    "Scratched"
                                                ]
                                            }
                                        }
                                    ],
                                    "title": "LHS Headlight",
                                    "value": "No"
                                },
                                "rhsHeadlight": {
                                    "additionalInfo": [
                                        {
                                            "image": {
                                                "title": "Light_Rhs_headlight_Additional_Info_Form_1",
                                                "url": "inspection/2020/03/12/RR55RQ6/v0/exteriorTyres/additionalInfo/lights/rhsHeadlight/lightrhsheadlightadditionalinfoform1_1584007684.jpg",
                                                "hashKey": "exteriorTyres_lights_rhsHeadlight_additionalInfo_form_0",
                                                "pointerCount": 0,
                                                "isTilted": 0,
                                                "imageUploadTime": {
                                                    "sec": 1584027482,
                                                    "usec": 0
                                                },
                                                "serverTime": {
                                                    "sec": 1584027484,
                                                    "usec": 0
                                                },
                                                "rating": "Yes"
                                            },
                                            "hashKey": "exteriorTyres_lights_rhsHeadlight_additionalInfo_form_0",
                                            "currentCondition": {
                                                "title": "Current Condition",
                                                "value": [
                                                    "Scratched"
                                                ]
                                            }
                                        }
                                    ],
                                    "title": "RHS Headlight",
                                    "value": "No"
                                },
                                "lhsFogLight": {
                                    "title": "LHS Fog Light",
                                    "value": "No",
                                    "additionalInfo": [
                                        {
                                            "image": {
                                                "title": "Light_Lhs_fog_light_Additional_Info_Form_1",
                                                "url": "inspection/2020/03/12/RR55RQ6/v2/exteriorTyres/additionalInfo/lights/lhsFogLight/lightlhsfoglightadditionalinfoform1_1591525883.jpg",
                                                "hashKey": "exteriorTyres_lights_lhsFogLight_additionalInfo_form_0",
                                                "pointerCount": 0,
                                                "isTilted": 0,
                                                "imageUploadTime": {
                                                    "sec": 1591545680,
                                                    "usec": 0
                                                },
                                                "serverTime": {
                                                    "sec": 1591545683,
                                                    "usec": 0
                                                }
                                            },
                                            "hashKey": "exteriorTyres_lights_lhsFogLight_additionalInfo_form_0",
                                            "currentCondition": {
                                                "title": "Current Condition",
                                                "value": [
                                                    "Not Working"
                                                ]
                                            }
                                        }
                                    ]
                                },
                                "rhsFogLight": {
                                    "title": "RHS Fog Light",
                                    "value": "No",
                                    "additionalInfo": [
                                        {
                                            "image": {
                                                "title": "Light_Rhs_fog_light_Additional_Info_Form_1",
                                                "url": "inspection/2020/03/12/RR55RQ6/v2/exteriorTyres/additionalInfo/lights/rhsFogLight/lightrhsfoglightadditionalinfoform1_1591525872.jpg",
                                                "hashKey": "exteriorTyres_lights_rhsFogLight_additionalInfo_form_0",
                                                "pointerCount": 0,
                                                "isTilted": 0,
                                                "imageUploadTime": {
                                                    "sec": 1591545667,
                                                    "usec": 0
                                                },
                                                "serverTime": {
                                                    "sec": 1591545672,
                                                    "usec": 0
                                                }
                                            },
                                            "hashKey": "exteriorTyres_lights_rhsFogLight_additionalInfo_form_0",
                                            "currentCondition": {
                                                "title": "Current Condition",
                                                "value": [
                                                    "Not Working"
                                                ]
                                            }
                                        }
                                    ]
                                }
                            },
                            "title": "Light",
                            "value": "No"
                        },
                        "dickyDoorBootDoor": {
                            "additionalInfo": [
                                {
                                    "image": {
                                        "title": "Dicky_door_/_boot_door_Additional_Info_Form_1",
                                        "url": "inspection/2020/03/12/RR55RQ6/v0/exteriorTyres/additionalInfo/dickyDoorBootDoor/dickydoorbootdooradditionalinfoform1_1584007150.jpg",
                                        "hashKey": "exteriorTyres_dickyDoorBootDoor_additionalInfo_form_0",
                                        "pointerCount": 0,
                                        "isTilted": 0,
                                        "imageUploadTime": {
                                            "sec": 1584026949,
                                            "usec": 0
                                        },
                                        "serverTime": {
                                            "sec": 1584026950,
                                            "usec": 0
                                        },
                                        "rating": "Yes"
                                    },
                                    "hashKey": "exteriorTyres_dickyDoorBootDoor_additionalInfo_form_0",
                                    "currentCondition": {
                                        "title": "Current Condition",
                                        "value": [
                                            "Scratched",
                                            "Rusted"
                                        ]
                                    },
                                    "workDone": {
                                        "title": "Work Done",
                                        "value": [
                                            "Repainted"
                                        ]
                                    },
                                    "severityScaleCC": {
                                        "title": "Severity Scale",
                                        "value": {
                                            "Rusted": "1",
                                            "Scratched": "4"
                                        }
                                    }
                                }
                            ],
                            "title": "Dicky Door / Boot Door",
                            "value": "No"
                        },
                        "bootFloor": {
                            "additionalInfo": [
                                {
                                    "image": {
                                        "title": "Boot_floor_Additional_Info_Form_1",
                                        "url": "inspection/2020/03/12/RR55RQ6/v0/exteriorTyres/additionalInfo/bootFloor/bootflooradditionalinfoform1_1584007378.jpg",
                                        "hashKey": "exteriorTyres_bootFloor_additionalInfo_form_0",
                                        "pointerCount": 0,
                                        "isTilted": 0,
                                        "imageUploadTime": {
                                            "sec": 1584027176,
                                            "usec": 0
                                        },
                                        "serverTime": {
                                            "sec": 1584027178,
                                            "usec": 0
                                        },
                                        "rating": "Yes"
                                    },
                                    "hashKey": "exteriorTyres_bootFloor_additionalInfo_form_0",
                                    "currentCondition": {
                                        "title": "Current Condition",
                                        "value": [
                                            "Rusted"
                                        ]
                                    },
                                    "severityScaleCC": {
                                        "title": "Severity Scale",
                                        "value": {
                                            "Rusted": "5"
                                        }
                                    },
                                    "workDone": {
                                        "title": "Work Done",
                                        "value": [
                                            "Repaired"
                                        ]
                                    }
                                }
                            ],
                            "title": "Boot Floor",
                            "value": "No"
                        },
                        "spareTyre": {
                            "additionalInfo": [
                                {
                                    "image": {
                                        "title": "Spare_tyre_Additional_Info_Form_1",
                                        "url": "inspection/2020/03/12/RR55RQ6/v0/exteriorTyres/additionalInfo/spareTyre/sparetyreadditionalinfoform1_1584007387.jpg",
                                        "hashKey": "exteriorTyres_spareTyre_additionalInfo_form_0",
                                        "pointerCount": 0,
                                        "isTilted": 0,
                                        "imageUploadTime": {
                                            "sec": 1584027186,
                                            "usec": 0
                                        },
                                        "serverTime": {
                                            "sec": 1584027187,
                                            "usec": 0
                                        },
                                        "rating": "Yes"
                                    },
                                    "hashKey": "exteriorTyres_spareTyre_additionalInfo_form_0",
                                    "treadDepth": {
                                        "title": "Tread Depth",
                                        "value": [
                                            "0-6%"
                                        ]
                                    }
                                }
                            ],
                            "title": "Spare Tyre",
                            "value": "No"
                        },
                        "lhsRearTyre": {
                            "additionalInfo": [
                                {
                                    "image": {
                                        "title": "Lhs_rear_tyre_Additional_Info_Form_1",
                                        "url": "inspection/2020/03/12/RR55RQ6/v0/exteriorTyres/additionalInfo/lhsRearTyre/lhsreartyreadditionalinfoform1_1584007421.jpg",
                                        "hashKey": "exteriorTyres_lhsRearTyre_additionalInfo_form_0",
                                        "pointerCount": 0,
                                        "isTilted": 0,
                                        "imageUploadTime": {
                                            "sec": 1584027220,
                                            "usec": 0
                                        },
                                        "serverTime": {
                                            "sec": 1584027221,
                                            "usec": 0
                                        },
                                        "rating": "Yes"
                                    },
                                    "hashKey": "exteriorTyres_lhsRearTyre_additionalInfo_form_0",
                                    "treadDepth": {
                                        "title": "Tread Depth",
                                        "value": [
                                            "22-38%"
                                        ]
                                    }
                                }
                            ],
                            "title": "LHS Rear Tyre",
                            "value": "No"
                        },
                        "lhsFrontTyre": {
                            "additionalInfo": [
                                {
                                    "image": {
                                        "title": "Lhs_front_tyre_Additional_Info_Form_1",
                                        "url": "inspection/2020/03/12/RR55RQ6/v0/exteriorTyres/additionalInfo/lhsFrontTyre/lhsfronttyreadditionalinfoform1_1584007746.jpg",
                                        "hashKey": "exteriorTyres_lhsFrontTyre_additionalInfo_form_0",
                                        "pointerCount": 0,
                                        "isTilted": 0,
                                        "imageUploadTime": {
                                            "sec": 1584027544,
                                            "usec": 0
                                        },
                                        "serverTime": {
                                            "sec": 1584027546,
                                            "usec": 0
                                        },
                                        "rating": "Yes"
                                    },
                                    "hashKey": "exteriorTyres_lhsFrontTyre_additionalInfo_form_0",
                                    "treadDepth": {
                                        "title": "Tread Depth",
                                        "value": [
                                            "22-38%"
                                        ]
                                    }
                                }
                            ],
                            "title": "LHS Front Tyre",
                            "value": "No"
                        },
                        "alloyWheels": {
                            "title": "Alloy Wheel",
                            "value": "N/A"
                        },
                        "bonnetPatti": {
                            "title": "Upper Cross Member (Bonnet Patti)",
                            "value": "No",
                            "additionalInfo": [
                                {
                                    "image": {
                                        "title": "Upper_cross_member_(bonnet_patti)_Additional_Info_Form_1",
                                        "url": "inspection/2020/03/12/RR55RQ6/v2/exteriorTyres/additionalInfo/bonnetPatti/uppercrossmemberbonnetpattiadditionalinfoform1_1591525929.jpg",
                                        "hashKey": "exteriorTyres_bonnetPatti_additionalInfo_form_0",
                                        "pointerCount": 0,
                                        "isTilted": 0,
                                        "imageUploadTime": {
                                            "sec": 1591545726,
                                            "usec": 0
                                        },
                                        "serverTime": {
                                            "sec": 1591545729,
                                            "usec": 0
                                        }
                                    },
                                    "hashKey": "exteriorTyres_bonnetPatti_additionalInfo_form_0",
                                    "currentCondition": {
                                        "title": "Current Condition",
                                        "value": [
                                            "Rusted"
                                        ]
                                    }
                                }
                            ]
                        },
                        "frontShow": {
                            "title": "Front Show",
                            "value": "Yes"
                        },
                        "headLightSupport": {
                            "title": "Head Light Support",
                            "value": "Yes"
                        },
                        "lowerCrossMember": {
                            "title": "Lower Cross Member",
                            "value": "Yes"
                        },
                        "pillars": {
                            "title": "Pillar",
                            "value": "Yes"
                        },
                        "radiatorSupport": {
                            "title": "Radiator Support",
                            "value": "Yes"
                        },
                        "rating": {
                            "title": "Rating",
                            "value": 3
                        },
                        "errorsCount": 0,
                        "reinspDiff": {
                            "doors": {
                                "subParts": {
                                    "rhsFront": {
                                        "additionalInfo": [
                                            {
                                                "hashKey": "exteriorTyres_doors_rhsFront_additionalInfo_form_0",
                                                "currentCondition": {
                                                    "title": "Current Condition",
                                                    "value": [
                                                        "Scratched",
                                                        "Dented"
                                                    ]
                                                },
                                                "workDone": {
                                                    "title": "Work Done",
                                                    "value": [
                                                        "Repainted"
                                                    ]
                                                },
                                                "severityScaleCC": {
                                                    "title": "Severity Scale",
                                                    "value": {
                                                        "Dented": "3",
                                                        "Scratched": "3"
                                                    }
                                                },
                                                "isValidate": 3
                                            }
                                        ],
                                        "title": "RHS Front",
                                        "value": "No",
                                        "isValidate": 3
                                    },
                                    "lhsFront": {
                                        "additionalInfo": [
                                            {
                                                "hashKey": "exteriorTyres_doors_lhsFront_additionalInfo_form_0",
                                                "currentCondition": {
                                                    "title": "Current Condition",
                                                    "value": [
                                                        "Scratched"
                                                    ]
                                                },
                                                "workDone": {
                                                    "title": "Work Done",
                                                    "value": [
                                                        "Repainted"
                                                    ]
                                                },
                                                "severityScaleCC": {
                                                    "title": "Severity Scale",
                                                    "value": {
                                                        "Scratched": "5"
                                                    }
                                                },
                                                "isValidate": 3
                                            }
                                        ],
                                        "title": "LHS Front",
                                        "value": "No",
                                        "isValidate": 3
                                    }
                                },
                                "title": "Door",
                                "value": "No"
                            },
                            "fenders": {
                                "subParts": {
                                    "rhs": {
                                        "additionalInfo": [
                                            {
                                                "hashKey": "exteriorTyres_fenders_rhs_additionalInfo_form_0",
                                                "currentCondition": {
                                                    "title": "Current Condition",
                                                    "value": [
                                                        "Scratched"
                                                    ]
                                                },
                                                "workDone": {
                                                    "title": "Work Done",
                                                    "value": [
                                                        "Repainted"
                                                    ]
                                                },
                                                "severityScaleCC": {
                                                    "title": "Severity Scale",
                                                    "value": {
                                                        "Scratched": "3"
                                                    }
                                                },
                                                "isValidate": 3
                                            }
                                        ],
                                        "title": "RHS",
                                        "value": "No",
                                        "isValidate": 3
                                    },
                                    "lhs": {
                                        "additionalInfo": [
                                            {
                                                "hashKey": "exteriorTyres_fenders_lhs_additionalInfo_form_0",
                                                "currentCondition": {
                                                    "title": "Current Condition",
                                                    "value": [
                                                        "Scratched"
                                                    ]
                                                },
                                                "workDone": {
                                                    "title": "Work Done",
                                                    "value": [
                                                        "Repainted"
                                                    ]
                                                },
                                                "severityScaleCC": {
                                                    "title": "Severity Scale",
                                                    "value": {
                                                        "Scratched": "5"
                                                    }
                                                },
                                                "isValidate": 3
                                            }
                                        ],
                                        "title": "LHS",
                                        "value": "No",
                                        "isValidate": 3
                                    }
                                },
                                "title": "Fender",
                                "value": "No"
                            },
                            "quarterPanel": {
                                "subParts": {
                                    "rhs": {
                                        "additionalInfo": [
                                            {
                                                "hashKey": "exteriorTyres_quarterPanel_rhs_additionalInfo_form_0",
                                                "currentCondition": {
                                                    "title": "Current Condition",
                                                    "value": [
                                                        "Scratched",
                                                        "Dented"
                                                    ]
                                                },
                                                "workDone": {
                                                    "title": "Work Done"
                                                },
                                                "severityScaleCC": {
                                                    "title": "Severity Scale",
                                                    "value": {
                                                        "Dented": "3",
                                                        "Scratched": "2"
                                                    }
                                                },
                                                "isValidate": 3
                                            }
                                        ],
                                        "title": "RHS",
                                        "value": "No",
                                        "isValidate": 3
                                    }
                                },
                                "title": "Quarter Panel",
                                "value": "No"
                            },
                            "lights": {
                                "subParts": {
                                    "lhsFogLight": {
                                        "title": "LHS Fog Light",
                                        "value": "Yes",
                                        "isValidate": 3
                                    },
                                    "rhsFogLight": {
                                        "title": "RHS Fog Light",
                                        "value": "Yes",
                                        "isValidate": 3
                                    }
                                },
                                "title": "Light",
                                "value": "No"
                            },
                            "dickyDoorBootDoor": {
                                "additionalInfo": [
                                    {
                                        "hashKey": "exteriorTyres_dickyDoorBootDoor_additionalInfo_form_0",
                                        "currentCondition": {
                                            "title": "Current Condition",
                                            "value": [
                                                "Scratched"
                                            ]
                                        },
                                        "workDone": {
                                            "title": "Work Done",
                                            "value": [
                                                "Repainted"
                                            ]
                                        },
                                        "severityScaleCC": {
                                            "title": "Severity Scale",
                                            "value": {
                                                "Scratched": "4"
                                            }
                                        },
                                        "isValidate": 3
                                    }
                                ],
                                "title": "Dicky Door / Boot Door",
                                "value": "No",
                                "isValidate": 3
                            },
                            "bonnetPatti": {
                                "title": "Upper Cross Member (Bonnet Patti)",
                                "value": "Yes",
                                "isValidate": 3
                            }
                        }
                    },
                    "electricalsInterior": {
                        "mainImages": [
                            {
                                "title": "Interior From Front Right",
                                "url": "inspection/2020/03/12/RR55RQ6/v0/electricalsInterior/mainImages/interiorfromfrontright_1584006565.jpg",
                                "hashKey": "inspectionData_electricalInterior_mainImages_interiorfromfrontright",
                                "pointerCount": 0,
                                "isTilted": 1,
                                "field": "inspectionData_electricalInterior_mainImages_interiorfromfrontright",
                                "imageUploadTime": {
                                    "sec": 1584026339,
                                    "usec": 0
                                },
                                "serverTime": {
                                    "sec": 1584026365,
                                    "usec": 0
                                },
                                "rating": "Yes"
                            },
                            {
                                "title": "Interior From Rear Right",
                                "url": "inspection/2020/03/12/RR55RQ6/v0/electricalsInterior/mainImages/interiorfromrearright_1584006566.jpg",
                                "hashKey": "inspectionData_electricalInterior_mainImages_interiorfromrearright",
                                "pointerCount": 0,
                                "isTilted": 0,
                                "field": "inspectionData_electricalInterior_mainImages_interiorfromrearright",
                                "imageUploadTime": {
                                    "sec": 1584026348,
                                    "usec": 0
                                },
                                "serverTime": {
                                    "sec": 1584026366,
                                    "usec": 0
                                },
                                "rating": "Yes"
                            },
                            {
                                "title": "Interior From Back Seat",
                                "url": "inspection/2020/03/12/RR55RQ6/v0/electricalsInterior/mainImages/interiorfrombackseat_1584006567.jpg",
                                "hashKey": "inspectionData_electricalInterior_mainImages_interiorfromback",
                                "pointerCount": 0,
                                "isTilted": 1,
                                "field": "inspectionData_electricalInterior_mainImages_interiorfromback",
                                "imageUploadTime": {
                                    "sec": 1584026363,
                                    "usec": 0
                                },
                                "serverTime": {
                                    "sec": 1584026367,
                                    "usec": 0
                                },
                                "rating": "Yes"
                            },
                            {
                                "title": "Odometer",
                                "url": "inspection/2020/03/12/RR55RQ6/v2/electricalsInterior/mainImages/odometer_1591526294.jpg",
                                "hashKey": "inspectionData_electricalInterior_mainImages_odometer",
                                "pointerCount": 0,
                                "isTilted": 0,
                                "field": "inspectionData_electricalInterior_mainImages_odometer",
                                "imageUploadTime": {
                                    "sec": 1591546085,
                                    "usec": 0
                                },
                                "serverTime": {
                                    "sec": 1591546094,
                                    "usec": 0
                                }
                            }
                        ],
                        "abs": {
                            "title": "ABS",
                            "value": "N/A"
                        },
                        "electrical": {
                            "title": "Electrical",
                            "value": "Yes"
                        },
                        "estimatedRFC": {
                            "title": "Estimated RFC",
                            "value": 0
                        },
                        "fabricSeats": {
                            "title": "Fabric Seat",
                            "value": "No",
                            "additionalInfo": [
                                {
                                    "image": {
                                        "title": "Fabric_seat_Additional_Info_Form_1",
                                        "url": "inspection/2020/03/12/RR55RQ6/v2/electricalsInterior/additionalInfo/fabricSeats/fabricseatadditionalinfoform1_1591526319.jpg",
                                        "hashKey": "electricalsInterior_fabricSeats_additionalInfo_form_0",
                                        "pointerCount": 0,
                                        "isTilted": 0,
                                        "imageUploadTime": {
                                            "sec": 1591546113,
                                            "usec": 0
                                        },
                                        "serverTime": {
                                            "sec": 1591546120,
                                            "usec": 0
                                        }
                                    },
                                    "hashKey": "electricalsInterior_fabricSeats_additionalInfo_form_0",
                                    "currentCondition": {
                                        "title": "Current Condition",
                                        "value": [
                                            "Torn",
                                            "Worn Out",
                                            "Depressed"
                                        ]
                                    }
                                }
                            ]
                        },
                        "highlightPositives": {
                            "title": "Highlight Positive",
                            "value": [
                                {
                                    "comment": "Power Windows",
                                    "id": 3,
                                    "rank": 12,
                                    "type": ""
                                },
                                {
                                    "comment": "Music System",
                                    "id": 5,
                                    "rank": 13,
                                    "type": ""
                                }
                            ]
                        },
                        "interior": {
                            "title": "Interior",
                            "value": "No",
                            "additionalInfo": [
                                {
                                    "currentCondition": {
                                        "title": "Current Condition",
                                        "value": [
                                            "Door Trim torn",
                                            "AC Vent damaged",
                                            "Glove Box cover damaged",
                                            "Dashboard scratched & broken"
                                        ]
                                    },
                                    "hashKey": "electricalsInterior_interior_additionalInfo_form_0"
                                }
                            ]
                        },
                        "leatherSeats": {
                            "title": "Leather Seat",
                            "value": "N/A"
                        },
                        "musicSystem": {
                            "title": "Music System",
                            "value": "Yes"
                        },
                        "numberOfAirbags": {
                            "title": "No. Of Airbags",
                            "value": "0"
                        },
                        "powerWindowsNumber": {
                            "title": "No. Of Power Windows",
                            "value": "2"
                        },
                        "rearDefogger": {
                            "title": "Rear Defogger",
                            "value": "N/A"
                        },
                        "reverseCamera": {
                            "title": "Reverse Camera",
                            "value": "N/A"
                        },
                        "steeringMountedAudioControls": {
                            "title": "Steering Mounted Audio Control",
                            "value": "N/A"
                        },
                        "sunroof": {
                            "title": "Sunroof",
                            "value": "N/A"
                        },
                        "rating": {
                            "title": "Rating",
                            "value": 4
                        },
                        "odometerReading": {
                            "title": "Odometer(Km)",
                            "value": "72674"
                        },
                        "comments": {
                            "title": "Comments",
                            "value": [

                            ]
                        },
                        "errorsCount": 0,
                        "powerWindows": {
                            "title": "Power Windows",
                            "value": "Yes"
                        },
                        "reinspDiff": {
                            "mainImages": [
                                {
                                    "title": "Interior From Front Right",
                                    "url": "inspection/2020/03/12/RR55RQ6/v0/electricalsInterior/mainImages/interiorfromfrontright_1584006565.jpg",
                                    "hashKey": "inspectionData_electricalInterior_mainImages_interiorfromfrontright",
                                    "pointerCount": 0,
                                    "isTilted": 1,
                                    "field": "inspectionData_electricalInterior_mainImages_interiorfromfrontright",
                                    "imageUploadTime": {
                                        "sec": 1584026339,
                                        "usec": 0
                                    },
                                    "serverTime": {
                                        "sec": 1584026365,
                                        "usec": 0
                                    },
                                    "rating": "Yes"
                                },
                                {
                                    "title": "Interior From Rear Right",
                                    "url": "inspection/2020/03/12/RR55RQ6/v0/electricalsInterior/mainImages/interiorfromrearright_1584006566.jpg",
                                    "hashKey": "inspectionData_electricalInterior_mainImages_interiorfromrearright",
                                    "pointerCount": 0,
                                    "isTilted": 0,
                                    "field": "inspectionData_electricalInterior_mainImages_interiorfromrearright",
                                    "imageUploadTime": {
                                        "sec": 1584026348,
                                        "usec": 0
                                    },
                                    "serverTime": {
                                        "sec": 1584026366,
                                        "usec": 0
                                    },
                                    "rating": "Yes"
                                },
                                {
                                    "title": "Interior From Back Seat",
                                    "url": "inspection/2020/03/12/RR55RQ6/v0/electricalsInterior/mainImages/interiorfrombackseat_1584006567.jpg",
                                    "hashKey": "inspectionData_electricalInterior_mainImages_interiorfromback",
                                    "pointerCount": 0,
                                    "isTilted": 1,
                                    "field": "inspectionData_electricalInterior_mainImages_interiorfromback",
                                    "imageUploadTime": {
                                        "sec": 1584026363,
                                        "usec": 0
                                    },
                                    "serverTime": {
                                        "sec": 1584026367,
                                        "usec": 0
                                    },
                                    "rating": "Yes"
                                },
                                {
                                    "title": "Odometer",
                                    "url": "inspection/2020/03/12/RR55RQ6/v1/electricalsInterior/mainImages/odometer_1584601057.jpg",
                                    "hashKey": "inspectionData_electricalInterior_mainImages_odometer",
                                    "pointerCount": 0,
                                    "isTilted": 1,
                                    "field": "inspectionData_electricalInterior_mainImages_odometer",
                                    "imageUploadTime": {
                                        "sec": 1584620850,
                                        "usec": 0
                                    },
                                    "serverTime": {
                                        "sec": 1584620858,
                                        "usec": 0
                                    },
                                    "rating": "Yes",
                                    "isValidate": 3
                                }
                            ],
                            "fabricSeats": {
                                "title": "Fabric Seat",
                                "value": "No",
                                "additionalInfo": [
                                    {
                                        "hashKey": "electricalsInterior_fabricSeats_additionalInfo_form_0",
                                        "currentCondition": {
                                            "title": "Current Condition",
                                            "value": [
                                                "Depressed"
                                            ]
                                        },
                                        "isValidate": 3
                                    }
                                ],
                                "isValidate": 3
                            },
                            "interior": {
                                "title": "Interior",
                                "value": "No",
                                "additionalInfo": [
                                    {
                                        "hashKey": "electricalsInterior_interior_additionalInfo_form_0",
                                        "currentCondition": {
                                            "title": "Current Condition",
                                            "value": [
                                                "Door Trim torn",
                                                "AC Vent damaged"
                                            ]
                                        },
                                        "isValidate": 3
                                    }
                                ],
                                "isValidate": 3
                            }
                        }
                    },
                    "engineTransmission": {
                        "clutch": {
                            "additionalInfo": [
                                {
                                    "currentCondition": {
                                        "title": "Current Condition",
                                        "value": [
                                            "Bearing Noise"
                                        ]
                                    },
                                    "hashKey": "engineTransmission_clutch_additionalInfo_form_0"
                                }
                            ],
                            "title": "Clutch",
                            "value": "No"
                        },
                        "comments": {
                            "title": "Comments",
                            "value": [
                                {
                                    "comment": "Radiator fan motor noise",
                                    "id": 60,
                                    "rank": 0,
                                    "type": "Comments"
                                },
                                {
                                    "comment": "Electrical wiring Damaged",
                                    "id": 43,
                                    "rank": 0,
                                    "type": "Comments"
                                },
                                {
                                    "comment": "Starter Motor / Solenoid malfunctioning",
                                    "id": 19,
                                    "rank": 0,
                                    "type": "Comments"
                                }
                            ]
                        },
                        "estimatedRFC": {
                            "title": "Estimated RFC",
                            "value": 0
                        },
                        "gearShifting": {
                            "title": "Gear Shifting",
                            "value": "Yes"
                        },
                        "highlightPositives": {
                            "title": "Highlight Positive",
                            "value": [
                                {
                                    "comment": "No Back Compression",
                                    "id": 43,
                                    "rank": 6,
                                    "type": ""
                                }
                            ]
                        },
                        "battery": {
                            "additionalInfo": [
                                {
                                    "currentCondition": {
                                        "title": "Current Condition",
                                        "value": [
                                            "Weak"
                                        ]
                                    },
                                    "hashKey": "engineTransmission_battery_additionalInfo_form_0"
                                }
                            ],
                            "title": "Battery",
                            "value": "No"
                        },
                        "coolant": {
                            "additionalInfo": [
                                {
                                    "hashKey": "engineTransmission_coolant_additionalInfo_form_0",
                                    "currentCondition": {
                                        "title": "Current Condition",
                                        "value": [
                                            "Dirty"
                                        ]
                                    }
                                }
                            ],
                            "title": "Coolant",
                            "value": "No"
                        },
                        "engineMounting": {
                            "additionalInfo": [
                                {
                                    "currentCondition": {
                                        "title": "Current Condition",
                                        "value": [
                                            "Excess Vibration"
                                        ]
                                    },
                                    "hashKey": "engineTransmission_engineMounting_additionalInfo_form_0"
                                }
                            ],
                            "title": "Engine Mounting",
                            "value": "No"
                        },
                        "engineOil": {
                            "additionalInfo": [
                                {
                                    "hashKey": "engineTransmission_engineOil_additionalInfo_form_0",
                                    "currentCondition": {
                                        "title": "Current Condition",
                                        "value": [
                                            "Dirty",
                                            "Leakage from Tappet Cover",
                                            "Leakage from Side cover"
                                        ]
                                    }
                                }
                            ],
                            "title": "Engine Oil",
                            "value": "No"
                        },
                        "engine": {
                            "title": "Engine",
                            "value": "No",
                            "additionalInfo": [
                                {
                                    "image": {
                                        "title": "Engine_Additional_Info_Form_1",
                                        "url": "inspection/2020/03/12/RR55RQ6/v0/engineTransmission/additionalInfo/engine/engineadditionalinfoform1_1584006906.jpg",
                                        "hashKey": "engineTransmission_engine_additionalInfo_form_0",
                                        "pointerCount": 0,
                                        "isTilted": 0,
                                        "imageUploadTime": {
                                            "sec": 1584026549,
                                            "usec": 0
                                        },
                                        "serverTime": {
                                            "sec": 1584026706,
                                            "usec": 0
                                        },
                                        "rating": "Yes"
                                    },
                                    "hashKey": "engineTransmission_engine_additionalInfo_form_0",
                                    "currentCondition": {
                                        "title": "Current Condition",
                                        "value": [
                                            "RPM fluctuating",
                                            "Misfiring",
                                            "MIL light glowing",
                                            "Air filter box damaged"
                                        ]
                                    }
                                }
                            ]
                        },
                        "engineBlowByBackCompression": {
                            "title": "Engine Permissible Blow by (Back Compression)",
                            "value": "Yes",
                            "additionalInfo": [
                                {
                                    "image": {
                                        "title": "Engine_permissible_blow_by_(back_compression)_Additional_Info_Form_1",
                                        "url": "inspection/2020/03/12/RR55RQ6/v0/engineTransmission/additionalInfo/engineBlowByBackCompression/enginepermissibleblowbybackcompressionadditionalinfoform1_1584007057.jpg",
                                        "hashKey": "engineTransmission_engineBlowByBackCompression_additionalInfo_form_0",
                                        "pointerCount": 0,
                                        "isTilted": 0,
                                        "imageUploadTime": {
                                            "sec": 1584026843,
                                            "usec": 0
                                        },
                                        "serverTime": {
                                            "sec": 1584026857,
                                            "usec": 0
                                        },
                                        "rating": "Yes"
                                    },
                                    "hashKey": "engineTransmission_engineBlowByBackCompression_additionalInfo_form_0",
                                    "currentCondition": {
                                        "title": "Current Condition",
                                        "value": [
                                            "No blow-by"
                                        ]
                                    }
                                }
                            ]
                        },
                        "engineOilLevelDipstik": {
                            "title": "Engine Oil Level Dipstik",
                            "value": "Yes"
                        },
                        "engineSound": {
                            "title": "Engine Sound",
                            "value": "No",
                            "additionalInfo": [
                                {
                                    "video": {
                                        "title": "Engine_sound_Additional_Info_Form_1",
                                        "hashKey": "engineTransmission_engineSound_additionalInfo_form_0",
                                        "pointerCount": 0,
                                        "engineSoundVideoIdle": "inspection/2020/03/12/RR55RQ6/v0/engineTransmission/additionalInfo/engineSound/enginesoundadditionalinfoform1_1584007059.mp4",
                                        "thumbUrl": "inspection/2020/03/12/RR55RQ6/v0/engineTransmission/additionalInfo/engineSound/enginesoundadditionalinfoform1_1584007060.jpg",
                                        "imageUploadTime": {
                                            "sec": 1584026843,
                                            "usec": 0
                                        },
                                        "serverTime": {
                                            "sec": 1584026860,
                                            "usec": 0
                                        },
                                        "url": "inspection/2020/03/12/RR55RQ6/v0/engineTransmission/additionalInfo/e01e974f8aaef801c0a03b0d8461a42e.mp4"
                                    },
                                    "hashKey": "engineTransmission_engineSound_additionalInfo_form_0",
                                    "currentCondition": {
                                        "title": "Current Condition",
                                        "value": [
                                            "Alternator Brg Noise",
                                            "Timing Noise",
                                            "Tappet Noise"
                                        ]
                                    }
                                },
                                {
                                    "video": {
                                        "title": "Engine_sound_Additional_Info_Form_2",
                                        "hashKey": "engineTransmission_engineSound_additionalInfo_form_1",
                                        "pointerCount": 0,
                                        "engineSoundVideoAccelerated": "inspection/2020/03/12/RR55RQ6/v0/engineTransmission/additionalInfo/engineSound/enginesoundadditionalinfoform2_1584007060.mp4",
                                        "thumbUrl": "inspection/2020/03/12/RR55RQ6/v0/engineTransmission/additionalInfo/engineSound/enginesoundadditionalinfoform2_1584007060.jpg",
                                        "imageUploadTime": {
                                            "sec": 1584026843,
                                            "usec": 0
                                        },
                                        "serverTime": {
                                            "sec": 1584026860,
                                            "usec": 0
                                        }
                                    },
                                    "hashKey": "engineTransmission_engineSound_additionalInfo_form_1"
                                }
                            ]
                        },
                        "exhaustSmoke": {
                            "title": "Exhaust Smoke",
                            "value": "No",
                            "additionalInfo": [
                                {
                                    "image": {
                                        "title": "Exhaust_smoke_Additional_Info_Form_1",
                                        "url": "inspection/2020/03/12/RR55RQ6/v0/engineTransmission/additionalInfo/exhaustSmoke/exhaustsmokeadditionalinfoform1_1584006903.jpg",
                                        "hashKey": "engineTransmission_exhaustSmoke_additionalInfo_form_0",
                                        "pointerCount": 0,
                                        "isTilted": 0,
                                        "imageUploadTime": {
                                            "sec": 1584026522,
                                            "usec": 0
                                        },
                                        "serverTime": {
                                            "sec": 1584026703,
                                            "usec": 0
                                        },
                                        "rating": "Yes"
                                    },
                                    "hashKey": "engineTransmission_exhaustSmoke_additionalInfo_form_0",
                                    "currentCondition": {
                                        "title": "Current Condition",
                                        "value": [
                                            "Silencer assembly damaged",
                                            "Noise from silencer assembly"
                                        ]
                                    }
                                }
                            ]
                        },
                        "rating": {
                            "title": "Rating",
                            "value": 3
                        },
                        "errorsCount": 0,
                        "reinspDiff": {
                            "engineBlowByBackCompression": {
                                "title": "Engine Permissible Blow by (Back Compression)",
                                "value": "Yes",
                                "additionalInfo": [
                                    {
                                        "hashKey": "engineTransmission_engineBlowByBackCompression_additionalInfo_form_0",
                                        "currentCondition": {
                                            "title": "Current Condition",
                                            "value": [
                                                "NO Blow-by"
                                            ]
                                        },
                                        "isValidate": 3
                                    }
                                ],
                                "isValidate": 3
                            }
                        }
                    },
                    "steeringSuspensionBrakes": {
                        "brakes": {
                            "title": "Brake",
                            "value": "Yes"
                        },
                        "steering": {
                            "additionalInfo": [
                                {
                                    "hashKey": "steeringSuspensionBrakes_steering_additionalInfo_form_0",
                                    "currentCondition": {
                                        "title": "Current Condition",
                                        "value": [
                                            "Abnormal Noise"
                                        ]
                                    }
                                }
                            ],
                            "title": "Steering",
                            "value": "No"
                        },
                        "suspension": {
                            "title": "Suspension",
                            "value": "No",
                            "additionalInfo": [
                                {
                                    "hashKey": "steeringSuspensionBrakes_suspension_additionalInfo_form_0",
                                    "currentCondition": {
                                        "title": "Current Condition",
                                        "value": [
                                            "Weak",
                                            "Abnormal Noise"
                                        ]
                                    }
                                }
                            ]
                        },
                        "errorsCount": 0,
                        "estimatedRFC": {
                            "title": "Estimated RFC",
                            "value": 0
                        },
                        "highlightPositives": {
                            "title": "Highlight Positive",
                            "value": [

                            ]
                        },
                        "rating": {
                            "title": "Rating",
                            "value": 3
                        },
                        "comments": {
                            "title": "Comments",
                            "value": [

                            ]
                        },
                        "reinspDiff": {
                            "suspension": {
                                "title": "Suspension",
                                "value": "No",
                                "additionalInfo": [
                                    {
                                        "hashKey": "steeringSuspensionBrakes_suspension_additionalInfo_form_0",
                                        "currentCondition": {
                                            "title": "Current Condition",
                                            "value": [
                                                "Abnormal Noise"
                                            ]
                                        },
                                        "isValidate": 3
                                    }
                                ],
                                "isValidate": 3
                            }
                        }
                    },
                    "airConditioning": {
                        "acCooling": {
                            "additionalInfo": [
                                {
                                    "hashKey": "airConditioning_acCooling_additionalInfo_form_0",
                                    "currentCondition": {
                                        "title": "Current Condition",
                                        "value": [
                                            "Not Working"
                                        ]
                                    }
                                }
                            ],
                            "title": "AC Cooling",
                            "value": "No"
                        },
                        "climateControlAc": {
                            "title": "Climate Control AC",
                            "value": "N/A"
                        },
                        "heater": {
                            "additionalInfo": [
                                {
                                    "hashKey": "airConditioning_heater_additionalInfo_form_0",
                                    "currentCondition": {
                                        "title": "Current Condition",
                                        "value": [
                                            "Not Working"
                                        ]
                                    }
                                }
                            ],
                            "title": "Heater",
                            "value": "No"
                        },
                        "comments": {
                            "title": "Comments",
                            "value": [
                                {
                                    "comment": "Blower Motor noise",
                                    "id": 31,
                                    "rank": 0,
                                    "type": "Comments"
                                }
                            ]
                        },
                        "errorsCount": 0,
                        "estimatedRFC": {
                            "title": "Estimated RFC",
                            "value": 0
                        },
                        "highlightPositives": {
                            "title": "Highlight Positive",
                            "value": [

                            ]
                        },
                        "rating": {
                            "title": "Rating",
                            "value": 3
                        }
                    },
                    "summary": {
                        "errorsCount": 0,
                        "estimatedRFC": {
                            "title": "Estimated RFC",
                            "value": 0
                        },
                        "highlightPositives": {
                            "title": "Highlight Positive",
                            "value": [

                            ]
                        },
                        "comments": {
                            "title": "Comments",
                            "value": [

                            ]
                        }
                    }
                },
                "make": "MARUTI SUZUKI",
                "model": "WAGON R 1.0",
                "variantSeller": "LXI BS III",
                "variantIdSeller": 2396,
                "variantYearIdSeller": 22276,
                "variant": "LXI CNG",
                "city": "Mumbai",
                "inspectionAt": "Store",
                "storeName": "Andheri East",
                "pdfUrl": "",
                "version": 2,
                "releaseVersion": "v2",
                "category": "Re-inspection",
                "carId": 1177152,
                "carCode": "RR55RQ6",
                "endTime": {
                    "sec": 1591546314,
                    "usec": 0
                },
                "resourcePath": "2020/03/12/",
                "appDetails": [
                    {
                        "deviceName": "SM-J600G",
                        "inspectionStatus": "smPending",
                        "osVer": "9",
                        "role": "store manager",
                        "uniqueID": "81f8a0bf-66c3-4921-b6be-a1ae8b523276",
                        "version": "2.4.117"
                    },
                    {
                        "deviceName": "SM-J600G",
                        "inspectionStatus": "smReview",
                        "osVer": "9",
                        "role": "store manager",
                        "uniqueID": "81f8a0bf-66c3-4921-b6be-a1ae8b523276",
                        "version": "2.4.117"
                    },
                    {
                        "deviceName": "LLD-AL10",
                        "inspectionStatus": "smPending",
                        "osVer": "9",
                        "role": "store manager",
                        "uniqueID": "65b4007d-cdce-4ea6-9f7b-e4ea326ebb85",
                        "version": "2.4.121"
                    },
                    {
                        "deviceName": "LLD-AL10",
                        "inspectionStatus": "smReview",
                        "osVer": "9",
                        "role": "store manager",
                        "uniqueID": "65b4007d-cdce-4ea6-9f7b-e4ea326ebb85",
                        "version": "2.4.121"
                    },
                    {
                        "deviceName": "SM-J600G",
                        "inspectionStatus": "smPending",
                        "osVer": "9",
                        "role": "store manager",
                        "uniqueID": "383757be-e2f0-4675-8b3a-74303e021f11",
                        "version": "2.4.145"
                    },
                    {
                        "deviceName": "SM-J600G",
                        "inspectionStatus": "smReview",
                        "osVer": "9",
                        "role": "store manager",
                        "uniqueID": "383757be-e2f0-4675-8b3a-74303e021f11",
                        "version": "2.4.145"
                    }
                ],
                "makeId": 21,
                "modelId": 150,
                "month": "10",
                "registrationState": "Maharashtra",
                "registrationStateId": 14,
                "storeCityId": 2378,
                "storeId": 126,
                "variantId": 895,
                "year": 2011,
                "b2bRegion": "",
                "documentVersion": "2.0.0",
                "inspectionType": "Normal",
                "maxQuotedPrice": 192934,
                "minQuotedPrice": 138912,
                "qcAssign": {
                    "to": "deepak.yadav1@cars24.com",
                    "logs": [
                        {
                            "to": "ankit.kumar3@cars24.com",
                            "from": "ankit.kumar3@cars24.com",
                            "timestamp": "12-03-2020 15:52:43"
                        },
                        {
                            "to": "deepak.yadav1@cars24.com",
                            "from": "system",
                            "timestamp": {
                                "sec": 1584621883,
                                "usec": 0
                            }
                        }
                    ]
                },
                "quotedPrice": 185911,
                "meta": {
                    "order": {
                        "orderState": "bought",
                        "createdAt": {
                            "sec": 1591545524,
                            "usec": 0
                        },
                        "canReInspect": true
                    },
                    "pdfCreatedTime": {
                        "sec": 1584622152,
                        "usec": 0
                    }
                },
                "reInspectionReason": {
                    "reason": "Change due to miss in inspection",
                    "reasonId": 3
                },
                "inspectionBy": "abdul.siddqui@cars24.com",
                "inspectionStartTime": {
                    "sec": 1591545524,
                    "usec": 0
                },
                "inspectionEndTime": {
                    "sec": 1591546314,
                    "usec": 0
                }
            },
            "inspectionEndTime": {
                "sec": 1584621636,
                "usec": 0
            },
            "qcAssignedTo": {
                "qcAssign": {
                    "to": "manmohan.chhokar@cars24.com"
                }
            },
            "regionDepartmentEmail": "kharadieonfreezone.retail@cars24.com"
        }
    };
    return response;
};

export const getCategory = () => {
    let response = [
        {
            "key": "New",
            "value": "New"
        },
        {
            "key": "New(CR)",
            "value": "New(CR)"
        },
        {
            "key": "New(R)",
            "value": "New(R)"
        },
        {
            "key": "Re-inspection",
            "value": "Re-inspection"
        },
        {
            "key": "Re-inspection(CR)",
            "value": "Re-inspection(CR)"
        },
        {
            "key": "Re-inspection(R)",
            "value": "Re-inspection(R)"
        }
    ];
    return response;
};

import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import RcAtDelivery from "./component";
import { resetInspectionDetailByKey } from "../../pages/inspection-review/actions";

const mapStateToProps = ({
    inspectionDetails,
}) => ({
    inspectionDetails,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    resetInspectionDetailByKeyConnect: resetInspectionDetailByKey,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(RcAtDelivery);

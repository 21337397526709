import React, { useState, useEffect} from "react";
import styles from "./car-variant-list-view.module.css";
import SelectComponent from "../../components/shared/select";
import RadioButton from "../../components/shared/radio-button";
import CarVariantListHeader from "../../components/car-variant-list-header";
import Button from "../../components/shared/button";
import { InspectionService } from '../../service';
import { convertKeyIntoString } from '../../utils/helper';
import Loader from '../../components/loader';
import Popup from "../../components/popup";
import {toast, ToastContainer} from 'react-toastify';

const CarVariantListView = ({
    carVariantReducer, 
    fetchManufacturingYears,
    fetchCarModelList,
    fetchCarMakeList,
    history,
    // location: {state : currentVariantData}
}) => {  
    const [isLoading, setIsLoading] = useState(false);
    const [isSequenceLoading, setIsSequenceLoading] = useState(false);
    const [variantStatus, setVariantStatus] = useState(null);
    const [isOpenSequencePopup, setIsOpenSequencePopup] = useState(false);
    const [sequenceParameter, setSequenceParameter] = useState([]);
    const [errorMessage, setErrorMessage] = useState({});
    const [sequenceParameterObject, setSequenceParameterObject] = useState({});
    const [fuelTypeValue, setFuelTypeValue] = useState('');
    const [carMake, setCarMake] = useState('');
    const [variantData, setVariantData] = useState({});
    const [variantListData, setVariantListData] = useState(null);
    const [missingVariants, setMissingVariants] = useState('');
    const [carModelIdValue, setCarModelIdValue] = useState('');
    const [modelData, setModelData] = useState('');
    const {carModelsList, carManufacturingYears, carMakeList } = carVariantReducer;
    const [selectedYears, setSelectedYears] = useState([]);
    const [errorList, setErrorList] = useState([]);
    const [confirmFlag, setConfirmFlag] = useState(false);
    const yearsList = Object.keys(carManufacturingYears);

    let carManufacturingYearsList = yearsList.length ? yearsList.map(year => ({label: year, value: year})) : [];
    const fuelTypes = [];
    (selectedYears || []).forEach(item => {
        const itemList = carManufacturingYears[item.value];
        if(itemList && itemList.length) {
            itemList.forEach(yearItem => {
                fuelTypes.push(yearItem.fuel_type);
            });
        }
    });
    const fuelTypeList = [...new Set(fuelTypes)];
    
    useEffect(() => {
        fetchCarMakeList();
    }, [fetchCarMakeList]);

    const onChangeHandlerCarMake = (makeId, makeName) => {
        setCarMake({label: makeName, value: makeId});
        fetchCarModelList(makeId);
        updateErrorList('make');
        setSelectedYears([]);
        setCarModelIdValue('');
        setModelData('');
        setFuelTypeValue('');
    };

    const onChangeHandlerCarModel = (modelId, modelName) => {
        if(carModelIdValue === modelId) {
            return;
        }
        setSelectedYears([]);
        setCarModelIdValue(modelId);
        fetchManufacturingYears(modelId);
        updateErrorList('model');
        setModelData({label: modelName, value: modelId});
        setFuelTypeValue('');
    };

    const updateErrorList = (field) => {
        const newList = errorList.filter(error => error !== field);
        setErrorList(newList);
    };

    const onChangeManufacturingYear = (yearList) => {
        const selectedVariantListData = [];
        setFuelTypeValue('');
        let yearsValue = [];
        updateErrorList('years');
        yearList && yearList.map(year => {
            selectedVariantListData.push(...carManufacturingYears[year.value]);
            yearsValue.push(year.value);
            
        });
        setSelectedYears(yearList);
        let selectedVariantList = selectedVariantListData.map(variantItem => ({
            label: variantItem.variant_name, 
            value: variantItem.variant_id
        }));
        selectedVariantList =  selectedVariantList.filter((v,i,a)=>a.findIndex(t=>(t.value === v.value))===i);
    };

    const onClickCallback = (value) => {
        updateErrorList('fuelType');
        setFuelTypeValue(value);
    };
    
    const addVariantBtnHandler = () => {
        history.push('/add-car-variant');
    };

    const getYears = () => {
        return selectedYears.map(yearItem => yearItem.value).join(',');
    };

    const fetchSequenceData = () => {
        setIsSequenceLoading(true);
        const requestParam = {
            modelId:carModelIdValue,
            years: getYears(),
            fuelType: fuelTypeValue
        };
        InspectionService.getCarVariantSequence(requestParam).then(response => {
            const { data: {detail: {parameters}}} = response;
            setIsSequenceLoading(false);
            setIsOpenSequencePopup(true);
            if(parameters && !parameters.length) {
                toast.error('No parameter found');
            } else {
                setSequenceParameter(parameters);
                const seqObject = {};
                parameters.forEach((item, index) => {
                    seqObject[item] = index + 1;
                });
                setSequenceParameterObject(seqObject);
            }
        }).catch(error => {
            setIsSequenceLoading(false);
            console.log('Error::', error);
            toast.error(error.message);
        });
    };

    const isValidRequestParam = (requestParam) => {
        const errorArray = [];
        if(!carMake.value) {
            errorArray.push('make');
        }
        if(!requestParam.modelId) {
            errorArray.push('model');
        }
        if(!requestParam.years) {
            errorArray.push('years');
        }
        if(!requestParam.fuelType) {
            errorArray.push('fuelType');
        }
        setErrorList(errorArray);
        return !errorArray.length;
    };

    const onSubmitDetailHandler = (isFetching = true) => {
        const requestParam = {
            modelId:carModelIdValue,
            years: getYears(),
            fuelType: fuelTypeValue
        };

        if(isValidRequestParam(requestParam)) {
            setIsLoading(isFetching);
            InspectionService.getCarVariantSuggestionData(requestParam).then(response => {
                const { data: {detail: {variantList, missingVariants, status}}} = response;
                setVariantListData(variantList);
                setMissingVariants(missingVariants);
                setVariantStatus(status);
                setIsLoading(false);
            }).catch(error => {
                setIsLoading(false);
                console.log('error::', error);
                toast.error(error.detail);
            });
        }
        
    };

    const displayParameters = (parameter) => {
        const allParameters = [];
        for(let key in parameter) {
            if(parameter[key] && !(key in {variant: "variant", variantId: "variantId", srNo: 'srNo', model_id: 'model_id'})) {
                allParameters.push(
                    <span>
                        {parameter[key].split(':')[0] + ' ' + convertKeyIntoString(key, ' ')}
                    </span>
                );
            }
        }
        return allParameters;
    };

    const confirmFlagHandler = (variant) => {
        setConfirmFlag(true);
        setVariantData(variant);
    };

    const onCloseHandler = () => setConfirmFlag(false);

    const deleteVaraintHandler = () => {
        setConfirmFlag(false);
        if(variantData.srNo) {
            InspectionService.deleteVariantSuggestionData(variantData.srNo).then(response => {
                const { data: {detail}} = response;
                onSubmitDetailHandler(false);
                toast.success(detail);
            }).catch(error => {
                console.log(error);
                toast.error(error.detail);
            });
        }
        
    };
    
    const closeSequencePopup = () => {
        setIsOpenSequencePopup(false);
    };

    const editVariantHandler = (item) => {
        const data = {
            carMake,
            modelData,
            selectedYears,
            editVariantData : item,
            fuelType: fuelTypeValue
        };
        history.push({pathname:'/add-car-variant', state: data});
    };

    const clearValidation = parameterName => {
        const errorObj = {...errorMessage};
        setTimeout(() => {
            delete errorObj[parameterName];
            setErrorMessage(errorObj);
        }, 1500);
    };

    const parameterHandleChange = (value, parameterName) => {
        if(value === sequenceParameterObject[parameterName]) {
            return;
        }
        const sequenceValues = Object.values(sequenceParameterObject);
        if(!sequenceValues.includes(value)) {
            const errorObj = {...errorMessage};
            delete errorObj[parameterName];
            setErrorMessage(errorObj);
            setSequenceParameterObject({...sequenceParameterObject, [parameterName]: value});
        } else {
            !value && setSequenceParameterObject({...sequenceParameterObject, [parameterName]: value});
            value && setErrorMessage({...errorMessage, [parameterName]: `${value} is already added`});
            clearValidation(parameterName);
        }
        
    };

    const isValidSequenceData = () => {
        const errorMessageObj = {...errorMessage};
        for(let key in sequenceParameterObject) {
            if(!sequenceParameterObject[key]) {
                errorMessageObj[key] = "Required";
            }
        }
        setErrorMessage(errorMessageObj);
        return !Object.keys(errorMessageObj).length;
    };

    const onSubmitSequenceHandler = () => {
        
        if(isValidSequenceData()) {
            const requestParam = {
                make_id: carMake.value,
                make_name: carMake.label,
                model_id: carModelIdValue,
                model_name: modelData.label,
                fuelType: fuelTypeValue,
                manufacturingYears: getYears(),
                displayFields: sequenceParameterObject,
            };
            InspectionService.submitCarVariantSequence(requestParam).then(response => {
                const {data:{detail}} = response;
                toast.success(detail);
                setIsOpenSequencePopup(false);
            }).catch(error => {
                console.log('Error::',error.detail);
                toast.error(error.detail);
            });
        }
    };

    const enableDisableHandler = () => {
        const status = variantStatus === "Active" ? "Pending" : "Active";
        const requestParam = {
            model_id: carModelIdValue,
            manufacturingYears: getYears(),
            fuelType: fuelTypeValue,
            status: status
        };
        InspectionService.enableCarVariantSuggestion(requestParam).then(response => {
            const {data:{detail}} = response;
            toast.success(detail);
            onSubmitDetailHandler();
        }).catch(error => {
            console.log('Error::',error.detail);
            toast.error(error.detail);
        });
    };

    const renderEnableDisableButton = () => {
        const status = variantStatus === "Active" ? "Disable" : "Enable"; 
        const ctaText = status+ " For Inspection App";
        return (
            <div className={styles.enableDisableBtn}>
                <Button  ctaText={ctaText} onClick={enableDisableHandler} />
            </div>
        );
    };

    const dropdownList = Array.from({length: Object.keys(sequenceParameterObject).length}, (_, i) => ({label: i + 1, value: i + 1}));

    return (
        <div className={styles.mainListViewContainer}>
            <CarVariantListHeader history={history} />
            {isSequenceLoading && <Loader />}
            <div className={styles.containerWrapper}>
                <div className={styles.variantTopModle}>
                    <ul>
                        <li>
                            <p>Make</p>
                            <SelectComponent 
                                optionsList={carMakeList} 
                                onChange={onChangeHandlerCarMake} 
                                placeholder="Select Make"
                                value={carMake}
                            />
                            {errorList.includes('make') &&
                            <span className={styles.errorMessage}> Select Make</span>}
                        </li>
                        <li>
                            <p>Model</p>
                            <SelectComponent 
                                optionsList={carModelsList} 
                                onChange={onChangeHandlerCarModel} 
                                placeholder="Select Model"
                                value={modelData}
                            />
                            {errorList.includes('model') &&
                            <span className={styles.errorMessage}> Select Model</span>}
                        </li>
                        <li>
                            <p>Manufacturing Year</p>
                            <SelectComponent 
                                isMulti 
                                optionsList={carManufacturingYearsList} 
                                onChange={onChangeManufacturingYear} 
                                value={selectedYears}
                                isClearable
                                placeholder="Select Years"
                            />
                            {errorList.includes('years') && 
                            <span className={styles.errorMessage}> Select Years</span>}
                        </li>
                        <li>
                            <p>Fuel Type</p>
                            <div className={styles.variantBox}>
                                {fuelTypeList.map((fuelType, index) => (
                                    <div className={styles.variant}>
                                        <RadioButton 
                                            key={index}
                                            text={fuelType} 
                                            onClickCallback={onClickCallback} 
                                            name={fuelType}
                                            value={fuelType}
                                            id={fuelType} 
                                            checkedStatus={fuelType === fuelTypeValue}
                                        />
                                    </div>))}
                                {errorList.includes('fuelType') && 
                                <span className={styles.errorMessage}> Select fuel Type</span>}
                            </div>
                        </li>
                    </ul>
                </div>
                <div className={styles.addVariantBtn}>
                    <Button onClick={addVariantBtnHandler} ctaText="Add Variant" />
                </div>
                <div className={styles.submitBtn}>
                    <Button disabled={isLoading || isSequenceLoading} onClick={onSubmitDetailHandler} ctaText="Submit" />
                </div>
            </div>
            {isLoading ? <Loader /> : (
                <div className={styles.variantListWrapper}>
                    {!!variantListData && variantListData.length > 0 && (
                        <div className={styles.missingVariantsContainer}>
                            {
                                !!missingVariants ? (
                                    <div className={styles.missingVariantsWrapper}>
                                        <h3>Variant data missing for:</h3>
                                        <p>{missingVariants}</p>
                                    </div>
                                ) : (
                                    <div className={styles.missingVariantsBtn}>
                                        <Button 
                                            ctaText="Set Question Sequence"
                                            onClick={fetchSequenceData} 
                                            disabled={isSequenceLoading}
                                        />
                                    </div>
                                )
                            }
                        </div>
                    )}
                    {!!variantListData && missingVariants === "" && renderEnableDisableButton()}
                    <ul>
                        {!!variantListData && variantListData.length > 0 ? variantListData.map((parameterItem, index) => (
                            <li key={index}>
                                <h4 className={styles.variantName}>{parameterItem.variant}</h4> 
                                <div className={styles.variantList}>
                                    {displayParameters(parameterItem)}
                                </div>
                                <div className={styles.actionBtnWrapper}>
                                    <Button onClick={() => editVariantHandler(parameterItem)} ctaText="Edit" />
                                    <Button 
                                        className={styles.deleteBtn} 
                                        ctaText="Delete" 
                                        onClick={() => confirmFlagHandler(parameterItem)}
                                    />
                                </div>
                            </li>
                        ))
                            : <span>No data found</span>}
                    </ul>
                </div>
            )}
            <Popup isOpen={confirmFlag}>
                <div className={styles.confirmContainer}>
                    <p>Are you sure you want to delete variant data for <span className={styles.variantName}>{variantData.variant}</span></p>
                    <div className={styles.confirmBtnWrapper}>
                        <Button ctaText="No" onClick={onCloseHandler} />
                        <Button onClick={deleteVaraintHandler} className={styles.deleteVariant} ctaText="Yes" />
                    </div>
                </div>
            </Popup>
            <Popup isOpen={isOpenSequencePopup} close={closeSequencePopup}>
                <table cellPadding="0" cellSpacing="0" className={styles.parameterTable}>
                    <thead>
                        <tr>
                            <th>Parameter Name</th>
                            <th>Sequence Number</th>
                        </tr>
                    </thead>
                    <tbody>
                        {sequenceParameter.length ? sequenceParameter.map((paramName, index) => (
                            <tr className={styles.parameterWrapper} key={index}>
                                <td>{paramName}</td>
                                <td>
                                    <SelectComponent 
                                        optionsList={dropdownList}
                                        onChange={(e) => parameterHandleChange(e, paramName)}
                                        value={{value: sequenceParameterObject[paramName], label: sequenceParameterObject[paramName]}}
                                        isClearable
                                        isSearchable
                                    />
                                    {errorMessage[paramName] && <span className={styles.errorMessage}>{errorMessage[paramName]}</span>}
                                </td>
                            </tr>
                        )): <span>No Parameter Found</span>}
                    </tbody>
                </table>
                <div className={styles.ctaWrapper}>
                    <Button ctaText="Submit Sequence" classNames="goBackCta" onClick={onSubmitSequenceHandler} />
                </div>
                {/* <Button ctaText="Cancel" onClick={closeSequencePopup} /> */}
            </Popup>
            <ToastContainer />
        </div>
    );
};

export default CarVariantListView;
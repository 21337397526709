import React, {useState} from "react";
import { useDispatch } from "react-redux";
import { INSPECTION_SECTION, RESET_REDUCER_KEYS } from "../../utils/app-constants";
import styles from "../inspection-insurance/inspection-insurance.module.css";
import Checkbox from "../shared/checkbox/";
import { getEditableComponent } from "../../utils/helper";
import Remark from "../shared/remark/Remark";

const InspectionOtherDetails = ({
    document,
    resetInspectionDetailByKeyConnect,
    inspectionDetails
}) => {
    const dispatch = useDispatch();
    const [isRemarkHistoryOpen, setIsRemarkHistoryOpen] = useState(false);
    const { text, checked, value, key, newValue, isEditable } = document;
    const { otherDetailsMapper } = inspectionDetails;
    const onCheckboxHandler = (key) => {
        let mapper = [...otherDetailsMapper];
        let dependentFields = [];
        let currentState;
        const index = mapper.findIndex(item=>item.key === key);
        mapper[index].checked = !mapper[index].checked;
        if(mapper[index].dependentFields){
            dependentFields = mapper[index].dependentFields;
        }

        mapper = mapper.map(map => {
            let element = { ...map };
            if (mapper.find(item => item.key === key).checked) {
                if (dependentFields.length > 0 && (dependentFields.find(field => field === element.key))) {
                    if (!currentState) {
                        element.checked = true;
                    }
                    element.hideRemarkInput = element.checked;
                } else {
                    element.hideRemarkInput = false;
                }
            }

            return element;
        });

        resetInspectionDetailByKeyConnect(RESET_REDUCER_KEYS.OTHER_DETAILS_MAPPER, mapper);
        setIsRemarkHistoryOpen(!checked);

    };

    return (
        <>
        <div className={styles.documentInfo}>
            <div className={styles.checkboxlabel}>
                {(key !== "ra" && key !== "cj") && <Checkbox
                    checkedStatus={checked}
                    onChangeCallback={() => onCheckboxHandler(key)}
                    name={`otherdetail_${key}`}
                    id={`otherdetail_${key}`}
                />
                }
                <label className={styles.lableText}>{text}</label>
            </div>
            <div className={styles.dataWrapper} style={{ display: "flex", alignItems: "center", justifyContent: "space-between", flexWrap: "wrap" }}>
                {
                    checked && isEditable ? getEditableComponent(key, document, resetInspectionDetailByKeyConnect) : newValue ? newValue : value
                }
                {
                    newValue ? <div className={styles.newValue}>{`changed from ${value}`}</div> : ""
                }

                {!!document?.remarkHistory?.length && (
                    <span
                        className={"remarkHistoryBtn"}
                        onClick={() => setIsRemarkHistoryOpen(!isRemarkHistoryOpen)}
                    >
                        Remark History
                    </span>
                )}

            </div>
        </div>

        <Remark
            document={document}
            section={INSPECTION_SECTION.CAR_DETAILS}
            showRemarkInput={document.checked && !document.hideRemarkInput}
            isRemarkHistoryOpen={isRemarkHistoryOpen}
        />
        </>
    );
};

export default InspectionOtherDetails;

import { createReducer } from "reduxsauce";
import Types from "./types";
import { removeDuplicates, getUnique } from "../../utils/helper";
import { LOCAL_STORAGE_KEYS } from '../../utils/app-constants';
import { saveToLocalStorage } from '../../utils/helper';

export const INITIAL_STATE = {
    isLoading: true,
    columns: [],
    page: 0,
    data: [],
    error: null,
    total: 0,
    initialCall: true

};

export const fetchInspections = (state = INITIAL_STATE) => {
    return { ...state, isLoading: true };
};

export const resetInspections = () => {
    return INITIAL_STATE;
};

export const fetchInspectionSuccess = (state = INITIAL_STATE, { data }) => {
    // data: [...state.data, ...result]
    const { detail, totalRecords, videoHostUrl } = data;
    let rowData = [...state.data, ...detail];
    // rowData = removeDuplicates(rowData, "appointmentId");
    rowData = getUnique(rowData, "appointmentId");

    return { ...state, data: rowData, total: totalRecords, isLoading: false };
};

export const fetchAInspectionFailure = (state = INITIAL_STATE, { error }) => {
    return { ...state, error, data: [], isLoading: false };
};
export const resetInspectionByKey = (state = INITIAL_STATE, { data }) => {
    const { key, value } = data;
    return { ...state, [key]: value };
};
export const HANDLERS = {
    [Types.FETCH_INSPECTIONS_LIST]: fetchInspections,
    [Types.RESET_INSPECTIONS_LIST]: resetInspections,
    [Types.FETCH_INSPECTIONS_LIST_SUCCESS]: fetchInspectionSuccess,
    [Types.FETCH_INSPECTIONS_LIST_FAILURE]: fetchAInspectionFailure,
    [Types.RESET_INSPECTION_BY_KEY]: resetInspectionByKey

};

export default createReducer(INITIAL_STATE, HANDLERS);

import React, { useEffect, useState, useRef } from "react";
import styles from "./bike-report.module.css";
import { InspectionService } from "../../service";
import { getInnerKeyDetailsOfObject, isAdditionalImagesExist } from "../../utils/arrayHelper";
import { thumborUrl} from "../../utils/url-constants";
import { LOCAL_STORAGE_KEYS, BIKE_DETAIL_KEYS, BIKE_REPORT_CATEGORY } from "../../utils/app-constants";
import { getFromLocalStorage, formatDateForBike } from "../../utils/helper";
import { timeConverter } from "../../utils/dateHelper";

const BikeReport = ({
    match,
}) => {
    const cdnBaseUrl = !!getFromLocalStorage(LOCAL_STORAGE_KEYS.IMAGE_HOST_URL) ? getFromLocalStorage(LOCAL_STORAGE_KEYS.IMAGE_HOST_URL) : thumborUrl();
    const { params: { appointmentId } } = match;
    const urlParams = new URLSearchParams(window.location.search);
    const bikeVersion = urlParams.get('version');
    const [data, setData ] = useState({});
    const [isNewVersion, setIsNewVersion] = useState(false);

    useEffect(() => {
        if(bikeVersion) {
            InspectionService.getBikeReport(appointmentId, bikeVersion)
                .then(res => {
                    if(res.data) {
                        setData(res.data);
                        const {uiVersion} = res.data.inspectionData || {};
                        !!uiVersion && setIsNewVersion(uiVersion === 2);
                    }        
                })
                .catch(e => {
                    console.log("exception", e);
                // alert("No data found!.");
                // window.close();    
                });
        } else {
            InspectionService.getBikeReportWithoutVersion(appointmentId)
                .then(res => {
                    if(res.data) {
                        setData(res.data);
                        const {uiVersion} = res.data.inspectionData || {};
                        !!uiVersion && setIsNewVersion(uiVersion === 2);
                    }        
                })
                .catch(e => {
                    console.log("exception", e);
                });
        }
               
    }, [appointmentId, bikeVersion]);

    const showItem = (category, label, value) => {
        if(!value) {
            return null;
        }
        let item = <><td>{label}</td> <td>{Array.isArray(value) ? value.join(', ') : value}</td></>;
        if (category === BIKE_REPORT_CATEGORY.NEW_VERSION && isNewVersion) {
            return item;
        } else if (category === BIKE_REPORT_CATEGORY.OLD_VERSION && !isNewVersion){
            return item;
        } else if (category === BIKE_REPORT_CATEGORY.BOTH_VERSION){
            return item;
        }
    };

    const renderCjRemarks = () => {
        const cjRemark = data && data.inspectionData && data.inspectionData.cjRemark && data.inspectionData.cjRemark.split('\\n');
        if(cjRemark && cjRemark.length > 1) {
            return cjRemark.map(cjItem => (
                <span className={styles.listRow}>{cjItem}</span>
            ));
        } 
        return cjRemark;
    };

    const frontImageUri = data.images &&  getInnerKeyDetailsOfObject(data.images, "front");
    const rearImageUri = data.images &&  getInnerKeyDetailsOfObject(data.images, "rear");
    const rightSideImageUri = data.images &&  getInnerKeyDetailsOfObject(data.images, "rightSide");
    const leftSideImageUri = data.images &&  getInnerKeyDetailsOfObject(data.images, "leftSide");
    const tyreFrontImageUri = data.images &&  getInnerKeyDetailsOfObject(data.images, "tyreFront");
    const tyreRearImageUri = data.images &&  getInnerKeyDetailsOfObject(data.images, "tyreRear");
    const odometerImageUri = data.images &&  getInnerKeyDetailsOfObject(data.images, "odometer");
    const topImageUri = data.images &&  getInnerKeyDetailsOfObject(data.images, "top");
    const keysImageUri = data.images &&  getInnerKeyDetailsOfObject(data.images, "keys");
    
    return (
        <table className={styles.taleData} cellSpacing="0" cellPadding="0" border="0">
            <tbody>
                <tr>
                    <td>
                        <table className={styles.tableTitle} cellSpacing="0" cellPadding="0" border="0">
                            <tbody>
                                <tr>
                                    <td>
                                        <img src="https://bikes-media.24c.in/cars24-logo.jpg" width="85px" height="40px" alt="CARS24" />
                                    </td>
                                    <td>
                                        <p>INSPECTION REPORT<br/>{data.appointmentId}</p>
                                    </td>
                                    <td>
                                        <p>{timeConverter(data.inspectionEndTime)}</p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
            </tbody>
            {renderCjRemarks() && (
                <div className={styles.cjRemark}>
                    <h3>CJ Remarks</h3>
                    <div>{renderCjRemarks()}</div>
                </div>
            )}
            <tbody>
                <tr>
                    <td>
                        {/*  General Details */}
                        <table className={styles.title} cellPadding="0" cellSpacing="0">
                            <tbody>
                                <tr>
                                    <td colSpan="4">Bike Details</td>
                                </tr>
                            </tbody>
                        </table>
                        <table className={styles.data1} cellPadding="0" cellSpacing="0">
                            <tbody>
                                <tr>
                                    {data  && showItem(BIKE_REPORT_CATEGORY.OLD_VERSION, "Appointment ID", data.appointmentId)}
                                    {data  && showItem(BIKE_REPORT_CATEGORY.OLD_VERSION, "Registration No.", data.regNo)}
                                </tr>
                                <tr>
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.NEW_VERSION, BIKE_DETAIL_KEYS.Inspection_Type, data.inspectionData[BIKE_DETAIL_KEYS.Inspection_Type])}
                                    {data.inspectionData && !!data.inspectionData[BIKE_DETAIL_KEYS.Inspection_Type] && data.inspectionData[BIKE_DETAIL_KEYS.Inspection_Type].toLowerCase() === "b2b" && showItem(BIKE_REPORT_CATEGORY.NEW_VERSION, BIKE_DETAIL_KEYS.Dealership_Name, data.inspectionData[BIKE_DETAIL_KEYS.Dealership_Name])}
                                </tr>
                                <tr>
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.NEW_VERSION, BIKE_DETAIL_KEYS.Customer_Name_on_RC, data.inspectionData[BIKE_DETAIL_KEYS.Customer_Name_on_RC])}
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.NEW_VERSION, BIKE_DETAIL_KEYS.Registration_Number, data.inspectionData[BIKE_DETAIL_KEYS.Registration_Number])}
                                </tr>
                                <tr>
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.NEW_VERSION, BIKE_DETAIL_KEYS.make, data.make)}
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.NEW_VERSION, BIKE_DETAIL_KEYS.model, data.model)}
                                </tr>
                                <tr>
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.NEW_VERSION, BIKE_DETAIL_KEYS.variant, data.variant)}
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.NEW_VERSION, BIKE_DETAIL_KEYS.Inspection_Store, data.inspectionData[BIKE_DETAIL_KEYS.Inspection_Store])}
                                </tr>
                                <tr>
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.NEW_VERSION, BIKE_DETAIL_KEYS.RA_Name, data.inspectionData[BIKE_DETAIL_KEYS.RA_Name])}
                                    {/* {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.NEW_VERSION, BIKE_DETAIL_KEYS.Manufacturing_Date, formatDateForBike(data.inspectionData[BIKE_DETAIL_KEYS.Manufacturing_Date]))} */}
                                </tr>
                                <tr>
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.NEW_VERSION, BIKE_DETAIL_KEYS.Ownership_Number, data.inspectionData[BIKE_DETAIL_KEYS.Ownership_Number])}
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.NEW_VERSION, "Kilometres Driven", data.inspectionData["Kilometres driven"])}
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.NEW_VERSION, "Odometer Reading", data.inspectionData["Odometer Reading"])}
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.OLD_VERSION, "Odometer", data.inspectionData["Kilometres driven"])}  
                                </tr>
                                <tr>
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.OLD_VERSION, BIKE_DETAIL_KEYS.RTO_Code, data.inspectionData[BIKE_DETAIL_KEYS.RTO_Code])}
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.OLD_VERSION, "Model/Variant", data.model)}
                                </tr>
                                <tr>
                                    {/* {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.OLD_VERSION, "Mfg. Month", data.inspectionData["Manufacturing Date"])} */}
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.OLD_VERSION, "Ownership Number", data.inspectionData["Ownership Number"])}
                                </tr>
                                <tr>
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.OLD_VERSION, "Insurance Type", data.inspectionData["Insurance Type"])}
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.OLD_VERSION, "Insurance Expiry", data.inspectionData["Insurance Expiry"])}
                                </tr>
                                <tr>
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.BOTH_VERSION, BIKE_DETAIL_KEYS.Hypothecation, data.inspectionData[BIKE_DETAIL_KEYS.Hypothecation])}
                                    {data.inspectionData && !!data.inspectionData[BIKE_DETAIL_KEYS.Hypothecation] && data.inspectionData[BIKE_DETAIL_KEYS.Hypothecation].toLowerCase() === "yes" && showItem(BIKE_REPORT_CATEGORY.OLD_VERSION, "Financier Name", data.inspectionData["financerName"])}
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.BOTH_VERSION, BIKE_DETAIL_KEYS.REGISTRATION_YEAR, data.inspectionData[BIKE_DETAIL_KEYS.REGISTRATION_YEAR])}
                                </tr>
                                {data.inspectionData && !!data.inspectionData[BIKE_DETAIL_KEYS.Hypothecation] && data.inspectionData[BIKE_DETAIL_KEYS.Hypothecation].toLowerCase() === "yes" && <tr>
                                    {showItem(BIKE_REPORT_CATEGORY.NEW_VERSION, BIKE_DETAIL_KEYS.Loan_Paid, data.inspectionData[BIKE_DETAIL_KEYS.Loan_Paid])}
                                    {showItem(BIKE_REPORT_CATEGORY.NEW_VERSION, BIKE_DETAIL_KEYS.Bank_NOC, data.inspectionData[BIKE_DETAIL_KEYS.Bank_NOC])}
                                </tr>}
                                <tr>
                                    {data.inspectionData && showItem(BIKE_REPORT_CATEGORY.NEW_VERSION, BIKE_DETAIL_KEYS.REGISTRATION_CITY_RTO_CODE, data.inspectionData[BIKE_DETAIL_KEYS.REGISTRATION_CITY_RTO_CODE])}
                                </tr>
                                <tr>
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.OLD_VERSION, "RC Availability", data.inspectionData["RC Availability"])}
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.OLD_VERSION, "RC Condition", data.inspectionData["RC Condition"])}
                                </tr>
                                <tr>
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.OLD_VERSION, "Chassis Number on RC", data.inspectionData["Chassis Number"])}
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.OLD_VERSION, "Chassis Number Embossing", data.inspectionData["Chassis Number Embossing"])}
                                </tr>
                                <tr>
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.OLD_VERSION, "Duplicate Key", data.inspectionData["Duplicate Key"])}
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.OLD_VERSION, "Is Scrap?", data.inspectionData["Is Scrap?"])}
                                </tr>
                                <tr>
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.OLD_VERSION, "Vehicle location", data.inspectionData["Vehicle location"])}
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.OLD_VERSION, "Registration Date", data.inspectionData["Registration Date"])}
                                </tr>
                                <tr>
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.NEW_VERSION, "RC Availability", data.inspectionData["RC Availability"])}
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.NEW_VERSION, "Insurance availability", data.inspectionData["Insurance availability"])}                                    
                                </tr>
                                <tr>
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.BOTH_VERSION, BIKE_DETAIL_KEYS.Customer_Mobile, data.inspectionData[BIKE_DETAIL_KEYS.Customer_Mobile])}
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.BOTH_VERSION, BIKE_DETAIL_KEYS.Customer_Name, data.inspectionData[BIKE_DETAIL_KEYS.Customer_Name])}
                                    {/* {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.NEW_VERSION, "Insurance availability", data.inspectionData["Insurance availability"])}                                     */}
                                </tr>
                                <tr>
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.BOTH_VERSION, BIKE_DETAIL_KEYS.Dealer_Name, data.inspectionData[BIKE_DETAIL_KEYS.Dealer_Name])}
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.BOTH_VERSION, BIKE_DETAIL_KEYS.Same_Color_Bike, data.inspectionData[BIKE_DETAIL_KEYS.Same_Color_Bike])}
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
            </tbody>
            {/* -- Exterior Details -- */}
            <tbody>
                <tr>
                    <td>
                        {<table className={styles.title} cellPadding="0" cellSpacing="0">
                            <tbody>
                                <tr>
                                    <td colSpan="4">Exterior</td>
                                </tr>
                            </tbody>
                        </table>}
                        <table className={styles.data1} cellPadding="0" cellSpacing="0">
                            <tbody>
                                <tr>
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.OLD_VERSION, "Chassis Frame / Floor", data.inspectionData["Chassis Frame / Floor"])}
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.OLD_VERSION, "Front Head Light Cover show", data.inspectionData["Front Head Light Cover show"])}
                                </tr>
                                <tr>
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.OLD_VERSION, "Front Show Panel", data.inspectionData["Front Show Panel"])}
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.OLD_VERSION, "Front nose", data.inspectionData["Front nose"])}
                                </tr>
                                <tr>
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.BOTH_VERSION, BIKE_DETAIL_KEYS.Front_Mudguard, data.inspectionData[BIKE_DETAIL_KEYS.Front_Mudguard])}
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.OLD_VERSION, "Rear Mud Guard", data.inspectionData["Rear Mud Guard"])}
                                </tr>
                                <tr>
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.OLD_VERSION, "Front Basket / Dicky", data.inspectionData["Front Basket / Dicky"])}
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.OLD_VERSION, "Side Dicky / Luggage box", data.inspectionData["Side Dicky / Luggage box"])}
                                </tr>
                                <tr>
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.BOTH_VERSION, "Fuel Tank", data.inspectionData["Fuel Tank"])}
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.OLD_VERSION, "Grab Handle Rear", data.inspectionData["Grab Handle Rear"])}
                                </tr>
                                <tr>
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.OLD_VERSION, "Side Panel - LHS", data.inspectionData["Side Panel - LHS"])}
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.OLD_VERSION, "Side Panel - RHS", data.inspectionData["Side Panel - RHS"])}
                                </tr>
                                <tr>
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.OLD_VERSION, "Chassis Frame / Floor", data.inspectionData["Chassis Frame / Floor"])}
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.OLD_VERSION, "Under Seat Space Dicky", data.inspectionData["Under Seat Space Dicky"])}
                                </tr>
                                <tr>
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.OLD_VERSION, "ORVM - LHS", data.inspectionData["ORVM - LHS"])}
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.OLD_VERSION, "ORVM - RHS", data.inspectionData["ORVM - RHS"])}
                                </tr>
                                <tr>
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.OLD_VERSION, "Seats", data.inspectionData["Seats"])}
                                    {!isNewVersion && <td colSpan="2"></td>}
                                </tr>
                                <tr>
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.NEW_VERSION, "No. of Dents on Bike Exterior", data.inspectionData["No. of Dents on Bike Exterior"])}
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.NEW_VERSION, "Is there any damage to Bike Exterior", data.inspectionData["Is there any damage to Bike Exterior"])}
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.NEW_VERSION, "", "")}
                                </tr>
                                <tr>
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.BOTH_VERSION, BIKE_DETAIL_KEYS.Headlight_Visor, data.inspectionData[BIKE_DETAIL_KEYS.Headlight_Visor])}
                                    {/* {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.BOTH_VERSION, BIKE_DETAIL_KEYS.Choke, data.inspectionData[BIKE_DETAIL_KEYS.Choke])} */}
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.BOTH_VERSION, "Silencer", data.inspectionData["Silencer"])}
                                </tr>
                                <tr>
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.BOTH_VERSION, BIKE_DETAIL_KEYS.Seat_Condition, data.inspectionData[BIKE_DETAIL_KEYS.Seat_Condition])}
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.BOTH_VERSION, BIKE_DETAIL_KEYS.Front_Wheel, data.inspectionData[BIKE_DETAIL_KEYS.Front_Wheel])}
                                </tr>
                                <tr>
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.BOTH_VERSION, "Gear Shifting", data.inspectionData["Gear Shifting"])}
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.BOTH_VERSION, BIKE_DETAIL_KEYS.Rear_Wheel, data.inspectionData[BIKE_DETAIL_KEYS.Rear_Wheel])}
                                </tr>
                                <tr>
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.BOTH_VERSION, BIKE_DETAIL_KEYS.Rear_Mudguard, data.inspectionData[BIKE_DETAIL_KEYS.Rear_Mudguard])}
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.BOTH_VERSION, BIKE_DETAIL_KEYS.Meter_Display_Condition, data.inspectionData[BIKE_DETAIL_KEYS.Meter_Display_Condition])}
                                </tr>
                                <tr>
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.BOTH_VERSION, BIKE_DETAIL_KEYS.Saree_Guard, data.inspectionData[BIKE_DETAIL_KEYS.Saree_Guard])}
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.BOTH_VERSION, BIKE_DETAIL_KEYS.Main_Stand, data.inspectionData[BIKE_DETAIL_KEYS.Main_Stand])}
                                </tr>
                                <tr>
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.BOTH_VERSION, BIKE_DETAIL_KEYS.Headlight_Windshield, data.inspectionData[BIKE_DETAIL_KEYS.Headlight_Windshield])}
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.BOTH_VERSION, BIKE_DETAIL_KEYS.Steering_Bar_Handle, data.inspectionData[BIKE_DETAIL_KEYS.Steering_Bar_Handle])}
                                </tr>
                                <tr>
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.BOTH_VERSION, BIKE_DETAIL_KEYS.Tail_Panel, data.inspectionData[BIKE_DETAIL_KEYS.Tail_Panel])}
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.BOTH_VERSION, BIKE_DETAIL_KEYS.Gear_Pedal, data.inspectionData[BIKE_DETAIL_KEYS.Gear_Pedal])}
                                </tr>
                                <tr>
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.BOTH_VERSION, BIKE_DETAIL_KEYS.Brakes_Rear, data.inspectionData[BIKE_DETAIL_KEYS.Brakes_Rear])}
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.BOTH_VERSION, BIKE_DETAIL_KEYS.Front_Side_Panel_RHS, data.inspectionData[BIKE_DETAIL_KEYS.Front_Side_Panel_RHS])}
                                </tr>
                                <tr>
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.BOTH_VERSION, BIKE_DETAIL_KEYS.Front_Side_Panel_LHS, data.inspectionData[BIKE_DETAIL_KEYS.Front_Side_Panel_LHS])}
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.BOTH_VERSION, BIKE_DETAIL_KEYS.Brakes_Front, data.inspectionData[BIKE_DETAIL_KEYS.Brakes_Front])}
                                </tr>
                                <tr>
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.BOTH_VERSION, BIKE_DETAIL_KEYS.Rear_Grip_Handle, data.inspectionData[BIKE_DETAIL_KEYS.Rear_Grip_Handle])}
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.BOTH_VERSION, BIKE_DETAIL_KEYS.Side_Stand, data.inspectionData[BIKE_DETAIL_KEYS.Side_Stand])}
                                </tr>
                                <tr>
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.BOTH_VERSION, BIKE_DETAIL_KEYS.Shock_Abosrber_Rear_LHS, data.inspectionData[BIKE_DETAIL_KEYS.Shock_Abosrber_Rear_LHS])}
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.BOTH_VERSION, BIKE_DETAIL_KEYS.Foot_Rest_Rear_LHS, data.inspectionData[BIKE_DETAIL_KEYS.Foot_Rest_Rear_LHS])}
                                </tr>

                                <tr>
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.BOTH_VERSION, BIKE_DETAIL_KEYS.Shock_Abosrber_Front_RHS, data.inspectionData[BIKE_DETAIL_KEYS.Shock_Abosrber_Front_RHS])}
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.BOTH_VERSION, BIKE_DETAIL_KEYS.Brakes_Lever_Front_RHS, data.inspectionData[BIKE_DETAIL_KEYS.Brakes_Lever_Front_RHS])}
                                </tr>

                                <tr>
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.BOTH_VERSION, BIKE_DETAIL_KEYS.Shock_Abosrber_Front_LHS, data.inspectionData[BIKE_DETAIL_KEYS.Shock_Abosrber_Front_LHS])}
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.BOTH_VERSION, BIKE_DETAIL_KEYS.Shock_Abosrber_Rear_RHS, data.inspectionData[BIKE_DETAIL_KEYS.Shock_Abosrber_Rear_RHS])}
                                </tr>

                                <tr>
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.BOTH_VERSION, BIKE_DETAIL_KEYS.No_Of_Indicators_Working, data.inspectionData[BIKE_DETAIL_KEYS.No_Of_Indicators_Working])}
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.BOTH_VERSION, BIKE_DETAIL_KEYS.Ignition_Lock, data.inspectionData[BIKE_DETAIL_KEYS.Ignition_Lock])}
                                </tr>
                                <tr>
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.BOTH_VERSION, BIKE_DETAIL_KEYS.Handle_Lock, data.inspectionData[BIKE_DETAIL_KEYS.Handle_Lock])}
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.BOTH_VERSION, BIKE_DETAIL_KEYS.Panel_Lock, data.inspectionData[BIKE_DETAIL_KEYS.Panel_Lock])}
                                </tr>

                                <tr>
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.BOTH_VERSION, BIKE_DETAIL_KEYS.Foot_Rest_Rear_RHS, data.inspectionData[BIKE_DETAIL_KEYS.Foot_Rest_Rear_RHS])}
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.BOTH_VERSION, BIKE_DETAIL_KEYS.Foot_Rest_Front_RHS, data.inspectionData[BIKE_DETAIL_KEYS.Foot_Rest_Front_RHS])}
                                </tr>
                                
                                <tr>
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.BOTH_VERSION, BIKE_DETAIL_KEYS.Foot_Rest_Front_LHS, data.inspectionData[BIKE_DETAIL_KEYS.Foot_Rest_Front_LHS])}
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.BOTH_VERSION, BIKE_DETAIL_KEYS.Rear_Brake_Pedal, data.inspectionData[BIKE_DETAIL_KEYS.Rear_Brake_Pedal])}
                                </tr>

                                <tr>
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.BOTH_VERSION, BIKE_DETAIL_KEYS.Brakes_Lever_Front_LHS, data.inspectionData[BIKE_DETAIL_KEYS.Brakes_Lever_Front_LHS])}
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.BOTH_VERSION, BIKE_DETAIL_KEYS.Number_Plate_Rear, data.inspectionData[BIKE_DETAIL_KEYS.Number_Plate_Rear])}
                                </tr>

                                <tr>
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.BOTH_VERSION, BIKE_DETAIL_KEYS.Mud_Flaps_Rear, data.inspectionData[BIKE_DETAIL_KEYS.Mud_Flaps_Rear])}
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.BOTH_VERSION, BIKE_DETAIL_KEYS.Number_Plate_Front, data.inspectionData[BIKE_DETAIL_KEYS.Number_Plate_Front])}
                                </tr>

                                <tr>
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.BOTH_VERSION, BIKE_DETAIL_KEYS.Mud_Flaps_Front, data.inspectionData[BIKE_DETAIL_KEYS.Mud_Flaps_Front])}
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.BOTH_VERSION, BIKE_DETAIL_KEYS.Front_Lower_Panel, data.inspectionData[BIKE_DETAIL_KEYS.Front_Lower_Panel])}
                                </tr>

                                <tr>
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.BOTH_VERSION, BIKE_DETAIL_KEYS.Front_Nose_Panel, data.inspectionData[BIKE_DETAIL_KEYS.Front_Nose_Panel])}
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.BOTH_VERSION, BIKE_DETAIL_KEYS.Mirror_LHS, data.inspectionData[BIKE_DETAIL_KEYS.Mirror_LHS])}
                                </tr>

                                <tr>
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.BOTH_VERSION, BIKE_DETAIL_KEYS.Mirror_RHS, data.inspectionData[BIKE_DETAIL_KEYS.Mirror_RHS])}
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.BOTH_VERSION, BIKE_DETAIL_KEYS.RI_Change_Flag, data.inspectionData[BIKE_DETAIL_KEYS.RI_Change_Flag])}
                                </tr>
                                
                            </tbody>
                        </table>
                    </td>
                </tr>
            </tbody>
            {/* -- Electricals Details -- */}
            <tbody>
                <tr>
                    <td>
                        <table className={styles.title} cellPadding="0" cellSpacing="0">
                            <tbody>
                                <tr>
                                    <td>Electricals</td>
                                </tr>
                            </tbody>
                        </table>
                        <table className={styles.data1} cellPadding="0" cellSpacing="0">
                            <tbody>
                                <tr>
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.NEW_VERSION, "Speedometer", data.inspectionData["Speedometer"])}
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.NEW_VERSION, "Battery", data.inspectionData["Battery"])}
                                </tr>
                                <tr>
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.NEW_VERSION, "Self Start", data.inspectionData["Self Start"])}
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.NEW_VERSION, "Headlight", data.inspectionData["Headlight"])}
                                </tr>
                                <tr>
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.NEW_VERSION, "Taillight", data.inspectionData["Taillight"])}
                                </tr>
                                <tr>
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.OLD_VERSION, "Head Light", data.inspectionData["Head Light"])}
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.OLD_VERSION, "Tail Light", data.inspectionData["Tail Light"])}
                                </tr>
                                <tr>
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.OLD_VERSION, "Indicator Lights - Front LHS", data.inspectionData["Indicator Lights - Front LHS"])}
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.OLD_VERSION, "Indicator Lights - Front RHS", data.inspectionData["Indicator Lights - Front RHS"])}
                                </tr>
                                <tr>
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.OLD_VERSION, "Indicator Lights - Rear LHS", data.inspectionData["Indicator Lights - Rear LHS"])}
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.OLD_VERSION, "Indicator Lights - Rear RHS", data.inspectionData["Indicator Lights - Rear RHS"])}
                                </tr>
                                <tr>
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.OLD_VERSION, "Mobile Charger", data.inspectionData["Mobile Charger"])}
                                </tr>
                                <tr>
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.OLD_VERSION, "Cluster Meter", data.inspectionData["Cluster Meter"])}
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.OLD_VERSION, "Battery Condition", data.inspectionData["Battery Condition"])}
                                </tr>
                                <tr>
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.OLD_VERSION, "Speedometer Working", data.inspectionData["Speedometer Working"])}
                                    {!isNewVersion && <td colSpan="2"></td>}
                                </tr>
                                <tr>
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.BOTH_VERSION, BIKE_DETAIL_KEYS.Clutch, data.inspectionData[BIKE_DETAIL_KEYS.Clutch])}
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.BOTH_VERSION, BIKE_DETAIL_KEYS.Chain_Condition, data.inspectionData[BIKE_DETAIL_KEYS.Chain_Condition])}
                                </tr>
                                <tr>
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.BOTH_VERSION, BIKE_DETAIL_KEYS.Ignition_Switch, data.inspectionData[BIKE_DETAIL_KEYS.Ignition_Switch])}
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.BOTH_VERSION, BIKE_DETAIL_KEYS.Indicator_Switch, data.inspectionData[BIKE_DETAIL_KEYS.Indicator_Switch])}
                                </tr>
                                <tr>
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.BOTH_VERSION, BIKE_DETAIL_KEYS.Self_Switch, data.inspectionData[BIKE_DETAIL_KEYS.Self_Switch])}
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.BOTH_VERSION, BIKE_DETAIL_KEYS.Headlight_Switch, data.inspectionData[BIKE_DETAIL_KEYS.Headlight_Switch])}
                                </tr>
                                <tr>
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.BOTH_VERSION, BIKE_DETAIL_KEYS.Carburetor, data.inspectionData[BIKE_DETAIL_KEYS.Carburetor])}
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.BOTH_VERSION, BIKE_DETAIL_KEYS.Accelerator_Cable, data.inspectionData[BIKE_DETAIL_KEYS.Accelerator_Cable])}
                                </tr>
                                <tr>
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.BOTH_VERSION, BIKE_DETAIL_KEYS.Meter_Cable, data.inspectionData[BIKE_DETAIL_KEYS.Meter_Cable])}
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.BOTH_VERSION, BIKE_DETAIL_KEYS.Dipper_Switch, data.inspectionData[BIKE_DETAIL_KEYS.Dipper_Switch])}
                                </tr>
                                <tr>
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.BOTH_VERSION, BIKE_DETAIL_KEYS.Brake_Cable, data.inspectionData[BIKE_DETAIL_KEYS.Brake_Cable])}
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.BOTH_VERSION, BIKE_DETAIL_KEYS.Choke_Cable, data.inspectionData[BIKE_DETAIL_KEYS.Choke_Cable])}
                                </tr>
                                <tr>
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.BOTH_VERSION, BIKE_DETAIL_KEYS.Horn, data.inspectionData[BIKE_DETAIL_KEYS.Horn])}
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.BOTH_VERSION, BIKE_DETAIL_KEYS.Choke, data.inspectionData[BIKE_DETAIL_KEYS.Choke])}
                                </tr>
                                <tr>
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.BOTH_VERSION, BIKE_DETAIL_KEYS.Clutch_Cable, data.inspectionData[BIKE_DETAIL_KEYS.Clutch_Cable])}
                                </tr>
                                
                            </tbody>
                        </table>
                    </td>
                </tr>
            </tbody>
            {/* -- Engine and transmission Details -- */}
            <tbody>
                <tr>
                    <td>
                        <table className={styles.title} cellPadding="0" cellSpacing="0">
                            <tbody>
                                <tr>
                                    <td>Engine and transmission</td>
                                </tr>
                            </tbody>
                        </table>
                        <table className={styles.data1} cellPadding="0" cellSpacing="0">
                            <tbody>
                                <tr>
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.NEW_VERSION, "Bike Type", data.inspectionData[BIKE_DETAIL_KEYS.Fuel_Type])}
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.NEW_VERSION, "Smoke Color", data.inspectionData[BIKE_DETAIL_KEYS.Exhaust_Smoke_Colour])}
                                </tr>
                                <tr>
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.NEW_VERSION, "Any Abnormal Engine Sound", data.inspectionData["Abnormal Engine Sound"])}
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.NEW_VERSION, "How many kicks does it takes to start the vehicle?", data.inspectionData["How many kicks does it takes to start the vehicle?"])}
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.NEW_VERSION, "Number of kicks required to start the bike", data.inspectionData["Number of kicks required to start the bike"])}
                                </tr>
                                <tr>
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.OLD_VERSION, "Engine and transmission", data.inspectionData["Engine"])}
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.OLD_VERSION, "Engine Sound", data.inspectionData["Engine Sound"])}
                                </tr>
                                <tr>
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.OLD_VERSION, "Exhaust Smoke", data.inspectionData["Exhaust Smoke"])}
                                </tr>
                                <tr>
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.OLD_VERSION, "Self Start", data.inspectionData["Self Start"])}
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.OLD_VERSION, "Kick Operation", data.inspectionData["Kick Operation"])}
                                </tr>
                                <tr>
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.OLD_VERSION, "Chain & Sprocket", data.inspectionData["Chain & Sprocket"])}
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.OLD_VERSION, "Belly pan (Engine cover)", data.inspectionData["Belly pan (Engine cover)"])}
                                </tr>
                                <tr>
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.BOTH_VERSION, BIKE_DETAIL_KEYS.Chassis_Number, data.inspectionData[BIKE_DETAIL_KEYS.Chassis_Number])}
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.BOTH_VERSION, BIKE_DETAIL_KEYS.Abnormal_Engine_Sound, data.inspectionData[BIKE_DETAIL_KEYS.Abnormal_Engine_Sound])}
                                </tr>
                                <tr>
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.BOTH_VERSION, BIKE_DETAIL_KEYS.Engine_Internal, data.inspectionData[BIKE_DETAIL_KEYS.Engine_Internal])}
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.BOTH_VERSION, BIKE_DETAIL_KEYS.Engine_Outer, data.inspectionData[BIKE_DETAIL_KEYS.Engine_Outer])}
                                </tr>
                                <tr>
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.BOTH_VERSION, BIKE_DETAIL_KEYS.Chassis_Condition, data.inspectionData[BIKE_DETAIL_KEYS.Chassis_Condition])}
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
            </tbody>
            {/* -- Wheels, brakes and suspension Details -- */}
            <tbody>
                <tr>
                    <td>
                        <table className={styles.title} cellPadding="0" cellSpacing="0">
                            <tbody>
                                <tr>
                                    <td>Wheels, brakes and suspension</td>
                                </tr>
                            </tbody>
                        </table>
                        <table className={styles.data1} cellPadding="0" cellSpacing="0">
                            <tbody>
                                <tr>
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.NEW_VERSION, "Tyre Type", data.inspectionData["Tyre Type"])}
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.NEW_VERSION, "Wheels Type", data.inspectionData["Wheels Type"])}
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.NEW_VERSION, "Is there a leakage in suspension?", data.inspectionData["Leakage In Suspension Shocker"])}
                                </tr>
                                <tr>
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.NEW_VERSION, "Brakes Condition", data.inspectionData["Brakes Condition"])}
                                </tr>
                                <tr>
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.OLD_VERSION, "Wheels Type", data.inspectionData["Wheels Type"])}
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.OLD_VERSION, "Wheels Condition", data.inspectionData["Wheels Condition"])}
                                </tr>
                                <tr>
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.OLD_VERSION, "Tire - Front", data.inspectionData["Tire - Front"])}
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.OLD_VERSION, "Tire - Rear", data.inspectionData["Tire - Rear"])}
                                </tr>
                                <tr>
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.OLD_VERSION, "Shock Absorber - Front LHS", data.inspectionData["Shock Absorber - Front LHS"])}
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.OLD_VERSION, "Shock Absorber - Front RHS", data.inspectionData["Shock Absorber - Front RHS"])}
                                </tr>
                                <tr>
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.OLD_VERSION, "Shock Absorber - Rear LHS", data.inspectionData["Shock Absorber - Rear LHS"])}
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.OLD_VERSION, "Shock Absorber - Rear RHS", data.inspectionData["Shock Absorber - Rear RHS"])}
                                </tr>
                                <tr>
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.OLD_VERSION, "Brakes - Front Disk", data.inspectionData["Brakes - Front Disk"])}
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.OLD_VERSION, "Brakes - Front Drum", data.inspectionData["Brakes - Front Drum"])}
                                </tr>
                                <tr>
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.OLD_VERSION, "Brakes - Rear Disk", data.inspectionData["Brakes - Rear Disk"])}
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.OLD_VERSION, "Brakes - Rear Drum", data.inspectionData["Brakes - Rear Drum"])}
                                </tr>
                                <tr>
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.OLD_VERSION, "Brake Control - Front LHS", data.inspectionData["Brake Control - Front LHS"])}
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.OLD_VERSION, "Brake Control - Front RHS", data.inspectionData["Brake Control - Front RHS"])}
                                </tr>
                                <tr>
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.OLD_VERSION, "Brake Control - Rear Brake Pedal", data.inspectionData["Brake Control - Rear Brake Pedal"])}
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.OLD_VERSION, "Steering Handle Bar", data.inspectionData["Steering Handle Bar"])}
                                </tr>
                                <tr>
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.OLD_VERSION, "Front Fork", data.inspectionData["Front Fork"])}
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.OLD_VERSION, "Spare tyre", data.inspectionData["Spare tyre"])}
                                </tr>
                                <tr>
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.BOTH_VERSION, BIKE_DETAIL_KEYS.Front_Tyre, data.inspectionData[BIKE_DETAIL_KEYS.Front_Tyre])}
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.BOTH_VERSION, BIKE_DETAIL_KEYS.Rear_Tyre, data.inspectionData[BIKE_DETAIL_KEYS.Rear_Tyre])}
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
            </tbody>
            {/* -- Other Features -- */}
            <tbody>
                <tr>
                    <td>
                        {!isNewVersion && <table className={styles.title} cellPadding="0" cellSpacing="0">
                            <tbody>
                                <tr>
                                    <td>Other Features</td>
                                </tr>
                            </tbody>
                        </table>}
                        <table className={styles.data1} cellPadding="0" cellSpacing="0">
                            <tbody>
                                <tr>
                                    {/* {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.OLD_VERSION, "Mud Flaps - Front", data.inspectionData["Mud Flaps - Front"])} */}
                                    {/* {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.OLD_VERSION, "Mud Flaps - Rear", data.inspectionData["Mud Flaps - Rear"])} */}
                                </tr>
                                <tr>
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.OLD_VERSION, "Foot Rest - Front LHS", data.inspectionData["Foot Rest - Front LHS"])}
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.OLD_VERSION, "Foot Rest - Front RHS", data.inspectionData["Foot Rest - Front RHS"])}
                                </tr>
                                <tr>
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.OLD_VERSION, "Foot Rest - Rear LHS", data.inspectionData["Foot Rest - Rear LHS"])}
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.OLD_VERSION, "Foot Rest - Rear RHS", data.inspectionData["Foot Rest - Rear RHS"])}
                                </tr>
                                <tr>
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.OLD_VERSION, "Stand - Side", data.inspectionData["Stand - Side"])}
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.OLD_VERSION, "Stand - Central", data.inspectionData["Stand - Central"])}
                                </tr>
                                <tr>
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.OLD_VERSION, "Front Leg guard", data.inspectionData["Front Leg guard"])}
                                    {data.inspectionData  && showItem(BIKE_REPORT_CATEGORY.OLD_VERSION, "Rear Saree Guard", data.inspectionData["Rear Saree Guard"])}
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
            </tbody>
            {/* -- Two Wheeler Images -- */}
            { data.images && (
                <>
                    <tbody>
                        <tr>
                            <td>
                                <table className={styles.title} cellPadding="0" cellSpacing="0">
                                    <tbody>
                                        <tr>
                                            <td>Two Wheeler Images</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table className={styles.imageTable} cellPadding="0" cellSpacing="0">
                                    <tbody>
                                        <tr>
                                            <td className={styles.imageHeading}>Bike Front</td>
                                            <td className={styles.imageHeading}>Bike Rear</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                {frontImageUri && <img src={cdnBaseUrl + frontImageUri} width="455px" height="350px"/>}
                                            </td>
                                            <td>
                                                {rearImageUri && <img src={cdnBaseUrl + rearImageUri} width="455px" height="350px"/>}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    </tbody>
                    <tbody>
                        <tr>
                            <td>
                                <table className={styles.imageTable} cellPadding="0" cellSpacing="0">
                                    <tbody>
                                        <tr>
                                            <td className={styles.imageHeading}>Right Side</td>
                                            <td className={styles.imageHeading}>Left Side</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                {rightSideImageUri && <img src={cdnBaseUrl + rightSideImageUri} width="455px" height="350px"/>}
                                            </td>
                                            <td>
                                                {leftSideImageUri && <img src={cdnBaseUrl + leftSideImageUri} width="455px" height="350px"/>}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    </tbody>
                    <tbody>
                        <tr>
                            <td>
                                <table className={styles.imageTable} cellPadding="0" cellSpacing="0">
                                    <tbody>
                                        <tr>
                                            <td className={styles.imageHeading}>Tyre Front</td>
                                            <td className={styles.imageHeading}>Tyre Rear</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                {tyreFrontImageUri && <img src={cdnBaseUrl + tyreFrontImageUri} width="455px" height="350px"/>}
                                            </td>
                                            <td>
                                                {tyreRearImageUri && <img src={cdnBaseUrl + tyreRearImageUri} width="455px" height="350px"/>}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    </tbody>
                    <tbody>
                        <tr>
                            <td>
                                <table className={styles.imageTable} cellPadding="0" cellSpacing="0">
                                    <tbody>
                                        <tr>
                                            <td className={styles.imageHeading}>Reading Panel</td>
                                            <td className={styles.imageHeading}>Bike Top</td>                            
                                        </tr>
                                        <tr>
                                            <td>
                                                {odometerImageUri && <img src={cdnBaseUrl + odometerImageUri} width="455px" height="350px"/>}
                                            </td>
                                            <td>
                                                {topImageUri && <img src={cdnBaseUrl + topImageUri} width="455px" height="350px"/>}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    </tbody>
                    {
                        (data.images && getInnerKeyDetailsOfObject(data.images, "keys")) && 
            <tbody>
                <tr>
                    <td>
                        <table className={styles.imageTable} cellPadding="0" cellSpacing="0">
                            <tbody>
                                <tr>
                                    <td className={styles.imageHeading}>Duplicate Key</td>
                                </tr>                       
                                <tr>
                                    <td>
                                        {keysImageUri && <img src={cdnBaseUrl + keysImageUri} width="455px" height="350px"/>}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
            </tbody>
                    }
                    {
                        (data.images  && isAdditionalImagesExist(data.images, "defectImages")) &&
            <tbody>
                <tr>
                    <td>
                        <table className={styles.imageTable} cellPadding="0" cellSpacing="0">
                            <tbody>
                                <tr>
                                    <td>Additional Images</td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div className={styles.daynamicList}>
                            <div className={styles.subDataList}>
                                {
                                    (data.images ) &&
                                    data.images.map((image, index) => (
                                        (image.type === "defectImages") && (
                                            <div key={index} className={styles.dataWrapper}>
                                                <p className={styles.titleText}>{image.title}</p>
                                                <div className={styles.imgBox}>
                                                    <img src={cdnBaseUrl+image.uri} />
                                                </div>
                                            </div>
                                        )
                                    )) }
                            </div>
                        </div>
                    </td> 
                </tr>
            </tbody> 
                    }
                </> 
            )}                  
            {/* -- Damage Image Section - END -- */}
            <br />
            <br />
        </table>
    );
};

export default BikeReport;
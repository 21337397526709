import { InspectionService } from "../../service";
import { Creators } from "./types";

const fetchXFactorList = (params = {}) => dispatch => {
    dispatch(Creators.showLoader());
    return (
        InspectionService.fetchStoreCancellationFactorList(params)
            .then(response => {
                let { data: { detail = [] } } = response;
      
                dispatch(Creators.fetchXFactorListSuccess(detail));
            })
            .catch(error => {
                dispatch(Creators.fetchXFactorListError());
                throw error;
            })
            .finally(() => {
                dispatch(Creators.hideLoader());
            })
    );
};

const addXFactor = (data) => dispatch => {
    dispatch(Creators.showLoader());
    return (
        InspectionService.addCancellationFactor(data)
            .then(response => {
                let { data } = response;
                dispatch(Creators.addUpdateSuccess());
                dispatch(Creators.setToast({ type: 'success', msg: data.detail }));
            })
            .catch(error => {
                dispatch(Creators.addUpdateError());
                dispatch(Creators.setToast({ type: 'error', msg: error.detail }));
                throw error;
            })
            .finally(() => {
                dispatch(Creators.hideLoader());
            })
    );
};

const updateXFactor = (id, data) => dispatch => {
    dispatch(Creators.showLoader());
    return (
        InspectionService.updateCancellationFactor(id, data)
            .then(response => {
                let { data } = response;
                dispatch(Creators.addUpdateSuccess());
                dispatch(Creators.setToast({ type: 'success', msg: data.detail }));
            })
            .catch(error => {
                dispatch(Creators.addUpdateError());
                dispatch(Creators.setToast({ type: 'error', msg: error.detail || 'Something went wrong!' }));
                throw error;
            })
            .finally(() => {
                dispatch(Creators.hideLoader());
                dispatch(Creators.resetToast());
            })
    );
};

const uploadXFactor = (file, fetchXFactorParams) => dispatch => {
    return (
        InspectionService.uploadCancellationFactor(file)
            .then(response => {
                let { data } = response;
                if(!data?.detail?.errors?.length) {
                    dispatch(Creators.setToast({ type: 'success', msg: "File uploaded successfully" }));
                } else {
                    let { error: errorCount, success: successCount, errors } = data.detail;
                    let errorMsgs = '';
                    errors.forEach(err => errorMsgs += `${err.message} at row ${err.rowNum} \n`);

                    const msg = `
                        ${successCount} rows updated successfully

                        Found errors in ${errorCount} rows
                        ${errorMsgs}
                    `;

                    dispatch(Creators.setToast({ type: 'error', msg }));
                }
            })
            .catch(error => {
                dispatch(Creators.setToast({ type: 'error', msg: error.detail }));
                throw error;
            })
            .finally(() => {
                dispatch(Creators.hideLoader());
                //refresh x-factor list
                setTimeout(() => {
                    dispatch(fetchXFactorList(fetchXFactorParams));
                }, 1000);
            })
    );
}

const showToast = (type, msg) => dispatch => {
    dispatch(Creators.setToast({ type, msg }));
}

export const updateFilter = (id, data) => dispatch => {
    dispatch(Creators.updateFilter({ id, data }));
}

export {
    fetchXFactorList,
    addXFactor,
    updateXFactor,
    uploadXFactor,
    showToast
};

import React from 'react';
import styles from './x-factor.module.css';
import UploadXFactor from "./UploadXFactor";

const XFactorDataTransfer = () => {
  return (
    <div className={styles.subheader}>
      <div className={styles.addBtnContainer}>
          <UploadXFactor />
      </div>
    </div>
  )
}

export default XFactorDataTransfer
import React from 'react'
import styles from './style.module.css';

const RejectionTable = ({ data }) => {
  return (
    <div className={styles.rejectionTable}>
      <table cellPadding="0" cellSpacing="0" width="100%">
        <thead>
            <tr>
                <th>No.</th>
                <th>Rejection Parameter</th>
                <th>Rejection Reason</th>
            </tr>
        </thead>
        <tbody>
          {data.map((d, i) => (
            <tr key={i}>
              <td>{i + 1}</td>
              <td>{d.rejectionParameter}</td>
              <td>{d.rejectionReason}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default RejectionTable
/* eslint-disable indent */
import React, { useState, useEffect } from "react";
import H3 from "../../components/h3";
import styles from "./inspection-review-exterior-bike.module.css";
import ImageWithZoom from "../image-with-zoom";
import ImageCheckboxBike from "../../components/image-checkbox-bike";
import { BIKE_IMAGE_TYPE, INSPECTION_FLOW, LOCAL_STORAGE_KEYS } from "../../utils/app-constants";
import { showToastMessages, navigateToHomeWithDelay, getFromLocalStorage } from "../../utils/helper";

const InspectionReviewExteriorBike = (
  {
    bikeInspectionDetails,
    data,
    type,
    currentMapper,
    versionViewMode = true
  }
) => {
  const { bikeMainImageMapper, qcUser } = bikeInspectionDetails;
  const [currentIndex, setCurrentIndex] = useState(0);
  const email = getFromLocalStorage(LOCAL_STORAGE_KEYS.EMAIL);
  // let bikeMainImagesMapper = [...data]; //[...bikeimageMapper];
  // mainImagesMapper = mainImagesMapper.filter(x => x.type === type);

  // bikeMainImagesMapper = bikeMainImagesMapper.filter(x => x.type === BIKE_IMAGE_TYPE.BIKE_IMAGES || x.type === BIKE_IMAGE_TYPE.DEFECT_IMAGES || x.type === BIKE_IMAGE_TYPE.DUPLICATE_KEY);

  // useEffect(() => {
  //   if (!!mainImagesMapper && mainImagesMapper.length > 0) {
  //     let rcImagesCount = mainImagesMapper.filter(x => x.imageType === "rc" && x.url !== "");
  //   }
  // }, [mainImagesMapper]);
  return (

    <div className={styles.exteriorWrapper}>
      <div className={styles.exteriorMainWrapper}>
        <H3 text={"Main Images"} />
        <div className={styles.imageWrapper}>
          {
            bikeMainImageMapper.map((item, index) => {
              return <div className={styles.exteriorImage}>
                <ImageWithZoom versionViewMode={versionViewMode} url={item.url} alt={item.url} index={index} height={513} editImage={email === qcUser ? true : false} mapper={bikeMainImageMapper} currentMapper={"bikeMainImageMapper"} inspectionFlow={INSPECTION_FLOW.BIKE} />
                {(email === qcUser) && <ImageCheckboxBike versionViewMode={versionViewMode} index={index} currentMapper={currentMapper} />}
                <p className={styles.imgCaption}>{item.title}</p>
              </div>;
            })
          }
        </div>

      </div>
    </div>
  );
};

export default InspectionReviewExteriorBike;

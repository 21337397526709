import React from 'react'

const Clock = ({ width = '16', style, className, color = "currentColor" }) => {
  return (
    <svg width={width} style={style} className={style} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_8058_65337)">
      <path d="M13.9522 1.9668C7.5293 1.9668 2.32812 7.11805 2.32812 13.4651C2.32812 19.8122 7.5293 24.9635 13.9522 24.9635C20.3868 24.9635 25.5996 19.8122 25.5996 13.4651C25.5996 7.11805 20.3868 1.9668 13.9522 1.9668ZM13.9639 22.6638C8.82087 22.6638 4.65527 18.5474 4.65527 13.4651C4.65527 8.38287 8.82087 4.26647 13.9639 4.26647C19.1069 4.26647 23.2725 8.38287 23.2725 13.4651C23.2725 18.5474 19.1069 22.6638 13.9639 22.6638Z" fill="#9C9C9C"/>
      <path d="M14.5422 8.04883H12.7969V14.9478L18.9056 18.5698L19.7783 17.1555L14.5422 14.0855V8.04883Z" fill={color} />
      </g>
      <defs>
      <clipPath id="clip0_8058_65337">
      <rect width="27.9258" height="27.596" fill="white"/>
      </clipPath>
      </defs>
    </svg>
  )
}

export default Clock
import React from "react";
import styles from "./change.module.css";

const Change = ({ value, errorMessage }) => {
    return (
        <div className={styles.change}>
            changed from <span>{value}</span>
            {!!errorMessage && <span className={styles.error}>{errorMessage}</span>}
        </div>
    );
};

export default React.memo(Change);
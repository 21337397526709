import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCategory } from '../../utils/mocApi';
import { getFromLocalStorage, isExcludedUser } from '../../utils/helper';
import { GS_UNFLAGGING_FILTER_PARAMS, LOCAL_STORAGE_KEYS } from '../../utils/app-constants';
import SelectComponent from '../../components/shared/select/component';
import Button from '../../components/shared/button';
import { Multiselect } from 'multiselect-react-dropdown';
import { fetchAssignee, fetchStoreLocation } from '../../components/inspection-filters/actions';
import { Creators } from './types';

const categoryOptions = getCategory().map(cat => ({ label: cat.key, value: cat.value }));
const isExcludedUserFlag = isExcludedUser();

const Filters = () => {
    const dispatch = useDispatch();
    const cities = useSelector(state => state.inspectionFiltersReducer.cityLocation);
    const assignee = useSelector(state => state.inspectionFiltersReducer.assigneeList);
    const { storeCityId, storeId, category, assignedTo } = useSelector(state => state.gsUnflaggingReducer.filters);

    const [cityOptions, setCityOptions] = useState([]);
    const [centerOptions, setCenterOptions] = useState([]);
    const [assigneeOptions, setAssigneeOptions] = useState([]);

    useEffect(() => {
        dispatch(fetchAssignee('gs'));
    }, []);

    useEffect(() => {
        if (assignee.length) {
            let _assigneeOptions = assignee.map(ass => ({ label: ass.name, value: ass.email }));

            if(isExcludedUserFlag) {
                setAssigneeOptions(_assigneeOptions);
            } else {
                setAssigneeOptions(_assigneeOptions.filter(op => op.value === getFromLocalStorage(LOCAL_STORAGE_KEYS.EMAIL)));
            }
        }
    }, [assignee]);

    useEffect(() => {
        if (cities.length) {
            let _cityOptions = cities.map(city => ({ label: city.city_name, value: city.city_id }));
            setCityOptions(_cityOptions);
        } else {
            dispatch(fetchStoreLocation());
        }
    }, [cities]);

    useEffect(() => {
        if (cities.length) {
            let _storeCityId = storeCityId.map(city => city.value);
            let city = cities.find(c => _storeCityId?.includes(c.city_id));

            if (city) {
                let _centerOptions = city.locations.map(loc => ({ label: loc.name, value: loc.location_id }));
                setCenterOptions(_centerOptions);
            } else {
                setCenterOptions([]);
            }
        }
    }, [storeCityId]);

    const handleFilterChange = (filterKey, value) => {
        console.log(value);
        dispatch(Creators.setFilter({ key: filterKey, value }));
        //clear center filter if city is cleared
        if (filterKey === GS_UNFLAGGING_FILTER_PARAMS.city && !value.length) {
            dispatch(Creators.setFilter({ key: GS_UNFLAGGING_FILTER_PARAMS.center, value: [] }));
        }
    };

    const resetFilters = () => {
        dispatch(Creators.resetFilters());
    };

    return (
        <div className="filterWrapper">

            <div className="ml-10">
                <Multiselect
                    selectedValues={storeCityId}
                    options={cityOptions}
                    onSelect={val => handleFilterChange(GS_UNFLAGGING_FILTER_PARAMS.city, val)}
                    onRemove={val => handleFilterChange(GS_UNFLAGGING_FILTER_PARAMS.city, val)}
                    displayValue="label"
                    placeholder="City"
                />
            </div>

            <div className="ml-10">
                <Multiselect
                    selectedValues={storeId}
                    options={centerOptions}
                    onSelect={val => handleFilterChange(GS_UNFLAGGING_FILTER_PARAMS.center, val)}
                    onRemove={val => handleFilterChange(GS_UNFLAGGING_FILTER_PARAMS.center, val)}
                    displayValue="label"
                    placeholder="Center"
                />
            </div>

            <div className="ml-10">
                <SelectComponent
                    value={category}
                    placeholder={"Category"}
                    optionsList={categoryOptions}
                    onChange={(value, label) => handleFilterChange(GS_UNFLAGGING_FILTER_PARAMS.category, [{ label, value }])}
                />
            </div>

            <div className="ml-10">
                <SelectComponent
                    value={assignedTo}
                    placeholder={"Assignee"}
                    optionsList={assigneeOptions}
                    onChange={(value, label) => handleFilterChange(GS_UNFLAGGING_FILTER_PARAMS.assignee, [{ label, value }])}
                />
            </div>

            <Button
                style={{ maxHeight: "38px", width: "inherit" }}
                classNames="ml-10 primaryButton cursor-pointer"
                ctaText={"Reset"}
                onClick={resetFilters}
            />
        </div>
    );
};

export default Filters;
import React from 'react'
import styles from './styles.module.css';
import { FILTER_ELEMENTS } from './constants'
import Select from './Select'
import Date from './Date';

const filterMap = {
  [FILTER_ELEMENTS.SELECT]: Select,
  [FILTER_ELEMENTS.DATE]: Date
}

const Filters = ({
  filterConfig,
  changeHandlers = {},
  CustomComponent
}) => {
  return (
    <div className={styles.filter}>
      <div>
        {
          Object.values(filterConfig).map(filter => {
            const FilterComponent = filterMap[filter.filterType];
            return (
              <div key={filter.id} className={styles.filterItem}>
                <FilterComponent
                  onChange={changeHandlers[filter.id]}
                  {...filter}
                />
              </div>
            )
          })
        }
      </div>
      <div>{CustomComponent}</div>
    </div>
  )
}

export default Filters;
import React from 'react';
import styles from "./car-variant-left-panel.module.css";
import { convertKeyIntoString } from '../../utils/helper';

const DropWidget = ({name, handleRemove}) => {
    return(
        <div className={styles.widgetBox}>
            <span className="paramaterName">{convertKeyIntoString(name, ' ')}</span>
            <button onClick={handleRemove} className="removeBtn">X</button>
        </div>
  
    );
};

export default DropWidget;
import React, { useEffect } from "react";
import H2 from "../../components/h2";
import styles from "./inspection-review-car-details.module.css";
import InspectionCarDetailsComments from "../inspection-car-details-comments";
import ImageWithZoom from "../image-with-zoom";
import ImageCheckbox from "../image-checkbox";
import { InspectionService } from "../../service";
import { getFieldRemarkHistory } from "../../utils/helper";
import { INSPECTION_SECTION, RESET_REDUCER_KEYS } from "../../utils/app-constants";

const InspectionReviewCarDetails = ({
    inspectionDetails,
    resetInspectionDetailByKeyConnect
}) => {
    const { commentsMapper, duplicateKeyMapper, carDetails, vahanData } = inspectionDetails;
    const comments = carDetails.comments || [];

    let isMigratedVehicleExists = comments.value?.find(item => item.id == 1);

    useEffect(() => {
        return () => {
            resetInspectionDetailByKeyConnect("commentsMapper", []);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const checkIfExists = (id) => {
        if (comments.value) {
            return !!comments.value.find(item => item.id == id);
        }
    };

    useEffect(() => {
        InspectionService.getComments("carDetails")
            .then(res => {
                let { data: { detail } } = res;
                if (detail) {
                    detail = detail || [];
                    let mapper = detail.map(function (el, index) {
                        const o = Object.assign({}, el);
                        o.checked = checkIfExists(o.id);
                        o.key = o.comment;
                        o.text = o.comment;
                        o.isVisible = true;
                        o.isEditable = false;
                        o.isImportant = checkIfExists(o.id);
                        o.remarkHistory = getFieldRemarkHistory(o.comment, inspectionDetails.carDetails?.errors);
                        o.mapper = RESET_REDUCER_KEYS.COMMENTS_MAPPER
                        return o;
                    });
                    resetInspectionDetailByKeyConnect("commentsMapper", mapper);
                }

            })
            .catch(e => {

            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={styles.carouselTextWrapper}>
            {/* <div className={styles.carouselWrapper}>
                <div className={styles.carousel}>
                    <Carousel images={images}/>
                    {
                        odometerMapper.map(item=> {
                            return <InspectionOdometer document={item}/>;
                        })
                    }
                </div>
            </div> */}
            <div className={styles.carousel}>
                {/*<Carousel images={images}/>*/}
                {
                    duplicateKeyMapper.length > 0 && duplicateKeyMapper.map(item => {
                        return <>
                            <ImageWithZoom url={item.url} alt={item.title} index={item.index} height={303} />
                            <ImageCheckbox currentMapper={"duplicateKeyMapper"} index={item.index} section={INSPECTION_SECTION.CAR_DETAILS}/>
                        </>;
                    })
                }
            </div>

            <div className={styles.commentsCarDetails}>
                <H2 text={"Comments: Car Details"} />
                <div className={styles.misMatchSection}>
                    {
                        commentsMapper && commentsMapper.map(detail => {
                            if (detail.status === "Inactive") return null;
                            
                            return <InspectionCarDetailsComments
                                isMigratedVehicleExists={isMigratedVehicleExists}
                                migratedVehicleFromBgc={vahanData?.bgcData?.migratedVehicle}
                                document={detail}
                                currentMapper={commentsMapper}
                                mapperName ={"commentsMapper"}
                            />;
                        })

                    }
                </div>
            </div>
        </div>
    );
};

export default InspectionReviewCarDetails;

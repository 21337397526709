import React, { useState, useEffect } from 'react';
import styles from './customer-document-popup.module.css';
import Button from '../shared/button/component';
import Carousel from '../carousel';
import {
  LOCAL_STORAGE_KEYS,
  REPLACE_WITH_INS,
  REPLACE_WITH_RC,
  NUMBERS,
} from '../../utils/app-constants';
import { getFromLocalStorage, showToastMessages } from '../../utils/helper';
import { InspectionService } from '../../service';
import SelectComponent from '../shared/select/component';
import { useDispatch } from 'react-redux';
import { fetchRestrictedPreInsImages } from '../../pages/inspection-review/actions';
const CustomerDocumentPopup = props => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [totalImages, setTotalImages] = useState([]);
  const [isConfirmEnabled, setIsConfirmEnabled] = useState(false);
  const [replacedImageKey, setReplacedImageKey] = useState('');
  const { isLoading, appointmentId } = props.inspectionDetails || {};
  const [curImage, setCurImage] = useState();
  const [imageUploadError, setImageUploadError] = useState(false);
  const dispatch = useDispatch();
  const onConfirmButton = () => {
    props.onClose();
    showToastMessages('Images uploaded successfully');
  };
  const onFetchingRestrictImages = () => {
    dispatch(fetchRestrictedPreInsImages(appointmentId));
  };
  useEffect(() => {
    const {
      preInspection: { preRcImages = [], preInsuranceImages = [] },
    } = props.inspectionDetails || {};
    const totImages = [...preRcImages, ...preInsuranceImages];
    if (!!totImages?.length) {
      setTotalImages(totImages);
      setIsConfirmEnabled(false);
    } else {
      setIsConfirmEnabled(true);
    }
  }, [props.inspectionDetails]);

  useEffect(() => {
    const currentImage = totalImages[currentIndex];
    setCurImage(currentImage);
  }, [totalImages, currentIndex]);

  const getTotalImageCount = type => {
    const {
      preInspection: { preRcImages = [], preInsuranceImages = [] },
      carDetails: { rcImages = [], insuranceImages = [] },
    } = props.inspectionDetails || {};
    return type === 'RC'
      ? rcImages.length + preRcImages.length
      : insuranceImages.length + preInsuranceImages.length;
  };
  const onImageAction = action => {
    const updatedImages = [...totalImages];
    const currentItem = updatedImages[currentIndex];
    const totImgCount = getTotalImageCount(currentItem?.type);
    if (totImgCount > NUMBERS.FIVE && !replacedImageKey) {
      setImageUploadError(true);
      return;
    }
    let email = getFromLocalStorage(LOCAL_STORAGE_KEYS.EMAIL);
    const payload = {
      appointmentId: appointmentId,
      fieldName: currentItem.type === 'RC' ? 'rcImages' : 'insuranceImages',
      action: action,
      user: email,
      id: currentItem.id,
      ...(totImgCount > NUMBERS.FIVE && { hashKey: replacedImageKey }),
    };
    InspectionService.updatePreInspectionImage(payload)
      .then(() => {
        currentItem.qcAction = action;
        updatedImages[currentIndex] = currentItem;
        setTotalImages(updatedImages);
        setIsConfirmEnabled(
          updatedImages.filter(item => !item.qcAction).length === 0,
        );
      })
      .catch(() => {
        showToastMessages(`${action?.toUpperCase()} failed`);
      });
  };
  const handleReplaceImage = e => {
    setImageUploadError(false);
    setReplacedImageKey(`${curImage.hashKey}${e}`);
  };

  const getReplaceImageDropdown = () => {
    const availableCount = getTotalImageCount(curImage?.type);
    if (availableCount > NUMBERS.FIVE) {
      return (
        <div className={styles.selectHash}>
          <SelectComponent
            hasError={imageUploadError}
            error={'Replace key is required'}
            optionsList={
              curImage?.type === 'RC' ? REPLACE_WITH_RC : REPLACE_WITH_INS
            }
            placeholder={'Replace'}
            onChange={handleReplaceImage}
          />
        </div>
      );
    }
  };
  return (
    !isLoading && (
      <div className={styles.customerDocumentPopup}>
        <div className={styles.heading}>
          <span>Customer Doc Upload Verification</span>
          {!!curImage && (
            <p className={styles.info}>
              Please accept or reject -{' '}
              <span className={styles.infoHighlight}>
                {curImage?.imageTitle}
              </span>
            </p>
          )}
        </div>
        <div className={styles.imageWrapper}>
          <div className={styles.carousel}>
            <Carousel
              images={totalImages}
              key="documents"
              setCurrentIndex={setCurrentIndex}
              height={500}
              wheelZoomDisabled={false}
              swipeIndex={0}
              currentMapper="rcImagesMapper"
              fetchRestrictImagesHandler={onFetchingRestrictImages}
            />
          </div>
        </div>
        {!totalImages[currentIndex]?.qcAction ? (
          <div className={styles.carouselActions}>
            <Button
              ctaText="╳"
              classNames={`${styles.carouselActionsDiscardCta}`}
              onClick={() => onImageAction('rejected')}
            />
            <Button
              ctaText="✔"
              classNames={`${styles.carouselActionsCta}`}
              onClick={() => onImageAction('accepted')}
            />
            {getReplaceImageDropdown()}
          </div>
        ) : (
          <div className={styles.carouselActions}>
            {totalImages[currentIndex]?.qcAction === 'accepted' ? (
              <div className={styles.ctaBanner}>
                <Button
                  ctaText="✔"
                  classNames={`${styles.carouselActionsCta}`}
                />
                <span>Accepted</span>
              </div>
            ) : (
              <div className={styles.ctaBanner}>
                <Button
                  ctaText="╳"
                  classNames={`${styles.carouselActionsDiscardCta}`}
                />
                <span>Rejected</span>
              </div>
            )}
          </div>
        )}
        <div className={styles.greenCtaButton}>
          <Button
            ctaText={'Confirm'}
            classNames="greenCtaButton"
            onClick={onConfirmButton}
            disabled={!isConfirmEnabled}
          />
        </div>
      </div>
    )
  );
};

export default CustomerDocumentPopup;

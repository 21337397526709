import { createReducer } from "reduxsauce";
import { GS_UNFLAGGING_FILTER_PARAMS } from "../../utils/app-constants";
import { Types } from "./types";

const INITIAL_FILTER_STATE = {
    [GS_UNFLAGGING_FILTER_PARAMS.city]: [],
    [GS_UNFLAGGING_FILTER_PARAMS.center]: [],
    [GS_UNFLAGGING_FILTER_PARAMS.category]: [],
    [GS_UNFLAGGING_FILTER_PARAMS.assignee]: []
};

export const INITIAL_STATE = {
    isLoading: true,
    data: [],
    filters: INITIAL_FILTER_STATE,
    details: {}
};

const showLoader = (state = INITIAL_STATE) => ({
    ...state, isLoading: true
});

const hideLoader = (state = INITIAL_STATE) => ({
    ...state, isLoading: false
});

const fetchGsUnflaggingListSuccess = (state = INITIAL_STATE, { data }) => ({
    ...state, data
});

const fetchGsUnflaggingListError = (state = INITIAL_STATE, { error }) => ({
    ...state, error: true
});

const fetchGsUnflaggingDetailsSuccess = (state = INITIAL_STATE, { data }) => ({
    ...state, details: data
});

const fetchGsUnflaggingDetailsError = (state = INITIAL_STATE, { error }) => ({
    ...state, error: true
});

const setFilter = (state= INITIAL_STATE, { filter }) => ({
    ...state, filters: { ...state.filters, [filter.key]: filter.value }
});

const resetFilters = (state = INITIAL_STATE) => ({
    ...state, filters: INITIAL_FILTER_STATE
});

export const HANDLERS = {
    [Types.SHOW_LOADER]: showLoader,
    [Types.HIDE_LOADER]: hideLoader,
    [Types.FETCH_GS_UNFLAGGING_LIST_SUCCESS]: fetchGsUnflaggingListSuccess,
    [Types.FETCH_GS_UNFLAGGING_LIST_ERROR]: fetchGsUnflaggingListError,
    [Types.FETCH_GS_UNFLAGGING_DETAILS_SUCCESS]: fetchGsUnflaggingDetailsSuccess,
    [Types.FETCH_GS_UNFLAGGING_DETAILS_ERROR]: fetchGsUnflaggingDetailsError,
    [Types.SET_FILTER]: setFilter,
    [Types.RESET_FILTERS]: resetFilters
};

export default createReducer(INITIAL_STATE, HANDLERS);
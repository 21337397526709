import React, { useEffect } from 'react';
import styles from './header.module.css';
import Logo from '../logo/component';
import { Link } from 'react-router-dom';
import {
  getFromLocalStorage,
  isUserRoleBIkeQc,
  saveToLocalStorage,
  isAuthorizedAccess,
  getHomepageURL,
} from '../../utils/helper';
import {
  LOCAL_STORAGE_KEYS,
  ROLE_KEYS,
  B_TO_B,
  CAR_ID_REGEX,
} from '../../utils/app-constants';
import { InspectionService } from '../../service';
import Performance from '../../components/performance';
import firebase from '../../utils/firebase';
import { useSelector } from 'react-redux';
import { useDescope, getSessionToken } from '@descope/react-sdk';
import { useLocation } from 'react-router-dom';
const Header = ({
  history,
  showVersion,
  details,
  showPerformance = true,
  showDropdown = true,
  showUsername = true,
  headerText,
  showInspectionPdf,
  inspectionPdfUrl,
  showLogout = true,
}) => {
  const { logout } = useDescope();
  let pathName = '/';
  const localData = getFromLocalStorage(LOCAL_STORAGE_KEYS.LOGIN_DATA);
  const name = localData ? JSON.parse(localData)[LOCAL_STORAGE_KEYS.NAME] : '';
  const email = getFromLocalStorage(LOCAL_STORAGE_KEYS.EMAIL);
  const role = getFromLocalStorage(LOCAL_STORAGE_KEYS.ROLE);
  const isBikeQc = isUserRoleBIkeQc();
  const storeType = details?.basicInfo?.header?.storeType;
  if (typeof window !== 'undefined') {
    pathName = window.location.pathname;
  }
  const path = pathName.trim().replace(CAR_ID_REGEX, '');
  const access = isAuthorizedAccess(path);
  const homePagepath = getHomepageURL(role);
  if (!access) {
    history.push(homePagepath);
  }
  const dropdownMappingOpject = {
    'gs-refurbishment': 'GS Refurbishment Suggestions',
    'gs-refurbishment-version': 'GS Refurbishment Version',
    inspection: 'Car QC',
    'inspection-bike': 'Bike QC',
    'inspection-version': 'Versions',
    'inspection-bike-version': 'Bike Versions',
    '': 'Car QC',
    admin: 'Admin - Xfactor',
    'cj-leave': 'Upload CJ Leave',
    'gs-unflagging': 'GS Unflagging',
    'admin-rv': 'Admin - Reg/Variant',
    'admin-vtt': 'Video Tutorial Trigger',
  };

  const onLogout = () => {
    let data = {
      email: email,
      accessToken: getSessionToken(),
    };
    InspectionService.logOut(data)
      .then(() => {
        firebase
          .getInstance()
          .auth()
          .signOut()
          .then(() => {
            console.log('Sign-out successful');
          })
          .catch(error => {
            console.log('An error happened.', error);
          });
        logout();
        localStorage.clear();
        history.replace('/login');
      })
      .catch(error => {
        console.log('error::', error);
        localStorage.clear();
        history.replace('/login');
      }).finally(()=> {
        window.location.reload();
      });
  };

  const RedirectBasedOnRoleCar = () => {
    setTimeout(() => {
      history.push('/inspection');
    });
  };

  const dropdownItem = dropdownMappingOpject[pathName.split('/')[1]];
  return (
    <div className={styles.header}>
      <div className={styles.logoSection}>
        <Logo />
        {showUsername && <p className={styles.userName}>{name}</p>}
        {headerText && <p className={styles.userName}>{headerText}</p>}
        {storeType === B_TO_B.B_TO_B && (
          <button className={styles.headerBtn}>B2B Store</button>
        )}
      </div>
      <div className={styles.versionSection}>
        {!isBikeQc && showPerformance && <Performance />}
        {showDropdown && (
          <div className={styles.performanceWrapper}>
            <p className={styles.performanceText}>{dropdownItem}</p>
            <div className={styles.performanceTextWrapper}>
              <ul className={styles.testWrapper}>
                {([
                  'qc',
                  ROLE_KEYS.QC_STORE_MANAGER,
                  ROLE_KEYS.QC_ADMIN,
                ].includes(role) ||
                  role === 'bike-qc') &&
                  dropdownItem !== 'Car QC' && (
                    <li name="Car QC">
                      <Link onClick={RedirectBasedOnRoleCar}>Car QC</Link>
                    </li>
                  )}
                {showVersion &&
                  role !== 'bike-qc' &&
                  dropdownItem !== 'Versions' && (
                    <li>
                      <Link to="/inspection-version">Versions</Link>
                    </li>
                  )}
                {[
                  'qc',
                  ROLE_KEYS.QC_STORE_MANAGER,
                  ROLE_KEYS.QC_ADMIN,
                ].includes(role) &&
                  dropdownItem !== 'GS Refurbishment Suggestions' && (
                    <li>
                      <Link to="/gs-refurbishment">
                        GS Refurbishment Suggestions
                      </Link>
                    </li>
                  )}
                {[
                  'qc',
                  ROLE_KEYS.QC_STORE_MANAGER,
                  ROLE_KEYS.QC_ADMIN,
                ].includes(role) &&
                  dropdownItem !== 'GS Refurbishment Version' && (
                    <li>
                      <Link to="/gs-refurbishment-version">
                        GS Refurbishment Version
                      </Link>
                    </li>
                  )}
                {role === ROLE_KEYS.QC_ADMIN &&
                  dropdownItem !== 'Admin - Xfactor' && (
                    <li>
                      <Link to="/admin">Admin - Xfactor</Link>
                    </li>
                  )}
                {role === ROLE_KEYS.QC_ADMIN &&
                  dropdownItem !== 'Admin - Reg/Variant' && (
                    <li>
                      <Link to="/admin-rv">Admin - Reg/Variant</Link>
                    </li>
                  )}
                {role === ROLE_KEYS.QC_ADMIN &&
                  dropdownItem !== 'Video Tutorial Trigger' && (
                    <li>
                      <Link to="/admin-vtt">Video Tutorial Trigger</Link>
                    </li>
                  )}
                {role === ROLE_KEYS.QC_ADMIN &&
                  dropdownItem !== 'Upload CJ Leave' && (
                    <li>
                      <Link to="/update-cj">Upload CJ Leave</Link>
                    </li>
                  )}
                {[ROLE_KEYS.GS, ROLE_KEYS.QC_ADMIN].includes(role) &&
                  dropdownItem !== 'GS Unflagging' && (
                    <li>
                      <Link to="/gs-unflagging">GS Unflagging</Link>
                    </li>
                  )}
              </ul>
            </div>
          </div>
        )}
        {showInspectionPdf && (
          <a href={inspectionPdfUrl} target="_blank" className={styles.pdfUrl}>
            Inspection Report PDF
          </a>
        )}
        {showLogout && (
          <p className={styles.logout} onClick={onLogout}>
            Logout
          </p>
        )}
      </div>
    </div>
  );
};

export default Header;

import React, { useState } from 'react'
import './styles.css';
import { useDispatch } from 'react-redux';
import { getRejectionRemark, saveRejectionRemark } from '../../../pages/inspection-review/actions';
import { REJECTION_REMARK_TYPE } from '../../../utils/app-constants';

const RemarkInput = ({
  document,
  section,
  inspectionDetails,
  resetInspectionDetailByKeyConnect,
  submitLabel = "Add"
}) => {
  const dispatch = useDispatch();
  const [comment, setComment] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleCommentSave = (remarkType, audioBlob) => {
    if(
      remarkType === REJECTION_REMARK_TYPE.TEXT &&
      (!comment.trim().length || isLoading)
    ) return;

    setIsLoading(true);

    let data = {
      carId: inspectionDetails.carId,
      section,
      part: document.part,
      subPart: document.subPart,
      remarkType
    }

    if(remarkType === REJECTION_REMARK_TYPE.TEXT) {
      data["remark"] = comment;
    }

    if(document.hashKey) {
      data["hashKey"] = document.hashKey;
    }

    let callback = () => {
      setIsLoading(false);
      setComment("");

      let params = {
        appointmentId: inspectionDetails.appointmentId,
        section,
        part: document.part
      }

      if(document.subPart) {
        params.subPart = document.subPart;
      }
      if(document.hashKey) {
        params.hashKey = document.hashKey;
      }
      dispatch(getRejectionRemark(params, document.mapper))
    }

    dispatch(saveRejectionRemark(data, callback));
  }

  return (
    <>
      <div className="remark">

        <input
          className="remark__input"
          placeholder="Comments"
          value={comment}
          onChange={e => setComment(e.target.value)}
        />

        <div className="remark__actions">

          <button
            className={`remark__send`}
            onClick={() => handleCommentSave(REJECTION_REMARK_TYPE.TEXT)}
            disabled={comment.trim().length < 1}
          >
            {isLoading ? "Adding...": submitLabel}
          </button>

        </div>

      </div>

      {!document.isEditable && document.mapper !== 'mismatchMapper' && <div className="remark__mandatory">*mandatory comment</div>}
    </>
  )
}

export default RemarkInput
import Types from "./types";
import { InspectionService } from "../../service";

const fetcInspectionList = (page, useremail, searchQuery, storeCityId, storeId, category, assignedToSearch, appointmentId, startDate, endDate) => dispatch => {
   
    return InspectionService.getInspectionList(page, useremail, searchQuery, storeCityId, storeId, category, assignedToSearch, appointmentId, startDate, endDate)
        .then(response => {
            // const { data: { detail } } = response;
            const { data } = response;
            // const { detail } = data;
            dispatch(fetcInspectionListSuccess(data));
            return response;
        })
        .catch(error => {
            dispatch(fetcInspectionListFailure(error));
            throw error;
        });
};
const AssignToMe = (carId, params) => dispatch => {
    return InspectionService.AssignToMe(carId, params)
        .then(response => {
            const { data: { detail } } = response;
            // dispatch(checkInPopupSuccess(detail));
            return response;

        })
        .catch(error => {
            // dispatch(checkInPopupFailure(error));
            throw error;
        });
};
const fetcInspectionListSuccess = (data) => ({
    type: Types.FETCH_INSPECTIONS_LIST_SUCCESS,
    data
});
const fetcInspectionListFailure = (data) => ({
    type: Types.FETCH_INSPECTIONS_LIST_FAILURE,
    data
});
const resetInspectionByKey = (key, value) => ({
    type: Types.RESET_INSPECTION_BY_KEY,
    data: { key, value }
});
export {
    fetcInspectionList,
    resetInspectionByKey,
    AssignToMe
};

import React from 'react';
import ReactSelect from 'react-select';

const Select = ({
  className,
  classNamePrefix,
  name,
  value,
  options = [],
  placeholder = '',
  onChange = () => {},
  ...restProps
}) => {

  const handleChange = (value) => {
    onChange(value ?? "");
  };

  return (
    <ReactSelect
      name={name}
      className={className}
      classNamePrefix={classNamePrefix}
      placeholder={placeholder}
      options={options}
      onChange={handleChange}
      value={value}
      styles={{ menuPortal: (provided) => ({ ...provided, zIndex: 10000000 }) }}
      menuPortalTarget={document.body}
      menuPlacement="auto"
      {...restProps}
    />
  )
}

export default Select
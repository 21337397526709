/* eslint-disable indent */
import React, { useState, useEffect } from 'react';
import styles from './login.module.css';
import Logo from '../../components/images/logo.svg';
import QcWeblink from '../../components/images/qcWeblink.svg';
import DescopeSignInWidget from './components/DescopeSignInWidget';
import { getSessionToken } from '@descope/react-sdk';
import { useSession, useUser, useDescope } from '@descope/react-sdk';
import {
  AuthService,
  GsUnflaggingService,
  InspectionService,
} from '../../service';
import {
  saveToLocalStorage,
  getFromLocalStorage,
  GetQueryString,
  onBackLogin,
} from '../../utils/helper';
import { LOCAL_STORAGE_KEYS, ROLE_KEYS } from '../../utils/app-constants';
import firebase from '../../utils/firebase';
import { useHistory } from 'react-router-dom';
const Account = ({ match }) => {
  const { isSessionLoading } = useSession();
  const { isUserLoading, user } = useUser();
  const { logout } = useDescope();
  const history = useHistory();
  let qsVersion = GetQueryString('version');
  let carId = GetQueryString('carId');
  const loginErrorMessage = decodeURIComponent(GetQueryString('key') || '');
  const Loading = () => {
    return <p>Loading...</p>;
  };

  const onLogout = msg => {
    const email = getFromLocalStorage(LOCAL_STORAGE_KEYS.EMAIL);
    let data = {
      email: email,
      accessToken: getSessionToken(),
    };

    InspectionService.logOut(data)
      .then(() => {
        firebase
          .getInstance()
          .auth()
          .signOut()
          .then(() => {
            console.log('Sign-out successful');
          })
          .catch(error => {
            console.log('An error happened.', error);
          });
        logout();
        localStorage.clear();
        onBackLogin(msg);
      })
      .catch(error => {
        console.log('error::', error);
      });
  };
  const onLoginSuccess = token => {
    AuthService.getUserDetails(token)
      .then(resp => {
        const { detail: data = {} } = resp;
        const { role = '', email = '', firebaseToken = '' } = data || {};
        saveToLocalStorage(LOCAL_STORAGE_KEYS.EMAIL, email);
        saveToLocalStorage(LOCAL_STORAGE_KEYS.ROLE, role);
        saveToLocalStorage(LOCAL_STORAGE_KEYS.LOGIN_DATA, JSON.stringify(data));
        if (
          [
            ROLE_KEYS.QC,
            ROLE_KEYS.QC_VERSION,
            ROLE_KEYS.QC_STORE_MANAGER,
            ROLE_KEYS.QC_ADMIN,
            ROLE_KEYS.GS,
          ].includes(role)
        ) {
          getAllDispositionsList();
          if (role === ROLE_KEYS.QC_VERSION) {
            history.push(`/inspection-version`);
          } else if (role === ROLE_KEYS.QC_BIKE) {
            history.push('/inspection-bike');
          } else {
            if (carId) {
              history.push(`/re-inspection/${carId}?version=${qsVersion}`);
            } else {
              if (firebaseToken) {
                firebase
                  .getInstance()
                  .auth()
                  .signInWithCustomToken(firebaseToken)
                  .then(() => {
                    if (role === ROLE_KEYS.GS) {
                      history.push('/gs-unflagging');
                    } else {
                      history.push('/inspection');
                    }
                  })
                  .catch(error => {
                    console.log('error', error);
                  });
              } else {
                if (role === ROLE_KEYS.GS) {
                  history.push('/gs-unflagging');
                } else {
                  history.push('/inspection');
                }
              }
            }
          }
        } else if (role === ROLE_KEYS.OTHER) {
          history.push(`/inspection-version`);
        } else {
          console.log('No role assigned');
          onLogout('No valid QC role assigned');
        }
      })
      .catch(err => {
        console.log({ err });
        logout();
        localStorage.clear();
        onBackLogin(err?.detail || err);
      });
  };

  useEffect(() => {
    if (isSessionLoading || isUserLoading) {
      Loading();
    }
  }, [isSessionLoading, isUserLoading]);

  const onSuccess = e => {
    const {
      detail: { sessionJwt = '', user = '' },
    } = e || {};
    if (!!user && !!sessionJwt) {
      onLoginSuccess(sessionJwt);
    }
  };

  const onError = () => {
    onBackLogin('Not a Valid Cars24 user');
  };

  const getAllDispositionsList = () => {
    GsUnflaggingService.getAllDispositions().then(res => {
      saveToLocalStorage(
        LOCAL_STORAGE_KEYS.DISPOSITIONS_DROPDOWN_ITEM,
        JSON.stringify(res.data.detail),
      );
    });
  };

  return (
    <div className={styles.loginMainWrapper}>
      <div className={styles.loginWrapper}>
        <div className={styles.logoSection}>
          <img src={Logo} alt="logo" />
          <img src={QcWeblink} alt="QcWeblink" />
        </div>
        <p className={styles.text}>Help us do right by our customers! </p>
        <div className={styles.googleLogin}>
          <DescopeSignInWidget onSuccess={onSuccess} onError={onError} />
        </div>
        {!!loginErrorMessage && (
          <div className={styles.loginSection}>
            <small key="loginErr" className={styles.textDanger}>
              {loginErrorMessage}
            </small>
          </div>
        )}
      </div>
    </div>
  );
};

export default Account;

import React, { useState, useEffect } from 'react';
import styles from './gs-refurbishment.module.css';
import { InspectionService } from "../../service";
import InfiniteScroll from 'react-infinite-scroller';
import Header from "../../components/header";
import Loader from '../../components/loader';
import Searchicon from "../../components/images/search.svg";
import ColorPallet from "../../components/colorpallet";
import Button from "../../components/shared/button/component";
import { LOCAL_STORAGE_KEYS, NUMBERS } from "../../utils/app-constants";
import { getFromLocalStorage } from "../../utils/helper";

const GSRefurbishment = ({history}) => {

    const [isLoading, setIsLoading] = useState(false);
    const [refurbishData, setRefurbishData] = useState([]);
    const email = getFromLocalStorage(LOCAL_STORAGE_KEYS.EMAIL);
    const [searchQuery, setSearchQuery] = useState('');
    const [pageCount, setPageCount] = useState(0);
    const [hasMoreItems, setHasMoreItems] = useState(true);

    const searchRefurbishmentData = (page = 0, appointmentId) => {
        setIsLoading(true);
        InspectionService.getRefurbishmentSearch(page, appointmentId).then( response => {
            const { data: {detail} } = response;
            setRefurbishData([...detail]);
            if(detail.length === 50) {
                setHasMoreItems(true);
                setPageCount(pageCount + 1);
            }
            setIsLoading(false);
        }).catch(error => {
            setIsLoading(false);
            console.log('Error::',error);
        });
    };

    const getRefurbishmentList = (page) => {
        page === 1 && setIsLoading(true);
        setHasMoreItems(false);
        InspectionService.getRefurbishmentList(page).then( response => {
            const { data: {detail} } = response;
            setRefurbishData([...refurbishData, ...detail]);
            if(detail.length === 50) {
                setHasMoreItems(true);
                setPageCount(pageCount + 1);
            }
            setIsLoading(false);
        }).catch(error => {
            setIsLoading(false);
            console.log('Error::',error);
        });
    };

    const loadMoreRefurbishment = () => {
        getRefurbishmentList(pageCount + 1);       
    };

    const redirectToReview = (item) => {
        history.push(`gs-refurbishment-review/${item.appointmentId}`);
    };

    const getAssignToMe = (item) => {
        return (
            <Button classNames="assignCta" ctaText={"assign to me"} onClick={(e) => assignToMeClick(e, item)} />
        );
    };

    const assignToMeClick = (e, item) => {
        e.stopPropagation();
        InspectionService.assignRefurbishmentToQc({
            appointmentId: item.appointmentId, 
            qcAssignTo: email
        })
            .then(() => {
                history.push(`gs-refurbishment-review/${item.appointmentId}`);
            })
            .catch(e => {
                console.log('Error::',e);
            });
    };

    const onChangeHandler = (event) => {
        const {value} = event.target;
        if(value && value.trim())
            setSearchQuery(value.trim());
    };

    const clearSearch = () => {
        setSearchQuery('');
        setPageCount(0);
        getRefurbishmentList(1);
    };

    const onSearchKeyPress = (e) => {
        const { charCode } = e;
        if (charCode === NUMBERS.THIRTEEN) {
            setHasMoreItems(false);
            searchRefurbishmentData(0, searchQuery);
        }
    };

    const refurbishmentData = refurbishData;

    const renderInspectionList = (itemList) => {
        return (
            itemList.map(item =>
                (
                    <tr key={item.appointmentId} onClick={() => redirectToReview(item)}>
                        <td><ColorPallet appointmentId={item.appointmentId} initialTime={item.endTime} /></td>
                        <td >{item.appointmentId}</td>
                        <td>{item.refurbishedBy}</td>
                        <td>{`${!!item.make ? item.make + "/" : ""}${!!item.model ? item.model : ""}`}</td>
                        <td>{item.endTime}</td>
                        <td>{(item.qcAssignTo && item.qcAssignTo.to) || getAssignToMe(item)}</td>
                    </tr>
                )
            )
        );
    };

    return (
        <div>
            <Header showPerformance={false} history={history} showVersion={false} />
            <div className={styles.inspectionHeader}>
                <div className={styles.search}>
                    <input 
                        type="text" 
                        name="search" 
                        placeholder="Appointment ID" 
                        onChange={onChangeHandler} 
                        value={searchQuery || ""} 
                        autoComplete="off" 
                        onKeyPress={onSearchKeyPress}
                    />
                    {searchQuery && <button onClick={clearSearch}>x</button>}
                    {!searchQuery && <button>
                        <img src={Searchicon} alt="search" />
                    </button>}
                </div>
            </div>
            {isLoading && <Loader />}
            {refurbishmentData.length == 0 && !isLoading && <p className={styles.noAppointment}>No Refurbishment Data</p>}
            <div className={styles.inspectionList}>
                <InfiniteScroll
                    key={"1"}
                    // ref={scrollRef}
                    className={styles.inspectionList}
                    pageStart={1}
                    loadMore={loadMoreRefurbishment}
                    hasMore={hasMoreItems}
                    // initialLoad={true}
                    threshold={500}
                >
                    {refurbishmentData.length > 0 && !isLoading && (
                        <table cellPadding="0" cellSpacing="0" width="100%" >
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Appt ID <span className={styles.count}>({refurbishData.length})</span></th>
                                    <th>Refurbishment Inspection By</th>
                                    <th>Model</th>
                                    <th>Inspection End Time</th>
                                    <th>Assignee</th>
                                </tr>
                            </thead>
                            <tbody>
                                {renderInspectionList(refurbishmentData)}
                            </tbody>
                        </table >
                    )}
                </InfiniteScroll>
            </div>
            {/* <DataInformationMessage
        isLoading={isLoading}
        data={[1]}
        error={error}
      /> */}
        </div>
    );
  
};

export default GSRefurbishment; 
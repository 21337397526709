export const  timeConverter = (UNIX_timestamp) => { 
    const a = new Date(UNIX_timestamp * 1000);
    const months = ['January','February','Mar','April','May','Jun','July','August','September',
        'October','November','December'];
    const year = a.getFullYear();
    const month = months[a.getMonth()];
    const date = a.getDate();
    return date + ' ' + month + ' ' + year ;
};

export const  getCurrentTimeInSecond = () => { 
    return Math.floor(new Date().getTime() / 1000);
};    
import { InspectionService } from "../../service";
import Types from "./types";

// Action for Car Variants Parameter
const fetchCarVariantParameterDataList = () =>  dispatch => {
    InspectionService.getCarVariantParameterData().then(response => {
        const { data } = response;
        dispatch(fetchCarVariantParameterDataListSuccess(data));
    }).catch(error => {
        dispatch(fetchCarVariantParameterDataListFailure(error));
        throw error;
    });
};

const fetchCarVariantParameterDataListSuccess = (data) => ({
    type: Types.FETCH_CAR_VARIANT_PARAMETER_DATA_SUCCESS,
    data
});

const fetchCarVariantParameterDataListFailure = (data) => ({
    type: Types.FETCH_CAR_VARIANT_PARAMETER_DATA_FAILURE,
    data
});

const fetchCarModelList = (id) => dispatch => {
    InspectionService.getCarModalList(id).then(response => {
        const { data } = response;
        dispatch(fetchCarModelListSuccess(data));
    }).catch(error => {
        dispatch(fetchCarModelListFailure(error));
    });
};

const fetchCarModelListSuccess = (data) => ({
    type: Types.FETCH_CAR_MODAL_SUCCESS,
    data
});

const fetchCarModelListFailure = data => ({
    type: Types.FETCH_CAR_MODAL_FAILURE,
    data
});

const fetchManufacturingYears = (modelId) => dispatch => {
    InspectionService.getCarManufacturingYearsList(modelId).then(response => {
        const { data } = response;
        dispatch(fetchManufacturingYearsSuccess(data));
    }).catch(error => {
        dispatch(fetchManufacturingYearsFailure(error));
    });
};

const fetchManufacturingYearsSuccess = data => ({
    type: Types.FETCH_CAR_MANUFACTURING_LIST_SUCCESS,
    data
});

const fetchManufacturingYearsFailure = data => ({
    type: Types.FETCH_CAR_MANUFACTURING_LIST_FAILURE,
    data
});

//CAR MAKE LIST
const fetchCarMakeList = () => dispatch => {
    InspectionService.getCarMake().then(response => {
        const { data } = response;
        dispatch(fetchCarMakeListSuccess(data));
    }).catch(error => {
        dispatch(fetchCarMakeListFailure(error));
    });
};

const fetchCarMakeListSuccess = data => ({
    type: Types.FETCH_CAR_MAKE_LIST_SUCCESS,
    data
});

const fetchCarMakeListFailure = data => ({
    type: Types.FETCH_CAR_MAKE_LIST_FAILURE,
    data
});
export {
    fetchCarVariantParameterDataList,
    fetchCarModelList,
    fetchManufacturingYears,
    fetchCarMakeList
};

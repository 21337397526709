import React, { useEffect, useState } from 'react';
import styles from './styles.module.css';
import Popup from '../../components/popup';
import Button from '../../components/shared/button/component';
import H2 from '../../components/h2/component';
import { GS_UNFLAG_REASON, GS_UNFLAG_APLHA_TO_BETA, GS_UNFLAG_BUTTON_ACTION, GS_UNFLAG_BETA_TO_ALPHA, GS_UNFLAG_CONDITION_FLAG, LOCAL_STORAGE_KEYS, NUMBERS } from '../../utils/app-constants';
import SelectComponent from '../../components/shared/select';
import { getFromLocalStorage } from '../../utils/helper';
import { useSelector } from 'react-redux';

const getDropdownOptions = (action, conditionFlag) => {
    let mapping = [];
    if(action === GS_UNFLAG_BUTTON_ACTION.UNFLAG) {
        mapping = GS_UNFLAG_REASON;
    } else if(action === GS_UNFLAG_BUTTON_ACTION.CHANGE) {
        if(conditionFlag !== NUMBERS.ZERO){
            const dispositionOptions= JSON.parse(getFromLocalStorage(LOCAL_STORAGE_KEYS.DISPOSITIONS_DROPDOWN_ITEM));
            return dispositionOptions.map((item) => ({
                label: item.value,
                value: item
            }));
        }
        else{
            mapping= GS_UNFLAG_APLHA_TO_BETA;
        }
    }
    return Object.keys(mapping).map(key => ({
        label: key,
        value: mapping[key]
    }));
};

const ButtonContainer = (props) => {

    const [subDispositionOptions,setSubDispositionOptions]=useState([]);
    const [isUnflag,setIsUnflag]=useState(false);

    const handleDispositionChange=(e)=>{
        if(props.conditionFlag===NUMBERS.ZERO){
            props.setDisposition(e);
        }
        else if(props.conditionFlag!==NUMBERS.ZERO && isUnflag){
            props.setDisposition(e);
        }
        else{
            props.setSubDispositions([]);
            props.setDisposition(e.key);
            if(!isUnflag){
                const subDisposition =  !!e.subDispostion && e.subDispostion?.length>0 && e.subDispostion?.map(item => ({
                    label: item.value,
                    value: item.key
                }));
                setSubDispositionOptions(subDisposition);
            }   
        }
    };
    return (
        <div className="pageWrap my-lg">
            <div className={styles.btnContainer}>
                <Button
                    ctaText={`Mark As ${GS_UNFLAG_CONDITION_FLAG[props.conditionFlag]}`.toUpperCase()}
                    classNames="greenCtaButton"
                    onClick={() => {props.handleBtnClick(GS_UNFLAG_BUTTON_ACTION.MARK);
                    }}
                />
                <Button
                    ctaText={`Change To ${GS_UNFLAG_CONDITION_FLAG[props.conditionFlag == 0 ? "4" : "0"]}`.toUpperCase()}
                    classNames="greenCtaButton ml-10"
                    onClick={() => {props.handleBtnClick(GS_UNFLAG_BUTTON_ACTION.CHANGE);
                        setIsUnflag(false);
                    }}
                    disabled={!!props.hardBeta}
                />
                <Button
                    ctaText="UN-FLAG THIS CAR"
                    classNames="redCtaButton ml-10"
                    onClick={() => {
                        props.handleBtnClick(GS_UNFLAG_BUTTON_ACTION.UNFLAG);
                        setIsUnflag(true);
                    }}
                />
            </div>

            <Popup isOpen={props.isModalOpen} close={props.handlePopupClose}>
                <H2
                    className="mt-lg"
                    text={`${props.btnAction} Appointment Id`}
                />
                <p className={styles.popupSubtitle}>{`Are you sure you want to ${props.btnAction} this appointment Id?`}</p>

                {props.btnAction !== GS_UNFLAG_BUTTON_ACTION.MARK && (
                    <>
                        <SelectComponent 
                            optionsList={
                                getDropdownOptions(props.btnAction, props.conditionFlag)
                            }
                            onChange={(e)=>handleDispositionChange(e)}
                            placeholder="Select Disposition"
                        />
                        {props.errors.disposition && <p className={styles.errorText}>{props.errors.disposition}</p>}
                        {(props.conditionFlag!==NUMBERS.ZERO && !isUnflag) && <div className={styles.granularities}>
                            <SelectComponent
                                optionsList={!!subDispositionOptions && subDispositionOptions.length>0 ? subDispositionOptions : []}
                                onChange={(value) => props.setSubDispositions(value)}
                                placeholder="Select All Wrong Granularities"
                                isMulti={true}
                                value={props.subDispositions?.length>0?props.subDispositions: "" }
                            />
                        </div>}
                        <textarea
                            className={styles.popupReason}
                            placeholder="Comments"
                            onChange={e => props.setReason(e.target.value)}
                            value={props.reason}
                        />
                        {props.errors.reason && <p className={styles.errorText}>{props.errors.reason}</p>}
                    </>
                )}

                <div className={`${styles.btnContainer} mt-lg`}>
                    <Button
                        ctaText="CONFIRM"
                        classNames={`primaryButton`}
                        onClick={props.handleActionSubmit}
                    />
                    <Button
                        ctaText="Cancel"
                        classNames="cancelButton ml-10"
                        onClick={props.handlePopupClose}
                    />
                </div>
            </Popup>
        </div>
    );
};

export default ButtonContainer;
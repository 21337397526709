import React, { useState, useEffect} from "react";
import styles from "./car-variant-left-panel.module.css";
import SelectComponent from "../../components/shared/select";
import RadioButton from "../../components/shared/radio-button";
import DropBoxForWidget from './DropBoxForWidget';

const CarVariantLeftPanel = ({
    carVariantReducer, 
    dropBoxes, 
    handleDrop, 
    handleRemove, 
    fetchManufacturingYears,
    updateCarVariantObject,
    carVariants,
    isReset,
    setdropBoxes,
    setCarVariants,
    errorList,
    reSetErrorMessage,
    fetchCarModelList,
    data,
    isEditMode
}) => {  
    const {carMake : selectedCarMake, fuelType: selectedFuelType, modelData: selectedModelData, selectedYears: currentSelectedYears, editVariantData}=data || {};
    const [fuelTypeValue, setFuelTypeValue] = useState(selectedFuelType || '');
    const [carMake, setCarMake] = useState(selectedCarMake || '');
    const [variantList, setVariantList] = useState([]);
    const [carModelIdValue, setCarModelIdValue] = useState('');
    // const fuelTypeList = ["Petrol", "Diesel", "CNG", "Hybrid"];
    const [modelData, setModelData] = useState( selectedModelData || '');
    const {carModelsList, carManufacturingYears, carMakeList } = carVariantReducer;
    const [selectedYears, setSelectedYears] = useState(currentSelectedYears || []);
    const yearsList = Object.keys(carManufacturingYears);
    const { variantParameters } = carVariants;
    let carManufacturingYearsList = yearsList.length ? yearsList.map(year => ({label: year, value: year})) : [];
    const fuelTypes = [];
    (selectedYears || []).forEach(item => {
        const itemList = carManufacturingYears[item.value];
        if(itemList && itemList.length) {
            itemList.forEach(yearItem => {
                fuelTypes.push(yearItem.fuel_type);
            });
        }
    });
    const fuelTypeList = [...new Set(fuelTypes)];
    const [editVariantId, setEditVariantId] = useState((!!editVariantData && editVariantData.variantId) || {});
    const onChangeHandlerCarMake = (makeId, makeName) => {
        setCarMake({label: makeName, value: makeId});
        fetchCarModelList(makeId);
        setSelectedYears([]);
        setVariantList([]);
        setModelData('');
        setCarModelIdValue('');
    };

    const onChangeHandlerCarModel = (modelId, modelName) => {
        if(carModelIdValue === modelId) {
            return;
        }
        reSetErrorMessage('model_id');
        setSelectedYears([]);
        setVariantList([]);
        setCarModelIdValue(modelId);
        fetchManufacturingYears(modelId);
        setModelData({label: modelName, value: modelId});
        updateCarVariantObject({'model_id': modelId, modelName});
    };

    useEffect(() => {
        if(isReset) {
            setSelectedYears([]);
            setVariantList([]);
            setCarModelIdValue('');
            setModelData('');
            setFuelTypeValue('');
        }
    }, [isReset]);

    useEffect(() =>{
        const selectedVariantListData = [];
        !!currentSelectedYears && currentSelectedYears.forEach(item => {
            const itemList = carManufacturingYears[item.value];
            !!itemList && selectedVariantListData.push(...itemList);
            let selectedVariantList = !!selectedVariantListData && selectedVariantListData.map(variantItem => ({
                label: variantItem.variant_name, 
                value: variantItem.variant_id
            }));
            selectedVariantList =  selectedVariantList.filter((v,i,a)=>a.findIndex(t=>(t.value === v.value))===i);
            setVariantList(selectedVariantList);
        });
    },[carManufacturingYears, currentSelectedYears]);
    const onChangeManufacturingYear = (yearList) => {
        reSetErrorMessage('manufacturingYears');
        const selectedVariantListData = [];
        let yearsValue = [];
        yearList && yearList.map(year => {
            selectedVariantListData.push(...carManufacturingYears[year.value]);
            yearsValue.push(year.value);
            
        });
        setSelectedYears(yearList);
        let selectedVariantList = selectedVariantListData.map(variantItem => ({
            label: variantItem.variant_name, 
            value: variantItem.variant_id
        }));
        selectedVariantList =  selectedVariantList.filter((v,i,a)=>a.findIndex(t=>(t.value === v.value))===i);
        setVariantList(selectedVariantList);
        updateCarVariantObject({'manufacturingYears': yearsValue.join(', ')});
    };

    const onChangeHandlerCarVariant = (variantId, variantName, variantIndex) => {
        reSetErrorMessage('variant', variantIndex);
        updateCarVariantObject({'variant': variantName, 'variantId': variantId}, variantIndex);
        setEditVariantId(variantId);
    };

    const onClickCallback = (value) => {
        setFuelTypeValue(value);
        reSetErrorMessage('fuelType');
        updateCarVariantObject({'fuelType': value});
    };
    
    const addNewVariantHandler = () => {
        setdropBoxes([...dropBoxes, dropBoxes[0]]);
        const newVarData = {...carVariants.variantParameters[0]};
        for(const key in newVarData) {
            newVarData[key] = '';
        }
        const variantParams = carVariants.variantParameters.concat(newVarData);
        setCarVariants({
            ...carVariants, 
            variantParameters: [...variantParams]
        });
    };

    const getVariantValues = variantObject => {
        if(variantObject && !Object.keys(variantObject).length) {
            return {label: '', value: ''};
        }
        return {label: variantObject.variant , value: variantObject.variantId};
    };

    return (
        <div className={styles.carVariantLeftPanel}>

            <div className={styles.variantTopModle}>
                <ul>
                    <li>
                        <p>Make</p>
                        <SelectComponent 
                            optionsList={carMakeList} 
                            onChange={onChangeHandlerCarMake} 
                            placeholder="Select Make"
                            value={carMake}
                            isDisabled={isEditMode ? true : false}
                        />
                        {errorList[0] && errorList[0]['make_id'] &&
                         <span className={styles.errorMessage}>{ 'Select ' + errorList[0]['make_id']}</span>}
                    </li>
                    
                    <li>
                        <p>Model</p>
                        <SelectComponent 
                            optionsList={carModelsList} 
                            onChange={onChangeHandlerCarModel} 
                            placeholder="Select Model"
                            value={modelData}
                            isDisabled={isEditMode ? true : false}
                        />
                        {errorList[0] && errorList[0]['model_id'] &&
                         <span className={styles.errorMessage}>{ 'Select ' + errorList[0]['model_id']}</span>}
                    </li>
                    <li>
                        <p>Manufacturing Year</p>
                        <SelectComponent 
                            isMulti 
                            optionsList={carManufacturingYearsList} 
                            onChange={onChangeManufacturingYear} 
                            value={selectedYears}
                            isClearable
                            placeholder="Select Years"
                            isDisabled={isEditMode ? true : false}
                        />
                        {errorList[0] && errorList[0]['manufacturingYears'] && 
                        <span className={styles.errorMessage}>Select {errorList[0]['manufacturingYears']}</span>}
                    </li>
                    <li>
                        <p>Fuel Type</p>
                        <div className={styles.variantBox}>
                            {fuelTypeList.map((fuelType, index) => (
                                <div className={styles.variant} key={index}>
                                    <RadioButton 
                                        key={index}
                                        text={fuelType} 
                                        onClickCallback={onClickCallback} 
                                        name={fuelType}
                                        value={fuelType}
                                        id={fuelType} 
                                        checkedStatus={fuelType === fuelTypeValue}
                                        isDisabled={isEditMode ? true : false}
                                    />
                                </div>))}
                            {errorList[0] && errorList[0]['fuelType'] && 
                            <span className={styles.errorMessage}>Select { errorList[0]['fuelType']}</span>}
                        </div>
                    </li>
                </ul>
            </div>
            <div className={styles.dropWrapper}>
                {dropBoxes.map((dropBoxesItem, variantIndex) => (
                    <div key={variantIndex} className={styles.drop}>
                        <ul>
                            <span className={styles.variantCountLabel}>{variantIndex + 1}</span>
                            {variantIndex !== 0 && (
                                <button 
                                    className={styles.removeVariant}
                                    onClick={() => handleRemove(null, null, variantIndex, true)}
                                >
                                X
                                </button>
                            )}
                            <li>
                                <ul className={styles.dropList}>
                                    <li>Add Paramater</li>
                                    <li>(Drag & Drop)</li>
                                    <li>Value</li>
                                </ul>
                                {dropBoxesItem.map((item, index) => (
                                    <DropBoxForWidget 
                                        accept={item.accepts}  
                                        lastDroppedItem={item.lastDroppedItem} 
                                        key={index}
                                        index={index}
                                        onDrop={(dragItem) => handleDrop(dragItem, index, variantIndex)}
                                        handleRemove={() => handleRemove(index, item.lastDroppedItem.name, variantIndex)}
                                        updateCarVariantObject={updateCarVariantObject}
                                        carVariants={carVariants}
                                        variantIndex={variantIndex}
                                        errorListObject={errorList[variantIndex] || {}}
                                    />
                                ))}
                            </li>
                            <li className={styles.variant}>
                                <p>Variant: </p>
                                <SelectComponent 
                                    optionsList={variantList} 
                                    onChange={(id, name) => onChangeHandlerCarVariant(id, name, variantIndex)} 
                                    classNameProps="variantWidth"
                                    value={getVariantValues(variantParameters[variantIndex])}
                                    placeholder="Select Variant"
                                    variantIndex={variantIndex}
                                    defaultValue={editVariantId}
                                />
                                {errorList[variantIndex] && errorList[variantIndex]['variant'] && 
                            <span className={styles.errorMessage}>Select { errorList[variantIndex]['variant']}</span>}
                            </li>
                        </ul>
                    </div>
                ))}
                {!isEditMode && (<div className={styles.addVariant}>
                    <button onClick={addNewVariantHandler}>Add Variant</button>
                </div>)}
            </div>
        </div>
    );
};

export default CarVariantLeftPanel;

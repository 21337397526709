import baseApi from "./baseApi";
import inspectionApi from "./inspection-service";
import authenticationApi from "./auth-service";
import gsUnflaggingApi from "./gs-unflagging-service";

const inspection = inspectionApi(baseApi);
const auth = authenticationApi(baseApi);
const gsUnflagging = gsUnflaggingApi(baseApi);

export {
    baseApi,
    inspection as InspectionService,
    auth as AuthService,
    gsUnflagging as GsUnflaggingService
};


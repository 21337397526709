import React, {useEffect, useState} from "react";
import styles from "./reinspection-email-popup.module.css";
import H2 from "../h2/component";
import Button from "../shared/button/component";
import { ReactMultiEmail, isEmail } from 'react-multi-email';
import 'react-multi-email/style.css';
import {InspectionService} from "../../service";
import InputText from "../shared/input-text";
import {isAssignedToMe} from "../../utils/helper";

const ReinspectionEmailPopup = ({inspectionDetails,onClose}) => {
    const [emails,setEmails] = useState([]);
    const {carId,version,appointmentId,qcAssign,raEmail,regionDepartmentEmail} = inspectionDetails;
    const [subject] = useState(`Re-inspection changes ${appointmentId}`);
    const currentUser = qcAssign ? qcAssign.to :"";

    useEffect(()=>{
        const fixed = ['qc@cars24.com',
            'purchase.qc@cars24.com',
            'inspections@cars24.com'];

        setEmails(fixed);
        if(raEmail) {
            fixed.push(raEmail);
        }
        if(regionDepartmentEmail){
            fixed.push(regionDepartmentEmail);
        }

        //eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);
    const sendEmail = ()=>{
        const response = {
            carId: carId,
            version: version,
            to: emails.join(",")
        };

        if(emails.length > 0){
            InspectionService.sendEmail(response)
                .then(res=>{
                    alert("Email Sent!");
                    onClose();
                })
                .catch(e=>{
                    alert("Email send issue");
                    onClose();
                });
        }
        else{
            alert("Please select recipients");
        }
    };

    return (
        <div className={styles.reinspectionEmailPopup}>
            <H2 text={"Re- Inspection Changes Mail"}/>
            <div className={styles.emailForm}>
                <p>To:</p>
                <ReactMultiEmail
                    placeholder="Emails"
                    emails={emails}
                    onChange={(_emails: string[]) => {
                        setEmails(_emails);
                    }}
                    validateEmail={email => {
                        return isEmail(email); // return boolean
                    }}
                    getLabel={(
                        email: string,
                        index: number,
                        removeEmail: (index: number) => void,
                    ) => {
                        return (
                            <div data-tag key={index}>
                                {email}
                                <span data-tag-handle onClick={() => removeEmail(index)}>
                                    x
                                </span>
                            </div>
                        );
                    }}
                />
                <p>Subject:</p>
                <InputText
                    placeholder={"Subject"}
                    value={subject}
                />
            </div>

            {
                isAssignedToMe(currentUser) && <div className={styles.sendCta}>
                    <Button ctaText={"Send"} classNames="greenCtaButton" onClick={sendEmail}/>
                </div>
            }
        </div>
    );
};

export default ReinspectionEmailPopup;

import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import InspectionReviewInsurance from './component';
import {
  resetInspectionDetailByKey,
  fetchInspectionRestrictImages,
} from '../../pages/inspection-review/actions';

const mapStateToProps = ({ inspectionDetails }) => ({
  inspectionDetails,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      resetInspectionDetailByKeyConnect: resetInspectionDetailByKey,
      fetchInspectionRestrictImagesConnect: fetchInspectionRestrictImages,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InspectionReviewInsurance);

import React, { useState } from "react";
import H2 from "../../components/h2";
import H3 from "../../components/h3";
import Checkbox from "../../components/shared/checkbox";
import InfoIcon from "../../components/shared/info-icon";
import ImageCheckbox from "../../components/image-checkbox";
import Carousel from "../../components/carousel/";
import Tyres from "../../components/images/lhsfronttyreadditio.jpg";
import styles from "./inspection-review-exterior.module.css";
import ImageWithZoom from "../image-with-zoom";
import Button from "../shared/button";
import InspectionPocPopup from "../inspection-poc-popup";
import Popup from "../popup";
import { getFromLocalStorage, isAssignedToMe } from "../../utils/helper";
import { INSPECTION_SECTION } from "../../utils/app-constants";
import { useDispatch } from "react-redux";
import RemarkInput from "../shared/remark-input";

const InspectionReviewExterior = ({ inspectionDetails, resetInspectionDetailByKeyConnect }) => {
    const dispatch = useDispatch();
    const { exteriorTyresMapper, exteriorComments, qcAssign, selectedVersion } = inspectionDetails;
    const currentUser = qcAssign ? qcAssign.to : "";
    let showEditImage = false;
    if (isAssignedToMe(currentUser) && !(selectedVersion > -1 || selectedVersion === "current")) {
        showEditImage = true;
    }
    // const [checkBoxChecked,setChecked] = useState(false);
    const onCheckboxHandler = () => {
        exteriorComments.checked = !exteriorComments.checked;
        resetInspectionDetailByKeyConnect("exteriorComments", exteriorComments);
    };
    return (
        <div className={styles.exteriorWrapper}>
            <H2 text={"Exterior"} />
            <div className={styles.exteriorComment}>
                {
                    exteriorComments && exteriorComments.value.length > 0 && <div className={styles.exteriorTextList}>
                        <div className={styles.exteriorText}>
                            <Checkbox
                                onChangeCallback={() => onCheckboxHandler()}
                                name={`exterior_comments`}
                                id={`exterior_comments`}
                                checkedStatus={exteriorComments ? exteriorComments.checked : false}
                            />
                            <p className={styles.text}>Comments: Exterior</p>
                        </div>
                        <ul>
                            {
                                exteriorComments && exteriorComments.value.map(comment => {
                                    return <li>
                                        <InfoIcon />
                                        <p>{comment.comment}</p>
                                    </li>;
                                })
                            }
                        </ul>
                    </div>
                }
            </div>
            <div className={styles.exteriorMainWrapper}>
                <H3 text={"Main Images"} />
                <div className={styles.imageWrapper}>
                    {
                        exteriorTyresMapper.map(item => {
                            return <div className={styles.exteriorImage}>
                                <ImageWithZoom url={item.url} alt={item.url} index={item.index} height={513} editImage={showEditImage} mapper={exteriorTyresMapper} currentMapper={"exteriorTyresMapper"} />
                                <ImageCheckbox currentMapper={"exteriorTyresMapper"} index={item.index} mapper={exteriorTyresMapper} section={INSPECTION_SECTION.EXTERIOR_TYRES} />
                            </div>;
                        })
                    }
                </div>

            </div>
        </div>
    );
};

export default InspectionReviewExterior;

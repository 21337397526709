import React, { useState, useEffect } from "react";
import styles from "./inspection-review-footer-bike.module.css";
import Button from "../shared/button/component";
import Loader from "../images/loader.svg";
import { InspectionService } from "../../service";
import { showToastMessages, navigateToHomeWithDelay, getFromLocalStorage } from "../../utils/helper";
import { LOCAL_STORAGE_KEYS, BIKE_DETAIL_KEYS } from "../../utils/app-constants";
import Popup from "../../components/popup";
import InspectionConfirmationPopup from "../../components/inspection-confirmation-popup/";

const InspectionReviewFooterBike = ({
    bikeInspectionDetails,
    history,
    versionViewMode = true
}) => {
    const { 
        appointmentId, 
        bikeId, qcUser, 
        bikeDocumentsMapper, 
        bikeRcImageMapper, 
        bikeInsuranceImageMapper, 
        bikeMainImageMapper,
        additionalBikeImagesMapper,
        cjRemark,
    } = bikeInspectionDetails;
    const [isLoading, setIsLoading] = useState(false);
    const email = getFromLocalStorage(LOCAL_STORAGE_KEYS.EMAIL);
    const [reason, setReason] = useState("");
    const [submitStatus, setSubmitStatus] = useState("APPROVED");
    const [isAnyFieldTouched, setIsAnyFieldTouched] = useState(false);
    let bikeStatus = "APPROVED";

    useEffect(() => {
        bikeDocumentsMapper.map(item => {
            if (item.checked) {
                setIsAnyFieldTouched(true);
                // setSubmitStatus("REJECTED");
                // return;
            }
        });

        let _rcImage = bikeRcImageMapper.filter(x => x.checked === true);
        _rcImage = !!_rcImage ? _rcImage : [];
        let _bikeInsuImage = bikeInsuranceImageMapper.filter(x => x.checked === true);
        _bikeInsuImage = !!_bikeInsuImage ? _bikeInsuImage : [];
        let _mainImage = bikeMainImageMapper.filter(x => x.checked === true);
        _mainImage = !!_mainImage ? _mainImage : [];
        const additionalImagesMapper = additionalBikeImagesMapper.filter(additionalImage => additionalImage.checked === true) || [];
        
        let allImage = [..._rcImage, ..._bikeInsuImage, ..._mainImage, ...additionalImagesMapper];
        if (!!allImage && allImage.length > 0) {
            setSubmitStatus("REJECTED");
            setIsAnyFieldTouched(true);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bikeDocumentsMapper, bikeRcImageMapper, bikeInsuranceImageMapper, bikeMainImageMapper, additionalBikeImagesMapper]);

    const isValidated = () => {
        let flag = true;
        let inValidDate = false;
        let fields = [];
        let modalYear = '';
        let manufacturingYear = '';
        bikeDocumentsMapper.map(item => {
            if(item.key === BIKE_DETAIL_KEYS.REGISTRATION_YEAR){
                modalYear = item.newValue || item.value;
            }
            if(item.key === BIKE_DETAIL_KEYS.Manufacturing_Date) {
                manufacturingYear = item.newValue || item.value;
            }
           
            if (item.checked) {
                if (!!item.newValue) {
                    if (item.newValue.toString() === "") {
                        flag = false;
                        fields.push(item.text);
                    }
                }
                else if (item.newValue.toString() === "") {
                    flag = false;
                    fields.push(item.text);
                }
            }
        });
        manufacturingYear = manufacturingYear && manufacturingYear.split('/')[2];

        if(+modalYear < +manufacturingYear) {
            return {
                flad: false,
                message: 'Modal Year should be greater than Manufacturing Year.',
                isModalYearInValid: true
            };
        }

        return {
            flag: flag,
            inValidDate: inValidDate,
            fields: fields
        };
    };

    const [popupOpen, setPopupOpen] = useState(false);

    const onPopupOpen = () => {
        const isValidatedData = isValidated();
        if (!isValidatedData.flag) {
            setIsLoading(false);
            if (isValidatedData.inValidDate) {
                alert(`Date is in invalid format for Fields: ${isValidatedData.fields.join()}`);
            } else if(isValidatedData.isModalYearInValid) {
                alert(isValidatedData.message);
            } else {
                alert(`Please Edit the following Fields: ${isValidatedData.fields.join()}`);
            }
        }
        else {
            setPopupOpen(true);
        }
    };
    const onPopupClose = (submitReview) => {
        if (submitReview === true) {
            SubmitStatus();
        }
        setPopupOpen(false);
    };

    const SubmitStatus = () => {
        setIsLoading(true);
        let data = new FormData();
        let submitRequestPromise = postRejectSubmitRequest();
        data.append("status", submitStatus);
        data.append("reason", reason);

        submitRequestPromise.then((response) => {
            setTimeout(() => {
                InspectionService.submitBikeStatus(data, bikeId, appointmentId)
                    .then(res => {
                        setIsLoading(false);
                        showToastMessages("Inspection Completed Successfully");
                        navigateToHomeWithDelay(history);
                    })
                    .catch(e => {
                        setIsLoading(false);
                        showToastMessages(`Error in submit review - ${!!e.message ? e.message : ""}`, false);
                    });
            }, 1);
        })
            .catch((e) => {
                setIsLoading(false);
                showToastMessages(`Error in submit review - ${!!e.message ? e.message : ""}`, false);
            });

    };

    const postRejectSubmitRequest = () => {
        if (!isAnyFieldTouched) {
            return new Promise((resolve, reject) => {
                resolve("done");
            });
        }
        else {
            return new Promise((resolve, reject) => {
                let reviewJson = {};
                const bikeDetailData = {};
                let arrayValueFields = [BIKE_DETAIL_KEYS.RC_Condition, BIKE_DETAIL_KEYS.Chassis_Number_Embossing];
                let _bikeDetail = bikeDocumentsMapper.filter(x => x.checked === true);
                if (!!_bikeDetail && _bikeDetail.length > 0) {
                    // setBikeStatus("REJECTED");
                    bikeStatus = "REJECTED";
                    for (let i = 0; i < _bikeDetail.length; i++) {
                        bikeDetailData[_bikeDetail[i].key] = !arrayValueFields.find(field => field === _bikeDetail[i].key) ? _bikeDetail[i].newValue : _bikeDetail[i].newValue.split(",");
                    }
                    reviewJson.inspectionData = bikeDetailData;
                }

                let imageArray = getRejectedImages();
                if (!!imageArray && imageArray.length > 0) {
                    reviewJson["images"] = imageArray;
                }

                const videosArray = getRejectedVideos();
                if(videosArray && videosArray.length) {
                    reviewJson["videos"] = videosArray;
                }

                reviewJson["reason"] = reason;
                console.log(reviewJson);
                InspectionService.pushAppoinetmentData(reviewJson, appointmentId)
                    .then(res => {
                        // showToastMessages("Updated");
                        resolve();
                    })
                    .catch(e => {
                        showToastMessages(`Error in submit review - ${!!e.message ? e.message : ""}`, false);
                        reject();
                    });
            });
        }
    };

    const getRejectedVideos = () => {
        return additionalBikeImagesMapper.filter(additionalImage => additionalImage.mediaType === 'video' && additionalImage.checked === true).map( video => ({
            hashKey: video.hashKey,
            rejected: true
        }));
    };

    const getRejectedImages = () => {
        let arrayImage = [];
        try {
            let _rcImage = bikeRcImageMapper.filter(x => x.checked === true);
            _rcImage = !!_rcImage ? _rcImage : [];
            let _bikeInsuImage = bikeInsuranceImageMapper.filter(x => x.checked === true);
            _bikeInsuImage = !!_bikeInsuImage ? _bikeInsuImage : [];
            let _mainImage = bikeMainImageMapper.filter(x => x.checked === true);
            _mainImage = !!_mainImage ? _mainImage : [];
            const additionalImagesMapper = additionalBikeImagesMapper.filter(additionalImage => additionalImage.mediaType === 'image' && additionalImage.checked === true);

            let allImage = [..._rcImage, ..._bikeInsuImage, ..._mainImage, ...additionalImagesMapper];

            let imageType = {};
            if (!!allImage && allImage.length > 0) {
                // setBikeStatus("REJECTED");
                bikeStatus = "REJECTED";
                for (let i = 0; i < allImage.length; i++) {
                    imageType = {};
                    imageType.hashKey = allImage[i].hashKey;
                    imageType.rejected = true;
                    arrayImage.push(imageType);
                }
            }
        }
        catch (e) {

        }
        return arrayImage;
    };

    const onTextAreaChange = ({ target }) => {
        const { value } = target;
        setReason(value);
        // resetInspectionDetailByKeyConnect("rejectionRemark", value);
    };

    const renderCjRemarks = () => {
        const cjRemarksValue = cjRemark && cjRemark.split('\\n');
        if(cjRemarksValue && cjRemarksValue.length > 1) {
            return cjRemarksValue.map(cjItem => (
                <span className={styles.listRow}>{cjItem}</span>
            ));
        } 
        return cjRemarksValue;
    };

    return (
        <>
            <div className={styles.inspectionReviewFooter}>
                {cjRemark && (
                    <div className={styles.cjRemarks}>
                        <h3>CJ Remark</h3>
                        <p>{renderCjRemarks()}</p>
                    </div>
                )}
                <div className={styles.comments}>
                    <h3>Comments</h3>
                    <textarea onChange={onTextAreaChange} value={reason}></textarea>
                </div>
                {(versionViewMode && email === qcUser) &&
                    <div className={styles.footer}>
                        <div className={styles.ctaWrapper}>
                            {/* <Button ctaText="Reject" classNames="secondaryButton" disabled={isLoading} onClick={() => SubmitStatus("REJECTED")} /> */}
                            {/* <Button ctaText="submit" classNames="primaryButton" disabled={isLoading} onClick={() => SubmitStatus("APPROVED")} /> */}
                            <Button ctaText="submit" classNames="primaryButton" disabled={isLoading} onClick={onPopupOpen} />
                        </div>
                        {isLoading &&
                            <div className={styles.loader}>
                                <img src={Loader} alt="loader" />
                            </div>
                        }
                    </div>
                }
            </div>
            {
                <Popup isOpen={popupOpen} close={onPopupClose}>
                    <InspectionConfirmationPopup onClose={onPopupClose} />
                </Popup>
            }
        </>
    );
};

export default InspectionReviewFooterBike;

import React from 'react';
import ThankyouImage1 from './images/thankyou1.svg';
import ThankyouImage2 from './images/thankyou2.svg';
import styles from "./thank-you.module.css";
import Evaluator2 from './images/evaluator2.svg';
import Evaluator3 from './images/evaluator3.svg';
import CallIcon from './images/call.svg';
import {SELL_ONLINE_STATUS} from '../../../utils/app-constants';

const ThakYouPage = ({status}) => {
   
    const mainIcon = {
        approved: ThankyouImage2,
        rejected: ThankyouImage1
    }; 
    const description = {
        approved: "Our Retail Associate will get in touch with you",
        rejected: "Meanwhile your feedback is sent to our evaluator for re-inspection"
    };
    const evaluatorIcon = {
        approved: Evaluator3,
        rejected: Evaluator2
    }; 
    const evaluatorText = {
        approved: 'CARS24 Retail Associate',
        rejected: 'CARS24 Car Evaluator'
    };
    return (
        <div>
            <div className={styles.imageSection}>
                <img src={mainIcon[status]} />
            </div>
            <h1 className={styles.heading}>Thanks for your Response!</h1>
            <p className={styles.descroption}>{description[status]}</p>
        </div>
    );
};

export default ThakYouPage;
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CarVariantLeftPanel from "./component";
import { fetchManufacturingYears, fetchCarModelList } from '../../pages/add-car-variant/actions';
const mapStateToProps = ({carVariantReducer}) => ({carVariantReducer});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchManufacturingYears,
    fetchCarModelList
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CarVariantLeftPanel);

import React, { useState, useEffect } from "react";
import CarVariantHeader from "../../components/car-variant-header";
import CarVariantLeftPanel from "../../components/car-variant-left-panel";
import CarVariantWidgetPanel from "../../components/car-variant-widget-panel";
import styles from "./car-variant.module.css";
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { convertKeyIntoString } from '../../utils/helper';

const VARIANT_COLUMNS = {
    VARIANT_ID: "variantId",
    VARIANT_NAME: "variant"
};
const CarVariant = (props) => {
    const {
        fetchCarVariantParameterDataList,
        fetchCarMakeList,
        location: {state : currentVariantData},
        carVariantReducer
    } = props || {};
    const isEditMode = !!currentVariantData;
    const {carVariantParameterList} = carVariantReducer || {};
    const {editVariantData, modelData} = isEditMode ? currentVariantData : {};
    const dropBoxeInitialState = [[{accepts: "parameter", lastDroppedItem: null, value: ''}]];
    
    const commonParameterValue = {
        model_id: 'model_id',
        modelName: 'modelName',
        fuelType: 'fuelType',
        manufacturingYears: 'manufacturingYears',
    };
    const carVariantInitialState = {
        model_id: isEditMode ? editVariantData[commonParameterValue.model_id] : '',
        modelName: isEditMode && !!modelData.label ? modelData.label : '',
        fuelType: isEditMode ? editVariantData[commonParameterValue.fuelType] : '',
        manufacturingYears: isEditMode ? editVariantData[commonParameterValue.manufacturingYears] : '',
        variantParameters: [{}]
    };

    const [isReset, setIsReset] = useState(false);
    const [dropBoxes, setdropBoxes] = useState(dropBoxeInitialState || {});
    const [carVariants, setCarVariants] = useState(carVariantInitialState);
    const [errorList, setErrorList] = useState([]);
    const nonEmptyVariantParams = !!editVariantData && Object.keys(editVariantData).filter(item => editVariantData[item] !== "");
    const editVariantParams = !!nonEmptyVariantParams && !!carVariantParameterList && carVariantParameterList.filter(item => nonEmptyVariantParams.includes(item.name));
    useEffect(()=> {
        if (!!editVariantParams && editVariantParams.length > 0) {
            let variantBoxes = [...dropBoxes];
            const variantParameters = JSON.parse(JSON.stringify(dropBoxes[0]));
            let paramIndex = 0;
            const variantParametersData = JSON.parse(JSON.stringify(carVariants.variantParameters));
            variantParametersData[0] = {...variantParametersData[0], [VARIANT_COLUMNS.VARIANT_ID]: editVariantData[VARIANT_COLUMNS.VARIANT_ID]};
            variantParametersData[0] = {...variantParametersData[0], [VARIANT_COLUMNS.VARIANT_NAME]: editVariantData[VARIANT_COLUMNS.VARIANT_NAME]};
            for (var param of editVariantParams) {
                const {name: paramName, image = {}} = param;
                let paramValue = editVariantData[paramName];
                if(variantParameters[paramIndex].lastDroppedItem === null) {
                    variantParameters.push({ accepts: "parameter", lastDroppedItem: null, value: '' });
                }
                if (paramName === "bodyShapeType") {
                    const paramItem = !!image && image.filter(item => item.name === paramValue.split(':')[0])[0];
                    paramValue = `${paramValue}:${paramItem.url}`;
                }
                variantParameters[paramIndex].lastDroppedItem = param;
                variantParameters[paramIndex].value = paramValue;
                variantParametersData[0] = {...variantParametersData[0], [paramName]: paramValue};
                paramIndex++;
            }
            variantBoxes[0] = variantParameters;
            setCarVariants({...carVariants,  variantParameters: variantParametersData});
            setdropBoxes(variantBoxes);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[carVariantReducer]);

    useEffect(() => {
        fetchCarVariantParameterDataList();
        fetchCarMakeList();
    }, [fetchCarVariantParameterDataList, fetchCarMakeList]);

    const isParameterPresent = (parameterName, variantIndex = 0) => {
        return dropBoxes[variantIndex].filter(parameter => parameter.lastDroppedItem && parameter.lastDroppedItem.name === parameterName).length;
    };
    
    const handleDrop = (item, index, variantIndex) => {
        setIsReset(false);
        reSetErrorMessage('isParamereSelected', variantIndex);
        const boxesArray = [...dropBoxes];
        const variantParametersData = JSON.parse(JSON.stringify(carVariants.variantParameters));
        const boxes = JSON.parse(JSON.stringify(dropBoxes[variantIndex]));
        const { parameter } = item;
        let overrrideParameter;
        if(isParameterPresent(parameter.name, variantIndex)) {
            alert(`Parameter: ${convertKeyIntoString(parameter.name, ' ')} already added`);
            return;
        }
        if(boxes[index].lastDroppedItem === null) {
            boxes.push({ accepts: "parameter", lastDroppedItem: null, value: '' });
        } else {
            overrrideParameter =  boxes[index].lastDroppedItem.name;
            delete variantParametersData[variantIndex][overrrideParameter];
        }
        boxes[index].lastDroppedItem = parameter;
        
        variantParametersData[variantIndex] = {...variantParametersData[variantIndex], [parameter.name]: ''};
        setCarVariants({...carVariants,  variantParameters: variantParametersData});
        boxesArray[variantIndex] = boxes;
        setdropBoxes(boxesArray);
    };

    const updateCarVariantObject = (updatedValue, variantIndex) => {
        const carVariantsObj = JSON.parse(JSON.stringify(carVariants));
        setIsReset(false);
        reSetErrorMessage(Object.keys(updatedValue)[0], variantIndex);
        if(updatedValue.model_id && updatedValue.model_id !== carVariantsObj.model_id ) {
            delete carVariantsObj.manufacturingYears;
            delete carVariantsObj.variant;
            delete carVariantsObj.variantId;
        }
        if(commonParameterValue[Object.keys(updatedValue)[0]]) {
            setCarVariants({...carVariantsObj, ...updatedValue});
        } else {
            const variantParametersData = [...carVariants.variantParameters];
            variantParametersData[variantIndex] = {
                ...variantParametersData[variantIndex],
                ...updatedValue
            };
            setCarVariants({
                ...carVariantsObj, 
                variantParameters: variantParametersData
            });
        }
    };
    const resetCarVariantData = () => {
        setIsReset(true);
        setdropBoxes(dropBoxeInitialState);
        setCarVariants(carVariantInitialState);
    };

    const handleRemove = (index, key, variantIndex, removeCompleteVariant = false) => {
        const carVariantsItem = JSON.parse(JSON.stringify(carVariants));
        const boxes = JSON.parse(JSON.stringify(dropBoxes));
        
        if(removeCompleteVariant) {
            const errorListObject = [...errorList];
            errorListObject.splice(variantIndex, 1);
            boxes.splice(variantIndex, 1);
            carVariantsItem.variantParameters.splice(variantIndex, 1);
            setdropBoxes(boxes);
            setErrorList(errorListObject);
            setCarVariants(carVariantsItem);
            return;
        }

        delete carVariantsItem.variantParameters[variantIndex][key];
        boxes[variantIndex].splice(index, 1);
        setdropBoxes(boxes);
        setCarVariants(carVariantsItem);
    };

    const reSetErrorMessage = (key, index = 0) => {
        const errorListObject = [...errorList];
        if(errorListObject[index] && errorListObject[index][key]) {
            delete errorListObject[index][key];
            setErrorList(errorListObject);
        }
        
    };
    return (
        <div>
            <CarVariantHeader 
                {...props} 
                carVariants={carVariants}
                resetCarVariantData={resetCarVariantData}
                setErrorList={setErrorList}
                
            />
            <DndProvider backend={HTML5Backend}>
                <div className={styles.mainWrapper}>
                    <CarVariantLeftPanel 
                        dropBoxes={dropBoxes} 
                        setdropBoxes={setdropBoxes}
                        handleDrop={handleDrop} 
                        handleRemove={handleRemove}
                        updateCarVariantObject={updateCarVariantObject}
                        carVariants={carVariants}
                        setCarVariants={setCarVariants}
                        isReset={isReset}
                        errorList={errorList}
                        setErrorList={setErrorList}
                        reSetErrorMessage={reSetErrorMessage}
                        data={currentVariantData}
                        isEditMode={isEditMode}
                    />
                    <CarVariantWidgetPanel />
                </div>
            </DndProvider>
            
        </div>
    );
};

export default CarVariant;

import Types from "./types";

const resetCarouselByKey = (key, value) => ({
    type: Types.RESET_CAROUSEL_BY_KEY,
    data: { key, value }
});

export {
    resetCarouselByKey
};

import React from "react";
import './styles.css';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import RemarkHistory from "./component";
import {resetInspectionDetailByKey} from "../../../pages/inspection-review/actions";

const mapStateToProps = ({inspectionDetails}) => ({
    inspectionDetails
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    resetInspectionDetailByKeyConnect : resetInspectionDetailByKey
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(RemarkHistory);

import React from "react";
import H2 from "../h2";
import styles from "./something-went-wrong.module.css";
import somethingWentWrong from "./images/something-went-wrong.svg";
import {ERROR_MESSAGE} from "../../utils/app-constants";
const SomethingWentWrong = () => {
    return (
        <div className={styles.errorWrapper}>
            <div className={styles.imgWrapper}>
                <img src={somethingWentWrong} alt={ERROR_MESSAGE.SOMETHING_WENT_WRONG_HEADING} />
            </div>
            <div className={styles.errorTextWrapper}>
                <H2 text={ERROR_MESSAGE.SOMETHING_WENT_WRONG_HEADING} />
                <p className={styles.text}>{ERROR_MESSAGE.SOMETHING_WENT_WRONG_CONTENT}</p>
            </div>
        </div>
    );
};

export default SomethingWentWrong;

import { exteriorTyreRejectedFieldsWithSubPartNodeArr, editableFielKeysForExteriorInterior } from "../app-constants";

export const getExteriorTyreMainfieldObject = (editedArray) => {
    let rejected = {};
    let imageRatingArray = [];
    if (!!editedArray && editedArray.length > 0) {
        for (let i = 0; i < editedArray.length; i++) {
            let fieldObjectVal = {
                "hashKey": editedArray[i].hashKey,
                "title": editedArray[i].title,
                "url": editedArray[i].url,
                "message": "Rejected by qc.",
                "isValidate": 2
            };
            if (editedArray[i].isMaskedByQc) {
                // fieldObjectVal["originalUrl"] = !!editedArray[i].originalUrl ? editedArray[i].originalUrl : "";
                fieldObjectVal["isValidate"] = 5;
                fieldObjectVal["url"] = editedArray[i].oldUrl;
                fieldObjectVal["maskedBy"] = "QC";
            }
            imageRatingArray.push(fieldObjectVal);
        }
    }
    return imageRatingArray;
};
export const getInteriorExteriorPostQcEditablefieldObject = (interiorOdoDocs, odometerComments, editInteriorElecctricalsMapper) => {
    let rejected = {};
    let rejectedJsonObj = JSON.parse(JSON.stringify(rejected));
    if (!!interiorOdoDocs && interiorOdoDocs.length > 0) {
        for (let i = 0; i < interiorOdoDocs.length; i++) {
            let fieldObjectVal = {
                "title": interiorOdoDocs[i].text,
                "value": interiorOdoDocs[i].newValue,
                // "message": "Rejected by qc.",
                // "isValidate": 5
            };
            let jsonfieldObjectVal = JSON.parse(JSON.stringify(fieldObjectVal));
            getExteriorTyreMapperFieldObj(interiorOdoDocs[i].key, rejectedJsonObj, jsonfieldObjectVal);
        }
    }
    if (!!odometerComments) {
        // getExteriorTyreRejectedCommentObj(odometerComments, rejectedJsonObj);
        getInteriorCommentsRejectedObj(odometerComments, rejectedJsonObj, true);
    }

    if(editInteriorElecctricalsMapper && editInteriorElecctricalsMapper.length) {
        for(let i = 0; i < editInteriorElecctricalsMapper.length; i++) {
            if(editInteriorElecctricalsMapper[i].isEditable) {
                let fieldObjectVal = {
                    "title": editInteriorElecctricalsMapper[i].text,
                    "value": editInteriorElecctricalsMapper[i].newValue,
                };
                let jsonfieldObjectVal = JSON.parse(JSON.stringify(fieldObjectVal));
                getExteriorTyreMapperFieldObj(editInteriorElecctricalsMapper[i].key, rejectedJsonObj, jsonfieldObjectVal);
            }
        }
    }

    return rejectedJsonObj;
};
export const getExteriorTyresRejectedfieldObject = (editedArray, editedImageArray, frontRearTyreEditedImages, editExteriorComments, additionalImages, sectionKey, interiorOdoDocs, odometerComments, editInteriorElecctricalsMapper) => {

    let rejected = {};
    let interiorOdoMapper = !!interiorOdoDocs && interiorOdoDocs.length > 0 ? interiorOdoDocs : [];
    let finalMap = [...editedArray, ...interiorOdoMapper];

    let rejectedJsonObj = JSON.parse(JSON.stringify(rejected));

    if (!!finalMap && finalMap.length > 0) {
        for (let i = 0; i < finalMap.length; i++) {
            // let editableExistKeyIndex = editableFielKeysForExteriorInterior.indexOf(finalMap[i].key);
            let fieldObjectVal = {
                "title": finalMap[i].text,
                "value": finalMap[i].value,
                "message": "Rejected by qc.",
                "isValidate": (finalMap[i].key === "odometerReading") ? 5 : 2
            };
            let jsonfieldObjectVal = JSON.parse(JSON.stringify(fieldObjectVal));
            getExteriorTyreMapperFieldObj(finalMap[i].key, rejectedJsonObj, jsonfieldObjectVal);
            // rejectedJsonObj.mainImages = getExteriorTyreMainfieldObject(editedImageArray);
        }
    }
    if (!!editInteriorElecctricalsMapper && editInteriorElecctricalsMapper.length > 0) {
        for(let i = 0; i < editInteriorElecctricalsMapper.length; i++) {
            let fieldObjectVal = {
                "title": editInteriorElecctricalsMapper[i].text,
                "value": editInteriorElecctricalsMapper[i].value,
                "message": "Rejected by qc.",
                "isValidate": editInteriorElecctricalsMapper[i].key === "fuelLevel" ? 5 : 2
            };
            if (editInteriorElecctricalsMapper[i].hasOwnProperty('additionalInfo')) {
                if (!!editInteriorElecctricalsMapper[i].additionalInfo) {
                    fieldObjectVal.additionalInfo = JSON.parse(editInteriorElecctricalsMapper[i].additionalInfo);
                }
            }
            rejectedJsonObj[editInteriorElecctricalsMapper[i].key] = fieldObjectVal;
        }
    }
    // these comments valid for the exterior comments
    if (!!editExteriorComments && editExteriorComments.checked) {
        getExteriorTyreRejectedCommentObj(editExteriorComments, rejectedJsonObj);
    }
    //these comments for the internal
    if (!!odometerComments) {
        // getExteriorTyreRejectedCommentObj(odometerComments, rejectedJsonObj);
        getInteriorCommentsRejectedObj(odometerComments, rejectedJsonObj, false);
    }
    if (!!editedImageArray && editedImageArray.length > 0) {
        rejectedJsonObj.mainImages = getExteriorTyreMainfieldObject(editedImageArray);
    }
    // if (!!editedImageArray && editedImageArray.length > 0) {
    //     rejectedJsonObj.mainImages = getExteriorTyreMainfieldObject(editedImageArray);
    // }
    if (!!frontRearTyreEditedImages && frontRearTyreEditedImages.length > 0) {
        // rejectedJsonObj.mainImages = getExteriorTyreFrontRearfieldObject(rejectedJsonObj, frontRearTyreEditedImages);
        rejectedJsonObj = getExteriorTyreFrontRearfieldObject(rejectedJsonObj, frontRearTyreEditedImages);
    }

    ////////////////// rejected section for additional Images ///////////////////////
    // lights section

    // end light section

    if (!!additionalImages && additionalImages.length > 0) {
        // let additionalImagesforAdditionalInfo = additionalImages.filter(x => x.checked === false && x.section === "exteriorTyres");
        let additionalImagesforAdditionalInfo = additionalImages.filter(x => x.checked === true && x.section === sectionKey);

        if (!!additionalImagesforAdditionalInfo && additionalImagesforAdditionalInfo.length > 0) {

            let arrayList = exteriorTyreRejectedFieldsWithSubPartNodeArr;

            // this is the case of where there is no subparts nodes // we can maintain array
            for (let i = 0; i < additionalImagesforAdditionalInfo.length; i++) {
                let withSubPartKeyIndex = arrayList.indexOf(additionalImagesforAdditionalInfo[i].key);
                if (withSubPartKeyIndex < 0) {
                    let fieldObjectVal = {
                        "title": additionalImagesforAdditionalInfo[i].title,
                        "value": additionalImagesforAdditionalInfo[i].value,
                        "message": "Rejected by qc.",
                        "isValidate": additionalImagesforAdditionalInfo[i].isMaskedByQc ? 5 : 2,
                        "additionalInfo": getAdditionalInfoForExteriorTyre(additionalImagesforAdditionalInfo[i])
                    };
                    let jsonfieldObjectVal = JSON.parse(JSON.stringify(fieldObjectVal));
                    rejectedJsonObj[additionalImagesforAdditionalInfo[i].key] = jsonfieldObjectVal;
                }
            }
            // End this is the case of where there is no subparts nodes // we can maintain array

            //Here we will write code that there is subparts exists in the fields
            let subPartsDone = [];
            for (let i = 0; i < additionalImagesforAdditionalInfo.length; i++) {
                let withSubPartKeyIndex = arrayList.indexOf(additionalImagesforAdditionalInfo[i].key);
                if (withSubPartKeyIndex >= 0) {
                    let isAdded = false;
                    if (subPartsDone.find(item => item === additionalImagesforAdditionalInfo[i].key)) {
                        isAdded = true;
                    }
                    if (!isAdded) {
                        subPartsDone.push(additionalImagesforAdditionalInfo[i].key);
                        let fieldObjectVal = {
                            "title": additionalImagesforAdditionalInfo[i].title,
                            "value": additionalImagesforAdditionalInfo[i].value,
                            "message": "Rejected by qc.",
                            "isValidate": additionalImagesforAdditionalInfo[i].isMaskedByQc ? 5 : 2,
                            // "subParts": getImagesWithSubPartsForExteriorTyre(additionalImagesforAdditionalInfo[i].key, additionalImagesforAdditionalInfo)
                            "subParts": getImagesWithSubPartsForExteriorTyre(additionalImagesforAdditionalInfo[i].key, additionalImagesforAdditionalInfo)
                        };
                        let jsonfieldObjectVal = JSON.parse(JSON.stringify(fieldObjectVal));
                        rejectedJsonObj[additionalImagesforAdditionalInfo[i].key] = jsonfieldObjectVal;
                    }
                }
            }
            //End Here we will write code that there is subparts exists in the fields
        }
    }
    ////////////////// end rejected section for additional Images ///////////////////
    return rejectedJsonObj;
};
export const getImagesWithSubPartsForExteriorTyre = (key, additionalImagesforsubParts) => {
    let returnObj = {};
    let subPartArray = additionalImagesforsubParts.filter(x => x.key === key);
    if (!!subPartArray && subPartArray.length > 0) {
        subPartArray.forEach(element => {
            let additionalInfoArray = [];
            let newElement = {};//Object.assign({}, element);//Object.assign(element);
            let subPartKey = element.subPart_key;
            newElement.message = "Rejected by qc.";
            newElement.isValidate = 2;
            newElement.title = element.subPart_title;
            newElement.value = element.subPart_value;
            newElement.additionalInfo = getAdditionalInfoForSubParts(element);
            returnObj[subPartKey] = newElement;
        });
    }
    return returnObj;
};
export const getAdditionalInfoForSubParts = (objsubPartData) => {
    let newElement = Object.assign({}, objsubPartData);
    let additionalInfoArray = [];
    if (newElement.hasOwnProperty('title')) { delete newElement.title; }
    if (newElement.hasOwnProperty('value')) { delete newElement.value; }
    if (newElement.hasOwnProperty('section')) { delete newElement.section; }
    if (newElement.hasOwnProperty('checked')) { delete newElement.checked; }
    if (newElement.hasOwnProperty('index')) { delete newElement.index; }
    if (newElement.hasOwnProperty('key')) { delete newElement.key; }
    if (newElement.hasOwnProperty('subPart_key')) { delete newElement.subPart_key; }
    if (newElement.hasOwnProperty('subPart_title')) { delete newElement.subPart_title; }
    if (newElement.hasOwnProperty('subPart_value')) { delete newElement.subPart_value; }

    additionalInfoArray.push(newElement);
    return additionalInfoArray;

};
export const getAdditionalInfoForExteriorTyre = (objAdditionalInfoData) => {
    let additionalInfoArray = [];
    // let returnObj = objAdditionalInfoData;
    let returnObj = Object.assign({}, objAdditionalInfoData);
    if (returnObj.isMaskedByQc) {
        if (!!returnObj["image"] && !!returnObj["image"]["oldUrl"]) {
            returnObj["image"]["url"] = returnObj["image"]["oldUrl"];
        }
    }
    if (returnObj.hasOwnProperty('checked')) { delete returnObj.checked; }
    // if (returnObj.hasOwnProperty('key')) { delete returnObj.key; }
    if (returnObj.hasOwnProperty('section')) { delete returnObj.section; }//delete returnObj.section;
    if (returnObj.hasOwnProperty('title')) { delete returnObj.title; }//delete returnObj.title;
    if (returnObj.hasOwnProperty('value')) { delete returnObj.value; }//delete returnObj.value;
    if (returnObj.hasOwnProperty('index')) { delete returnObj.index; }//delete returnObj.index;
    additionalInfoArray.push(returnObj);
    return additionalInfoArray;
};

export const getExteriorTyreRejectedCommentObj = (editExteriorComments, rejectedJsonObj) => {
    rejectedJsonObj.comments = {};
    rejectedJsonObj.comments.title = editExteriorComments.title;
    rejectedJsonObj.comments.value = editExteriorComments.value;
    rejectedJsonObj.comments.message = "Rejected by qc.";
    rejectedJsonObj.comments.isValidate = 2;

};
export const getInteriorCommentsRejectedObj = (editedArray, rejectedJsonObj, qcEdit) => {
    let rejected = {};
    let commentsArray = [];
    let isNodeAdded = false;
    let nodeAdded = false;

    let mismatchRjrejected = editedArray.filter(x => x.isImportant === true);
    let mismatchQCrejected = editedArray.filter(x => x.checked === true);
    if ((!!mismatchQCrejected && mismatchQCrejected.length > 0)) {
        if (!!mismatchRjrejected && mismatchRjrejected.length > 0) {
            if (JSON.stringify(mismatchQCrejected) !== JSON.stringify(mismatchRjrejected)) {
                nodeAdded = true;
            }
        }
        else {
            nodeAdded = true;
        }
    }

    if (nodeAdded) {
        let cjRejected = editedArray.filter(x => x.checked === true);
        if (!qcEdit) {
            cjRejected = editedArray.filter(x => x.isImportant === true);
        }
        // let qcRejected = editedArray.filter(x => x.isImportant === false && x.checked === true);
        if (!!cjRejected && cjRejected.length > 0) {
            isNodeAdded = true;
            for (let i = 0; i < cjRejected.length; i++) {
                let fieldObjectVal = {
                    "comment": cjRejected[i].comment,
                    "id": cjRejected[i].id,
                    "rank": !!cjRejected[i].rank ? cjRejected[i].rank : 0,
                    "type": !!cjRejected[i].type ? cjRejected[i].type : "Comments"
                };
                commentsArray.push(fieldObjectVal);
            }
        }
    }
    rejectedJsonObj.comments = {};
    rejectedJsonObj.comments.title = "comments";
    rejectedJsonObj.comments.value = commentsArray;
    if (!qcEdit) {
        rejectedJsonObj.comments.message = "Rejected by qc.";
        rejectedJsonObj.comments.isValidate = 5;
    }
    if (!!commentsArray && commentsArray.length === 0) {
        if (!nodeAdded) {
            delete rejectedJsonObj.comments;
        }
    }

};
export const getExteriorTyreFrontRearfieldObject = (rejectedJsonObj, editedArray) => {
    for (let i = 0; i < editedArray.length; i++) {
        let fieldObjectVal = {
            "title": editedArray[i].mainTitle,
            "value": editedArray[i].newValue,
            "message": "Rejected by qc.",
            "isValidate": 2
        };
        let jsonfieldObjectVal = JSON.parse(JSON.stringify(fieldObjectVal));
        // getExteriorTyreMapperFieldObj(editedArray[i].title, rejectedJsonObj, jsonfieldObjectVal);
        if (editedArray[i].title === "Lhs front tyre") {
            rejectedJsonObj.lhsFrontTyre = jsonfieldObjectVal;
            rejectedJsonObj.lhsFrontTyre.additionalInfo = GetExterionTyreAdditionalInfoObject(editedArray[i]);
        }
        if (editedArray[i].title === "Lhs rear tyre") {
            rejectedJsonObj.lhsRearTyre = jsonfieldObjectVal;
            rejectedJsonObj.lhsRearTyre.additionalInfo = GetExterionTyreAdditionalInfoObject(editedArray[i]);
        }
        if (editedArray[i].title === "Rhs front tyre") {
            rejectedJsonObj.rhsFrontTyre = jsonfieldObjectVal;
            rejectedJsonObj.rhsFrontTyre.additionalInfo = GetExterionTyreAdditionalInfoObject(editedArray[i]);
        }
        if (editedArray[i].title === "Rhs rear tyre") {
            rejectedJsonObj.rhsRearTyre = jsonfieldObjectVal;
            rejectedJsonObj.rhsRearTyre.additionalInfo = GetExterionTyreAdditionalInfoObject(editedArray[i]);
        }
        if (editedArray[i].title === "Spare tyre") {
            rejectedJsonObj.spareTyre = jsonfieldObjectVal;
            rejectedJsonObj.spareTyre.additionalInfo = GetExterionTyreAdditionalInfoObject(editedArray[i]);
        }
        // rejectedJsonObj.additionalInfo = {};
        // let newAdditionalDataforPost = [];
        // let additionalInfoObject;

        // let additionalInfoInnerObject = {};
        // let imageObject = {
        //     "hashKey": editedArray[i].hashKey,
        //     "title": editedArray[i].title,
        //     "url": editedArray[i].url,
        //     "pointerCount": 0,
        //     "imageUploadTime": ""
        // };
        // additionalInfoInnerObject.image = imageObject;
        // additionalInfoInnerObject.hashKey = editedArray[i].hashKey;
        // let currentCondition = {
        //     "title": "Current Condition",
        //     "value": "",
        // };
        // additionalInfoInnerObject.currentCondition = currentCondition;
        // let treadDepth = {
        //     "title": "Trad Depth",
        //     "value": "",
        // };
        // additionalInfoInnerObject.treadDepth = treadDepth;

        // newAdditionalDataforPost.push(additionalInfoInnerObject);
        // rejectedJsonObj.additionalInfo = newAdditionalDataforPost;
        // rejectedJsonObj.mainImages = getExteriorTyreMainfieldObject(editedImageArray);
    }
    return rejectedJsonObj;
};
export const GetExterionTyreAdditionalInfoObject = (lhsRhsImg) => {
    // rejectedJsonObj.additionalInfo = {};
    let newAdditionalDataforPost = [];
    let additionalInfoObject;
    let additionalInfoInnerObject = {};
    let imageObject = {
        "hashKey": lhsRhsImg.hashKey,
        "title": lhsRhsImg.imageTitle,
        "url": lhsRhsImg.url,
        "pointerCount": 0,
        "imageUploadTime": ""
    };
    additionalInfoInnerObject.image = imageObject;
    additionalInfoInnerObject.image.serverTime = lhsRhsImg.serverTime;
    additionalInfoInnerObject.hashKey = lhsRhsImg.hashKey;
    let currentCondition = {
        "title": "Current Condition",
        "value": "",
    };
    additionalInfoInnerObject.currentCondition = lhsRhsImg.currentCondition;//currentCondition;
    let treadDepth = {
        "title": "Trad Depth",
        "value": "",
    };
    additionalInfoInnerObject.treadDepth = lhsRhsImg.treadDepth;//treadDepth;

    newAdditionalDataforPost.push(additionalInfoInnerObject);
    return newAdditionalDataforPost;

};
export const getExteriorTyreMapperFieldObj = (key, rejectedJsonObj, carObject) => {
    if (key === "Lhs front tyre") {
        return rejectedJsonObj.lhsFrontTyre = carObject;
    }
    if (key === "Lhs rear tyre") {
        return rejectedJsonObj.lhsRearTyre = carObject;
    }
    if (key === "Rhs front tyre") {
        return rejectedJsonObj.rhsFrontTyre = carObject;
    }
    if (key === "Rhs rear tyre") {
        return rejectedJsonObj.rhsRearTyre = carObject;
    }
    if (key === "Spare tyre Additional") {
        return rejectedJsonObj.spareTyre = carObject;
    }
    if (key === "alloyWheels") {
        return rejectedJsonObj.alloyWheels = carObject;
    }
    if (key === "steeringMountedAudioControls") {
        return rejectedJsonObj.steeringMountedAudioControls = carObject;
    }
    if (key === "musicSystem") {
        return rejectedJsonObj.musicSystem = carObject;
    }
    if (key === "reverseCamera") {
        return rejectedJsonObj.reverseCamera = carObject;
    }
    if (key === "leatherSeats") {
        return rejectedJsonObj.leatherSeats = carObject;
    }
    if (key === "fabricSeats") {
        return rejectedJsonObj.fabricSeats = carObject;
    }

    if (key === "sunroof") {
        return rejectedJsonObj.sunroof = carObject;
    }
    if (key === "abs") {
        return rejectedJsonObj.abs = carObject;
    }
    if (key === "rearDefogger") {
        return rejectedJsonObj.rearDefogger = carObject;
    }
    if (key === "powerWindows") {
        return rejectedJsonObj.powerWindows = carObject;
    }
    if (key === "powerWindows") {
        return rejectedJsonObj.powerWindows = carObject;
    }
    if (key === "airbagFeature") {
        return rejectedJsonObj.airbagFeature = carObject;
    }
    if (key === "numberOfAirbags") {
        return rejectedJsonObj.numberOfAirbags = carObject;
    }
    if (key === "powerWindowsNumber") {
        return rejectedJsonObj.powerWindowsNumber = carObject;
    }
    if (key === "odometerReading") {
        return rejectedJsonObj.odometerReading = carObject;
    }
    if (key === "fuelLevel") {
        return rejectedJsonObj.fuelLevel = carObject;
    }
};
import React, {useEffect, useState} from "react";
import {InspectionService} from "../../service";
import {RESET_REDUCER_KEYS} from "../../utils/app-constants";
import SelectComponent from "../shared/select";

const RegistrationCity = ({detail,resetInspectionDetailByKeyConnect,inspectionDetails}) => {
    const [cityDropDown,setCityDropDown] = useState([]);
    const [selectedCity,setSelectedCity] = useState("");
    const {documentsMapper}= inspectionDetails;
    const {key} = detail;
    const selectedState = documentsMapper.find(item=>item.key === "registrationState").newValue;

    useEffect(()=>{
        let stateId = documentsMapper.find(item=>item.key === "registrationState");
        if(stateId) {
            stateId = stateId.newId ? stateId.newId : stateId.id;
            InspectionService.getCityList(stateId)
                .then(res => {
                    const {data: {detail}} = res;
                    if (detail.length > 0) {
                        const dropdown = detail.map(city => {
                            return {
                                label: city.city_name,
                                value: city.city_id
                            };
                        });
                        setCityDropDown(dropdown);
                    }

                })
                .catch(err => {

                });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[JSON.stringify(documentsMapper)]);

    useEffect(()=>{
        if(selectedCity){
            setNewValue();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[selectedCity]);

    useEffect(()=>{
        return ()=>{
            // resetToInitialValue();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const resetToInitialValue=()=>{
        let mapper = [...documentsMapper];
        const index = mapper.findIndex(item=>item.key === key);
        let newValue = "";
        let newId = "";
        mapper = mapper.map(map => {
            let element = { ...map };
            if (map.key === key) {
                element.newValue = newValue;
                element.newId = newId;
                element.checked = false;
            }

            return element;
        });
        resetInspectionDetailByKeyConnect(RESET_REDUCER_KEYS.DOCUMENTS_MAPPER, mapper);
    };

    const onCityChange = (value)=>{
        setSelectedCity(value);
    };

    const setNewValue = ()=>{
        let mapper = [...documentsMapper];
        const newValue = selectedCity;
        let dependentFields = [];
        mapper = mapper.map(map => {
            let element = { ...map };
            if (map.key === key) {
                element.newValue = cityDropDown.find(city=>city.value === newValue).label;
                element.newId = newValue;
                // if(element.dependentFields && element.dependentFields.length >0){
                //     dependentFields = element.dependentFields;
                // }
            }

            return element;
        });

        resetInspectionDetailByKeyConnect(RESET_REDUCER_KEYS.DOCUMENTS_MAPPER, mapper);
    };

    return (
        <div>
            <SelectComponent
                placeholder="City"
                optionsList={cityDropDown}
                onChange={onCityChange}
                name={selectedState}
            />
        </div>
    );
};

export default RegistrationCity;

import { createTypes } from "reduxsauce";

export default createTypes(
    `
    FETCH_BIKE_INSPECTIONS_LIST
    FETCH_BIKE_INSPECTIONS_LIST_SUCCESS
    FETCH_BIKE_INSPECTIONS_LIST_FAILURE
    RESET_BIKE_INSPECTIONS_LIST
    RESET_BIKE_INSPECTION_BY_KEY
`,
    {
        prefix: "inspection-bike/"
    }
);

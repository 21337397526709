import React from "react";
import Button from "../../components/shared/button";

import styles from "./list-header.module.css";

const ListViewHeader = ({history}) => {

    const goBackHandler = () => history.push('/');

    return (
        <div className={styles.carVariantHeader}>
            <div className={styles.goBack} onClick={goBackHandler}>Go Back</div>
        </div>
    
    );
};

export default ListViewHeader;
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import InspectionReviewFooter from "./component";
import { resetInspectionDetailByKey } from "../../pages/inspection-review/actions";
import { submitReview } from "./actions";

const mapStateToProps = ({ inspectionDetails }) => ({
    inspectionDetails
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    resetInspectionDetailByKeyConnect: resetInspectionDetailByKey,
    submitReviewConnect: submitReview
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(InspectionReviewFooter);

import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Inspection from "./component";
import { fetcInspectionList, resetInspectionByKey, AssignToMe } from "./actions";
import {resetInspectionDetailByKey} from "../inspection-review/actions";

const mapStateToProps = ({
    inspectionsReducer,
    inspectionFiltersReducer,
    search
}) => ({
    inspectionsReducer, //combined reducer
    inspectionFiltersReducer, //combined reducer
    search //combined reducer
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetcInspectionListConnect: fetcInspectionList,
    resetInspectionByKeyConnect: resetInspectionByKey,
    resetInspectionDetailByKeyConnect:resetInspectionDetailByKey,
    AssignToMeConnect: AssignToMe
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Inspection);

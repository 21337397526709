import React, { useEffect, useState } from "react";
import SelectComponent from "../shared/select";
import { INSURANCE_TYPE, RESET_REDUCER_KEYS, BIKE_DETAIL_KEYS } from "../../utils/app-constants";
import { isUserRoleBIkeQc } from "../../utils/helper";

const InsuranceType = ({ detail, inspectionDetails, resetInspectionDetailByKeyConnect, bikeInspectionDetails, resetInspectionBikeDetailByKeyConnect }) => {
    const [selectedValue, setSelectedValue] = useState("");
    const { key } = detail;
    const { insuranceMapper } = inspectionDetails;
    let isBikeQc = isUserRoleBIkeQc();
    const { bikeDocumentsMapper } = bikeInspectionDetails;

    useEffect(() => {
        if (selectedValue) {
            setNewValue();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedValue]);

    useEffect(() => {
        return () => {
            // resetToInitialValue();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const resetToInitialValue = () => {
        let mapper = isBikeQc ? [...bikeDocumentsMapper] : [...insuranceMapper];
        let newValue = "";
        let newId = "";
        mapper = mapper.map(map => {
            let element = { ...map };
            if (map.key === key) {
                element.newValue = newValue;
                element.newId = newId;
                element.checked = false;
            }

            return element;
        });
        if (isBikeQc) {
            resetInspectionBikeDetailByKeyConnect(RESET_REDUCER_KEYS.BIKE_DOCUMENTS_MAPPER, mapper);
        } else {
            resetInspectionDetailByKeyConnect(RESET_REDUCER_KEYS.INSURANCE_MAPPER, mapper);
        }
    };

    const setNewValue = () => {
        let mapper = isBikeQc ? [...bikeDocumentsMapper] : [...insuranceMapper];
        const newValue = selectedValue;
        const keyIndex = mapper.findIndex(item => item.key === key);
        mapper[keyIndex].newValue = newValue;
        mapper[keyIndex].newId = newValue;

        if (isBikeQc) {
            const depenDentkeyIndex = mapper.findIndex(item => item.key === BIKE_DETAIL_KEYS.Insurance_Expiry);
            if (newValue === "Insurance Expired") {
                mapper[depenDentkeyIndex].isVisible = false;
                mapper[depenDentkeyIndex].checked = false;
            }
            else {
                mapper[depenDentkeyIndex].isVisible = true;
                mapper[depenDentkeyIndex].checked = true;
            }
        }

        if (isBikeQc) {
            resetInspectionBikeDetailByKeyConnect(RESET_REDUCER_KEYS.BIKE_DOCUMENTS_MAPPER, mapper);
        } else {
            resetInspectionDetailByKeyConnect(RESET_REDUCER_KEYS.INSURANCE_MAPPER, mapper);
        }
    };

    const onSelect = (value) => {
        setSelectedValue(value);
    };

    return (
        <div>
            <SelectComponent
                optionsList={INSURANCE_TYPE}
                placeholder={detail.text}
                onChange={onSelect}
            />
        </div>
    );
};

export default InsuranceType;

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CarVariantListView from './component';
import { 
    fetchCarModelList,
    fetchCarMakeList,
    fetchManufacturingYears,
} from '../add-car-variant/actions';

const mapStateToProps = ({carVariantReducer}) => ({
    carVariantReducer
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchCarModelList,
    fetchManufacturingYears,
    fetchCarMakeList
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CarVariantListView);
import React, { useEffect } from "react";
import ReInspection from "../../components/re-inspection";
import InspectionHeader from "../../components/inspection-header";
import styles from "./reinspection-page.module.css";
import { isAuthenticated, GetQueryString } from "../../utils/helper";
import MakeModelVariantYear from "../../components/make-model-variant-year";
import Loader from '../../components/loader';

const ReinspectionPage = ({ inspectionDetails, match, history }) => {
    const { appointmentId, isLoading } = inspectionDetails;
    const { params: { carId } } = match;
    let qsVersion = GetQueryString("version");
    useEffect(() => {
        const isAuth = isAuthenticated();
        if (!isAuth) {
            history.push(`/login?carId=${carId}?version=${qsVersion}`);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            {isLoading && <Loader />}
            <div className={styles.headerReinspection}>
                <p className={styles.inspectionId}>{appointmentId}</p>
            </div>
            <div className={styles.carName}>
                <MakeModelVariantYear />
            </div>
            <ReInspection match={match} />
        </div>
    );
};

export default ReinspectionPage;

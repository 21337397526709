import React from "react";
import styles from "./inspection-review-info.module.css";
import InspectionReviewCarInfo from "../../components/inspection-review-car-info/";
import MakeModelVariantYear from "../../components/make-model-variant-year";
import Button from "../../components/shared/button";
import InspectionReviewBikeInfo from "../../components/inspection-review-bike-info/";
import { isUserRoleBIkeQc } from "../../utils/helper";

const InspectionReviewInfo = ({ inputEl, docRef, intRef, sumRef, versionViewMode = true }) => {
    let isBikeQc = isUserRoleBIkeQc();
    return (
        <>
            <div className={styles.inspectionReviewInfo}>
                {!isBikeQc && <InspectionReviewCarInfo inputEl={inputEl} docRef={docRef} intRef={intRef} sumRef={sumRef} />}
                {isBikeQc && <InspectionReviewBikeInfo versionViewMode={versionViewMode} inputEl={inputEl} docRef={docRef} intRef={intRef} sumRef={sumRef} />}
                <div className={styles.filterSection}>
                    {/* <Button classNames="primaryButton" ctaText={"save changes"} /> */}
                </div>
            </div>

        </>
    );
};

export default InspectionReviewInfo;

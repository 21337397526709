import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import InspectionVersion from "./component";
import {fetcInspectionVersionList,resetInspectionVersionByKey} from "./actions";
import {resetInspectionDetailByKey} from "../inspection-review/actions";
import {resetSearchByKey} from "../../components/shared/search/actions";

const mapStateToProps = ({search,inspectionVersion}) => ({
    search,
    inspectionVersion
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetcInspectionVersionListConnect : fetcInspectionVersionList,
    resetInspectionDetailByKeyConnect : resetInspectionDetailByKey,
    resetInspectionVersionByKeyConnect: resetInspectionVersionByKey,
    resetSearchByKeyConnect : resetSearchByKey
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(InspectionVersion);

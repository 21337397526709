import { createTypes } from "reduxsauce";

export default createTypes(
    `
    RESET_CAROUSEL_BY_KEY
`,
    {
        prefix: "carousel/"
    }
);

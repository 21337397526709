import React from 'react';
import styles from './styles.module.css';

const Date = ({
  id,
  value,
  className = '',
  name,
  onChange = () => {},
  ...restProps
}) => {

  const handleChange = (e) => {
    onChange(e.target.value);
  };

  return (
    <input
      id={id}
      type="date"
      name={name}
      className={`${className} ${styles.date}`}
      value={value}
      onChange={handleChange}
      {...restProps}
    />
  )
}

export default Date
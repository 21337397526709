import React from "react";
import styles from "./make-model-variant-year.module.css";
import SelectComponent from "../shared/select/component";

const MakeModelVariantYear = ({inspectionDetails}) => {
    const {makeModelVariantMapper} = inspectionDetails;
    return (
        <div className={styles.makeModelVariantYear}>
            {
                makeModelVariantMapper.map(item=>{
                    if(!item.key.match(/scrap/i))
                        return <p>{item.value}</p>;
                })
            }
        </div>
    );
};

export default MakeModelVariantYear;

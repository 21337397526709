import React, {useEffect, useState} from "react";
import SelectComponent from "../shared/select";
import {RESET_REDUCER_KEYS, YES_NO} from "../../utils/app-constants";
import { InspectionService } from "../../service";

const RtoNocIssued = ({detail,resetInspectionDetailByKeyConnect, inspectionDetails}) => {

    const [nocIssuedList, setNocIssuedList] = useState([]);
    const [selectedValue, setSelectedValue] = useState("");
    const { key } = detail;
    const { documentsMapper } = inspectionDetails;
   
    useEffect(() => {
        InspectionService.getNocIssuedForList()
            .then(res => {
                const { data: { detail } } = res;
                if (detail.length > 0) {
                    const dropdown = detail.map(x => {
                        const dropdownValue = formatLabel(x?.rtoCode, x?.rto);
                        return {
                            label: dropdownValue,
                            value: dropdownValue,
                        };
                    });
                    setNocIssuedList(dropdown);
                }

            })
            .catch(err => {

            });
    }, []);

    useEffect(() => {
        if (selectedValue) {
            setNewValue();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedValue]);

    const formatLabel =(rtoCode,rto)=> {
        const rtoName = rto?.charAt(0)?.toUpperCase() + rto?.slice(1)?.toLowerCase() || "";
        const rtoInitials= rtoCode?.split('-')[0] || ""
        return rtoCode + " " + rtoName + " - " + rtoInitials;
    }

    const setNewValue = () => {
        let mapper = [...documentsMapper];
        const newValue = selectedValue;
        let dependentFields = [];
        mapper = mapper.map(map => {
            let element = { ...map };
            if (map.key === key) {
                let newSelectedVal = "";
                let objNewSeleted = nocIssuedList.find(x => x.value === newValue);
                if (!!objNewSeleted) {
                    newSelectedVal = objNewSeleted.label;
                }
                element.newValue = newSelectedVal;
                element.newId = newValue === -1 ? 0 : newValue;
                // element.checked = false;
                if (element.dependentFields && element.dependentFields.length > 0) {
                    dependentFields = element.dependentFields;
                }
            }

            return element;
        });

        mapper = mapper.map(map => {
            let element = { ...map };
            if (dependentFields.find(field => field === element.key)) {
                element.checked = true;
            }

            return element;
        });

        resetInspectionDetailByKeyConnect(RESET_REDUCER_KEYS.DOCUMENTS_MAPPER, mapper);
    };

    const onSelect = (value) => {
        setSelectedValue(value);
    };
    return (
        <div>
            <SelectComponent
                optionsList={nocIssuedList}
                placeholder={detail.text}
                onChange={onSelect}
            />
        </div>
    );
};

export default RtoNocIssued;

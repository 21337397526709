import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ManufacturingDate from "./component";
import {fetchInspectionDetail, resetInspectionDetailByKey} from "../../pages/inspection-review/actions";

const mapStateToProps = ({
    inspectionDetails
}) => ({
    inspectionDetails
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    resetInspectionDetailByKeyConnect: resetInspectionDetailByKey,
    fetchInspectionDetailConnect: fetchInspectionDetail
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ManufacturingDate);

import React, { useEffect, useState } from "react";
import styles from "./tooltip.module.css";
import RightSign from "../../components/images/rightSign.svg";
import BasicInfoIcon from "../../components/images/basicInfoIcon.svg";
import InfoIcon from "../../components/images/infoIcon.svg";
import YellowInfoIcon from "../../components/images/yellowInfoIcon.svg";

const Tooltip = ({ dataSource, vaahanFlag, parivahanWebsite, vahanWebsite, vahanSms, rcOcrRegNo, taxUpTo, keyId, commentFlag = null }) => {
    const getInfoIcon = () => {
        switch(commentFlag) {
            case 1:
                return <img src={BasicInfoIcon} alt="Info Sign" />
            case 3:
                return <img src={YellowInfoIcon} alt="Info Unverified" />
            default:
                if(vaahanFlag === "no") {
                    return <img src={InfoIcon} alt="info mismatch Sign" />
                } else if (vaahanFlag === "yes") {
                    return <img src={RightSign} alt="right Sign" className={styles.vaahanIcon} />
                } else if (vaahanFlag === "info") {
                    return <img src={BasicInfoIcon} alt="Info Sign" />
                }
        }
    }

    return (
        <div className={styles.tooltipWrapper}>
            {getInfoIcon()}
            {
                !vaahanFlag && !!rcOcrRegNo && <img src={InfoIcon} alt="info mismatch Sign" />
            }{
                !['cngLpgFitment', 'financierName', 'chassisNumberEmbossing'].includes(keyId) && (
                    <div className={styles.tooltip}>
                        <table cellPadding="0" cellSpacing="0" width="100%">
                            {/* <tr>
                                <td>Parivahan :</td>
                                <td>{!!parivahanWebsite ? parivahanWebsite : ""}</td>
                            </tr> */}
                            {/* <tr>
                                <td>SMS :</td>
                                <td>{!!vahanSms ? vahanSms : ""}</td>
                            </tr>  */}
                            <tr>
                                <p>{dataSource ? `${dataSource} :` : "Vaahan Web :"}</p>
                                <p>{!!vahanWebsite ? vahanWebsite : ""}</p>
                            </tr>
                            {!!rcOcrRegNo && <tr>
                                <p>RC OCR :</p>
                                <p>{rcOcrRegNo}</p>
                            </tr>}
                            {(keyId === "roadTaxPaid" && !!taxUpTo) && <tr>
                                <p>Tax Paid :</p>
                                <p>{taxUpTo}</p>
                            </tr>}
                        </table>
                    </div>
                )
            }
        </div>
    );
};

export default Tooltip;

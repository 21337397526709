import { createReducer } from "reduxsauce";
import Types from "./types";

export const INITIAL_STATE = {
    isLoading: true,
    cityLocation: [],
    error: null,
    citySelected: [],
    locationSelected: [],
    categorySelected: "",
    assigneeList: [],
    assigneeSelected: ""

};

export const fetchCityLocation = (state = INITIAL_STATE) => {
    return { ...state, isLoading: true };
};

export const resetCityLocation = () => {
    return INITIAL_STATE;
};

export const fetchCityLocationSuccess = (state = INITIAL_STATE, { data }) => {
    return { ...state, cityLocation: data, isLoading: false };
};

export const fetchCityLocationFailure = (state = INITIAL_STATE, { error }) => {
    return { ...state, error, cityLocation: [], isLoading: false };
};

export const resetFilterByKey = (state = INITIAL_STATE, { data }) => {
    const { key, value } = data;
    return { ...state, [key]: value };
};
export const fetchAssigneeSuccess = (state = INITIAL_STATE, { data }) => {
    return { ...state, assigneeList: data, assigneeSelected: "" };
};

export const fetchAssigneeFailure = (state = INITIAL_STATE, { error }) => {
    return { ...state, error, assigneeList: [], assigneeSelected: "" };
};
export const HANDLERS = {
    [Types.FETCH_CITYLOCATION]: fetchCityLocation,
    [Types.RESET_CITYLOCATION]: resetCityLocation,
    [Types.FETCH_CITYLOCATION_SUCCESS]: fetchCityLocationSuccess,
    [Types.FETCH_CITYLOCATION_FAILURE]: fetchCityLocationFailure,
    [Types.RESET_FILTER_BY_KEY]: resetFilterByKey,
    [Types.FETCH_ASSIGNEE_SUCCESS]: fetchAssigneeSuccess,
    [Types.FETCH_ASSIGNEE_FAILURE]: fetchAssigneeFailure

};

export default createReducer(INITIAL_STATE, HANDLERS);

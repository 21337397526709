import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import InspectionHeaderTimer from "./component";

const mapStateToProps = ({ inspectionDetails }) => ({ inspectionDetails });

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(InspectionHeaderTimer);

/* eslint-disable indent */
import React from "react";

const UnauthorizedAccess = () => {
  return (
    <div>
      <h3>You are not authorized for this feature </h3>
    </div>
  );
};

export default UnauthorizedAccess;

import { createReducer } from "reduxsauce";
import Types from "./types";
import { BIKE_IMAGE_TYPE, INSPECTION_FLOW } from "../../utils/app-constants";
import { getMakeModelVariantMapperForBike } from "../../utils/helper";
import { thumborUrl } from "../../utils/url-constants";
import { saveToLocalStorage, getFromLocalStorage } from "../../utils/helper";
import { LOCAL_STORAGE_KEYS } from "../../utils/app-constants";

import {
    getBikeDocumentsMapper,
    getBikeDocumentsMapper_v2,
    getBikeAdditionalImagesMapper
} from "../../utils/helper";

export const INITIAL_STATE = {
    isLoading: true,
    bikeId: "",
    appointmentId: "",
    inspectionStatus: "",
    bikeDocumentsMapper: [],
    bikeInspectionDataMapper: {},
    bikeimageMapper: [],
    bikeRcImageMapper: [],
    bikeInsuranceImageMapper: [],
    bikeMainImageMapper: [],
    bikeMakeModelVariantMapper: [],
    cjName: "",
    reportUrl: "",
    pdfUrl: "",
    imageHostUrl: thumborUrl(),
    imagequalityParam: "",
    additionalBikeImagesMapper: [],
    uiVersion: ""
};

export const fetchInspectionDetail = (state = INITIAL_STATE) => {
    return { ...state, isLoading: true };
};

export const resetInspectionBikeDetail = () => {
    return INITIAL_STATE;
};

export const fetchInspectionBikeDetailSuccess = (state = INITIAL_STATE, { data }) => {

    try {
        const { qcUser } = data;
        const _bikeData = data.inspectionData;// data[0].inspectionData;
        const _bikeImages = data.images || [];//data[0].images;
        let mapper = _bikeImages.map(function (el, index) {
            const o = Object.assign({}, el);
            o.checked = false;
            o.url = el.uri;
            return o;
        });
        let dynamicImageHostUrl = !!getFromLocalStorage(LOCAL_STORAGE_KEYS.IMAGE_HOST_URL) ? getFromLocalStorage(LOCAL_STORAGE_KEYS.IMAGE_HOST_URL) : thumborUrl();
        // saveToLocalStorage(LOCAL_STORAGE_KEYS.IMAGE_HOST_URL, dynamicImageHostUrl);
        let imageQuality = !!getFromLocalStorage(LOCAL_STORAGE_KEYS.IMAGE_QUALITY_PARAM) ? getFromLocalStorage(LOCAL_STORAGE_KEYS.IMAGE_QUALITY_PARAM) : "";
        const {uiVersion} = data.inspectionData;
        let bikeDocumentsMapper = null;
        if (!!uiVersion && uiVersion === 2) {
            bikeDocumentsMapper = getBikeDocumentsMapper_v2(data);
        } else {
            bikeDocumentsMapper = getBikeDocumentsMapper(data);
        }
        let copyMapper = [...mapper];
        let rcImagesMapper = copyMapper.filter(x => x.type === BIKE_IMAGE_TYPE.RC || x.type === BIKE_IMAGE_TYPE.CHASIS_NUMBER);
        let insuranceImagesMapper = copyMapper.filter(x => x.type === BIKE_IMAGE_TYPE.INSURANCE);
        let bikeMainImagesMapper = copyMapper.filter(x => x.type === BIKE_IMAGE_TYPE.BIKE_IMAGES || x.type === BIKE_IMAGE_TYPE.DUPLICATE_KEY);

        rcImagesMapper = rcImagesMapper.map(function (el, index) {
            const o = Object.assign({}, el);
            o.index = index;
            return o;
        });
        insuranceImagesMapper = insuranceImagesMapper.map(function (el, index) {
            const o = Object.assign({}, el);
            o.index = index;
            return o;
        });
        bikeMainImagesMapper = bikeMainImagesMapper.map(function (el, index) {
            const o = Object.assign({}, el);
            o.index = index;
            return o;
        });

        const aptID = data.appointmentId;//data[0].appointmentId;
        const _bikeId = data.id;//data[0].id;
        const bikeMakeModelVariantMapper = getMakeModelVariantMapperForBike(data);
        const additionalBikeImagesMapper = getBikeAdditionalImagesMapper(data);
        return {
            ...state,
            bikeInspectionDataMapper: _bikeData,
            bikeimageMapper: mapper,
            bikeRcImageMapper: rcImagesMapper,
            bikeInsuranceImageMapper: insuranceImagesMapper,
            bikeMainImageMapper: bikeMainImagesMapper,
            isLoading: false,
            appointmentId: aptID,
            bikeId: _bikeId,
            cjName: (!!_bikeData && !!_bikeData["CJ Email"]) ? _bikeData["CJ Email"] : "",
            reportUrl: (!!_bikeData && !!_bikeData["formUrl"]) ? _bikeData["formUrl"] : "",
            cjRemark: _bikeData && _bikeData.cjRemark,
            qcUser,
            bikeMakeModelVariantMapper,
            pdfUrl: (!!data && !!data["pdfUrl"]) ? data["pdfUrl"] : "",
            bikeDocumentsMapper,
            imageHostUrl: dynamicImageHostUrl,
            imagequalityParam: imageQuality,
            additionalBikeImagesMapper,
            uiVersion,
            version: data.version
        };
    }
    catch (e) {
        console.log(e);
        return { ...state, error: "Expection", data: [], isLoading: false };
    }
};

export const fetchInspectionBikeDetailFailure = (state = INITIAL_STATE, { error }) => {
    return { ...state, error: "Expection", data: [], isLoading: false };
};
export const resetInspectionBikeDetailByKey = (state = INITIAL_STATE, { data }) => {
    const { key, value } = data;
    return { ...state, [key]: value };

};
export const HANDLERS = {
    [Types.FETCH_INSPECTIONS_BIKE_DETAIL_SUCCESS]: fetchInspectionBikeDetailSuccess,
    [Types.FETCH_INSPECTIONS_BIKE_DETAIL_FAILURE]: fetchInspectionBikeDetailFailure,
    [Types.RESET_INSPECTIONS_BIKE_DETAIL]: resetInspectionBikeDetail,
    [Types.RESET_INSPECTION_BIKE_DETAIL_BY_KEY]: resetInspectionBikeDetailByKey

};
export default createReducer(INITIAL_STATE, HANDLERS);

import React, { useState, useEffect } from "react";
import styles from "./rejection-popup.module.css";
import H2 from "../h2";
import Button from "../shared/button";
import { Multiselect } from "multiselect-react-dropdown";
import {
    ISSUE_TYPES,
    RC_CONDITIONS,
} from "../../utils/app-constants";

const RejectionPopup = ({ resetInspectionDetailByKeyConnect, onClose, rejectionDataList }) => {
    const [selectedList, setSelectedList] = useState([]);

    const onSelectIssue = (optionsList, selectedItem) => {
        const okIndex = optionsList.findIndex(key => key.id === "No issue");
        if (okIndex > -1 && selectedItem.id !== "No issue") {
            optionsList.splice(okIndex, 1);
        }
        else if (selectedItem.id === "No issue") {
            optionsList.splice(0, optionsList.length);
            optionsList.push(selectedItem);
        }
        setSelectedList(optionsList);
        resetInspectionDetailByKeyConnect("issueType", optionsList);
    };

    useEffect(() => {
        setSelectedList(rejectionDataList);
        resetInspectionDetailByKeyConnect("issueType", rejectionDataList);
    }, []);

    const onRemoveIssue = (optionsList, removedItem) => {
        setSelectedList(optionsList);
        resetInspectionDetailByKeyConnect("issueType", optionsList);
    };
    const submitConfirm = () => {
        if (!!selectedList && selectedList.length > 0) {
            onClose(true);
        }
        else {
            alert("Please select Issue Type");
        }
    };
    const cancelConfirm = () => {
        onClose(false);
    };

    return (
        <div className={styles.rejectionPopup}>
            <H2 text={"Rejection Reason:"} />
            <div className={styles.multiselect}>
                <Multiselect
                    options={ISSUE_TYPES}
                    displayValue="name"
                    closeOnSelect={false}
                    onSelect={onSelectIssue}
                    onRemove={onRemoveIssue}
                    selectedValues={selectedList}
                />
            </div>
            <p>Are you sure about rejecting this car?</p>
            <div className={styles.ctaWrapper}>
                <Button classNames="greenCtaButton" onClick={cancelConfirm} ctaText={"No, Go Back"} />
                <Button onClick={submitConfirm} classNames="redCtaButton" ctaText={"Yes, Reject"} />
            </div>
        </div>
    );
};

export default RejectionPopup;

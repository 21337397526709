import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import styles from "./inspection-insurance.module.css";
import Checkbox from "../shared/checkbox/";
import { getEditableComponent } from "../../utils/helper";
import { RESET_REDUCER_KEYS } from "../../utils/app-constants";
import Change from "../../components/change";
import Tooltip from "../tooltip";
import { INSPECTION_SECTION } from "../../utils/app-constants";
import Remark from "../shared/remark/Remark";
import YellowInfoIcon from "../images/yellowInfoIcon.svg";
import GreenTickIcon from '../../pages/sell-online/images/green_tick.svg'

const InspectionInsurance = ({
    document,
    resetInspectionDetailByKeyConnect,
    inspectionDetails
}) => {
    const dispatch = useDispatch();
    const [isRemarkHistoryOpen, setIsRemarkHistoryOpen] = useState(false);
    const { commentFlag, text, checked, value, key, newValue, isEditable, isDisabled, vaahanFlag, parivahanWebsite, vahanWebsite, vahanSms, prevValue } = document;
    const { insuranceMapper } = inspectionDetails;
    const resetValues = (field) => {
        field.newId = '';
        field.newValue = '';
    };

    const onCheckboxHandler = (e, key) => {
        const index = insuranceMapper.findIndex(item => item.key === key);
        insuranceMapper[index].checked = !insuranceMapper[index].checked;
        const { target: { checked } } = e;

        if (!checked) {
            resetValues(insuranceMapper[index]);
        }
        resetInspectionDetailByKeyConnect(RESET_REDUCER_KEYS.INSURANCE_MAPPER, insuranceMapper);
        setIsRemarkHistoryOpen(checked);
    };

    return (
        <>
            <div className={styles.documentInfo}>
                <div className={styles.checkboxlabel}>
                    { !(commentFlag == 0 || commentFlag == 2) &&
                        <Checkbox
                            checkedStatus={checked}
                            onChangeCallback={(e) => onCheckboxHandler(e, key)}
                            name={`insurance_${key}`}
                            id={`insurance_${key}`}
                            isDisabled={isDisabled}
                            isEditable={isEditable}
                            key={key}
                            document={document}
                        />
                    }
                    <label className={styles.lableText}>{text}</label>
                </div>
                <div className={styles.peWrappper}>
                    <div>
                        <div className={styles.dataWrapper}>
                            {
                                vaahanFlag && <Tooltip vaahanFlag={vaahanFlag} parivahanWebsite={parivahanWebsite} vahanWebsite={vahanWebsite} vahanSms={vahanSms} />
                            }
                            {
                                checked && isEditable ? getEditableComponent(key, document, resetInspectionDetailByKeyConnect) : newValue ? newValue : value
                            }

                            <span> 
                                {
                                    commentFlag == 3 && <img src={YellowInfoIcon} className={styles.infoIcons} />
                                }
                                {
                                    (commentFlag == 0 || commentFlag == 2) && <img src={GreenTickIcon} className={styles.infoIcons} />
                                }
                            </span> 
                            {
                                newValue ? <Change value={value} /> : ""
                            }

                        </div>
                        {!!prevValue && <label className={styles.pe}>PE: {prevValue}</label>}
                    </div>
                    {!!document?.remarkHistory?.length && (
                        <span
                            className={"remarkHistoryBtn"}
                            onClick={() => setIsRemarkHistoryOpen(!isRemarkHistoryOpen)}
                        >
                        Remark History
                        </span>
                    )}
                </div>
            </div>

            <Remark
                document={document}
                section={INSPECTION_SECTION.CAR_DETAILS}
                showRemarkInput={document.checked && !document.hideRemarkInput}
                isRemarkHistoryOpen={isRemarkHistoryOpen}
            />
            
        </>
    );
};

export default InspectionInsurance;

import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import InspectionReview from "./component";
import {fetchInspectionDetail, resetInspectionDetail, resetInspectionDetailByKey} from "./actions";

const mapStateToProps = ({
    inspectionDetails
}) => ({
    inspectionDetails
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchInspectionDetailConnect: fetchInspectionDetail,
    resetInspectionDetailConnect: resetInspectionDetail,
    resetInspectionDetailByKeyConnect : resetInspectionDetailByKey
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(InspectionReview);

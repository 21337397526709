import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import RejectionPopup from "./component";
import {resetInspectionDetailByKey} from "../../pages/inspection-review/actions";

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    resetInspectionDetailByKeyConnect: resetInspectionDetailByKey
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(RejectionPopup);

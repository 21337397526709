import React, { useEffect } from "react";
import styles from "./inspection-review-bike.module.css";
import DataInformationMessage from "../../components/data-information-message";
import InspectionReviewRcBike from "../../components/inspection-review-rc-bike";
import InspectionReviewExteriorBike from "../../components/inspection-review-exterior-bike";
import InspectionHeader from "../../components/inspection-header/";
import InspectionReviewFooterBike from "../../components/inspection-review-footer-bike";
import InspectionReviewInfo from "../../components/inspection-review-info/";
import { InspectionService } from "../../service";
import { setImageHostToLocalStorageForBike } from "../../utils/helper";
import MultipleCarousel from '../../components/multiple-carousel';

const InspectionReviewBike = ({
    fetchBikeInspectionDetailConnect,
    match,
    bikeInspectionDetails,
    history,
    resetInspectionBikeDetailConnect
}) => {
    const { params: { appointmentId } } = match;
    const urlParams = new URLSearchParams(window.location.search);
    const bikeVersion = urlParams.get('version');
    const versionViewMode = bikeVersion === null;
    const {
        isLoading,
        error
    } = bikeInspectionDetails;

    const { 
        cjName, 
        reportUrl, 
        bikeRcImageMapper, 
        bikeInsuranceImageMapper, 
        bikeMainImageMapper, 
        pdfUrl,
        additionalBikeImagesMapper
    } = bikeInspectionDetails;

    useEffect(() => {
        InspectionService.getBikePublicConfigData().then(response => {
            const { data: { imageUrlTemplate } } = response;
            if (!!imageUrlTemplate) {
                setImageHostToLocalStorageForBike(imageUrlTemplate);
            }
        }).catch(error => {
        });

        return () => {
            resetInspectionBikeDetailConnect();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        fetchBikeInspectionDetailConnect(appointmentId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appointmentId]);

    return (
        <div>
            <div className={styles.fixedHeader} >
                <InspectionHeader history={history} cjName={cjName} reportUrl={reportUrl} pdfUrl={pdfUrl} bikeVersion={bikeVersion}/>
                <InspectionReviewInfo versionViewMode={versionViewMode} />
            </div>
            {!isLoading && !error && (
                <>
                    <InspectionReviewRcBike 
                        data={bikeRcImageMapper} 
                        type="RC" 
                        currentMapper={"bikeRcImageMapper"} 
                        detailView={true} 
                        versionViewMode={versionViewMode}
                    />
                    <InspectionReviewRcBike 
                        data={bikeInsuranceImageMapper} 
                        type="INSU" 
                        currentMapper={"bikeInsuranceImageMapper"} 
                        versionViewMode={versionViewMode}
                    />
                    <InspectionReviewExteriorBike 
                        data={bikeMainImageMapper} 
                        type="MAIN" 
                        currentMapper={"bikeMainImageMapper"} 
                        versionViewMode={versionViewMode}
                    />
                    <MultipleCarousel 
                        mapper={additionalBikeImagesMapper}
                        currentMapper="additionalBikeImagesMapper"
                        versionViewMode={versionViewMode}
                    />
                </>
            )}
            <DataInformationMessage
                isLoading={isLoading}
                data={[1]}
                error={error}
            />

            <div>
                <InspectionReviewFooterBike versionViewMode={versionViewMode} history={history} />
            </div>
        </div>

    );
};

export default InspectionReviewBike;

import Types from "./types";
import { InspectionService } from "../../service";
import { getBikeListing } from "../../utils/mocBikeApi";

const fetcBikeInspectionList = (page, useremail, searchQuery, appointmentId, startDate, endDate) => dispatch => {

    return InspectionService.getBikeInspectionList(page, useremail, searchQuery, appointmentId, startDate, endDate)
        .then(response => {
            // response = getBikeListing();
            const { data } = response;
            dispatch(fetcBikeInspectionListSuccess(data));
            return response;
        })
        .catch(error => {
            // let response = getBikeListing();
            // dispatch(fetcBikeInspectionListSuccess(response));
            dispatch(fetcBikeInspectionListFailure(error));
            throw error;
        });
};
const fetcBikeInspectionListSuccess = (data) => ({
    type: Types.FETCH_BIKE_INSPECTIONS_LIST_SUCCESS,
    data
});
const fetcBikeInspectionListFailure = (data) => ({
    type: Types.FETCH_BIKE_INSPECTIONS_LIST_FAILURE,
    data
});
const resetBikeInspectionByKey = (key, value) => ({
    type: Types.RESET_BIKE_INSPECTION_BY_KEY,
    data: { key, value }
});
export {
    fetcBikeInspectionList,
    resetBikeInspectionByKey
};

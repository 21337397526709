import React, { useEffect, useState } from "react";
import styles from "./inspection-documents.module.css";
import Checkbox from "../shared/checkbox/";
import { getEditableComponent } from "../../utils/helper";
import { INSPECTION_SECTION, RESET_REDUCER_KEYS, INSPECTION_STATUS_KEYS } from "../../utils/app-constants";
import Change from "../change";
import Tooltip from "../../components/tooltip";
import Remark from "../shared/remark/Remark";

const InspectionDocuments = ({
    document,
    resetInspectionDetailByKeyConnect,
    inspectionDetails,
    onItemCheckCallback = () => { }
}) => {
    
    const [isRemarkHistoryOpen, setIsRemarkHistoryOpen] = useState(false);
    const [showStateError,setShowStateError] = useState({
        value:false,
        message:""
    });
    const { text, checked, value, key, newValue, isVerified ,isEditable, isDisabled, isImportant, vaahanFlag, parivahanWebsite, vahanWebsite, vahanSms, prevValue, errorMsg, dataSource, rcOcrRegNo, commentFlag } = document;
    const excludedFieldsForChangedTo = [
        "rcCondition",
    ];
    const { documentsMapper, isDocumentPopup, inspectionStatus } = inspectionDetails;
    const {vahanWebsite : vahanWebsiteData} = inspectionDetails?.vahanData || {};
    
    useEffect(()=>{
        checkStateError();
    },[value]);

    useEffect(()=>{
        if(key === "registrationState" && !!newValue){
            setShowStateError({
                value:false,
                message: ""
            });
            if(newValue === "West Bengal"){
                setShowStateError({
                    value:true,
                    message: "Please make sure to check for tax receipt along with RC for West Bengal"
                });
            }
            else if(newValue === "Puducherry"){
                setShowStateError({
                    value:true,
                    message: "Please make sure to check for annexure along with RC for Puducherry"
                });
            }
        }
        else{
            checkStateError();
        }
    },[newValue]);

    const checkStateError= () =>{
        if(key === "registrationState"){
            let errorMessage= "";
            if(value === "West Bengal"){
                errorMessage= "Please make sure to check for tax receipt along with RC for West Bengal";
            }
            else if(value === "Puducherry"){
                errorMessage= "Please make sure to check for annexure along with RC for Puducherry";
            }
            setShowStateError({
                value:true,
                message:errorMessage
            });
        }
    };

    const resetValues = (field) => {
        field.newId = '';
        field.newValue = '';
        field.errorMsg = '';
        field.isValid = true;
    };

    const onCheckboxHandler = (e, key) => {
        const { target: { checked } } = e;

        let mapper = [...documentsMapper];
        const field = mapper.find(item => item.key === key);
        if ([INSPECTION_STATUS_KEYS.SM_REVIEW, INSPECTION_STATUS_KEYS.DONE].includes(inspectionStatus)) {
            field.isEditable = true;
        }
        const { dependentFields } = field;
        field.checked = checked;
        if (!checked) {
            resetValues(field);
        }

        if(key === "rcAvailability" && field.value === "Photocopy") {
            dependentFields.push("rcAtDelivery");
        }

        (dependentFields || []).forEach((item => {
            const dependentItem = mapper.find(x => x.key === item);
            if (dependentItem) {
                if (key === "rcAvailability") {
                    const rcAvailabilityIndex = mapper.findIndex(item => item.key === "rcAvailability");
                    if (rcAvailabilityIndex > -1 && mapper[rcAvailabilityIndex].value.match(/lost/i)) {
                        dependentItem.checked = false;
                    } else {
                        dependentItem.checked = checked;
                        dependentItem.isDisabled = checked;
                    }
                }
                else if (field.key === "isUnderHypothecation" && field.value === "No") {

                }
                else {
                    dependentItem.checked = checked;
                    dependentItem.isDisabled = checked;
                }
            }

            dependentItem.hideRemarkInput = checked;
            
            if (!checked) {
                resetValues(dependentItem);
            }
        }));

        const fieldIndex = mapper.indexOf(field);
        mapper[fieldIndex] = field;
        
        resetInspectionDetailByKeyConnect(RESET_REDUCER_KEYS.DOCUMENTS_MAPPER, mapper);

        onItemCheckCallback(key);

        setIsRemarkHistoryOpen(checked);
    };

    return (
        <>
            <div className={styles.documentInfo}>
                <div className={styles.checkboxlabel}>
                    {key !=='carColour' && !isVerified && (
                        <Checkbox
                            checkedStatus={checked}
                            onChangeCallback={(e) => onCheckboxHandler(e, key)}
                            name={`documents_${key}`}
                            id={`documents_${key}`}
                            isDisabled={isDisabled}
                            isEditable={isEditable}
                            key={key}
                            document={document}
                        />
                    )}
                    <label className={`${styles.lableText} ${isImportant ? styles.boldField : ""}`}>{text}</label>
                    {/* <label>{prevValue}</label> */}
                </div>
                <div className={styles.peWrappper}>
                    <div>
                        <div className={styles.dataWrapper}>
                            {
                               (!!vaahanFlag || !!rcOcrRegNo || !!commentFlag) && !isVerified && <Tooltip dataSource={dataSource} vaahanFlag={vaahanFlag} parivahanWebsite={parivahanWebsite} vahanWebsite={vahanWebsite} vahanSms={vahanSms} rcOcrRegNo={rcOcrRegNo} taxUpTo={vahanWebsiteData?.taxUpTo} keyId={key} commentFlag={commentFlag} isVerified={isVerified}/>
                            }
                            {
                                checked && isEditable ? getEditableComponent(key, document) : newValue ?
                                    <label className={`${isImportant ? styles.boldField : ""}`}>{newValue}</label> :
                                    <label className={`${isImportant ? styles.boldField : ""}`}>{value}</label>
                            }
                            {
                                newValue && !excludedFieldsForChangedTo.find(item => item === key) ? <Change value={value} errorMessage={errorMsg} /> : ""
                            }
                            {
                                (showStateError?.value && key === "registrationState") && <div class={styles.stateError}>{showStateError?.message}</div>
                            }
                        </div>
                        {!!prevValue && <label className={styles.pe}>PE: {prevValue}</label>}
                    </div>
                    {!!document?.remarkHistory?.length && (
                        <span
                            className={styles.remarkHistoryBtn}
                            onClick={() => setIsRemarkHistoryOpen(!isRemarkHistoryOpen)}
                        >
                        Remark History
                        </span>
                    )}
                </div>
            </div>

            <Remark
                document={document}
                section={INSPECTION_SECTION.CAR_DETAILS}
                showRemarkInput={document.checked && !document.hideRemarkInput}
                isRemarkHistoryOpen={isRemarkHistoryOpen}
            />

        </>
    );
};

export default InspectionDocuments;

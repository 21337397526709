/* eslint-disable indent */
import React from "react";

const HealthPage = () => {
  return (
    <div>
      Success
        </div>
  );
};

export default HealthPage;

import React from "react";
import Select from 'react-select';
import styles from "./select.module.css";

const SelectComponent = ({ name, placeholder = "select", defaultValue = "", hasError = false, isDisabled = false,
    isMulti = false, isSearchable = true, isClearable = false,
    onChange,
    optionsList,
    questionId,
    value,
    classNameProps = '',
    error = "" }) => {
    const options = optionsList.map((option) => {
        return (
            { 
                value: option.value || option.model_id || option.make_id,
                label: option.label || option.model_name || option.make_name,
                optionData: option
            }
        );
    });

    const onCHangeHandler = (option) => {
        let optionObject = option ? option : {value: '', label: ''};
        return isMulti ? onChange(optionObject)  : onChange(optionObject.value, optionObject.label, optionObject.optionData);
    };

    return (
        <div className={`
            ${hasError ? styles.validatorInput : ""}
            ${isDisabled ? "cursor-not-allowed" : ""}
        `}>
            <Select
                key={name}
                name={name}
                placeholder={placeholder}
                defaultValue={Object.keys(defaultValue).length > 0 ? defaultValue : ""}
                isDisabled={isDisabled}
                isSearchable={isSearchable}
                isClearable={isClearable}
                isMulti={isMulti}
                options={options}
                value={value}
                classNamePrefix='react-select'
                className={`react-select-container ${classNameProps}`}
                onChange={onCHangeHandler}
                styles={{
                    menuPortal: (provided) => ({
                        ...provided,
                        zIndex: 10000000,
                    }),
                }}
                menuPortalTarget={document.body}
                maxMenuHeight={150}
            />
            {hasError &&
                <span className={styles.error}>{error}</span>
            }
        </div>
    );
};

export default SelectComponent;